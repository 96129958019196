import Papa from 'papaparse';

export default class FileService {


    downloadCSV(data, fileName, dataKeys) {

        let newData, newFileName, link;

        // let csv = this.convertArrayOfObjectsToCSV(data, ',', '\n', dataKeys);
        // the papaparse code below was used to replace th euse of teh function above to convert json object to csv data
        // Date seem not to have been converted properly using that function

        let papa_config = {
          quotes: false, //or array of booleans
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ',',
          header: true,
          newline: '\r\n',
          skipEmptyLines: false, //or 'greedy',
          columns: null //or array of strings
        };
        let csv = Papa.unparse(data, papa_config);

        if (csv === null) return;

        newFileName = `${fileName} ${Math.round(new Date().getTime() / 1000)}.csv` || 'dataexport.csv';

        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, newFileName)
        } else {
            link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection, Browsers that support HTML5 download attribute
                newData = URL.createObjectURL(blob);
                link.setAttribute("href", newData);
                link.setAttribute("download", newFileName);
                link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        // if (!csv.match(/^data:text\/csv/i)) {
        //     csv = 'data:text/csv;charset=utf-8,' + csv;
        // }
        // newData = encodeURI(csv);

        // link = document.createElement('a');
        // link.setAttribute('href', newData);
        // link.setAttribute('download', newFileName);
        // link.click();
    }



    convertArrayOfObjectsToCSV(data, columnDelimiter, lineDelimiter, keys) {
        let result, ctr;

        data = data || null;
        if (data === null || !data.length || typeof data === 'undefined') {
            return null;
        }

        columnDelimiter = columnDelimiter || ',';
        lineDelimiter = lineDelimiter || '\n';

        keys = keys || Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) result += columnDelimiter;

                if (this.isNumber(item[key]) === true) {
                    result += parseFloat(item[key].toString().replace(/\$|,/g, ''));
                } else {
                    result += "\"" + item[key] + "\"";
                }


                // result += item[key]; //previous result code before the if statement was added above
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }


    isNumber(value) {

        if (value !== null && typeof value !== 'undefined') {

            value = value.toString(); // forceful conversion to string value

            let t = parseFloat(value.replace(/\$|,/g, ''))
            return isFinite(t) && +t === t;

        } else {

            return false

        }
    };


}