import React, {Component} from 'react';


class ValuesChart extends Component{
    constructor(props){
        super(props);
        this.state = {
            chartID: props.chartID || 'chartID',
            // chartIdentifierName: props.chartIdentifierName,
            title: props.title || '',
            showArrowIcon: props.showArrowIcon || false,
            arrowIconDirection: props.arrowIconDirection || 'up',
            titleTrendingValue: props.titleTrendingValue || '',
            titleTrendingValuePrefix: props.titleTrendingValuePrefix || '',
            titleTrendingValueSuffix: props.titleTrendingValueSuffix || '',
            textColour: props.textColour || 'purple',
            buttonTitle:  props.buttonTitle || '',
            chartElement:  props.chartElement || null,
            link: props.link,
            // chartHeight: props.chartHeight,
            // chartWidth: props.chartWidth,
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '8',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '8',
        
        }

    }


    componentDidMount(){ 


    }


    render(){


        
        return (
            // <!--Values Chart start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div id={this.state.chartID} className="card">
                    <div className="card-content">
                        <h4 className="header mt-0">
                            {this.state.title}
                            <span className={`${this.state.textColour}-text small text-darken-1 ml-1`}>
{(this.state.showArrowIcon === true)? ((this.state.arrowIconDirection === "up")? <i className={`material-icons green-text`}>keyboard_arrow_up</i>:<i className={`material-icons red-text`}>keyboard_arrow_down</i>) : null } 
                                {this.state.titleTrendingValuePrefix} {this.state.titleTrendingValue}{this.state.titleTrendingValueSuffix}
                            </span>
                            <a href={this.state.link} className="waves-effect waves-light btn gradient-45deg-purple-deep-orange gradient-shadow right">{this.state.buttonTitle}</a>
                        </h4>
                        <div className="row">
                            <div className="col s12">
                                <div className={this.state.chartID}>
                                    {this.state.chartElement}
                                </div>
                            </div>
                            {/* ::after */}
                        </div>
                    </div>
                </div>
            </div>
            // <!--Values Chart end-->
        )
    }
}

export default ValuesChart;