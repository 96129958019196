import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
import BrowserService from '../modules/BrowserService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import FormatService from '../modules/FormatService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class EEGClaimDetails extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getEEGClaim = this.getEEGClaim.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.activateDeactivateEEGClaim = this.activateDeactivateEEGClaim.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.getECCDetails = this.getECCDetails.bind(this);
        this.getClaimExportedItems = this.getClaimExportedItems.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.submitEEGClaim = this.submitEEGClaim.bind(this);
        this.addEEGClaimDataFinal = this.addEEGClaimDataFinal.bind(this);
        this.unaddEEGClaimDataFinal = this.unaddEEGClaimDataFinal.bind(this);
        this.state = {
          eegClaimDetails: '',
          eccItems: '',
          claimExportedItems: '',
          ecc_details: [],
          claim_exported_items_details: [],
          numberOfRecords: 0,
          currentNumberOfRecords: 10,
          paginationItem: null,
          preloader: true,
          submitbuttondisabled: false,
          submit_eeg_claim_button_caption: null,
          unsubmit_eeg_claim_button_caption: null,
          result: '',
          status: '',
          message: '',
          logged_in_username: '',
          claim_status: 'Inactive',
          temp_claim_status: 'Inactive',
          hash_organisation: '',
          claim_update: null,
          claim_delete: null,
          scopes: {},
          paginationGetMoreDataButton: null,
          return_back_to_url: '/exporters/eeg/claims'
        };
        this.Auth = new AuthService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.Format = new FormatService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();

    }

    componentDidMount() {
      document.title = this.Language.PageTitle.EEGClaimDetail + ' - ' + this.Language.EEGWebPortal;
        this.setState({
      submitbuttondisabled: false
    });


        this.getAccessScopes();
        this.getEEGClaim();
        this.getClaimExportedItems();


        this.getECCDetails(this.state.currentNumberOfRecord);

        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })


    }


    getProfile() {

        let user_profile = this.Auth.getProfile();



        if (this.props.history.location.search && this.props.history.location.search !== '' && this.props.history.location.search !== null && typeof this.props.history.location.search !== 'undefined') {
            let search = this.props.history.location.search || '';
            if (search.substring(0, 4) === this.returnURLQuery) {
                let returnURLPlaceHolder = search.substring(4);
                this.setState({
                  return_back_to_url: returnURLPlaceHolder || this.state.return_back_to_url
                });
            }
        }



        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['read:claims', 'action:claims'], 'view') === false)) {
            this.props.history.replace(this.state.return_back_to_url);
        };

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'update:claims' || this.state.scopes['views'][i] === 'action:claims') this.setState({ claim_update: true });
                if (this.state.scopes['views'][i] === 'delete:claims' || this.state.scopes['views'][i] === 'action:claims') this.setState({ claim_delete: true });
            }
        };


        // Check the buttons scope
        if (this.state.scopes['buttons']) {
            let len = this.state.scopes['buttons'].length;

            for (let i = 0; i < len; i++) {


            }

        };

        // Check the processes scope
        if (this.state.scopes['processes']) {
            let len = this.state.scopes['processes'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['processes'][i] === 'read:view_encrypted_data') this.setState({ view_encrypted_data: true });
            }

        };

    }

    checkBoxChange() {

        let checkBoxState = this.refs.activate_switch.checked;

        if (checkBoxState) { // Activating
            if (window.confirm(`${this.Language.ConfirmMessage.ActivateClaim}`)) {

                this.activateDeactivateEEGClaim('Activate');

                this.setState({ temp_claim_status: 'Active' });

            } else {

                this.refs.activate_switch.checked = false;

            };

        }
        else { //Deactivating
            if (window.confirm(`${this.Language.ConfirmMessage.DeactivateClaim}`)) {
                // make call to the database here
                this.activateDeactivateEEGClaim('Deactivate');

                this.setState({ temp_claim_status: 'Inactive' });


            } else {

                this.refs.activate_switch.checked = "checked";

            };

        };

    }



    getEEGClaim() {
        let eegclaimId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/eeg/claims/specific/${eegclaimId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {
                this.setState({ eegClaimDetails: response.data.data[0] }, () => {
                    
                })

                if (response.data.data[0].status === 'Active') {
                    this.setState({ claim_status: 'Active' });
                } else {
                    this.setState({ claim_status: 'Inactive' });
                }

                if (response.data.data[0].status === 'Active') {
                    this.setState({ temp_claim_status: 'Active' });
                } else {
                    this.setState({ temp_claim_status: 'Inactive' });
                }


                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }


    activateDeactivateEEGClaim(activate_deactivate_action) {

        let nxp_number = this.state.eegClaimDetails.nxp_number;
        let logged_in_username = this.state.logged_in_username;
        let eegclaimIdTemp = this.state.eegClaimDetails.hash_id;

        const userOS = this.Browser.getOS();

        const newEEGClaimAction = {
            nxp_number: nxp_number,
            activate_deactivate_action: activate_deactivate_action,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken()
        }


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/eeg/claims/activate/${eegclaimIdTemp}`,
            data: newEEGClaimAction
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200 && newEEGClaimAction.activate_deactivate_action === 'Activate') window.Materialize.toast(this.Language.NodeMessage.EEGClaim.ActivateSuccess + newEEGClaimAction.nxp_number, 8000, 'rounded green');
                if (response.status === 200 && newEEGClaimAction.activate_deactivate_action === 'Deactivate') window.Materialize.toast(this.Language.NodeMessage.EEGClaim.DeactivateSuccess + newEEGClaimAction.nxp_number, 8000, 'rounded green');

            })


        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.UpdateError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.DoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                 


            });
    }




    onDelete() {

        document.getElementById("delete_eeg_claim_button").setAttribute("disabled", true);


        let nxp_number = this.state.eegClaimDetails.nxp_number;
        let exporter_id = this.state.eegClaimDetails.exporter_id;
        let logged_in_username = this.state.logged_in_username;
        let eegclaimIdTemp = this.state.eegClaimDetails.hash_id;

        const userOS = this.Browser.getOS();

        const deleteEEGClaim = {
            nxp_number: nxp_number,
            exporter_id: exporter_id,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/eeg/claims/${eegclaimIdTemp}`,
            data: deleteEEGClaim
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                window.Materialize.toast(this.Language.NodeMessage.EEGClaim.DeleteSuccess + deleteEEGClaim.nxp_number, 8000, 'rounded green');
            })


            document.getElementById("delete_eeg_claim_button").removeAttribute("disabled");
            this.props.history.replace(this.state.return_back_to_url);

        })
            .catch(error => {
                if (error.response) {

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_eeg_claim_button").removeAttribute("disabled");

            });

    }



    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            ecc_details: null,
            paginationItem: null,
            eccItems: [],
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getECCDetails(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }



    getECCDetails(currentNumberOfRecords) {

        let eegclaimId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/eccs/nxp/${eegclaimId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ eccItems: response.data.data }, () => {
                    

                    if (this.state.eccItems && this.state.eccItems.length > 0) {


                        const ecc_details = this.state.eccItems.map((ecc_value, i) => {
                            return (
                                <li className="collection-item" key={i}>
                                    <div className="row">
                                        <div className="col s4">
                                            <p><b> {this.Language.PageLabel.CertificateNumber}:</b> {ecc_value.certificate_number}</p>
                                            <p></p>
                                            <p><b> {this.Language.PageLabel.Value}:</b> {ecc_value.currency} {this.Format.FormatLocaleNumber(ecc_value.certificate_value, this.Language.Lang, true, 2)}</p>
                                            <p></p>
                                            <p><b> {this.Language.PageLabel.Approved}:</b> {ecc_value.is_approved ? ((ecc_value.is_approved === 'true') || (ecc_value.is_approved === true) ? this.Language.DataValue.Yes : this.Language.DataValue.No) : null}</p>
                                            <p><b> {this.Language.PageLabel.Status}:</b> {ecc_value.status ? ((ecc_value.status === 'Active') ? this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null}</p>
                                        </div>
                                        <div className="col s4">
                                            <p><b> {this.Language.PageLabel.Utilised}:</b> {ecc_value.is_utilised ? ((ecc_value.is_utilised === 'true') || (ecc_value.is_utilised === true) ? this.Language.DataValue.Yes : this.Language.DataValue.No) : null}</p>
                                            <p><b> {this.Language.PageLabel.UtilisingAgency}:</b> {ecc_value.utilising_agency}</p>
                                        </div>
                                        <div className="col s4">
                                            <p><b> {this.Language.PageLabel.Transferred}:</b> {ecc_value.is_transfered ? ((ecc_value.is_transfered === 'true') || (ecc_value.is_transfered === true) ? this.Language.DataValue.Yes : this.Language.DataValue.No) : null}</p>
                                            <p><b> {this.Language.PageLabel.TransferApproved}:</b> {ecc_value.transfer_approved ? ((ecc_value.transfer_approved === 'true') || (ecc_value.transfer_approved === true) ? this.Language.DataValue.Yes : this.Language.DataValue.No) : null}</p>
                                            <p></p>
                                            <p><b> {this.Language.PageLabel.TransferredTo}:</b> {ecc_value.transfered_to}</p>
                                        </div>
                                        {/* :;after */}
                                    </div>
                                </li>
                            )
                        })

                        this.setState({ ecc_details: ecc_details });

                        this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                        this.setState({ preloader: null });

                    } else { // return empty list with comment

                        const ecc_details = <li className="collection-item">
                            <div className="row">
                                <div className="col s12">
                                    <p>{this.Language.PageLabel.NoECCIssued}</p>
                                </div>
                            </div>
                        </li>

                        this.setState({ ecc_details: ecc_details });
                        this.setState({ preloader: null });

                    }

                })



            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }




    getClaimExportedItems() {

        let eegclaimId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/eeg/claim/exported/items/all/${eegclaimId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({ claimExportedItems: response.data.data }, () => {
                    

                    if (this.state.claimExportedItems && this.state.claimExportedItems.length > 0) {


                        const claim_exported_items_details = this.state.claimExportedItems.map((exported_item_value, i) => {
                            return (
                                <li className="collection-item" key={i}>
                                    <div className="row">
                                        <div className="col s7">
                                            <b> {this.Language.PageLabel.Description}:</b> {exported_item_value.product_description}
                                            <p></p>
                                            <b> {this.Language.PageLabel.UnitPrice}:</b> {exported_item_value.export_currency}  {this.Format.FormatLocaleNumber(exported_item_value.unit_price, this.Language.Lang, true, 2)}
                                            <b> {this.Language.PageLabel.Quantity}:</b> {this.Format.FormatLocaleNumber(exported_item_value.quantity, this.Language.Lang, true, 2)}
                                            <p></p>
                                            <b> {this.Language.PageLabel.ExportAmount}:</b> {exported_item_value.export_currency}  {this.Format.FormatLocaleNumber(exported_item_value.export_amount, this.Language.Lang, true, 2)}
                                        </div>
                                        <div className="col s1">
                                        </div>
                                        <div className="col s4">
                                            <b> {this.Language.PageLabel.Status}:</b> {exported_item_value.status ? ((exported_item_value.status === 'Active') ? this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null}
                                            <p></p>
                                            <b> {this.Language.PageLabel.CalledOver}:</b> {exported_item_value.is_called_over ? ((exported_item_value.is_called_over === 'true' || exported_item_value.is_called_over === true) ? this.Language.DataValue.Yes : this.Language.DataValue.No) : null}
                                        </div>
                                        {/* :;after */}
                                    </div>
                                </li>
                            )
                        })

                        this.setState({ claim_exported_items_details: claim_exported_items_details });

                    } else { // return empty list with comment

                        const claim_exported_items_details = <li className="collection-item">
                            <div className="row">
                                <div className="col s12">
                                    <p>{this.Language.PageLabel.NoEportedItemCaptured}</p>
                                </div>
                            </div>
                        </li>

                        this.setState({ claim_exported_items_details: claim_exported_items_details });

                    }

                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    submitEEGClaim(eegclaim_option) {
    if (eegclaim_option === 'submit_eeg_claim') {
      const userOS = this.Browser.getOS();

      //make call to database
      const newEEGClaimData = {
        exporter_id: this.state.eegClaimDetails.exporter_id,
        nxp_number: this.state.eegClaimDetails.nxp_number,
        hash_id: this.state.eegClaimDetails.hash_id,
        last_updated_by: this.state.logged_in_username,
        device_name: this.state.logged_in_username + "'s " + userOS,
        device_model: userOS,
        device_os: userOS,
        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
        app_source: 'web',
        token: this.Auth.getToken()
      };

      

      this.setState({submitbuttondisabled: true});

      this.addEEGClaimDataFinal(newEEGClaimData);
    }


    if (eegclaim_option === 'unsubmit_eeg_claim') {
      const userOS = this.Browser.getOS();

      //make call to database
      const newEEGClaimData = {
        exporter_id: this.state.eegClaimDetails.exporter_id,
        nxp_number: this.state.eegClaimDetails.nxp_number,
        hash_id: this.state.eegClaimDetails.hash_id,
        last_updated_by: this.state.logged_in_username,
        device_name: this.state.logged_in_username + "'s " + userOS,
        device_model: userOS,
        device_os: userOS,
        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
        app_source: 'web',
        token: this.Auth.getToken()
      };

      

      this.setState({submitbuttondisabled: true});

      this.unaddEEGClaimDataFinal(newEEGClaimData);
    }
  }


  addEEGClaimDataFinal(newEEGClaimData) {
    axios
      .request({
        method: 'put',
        url: `${this.apiURL}/api/v1/eeg/claims/submit/claim/${newEEGClaimData.hash_id}`,
        data: newEEGClaimData
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {
          if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.SubmitSuccessEEGClaim + newEEGClaimData.nxp_number, 8000, 'rounded green');
        });

        this.setState({preloader: null, submit_eeg_claim_button_caption: this.Language.ButtonLabel.Submitted});
      })
      .catch(error => {
        if (error.response) {
          

            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.Error, 15000, 'rounded deep-orange darken-4');
            if (error.response.status === 400)
              window.Materialize.toast(this.Language.NodeMessage.EEGClaim.IncompleteData + ' ' + error.response.data.eeg_claim_message, 15000, 'rounded deep-orange darken-4');
            if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.AlreadySubmitted, 15000, 'rounded deep-orange darken-4');

        } else if (error.request) {
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        

        this.setState({submitbuttondisabled: false});
        // document.getElementById("save_baseline_data_button").removeAttribute("disabled");
      });
  }



    unaddEEGClaimDataFinal(newEEGClaimData) {
    axios
      .request({
        method: 'put',
        url: `${this.apiURL}/api/v1/eeg/claims/unsubmit/claim/${newEEGClaimData.hash_id}`,
        data: newEEGClaimData
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {
          if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.UnsubmitSuccessEEGClaim + newEEGClaimData.nxp_number, 8000, 'rounded green');
        });

        this.setState({preloader: null, unsubmit_eeg_claim_button_caption: this.Language.ButtonLabel.Unsubmitted});
      })
      .catch(error => {
        if (error.response) {
          

          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.Error, 15000, 'rounded deep-orange darken-4');
          if (error.response.status === 400)
            window.Materialize.toast(this.Language.NodeMessage.EEGClaim.DoesNotExist, 15000, 'rounded deep-orange darken-4');
          if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.NotYetSubmitted, 15000, 'rounded deep-orange darken-4');
        } else if (error.request) {
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
       

        this.setState({submitbuttondisabled: false});
        // document.getElementById("save_baseline_data_button").removeAttribute("disabled");
      });
  }





    render() {


        let new_export_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_export_date);
        if (new_export_date !== '') new_export_date = this.DateService.convertToCalendarFullDate(new_export_date);
        if (new_export_date === '01 January, 1900') new_export_date = '';


        let new_claims_application_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_claims_application_date);
        if (new_claims_application_date !== '') new_claims_application_date = this.DateService.convertToCalendarFullDate(new_claims_application_date);
        if (new_claims_application_date === '01 January, 1900') new_claims_application_date = '';


        let approved_at = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.approved_at);
        if (approved_at !== '') approved_at = this.DateService.convertToCalendarFullDate(approved_at);
        if (approved_at === '01 January, 1900') approved_at = '';

        let submitted_on = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_submitted_on);
        if (submitted_on !== '') submitted_on = this.DateService.convertToCalendarFullDate(submitted_on);
        if (submitted_on === '01 January, 1900') submitted_on = '';


        let new_commercial_invoice_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_commercial_invoice_date);
        if (new_commercial_invoice_date !== '') new_commercial_invoice_date = this.DateService.convertToCalendarFullDate(new_commercial_invoice_date);
        if (new_commercial_invoice_date === '01 January, 1900') new_commercial_invoice_date = '';


        let new_date_on_bill_of_lading = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_date_on_bill_of_lading);
        if (new_date_on_bill_of_lading !== '') new_date_on_bill_of_lading = this.DateService.convertToCalendarFullDate(new_date_on_bill_of_lading);
        if (new_date_on_bill_of_lading === '01 January, 1900') new_date_on_bill_of_lading = '';


        let new_waybill_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_waybill_date);
        if (new_waybill_date !== '') new_waybill_date = this.DateService.convertToCalendarFullDate(new_waybill_date);
        if (new_waybill_date === '01 January, 1900') new_waybill_date = '';


        let new_sgd_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_sgd_date);
        if (new_sgd_date !== '') new_sgd_date = this.DateService.convertToCalendarFullDate(new_sgd_date);
        if (new_sgd_date === '01 January, 1900') new_sgd_date = '';


        let new_custom_release_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_custom_release_date);
        if (new_custom_release_date !== '') new_custom_release_date = this.DateService.convertToCalendarFullDate(new_custom_release_date);
        if (new_custom_release_date === '01 January, 1900') new_custom_release_date = '';


        let new_cbn_confirmation_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_cbn_confirmation_date);
        if (new_cbn_confirmation_date !== '') new_cbn_confirmation_date = this.DateService.convertToCalendarFullDate(new_cbn_confirmation_date);
        if (new_cbn_confirmation_date === '01 January, 1900') new_cbn_confirmation_date = '';


        let new_repatriation_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_repatriation_date);
        if (new_repatriation_date !== '') new_repatriation_date = this.DateService.convertToCalendarFullDate(new_repatriation_date);
        if (new_repatriation_date === '01 January, 1900') new_repatriation_date = '';


        let new_exporter_bank_letter_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_exporter_bank_letter_date);
        if (new_exporter_bank_letter_date !== '') new_exporter_bank_letter_date = this.DateService.convertToCalendarFullDate(new_exporter_bank_letter_date);
        if (new_exporter_bank_letter_date === '01 January, 1900') new_exporter_bank_letter_date = '';


        let new_cci_date = this.DateService.removeInvalidDateValue(this.state.eegClaimDetails.new_cci_date);
        if (new_cci_date !== '') new_cci_date = this.DateService.convertToCalendarFullDate(new_cci_date);
        if (new_cci_date === '01 January, 1900') new_cci_date = '';



        let eegclaimId = this.props.match.params.id;

        if (this.state.temp_claim_status === 'Active') {
            $(".switch").find("input[type=checkbox]").prop('checked', true);
        } else {
            $(".switch").find("input[type=checkbox]").prop('checked', false);
        }




        return [
          // <LoaderWrapper key={0}/>,
          <Header key={1} history={this.props.history} language={this.Language} />,

          // <!-- START MAIN -->
          <div id="main" key={2}>
            <div className="wrapper green lighten-5">
              <LeftSideBar active_menu_level_1={'EEG Claims'} active_menu_level_2={'Claims Summary'} history={this.props.history} language={this.Language} />
              {/* <!-- START CONTENT --> */}
              <section id="content">
                <BreadcrumbsWrapper
                  page_title={`${this.Language.PageTitle.EEGClaimDetail}`}
                  breadcrumbs={[this.Language.BreadCrumbLabel.EEGClaim, this.Language.BreadCrumbLabel.EEGClaimsSummary, this.Language.BreadCrumbLabel.EEGClaimDetail]}
                  links={[`/exporters/eeg/claims`, this.state.return_back_to_url, `/exporters/eeg/claims/${eegclaimId}`]}
                  language={this.Language}
                />
                {/* <!--start container--> */}
                <div className="container">
                  <div id="profile-page" className="section">
                    <div id="profile-page-content" className="row">
                      <div id="profile-page-sidebar" className="col s12 m5">
                        <div className="card cyan">
                          <div className="card-content white-text">
                            <span className="card-title">
                              {this.Language.DataField.NXPNumber}: <b>{this.state.eegClaimDetails.nxp_number}</b>{' '}
                            </span>
                            <p>
                              {' '}
                              {this.Language.DataField.Exporter}: <b>{this.state.eegClaimDetails.exporter_name} </b>
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.RegistrationNumber}: <b>{this.state.eegClaimDetails.registration_number}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.EEGRate}: <b>{this.Format.FormatLocaleNumber(this.state.eegClaimDetails.eeg_rate, this.Language.Lang, true, 2)} </b>
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.EEGPayable}:{' '}
                              <b>
                                {this.Language.DefaultCurrency} {this.Format.FormatLocaleNumber(this.state.eegClaimDetails.eeg_payable, this.Language.Lang, true, 2)}{' '}
                              </b>
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ExportYear}: <b>{this.state.eegClaimDetails.export_year} </b>
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ExportDate}: <b>{new_export_date}</b>
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.OriginatingBank}: <b>{this.state.eegClaimDetails.originating_nxp_bank}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.BankAddress}: <b>{this.state.eegClaimDetails.nxp_bank_address}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ApplicationDate}: <b>{new_claims_application_date}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ProductCategory}: <b>{this.state.eegClaimDetails.product_category}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Industry}: <b>{this.state.eegClaimDetails.industry}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Status}:{' '}
                              <b>{this.state.eegClaimDetails.status ? (this.state.eegClaimDetails.status === 'Active' ? this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.CreatedBy}: <b>{this.state.eegClaimDetails.created_by}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Submitted}:{' '}
                              <b>
                                {this.state.eegClaimDetails.is_submitted !== null
                                  ? this.state.eegClaimDetails.is_submitted === 'true' || this.state.eegClaimDetails.is_submitted === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.SubmittedBy}: <b>{this.state.eegClaimDetails.submitted_by}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.SubmittedOn}: <b>{submitted_on}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Approved}:{' '}
                              <b>
                                {this.state.eegClaimDetails.is_approved !== null
                                  ? this.state.eegClaimDetails.is_approved === 'true' || this.state.eegClaimDetails.is_approved === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ApprovedBy}: <b>{this.state.eegClaimDetails.approved_by}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ApprovedOn}: <b>{approved_at}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.CalledOver}:{' '}
                              <b>
                                {this.state.eegClaimDetails.is_called_over !== null
                                  ? this.state.eegClaimDetails.is_called_over === 'true' || this.state.eegClaimDetails.is_called_over === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.CBNConfirmation}:{' '}
                              <b>
                                {this.state.eegClaimDetails.cbn_confirmed !== null
                                  ? this.state.eegClaimDetails.cbn_confirmed === 'true' || this.state.eegClaimDetails.cbn_confirmed === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Paid}:{' '}
                              <b>
                                {this.state.eegClaimDetails.is_paid !== null
                                  ? this.state.eegClaimDetails.is_paid === 'true' || this.state.eegClaimDetails.is_paid === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                          </div>
                        </div>
                        <ul id="export-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">language</i>
                                <b className="teal-text"> {this.Language.PageLabel.LocationDetails} </b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ExportPoint}</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.export_point}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.DeliveryMode}</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.delivery_mode}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.DestinationCity}</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.destination_city}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.DestinationCountry}</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.destination_country}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.Customer}</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.customer_name}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CustomerAddress}</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.customer_address}</div>
                              {/* :;after */}
                            </div>
                          </li>
                        </ul>
                        <br />
                        <ul id="status-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">check_circle</i>
                                <b className="teal-text"> {this.Language.PageLabel.ClaimStatus}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ApplicationCaptured}:</div>
                              <div className="col s7 right-align">
                                {' '}
                                {this.state.eegClaimDetails.claim_data_captured !== null
                                  ? this.state.eegClaimDetails.claim_data_captured === 'true' || this.state.eegClaimDetails.claim_data_captured === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}{' '}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ApplicationReceived}:</div>
                              <div className="col s7 right-align">
                                {' '}
                                {this.state.eegClaimDetails.claim_application_received !== null
                                  ? this.state.eegClaimDetails.claim_application_received === 'true' || this.state.eegClaimDetails.claim_application_received === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}{' '}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ApplicationApproved}:</div>
                              <div className="col s7 right-align">
                                {' '}
                                {this.state.eegClaimDetails.claim_application_approved !== null
                                  ? this.state.eegClaimDetails.claim_application_approved === 'true' || this.state.eegClaimDetails.claim_application_approved === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}{' '}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CBNConfirmation}:</div>
                              <div className="col s7 right-align">
                                {' '}
                                {this.state.eegClaimDetails.cbn_confirmation !== null
                                  ? this.state.eegClaimDetails.cbn_confirmation === 'true' || this.state.eegClaimDetails.cbn_confirmation === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}{' '}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.Delisted}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.is_delisted !== null
                                  ? this.state.eegClaimDetails.is_delisted === 'true' || this.state.eegClaimDetails.is_delisted === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.DelistReason}:</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.delist_reason}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnlistReason}:</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.enlist_reason}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ValidatedOkForApproval}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.validated_ok_for_approval !== null
                                  ? this.state.eegClaimDetails.validated_ok_for_approval === 'true' || this.state.eegClaimDetails.validated_ok_for_approval === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ValidatedNotOkForApproval}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.validated_not_ok_for_approval !== null
                                  ? this.state.eegClaimDetails.validated_not_ok_for_approval === 'true' || this.state.eegClaimDetails.validated_not_ok_for_approval === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ClaimCleared}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.claim_cleared !== null
                                  ? this.state.eegClaimDetails.claim_cleared === 'true' || this.state.eegClaimDetails.claim_cleared === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ClaimApprovedForPayment}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.claim_approved_for_payment !== null
                                  ? this.state.eegClaimDetails.claim_approved_for_payment === 'true' || this.state.eegClaimDetails.claim_approved_for_payment === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ClaimApprovedForIssuance}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.claim_approved_for_issuance !== null
                                  ? this.state.eegClaimDetails.claim_approved_for_issuance === 'true' || this.state.eegClaimDetails.claim_approved_for_issuance === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ReportClaimStatus}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.report_claim_status !== null
                                  ? this.state.eegClaimDetails.report_claim_status === 'true' || this.state.eegClaimDetails.report_claim_status === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.Paid}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.is_paid !== null
                                  ? this.state.eegClaimDetails.is_paid === 'true' || this.state.eegClaimDetails.is_paid === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                        </ul>
                        <br />
                        <br />
                      </div>
                      <div id="profile-page-wall" className="col s12 m7">
                        <ul id="invoice-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">credit_card</i>
                                <b className="teal-text"> {this.Language.PageLabel.InvoiceDetails}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.NXPQuantity}:</div>
                              <div className="col s7 right-align">{this.Format.FormatLocaleNumber(this.state.eegClaimDetails.nxp_quantity, this.Language.Lang, true, 2)}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.InvoiceQuantity}:</div>
                              <div className="col s7 right-align">{this.Format.FormatLocaleNumber(this.state.eegClaimDetails.invoice_quantity, this.Language.Lang, true, 2)}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.MeasurementUnit}:</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.measurement_unit}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ExportValue}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.export_currency} {this.Format.FormatLocaleNumber(this.state.eegClaimDetails.export_value, this.Language.Lang, true, 2)}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ExportExchangeRate}:</div>
                              <div className="col s7 right-align">{this.Format.FormatLocaleNumber(this.state.eegClaimDetails.export_exchange_rate, this.Language.Lang, true, 2)}</div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.NairaExportValue}:</div>
                              <div className="col s7 right-align">
                                {' '}
                                {this.Language.DefaultCurrency} {this.Format.FormatLocaleNumber(this.state.eegClaimDetails.naira_export_value, this.Language.Lang, true, 2)}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CommercialInvoiceDate}:</div>
                              <div className="col s7 right-align">{new_commercial_invoice_date}</div>
                            </div>
                          </li>
                        </ul>
                        <br />
                        <ul id="other-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">dashboard</i>
                                <b className="teal-text"> {this.Language.PageLabel.OtherDetails}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.DateOnBillofLading}:</div>
                              <div className="col s7 right-align">{new_date_on_bill_of_lading}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.WaybillDate}:</div>
                              <div className="col s7 right-align">{new_waybill_date}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.SGDDate}:</div>
                              <div className="col s7 right-align">{new_sgd_date}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CustomReleaseDate}:</div>
                              <div className="col s7 right-align">{new_custom_release_date}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CBNConfirmationDate}:</div>
                              <div className="col s7 right-align">{new_cbn_confirmation_date}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.RepatriationDate}:</div>
                              <div className="col s7 right-align">{new_repatriation_date}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.Freight}:</div>
                              <div className="col s7 right-align">{this.Format.FormatLocaleNumber(this.state.eegClaimDetails.freight, this.Language.Lang)}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.AmountRepatriated}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.repatriation_currency} {this.Format.FormatLocaleNumber(this.state.eegClaimDetails.amount_repatriated, this.Language.Lang, true, 2)}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ExchangeRate}:</div>
                              <div className="col s7 right-align">{this.Format.FormatLocaleNumber(this.state.eegClaimDetails.exchange_rate, this.Language.Lang, true, 2)}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CrossRate}:</div>
                              <div className="col s7 right-align">{this.Format.FormatLocaleNumber(this.state.eegClaimDetails.cross_rate, this.Language.Lang, true, 2)}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.NairaEquivalent}:</div>
                              <div className="col s7 right-align">
                                {this.Language.DefaultCurrency} {this.Format.FormatLocaleNumber(this.state.eegClaimDetails.naira_equivalent, this.Language.Lang, true, 2)}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ExporterBankLetterDate}:</div>
                              <div className="col s7 right-align">{new_exporter_bank_letter_date}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ExporterBankAmountRepatriated}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.exporter_bank_repatriation_currency}{' '}
                                {this.Format.FormatLocaleNumber(this.state.eegClaimDetails.exporter_bank_amount_repatriated, this.Language.Lang, true, 2)}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CleanCertificateOfInspection}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.has_clean_certificate_of_inspection !== null
                                  ? this.state.eegClaimDetails.has_clean_certificate_of_inspection === 'true' || this.state.eegClaimDetails.has_clean_certificate_of_inspection === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CCINumber}:</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.cci_number}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.CCIDate}:</div>
                              <div className="col s7 right-align">{new_cci_date}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.NonOilExportCertificate}:</div>
                              <div className="col s7 right-align">
                                {this.state.eegClaimDetails.has_non_oil_export_certificate !== null
                                  ? this.state.eegClaimDetails.has_non_oil_export_certificate === 'true' || this.state.eegClaimDetails.has_non_oil_export_certificate === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.GeneralComments}:</div>
                              <div className="col s7 right-align">{this.state.eegClaimDetails.general_comments}</div>
                              {/* :;after */}
                            </div>
                          </li>
                        </ul>
                        <br />
                        <br />
                        <ul id="exported-items-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">airplanemode_active</i>
                                <b className="teal-text"> {this.Language.PageLabel.ExportedItems}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          {this.state.claim_exported_items_details}
                        </ul>
                        <br />
                        <br />
                        <ul id="exporter-credit-certificate-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">credit_card</i>
                                <b className="teal-text"> {this.Language.PageLabel.ExporterCreditCertificates}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          {this.state.preloader && (
                            <div className="progress teal lighten-4">
                              <div className="indeterminate teal"></div>
                            </div>
                          )}
                          {this.state.ecc_details}
                        </ul>
                        <br />
                        {this.state.paginationItem}
                      </div>
                    </div>
                    <br />
                    <br />
                    <Link className="waves-effect waves-light btn grey" to={this.state.return_back_to_url}>
                      <i className="material-icons left">arrow_back</i>
                      {this.Language.ButtonLabel.Back}
                    </Link>

                    {this.state.claim_update && (this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && (
                      <Link className="waves-effect waves-light btn" to={`/exporters/eeg/claims/edit/${this.state.eegClaimDetails.hash_id}${this.returnURLQuery}${this.state.return_back_to_url}`}>
                        <i className="material-icons left">edit</i>
                        {this.Language.ButtonLabel.Edit}
                      </Link>
                    )}

                    {this.state.claim_delete && (this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && (
                      <button
                        className="waves-effect waves-light btn red right"
                        id="delete_eeg_claim_button"
                        onClick={() => {
                          if (window.confirm(`${this.Language.ConfirmMessage.DeleteClaim}`)) {
                            this.onDelete();
                          }
                        }}>
                        <i className="material-icons left">delete</i>
                        {this.Language.ButtonLabel.Delete}
                      </button>
                    )}

                    <br />
                    <br />
                    {this.state.claim_update && (this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && (
                      <div className="collection-item border-none">
                        <div className="switch">
                          <label className="font-weight-600 black-text">
                            {this.Language.DataField.DeactivateEEGClaim}
                            <input type="checkbox" name="activate_switch" ref="activate_switch" onClick={this.checkBoxChange} />
                            <span className="lever"></span>
                            {this.Language.DataField.ActivateEEGClaim}
                          </label>
                        </div>
                      </div>
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                    {(this.state.eegClaimDetails.is_submitted === false || this.state.eegClaimDetails.is_submitted === 'false') && (
                      <Link
                        className={`waves-effect waves-light btn lighten-3 left ${this.state.submitbuttondisabled ? 'disabled' : ''}`}
                        to={`#!`}
                        onClick={() => {
                          if (window.confirm(`${this.Language.ConfirmMessage.SubmitEEGClaim} ${this.state.eegClaimDetails.nxp_number}`)) {
                            this.submitEEGClaim('submit_eeg_claim');
                          }
                        }}>
                        <i className="material-icons left">assignment_turned_in</i>
                        {this.state.submit_eeg_claim_button_caption || this.Language.ButtonLabel.SubmitEEGClaim}
                      </Link>
                    )}
                    {(this.state.eegClaimDetails.is_submitted === false || this.state.eegClaimDetails.is_submitted === 'false') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === false || this.state.eegClaimDetails.is_submitted === 'false') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === false || this.state.eegClaimDetails.is_submitted === 'false') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === false || this.state.eegClaimDetails.is_submitted === 'false') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === false || this.state.eegClaimDetails.is_submitted === 'false') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && (
                      <Link
                        className={`waves-effect waves-light btn left red ${this.state.submitbuttondisabled ? 'disabled' : ''}`}
                        to={`#!`}
                        onClick={() => {
                          if (window.confirm(`${this.Language.ConfirmMessage.UnsubmitEEGClaim} ${this.state.eegClaimDetails.nxp_number}`)) {
                            this.submitEEGClaim('unsubmit_eeg_claim');
                          }
                        }}>
                        {this.state.unsubmit_eeg_claim_button_caption || this.Language.ButtonLabel.UnsubmitEEGClaim}
                      </Link>
                    )}
                    {(this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && <br />}
                    {(this.state.eegClaimDetails.is_submitted === true || this.state.eegClaimDetails.is_submitted === 'true') && <br />}
                  </div>
                </div>
                {/* <!--end container--> */}
              </section>
              {/* <!-- END CONTENT --> */}
              <FloatingActionButton language={this.Language} />
              {/* <RightSideBar language={this.Language}/> */}
            </div>
            {/* <!-- END WRAPPER --> */}
          </div>,
          // <!-- END MAIN --> */
          // <!-- //////////////////////////////////////////////////////////////////////////// -->
          <Footer key={3} language={this.Language} />
        ];
    }
}

export default EEGClaimDetails;