import React, { Component } from 'react';


class DatatableFooterItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            footerItem: props.footerItem
        }
    }

    render(){

        return (
            <th rowSpan="1" colSpan="1">{this.state.footerItem}</th>
        )
    }
}

export default DatatableFooterItem;