import React, { Component } from 'react';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import HeaderNotificationItem from './HeaderNotificationItem';
import { Link } from 'react-router-dom';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';


class Header extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getNewNotifications = this.getNewNotifications.bind(this);
        this.handleSignout = this.handleSignout.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            hash_user: '',
            username: '',
            name: '',
            email: '',
            organisation: '',
            partner: '',
            user_role: '',
            notifications: [],
            message: '',
            // menu Access States
            notification: null,
            announcement: null,
            user: null,
            exporter: null,
            change_password: null,
            device: null,
            search: null,
            searchResults: null,
            preloader: null,
            language: props.language,
        };

        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.searchURL = URLService.prototype.getSearchURL();
        this.searchQuery = URLService.prototype.getSearchQuery();
    }


    componentWillMount() {
        this.getProfile();
    }


    componentDidMount() {
        this.getAccessScopes();
        // this.getNewNotifications();

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null) {
            this.setState({
                scopes: user_profile.scopes,
                hash_user: user_profile.user,
                username: user_profile.username,
                name: user_profile.name,
                email: user_profile.email,
                organisation: user_profile.organisation,
                partner: user_profile.partner,
                user_role: user_profile.user_role
            });
        }

    }


    getAccessScopes() {


        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'read:notification' || this.state.scopes['views'][i] === 'action:notification') this.setState({ notification: true }, () => {
                    this.getNewNotifications();
                });
                if (this.state.scopes['views'][i] === 'read:announcement' || this.state.scopes['views'][i] === 'action:announcement') this.setState({ announcement: true });
                if (this.state.scopes['views'][i] === 'read:user' || this.state.scopes['views'][i] === 'action:user') this.setState({ user: true });
                if (this.state.scopes['views'][i] === 'read:exporter' || this.state.scopes['views'][i] === 'action:exporter') this.setState({ exporter: true });
                if (this.state.scopes['views'][i] === 'create:change_password' || this.state.scopes['views'][i] === 'update:change_password' || this.state.scopes['views'][i] === 'action:change_password') this.setState({ change_password: true });
                if (this.state.scopes['views'][i] === 'read:device' || this.state.scopes['views'][i] === 'action:devices') this.setState({ device: true });
                if (this.state.scopes['views'][i] === 'read:search' || this.state.scopes['views'][i] === 'action:search') this.setState({ search: true });
            }

        };


    }


    getNewNotifications() {


        axios.get(`${this.apiURL}/api/v1/user/notifications/new/${this.state.hash_user}?tk=${this.Auth.getToken()}`)
            .then(response => {

                if (response.data.data) {
                    this.setState({ notifications: response.data.data })

                };

                this.setState({ message: response.data.message });
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    // window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange accent-3');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                //console.log(error.config);
            });
    }


    handleSignout() {

        const userOS = this.Browser.getOS();

        const newSignout = {
          username: this.state.username,
          hash_id: this.state.hash_user,
          device_name: this.state.username + "'s " + userOS,
          device_model: userOS,
          device_os: userOS,
          browser: this.Browser.getBrowser() || this.state.language.DefaultBrowser,
          location_used: this.Browser.getLocation() || this.state.language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
          app_source: 'Web',
          token: this.Auth.getToken()
        };


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/authenticate/signout/${this.state.hash_user}?tk=${this.Auth.getToken()}`,
            data: newSignout
        }).then(response => {
            console.log(response.data.status);

            window.Materialize.toast(response.data.message, 8000, 'rounded green');

        })
            .catch(function (error) {
                if (error.response) {

                    console.log(error.response.data.status);
                    console.log(error.response.data.message);
                    // window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4'); // commented so that the signout failed messages do not show

                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                //console.log(error.config);
            });


        this.Auth.removeLanguage();
        this.Auth.removeTimeZone();
        this.Auth.removeDashboards();
        this.Auth.logout();
        this.props.history.replace('/signin');
    }


    onSubmit(event) {

        event.preventDefault();

        let searchValue = this.refs.search.value;

        if (searchValue !== '' && searchValue !== null && typeof searchValue !== 'undefined') {

            searchValue = searchValue.split(" ");
            searchValue = searchValue.join('+'); // prepare the search query 

            this.props.history.push('/search?q=' + searchValue); // redirect to the search path with the search query

        }

    }




    render() {

        let notification_items = [];

        if (this.state.notifications) {
            notification_items = this.state.notifications.map((notification, i) => {
                return (
                    <HeaderNotificationItem key={notification.notification_id} user={this.state.hash_user} notification_hash_id={notification.hash_id} notification_time={notification.notification_time} message_title={notification.message_title} seconds_since_created={notification.seconds_since_created} DateService={this.DateService} />
                )
            })
        };

        let notification_count = notification_items.length;
        let notification_badge_caption = "";

        if (notification_count === 1) {
            notification_badge_caption = this.state.language.DataValue.new.one;
        };


        if (notification_count > 1) {
            notification_badge_caption = this.state.language.DataValue.new.multiple;
        };

        //determine the placeholder value to be used in the serach field
        let placeholder = this.state.language.Header.Explore;

        if (this.props.history.location.search && this.props.history.location.search !== '' && this.props.history.location.search !== null && typeof this.props.history.location.search !== 'undefined') {

            let search = this.props.history.location.search;
            let pathname = this.props.history.location.pathname;

            if (search.substring(0, 3) === this.searchQuery && pathname === this.searchURL) {
                let temp_placeholder = search.substring(3);
                temp_placeholder = temp_placeholder.split('+');
                temp_placeholder = temp_placeholder.join(" ");

                placeholder = temp_placeholder;
            }

        }


        const ulStyle = {
            whiteSpace: 'nowrap',
            opacity: 1,
            left: 1174.61,
            position: 'absolute',
            top: 64,
            display: 'none'
        };

        const ulStyle2 = {
            whiteSpace: 'nowrap',
            opacity: 1,
            left: 1373.64,
            position: 'absolute',
            top: 64,
            display: 'none'
        };


        return (
            // <!-- //////////////////////////////////////////////////////////////////////////// -->
            // <!-- START HEADER -->
            <header id="header" className="page-topbar">
                <noscript>
                    You need to enable JavaScript to run this app.
                </noscript>
                {/* <!-- start header nav--> */}
                <div className="navbar-fixed">
                    <nav className="navbar-color gradient-45deg-green-teal gradient-shadow">
                        <form className="" onSubmit={this.onSubmit.bind(this)}>
                            <div className="nav-wrapper">
                                {this.state.search && <div className="header-search-wrapper hide-on-med-and-down sideNav-lock">
                                    <Link to={''} onClick={this.onSubmit}><i className="material-icons">search</i></Link>
                                    <input type="text" name="search" ref="search" id="search" className="header-search-input z-depth-2" placeholder={placeholder} />
                                </div>}
                                <ul className="right hide-on-med-and-down">
                                    {this.state.notification && <li>
                                        <Link to={`/notifications/${this.state.hash_user}`} className="waves-effect waves-block waves-light notification-button" data-activates="notifications-dropdown">
                                            <i className="material-icons">notifications_none<small className="notification-badge">{notification_count}</small></i>
                                        </Link>
                                        <ul id="notifications-dropdown" className="dropdown-content" style={ulStyle}>
                                            <li><h6>{this.state.language.Header.Notifications}<span className="new badge" data-badge-caption={notification_badge_caption}>{notification_count}</span></h6></li>
                                            <li className="divider"></li>
                                            {notification_items}
                                        </ul>

                                    </li>
                                    }
                                    <li>
                                        <Link to={`/user/profile/${this.state.hash_user}`} className="waves-effect waves-block waves-light profile-button" data-activates="profile-dropdown">
                                            {/* <span className="avatar-status avatar-online"><img src="/assets/images/avatar/avatar-7.png" alt="avatar"/>
                                        <i></i>
                                    </span> */}
                                            <span>
                                                <i className="material-icons">account_circle</i>
                                            </span>
                                        </Link>
                                        <ul id="profile-dropdown" className="dropdown-content" style={ulStyle2}>
                                            {this.state.user && <li>
                                                <Link to={`/user/profile/${this.state.hash_user}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">account_circle</i>
                                                    {this.state.name}
                                                </Link>
                                            </li>
                                            }
                                            {(this.state.organisation !== '' && this.state.organisation !== null) && <li>
                                                <Link to={`/exporter/profile/${this.state.organisation}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">account_balance</i>
                                                    {this.state.language.Header.Organisation}
                                                </Link>
                                            </li>
                                            }
                                            {(this.state.partner !== '' && this.state.partner !== null) && <li>
                                                <Link to={`/partner/profile/${this.state.partner}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">location_city</i>
                                                    {this.state.language.Header.PartnerAgency}
                                                </Link>
                                            </li>
                                            }
                                            <li>
                                                <Link to={"/support/contacts"} className="grey-text text-darken-1">
                                                    <i className="material-icons">live_help</i>
                                                    {this.state.language.Header.Help}
                                                </Link>
                                            </li>
                                            <li className="divider"></li>
                                            {this.state.change_password && <li>
                                                <Link to={`/password/change/user/${this.state.hash_user}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">lock_outline</i>
                                                    {this.state.language.Header.ChangePassword}
                                                </Link>
                                            </li>
                                            }
                                            <li>
                                                <a href={"#!"} className="grey-text text-darken-1" onClick={this.handleSignout.bind(this)}>
                                                    <i className="material-icons">power_settings_new</i>
                                                    {this.state.language.Header.SignOut}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    {this.state.user && <li>
                                        <Link to={`/user/profile/${this.state.hash_user}`} data-activates="settings-dropdown" className="waves-effect waves-block waves-light notification-button">
                                            <i className="material-icons">settings</i>
                                        </Link>
                                        <ul id="settings-dropdown" className="dropdown-content" style={ulStyle2}>
                                            {this.state.notification && <li>
                                                <Link to={`/settings/notification/${this.state.hash_user}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">announcement</i>
                                                    {this.state.language.Header.NotifcationSettings}
                                                </Link>
                                            </li>
                                            }
                                            {this.state.user && <li>
                                                <Link to={`/user/devices/${this.state.hash_user}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">devices</i>
                                                    {this.state.language.Header.Devices}
                                                </Link>
                                            </li>
                                            }
                                            {this.state.user && <li>
                                                <Link to={`/settings/security/${this.state.hash_user}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">security</i>
                                                    {this.state.language.Header.SecuritySettings}
                                                </Link>
                                            </li>
                                            }
                                            {this.state.user && <li>
                                                <Link to={`/settings/language/timezone/${this.state.hash_user}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">language</i>
                                                    {this.state.language.Header.LanguageAndTimeZone}
                                                </Link>
                                            </li>
                                            }
                                            {this.state.user && <li>
                                                <Link to={`/user/profile/signins/${this.state.hash_user}`} className="grey-text text-darken-1">
                                                    <i className="material-icons">schedule</i>
                                                    {this.state.language.Header.SigninActivities}
                                                </Link>
                                            </li>
                                            }
                                        </ul>
                                    </li>
                                    }

                                    {/* 
{this.state.user &&         <li>
                                <Link to={"#!"} data-activates="chat-out" className="waves-effect waves-block waves-light chat-collapse tooltipped" data-position="down" data-delay="50" data-tooltip={this.state.language.ToolTip.OpenMenu}>
                                    <i className="material-icons">format_indent_increase</i>
                                </Link>
                            </li>
}
*/}
                                    <li>
                                        <a href={"#!"} id="signout_b" className="waves-effect waves-block waves-light tooltipped" onClick={this.handleSignout.bind(this)} data-position="down" data-delay="50" data-tooltip={this.state.language.ToolTip.SignOut}>
                                            <i className="material-icons">power_settings_new</i>
                                        </a>
                                    </li>
                                </ul>
                                {/* <!-- notifications-dropdown--> */}
                                {/* <!-- settings-dropdown--> */}
                                {/* <!-- profile-dropdown--> */}
                                {/* <!-- chat sideNav--> */}
                            </div>
                        </form>
                    </nav>
                </div>

            </header>
            // <!-- END HEADER -->
            // <!-- //////////////////////////////////////////////////////////////////////////// -->
        )
    }
}

export default Header;