import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditPickList extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updatePickList = this.updatePickList.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getPickList = this.getPickList.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            item_name: '',
            item_name_temp_old: '',
            item_code: '',
            item_description: '',
            pick_list_category: '',
            pick_list_category_temp_old: '',
            pick_list_type: '',
            is_priority: false,
            status: '',
            status_active: false,
            last_updated_by: '',
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_pick_list_category: [],
            select_field_values_pick_list_type: [],
            preloader: true,
            logged_in_username: '',
            scopes: {},
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EditPicklistItem + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getPickList();

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({});
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:pick_lists', 'action:pick_lists'], 'view') === false)) {
            this.props.history.replace('/settings/picklists');
        } else {
            this.getDropdownValues("pick_list_category,pick_list_type");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getPickList() {
        let picklistId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/settings/picklists/${picklistId}?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    item_name: response.data.data[0].item_name || '',
                    item_name_temp_old: response.data.data[0].item_name || '',
                    item_code: response.data.data[0].item_code || '',
                    item_description: response.data.data[0].item_description || '',
                    pick_list_category: response.data.data[0].pick_list_category,
                    pick_list_category_temp_old: response.data.data[0].pick_list_category,
                    pick_list_type: response.data.data[0].pick_list_type,
                    is_priority: (response.data.data[0].is_priority === 'true' || response.data.data[0].is_priority === true) ? true : false || false,
                    status: response.data.data[0].status,
                    last_updated_by: response.data.data[0].last_updated_by,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                });



                if (this.state.status === 'Active') {
                    this.setState({ status_active: true });
                } else {
                    this.setState({ status_active: false });
                };



                this.setState({ response_status: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }

        if (name === 'is_priority') { this.setState({ [name]: !this.state.is_priority }); }

    }




    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SavePickList} ${this.refs.item_name.value}`)) {

            let item_name = this.refs.item_name.value;

            let pick_list_category = this.refs.pick_list_category.value;
            if (pick_list_category === "Select Picklist Category") {
                pick_list_category = "";
            } else {
                pick_list_category = pick_list_category.trim().toLowerCase();
            }

            let item_name_temp_old = this.state.item_name_temp_old;
            let pick_list_category_temp_old = this.state.pick_list_category_temp_old;

            let pick_list_type = this.refs.pick_list_type.value;
            if (pick_list_type === "Select Picklist Type") pick_list_type = "";


            if (pick_list_category === "pick_list_category") item_name = item_name.trim().toLowerCase(); // force the item name to lower case if it is a pick list category item
            if (pick_list_type === "pick_list_type") item_name = item_name.trim(); // trim the item name if it is a pick list type item

            let item_code = this.refs.item_code.value;
            if (pick_list_category === "pick_list_category") item_code = item_code.trim().toLowerCase(); // force the item code to lower case if it is a pick list category item
            if (pick_list_type === "pick_list_type") item_name = item_name.trim(); // trim the item name if it is a pick list type item


            let item_description = this.refs.item_description.value;

            let is_priority = this.refs.is_priority.checked;


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.PickLists.EditErrorTitle, [item_name, item_code, pick_list_category, pick_list_type], [this.Language.DataField.ItemName, this.Language.DataField.ItemCode, this.Language.DataField.PicklistCategory, this.Language.DataField.PicklistType], ["isRequired", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

                if (this.state.error_messages === null) {

                    if (pick_list_category === "Select Picklist Category") pick_list_category = null;
                    if (pick_list_type === "Select Picklist Type") pick_list_type = null;


                    const userOS = this.Browser.getOS();


                    //make call to database 
                    const newPickListItem = {
                        item_name: item_name,
                        item_name_temp_old: item_name_temp_old,
                        item_code: item_code,
                        item_description: item_description,
                        pick_list_category: pick_list_category,
                        pick_list_category_temp_old: pick_list_category_temp_old,
                        pick_list_type: pick_list_type,
                        is_priority: is_priority,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }


                    

                    this.setState({ preloader: true });
                    document.getElementById("update_pick_list_button").setAttribute("disabled", true);

                    this.updatePickList(newPickListItem);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_pick_list_category = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'pick_list_category') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_pick_list_category: select_field_values_pick_list_category });



                        const select_field_values_pick_list_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'pick_list_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_pick_list_type: select_field_values_pick_list_type });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    updatePickList(newPickListItem) {

        let picklistId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/settings/picklists/${picklistId}`,
            data: newPickListItem
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.state.message, 8000, 'rounded green');

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.PickListValues.UpdateSuccess + newPickListItem.item_name, 8000, 'rounded green');

            })


            this.setState({ preloader: null });
            document.getElementById("update_pick_list_button").removeAttribute("disabled");

            this.props.history.push('/settings/picklists');

        })
            .catch(error => {
                if (error.response) {

                    

                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.PickListValues.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_pick_list_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_pick_list_button").setAttribute("disabled", true);

        let item_name = this.state.item_name;
        let pick_list_category = this.state.pick_list_category;
        let logged_in_username = this.state.logged_in_username;
        let picklistId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deletePicklistItem = {
            item_name: item_name,
            pick_list_category: pick_list_category,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/settings/picklists/${picklistId}`,
            data: deletePicklistItem
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                window.Materialize.toast(this.Language.NodeMessage.PickListValues.DeleteSuccess + deletePicklistItem.item_name, 8000, 'rounded green');
            })


            document.getElementById("delete_pick_list_button").removeAttribute("disabled");
            this.props.history.push('/settings/picklists');

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DoesNotExist, 8000, 'rounded deep-orange darken-4');




                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_pick_list_button").removeAttribute("disabled");

            });

    }



    render() {



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Picklist Values"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditPicklistItem}`} breadcrumbs={[this.Language.BreadCrumbLabel.Settings, this.Language.BreadCrumbLabel.PicklistValues, this.Language.BreadCrumbLabel.EditPicklistItem]} links={[`/settings/modules`, `/settings/picklists`, `/settings/picklists/edit`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditPicklistItem}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>




                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="item_name" type="text" ref="item_name" value={this.state.item_name} onChange={this.handleInputChange} />
                                                            <label htmlFor="item_name" className={(this.state.item_name && this.state.item_name !== null && typeof this.state.item_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ItemName} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="item_code" type="text" ref="item_code" data-length="20" value={this.state.item_code} onChange={this.handleInputChange} />
                                                            <label htmlFor="item_code" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + "20"} className={(this.state.item_code && this.state.item_code !== null && typeof this.state.item_code !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ItemCode} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="item_description" className="materialize-textarea" data-length="300" ref="item_description" value={this.state.item_description} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="item_description" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.item_description && this.state.item_description !== null && typeof this.state.item_description !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ItemDescription}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-pick_list_category" name="pick_list_category" ref="pick_list_category" defaultValue={this.state.pick_list_category}>
                                                                    {this.state.pick_list_category && <option value={this.state.pick_list_category} selected>{this.state.pick_list_category}</option>}
                                                                    <option value="Select Picklist Category">{this.Language.DataFieldDefault.SelectPicklistCategory}</option>
                                                                    {this.state.select_field_values_pick_list_category.map((pick_list_value, i) => {
                                                                        return (
                                                                            pick_list_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.PicklistCategory} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-pick_list_type" className="initialized" ref="pick_list_type" defaultValue={this.state.pick_list_type}>
                                                                    {this.state.pick_list_type && <option value={this.state.pick_list_type} selected>{this.state.pick_list_type}</option>}
                                                                    <option value="Select Picklist Type">{this.Language.DataFieldDefault.SelectPicklistType}</option>
                                                                    {this.state.select_field_values_pick_list_type.map((pick_list_type_value, i) => {
                                                                        return (
                                                                            pick_list_type_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.PicklistType} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s1">
                                                            {/* this is to give a space before the checkbox */}
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="is_priority" ref="is_priority" checked={this.state.is_priority} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="is_priority">{this.Language.DataField.IsPriority}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivatePicklistItem}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivatePicklistItem}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />

                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_pick_list_button">
                                                                {this.Language.ButtonLabel.UpdatePicklistItem}
                                                            </button>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/settings/picklists`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_pick_list_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeletePickList} ${this.state.item_name}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditPickList;