import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateAnnouncement = this.updateAnnouncement.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getAnnouncement = this.getAnnouncement.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            announcement_code: '',
            announcement_title: '',
            announcement: '',
            announcement_type: '',
            announcement_category: '',
            announcement_status: '',
            publish_status: '',
            published_to: '',
            requires_approval: false,
            is_approved: false,
            approved_by: '',
            hash_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_announcement_type: [],
            select_field_values_announcement_category: [],
            select_field_values_announcement_status: [],
            select_field_values_publish_status: [],
            select_field_values_published_to: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        this.getProfile();
        this.checkAccessRight();
        this.getAnnouncement();

        const monthsFull = this.Language.DatePicker.monthsFull.split(",");
        const monthsShort = this.Language.DatePicker.monthsShort.split(",");
        const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
        const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
        const today = this.Language.DatePicker.today;
        const clear = this.Language.DatePicker.clear;
        const close = this.Language.DatePicker.close;
        const labelMonthNext = this.Language.DatePicker.labelMonthNext;
        const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
        const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
        const labelYearSelect = this.Language.DatePicker.labelYearSelect;


        window.$('.datepicker').pickadate({
            monthsFull: monthsFull,
            monthsShort: monthsShort,
            weekdaysFull: weekdaysFull,
            weekdaysShort: weekdaysShort,
            today: today,
            clear: clear,
            close: close,
            labelMonthNext: labelMonthNext,
            labelMonthPrev: labelMonthPrev,
            labelMonthSelect: labelMonthSelect,
            labelYearSelect: labelYearSelect
            // firstDay: 1,
            // format: 'dd mmmm yyyy',
            // formatSubmit: 'yyyy/mm/dd',
            // selectMonths: true,
            // selectYears: true,
            // selectMonths: true, // Creates a dropdown to control month
            // selectYears: 15, // Creates a dropdown of 15 years to control year,
            // closeOnSelect: false, // Close upon selecting a date,
            // container: undefined, // ex. 'body' will append picker to body
        });

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:announcements', 'action:announcements'], 'view') === false)) {
            this.props.history.replace('/annoucements/all');
        } else {
            this.getDropdownValues("announcement_type,announcement_category,announcement_status,publish_status,published_to");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getAnnouncement() {

        let announcementId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/user/announcements/${announcementId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({
                    announcement_code: response.data.data[0].announcement_code || '',
                    announcement_title: response.data.data[0].announcement_title || '',
                    announcement: response.data.data[0].announcement || '',
                    announcement_type: response.data.data[0].announcement_type || '',
                    announcement_category: response.data.data[0].announcement_category || '',
                    announcement_date: this.DateService.convertToCalendarFullDate(response.data.data[0].announcement_date),
                    announcement_status: response.data.data[0].announcement_status || '',
                    publish_status: response.data.data[0].publish_status || '',
                    published_to: response.data.data[0].published_to || '',
                    requires_approval: (response.data.data[0].requires_approval === true || response.data.data[0].requires_approval === 'true') ? true : false || false,
                    is_approved: (response.data.data[0].is_approved === true || response.data.data[0].is_approved === 'true') ? true : false || false,
                    approved_by: response.data.data[0].approved_by || '',
                    hash_id: response.data.data[0].hash_id || '',
                    status: response.data.data[0].status || '',
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                  document.title = this.Language.PageTitle.EditAnnouncement.BeginningPhrase + ' ' + this.state.announcement_code + ' ' + this.Language.PageTitle.EditAnnouncement.EndPhrase + ' - ' + this.Language.EEGWebPortal;
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                })

                this.setState({ message: response.data.message }, () => {
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


        if (name === 'requires_approval') { this.setState({ [name]: !this.state.requires_approval }); }
        if (name === 'is_approved') { this.setState({ [name]: !this.state.is_approved }); }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveAnnouncement}`)) {


            let announcement_code = this.refs.announcement_code.value;
            let announcement_title = this.refs.announcement_title.value;
            let announcement = this.refs.announcement.value;

            let announcement_type = this.refs.announcement_type.value;
            if (announcement_type === "Select Announcement Type") announcement_type = '';

            let announcement_category = this.refs.announcement_category.value;
            if (announcement_category === "Select Announcement Category") announcement_category = '';

            let announcement_status = this.refs.announcement_status.value;
            if (announcement_status === "Select Announcement Status") announcement_status = '';

            let publish_status = this.refs.publish_status.value;
            if (publish_status === "Select Publish Status") publish_status = '';

            let published_to = this.refs.published_to.value;
            if (published_to === "Select Publish To Option") published_to = '';

            let announcement_date = this.DateService.convertToFullDate(this.refs.announcement_date.value);


            let requires_approval = true;
            let is_approved = this.refs.is_approved.checked;

            let approved_by = this.state.approved_by;
            if (is_approved) approved_by = this.state.logged_in_username;


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.Announcement.EditErrorTitle, [announcement_code, announcement_title, announcement, announcement_type, announcement_category, announcement_date, announcement_status, publish_status, published_to], [this.Language.DataField.AnnouncementCode, this.Language.DataField.AnnouncementTitle, this.Language.DataField.Announcement, this.Language.DataField.AnnouncementType, this.Language.DataField.AnnouncementCategory, this.Language.DataField.AnnouncementDate, this.Language.DataField.AnnouncementStatus, this.Language.DataField.PublishStatus, this.Language.DataField.PublishedTo], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequiredDate", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

                if (this.state.error_messages === null) {

                    if (announcement_type === "Select Announcement Type") announcement_type = null;
                    if (announcement_category === "Select Announcement Category") announcement_category = null;
                    if (announcement_status === "Select Announcement Status") announcement_status = null;
                    if (publish_status === "Select Publish Status") publish_status = null;
                    if (published_to === "Select Publish To Option") published_to = null;


                    const userOS = this.Browser.getOS();


                    //make call to database 
                    const newAnnouncement = {
                        announcement_code: announcement_code,
                        announcement_title: announcement_title,
                        announcement: announcement,
                        announcement_type: announcement_type,
                        announcement_category: announcement_category,
                        announcement_date: announcement_date,
                        announcement_status: announcement_status,
                        publish_status: publish_status,
                        published_to: published_to,
                        requires_approval: requires_approval,
                        is_approved: is_approved,
                        approved_by: approved_by,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }



                    this.setState({ preloader: true });
                    document.getElementById("update_announcement_button").setAttribute("disabled", true);

                    this.updateAnnouncement(newAnnouncement);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });







        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {



                        const select_field_values_announcement_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'announcement_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_announcement_type: select_field_values_announcement_type });



                        const select_field_values_announcement_category = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'announcement_category') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_announcement_category: select_field_values_announcement_category });



                        const select_field_values_announcement_status = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'announcement_status') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_announcement_status: select_field_values_announcement_status });



                        const select_field_values_publish_status = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'publish_status') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_publish_status: select_field_values_publish_status });



                        const select_field_values_published_to = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'published_to') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_published_to: select_field_values_published_to });

                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    updateAnnouncement(newAnnouncement) {

        let announcementId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/user/announcements/${announcementId}`,
            data: newAnnouncement
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.Announcement.UpdateSuccess + newAnnouncement.announcement_code, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_announcement_button").removeAttribute("disabled");

            this.props.history.push('/announcements/all');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.Announcement.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.Announcement.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.Announcement.UpdateConflict, 8000, 'rounded deep-orange darken-4');


                    }

                    //   this.props.history.push('/announcements/all');


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("update_announcement_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_announcement_button").setAttribute("disabled", true);

        let announcement_code = this.state.announcement_code;
        let logged_in_username = this.state.logged_in_username;
        let announcementId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteAnnouncement = {
            announcement_code: announcement_code,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/user/announcements/${announcementId}`,
            data: deleteAnnouncement
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                window.Materialize.toast(this.Language.NodeMessage.Announcement.DeleteSuccess + deleteAnnouncement.announcement_code, 8000, 'rounded green');
            })


            document.getElementById("delete_announcement_button").removeAttribute("disabled");
            this.props.history.replace('/announcements/all');

        })
            .catch(error => {
                if (error.response) {



                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.Announcement.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                document.getElementById("delete_announcement_button").removeAttribute("disabled");

            });

    }




    render() {


        // let announcementId = this.props.match.params.id;



        return [
          // <LoaderWrapper key={0}/>,
          <Header key={1} history={this.props.history} language={this.Language} />,

          // <!-- START MAIN -->
          <div id="main" key={2}>
            <div className="wrapper green lighten-5">
              <LeftSideBar active_menu_level_1={'Notifications'} active_menu_level_2={'Announcements'} history={this.props.history} language={this.Language} />
              {/* <!-- START CONTENT --> */}
              <section id="content">
                <BreadcrumbsWrapper
                  page_title={`${this.Language.PageTitle.EditAnnouncement.BeginningPhrase} ${this.state.announcement_code} ${this.Language.PageTitle.EditAnnouncement.EndPhrase}`}
                  breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.Announcements, this.Language.BreadCrumbLabel.EditAnnouncement]}
                  links={[`/`, `/announcements/all`, `/announcements/all/edit`]}
                  language={this.Language}
                />
                {/* <!--start container--> */}
                <div className="container">
                  <div className="row">
                    <div className="col s12 l12">
                      {this.state.error_messages}

                      <div className="card-panel">
                        {this.state.preloader && (
                          <div className="progress teal lighten-4">
                            <div className="indeterminate teal"></div>
                          </div>
                        )}

                        <h4 className="header2">{this.Language.PageLabel.EditAnnouncement}</h4>
                        <div className="row">
                          <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                            <div className="row">
                              <div className="input-field col s8">
                                <i className="material-icons prefix">announcement</i>
                                <input id="announcement_title" name="announcement_title" type="text" ref="announcement_title" value={this.state.announcement_title} onChange={this.handleInputChange} />
                                <label
                                  htmlFor="announcement_title"
                                  className={
                                    (this.state.announcement_title && this.state.announcement_title !== null && typeof this.state.announcement_title !== 'undefined') ||
                                    this.state.announcement_title === 0
                                      ? 'active'
                                      : ''
                                  }>
                                  {this.Language.DataField.AnnouncementTitle} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input id="announcement_code" name="announcement_code" type="text" ref="announcement_code" value={this.state.announcement_code} onChange={this.handleInputChange} />
                                <label
                                  htmlFor="announcement_code"
                                  className={
                                    (this.state.announcement_code && this.state.announcement_code !== null && typeof this.state.announcement_code !== 'undefined') || this.state.announcement_code === 0
                                      ? 'active'
                                      : ''
                                  }>
                                  {this.Language.DataField.AnnouncementCode} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s12">
                                <textarea id="announcement" className="materialize-textarea" ref="announcement" value={this.state.announcement} onChange={this.handleInputChange}></textarea>
                                <label htmlFor="announcement" className={this.state.announcement && this.state.announcement !== null && typeof this.state.announcement !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.Announcement}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s6">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select className="initialized" name="announcement_type" id="announcement_type" ref="announcement_type">
                                    {this.state.announcement_type && (
                                      <option value={this.state.announcement_type} selected>
                                        {this.state.announcement_type}
                                      </option>
                                    )}
                                    <option value="Select Announcement Type">{this.Language.DataFieldDefault.SelectAnnouncementType}</option>
                                    {this.state.select_field_values_announcement_type.map((announcement_type_value, i) => {
                                      return announcement_type_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.AnnouncementType} *</label>
                              </div>
                              <div className="input-field col s6">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select className="initialized" name="announcement_category" id="announcement_category" ref="announcement_category">
                                    {this.state.announcement_category && (
                                      <option value={this.state.announcement_category} selected>
                                        {this.state.announcement_category}
                                      </option>
                                    )}
                                    <option value="Select Announcement Category">{this.Language.DataFieldDefault.SelectAnnouncementCategory}</option>
                                    {this.state.select_field_values_announcement_category.map((announcement_category_value, i) => {
                                      return announcement_category_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.AnnouncementCategory} *</label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s8">
                                <input
                                  type="text"
                                  className="datepicker picker__input"
                                  ref="announcement_date"
                                  value={this.state.announcement_date}
                                  readOnly="false"
                                  id="announcement_date"
                                  tabIndex="-1"
                                  aria-haspopup="true"
                                  aria-readonly="false"
                                  aria-owns="announcement_date_root"
                                />
                                <label
                                  htmlFor="announcement_date"
                                  className={this.state.announcement_date && this.state.announcement_date !== null && typeof this.state.announcement_date !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.AnnouncementDate} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s4">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select className="initialized" name="announcement_status" id="announcement_status" ref="announcement_status">
                                    {this.state.announcement_status && (
                                      <option value={this.state.announcement_status} selected>
                                        {this.state.announcement_status}
                                      </option>
                                    )}
                                    <option value="Select Announcement Status">{this.Language.DataFieldDefault.SelectAnnouncementStatus}</option>
                                    {this.state.select_field_values_announcement_status.map((announcement_status_value, i) => {
                                      return announcement_status_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.AnnouncementStatus} *</label>
                              </div>
                              <div className="input-field col s4">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select className="initialized" name="publish_status" id="publish_status" ref="publish_status">
                                    {this.state.publish_status && (
                                      <option value={this.state.publish_status} selected>
                                        {this.state.publish_status}
                                      </option>
                                    )}
                                    <option value="Select Publish Status">{this.Language.DataFieldDefault.SelectPublishStatus}</option>
                                    {this.state.select_field_values_publish_status.map((publish_status_value, i) => {
                                      return publish_status_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.PublishStatus} *</label>
                              </div>
                              <div className="input-field col s4">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select className="initialized" name="published_to" id="published_to" ref="published_to">
                                    {this.state.published_to && (
                                      <option value={this.state.published_to} selected>
                                        {this.state.published_to}
                                      </option>
                                    )}
                                    <option value="Select Publish To Option">{this.Language.DataFieldDefault.SelectPublishToOption}</option>
                                    {this.state.select_field_values_published_to.map((published_to_value, i) => {
                                      return published_to_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.PublishedTo} *</label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="col s3">
                                <p>
                                  <input type="checkbox" id="is_approved" ref="is_approved" checked={this.state.is_approved} onChange={this.handleCheckBoxChange} />
                                  <label htmlFor="is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s1">{/* this div is used to give a space between the elements */}</div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.DeactivateAnnouncement}
                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                    <span className="lever"></span>
                                    {this.Language.DataField.ActivateAnnouncement}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="row">
                              <div className="input-field col s6">
                                <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_announcement_button">
                                  {this.Language.ButtonLabel.SaveChanges}
                                </button>
                              </div>
                              {/* ::after */}
                              <div className="input-field col s4">
                                <Link className="waves-effect waves-light btn grey right" to={`/announcements/all`}>
                                  <i className="material-icons left">cancel</i>
                                  {this.Language.ButtonLabel.Cancel}
                                </Link>
                              </div>
                              <div className="input-field col s2">
                                <div
                                  className="waves-effect waves-light btn red"
                                  id="delete_announcement_button"
                                  onClick={() => {
                                    if (window.confirm(`${this.Language.ConfirmMessage.DeleteAnnouncement} ${this.state.announcement_code}`)) {
                                      this.onDelete();
                                    }
                                  }}>
                                  <i className="material-icons left">delete</i>
                                  {this.Language.ButtonLabel.Delete}
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                          </form>
                          {/* ::after */}
                        </div>
                      </div>
                    </div>
                    {/* ::after */}
                  </div>
                </div>
                {/* <!--end container--> */}
              </section>
              {/* <!-- END CONTENT --> */}
              <FloatingActionButton language={this.Language} />
              {/* <RightSideBar language={this.Language}/> */}
            </div>
            {/* <!-- END WRAPPER --> */}
          </div>,
          // <!-- END MAIN --> */
          // <!-- //////////////////////////////////////////////////////////////////////////// -->
          <Footer key={3} language={this.Language} />
        ];
    }
}

export default EditAnnouncement;