import React, { Component } from 'react';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditUser extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getUser = this.getUser.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.state = {
            username: '',
            user_role: '',
            user_role_id: '',
            exporter_name: '',
            exporter_id: '',
            organisation_name: '',
            partner_id: '',
            title: '',
            prefix: '',
            suffix: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            date_of_registration: '',
            language_id: '',
            time_zone_region: '',
            time_zone_region_temp: '',
            phone: '',
            email: '',
            email_signature: '',
            user_type: '',
            user_type_id: '',
            internal_user: false,
            partner_user: false,
            enable_sms: false,
            enable_email: false,
            enable_mobile_app: false,
            enable_offline_user: false,
            status: '',
            status_active: false,
            is_frozen: false,
            is_password_locked: false,
            password_change: false,
            two_step_verification: false,
            sign_in_method: '',
            signin_security_questions: false,
            signin_code: false,
            signin_na: false,
            enable_notifications: false,
            receive_notification_as_sms: false,
            receive_notification_as_email: false,
            is_approved_initial_value: false,
            is_approved: false,
            approved_by: '',
            approved_at: '',
            last_updated_by: '',
            profile_is_visible: false,
            password_expiry_date: '',
            recovery_email: '',
            recovery_phone: '',
            email_security_risk: '',
            email_account_activity: '',
            phone_security_risk: '',
            phone_account_activity: '',
            security_question1: '',
            security_answer1: '',
            security_question2: '',
            security_answer2: '',
            security_question3: '',
            security_answer3: '',
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            pick_list_values_time_zone: [],
            select_field_values_user_role: [],
            select_field_values_user_type: [],
            select_field_values_exporter: [],
            select_field_values_organisation: [],
            select_field_values_title: [],
            select_field_values_language: [],
            select_field_values_time_zone_region: [],
            preloader: true,
            hash_user: '',
            logged_in_username: '',
            scopes: {},
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        this.getProfile();
        this.getUser();
        

        const monthsFull = this.Language.DatePicker.monthsFull.split(",");
        const monthsShort = this.Language.DatePicker.monthsShort.split(",");
        const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
        const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
        const today = this.Language.DatePicker.today;
        const clear = this.Language.DatePicker.clear;
        const close = this.Language.DatePicker.close;
        const labelMonthNext = this.Language.DatePicker.labelMonthNext;
        const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
        const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
        const labelYearSelect = this.Language.DatePicker.labelYearSelect;


        window.$('.datepicker').pickadate({
            monthsFull: monthsFull,
            monthsShort: monthsShort,
            weekdaysFull: weekdaysFull,
            weekdaysShort: weekdaysShort,
            today: today,
            clear: clear,
            close: close,
            labelMonthNext: labelMonthNext,
            labelMonthPrev: labelMonthPrev,
            labelMonthSelect: labelMonthSelect,
            labelYearSelect: labelYearSelect
            // firstDay: 1,
            // format: 'dd mmmm yyyy',
            // formatSubmit: 'yyyy/mm/dd',
            // selectMonths: true,
            // selectYears: true,
            // selectMonths: true, // Creates a dropdown to control month
            // selectYears: 15, // Creates a dropdown of 15 years to control year,
            // closeOnSelect: false, // Close upon selecting a date,
            // container: undefined, // ex. 'body' will append picker to body
        });

        this.setState({ pick_list_values_time_zone: this.DateService.getTimezoneList(this.Language.Lang) });

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:users', 'action:users'], 'view') === false) || (this.state.hash_user === this.props.match.params.id)) {
            this.props.history.replace('/users');
        } else {
            this.getDropdownValues("user_role,user_type,title,language,exporter,organisation");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }

    getUser() {
        let userId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/users/${userId}?tk=${this.Auth.getToken()}`)
            .then(response => {

                this.setState({
                    username: response.data.data[0].username || '',
                    user_role: response.data.data[0].user_role,
                    user_role_id: response.data.data[0].user_role_id,
                    exporter_name: response.data.data[0].exporter_name,
                    exporter_id: response.data.data[0].exporter_id,
                    organisation_name: response.data.data[0].organisation_name,
                    partner_id: response.data.data[0].partner_id,
                    title: response.data.data[0].title,
                    prefix: response.data.data[0].prefix || '',
                    suffix: response.data.data[0].suffix || '',
                    first_name: response.data.data[0].first_name || '',
                    middle_name: response.data.data[0].middle_name || '',
                    last_name: response.data.data[0].last_name || '',
                    date_of_registration: this.DateService.convertToCalendarFullDate(response.data.data[0].date_of_registration),
                    language_id: response.data.data[0].language_id,
                    time_zone_region: response.data.data[0].time_zone_region,
                    phone: response.data.data[0].phone || '',
                    email: response.data.data[0].email || '',
                    email_signature: response.data.data[0].email_signature || '',
                    user_type: response.data.data[0].user_type,
                    user_type_id: response.data.data[0].user_type_id,
                    internal_user: (response.data.data[0].internal_user === 'true' || response.data.data[0].internal_user === true) ? true : false || false,
                    partner_user: (response.data.data[0].partner_user === 'true' || response.data.data[0].partner_user === true) ? true : false || false,
                    enable_sms: (response.data.data[0].enable_sms === 'true' || response.data.data[0].enable_sms === true) ? true : false || false,
                    enable_email: (response.data.data[0].enable_email === 'true' || response.data.data[0].enable_email === true) ? true : false || false,
                    enable_mobile_app: (response.data.data[0].enable_mobile_app === 'true' || response.data.data[0].enable_mobile_app === true) ? true : false || false,
                    enable_offline_user: (response.data.data[0].enable_offline_user === 'true' || response.data.data[0].enable_offline_user === true) ? true : false || false,
                    status: response.data.data[0].status,
                    is_frozen: (response.data.data[0].is_frozen === 'true' || response.data.data[0].is_frozen === true) ? true : false || false,
                    is_password_locked: (response.data.data[0].is_password_locked === 'true' || response.data.data[0].is_password_locked === true) ? true : false || false,
                    password_change: (response.data.data[0].password_change === 'true' || response.data.data[0].password_change === true) ? true : false || false,
                    two_step_verification: (response.data.data[0].two_step_verification === 'true' || response.data.data[0].two_step_verification === true) ? true : false || false,
                    sign_in_method: response.data.data[0].sign_in_method,
                    enable_notifications: (response.data.data[0].enable_notifications === 'true' || response.data.data[0].enable_notifications === true) ? true : false || false,
                    receive_notification_as_sms: (response.data.data[0].receive_notification_as_sms === 'true' || response.data.data[0].receive_notification_as_sms === true) ? true : false || false,
                    receive_notification_as_email: (response.data.data[0].receive_notification_as_email === 'true' || response.data.data[0].receive_notification_as_email === true) ? true : false || false,
                    is_approved_initial_value: (response.data.data[0].is_approved === 'true' || response.data.data[0].is_approved === true) ? true : false || false,
                    is_approved: (response.data.data[0].is_approved === 'true' || response.data.data[0].is_approved === true) ? true : false || false,
                    approved_by: response.data.data[0].approved_by,
                    approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].approved_at),
                    last_updated_by: response.data.data[0].last_updated_by,
                    profile_is_visible: (response.data.data[0].profile_is_visible === 'true' || response.data.data[0].profile_is_visible === true) ? true : false || false,
                    password_expiry_date: response.data.data[0].password_expiry_date,
                    recovery_email: response.data.data[0].recovery_email,
                    recovery_phone: response.data.data[0].recovery_phone,
                    email_security_risk: response.data.data[0].email_security_risk,
                    email_account_activity: response.data.data[0].email_account_activity,
                    phone_security_risk: response.data.data[0].phone_security_risk,
                    phone_account_activity: response.data.data[0].phone_account_activity,
                    security_question1: response.data.data[0].security_question1,
                    security_answer1: response.data.data[0].security_answer1,
                    security_question2: response.data.data[0].security_question2,
                    security_answer2: response.data.data[0].security_answer2,
                    security_question3: response.data.data[0].security_question3,
                    security_answer3: response.data.data[0].security_answer3,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                    document.title = this.Language.PageTitle.EditUserAccount + ' ' + this.state.username + ' - ' + this.Language.EEGWebPortal;
                });



                if (this.state.status === 'Active') {
                    this.setState({ status_active: true });
                } else {
                    this.setState({ status_active: false });
                };


                if (this.state.sign_in_method === 'Security Question') {
                    this.setState({ signin_security_questions: true });
                } else if (this.state.sign_in_method === 'Code') {
                    this.setState({ signin_code: true });
                } else {
                    this.setState({ signin_na: true });
                };


                if (this.state.time_zone_region !== null && typeof this.state.time_zone_region !== 'undefined' && this.state.time_zone_region !== 'undefined') {

                    let time_zone_region_temp = this.state.time_zone_region || '';
                    time_zone_region_temp = time_zone_region_temp.split("***"); // split the time zone values  gotten from the database
                    time_zone_region_temp = time_zone_region_temp[1];

                    this.setState({ time_zone_region_temp: time_zone_region_temp });
                };


                this.setState({ response_status: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


        if (name === 'signin_security_questions') {
            this.setState({
                signin_security_questions: true,
                signin_code: false,
                signin_na: false
            });
        }

        if (name === 'signin_code') {
            this.setState({
                signin_code: true,
                signin_security_questions: false,
                signin_na: false
            });
        }

        if (name === 'signin_na') {
            this.setState({
                signin_na: true,
                signin_security_questions: false,
                signin_code: false
            });
        }


        if (name === 'internal_user') { this.setState({ [name]: !this.state.internal_user }); }
        if (name === 'partner_user') { this.setState({ [name]: !this.state.partner_user }); }
        if (name === 'enable_sms') { this.setState({ [name]: !this.state.enable_sms }); }
        if (name === 'enable_email') { this.setState({ [name]: !this.state.enable_email }); }
        if (name === 'enable_mobile_app') { this.setState({ [name]: !this.state.enable_mobile_app }); }
        if (name === 'enable_offline_user') { this.setState({ [name]: !this.state.enable_offline_user }); }
        if (name === 'is_frozen') { this.setState({ [name]: !this.state.is_frozen }); }
        if (name === 'is_password_locked') { this.setState({ [name]: !this.state.is_password_locked }); }
        if (name === 'password_change') { this.setState({ [name]: !this.state.password_change }); }
        if (name === 'two_step_verification') { this.setState({ [name]: !this.state.two_step_verification }); }
        if (name === 'enable_notifications') { this.setState({ [name]: !this.state.enable_notifications }); }
        if (name === 'receive_notification_as_sms') { this.setState({ [name]: !this.state.receive_notification_as_sms }); }
        if (name === 'receive_notification_as_email') { this.setState({ [name]: !this.state.receive_notification_as_email }); }
        if (name === 'approve_account') { this.setState({ is_approved: !this.state.is_approved }); }

    }




    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.EditUserAccount} ${this.refs.username.value.trim()}`)) {

            let username = this.refs.username.value.trim();
            let user_role = this.refs.user_role.value;
            if (user_role === "Select User Role") user_role = "";

            let exporter = this.refs.exporter.value;
            if (exporter === "Select Exporter" && (this.refs.partner_user.checked === false && this.refs.internal_user.checked === false)) exporter = "";


            let partner = this.refs.partner.value;
            if (partner === "Select Partner Agency" && (this.refs.partner_user.checked === true || this.refs.internal_user.checked === true)) partner = "";

            let title = this.refs.title.value;
            if (title === "Select Title") title = null;

            let prefix = this.refs.prefix.value;
            let suffix = this.refs.suffix.value;
            let first_name = this.refs.first_name.value;
            let middle_name = this.refs.middle_name.value;
            let last_name = this.refs.last_name.value;


            let date_of_registration = this.DateService.convertToFullDate(this.refs.date_of_registration.value);

            let language = this.refs.language.value;
            if (language === "Select Language") language = null;

            let time_zone_region = this.refs.time_zone_region.value;
            if (time_zone_region === "Select Default Time Zone") time_zone_region = '';

            let email = this.refs.email.value.trim();
            let phone = this.refs.phone.value.trim();

            let email_signature = this.refs.email_signature.value;
            let user_type = this.refs.user_type.value;
            if (user_type === "Select User Type") user_type = ""

            let internal_user = this.refs.internal_user.checked;
            let partner_user = this.refs.partner_user.checked;
            let enable_sms = this.refs.enable_sms.checked;
            let enable_email = this.refs.enable_email.checked;
            let enable_mobile_app = this.refs.enable_mobile_app.checked;
            let enable_offline_user = this.refs.enable_offline_user.checked;

            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let is_frozen = this.refs.is_frozen.checked;
            let is_password_locked = this.refs.is_password_locked.checked;
            let password_change = this.refs.password_change.checked;
            let two_step_verification = this.refs.two_step_verification.checked;

            let sign_in_method = null;
            if (this.refs.signin_security_questions.checked) sign_in_method = "Security Question";
            if (this.refs.signin_code.checked) sign_in_method = "Code";
            if (this.refs.signin_na.checked) sign_in_method = null;

            let enable_notifications = this.refs.enable_notifications.checked;
            let receive_notification_as_sms = this.refs.receive_notification_as_sms.checked;
            let receive_notification_as_email = this.refs.receive_notification_as_email.checked;


            let is_approved_initial_value = this.state.is_approved_initial_value;
            let is_approved = this.refs.approve_account.checked;
            let approved_by = null;
            if (is_approved_initial_value === true && is_approved === true) approved_by = this.state.approved_by;
            if (is_approved_initial_value === false && is_approved === true) approved_by = this.state.logged_in_username;


            let approved_at = null;
            if (is_approved_initial_value === true && is_approved === true) { // if the user account had been approved before now take the approved at value
                approved_at = this.state.approved_at;
            }

            if (is_approved_initial_value === false && is_approved === true) { // if the user account has just been approved then take the new approved at value
                let currentdate = new Date();
                approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
            }


            let last_updated_by = this.state.logged_in_username;

            let profile_is_visible = this.state.profile_is_visible;
            let password_expiry_date = this.state.password_expiry_date;
            let recovery_email = this.state.recovery_email;
            let recovery_phone = this.state.recovery_phone;
            let email_security_risk = this.state.email_security_risk;
            let email_account_activity = this.state.email_account_activity;
            let phone_security_risk = this.state.phone_security_risk;
            let phone_account_activity = this.state.phone_account_activity;
            let security_question1 = this.state.security_question1;
            let security_answer1 = this.state.security_answer1;
            let security_question2 = this.state.security_question2;
            let security_answer2 = this.state.security_answer2;
            let security_question3 = this.state.security_question3;
            let security_answer3 = this.state.security_answer3;


            let last_updated_on_check = this.state.last_updated_on_check;



            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.UserAccount.EditErrorTitle, [username, user_role, exporter, partner, first_name, last_name, date_of_registration, email, email, phone, phone, user_type, language, time_zone_region, email_signature], [this.Language.DataField.Username, this.Language.DataField.UserRole, this.Language.DataField.Exporter, this.Language.DataField.PartnerAgency, this.Language.DataField.FirstName, this.Language.DataField.LastName, this.Language.DataField.DateofRegistration, this.Language.DataField.Email, this.Language.DataField.Email, this.Language.DataField.Phone, this.Language.DataField.Phone, this.Language.DataField.UserType, this.Language.DataField.Language, this.Language.DataField.DefaultTimeZone, this.Language.DataField.EmailSignature], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequiredDate", "isRequired", "isEmail", "isRequired", "isPhone", "isRequired", "isRequired", "isRequired", "maxLength"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {

                    if (exporter === "Select Exporter") exporter = null;
                    if (partner === "Select Partner Agency") partner = null;

                    if (language === "Select Language") language = null;
                    if (time_zone_region === "Select Default Time Zone") time_zone_region = null;

                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newUserAccount = {
                        username: username,
                        user_role: user_role,
                        exporter_id: exporter,
                        partner_id: partner,
                        title: title,
                        prefix: prefix,
                        suffix: suffix,
                        first_name: first_name,
                        middle_name: middle_name,
                        last_name: last_name,
                        date_of_registration: date_of_registration,
                        language_id: language,
                        time_zone_region: time_zone_region,
                        email: email,
                        phone: phone,
                        email_signature: email_signature,
                        user_type: user_type,
                        internal_user: internal_user,
                        partner_user: partner_user,
                        enable_sms: enable_sms,
                        enable_email: enable_email,
                        enable_mobile_app: enable_mobile_app,
                        enable_offline_user: enable_offline_user,
                        status: status,
                        is_frozen: is_frozen,
                        is_password_locked: is_password_locked,
                        password_change: password_change,
                        two_step_verification: two_step_verification,
                        sign_in_method: sign_in_method,
                        enable_notifications: enable_notifications,
                        receive_notification_as_sms: receive_notification_as_sms,
                        receive_notification_as_email: receive_notification_as_email,
                        is_approved: is_approved,
                        approved_by: approved_by,
                        approved_at: approved_at,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                        last_password_change: date_of_registration,
                        profile_is_visible: profile_is_visible,
                        password_expiry_date: password_expiry_date,
                        recovery_email: recovery_email,
                        recovery_phone: recovery_phone,
                        email_security_risk: email_security_risk,
                        email_account_activity: email_account_activity,
                        phone_security_risk: phone_security_risk,
                        phone_account_activity: phone_account_activity,
                        security_question1: security_question1,
                        security_answer1: security_answer1,
                        security_question2: security_question2,
                        security_answer2: security_answer2,
                        security_question3: security_question3,
                        security_answer3: security_answer3
                    }

                    

                    this.setState({ preloader: true });
                    document.getElementById("update_user_account_button").setAttribute("disabled", true);

                    this.updateUser(newUserAccount);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_user_role = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'user_role') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_user_role: select_field_values_user_role });



                        const select_field_values_user_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'user_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_user_type: select_field_values_user_type });




                        const select_field_values_exporter = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'exporter') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_exporter: select_field_values_exporter });



                        const select_field_values_organisation = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'organisation') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_organisation: select_field_values_organisation });


                        const select_field_values_title = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'title') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_title: select_field_values_title });



                        const select_field_values_language = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'language') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_language: select_field_values_language });



                        const select_field_values_time_zone_region = this.state.pick_list_values_time_zone.map((pick_list_value, i) => {
                            return (
                                <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                            )
                        })

                        this.setState({ select_field_values_time_zone_region: select_field_values_time_zone_region });

                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    updateUser(newUserAccount) {

        let userId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/users/${userId}`,
            data: newUserAccount
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.UserAccount.UpdateSuccess + newUserAccount.username, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_user_account_button").removeAttribute("disabled");

            // this.clearForm();

            this.props.history.push(`/users/${userId}`);

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.UserAccount.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserAccount.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.UserAccount.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/users');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_user_account_button").removeAttribute("disabled");

            });

    }


    render() {

        // let date_of_registration = this.state.date_of_registration + '';
        // date_of_registration = this.DateService.convertToCalendarFullDate(date_of_registration);

        let userId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"User Management"} active_menu_level_2={"User Accounts"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditUserAccount} ${this.state.username}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.UserAccounts, this.Language.BreadCrumbLabel.EditUserAccount]} links={[`/users`, `/users`, `/users`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditUserAccount}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <i className="material-icons prefix">account_circle</i>
                                                            <input id="username" name="username" type="text" ref="username" value={this.state.username} disabled />
                                                            <label htmlFor="username" className={(this.state.username && this.state.username !== null && typeof this.state.username !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Username} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-user_role" name="user_role" ref="user_role">
                                                                    <option value={this.state.user_role_id}>{this.state.user_role}</option>
                                                                    <option value="Select User Role">{this.Language.DataFieldDefault.SelectUserRole}</option>
                                                                    {this.state.select_field_values_user_role.map((user_role_value, i) => {
                                                                        return (
                                                                            user_role_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.UserRole} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                {/* <input type="text" className="select-dropdown"  readOnly="true" data-activates="select-options-exporter"/> */}
                                                                <select data-select-id="select-options-exporter" className="initialized" name="exporter" ref="exporter">
                                                                    {this.state.exporter_id && <option value={this.state.exporter_id} selected>{this.state.exporter_name}</option>}
                                                                    <option value="Select Exporter">{this.Language.DataFieldDefault.SelectExporter}</option>
                                                                    {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                                                        return (
                                                                            exporter_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Exporter} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                {/* <input type="text" className="select-dropdown"  readOnly="true" data-activates="select-options-partner"/> */}
                                                                <select data-select-id="select-options-partner" className="initialized" name="partner" ref="partner">
                                                                    {this.state.partner_id && <option value={this.state.partner_id} selected>{this.state.organisation_name}</option>}
                                                                    <option value="Select Partner Agency">{this.Language.DataFieldDefault.SelectPartnerAgency}</option>
                                                                    {this.state.select_field_values_organisation.map((organisation_value, i) => {
                                                                        return (
                                                                            organisation_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Partner} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                {/* <input type="text" className="select-dropdown"  readOnly="true" data-activates="select-options-title"/> */}
                                                                <select data-select-id="select-options-title" className="initialized" name="title" ref="title">
                                                                    <option value={this.state.title}>{this.state.title}</option>
                                                                    <option value="Select Title">{this.Language.DataFieldDefault.SelectTitle}</option>
                                                                    {this.state.select_field_values_title.map((title_value, i) => {
                                                                        return (
                                                                            title_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Title}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="prefix" type="text" ref="prefix" name="prefix" value={this.state.prefix} onChange={this.handleInputChange} />
                                                            <label htmlFor="prefix" className={(this.state.prefix && this.state.prefix !== null && typeof this.state.prefix !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Prefix}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s4">
                                                            <input id="suffix" type="text" ref="suffix" name="suffix" value={this.state.suffix} onChange={this.handleInputChange} />
                                                            <label htmlFor="suffix" className={(this.state.suffix && this.state.suffix !== null && typeof this.state.suffix !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Suffix}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        {/* ::after */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="first_name" type="text" ref="first_name" name="first_name" value={this.state.first_name} onChange={this.handleInputChange} />
                                                            <label htmlFor="first_name" className={(this.state.first_name && this.state.first_name !== null && typeof this.state.first_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.FirstName} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="middle_name" type="text" ref="middle_name" name="middle_name" value={this.state.middle_name} onChange={this.handleInputChange} />
                                                            <label htmlFor="middle_name" className={(this.state.middle_name && this.state.middle_name !== null && typeof this.state.middle_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.MiddleName}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        {/* ::after */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="last_name" type="text" ref="last_name" name="last_name" value={this.state.last_name} onChange={this.handleInputChange} />
                                                            <label htmlFor="last_name" className={(this.state.last_name && this.state.last_name !== null && typeof this.state.last_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.LastName} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        {/* ::after */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input type="text" className="datepicker picker__input" ref="date_of_registration" value={this.state.date_of_registration} readOnly="false" id="date_of_registration" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="date_of_registration_root" />
                                                            <label htmlFor="date_of_registration" className={(this.state.date_of_registration && this.state.date_of_registration !== null && typeof this.state.date_of_registration !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.DateofRegistration} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                {/* <input type="text" className="select-dropdown" readOnly="true" data-activates="select-options-language"/> */}
                                                                <select data-select-id="select-options-language" ref="language" className="initialized">
                                                                    <option value={this.state.language_id}>{this.state.language_id}</option>
                                                                    <option value="Select Language">{this.Language.DataFieldDefault.SelectLanguage}</option>
                                                                    {this.state.select_field_values_language.map((language_value, i) => {
                                                                        return (
                                                                            language_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Language}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-time_zone_region" ref="time_zone_region" name="time_zone_region" className="initialized">
                                                                    <option value={this.state.time_zone_region}>{this.state.time_zone_region_temp}</option>
                                                                    <option value="Select Default Time Zone">{this.Language.DataFieldDefault.SelectDefaultTimeZone}</option>
                                                                    {this.state.select_field_values_time_zone_region.map((timezone_value, i) => {
                                                                        return (
                                                                            timezone_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.DefaultTimeZone}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <h6><b>Contact Details</b></h6>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="email" type="email" className="validate" ref="email" name="email" value={this.state.email} onChange={this.handleInputChange} />
                                                            <label htmlFor="email" data-error={this.Language.DataFieldError.Email} className={(this.state.email && this.state.email !== null && typeof this.state.email !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Email} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="phone" type="tel" className="validate" ref="phone" name="phone" value={this.state.phone} onChange={this.handleInputChange} />
                                                            <label htmlFor="phone" data-error={this.Language.DataFieldError.Phone} className={(this.state.phone && this.state.phone !== null && typeof this.state.phone !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Phone} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="email_signature" className="materialize-textarea" data-length={this.state.text_limit} name="email_signature" ref="email_signature" value={this.state.email_signature} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="email_signature" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.email_signature && this.state.email_signature !== null && typeof this.state.email_signature !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.EmailSignature}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <h6><b>{this.Language.PageLabel.OtherDetailsandSettings}</b></h6>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s5">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                {/* <input type="text" className="select-dropdown" readOnly="true" data-activates="select-options-user_type"/> */}
                                                                <select data-select-id="select-options-user_type" className="initialized" ref="user_type">
                                                                    <option value={this.state.user_type_id}>{this.state.user_type}</option>
                                                                    <option value="Select User Type">{this.Language.DataFieldDefault.SelectUserType}</option>
                                                                    {this.state.select_field_values_user_type.map((user_type_value, i) => {
                                                                        return (
                                                                            user_type_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.UserType} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="col s1">
                                                            {/* this div is used to give a space between the elements */}
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="internal_user" ref="internal_user" name="internal_user" checked={this.state.internal_user} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="internal_user">{this.Language.DataField.InternalUser}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3 right">
                                                            <p>
                                                                <input type="checkbox" id="partner_user" ref="partner_user" name="partner_user" checked={this.state.partner_user} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="partner_user">{this.Language.DataField.PartnerUser}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="enable_sms" ref="enable_sms" name="enable_sms" checked={this.state.enable_sms} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_sms">{this.Language.DataField.EnableSMS}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="enable_email" ref="enable_email" name="enable_email" checked={this.state.enable_email} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_email">{this.Language.DataField.EnableEmail}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="enable_mobile_app" ref="enable_mobile_app" name="enable_mobile_app" checked={this.state.enable_mobile_app} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_mobile_app">{this.Language.DataField.EnableMobileApp}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="enable_offline_user" ref="enable_offline_user" name="enable_offline_user" checked={this.state.enable_offline_user} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_offline_user">{this.Language.DataField.EnableOfflineUsage}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateAccount}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateAccount}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="is_frozen" ref="is_frozen" checked={this.state.is_frozen} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="is_frozen">{this.Language.DataField.AccountFrozen}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="is_password_locked" ref="is_password_locked" checked={this.state.is_password_locked} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="is_password_locked">{this.Language.DataField.PasswordLocked}</label>
                                                            </p>
                                                        </div>
                                                        <div className="password_change col s3">
                                                            <p>
                                                                <input type="checkbox" id="password_change" ref="password_change" checked={this.state.password_change} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="password_change">{this.Language.DataField.RequirePasswordChangeOnFirstLogin}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <br />
                                                            <p>
                                                                <input type="checkbox" id="two_step_verification" ref="two_step_verification" name="two_step_verification" checked={this.state.two_step_verification} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="two_step_verification">{this.Language.DataField.EnableTwoFactorAthentication}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s2">
                                                            <label htmlFor="signin_select">{this.Language.DataField.TwoFactorSigninMethod}</label>
                                                            <p>
                                                                <input name="signin_method" type="radio" id="signin_security_questions" ref="signin_security_questions" checked={this.state.signin_security_questions} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="signin_security_questions">{this.Language.DataField.SecurityQuestion}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s2 center">
                                                            <br />
                                                            <p>
                                                                <input name="signin_method" type="radio" id="signin_code" ref="signin_code" checked={this.state.signin_code} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="signin_code">{this.Language.DataField.Code}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s2">
                                                            <br />
                                                            <p>
                                                                <input name="signin_method" type="radio" id="signin_na" ref="signin_na" checked={this.state.signin_na} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="signin_na">{this.Language.DataField.None}</label>
                                                            </p>
                                                        </div>
                                                        {/* ::after */}
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4 enable_notifications">
                                                            <p>
                                                                <input type="checkbox" id="enable_notifications" ref="enable_notifications" checked={this.state.enable_notifications} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_notifications">{this.Language.DataField.EnableNotifications}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="receive_notification_as_sms" ref="receive_notification_as_sms" checked={this.state.receive_notification_as_sms} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="receive_notification_as_sms">{this.Language.DataField.ReceiveNotificationsAsSMS}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="receive_notification_as_email" ref="receive_notification_as_email" checked={this.state.receive_notification_as_email} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="receive_notification_as_email">{this.Language.DataField.ReceiveNotificationsAsEmail}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6 approve_account">
                                                            <p>
                                                                <input type="checkbox" id="approve_account" ref="approve_account" checked={this.state.is_approved} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="approve_account"><b>{this.Language.DataField.ApproveAccount}</b></label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_user_account_button">
                                                                {this.Language.ButtonLabel.UpdateUserAccount}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s6">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/users/${userId}`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditUser;