import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AuthService from "../modules/AuthService";
import BrowserService from "../modules/BrowserService";
import ValidationService from "../modules/ValidationService";
import DateService from "../modules/DateService";
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSideBar from "../components/LeftSideBar";
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from "../components/BreadcrumbsWrapper";
import FloatingActionButton from "../components/FloatingActionButton";
import SelectField from "../components/SelectField";
import LanguageService from "../modules/LanguageService";
import URLService from "../modules/URLService";

import ReactDOM from "react-dom";

const $ = require("jquery");

class EditBaselineYear extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.updateBaselineYear = this.updateBaselineYear.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.getBaselineYear = this.getBaselineYear.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getDropdownValues = this.getDropdownValues.bind(this);
    this.getBaselineYears = this.getBaselineYears.bind(this);
    this.toggleBaselineOption = this.toggleBaselineOption.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.state = {
      business_year: "",
      enable_baseline_year: false,
      baseline_year_option_check_box: false,
      all_exporters: false,
      specific_exporter: false,
      specific_exporter_category: false,
      exporter: "",
      exporter_category: "",
      start_date: "",
      end_date: "",
      hash_id: "",
      status: "",
      status_active: false,
      last_updated_on_check: "",
      result: "",
      message: "",
      response_status: "",
      pick_list_values: [],
      select_field_values_business_year: [],
      select_field_values_exporter: [],
      select_field_values_exporter_category: [],
      preloader: true,
      logged_in_username: "",
      error_messages: null,
      close_error_messages: (
        <button
          type="button"
          className="close white-text"
          data-dismiss="alert"
          aria-label="Close"
          onClick={this.handleCloseAlert}
        >
          <span aria-hidden="true">×</span>
        </button>
      )
    };
    this.Auth = new AuthService();
    this.Validate = new ValidationService();
    this.DateService = new DateService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.apiURL = URLService.prototype.getApiURL();
  }

  componentWillMount() {}

  componentDidMount() {
    document.title = this.Language.PageTitle.EditBaselineYear + ' - ' + this.Language.EEGWebPortal;
    this.getProfile();
    this.checkAccessRight();
    this.getBaselineYear();

    const monthsFull = this.Language.DatePicker.monthsFull.split(",");
    const monthsShort = this.Language.DatePicker.monthsShort.split(",");
    const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
    const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
    const today = this.Language.DatePicker.today;
    const clear = this.Language.DatePicker.clear;
    const close = this.Language.DatePicker.close;
    const labelMonthNext = this.Language.DatePicker.labelMonthNext;
    const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
    const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
    const labelYearSelect = this.Language.DatePicker.labelYearSelect;

    window.$(".datepicker").pickadate({
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      today: today,
      clear: clear,
      close: close,
      labelMonthNext: labelMonthNext,
      labelMonthPrev: labelMonthPrev,
      labelMonthSelect: labelMonthSelect,
      labelYearSelect: labelYearSelect
      // firstDay: 1,
      // format: 'dd mmmm yyyy',
      // formatSubmit: 'yyyy/mm/dd',
      // selectMonths: true,
      // selectYears: true,
      // selectMonths: true, // Creates a dropdown to control month
      // selectYears: 15, // Creates a dropdown of 15 years to control year,
      // closeOnSelect: false, // Close upon selecting a date,
      // container: undefined, // ex. 'body' will append picker to body
    });

    window.$("select").material_select();
    window
      .$(ReactDOM.findDOMNode(this.refs.specific_exporter))
      .on("change", this.toggleBaselineOption.bind(this));

    window
      .$(ReactDOM.findDOMNode(this.refs.specific_exporter_category))
      .on("change", this.toggleBaselineOption.bind(this));

    window
      .$(ReactDOM.findDOMNode(this.refs.all_exporters))
      .on("change", this.toggleBaselineOption.bind(this));
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    $("select").material_select("destroy");
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState({ logged_in_username: user_profile.username });
    }
  }

  checkAccessRight() {
    if (
      this.Auth.checkAccessScope(
        ["update:baseline_years", "action:baseline_years"],
        "view"
      ) === false
    ) {
      this.props.history.replace("/exporter/baseline/data");
    } else {
      this.getDropdownValues("exporter_hash,exporter_category");
    }
  }

  handleCloseAlert() {
    this.setState({ error_messages: null }); // change the value of error_message to close the alert box
  }

  getBaselineYear() {
    let baselineYearId = this.props.match.params.id;

    axios
      .get(
        `${
          this.apiURL
        }/api/v1/baseline/years/${baselineYearId}?tk=${this.Auth.getToken()}`
      )
      .then(response => {
        this.setState({
          business_year: response.data.data[0].business_year,
          enable_baseline_year:
            response.data.data[0].enable_for_baseline_entry === true ||
            response.data.data[0].enable_for_baseline_entry === "true"
              ? true
              : false || false,
          baseline_year_option_check_box:
            response.data.data[0].applies_to_specific_exporter_or_category ===
              true ||
            response.data.data[0].applies_to_specific_exporter_or_category ===
              "true"
              ? true
              : false || false,
          all_exporters:
            (response.data.data[0].exporter_name === "" ||
              response.data.data[0].exporter_name === null ||
              typeof response.data.data[0].exporter_name === "undefined") &&
            (response.data.data[0].exporter_or_category_value === "" ||
              response.data.data[0].exporter_or_category_value === null ||
              typeof response.data.data[0].exporter_or_category_value ===
                "undefined")
              ? true
              : false || false,
          specific_exporter:
            response.data.data[0].exporter_name !== "" &&
            response.data.data[0].exporter_name !== null &&
            typeof response.data.data[0].exporter_name !== "undefined"
              ? true
              : false || false,
          specific_exporter_category:
            (response.data.data[0].applies_to_specific_exporter_or_category ===
              true ||
              response.data.data[0].applies_to_specific_exporter_or_category ===
                "true") &&
            (response.data.data[0].exporter_name === "" ||
              response.data.data[0].exporter_name === null ||
              typeof response.data.data[0].exporter_name === "undefined")
              ? true
              : false || false,
          exporter: response.data.data[0].exporter_name || "Select Exporter",
          exporter_category:
            response.data.data[0].exporter_or_category_value !== "" &&
            response.data.data[0].exporter_or_category_value !== null &&
            typeof response.data.data[0].exporter_or_category_value !==
              "undefined" &&
            (response.data.data[0].exporter_name === "" ||
              response.data.data[0].exporter_name === null ||
              typeof response.data.data[0].exporter_name === "undefined")
              ? response.data.data[0].exporter_or_category_value
              : "Select Exporter Category",
          start_date: this.DateService.convertToCalendarFullDate(
            response.data.data[0].start_date
          ),
          end_date: this.DateService.convertToCalendarFullDate(
            response.data.data[0].end_date
          ),
          hash_id: response.data.data[0].hash_id,
          status: response.data.data[0].status,
          last_updated_on_check:
            response.data.data[0].last_updated_on_check || ""
        });

        this.setState({
          status_active: this.state.status === "Active" ? true : false
        });

        this.setState({ result: response.data.status }, () => {
          
        });

        this.setState({ message: response.data.message }, () => {
          
        });

        window.$("select").material_select();
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          

          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(
                error.response.data.message[i].msg,
                8000,
                "rounded deep-orange darken-4"
              );
            }
          } else {
            if (error.response.status === 500)
              window.Materialize.toast(
                this.Language.NodeMessage.GetError,
                8000,
                "rounded deep-orange darken-4"
              );
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        
      });
  }

  toggleBaselineOption(event) {
    event.preventDefault();

    const all_exporters = this.refs.all_exporters.checked;
    const specific_exporter = this.refs.specific_exporter.checked;
    const specific_exporter_category = this.refs.specific_exporter_category
      .checked;

    if (specific_exporter === true) {
      this.setState({
        exporter_select_status: false,
        exporter_category_select_status: true
      });
      $(".specific_exporter_category")
        .find("input[type=radio]")
        .prop("checked", false);
      $(".all_exporters")
        .find("input[type=radio]")
        .prop("checked", false);
      $(".specific_exporter")
        .find("input[type=radio]")
        .prop("checked", true);
    }

    if (specific_exporter_category === true) {
      this.setState({
        exporter_select_status: true,
        exporter_category_select_status: false
      });
      $(".specific_exporter")
        .find("input[type=radio]")
        .prop("checked", false);
      $(".all_exporters")
        .find("input[type=radio]")
        .prop("checked", false);
      $(".specific_exporter_category")
        .find("input[type=radio]")
        .prop("checked", true);
    }

    if (all_exporters === true) {
      this.setState({
        exporter_select_status: true,
        exporter_category_select_status: true
      });
      $(".specific_exporter")
        .find("input[type=radio]")
        .prop("checked", false);
      $(".specific_exporter_category")
        .find("input[type=radio]")
        .prop("checked", false);
      $(".all_exporters")
        .find("input[type=radio]")
        .prop("checked", true);
    }

    window.$("select").material_select();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  }

  handleCheckBoxChange(event) {
    const target = event.target;
    // const checked = target.checked;
    const name = target.id;

    if (name === "activate_switch") {
      this.setState({
        status_active: !this.state.status_active,
        status: this.state.status === "Active" ? "Inactive" : "Active"
      });
    }

    if (name === "enable_baseline_year") {
      this.setState({ [name]: !this.state.enable_baseline_year });
    }

    if (name === "baseline_year_option_check_box") {
      this.setState({ [name]: !this.state.baseline_year_option_check_box });
    }

    if (name === "all_exporters") {
      this.setState({
        all_exporters: true,
        specific_exporter: false,
        specific_exporter_category: false
      });
    }

    if (name === "specific_exporter") {
      this.setState({
        all_exporters: false,
        specific_exporter: true,
        specific_exporter_category: false
      });
    }

    if (name === "specific_exporter_category") {
      this.setState({
        all_exporters: false,
        specific_exporter: false,
        specific_exporter_category: true
      });
    }
  }

  onSubmit(event) {
    event.preventDefault();

    if (
      window.confirm(
        `${this.Language.ConfirmMessage.SaveBaselineYear} - ${this.refs.baseline_year.value}`
      )
    ) {
      let baseline_year = this.refs.baseline_year.value;
      if (baseline_year === "Select Baseline Year") baseline_year = "";

      let enable_baseline_year = this.refs.enable_baseline_year.checked;

      let baseline_year_option_check_box = this.refs
        .baseline_year_option_check_box.checked;

      const specific_exporter = this.refs.specific_exporter.checked;
      const specific_exporter_category = this.refs.specific_exporter_category
        .checked;

      let exporter = this.refs.exporter.value;
      if (specific_exporter === true && exporter === "Select Exporter")
        exporter = "";

      let exporter_category = this.refs.exporter_category.value;
      if (
        specific_exporter_category === true &&
        exporter_category === "Select Exporter Category"
      )
        exporter_category = "";

      let start_date = this.DateService.convertToFullDate(
        this.refs.start_date.value
      );
      let end_date = this.DateService.convertToFullDate(
        this.refs.end_date.value
      );

      let status = "Inactive";
      if (this.refs.activate_switch.checked) status = "Active";
      if (!this.refs.activate_switch.checked) status = "Inactive";

      let last_updated_by = this.state.logged_in_username;

      let last_updated_on_check = this.state.last_updated_on_check;

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.ExchangeRates.AddErrorTitle,
            [baseline_year, exporter, exporter_category, start_date, end_date],
            [
              this.Language.DataField.BaselineYear,
              this.Language.DataField.Exporter,
              this.Language.DataField.ExporterCategory,
              this.Language.DataField.StartDate,
              this.Language.DataField.EndDate
            ],
            [
              "isRequired",
              "isRequired",
              "isRequired",
              "isRequiredDate",
              "isRequiredDate"
            ],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            if (baseline_year === "Select Baseline Year") baseline_year = null;

            if (exporter === "Select Exporter") exporter = null;
            if (exporter_category === "Select Exporter Category")
              exporter_category = null;

            let exporter_or_category_value = null;

            if (
              specific_exporter === true &&
              exporter !== null &&
              exporter !== ""
            )
              exporter_or_category_value = exporter;

            if (
              specific_exporter_category === true &&
              exporter_category !== null &&
              exporter_category !== ""
            )
              exporter_or_category_value = exporter_category;

            if (
              specific_exporter === true ||
              specific_exporter_category === true
            ) {
              baseline_year_option_check_box = true;
            } else {
              baseline_year_option_check_box = false;
            }

            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineYear = {
              business_year: baseline_year,
              enable_for_baseline_entry: enable_baseline_year,
              applies_to_specific_exporter_or_category: baseline_year_option_check_box,
              exporter_or_category_value: exporter_or_category_value,
              start_date: start_date,
              end_date: end_date,
              status: status,
              last_updated_by: last_updated_by,
              last_updated_on_check: last_updated_on_check,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser:
                this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used:
                this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: "web",
              token: this.Auth.getToken()
            };

            

            this.setState({ preloader: true });
            document
              .getElementById("update_baseline_year_button")
              .setAttribute("disabled", true);

            this.updateBaselineYear(newBaselineYear);
          } else {
            window.Materialize.toast(
              this.Language.ReactValidationMessage.FormErrors,
              8000,
              "rounded deep-orange darken-4"
            );
          }
        }
      );
    }
  }

  getDropdownValues(pick_list_names) {
    axios
      .get(`${this.apiURL}/api/v1/settings/picklists`, {
        params: {
          tk: this.Auth.getToken(),
          pick_list_names: pick_list_names
        }
      })
      .then(response => {
        this.setState({ pick_list_values: response.data.data }, () => {
          

          if (
            this.state.pick_list_values &&
            this.state.pick_list_values.length > 0
          ) {
            const select_field_values_exporter = this.state.pick_list_values.map(
              (pick_list_value, i) => {
                if (pick_list_value.category === "exporter_hash") {
                  return (
                    <SelectField
                      pick_list_id={pick_list_value.pick_list_id}
                      key={i}
                      item_name={pick_list_value.item_name}
                    />
                  );
                } else {
                  return null;
                }
              }
            );

            this.setState({
              select_field_values_exporter: select_field_values_exporter
            });

            const select_field_values_exporter_category = this.state.pick_list_values.map(
              (pick_list_value, i) => {
                if (pick_list_value.category === "exporter_category") {
                  return (
                    <SelectField
                      pick_list_id={pick_list_value.item_name}
                      key={i}
                      item_name={pick_list_value.item_name}
                    />
                  );
                } else {
                  return null;
                }
              }
            );

            this.setState({
              select_field_values_exporter_category: select_field_values_exporter_category
            });
          }
        });

        window.$("select").material_select();
        this.setState({ preloader: null });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(
                error.response.data.message[i].msg,
                8000,
                "rounded deep-orange darken-4"
              );
            }
          } else {
            if (error.response.status === 500)
              window.Materialize.toast(
                this.Language.NodeMessage.PickListValues.Error,
                8000,
                "rounded deep-orange darken-4"
              );
            if (error.response.status === 400)
              window.Materialize.toast(
                this.Language.NodeMessage.PickListValues.DataNotprovided,
                8000,
                "rounded deep-orange darken-4"
              );
          }
        } else if (error.request) {
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        

        this.setState({ preloader: null });
      });
  }

  getBaselineYears() {
    let year_temp = null;
    let select_field_values_years_array = [];

    for (let i = 0; i < 20; i++) {
      // populate the values in the export year dropdown
      if (i === 0) {
        year_temp = new Date();
        year_temp = year_temp.getFullYear();
        select_field_values_years_array.push(year_temp - 5); // add five preceeding years to the list
        select_field_values_years_array.push(year_temp - 4);
        select_field_values_years_array.push(year_temp - 3);
        select_field_values_years_array.push(year_temp - 2);
        select_field_values_years_array.push(year_temp - 1);
        select_field_values_years_array.push(year_temp);
      } else {
        select_field_values_years_array.push(
          select_field_values_years_array[
            select_field_values_years_array.length - 1
          ] + 1
        ); // get the last element in the array and add 1 to it before adding the value back to the array
      }
    }

    axios
      .get(
        `${this.apiURL}/api/v1/baseline/years/active/exporter/exporter/exporter_category`,
        {
          params: {
            tk: this.Auth.getToken()
          }
        }
      )
      .then(response => {
        const baseline_years = response.data.data;

        const select_field_values_business_year = select_field_values_years_array.map(
          (business_year, i) => {
            const years_len = baseline_years.length;
            let is_allowed_year = true;

            for (let j = 0; j < years_len; j++) {
              if (
                baseline_years[j].business_year === business_year &&
                baseline_years[j].applies_to_specific_exporter_or_category ===
                  false
              ) {
                is_allowed_year = false;
              }
            }

            if (is_allowed_year === true) {
              return (
                <SelectField
                  pick_list_id={business_year}
                  key={i}
                  item_name={business_year}
                />
              );
            } else {
              return null;
            }
          }
        );

        this.setState({
          select_field_values_business_year: select_field_values_business_year
        });
        window.$("select").material_select();
      })
      .catch(error => {
        const select_field_values_business_year = select_field_values_years_array.map(
          (business_year, i) => {
            return (
              <SelectField
                pick_list_id={business_year}
                key={i}
                item_name={business_year}
              />
            );
          }
        );

        this.setState({
          select_field_values_business_year: select_field_values_business_year
        });
        window.$("select").material_select();
      });
  }

  updateBaselineYear(newBaselineYear) {
    let baselineYearId = this.props.match.params.id;

    axios
      .request({
        method: "put",
        url: `${this.apiURL}/api/v1/baseline/years/${baselineYearId}`,
        data: newBaselineYear
      })
      .then(response => {
        this.setState({ response_status: response.data.status });

        this.setState({ message: response.data.message }, () => {
          

          if (response.status === 200)
            window.Materialize.toast(
              this.Language.NodeMessage.BaselineYears.UpdateSuccess +
                newBaselineYear.business_year,
              8000,
              "rounded green"
            );
        });

        this.setState({ preloader: null });
        document
          .getElementById("update_baseline_year_button")
          .removeAttribute("disabled");

        this.props.history.push("/exporter/baseline/years");

        // this.clearForm();
      })
      .catch(error => {
        if (error.response) {
          

          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(
                error.response.data.message[i].msg,
                8000,
                "rounded deep-orange darken-4"
              );
            }
          } else {
            if (error.response.status === 500)
              window.Materialize.toast(
                this.Language.NodeMessage.BaselineYears.UpdateError,
                8000,
                "rounded deep-orange darken-4"
              );
            if (error.response.status === 400)
              window.Materialize.toast(
                this.Language.NodeMessage.BaselineYears.DoesNotExist,
                8000,
                "rounded deep-orange darken-4"
              );
            if (error.response.status === 409)
              window.Materialize.toast(
                this.Language.NodeMessage.BaselineYears.UpdateConflict,
                8000,
                "rounded deep-orange darken-4"
              );
          }

          //   this.props.history.push('/modules');
        } else if (error.request) {
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        

        this.setState({ preloader: null });
        document
          .getElementById("update_baseline_year_button")
          .removeAttribute("disabled");
      });
  }

  onDelete() {
    document
      .getElementById("delete_baseline_year_button")
      .setAttribute("disabled", true);

    let business_year = this.state.business_year;
    let baseline_year_option_check_box = this.state
      .baseline_year_option_check_box;
    let logged_in_username = this.state.logged_in_username;
    let baselineYearId = this.props.match.params.id;

    const userOS = this.Browser.getOS();

    const deleteBaselineYear = {
      business_year: business_year,
      applies_to_specific_exporter_or_category: baseline_year_option_check_box,
      last_updated_by: logged_in_username,
      device_name: this.state.logged_in_username + "'s " + userOS,
      device_model: userOS,
      device_os: userOS,
      browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
      location_used:
        this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
      app_source: "web",
      token: this.Auth.getToken()
    };


    axios
      .request({
        method: "delete",
        url: `${this.apiURL}/api/v1/baseline/years/${baselineYearId}`,
        data: deleteBaselineYear
      })
      .then(response => {
        this.setState({ status: response.data.status });

        this.setState({ message: response.data.message }, () => {

          window.Materialize.toast(
            this.Language.NodeMessage.BaselineYears.DeleteSuccess +
              deleteBaselineYear.business_year,
            8000,
            "rounded green"
          );
        });

        document
          .getElementById("delete_baseline_year_button")
          .removeAttribute("disabled");
        this.props.history.replace("/exporter/baseline/years");
      })
      .catch(error => {
        if (error.response) {


          if (error.response.status === 500)
            window.Materialize.toast(
              this.Language.NodeMessage.DeleteError,
              8000,
              "rounded deep-orange darken-4"
            );
          if (error.response.status === 400)
            window.Materialize.toast(
              this.Language.NodeMessage.BaselineYears.DeleteDoesNotExist,
              8000,
              "rounded deep-orange darken-4"
            );
        } else if (error.request) {

        } else {
          // Something happened in setting up the request that triggered an Error

        }

        document
          .getElementById("delete_baseline_year_button")
          .removeAttribute("disabled");
      });
  }

  render() {
    // let baselineYearId = this.props.match.params.id;

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Baseline Data'} active_menu_level_2={'Baseline Years'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.EditBaselineYear}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.BaselineData, this.Language.BreadCrumbLabel.BaselineYears, this.Language.BreadCrumbLabel.EditBaselineYear]}
              links={[`/exporter/baseline/data`, `/exporter/baseline/years`, `/exporter/baseline/years/edit`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <div className="row">
                <div className="col s12 l12">
                  {this.state.error_messages}

                  <div className="card-panel">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <div className="row">
                      <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                        <div className="row">
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-baseline_year" className="initialized" name="baseline_year" id="baseline_year" ref="baseline_year">
                                {this.state.business_year && (
                                  <option value={this.state.business_year} selected>
                                    {this.state.business_year}
                                  </option>
                                )}
                                <option value="Select Baseline Year">{this.Language.DataFieldDefault.SelectBaselineYear}</option>
                                {this.state.select_field_values_business_year.map((baseline_year_value, i) => {
                                  return baseline_year_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.BaselineYear} *</label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s6">
                            <p>
                              <input type="checkbox" id="enable_baseline_year" ref="enable_baseline_year" checked={this.state.enable_baseline_year} onChange={this.handleCheckBoxChange} />
                              <label htmlFor="enable_baseline_year">{this.Language.DataField.EnableBaselineYear}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="row">
                          <div className="col s3">
                            <br />
                            <p>
                              <input
                                type="checkbox"
                                id="baseline_year_option_check_box"
                                ref="baseline_year_option_check_box"
                                name="baseline_year_option_check_box"
                                checked={this.state.baseline_year_option_check_box}
                                onChange={this.handleCheckBoxChange}
                              />
                              <label htmlFor="baseline_year_option_check_box">{this.Language.DataField.BaselineYearOption}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <label htmlFor="exporter_or_category_select">{this.Language.DataField.BaselineYearOption}</label>
                            <p>
                              <input name="baseline_year_option" type="radio" id="all_exporters" ref="all_exporters" checked={this.state.all_exporters} onChange={this.handleCheckBoxChange} />
                              <label htmlFor="all_exporters">{this.Language.DataField.AllExporters}</label>
                            </p>
                          </div>
                          <div className="col s3 center">
                            <br />
                            <p>
                              <input
                                name="baseline_year_option"
                                type="radio"
                                id="specific_exporter"
                                ref="specific_exporter"
                                checked={this.state.specific_exporter}
                                onChange={this.handleCheckBoxChange}
                              />
                              <label htmlFor="specific_exporter">{this.Language.DataField.SpecificExporter}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <br />
                            <p>
                              <input
                                name="baseline_year_option"
                                type="radio"
                                id="specific_exporter_category"
                                ref="specific_exporter_category"
                                checked={this.state.specific_exporter_category}
                                onChange={this.handleCheckBoxChange}
                              />
                              <label htmlFor="specific_exporter_category">{this.Language.DataField.SpecificExporterCategory}</label>
                            </p>
                          </div>
                          {/* ::after */}
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-exporter" className="initialized" name="exporter" id="exporter" ref="exporter" disabled={this.state.exporter_select_status}>
                                {this.state.exporter && (
                                  <option value={this.state.exporter} selected>
                                    {this.state.exporter}
                                  </option>
                                )}
                                <option value="Select Exporter">{this.Language.DataFieldDefault.SelectExporter}</option>
                                {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                  return exporter_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.Exporter} *</label>
                          </div>
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select
                                data-select-id="select-options-exporter_category"
                                className="initialized"
                                name="exporter_category"
                                id="exporter_category"
                                ref="exporter_category"
                                disabled={this.state.exporter_category_select_status}>
                                {this.state.exporter_category && (
                                  <option value={this.state.exporter_category} selected>
                                    {this.state.exporter_category}
                                  </option>
                                )}
                                <option value="Select Exporter Category">{this.Language.DataFieldDefault.SelectExporterCategory}</option>
                                {this.state.select_field_values_exporter_category.map((exporter_category_value, i) => {
                                  return exporter_category_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.ExporterCategory} *</label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="start_date"
                              readOnly={false}
                              id="P875200303"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="P875200303_root"
                              value={this.state.start_date}
                            />
                            <label htmlFor="start_date" className={this.state.start_date && this.state.start_date !== null && typeof this.state.start_date !== 'undefined' ? 'active' : ''}>
                              {this.Language.DataField.StartDate} *
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="end_date"
                              readOnly={false}
                              id="P875200304"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="P875200304_root"
                              value={this.state.end_date}
                            />
                            <label htmlFor="end_date" className={this.state.end_date && this.state.end_date !== null && typeof this.state.end_date !== 'undefined' ? 'active' : ''}>
                              {this.Language.DataField.EndDate} *
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="row">
                          <div className="col s6">
                            <br />
                            <div className="switch">
                              <label className="font-weight-600 black-text">
                                {this.Language.DataField.DeactivateBaselineYear}
                                <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                <span className="lever"></span>
                                {this.Language.DataField.ActivateBaselineYear}
                              </label>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_baseline_year_button">
                              {this.Language.ButtonLabel.UpdateBaselineYear}
                            </button>
                          </div>
                          {/* ::after */}
                          <div className="input-field col s4">
                            <Link className="waves-effect waves-light btn grey right" to={`/exporter/baseline/years`}>
                              <i className="material-icons left">cancel</i>
                              {this.Language.ButtonLabel.Cancel}
                            </Link>
                          </div>
                          <div className="input-field col s2">
                            <div
                              className="waves-effect waves-light btn red"
                              id="delete_baseline_year_button"
                              onClick={() => {
                                if (window.confirm(`${this.Language.ConfirmMessage.DeleteBaselineYear} ${this.state.business_year}`)) {
                                  this.onDelete();
                                }
                              }}>
                              <i className="material-icons left">delete</i>
                              {this.Language.ButtonLabel.Delete}
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                      </form>
                      {/* ::after */}
                    </div>
                  </div>
                </div>
                {/* ::after */}
              </div>
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default EditBaselineYear;
