import React, { Component } from 'react';


class DataCollectionWithChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collectionID: props.collectionID || 'projects-collection',
            iconName: props.iconName || 'language',
            iconColour: props.iconColour || 'cyan',
            title: props.title || '',
            subTitle: props.subTitle || '',
            noRecordAvailable: props.noRecordAvailable || 'No records available',
            collectionValuesList: props.collectionValuesList || [],
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '12',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '6',
        }

    }


    render() {



        return (
            // <!--Data Chart Analytics start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <ul id={this.state.collectionID} className="collection z-depth-1">
                    <li className="collection-item avatar">
                        <i className={`material-icons ${this.state.iconColour} circle`}>{this.state.iconName}</i>
                        <h6 className="collection-header m-0"><b>{this.state.title}</b></h6>
                        <p>{this.state.subTitle}</p>
                    </li>
                    {(this.state.collectionValuesList && this.state.collectionValuesList.length > 0) ?

                        this.state.collectionValuesList.map((collection_value, i) => {

                            if (collection_value && collection_value.title !== null && typeof collection_value.title !== 'undefined') {

                                let categoryColour = collection_value.categoryColour || 'cyan'
                                let progressColour = collection_value.progressColour || 'teal'
                                let link = collection_value.link || '#!'

                                return (
                                    <li key={i} className="collection-item">
                                        <div className="row">
                                            <div className="col s6">
                                                {(collection_value.showLink === true) ? <p className="collections-title"> <a href={link}> <b className="teal-text">{collection_value.title}</b> </a></p> : <p className="collections-title"><b>{collection_value.title}</b></p>
                                                }
                                                <p className="collections-content">{collection_value.subTitle}</p>
                                            </div>
                                            <div className="col s3">
                                                <br />
                                                <span className={`task-cat ${categoryColour}`}>{collection_value.categoryTitle}</span>
                                            </div>
                                            <div className="col s3">
                                                <br />
                                                {(collection_value.showProgress === true) ? <div className={`progress ${progressColour}`}>
                                                    <div className="determinate" style={{ width: collection_value.progressValue }}></div>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </li>
                                )

                            } else {

                                return (
                                    null
                                )

                            }


                        }) : <li className="collection-item">
                            <div className="row">
                                <div className="col s6">
                                    <p className="collections-title">{this.state.noRecordAvailable}</p>
                                </div>
                            </div>
                        </li>

                    }
                </ul>
            </div>
            // <!--Data Chart Analytics end-->
        )
    }
}

export default DataCollectionWithChart;