import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class EditSystemModule extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateModule = this.updateModule.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getModule = this.getModule.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            module_name: '',
            module_description: '',
            module_code: '',
            module_number: '',
            module_menu_position: '',
            is_child_module: false,
            is_sub_module: false,
            module_url: '',
            parent_module_id: '',
            parent_module_name: '',
            module_hash_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_module: [],
            preloader: true,
            logged_in_username: '',
            text_limit: 3,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
      document.title = this.Language.PageTitle.EditSystemModule + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();
        this.getModule();

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['update:modules', 'action:modules'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("module");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getModule() {

        let moduleId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/settings/modules/${moduleId}?tk=${this.Auth.getToken()}`)
            .then(response => {

                this.setState({
                    module_name: response.data.data[0].module_name,
                    module_description: response.data.data[0].module_description,
                    module_code: response.data.data[0].module_code,
                    module_number: response.data.data[0].module_number,
                    module_menu_position: response.data.data[0].module_menu_position,
                    is_child_module: (response.data.data[0].is_child_module === true || response.data.data[0].is_child_module === 'true') ? true : false || false,
                    is_sub_module: (response.data.data[0].is_sub_module === true || response.data.data[0].is_sub_module === 'true') ? true : false || false,
                    module_url: response.data.data[0].module_url,
                    parent_module_id: response.data.data[0].parent_module_id,
                    parent_module_name: response.data.data[0].parent_module_name,
                    module_hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


        if (name === 'is_child_module') { this.setState({ [name]: !this.state.is_child_module }); }
        if (name === 'is_sub_module') { this.setState({ [name]: !this.state.is_sub_module }); }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.EditSystemModule} ${this.refs.module_name.value.trim()}`)) {


            let module_name = this.refs.module_name.value.trim().toLowerCase();
            let module_description = this.refs.module_description.value;
            let module_code = this.refs.module_code.value.trim();
            let module_number = this.refs.module_number.value.replace(/\s/g, "");
            let module_menu_position = this.refs.module_menu_position.value.replace(/\s/g, "");
            let module_url = this.refs.module_url.value.trim();


            let parent_module_id = this.refs.parent_module_id.value;
            if (parent_module_id === "Select Parent Module" && (this.refs.is_child_module.checked === true || this.refs.is_sub_module.checked === true)) parent_module_id = "";

            let is_child_module = this.refs.is_child_module.checked;
            let is_sub_module = this.refs.is_sub_module.checked;

            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;

            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.SystemModule.EditErrorTitle, [module_name, module_description, module_code, module_number, module_menu_position, module_url, module_number, module_menu_position, module_number, module_menu_position], [this.Language.DataField.ModuleName, this.Language.DataField.ModuleDescription, this.Language.DataField.ModuleCode, this.Language.DataField.ModuleNumber, this.Language.DataField.ModuleMenuPosition, this.Language.DataField.ModuleURL, this.Language.DataField.ModuleNumber, this.Language.DataField.ModuleMenuPosition, this.Language.DataField.ModuleNumber, this.Language.DataField.ModuleMenuPosition], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isNumber", "isNumber", "maxLength", "maxLength"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {

                    if (parent_module_id === "Select Parent Module") parent_module_id = null;

                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newModule = {
                        module_name: module_name,
                        module_description: module_description,
                        module_code: module_code,
                        module_number: Math.abs(module_number) || null,
                        module_menu_position: Math.abs(module_menu_position) || null,
                        is_child_module: is_child_module,
                        is_sub_module: is_sub_module,
                        module_url: module_url,
                        parent_module_id: parent_module_id,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_module_button").setAttribute("disabled", true);

                    this.updateModule(newModule);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_module = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'module') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_module: select_field_values_module });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    updateModule(newModule) {

        let moduleId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/settings/modules/${moduleId}`,
            data: newModule
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.SystemModule.UpdateSuccess + newModule.module_name, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_module_button").removeAttribute("disabled");

            this.props.history.push('/settings/modules');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SystemModule.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.SystemModule.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.SystemModule.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_module_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_module_button").setAttribute("disabled", true);


        let module_name = this.state.module_name;
        let logged_in_username = this.state.logged_in_username;
        let moduleId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteModule = {
            module_name: module_name,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/settings/modules/${moduleId}`,
            data: deleteModule
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.Language.NodeMessage.SystemModule.DeleteSuccess + deleteModule.module_name, 8000, 'rounded green');
            })


            document.getElementById("delete_module_button").removeAttribute("disabled");
            this.props.history.replace('/settings/modules');

        })
            .catch(error => {
                if (error.response) {

                    


                    window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_module_button").removeAttribute("disabled");

            });

    }




    render() {


        // let moduleId = this.props.match.params.id;



        return [
          // <LoaderWrapper key={0}/>,
          <Header key={1} history={this.props.history} language={this.Language} />,

          // <!-- START MAIN -->
          <div id="main" key={2}>
            <div className="wrapper green lighten-5">
              <LeftSideBar active_menu_level_1={'Access Management'} active_menu_level_2={'System Modules'} history={this.props.history} language={this.Language} />
              {/* <!-- START CONTENT --> */}
              <section id="content">
                <BreadcrumbsWrapper
                  page_title={`${this.Language.PageTitle.EditSystemModule} ${this.state.module_name}`}
                  breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.SystemModules, this.Language.BreadCrumbLabel.EditSystemModule]}
                  links={[`/settings/modules`, `/settings/modules`, `/settings/modules`]}
                  language={this.Language}
                />
                {/* <!--start container--> */}
                <div className="container">
                  <div className="row">
                    <div className="col s12 l12">
                      {this.state.error_messages}

                      <div className="card-panel">
                        {this.state.preloader && (
                          <div className="progress teal lighten-4">
                            <div className="indeterminate teal"></div>
                          </div>
                        )}

                        <h4 className="header2">{this.Language.PageLabel.EditSystemModule}</h4>
                        <div className="row">
                          <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                            <div className="row">
                              <div className="input-field col s8">
                                <i className="material-icons prefix">view_quilt</i>
                                <input id="module_name" name="module_name" type="text" ref="module_name" value={this.state.module_name} />
                                <label htmlFor="module_name" className={this.state.module_name && this.state.module_name !== null && typeof this.state.module_name !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.ModuleName} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input id="module_code" name="module_code" type="text" data-length="10" ref="module_code" value={this.state.module_code} onChange={this.handleInputChange} />
                                <label
                                  htmlFor="module_code"
                                  data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + '10'}
                                  className={this.state.module_code && this.state.module_code !== null && typeof this.state.module_code !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.ModuleCode} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s12">
                                <textarea
                                  id="module_description"
                                  className="materialize-textarea"
                                  data-length="300"
                                  name="module_description"
                                  ref="module_description"
                                  value={this.state.module_description}
                                  onChange={this.handleInputChange}></textarea>
                                <label
                                  htmlFor="module_description"
                                  data-error={this.Language.DataFieldError.MaxLength}
                                  className={this.state.module_description && this.state.module_description !== null && typeof this.state.module_description !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.ModuleDescription} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s4">
                                <input
                                  id="module_number"
                                  name="module_number"
                                  type="number"
                                  className="validate"
                                  data-length={this.state.text_limit}
                                  ref="module_number"
                                  value={this.state.module_number}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="module_number"
                                  data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit}
                                  className={
                                    (this.state.module_number && this.state.module_number !== null && typeof this.state.module_number !== 'undefined') || this.state.module_number === 0 ? 'active' : ''
                                  }>
                                  {this.Language.DataField.ModuleNumber} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input
                                  id="module_menu_position"
                                  name="module_menu_position"
                                  type="number"
                                  className="validate"
                                  data-length={this.state.text_limit}
                                  ref="module_menu_position"
                                  value={this.state.module_menu_position}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="module_menu_position"
                                  data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit}
                                  className={
                                    (this.state.module_menu_position && this.state.module_menu_position !== null && typeof this.state.module_menu_position !== 'undefined') ||
                                    this.state.module_menu_position === 0
                                      ? 'active'
                                      : ''
                                  }>
                                  {this.Language.DataField.ModuleMenuPosition} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input id="module_url" name="module_url" type="text" ref="module_url" value={this.state.module_url} onChange={this.handleInputChange} />
                                <label htmlFor="module_url" className={this.state.module_url && this.state.module_url !== null && typeof this.state.module_url !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.ModuleURL} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                              <div className="input-field col s12">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select data-select-id="select-options-parent_module_id" className="initialized" name="parent_module_id" ref="parent_module_id">
                                    {this.state.parent_module_id && (
                                      <option value={this.state.parent_module_id} selected>
                                        {this.state.parent_module_name}
                                      </option>
                                    )}
                                    <option value="Select Parent Module">{this.Language.DataFieldDefault.SelectParentModule}</option>
                                    {this.state.select_field_values_module.map((module_value, i) => {
                                      return module_value;
                                    })}
                                  </select>
                                </div>
                                <label>
                                  {this.Language.DataField.ParentModule}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="col s4">
                                <p>
                                  <input type="checkbox" id="is_child_module" ref="is_child_module" name="is_child_module" checked={this.state.is_child_module} onChange={this.handleCheckBoxChange} />
                                  <label htmlFor="is_child_module">{this.Language.DataField.ChildModule}</label>
                                </p>
                              </div>
                              <div className="col s4">
                                <p>
                                  <input type="checkbox" id="is_sub_module" ref="is_sub_module" name="is_sub_module" checked={this.state.is_sub_module} onChange={this.handleCheckBoxChange} />
                                  <label htmlFor="is_sub_module">{this.Language.DataField.SubModuleQ}</label>
                                </p>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                              <div className="col s4">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.DeactivateModule}
                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                    <span className="lever"></span>
                                    {this.Language.DataField.ActivateModule}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                              <div className="input-field col s8">
                                <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_module_button">
                                  {this.Language.ButtonLabel.UpdateModule}
                                </button>
                              </div>
                              {/* ::after */}
                              <div className="input-field col s2">
                                <Link className="waves-effect waves-light btn grey right" to={`/settings/modules`}>
                                  <i className="material-icons left">cancel</i>
                                  {this.Language.ButtonLabel.Cancel}
                                </Link>
                              </div>
                              <div className="input-field col s2">
                                <div
                                  className="waves-effect waves-light btn red"
                                  id="delete_module_button"
                                  onClick={() => {
                                    if (window.confirm(`${this.Language.ConfirmMessage.DeleteSystemModule} ${this.state.module_name}`)) {
                                      this.onDelete();
                                    }
                                  }}>
                                  <i className="material-icons left">delete</i>
                                  {this.Language.ButtonLabel.Delete}
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                          </form>
                          {/* ::after */}
                        </div>
                      </div>
                    </div>
                    {/* ::after */}
                  </div>
                </div>
                {/* <!--end container--> */}
              </section>
              {/* <!-- END CONTENT --> */}
              <FloatingActionButton language={this.Language} />
              {/* <RightSideBar language={this.Language}/> */}
            </div>
            {/* <!-- END WRAPPER --> */}
          </div>,
          // <!-- END MAIN --> */
          // <!-- //////////////////////////////////////////////////////////////////////////// -->
          <Footer key={3} language={this.Language} />
        ];
    }
}

export default EditSystemModule;