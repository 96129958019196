import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';



class EditLoginConfiguration extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateLoginConfiguration = this.updateLoginConfiguration.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getLoginConfiguration = this.getLoginConfiguration.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            loginConfigurationDetails: [],
            check_ip_address: false,
            login_timeout_interval_seconds: 0,
            max_failed_attempt: 5,
            require_validation_on_new_device_use: false,
            allow_mutiple_login_per_device: false,
            send_notification_on_new_login: false,
            send_notification_on_new_device_use: false,
            send_email_on_new_login: false,
            send_email_on_new_device_use: false,
            send_sms_on_new_login: false,
            send_sms_on_new_device_use: false,
            global_two_step_verification: false,
            show_login_message: false,
            login_message: '',
            security_question1: '',
            security_question2: '',
            security_question3: '',
            hash_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_user: '',
            scopes: {},
            text_limit: 12,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EditLoginConfiguration + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();


    }



    componentDidUpdate() {

    }


    componentWillUnmount() {

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['update:login_config', 'action:login_config'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getLoginConfiguration();
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getLoginConfiguration() {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);


        axios.get(`${this.apiURL}/api/v1/authenticate/login/config?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({
                    loginConfigurationDetails: response.data.data,
                    check_ip_address: (response.data.data[0].check_ip_address === true || response.data.data[0].check_ip_address === 'true') ? true : false || false,
                    login_timeout_interval_seconds: response.data.data[0].login_timeout_interval_seconds || this.state.login_timeout_interval_seconds,
                    max_failed_attempt: response.data.data[0].max_failed_attempt || this.state.max_failed_attempt,
                    require_validation_on_new_device_use: (response.data.data[0].require_validation_on_new_device_use === true || response.data.data[0].require_validation_on_new_device_use === 'true') ? true : false || false,
                    allow_mutiple_login_per_device: (response.data.data[0].allow_mutiple_login_per_device === true || response.data.data[0].allow_mutiple_login_per_device === 'true') ? true : false || false,
                    send_notification_on_new_login: (response.data.data[0].send_notification_on_new_login === true || response.data.data[0].send_notification_on_new_login === 'true') ? true : false || false,
                    send_notification_on_new_device_use: (response.data.data[0].send_notification_on_new_device_use === true || response.data.data[0].send_notification_on_new_device_use === 'true') ? true : false || false,
                    send_email_on_new_login: (response.data.data[0].send_email_on_new_login === true || response.data.data[0].send_email_on_new_login === 'true') ? true : false || false,
                    send_email_on_new_device_use: (response.data.data[0].send_email_on_new_device_use === true || response.data.data[0].send_email_on_new_device_use === 'true') ? true : false || false,
                    send_sms_on_new_login: (response.data.data[0].send_sms_on_new_login === true || response.data.data[0].send_sms_on_new_login === 'true') ? true : false || false,
                    send_sms_on_new_device_use: (response.data.data[0].send_sms_on_new_device_use === true || response.data.data[0].send_sms_on_new_device_use === 'true') ? true : false || false,
                    global_two_step_verification: (response.data.data[0].global_two_step_verification === true || response.data.data[0].global_two_step_verification === 'true') ? true : false || false,
                    show_login_message: (response.data.data[0].show_login_message === true || response.data.data[0].show_login_message === 'true') ? true : false || false,
                    login_message: response.data.data[0].login_message || '',
                    security_question1: response.data.data[0].security_question1 || '',
                    security_question2: response.data.data[0].security_question2 || '',
                    security_question3: response.data.data[0].security_question3 || '',
                    hash_id: response.data.data[0].hash_id || '',
                    status: response.data.data[0].status || '',
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                });



                if (this.state.status === 'Active') {
                    this.setState({ status_active: true });
                } else {
                    this.setState({ status_active: false });
                }


                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                this.setState({ preloader: null });



            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

            });

        this.setState({ preloader: null });

    }




    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


        if (name === 'check_ip_address') { this.setState({ [name]: !this.state.check_ip_address }); }
        if (name === 'require_validation_on_new_device_use') { this.setState({ [name]: !this.state.require_validation_on_new_device_use }); }
        if (name === 'allow_mutiple_login_per_device') { this.setState({ [name]: !this.state.allow_mutiple_login_per_device }); }
        if (name === 'send_notification_on_new_login') { this.setState({ [name]: !this.state.send_notification_on_new_login }); }
        if (name === 'send_notification_on_new_device_use') { this.setState({ [name]: !this.state.send_notification_on_new_device_use }); }
        if (name === 'send_email_on_new_login') { this.setState({ [name]: !this.state.send_email_on_new_login }); }
        if (name === 'send_email_on_new_device_use') { this.setState({ [name]: !this.state.send_email_on_new_device_use }); }
        if (name === 'send_sms_on_new_login') { this.setState({ [name]: !this.state.send_sms_on_new_login }); }
        if (name === 'send_sms_on_new_device_use') { this.setState({ [name]: !this.state.send_sms_on_new_device_use }); }
        if (name === 'global_two_step_verification') { this.setState({ [name]: !this.state.global_two_step_verification }); }
        if (name === 'show_login_message') { this.setState({ [name]: !this.state.show_login_message }); }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.EditLoginConfiguration}`)) {


            let check_ip_address = this.refs.check_ip_address.checked;

            let login_timeout_interval_seconds = this.refs.login_timeout_interval_seconds.value.replace(/\s/g, "");
            let max_failed_attempt = this.refs.max_failed_attempt.value.replace(/\s/g, "");

            let require_validation_on_new_device_use = this.refs.require_validation_on_new_device_use.checked;
            let allow_mutiple_login_per_device = this.refs.allow_mutiple_login_per_device.checked;
            let send_notification_on_new_login = this.refs.send_notification_on_new_login.checked;
            let send_notification_on_new_device_use = this.refs.send_notification_on_new_device_use.checked;
            let send_email_on_new_login = this.refs.send_email_on_new_login.checked;
            let send_email_on_new_device_use = this.refs.send_email_on_new_device_use.checked;
            let send_sms_on_new_login = this.refs.send_sms_on_new_login.checked;
            let send_sms_on_new_device_use = this.refs.send_sms_on_new_device_use.checked;
            let global_two_step_verification = this.refs.global_two_step_verification.checked;
            let show_login_message = this.refs.show_login_message.checked;

            let login_message = this.refs.login_message.value;
            let security_question1 = this.refs.security_question1.value.trim();
            let security_question2 = this.refs.security_question2.value.trim();
            let security_question3 = this.refs.security_question3.value.trim();
            let hash_id = this.state.hash_id;

            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let username = this.state.logged_in_username;
            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.LoginConfiguration.EditErrorTitle, [login_timeout_interval_seconds, login_timeout_interval_seconds, login_timeout_interval_seconds, max_failed_attempt, max_failed_attempt, login_message, security_question1, security_question2, security_question3], [this.Language.DataField.TimeoutInterval, this.Language.DataField.TimeoutInterval, this.Language.DataField.TimeoutInterval, this.Language.DataField.MaximumNumberofAttempts, this.Language.DataField.MaximumNumberofAttempts, this.Language.DataField.LoginMessage, this.Language.DataField.SecurityQuestion1, this.Language.DataField.SecurityQuestion2, this.Language.DataField.SecurityQuestion3], ["isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "isRequired", "isRequired", "isRequired", "isRequired"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {


                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newLoginConfiguration = {
                        username: username,
                        hash_id: hash_id,
                        check_ip_address: check_ip_address,
                        login_timeout_interval_seconds: Math.abs(login_timeout_interval_seconds) || null,
                        max_failed_attempt: Math.abs(max_failed_attempt) || null,
                        require_validation_on_new_device_use: require_validation_on_new_device_use,
                        allow_mutiple_login_per_device: allow_mutiple_login_per_device,
                        send_notification_on_new_login: send_notification_on_new_login,
                        send_notification_on_new_device_use: send_notification_on_new_device_use,
                        send_email_on_new_login: send_email_on_new_login,
                        send_email_on_new_device_use: send_email_on_new_device_use,
                        send_sms_on_new_login: send_sms_on_new_login,
                        send_sms_on_new_device_use: send_sms_on_new_device_use,
                        global_two_step_verification: global_two_step_verification,
                        show_login_message: show_login_message,
                        login_message: login_message,
                        security_question1: security_question1,
                        security_question2: security_question2,
                        security_question3: security_question3,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_login_configuration_button").setAttribute("disabled", true);

                    this.updateLoginConfiguration(newLoginConfiguration);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }







    updateLoginConfiguration(newLoginConfiguration) {

        let loginConfigId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/authenticate/login/config/${loginConfigId}`,
            data: newLoginConfiguration
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                if (newLoginConfiguration.status === "Active") window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.UpdateSuccess, 8000, 'rounded green');
                if (newLoginConfiguration.status === "Inactive") window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.UpdateSuccessInactive, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_login_configuration_button").removeAttribute("disabled");

            this.props.history.push('/security/login/configuration');


        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                   
                }
               

                this.setState({ preloader: null });
                document.getElementById("update_login_configuration_button").removeAttribute("disabled");

            });

    }




    onDelete() {

        document.getElementById("delete_login_configuration_button").setAttribute("disabled", true);


        let username = this.state.logged_in_username;
        let loginConfigId = this.props.match.params.id;

        const userOS = this.Browser.getOS();

        const deleteLoginConfiguration = {
            username: username,
            last_updated_by: username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/authenticate/login/config/${loginConfigId}`,
            data: deleteLoginConfiguration
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.DeleteSuccess, 8000, 'rounded green');

            })


            document.getElementById("delete_login_configuration_button").removeAttribute("disabled");
            this.props.history.replace('/security/login/configuration');

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.DoesNotExist, 8000, 'rounded deep-orange darken-4');



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_login_configuration_button").removeAttribute("disabled");

            });

    }




    render() {


        let loginConfigId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Security"} active_menu_level_2={"Login Configuration"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditLoginConfiguration}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.LoginConfiguration, this.Language.BreadCrumbLabel.EditLoginConfiguration]} links={[`/users`, `/security/login/configuration`, `/security/login/configuration/edit/${loginConfigId}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.EditLoginConfiguration}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="login_timeout_interval_seconds" name="login_timeout_interval_seconds" type="number" className="validate" data-length={this.state.text_limit} ref="login_timeout_interval_seconds" value={this.state.login_timeout_interval_seconds} onChange={this.handleInputChange} />
                                                            <label htmlFor="login_timeout_interval_seconds" data-error={this.Language.DataFieldError.IntegerNumber} className={((this.state.login_timeout_interval_seconds && this.state.login_timeout_interval_seconds !== null && typeof this.state.login_timeout_interval_seconds !== 'undefined') || this.state.login_timeout_interval_seconds === 0) ? "active" : ""}>
                                                                {this.Language.DataField.InactivityTimeoutInterval} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="max_failed_attempt" name="max_failed_attempt" type="number" className="validate" data-length="2" ref="max_failed_attempt" value={this.state.max_failed_attempt} onChange={this.handleInputChange} />
                                                            <label htmlFor="max_failed_attempt" data-error={this.Language.DataFieldError.IntegerNumber} className={((this.state.max_failed_attempt && this.state.max_failed_attempt !== null && typeof this.state.max_failed_attempt !== 'undefined') || this.state.max_failed_attempt === 0) ? "active" : ""}>
                                                                {this.Language.DataField.MaxNumberofFailedLoginAttempts} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="check_ip_address" ref="check_ip_address" name="check_ip_address" checked={this.state.check_ip_address} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="check_ip_address">{this.Language.DataField.CheckUserIPAddress}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="require_validation_on_new_device_use" ref="require_validation_on_new_device_use" name="require_validation_on_new_device_use" checked={this.state.require_validation_on_new_device_use} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="require_validation_on_new_device_use">{this.Language.DataField.RequireValidationforNewDevices}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="allow_mutiple_login_per_device" ref="allow_mutiple_login_per_device" name="allow_mutiple_login_per_device" checked={this.state.allow_mutiple_login_per_device} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="allow_mutiple_login_per_device">{this.Language.DataField.AllowMultipleUserLoginsOnADevice}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="send_notification_on_new_login" ref="send_notification_on_new_login" name="send_notification_on_new_login" checked={this.state.send_notification_on_new_login} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="send_notification_on_new_login">{this.Language.DataField.SendNotificationOnNewLogin}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="send_notification_on_new_device_use" ref="send_notification_on_new_device_use" name="send_notification_on_new_device_use" checked={this.state.send_notification_on_new_device_use} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="send_notification_on_new_device_use">{this.Language.DataField.SendNotificationOnNewDeviceLogins}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_email_on_new_login" ref="send_email_on_new_login" name="send_email_on_new_login" checked={this.state.send_email_on_new_login} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="send_email_on_new_login">{this.Language.DataField.SendEmailOnNewLogin}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_email_on_new_device_use" ref="send_email_on_new_device_use" name="send_email_on_new_device_use" checked={this.state.send_email_on_new_device_use} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="send_email_on_new_device_use">{this.Language.DataField.SendEmailOnNewDeviceLogins}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_sms_on_new_login" ref="send_sms_on_new_login" name="send_sms_on_new_login" checked={this.state.send_sms_on_new_login} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="send_sms_on_new_login">{this.Language.DataField.SendSMSOnNewLogin}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_sms_on_new_device_use" ref="send_sms_on_new_device_use" name="send_sms_on_new_device_use" checked={this.state.send_sms_on_new_device_use} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="send_sms_on_new_device_use">{this.Language.DataField.SendSMSOnNewDeviceLogins}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="global_two_step_verification" ref="global_two_step_verification" name="global_two_step_verification" checked={this.state.global_two_step_verification} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="global_two_step_verification">{this.Language.DataField.RequireTwoStepAuthenticationForAllUsers}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">question_answer</i>
                                                            <input id="security_question1" name="security_question1" type="text" ref="security_question1" value={this.state.security_question1} onChange={this.handleInputChange} />
                                                            <label htmlFor="security_question1" className={(this.state.security_question1 && this.state.security_question1 !== null && typeof this.state.security_question1 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SecurityQuestion1} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="security_question2" name="security_question2" type="text" ref="security_question2" value={this.state.security_question2} onChange={this.handleInputChange} />
                                                            <label htmlFor="security_question2" className={(this.state.security_question2 && this.state.security_question2 !== null && typeof this.state.security_question2 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SecurityQuestion2} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="security_question3" name="security_question3" type="text" ref="security_question3" value={this.state.security_question3} onChange={this.handleInputChange} />
                                                            <label htmlFor="security_question3" className={(this.state.security_question3 && this.state.security_question3 !== null && typeof this.state.security_question3 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SecurityQuestion3} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="show_login_message" ref="show_login_message" name="show_login_message" checked={this.state.show_login_message} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="show_login_message">{this.Language.DataField.ShowLoginMessage}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s8">
                                                            <textarea id="login_message" className="materialize-textarea" data-length="300" name="login_message" ref="login_message" value={this.state.login_message} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="login_message" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.login_message && this.state.login_message !== null && typeof this.state.login_message !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.LoginMessage}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateLoginConfiguration}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateLoginConfiguration}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_login_configuration_button">
                                                                {this.Language.ButtonLabel.UpdateLoginConfiguration}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s2">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/security/login/configuration`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_login_configuration_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteLoginConfiguration}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditLoginConfiguration;