import * as en from '../translations/en'
import * as fr from '../translations/fr'

export default class LanguageService {

    getSessionLanguage() {
        try {
            let lang = sessionStorage.getItem('ep_lang');

            if (lang !== null && typeof lang !== 'undefined') {
                return lang;
            } else {
                throw new Error(); // this forces the execution to move to the catch section of this try-catch
            }

        }
        catch (error) {

            try {
                let token = sessionStorage.getItem('ep_token');
                let payload = this.decode(token);
                let lang = payload.language;

                if (lang !== null && typeof lang !== 'undefined') {

                    switch (lang) {
                        case 'English':
                            lang = "en";
                            break;

                        case 'French':
                        case 'Français':
                            lang = "fr";
                            break;

                        // case 'Chinese':
                        // case '中文':
                        //     lang = "zh";
                        //     break;

                        default:
                            lang = "en";
                    }

                    sessionStorage.setItem('ep_lang', lang);
                    return lang;

                } else {
                    throw new Error(); // this forces the execution to move to the catch section of this try-catch
                }

            }
            catch (error) {
                let lang = window.navigator.language;
                lang = lang.toLowerCase();

                switch (lang) {
                    case 'en':
                    case 'en-us':
                    case 'en-gb':
                    case 'en-au':
                    case 'en-bz':
                    case 'en-ca':
                    case 'en-ie':
                    case 'en-jm':
                    case 'en-nz':
                    case 'en-ph':
                    case 'en-za':
                    case 'en-tt':
                    case 'en-zw':
                        lang = "en";
                        break;

                    case 'fr':
                    case 'fr-fr':
                    case 'fr-be':
                    case 'fr-ca':
                    case 'fr-lu':
                    case 'fr-mc':
                    case 'fr-ch':
                        lang = "fr";
                        break;

                    // case 'zh':
                    // case 'zh-cn':
                    // case 'zh-hk':
                    // case 'zh-sg':
                    // case 'zh-tw':
                    //     lang = "zh";
                    //     break;

                    default:
                        lang = "en";
                }

                sessionStorage.setItem('ep_lang', lang);
                return lang;
            }

        }
    }


    decode(token) {
        try {
            let payload = JSON.parse(atob(token.split('.')[1]));
            return payload;
        } catch (error) {
            return null;
        }
    }


    getTranslation() {

        let lang = this.getSessionLanguage();
        let language = en;

        switch (lang) {
            case 'en':
                language = en;
                break;

            case 'fr':
                language = fr;
                break;

            // case 'zh':
            //     language = en;
            //     break;

            default:
                language = en;
        }


        return language;

    }


    getBaseTranslation() {

        return en;

    }


    setTranslation(language) {
        try {

            let lang = 'en';

            switch (language) {
                case 'English':
                case 'en':
                    lang = 'en';
                    break;

                case 'Français':
                case 'French':
                case 'fr':
                    lang = 'fr';
                    break;

                // case '中文':
                // case 'Chinese':
                // case 'zh':
                //     lang = 'zh';
                //     break;

                default:
                    lang = 'en';
            }


            sessionStorage.setItem('ep_lang', lang);

        } catch (error) {

        }
    }



    getLanguageList() {
        const langauges = ['English', 'Français'];
        // const langauges = ['English', 'Français', '中文'];
        return langauges;
    }


    getBaseDefaultLanguage() {
        const langauge = 'English';
        return langauge;
    }





}