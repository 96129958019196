import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class AddExporterExportedItem extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addExporterExportedItem = this.addExporterExportedItem.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            message: '',
            status: '',
            pick_list_values: [],
            select_field_values_exporter: [],
            preloader: true,
            logged_in_username: '',
            text_limit: 3000,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.AddExporterExportedItem + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if (this.Auth.checkAccessScope(['create:exported_items', 'action:exported_items'], 'view') === false) {
            this.props.history.replace('/exporters/exported/items');
        } else {
            this.getDropdownValues("exporter");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }




    prepareData(return_option) {

        let exporter_id = this.refs.exporter_id.value;
        if (exporter_id === "Select Exporter") exporter_id = "";

        let exported_item = this.refs.exported_item.value;
        let exported_item_description = this.refs.exported_item_description.value;


        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;
        let last_updated_by = this.state.logged_in_username;


        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ExporterExportedItem.AddErrorTitle, [exporter_id, exported_item, exported_item_description, exported_item_description], [this.Language.DataField.Exporter, this.Language.DataField.ExportedItem, this.Language.DataField.Description, this.Language.DataField.Description], ["isRequired", "isRequired", "isRequired", "maxLength"], 0, this.state.text_limit) }, () => {

            if (this.state.error_messages === null) {

                if (exporter_id === "Select Exporter") exporter_id = null;


                const userOS = this.Browser.getOS();


                //make call to database 
                const newExporterExportedItem = {
                    exporter_id: exporter_id,
                    exported_item: exported_item,
                    exported_item_description: exported_item_description,
                    status: status,
                    created_by: created_by,
                    last_updated_by: last_updated_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken()
                }


                this.setState({ preloader: true });
                document.getElementById("save_exporter_exported_item_button").setAttribute("disabled", true);


                this.addExporterExportedItem(newExporterExportedItem, return_option);


            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }



    clearForm() {

        document.getElementById("add_exporter_exported_item_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }


    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {

                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_exporter = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'exporter') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_exporter: select_field_values_exporter });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    addExporterExportedItem(newExporterExportedItem, return_option) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/exporter/exported/items`,
            data: newExporterExportedItem
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.AddSuccess + newExporterExportedItem.exported_item, 8000, 'rounded green');

            })


            this.setState({ preloader: null });
            document.getElementById("save_exporter_exported_item_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/exporters/exported/items');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("save_exporter_exported_item_button").removeAttribute("disabled");

            });

    }






    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Exporter Management"} active_menu_level_2={"Exported Items"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.AddExporterExportedItem}`} breadcrumbs={[this.Language.BreadCrumbLabel.ExporterManagement, this.Language.BreadCrumbLabel.ExporterExportedItems, this.Language.BreadCrumbLabel.AddExporterExportedItem]} links={[`/exporters`, `/exporters/exported/items`, `/exporters/exported/items/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.AddExporterExportedItem}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_exporter_exported_item_form" ref="add_exporter_exported_item_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-exporter" className="initialized" name="exporter_id" id="exporter_id" ref="exporter_id">
                                                                    <option value="Select Exporter" >{this.Language.DataFieldDefault.SelectExporter}</option>
                                                                    {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                                                        return (
                                                                            exporter_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Exporter} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="exported_item" type="text" ref="exported_item" name="exported_item" />
                                                            <label htmlFor="exported_item" className="">
                                                                {this.Language.DataField.ExportedItem} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="exported_item_description" className="materialize-textarea" data-length={this.state.text_limit} ref="exported_item_description"></textarea>
                                                            <label htmlFor="exported_item_description" data-error={this.Language.DataFieldError.MaxLength}>
                                                                {this.Language.DataField.Description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateExportedItem}
                                                                    <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateExportedItem}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveExportedItem.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_exporter_exported_item_button">
                                                                {this.Language.ButtonLabel.SaveExportedItem}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveExportedItem.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/exporters/exported/items`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar  language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddExporterExportedItem;