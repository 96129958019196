import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';




class PartnerProfile extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getPartner = this.getPartner.bind(this);
        this.state = {
            partnerDetails: '',
            result: '',
            message: '',
            hash_partner: ''
        }
        this.Auth = new AuthService();
        this.DateService = new DateService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
        this.getProfile();
    
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.PartnerProfile + ' - ' + this.Language.EEGWebPortal;
        this.checkAccessRight();
        this.getPartner();

        
    }


    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null){
            this.setState({hash_user: user_profile.user,
                username: user_profile.username,
                hash_partner: user_profile.partner
            });
        }

    }


    checkAccessRight(){

        if ( (this.Auth.checkAccessScope(['read:partner', 'action:partner'], 'view') === false) || this.state.hash_partner !== this.props.match.params.id){
             this.props.history.replace('/');   
        };
 
     }




     getPartner(){
        let partnerId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/partners/${partnerId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {
                this.setState({partnerDetails: response.data.data[0]}, () => {
                    
                })
                

                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx

                  

                  if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                //
              });
    }





    render(){

        let date_of_creation = this.state.partnerDetails.date_of_creation + '';
        date_of_creation = this.DateService.convertToCalendarFullDate(date_of_creation);

        let partnerId = this.props.match.params.id;

 

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Partner Agency"} active_menu_level_2={""} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.PartnerProfile}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.PartnerProfile]} links={[`/`, `/partners/${partnerId}`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <div id="profile-page" className="section">
                                    <div id="profile-page-header" className="card" style={{overflow: "hidden"}}>
                                        <div className="card-image waves-effect waves-block waves-light">
                                            <img className="activator" src="/assets/images/gallary/30.png" alt=""/>
                                        </div>
                                        <figure className="card-profile-image">
                                            <img src="/images/bank.png" alt="" className="circle white z-depth-2 responsive-img activator gradient-shadow"/>
                                        </figure>
                                        <div className="card-content">
                                            <div className="row pt-2">
                                                <div className="col s12 m4 offset-m2">
                                                    <h4 className="card-title grey-text text-darken-4">{this.state.partnerDetails.organisation_name} </h4>
                                                    <p className="medium-small grey-text">{this.state.partnerDetails.registration_number}</p>
                                                </div>
                                                <div className="col s12 m2 center-align">
                                                    <h4 className="card-title grey-text text-darken-4">{this.state.partnerDetails.organisation_type}</h4>
                                                    <p className="medium-small grey-text">{this.Language.DataField.PartnerCategory}</p>
                                                </div>
                                                <div className="col s12 m2 center-align">
                                                    <h4 className="card-title grey-text text-darken-4">{date_of_creation}</h4>
                                                    <p className="medium-small grey-text">{this.Language.DataField.RegistrationDate}</p>
                                                </div>
                                                <div className="col s12 m1 right-align">
                                                    <a className="btn-floating activator waves-effect waves-light teal accent-2 right" href="#!">
                                                        <i className="material-icons">account_balance</i>
                                                    </a>
                                                </div>
                                                {/* :;after */}
                                            </div>
                                        </div>
                                        <div className="card-reveal" style={{display: "none", transform: "translateY(0px)"}}>
                                            <p>
                                                <span className="card-title grey-text text-darken-4">
                                                {this.state.partnerDetails.organisation_name} 
                                                    <i className="material-icons right">close</i>
                                                </span>
                                                <span>
                                                    <i className="material-icons cyan-text text-darken-2">account_balance</i>
                                                    {this.state.partnerDetails.registration_number}
                                                </span>
                                            </p>
                                            <p>
                                                {this.state.partnerDetails.website}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">perm_phone_msg</i>
                                                 {this.state.partnerDetails.phone}, {this.state.partnerDetails.mobile}, {this.state.partnerDetails.alternate_phone}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">email</i>
                                                 {this.state.partnerDetails.email}, {this.state.partnerDetails.alternate_email}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">card_membership</i>
                                                 {date_of_creation}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">assignment_turned_in</i>
                                                {this.Language.DataField.PartnerCategory}: {this.state.partnerDetails.organisation_type}
                                            </p>
                                        </div>
                                    </div>
                                    <div id="profile-page-content" className="row">
                                        <div id="profile-page-sidebar" className="col s12 m5">
                                            <div className="card cyan">
                                                <div className="card-content white-text">
                                                    <span className="card-title">{this.Language.DataField.Description}: <b>{this.state.partnerDetails.organisation_description}</b> </span>
                                                    <p>
                                                        {this.Language.DataField.PartnerCategory}: <b>{this.state.partnerDetails.organisation_type}</b>
                                                    </p>
                                                    <p> {this.Language.DataField.Status}: <b>{this.state.partnerDetails.status ? (this.state.partnerDetails.status === 'Active' ?  this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null }</b> </p> 
                                                </div>
                                            </div>
                                            <ul id="profile-page-about-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <b className="teal-text"> {this.Language.PageLabel.ContactDetails} </b>
                                                        </div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">perm_phone_msg</i>
                                                            {this.Language.DataField.Phone}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.phone}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">phone</i>
                                                            {this.Language.DataField.AlternatePhone}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.alternate_phone}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">smartphone</i>
                                                            {this.Language.DataField.Mobile}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.mobile}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">email</i>
                                                            {this.Language.DataField.Email}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.email}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">email</i>
                                                            {this.Language.DataField.AlternateEmail}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.alternate_email}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">language</i>
                                                            {this.Language.DataField.Website}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.website}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">card_membership</i>
                                                            {this.Language.DataField.RegistrationDate}
                                                        </div>
                                                        <div className="col s7 right-align">{date_of_creation}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <br/>
                                            <br/>
                                        </div>
                                        <div id="profile-page-wall" className="col s12 m7">
                                            
                                            <ul id="profile-page-about-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">location_on</i>
                                                            <b className="teal-text"> {this.Language.PageLabel.Address} </b>
                                                        </div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine1}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_line1}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine2}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_line2}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine3}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_line3}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.City}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_city}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.State}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_state}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.Country}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_country}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AlternateAddress}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.alternate_address}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.MailingAddress}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.mailing_address}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.ZipCode}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.post_zip_code}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.PostalArea}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.postal_area}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            {/* ::after */}
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                    {/* <Link className="waves-effect waves-light btn" to={`/partner/profile/edit/${this.state.exporterDetails.hash_id}`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>  */}

                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]  
        )
    }
}

export default PartnerProfile;