import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class AddSystemAccessRightObject extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.AddSystemAccessRightObject = this.AddSystemAccessRightObject.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_module: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.CreateSystemAccessRightObject + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['create:access_right_object', 'action:access_right_object'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("module");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }



    prepareData(return_option) {


        let system_object_name = this.refs.system_object_name.value.trim().toLowerCase();

        let system_object_type = this.refs.system_object_type.value;
        if (system_object_type === "Select System Object Type") system_object_type = "";

        let system_object_action_type = this.refs.system_object_action_type.value;
        if (system_object_action_type === "Select System Object Action Type") system_object_action_type = "";


        let module_name_id = this.refs.module_name_id.value;
        if (module_name_id === "Select Module") module_name_id = "";

        let sub_module_name_id = this.refs.sub_module_name_id.value;
        if (sub_module_name_id === "Select Sub Module") sub_module_name_id = "";


        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;



        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.SystemAccessRightObject.AddErrorTitle, [system_object_name, system_object_type, system_object_action_type, module_name_id], [this.Language.DataField.SystemObjectName, this.Language.DataField.SystemObjectType, this.Language.DataField.SystemActionType, this.Language.DataField.Module], ["isRequired", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

            if (this.state.error_messages === null) {

                if (module_name_id === "Select Module") module_name_id = null;
                if (sub_module_name_id === "Select Sub Module") sub_module_name_id = null;

                const userOS = this.Browser.getOS();


                //make call to database 
                const newSystemAccessRightObject = {
                    system_object_name: system_object_name,
                    module_id: module_name_id,
                    sub_module_id: sub_module_name_id,
                    system_object_type: system_object_type,
                    system_object_action_type: system_object_action_type,
                    status: status,
                    created_by: created_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }



                this.setState({ preloader: true });
                document.getElementById("create_system_access_right_object_button").setAttribute("disabled", true);

                this.AddSystemAccessRightObject(newSystemAccessRightObject, return_option);

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }


    clearForm() {

        document.getElementById("add_system_access_right_object_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }


    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_module = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'module') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_module: select_field_values_module });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    AddSystemAccessRightObject(newSystemAccessRightObject, return_option) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/settings/access/right/objects`,
            data: newSystemAccessRightObject
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.AddSuccess + newSystemAccessRightObject.system_object_name, 8000, 'rounded green');


            })


            this.setState({ preloader: null });
            document.getElementById("create_system_access_right_object_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/settings/access/right/objects');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.AlreadyExist, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("create_system_access_right_object_button").removeAttribute("disabled");

            });

    }




    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"Access Right Objects"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.CreateSystemAccessRightObject}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.SystemAccessRightObject, this.Language.BreadCrumbLabel.AddSystemAccessRightObject]} links={[`/settings/modules`, `/settings/access/right/objects`, `/settings/access/right/object/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.AddANewSystemAccessRightObject}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_system_access_right_object_form" ref="add_system_access_right_object_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">view_quilt</i>
                                                            <input id="system_object_name" name="system_object_name" type="text" ref="system_object_name" />
                                                            <label htmlFor="system_object_name" className="">
                                                                {this.Language.DataField.SystemObjectName} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-system_object_type" className="initialized" name="system_object_type" ref="system_object_type">
                                                                    <option value="Select System Object Type">{this.Language.DataFieldDefault.SelectSystemObjectType}</option>
                                                                    <option value="view">{this.Language.DataField.View}</option>
                                                                    <option value="process">{this.Language.DataField.Process}</option>
                                                                    <option value="button">{this.Language.DataField.Button}</option>
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.SystemObjectType}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-system_object_action_type" className="initialized" name="system_object_action_type" ref="system_object_action_type">
                                                                    <option value="Select System Object Action Type">{this.Language.DataFieldDefault.SelectSystemObjectActionType}</option>
                                                                    <option value="create">{this.Language.DataField.Create}</option>
                                                                    <option value="read">{this.Language.DataField.Read}</option>
                                                                    <option value="update">{this.Language.DataField.Update}</option>
                                                                    <option value="action">{this.Language.DataField.Action}</option>
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.SystemActionType}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s7">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-module_name_id" className="initialized" name="module_name_id" ref="module_name_id">
                                                                    <option value="Select Module">{this.Language.DataFieldDefault.SelectModule}</option>
                                                                    {this.state.select_field_values_module.map((module_value, i) => {
                                                                        return (
                                                                            module_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Module}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s5">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-sub_module_name_id" className="initialized" name="sub_module_name_id" ref="sub_module_name_id">
                                                                    <option value="Select Sub Module">{this.Language.DataFieldDefault.SelectSubModule}</option>
                                                                    {this.state.select_field_values_module.map((module_value, i) => {
                                                                        return (
                                                                            module_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.SubModule}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateSystemAccessRightObject}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateSystemAccessRightObject}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveNewSystemAccessRightObject.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_system_access_right_object_button">
                                                                {this.Language.ButtonLabel.SaveNewSystemAccessRightObject}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveNewSystemAccessRightObject.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/settings/access/right/objects`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddSystemAccessRightObject;