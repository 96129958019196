import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class AddUserRole extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addUserRole = this.addUserRole.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_role: [],
            preloader: true,
            logged_in_username: '',
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.CreateUserRole + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`


        if ((this.Auth.checkAccessScope(['create:roles', 'action:roles'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("user_type");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }



    prepareData(return_option) {


        let role_title = this.refs.role_title.value.trim();
        let role_description = this.refs.role_description.value;

        let role_type = this.refs.role_type.value;
        if (role_type === "Select Role Type") role_type = "";


        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;



        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.UserRole.AddErrorTitle, [role_title, role_description, role_type, role_description], [this.Language.DataField.RoleTitle, this.Language.DataField.RoleDescription, this.Language.DataField.RoleType, this.Language.DataField.RoleDescription,], ["isRequired", "isRequired", "isRequired", "maxLength"], 0, this.state.text_limit) }, () => {

            if (this.state.error_messages === null) {

                if (role_type === "Select Role Type") role_type = null;

                const userOS = this.Browser.getOS();

                //make call to database 
                const newUserRole = {
                    role_title: role_title,
                    role_description: role_description,
                    role_type: role_type,
                    status: status,
                    created_by: created_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }



                this.setState({ preloader: true });
                document.getElementById("create_role_button").setAttribute("disabled", true);

                this.addUserRole(newUserRole, return_option);

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }



    clearForm() {

        document.getElementById("add_user_role_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }


    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_role = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'user_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_role: select_field_values_role });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    addUserRole(newUserRole, return_option) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/user/roles`,
            data: newUserRole
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.UserRole.AddSuccess + newUserRole.role_title, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("create_role_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/settings/user/roles');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.UserRole.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserRole.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }

                    //   this.props.history.push('/settings/user/roles');


                } else if (error.request) {

                } else {
                    // Something happened in setting up the request that triggered an Error

                }

                this.setState({ preloader: null });
                document.getElementById("create_role_button").removeAttribute("disabled");

            });

    }




    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"User Roles"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.CreateUserRole}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.UserRoles, this.Language.BreadCrumbLabel.AddUserRole]} links={[`/settings/modules`, `/settings/user/roles`, `settings/user/role/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.AddANewUserRole}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_user_role_form" ref="add_user_role_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">assignment_ind</i>
                                                            <input id="role_title" name="role_title" type="text" ref="role_title" />
                                                            <label htmlFor="role_title" className="">
                                                                {this.Language.DataField.RoleTitle} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="role_description" className="materialize-textarea" data-length={this.state.text_limit} name="role_description" ref="role_description"></textarea>
                                                            <label htmlFor="role_description" data-error={this.Language.DataFieldError.MaxLength}>
                                                                {this.Language.DataField.RoleDescription} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-role_type" className="initialized" name="role_type" ref="role_type">
                                                                    <option value="Select Role Type">{this.Language.DataFieldDefault.SelectRoleType}</option>
                                                                    {this.state.select_field_values_role.map((role_value, i) => {
                                                                        return (
                                                                            role_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.RoleType} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateUserRole}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateUserRole}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveNewUserRole.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_role_button">
                                                                {this.Language.ButtonLabel.SaveNewUserRole}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveNewUserRole.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/settings/user/roles`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddUserRole;