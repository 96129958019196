import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
// import {Link} from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import FileUpload from '../components/FileUpload';
import URLService from '../modules/URLService';
import FileService from '../modules/FileService';

const $ = require('jquery');

class DataDownloadUpload extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.getDropdownValues = this.getDropdownValues.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.state = {
      message: '',
      status: '',
      data_option: 'download',
      data_download: true,
      data_upload: false,
      from_last_updated_on_time: '12:00PM',
      from_last_updated_on_date: '',
      pick_list_values: [],
      select_field_values_table_name: [],
      downloaded_data_result: null,
      preloader: true,
      logged_in_username: '',
      text_limit: 300,
      error_messages: null,
      fileUploadItem_1: null,
      first_time_page_loading: true,
      close_error_messages: (
        <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
          <span aria-hidden="true">×</span>
        </button>
      )
    };
    this.File = new FileService();
    this.Auth = new AuthService();
    this.Validate = new ValidationService();
    this.DateService = new DateService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.apiURL = URLService.prototype.getApiURL();
  }

  componentWillMount() {}

  componentDidMount() {
    document.title = this.Language.PageTitle.DataDownloadUpload + ' - ' + this.Language.EEGWebPortal;
    this.getProfile();
    this.setState({preloader: null});

    window.$('.dropify').dropify({
      messages: {
        default: this.Language.FileUpload.Default,
        replace: this.Language.FileUpload.Replace,
        remove: this.Language.FileUpload.Remove,
        error: this.Language.FileUpload.Error
      }
    });

    const monthsFull = this.Language.DatePicker.monthsFull.split(',');
    const monthsShort = this.Language.DatePicker.monthsShort.split(',');
    const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(',');
    const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(',');
    const today = this.Language.DatePicker.today;
    const clear = this.Language.DatePicker.clear;
    const close = this.Language.DatePicker.close;
    const labelMonthNext = this.Language.DatePicker.labelMonthNext;
    const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
    const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
    const labelYearSelect = this.Language.DatePicker.labelYearSelect;

    window.$('.datepicker').pickadate({
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      today: today,
      clear: clear,
      close: close,
      labelMonthNext: labelMonthNext,
      labelMonthPrev: labelMonthPrev,
      labelMonthSelect: labelMonthSelect,
      labelYearSelect: labelYearSelect
      // firstDay: 1,
      // format: 'dd mmmm yyyy',
      // formatSubmit: 'yyyy/mm/dd',
      // selectMonths: true,
      // selectYears: true,
      // selectMonths: true, // Creates a dropdown to control month
      // selectYears: 15, // Creates a dropdown of 15 years to control year,
      // closeOnSelect: false, // Close upon selecting a date,
      // container: undefined, // ex. 'body' will append picker to body
    });

    const cancel = this.Language.DatePicker.cancel;

    window.$('.timepicker').pickatime({
      clear: clear,
      close: close,
      cancel: cancel
    });

    // this is to solve the issue with the time picker diappearing when clicked
    window.$('.timepicker').on('mousedown', function(event) {
      event.preventDefault();
    });

    if (this.state.first_time_page_loading === true ) {

      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();

      var monthNamesEng = [
        this.Language.Calendar.MonthsFull.January,
        this.Language.Calendar.MonthsFull.February,
        this.Language.Calendar.MonthsFull.March,
        this.Language.Calendar.MonthsFull.April,
        this.Language.Calendar.MonthsFull.May,
        this.Language.Calendar.MonthsFull.June,
        this.Language.Calendar.MonthsFull.July,
        this.Language.Calendar.MonthsFull.August,
        this.Language.Calendar.MonthsFull.September,
        this.Language.Calendar.MonthsFull.October,
        this.Language.Calendar.MonthsFull.November,
        this.Language.Calendar.MonthsFull.December
      ];

      let hours = date.getHours();
      hours = parseInt(hours, 10);
      let minutes = date.getMinutes();
      minutes = parseInt(minutes, 10);
      let time_period = 'AM';
      if (hours > 12) {
        hours = hours - 12;
        time_period = 'PM';
      }
      if (day < 10) day = '0' + day;
      if (hours < 10) hours = '0' + hours;
      if (minutes < 10) minutes = '0' + minutes;

      const full_date = day + ' ' + monthNamesEng[month] + ', ' + year;
      const full_time = hours + ':' + minutes + time_period;


      

      this.setState({from_last_updated_on_date: full_date, from_last_updated_on_time: full_time, first_time_page_loading: false});
    }
    
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    window.$('select').material_select('destroy');
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState({logged_in_username: user_profile.username}, () => {
        this.setState({
          fileUploadItem_1: (
            <FileUpload
              fieldName={this.Language.PageLabel.UploadDataFile}
              fileExtensions={'xls xlsx csv'}
              fieldID={'upload-file-001'}
              maxFileSize={'3M'}
              defaultFile={''}
              fileName={'Data_Upload'}
              pageDataCategory={'Data_Upload'}
              pageName={'DataDownloadUpload'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.TableName}
            />
          )
        });

        this.checkAccessRight();
      });
    }
  }

  checkAccessRight() {
    let default_home = '/';
    const dashboards = this.Auth.getDashboards() || [];
    if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${'5'}`;

    if (this.Auth.checkAccessScope(['create:data', 'update:data', 'action:data'], 'view') === false) {
      this.props.history.replace(default_home);
    } else {
      this.getDropdownValues('data_tables');
    }
  }

  handleCloseAlert() {
    this.setState({error_messages: null}); // change the value of error_message to close the alert box
  }

  handleCheckBoxChange(event) {
    const target = event.target;
    // const checked = target.checked;
    const name = target.id;

    if (name === 'data_download') {
      this.setState({
        data_download: true,
        data_upload: false,
        data_option: 'download'
      });
    }

    if (name === 'data_upload') {
      this.setState({
        data_upload: true,
        data_download: false,
        data_option: 'upload'
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  }

  prepareData() {
    let table_name = this.refs.table_name.value;
    if (table_name === 'Select Table Name') table_name = '';

    let from_last_updated_on_date = this.DateService.convertToFullDate(this.refs.from_last_updated_on_date.value);
    let from_last_updated_on_time = this.DateService.convertToFullTime(this.refs.from_last_updated_on_time.value);

    this.setState({from_last_updated_on_date: this.refs.from_last_updated_on_date.value, from_last_updated_on_time: this.refs.from_last_updated_on_time.value});

    let temp_date = from_last_updated_on_date.split(' ');
    const final_from_last_updated_on_date = temp_date[0] + ' ' + from_last_updated_on_time;

    let created_by = this.state.logged_in_username;
    let last_updated_by = this.state.logged_in_username;

    this.setState(
      {
        error_messages: this.Validate.validation(
          this.state.close_error_messages,
          this.Language.ReactValidationMessage.DataDownloadUpload.DownloadErrorTitle,
          [table_name, from_last_updated_on_date, from_last_updated_on_time],
          [this.Language.DataField.TableName, this.Language.DataField.FromLastUpdatedOnDate, this.Language.DataField.FromLastUpdatedOnTime],
          ['isRequired', 'isRequired', 'isRequired'],
          0,
          this.state.text_limit
        )
      },
      () => {
        if (this.state.error_messages === null) {
          if (table_name === 'Select Table Name') table_name = null;

          const userOS = this.Browser.getOS();

          //make call to database
          const newDataDownloadRequest = {
            table_name: table_name,
            from_last_updated_on_date: final_from_last_updated_on_date,
            include_deleted_records: true,
            created_by: created_by,
            last_updated_by: last_updated_by,
            device_name: this.state.logged_in_username + "'s " + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: 'web',
            token: this.Auth.getToken()
          };

          this.setState({preloader: true});
          document.getElementById('download_data_button').setAttribute('disabled', true);


          this.downloadData(newDataDownloadRequest);
        } else {
          window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
        }
      }
    );
  }

  clearForm() {
    // this function is unused but included to enable the use $ variable
    document.getElementById('download_upload_data_form').reset();

    $('.switch')
      .find('input[type=checkbox]')
      .prop('checked', true);
  }

  getDropdownValues(pick_list_names) {
    axios
      .get(`${this.apiURL}/api/v1/settings/picklists`, {
        params: {
          tk: this.Auth.getToken(),
          pick_list_names: pick_list_names
        }
      })
      .then(response => {
        this.setState({pick_list_values: response.data.data}, () => {

          if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {
            const select_field_values_table_name = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'data_tables') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_table_name: select_field_values_table_name});
          }
        });

        window.$('select').material_select();
        this.setState({preloader: null});
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null});
      });
  }

  downloadData(newDataDownloadRequest) {

    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
    const currentNumberOfRecords = 'all_records';


    // CHeck if the table is allowed for download
    const can_download_table_list_encrypted = ['user_accounts', 'organisations', 'exporters', 'eeg_claims'];

    let encrypted_tables_len = can_download_table_list_encrypted.length;
    let allow_to_proceed = true;
    let data_download_table_option = 'ue';

    for (let i = 0; i < encrypted_tables_len; i++) {
      if (newDataDownloadRequest.table_name === can_download_table_list_encrypted[i]) {
        data_download_table_option = 'e'
        break;
      }
    }



    const cannot_download_table_list = [
      'login_configurations',
      'modules',
      'audit_activities',
      'access_rights',
      'data_field_security',
      'notifications',
      'objects',
      'password_change_history',
      'password_configurations',
      'system_access_right_objects',
      'system_parameters',
      'tenant',
      'user_account_device_history',
      'user_account_devices',
      'online_users',
      'user_signins'
    ];


    let exclude_tables_len = cannot_download_table_list.length;

    for (let i = 0; i < exclude_tables_len; i++) {
      if (newDataDownloadRequest.table_name === cannot_download_table_list[i]) {
        allow_to_proceed = false;
        break;
      }
    }
    


    if (allow_to_proceed === true) {

       axios
         .get(
           `${this.apiURL}/api/v1/data/management/download/data/${data_download_table_option}/${
             newDataDownloadRequest.table_name
           }?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}&from_luo=${newDataDownloadRequest.from_last_updated_on_date}&include_dr=${
             newDataDownloadRequest.include_deleted_records
           }`
         )
         .then(response => {
           this.setState({status: response.data.status});
           if (response.data.status === 200) window.Materialize.toast(response.data.message, 8000, 'rounded green');

           this.setState({downloaded_data_result: response.data.data, preloader: null}, () => {

             if (this.state.downloaded_data_result && this.state.downloaded_data_result.length > 0) {
               let file_name = newDataDownloadRequest.table_name + '_' + newDataDownloadRequest.from_last_updated_on_date.split(' ').join('_');
               file_name = file_name.split(':').join('-');
               let data_fields = Object.keys(this.state.downloaded_data_result[0]);

               this.File.downloadCSV(this.state.downloaded_data_result, file_name, data_fields);
             } else {
               window.Materialize.toast(this.Language.NodeMessage.DataDownloadUpload.NoData, 8000, 'rounded green');
             }

             document.getElementById('download_data_button').removeAttribute('disabled');
           });
         })
         .catch(error => {
           if (error.response) {

             if (error.response.data.message[0].msg) {
               let len = error.response.data.message.length;

               for (let i = 0; i < len; i++) {
                 window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
               }
             } else {
               if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DataDownloadUpload.Error, 8000, 'rounded deep-orange darken-4');
             }
           } else if (error.request) {
           } else {
             // Something happened in setting up the request that triggered an Error
           }

           this.setState({preloader: null});
           document.getElementById('download_data_button').removeAttribute('disabled');
         });

    } else {

      window.Materialize.toast(this.Language.NodeMessage.DataDownloadUpload.Error, 8000, 'rounded deep-orange darken-4');

      this.setState({preloader: null});
      document.getElementById('download_data_button').removeAttribute('disabled');
    }
     
  }

  render() {
    const lengthCheck = 25;

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Data Management'} active_menu_level_2={'Data Download/Upload'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.DataDownloadUpload}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.DataManagement, this.Language.BreadCrumbLabel.DataDownloadUpload]}
              links={[`/data/management/tables`, `/data/management/download/upload`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <div className="row">
                <div className="col s12 l12">
                  {this.state.error_messages}

                  <div className="card-panel">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <h4 className="header2">{this.Language.PageLabel.DownloadUploadData}</h4>
                    <div className="row">
                      <div className="row">
                        <div className="input-field col s6">
                          <div className="select-wrapper initialized">
                            <span className="caret">▼</span>
                            <select data-select-id="select-options-table_name" className="initialized" name="table_name" id="table_name" ref="table_name">
                              <option value="Select Table Name">{this.Language.DataFieldDefault.SelectTableName}</option>
                              {this.state.select_field_values_table_name.map((table_value, i) => {
                                return table_value;
                              })}
                            </select>
                          </div>
                          <label>{this.Language.DataField.TableName} *</label>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col s3">
                          {/* <div className="row"> */}
                          <label htmlFor="data_option_select">{this.Language.DataField.DataOption}</label>
                          <p>
                            <input name="data_option" type="radio" id="data_download" ref="data_download" checked={this.state.data_download} onChange={this.handleCheckBoxChange} />
                            <label htmlFor="data_download">{this.Language.DataField.Download}</label>
                          </p>
                        </div>
                        <div className="col s3">
                          <br />
                          <p>
                            <input name="data_option" type="radio" id="data_upload" ref="data_upload" checked={this.state.data_upload} onChange={this.handleCheckBoxChange} />
                            <label htmlFor="data_upload">{this.Language.DataField.Upload}</label>
                          </p>
                          {/* </div> */}
                        </div>
                        {/* ::after */}
                      </div>
                      <br />
                      {this.state.data_download && <br />}
                      {this.state.data_download && (
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="from_last_updated_on_date"
                              readOnly={false}
                              id="P875200303date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="P875200303date_root"
                              value={this.state.from_last_updated_on_date}
                              onChange={this.handleInputChange}
                            />
                            <label
                              htmlFor="from_last_updated_on_date"
                              className={
                                this.state.from_last_updated_on_date && this.state.from_last_updated_on_date !== null && typeof this.state.from_last_updated_on_date !== 'undefined' ? 'active' : ''
                              }>
                              {this.Language.DataField.FromLastUpdatedOnDate} *{/* ::after */}
                            </label>
                          </div>
                          {/* ::after */}
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="timepicker picker__input"
                              ref="from_last_updated_on_time"
                              readOnly={false}
                              id="P875200303time"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="P875200303time_root"
                              value={this.state.from_last_updated_on_time}
                              onChange={this.handleInputChange}
                            />
                            <label
                              htmlFor="from_last_updated_on_time"
                              className={
                                this.state.from_last_updated_on_time && this.state.from_last_updated_on_time !== null && typeof this.state.from_last_updated_on_time !== 'undefined' ? 'active' : ''
                              }>
                              {this.Language.DataField.FromLastUpdatedOnTime} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                      )}
                      {this.state.data_download && <br />}
                      {this.state.data_download && <br />}
                      {this.state.data_upload && (
                        <div className="row">
                          <div className="col s3"></div>
                          <div className="col s3 update_existing_data">
                            <p>
                              <input type="checkbox" id="update_existing_data" ref="update_existing_data" name="update_existing_data" />
                              <label htmlFor="update_existing_data">{this.Language.DataField.UpdateExistingData}</label>
                            </p>
                          </div>
                        </div>
                      )}
                      {this.state.data_upload && <br />}
                      {this.state.data_upload && this.state.fileUploadItem_1}
                      {this.state.data_upload && <br />}
                      {this.state.data_upload && <br />}
                      {this.state.data_upload && <br />}
                      {this.state.data_upload && <br />}
                      {this.state.data_download && (
                        <div className="row">
                          <div className={this.Language.ButtonLabel.DownloadData.length <= lengthCheck ? 'input-field col s5' : 'input-field col s7'} onClick={this.prepareData}>
                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="download_data_button">
                              {this.Language.ButtonLabel.DownloadData}
                            </button>
                          </div>
                          {/* ::after */}
                        </div>
                      )}
                      {this.state.data_download && <br />}
                      {this.state.data_download && <br />}
                      {this.state.data_download && <br />}
                      {/* ::after */}
                    </div>
                  </div>
                </div>
                {/* ::after */}
              </div>
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar  language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default DataDownloadUpload;
