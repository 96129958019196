import React, {Component} from 'react';


class ValuesChartVertical extends Component{
    constructor(props){
        super(props);
        this.state = {
            chartID: props.chartID || 'chartID',
            title: props.title || '',
            subTitle: props.subTitle || '',
            lowerTitle: props.lowerTitle || '',
            iconName: props.iconName || 'language',
            showArrowIcon: props.showArrowIcon || false,
            arrowIconDirection: props.arrowIconDirection || 'up',
            titleTrendingValue: props.titleTrendingValue || '',
            titleTrendingValuePrefix: props.titleTrendingValuePrefix || '',
            titleTrendingValueSuffix: props.titleTrendingValueSuffix || '',
            textColour: props.textColour || 'purple',
            subTitleTextColour: props.subTitleTextColour || 'grey',
            buttonTitle:  props.buttonTitle || '',
            chartElement:  props.chartElement || null,
            link:  props.link || '#!',
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '4',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '4',
        
        }

    }


    componentDidMount(){ 

    }


    render(){



        
        return (
            // <!--Values Chart Vertical start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div id={this.state.chartID} className="card">
                    <div className="card-content">
                        <h4 className="header m-0">
                            {this.state.title}
                            <i className={`material-icons right ${this.state.subTitleTextColour}-text lighten-3`}>more_vert</i>
                        </h4>
                        <p className={`no-margin ${this.state.subTitleTextColour}-text lighten-3 medium-small`}>{this.state.subTitle}</p>
                        <h3 className="header">
                            {this.state.titleTrendingValuePrefix} {this.state.titleTrendingValue}{this.state.titleTrendingValueSuffix}
{(this.state.showArrowIcon === true)? ((this.state.arrowIconDirection === "up")? <i className={`material-icons green-text text-accent-2`}>arrow_upward</i>:<i className={`material-icons deep-orange-text text-accent-2`}>arrow_downward</i>) : null } 
                        </h3>
                        {this.state.chartElement}
                        <br/>
                        <br/>
                        <div className="center-align">
                            <p className="lighten-3">{this.state.lowerTitle}</p>
                            <a href={this.state.link} className="waves-effect waves-light btn gradient-45deg-purple-deep-orange gradient-shadow">{this.state.buttonTitle}</a>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
            // <!--Values Chart Vertical end-->
        )
    }
}

export default ValuesChartVertical;