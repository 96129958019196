import React, {Component} from 'react';
// import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

class DocumentDownloader extends Component {
  constructor(props) {
    super(props);
    this.getDocument = this.getDocument.bind(this);
    this.state = {
      document: null
    };
    this.Auth = new AuthService();
    this.DateService = new DateService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.apiURL = URLService.prototype.getApiURL();
    this.timeOutTracker = null;
  }

  componentDidMount() {
    this.getDocument();
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutTracker);
  }

  getDocument() {
    let documentId = this.props.match.params.id;
    let documentOwnerId = this.props.match.params.exp || '';
    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

    const user_language = this.Language.Lang;

    let href = '';

    if (documentId === 'user_guide') href = `${this.apiURL}/api/v1/file/documentation/download/${documentId}?tk=${this.Auth.getToken()}&lang=${user_language}`;
    if (documentId !== 'user_guide') href = `${this.apiURL}/api/v1/file/download/${documentId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&do=${documentOwnerId}&lang=${user_language}`;

    let link = document.createElement('a');
    link.id = 'documentDownloadLink'; //give the element an ID!
    link.href = href;

    link.style = 'visibility:hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);



    // axios.get(`${this.apiURL}/api/v1/file/download/${documentId}?tk=${this.Auth.getToken()}`)
    //     .then(response => {

    //         this.setState({
    //             document: response.data
    //         }, () => {

    //             let newData, newFileName, link;

    //             newFileName =  'dataexport.png';

    //             let blob = new Blob([response.data], {type: "image/png"});

    //             // let blob = new File([response.data], "uploaded_file.png", { type: "image/png", lastModified: Date.now() })

    //             if (navigator.msSaveBlob) { // IE 10+
    //                 navigator.msSaveBlob(blob, newFileName)
    //             } else {
    //                 link = document.createElement("a");
    //                 if (link.download !== undefined){
    //                     // feature detection, Browsers that support HTML5 download attribute
    //                     newData = URL.createObjectURL(blob);
    //                     link.setAttribute("href", newData);
    //                     link.setAttribute("download", newFileName);
    //                     link.style = "visibility:hidden";
    //                     document.body.appendChild(link);
    //                     link.click();
    //                     document.body.removeChild(link);
    //

    //                     window.URL.revokeObjectURL(newData); // this was added later for memory management. please test first
    //                 }
    //             }

    //         });

    //     })
    //     .catch(function (error) {
    //         if (error.response) {
    //           // The request was made and the server responded with a status code
    //           // that falls out of the range of 2xx

    //         } else if (error.request) {
    //           // The request was made but no response was received
    //           // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //           // http.ClientRequest in node.js

    //         } else {
    //           // Something happened in setting up the request that triggered an Error

    //         }

    //       });
  }

  render() {

    this.timeOutTracker = setTimeout(function () {
      window.close();
    }, 2000);

    return <div>{this.Language.ConfirmMessage.CloseTabAfterFileDownload}</div>;
  }
}

export default DocumentDownloader;
