import React, { Component } from 'react';
import DatatableBodyRow from './DatatableBodyRow';


class DatatableBody extends Component{
    constructor(props){
        super(props);
        this.state = {
            tableBodyData: props.tableBodyData,
            fields: props.fields,
            fieldDataTypes: props.fieldDataTypes,
            fieldDecimalPlaces: props.fieldDecimalPlaces,
            dateFormat: props.dateFormat,
            timeFormat: props.timeFormat,
            link: props.link,
            current_path: props.current_path,
            return_query_prefix: props.return_query_prefix,
            link_status: props.link_status,
            linkToBlankTarget: props.linkToBlankTarget,
            linkToBlankTargetToken: props.linkToBlankTargetToken,
            language: props.language
        }
    }



    render(){

        // Run through the Data to identify the selected fields
        const len = this.state.fields.length;


        const bodyItems = this.state.tableBodyData.map((datarow,  i) => {

            let newDataRow = [];

            const freezer_object = Object.freeze(datarow); // freeze the mapped object so that there are no errorneous changes to the object

            for (let i = 0; i < len; i++) {
                newDataRow.push(datarow[this.state.fields[i]]); // data row items

            }

            return(
                <DatatableBodyRow key={i} keyPosition={i} hash_id={freezer_object.hash_id} tableBodyRowData={newDataRow} rowDataTypes={this.state.fieldDataTypes} rowDecimalPlaces={this.state.fieldDecimalPlaces} dateFormat={this.state.dateFormat} timeFormat={this.state.timeFormat} link={this.state.link} return_query_prefix={this.state.return_query_prefix} current_path={this.state.current_path} link_status={this.state.link_status} linkToBlankTarget={this.state.linkToBlankTarget} linkToBlankTargetToken={this.state.linkToBlankTargetToken} language={this.state.language}/>
            )

        })

            return (
                <tbody>
                    {bodyItems}
                </tbody>
            )

    }

}

export default DatatableBody;