import React, {Component} from 'react';
import BreadcrumbsWrapperItem from './BreadcrumbsWrapperItem';


class BreadcrumbsWrapper extends Component{
    constructor(props){
        super(props);
        this.state = {
            page_title: props.page_title,
            breadcrumbs: props.breadcrumbs,
            links: props.links,
            language: props.language,
        }

    }


    render(){

        // const ulStyle41 = {
        //     whiteSpace: 'nowrap',
        //     opacity: 1,
        //     left: 1418.89,
        //     position: 'absolute',
        //     top: 130,
        //     display: 'none'
        //   };


        const breadcrumbItems = this.state.breadcrumbs.map((breadcrumb,  i) => {
            let position = 'first';

            if (i === 0){ 
                    position = 'first';
                } else if (i < (this.state.breadcrumbs.length - 1)) { 
                    position = 'middle';
                } else { 
                    position = 'last';
                }

            return(
                <BreadcrumbsWrapperItem key={i} link={this.state.links[i]} itemName={breadcrumb} position={position} />
            )
        })


        // let item_count = 2;
        // let item_badge_caption = "";

        // if (item_count === 1) {
        //     item_badge_caption = this.state.language.DataValue.new.one;
        // };


        // if (item_count > 1) {
        //     item_badge_caption = this.state.language.DataValue.new.multiple;
        // };


        
        return (
            // <!--breadcrumbs start-->
            <div id="breadcrumbs-wrapper">
                {/* <!-- Search for small screen --> */}
                <div className="header-search-wrapper grey lighten-2 hide-on-large-only">
                    <input type="text" name="Search" className="header-search-input z-depth-2" placeholder="Explore"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col s10 m6 16">
                            <h5 className="breadcrumbs-title">{this.state.page_title}</h5>
                            <ol className="breadcrumbs">
                                {breadcrumbItems}
                            </ol>
                        </div>
                        {/* <div className="col s2 m6 16">
                            <a data-activates="breadcrumbs_dropdown1" className="btn dropdown-settings waves-effect waves-light breadcrumbs-btn right  teal gradient-shadow" href="#!">
                                <i className="material-icons hide-on-med-and-up">settings</i>
                                <span className="hide-on-small-only">{this.state.language.BreadCrumbLabel.Settings}</span>
                                <i className="material-icons right">arrow_drop_down</i>
                            </a>
                            <ul id="breadcrumbs_dropdown1" className="dropdown-content" style={ulStyle41}>
                                <li>
                                    <a href="#!" className="grey-text text-darken-2">
                                        {this.state.language.BreadCrumbLabel.Access}
                                        <span className="badge">1</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="grey-text text-darken-2">
                                        {this.state.language.BreadCrumbLabel.Profile}
                                        <span className="new badge" data-badge-caption={item_badge_caption}>{item_count}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="grey-text text-darken-2">
                                        {this.state.language.BreadCrumbLabel.Notifications}
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        {/* ::after */}
                    </div>
                </div>
            </div>
            // <!--breadcrumbs end-->
        )
    }
}

export default BreadcrumbsWrapper;