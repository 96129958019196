import React, { Component } from 'react';


class DataMapSectionCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            chartID: props.chartID || 'polar-chart-holder',
            title: props.title || '',
            subTitle: props.subTitle || '',
            chartTitle: props.chartTitle || '',
            chartSubTitle: props.chartSubTitle || '',
            mapHeight: props.mapHeight || '300',
            mapWidth: props.mapWidth || '718.003',
            cardColour: props.cardColour || 'gradient-45deg-purple-deep-orange',
            textColour: props.textColour || 'white',
            subTextColour: props.subTextColour || 'grey',
            chartElement: props.chartElement || null,
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '6',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '6',
        }
    }


    componentDidMount(){ 

        if (this.state.cardColour === 'blue' || this.state.cardColour === 'cyan') this.setState({cardColour: 'gradient-45deg-light-blue-cyan'});
        if (this.state.cardColour === 'purple' || this.state.cardColour === 'orange') this.setState({cardColour: 'gradient-45deg-purple-deep-orange'});
        if (this.state.cardColour === 'green' || this.state.cardColour === 'teal') this.setState({cardColour: 'gradient-45deg-green-teal'});
        if (this.state.cardColour === 'red' || this.state.cardColour === 'pink') this.setState({cardColour: 'gradient-45deg-red-pink'});
        if (this.state.cardColour === 'amber') this.setState({cardColour: 'gradient-45deg-amber-amber'});

    }

    
    render(){
        return (
            // <!--Data Map Section Card start-->
            <div className={`container ${this.state.cardColour}`}>
                <div className="row section">
                <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                    <h5 className={`${this.state.textColour}-text`}>{this.state.title}</h5>
                    <p className={`${this.state.subTextColour}-text text-lighten-4`}>{this.state.subTitle}</p>
                    <div id="world-map-markers">
                    <div className="jvectormap-container" style={{width: '100%', height: '100%', position: 'relative', overflow: 'hidden'}}>
                    {/* <svg width={this.state.mapWidth} height={this.state.mapHeight}/><g transform="scale(3.032983116903993) translate(-444.0054885361095, -209.82169157660127)"/> */}
                    </div>
                    </div>
                </div>
                <div className="col l4 offset-l2 s12">
                    <h5 className={`${this.state.textColour}-text`}>{this.state.chartTitle}</h5>
                    <p className={`${this.state.subTextColour}-text text-lighten-4`}>{this.state.chartSubTitle}</p>
                    <div id={this.state.chartID}>
                        {this.state.chartElement}
                    </div>
                </div>
                </div>
            </div>
            // <!--Data Map Section Card end-->
        )
    }
}

export default DataMapSectionCard;