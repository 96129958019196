import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';



class EditExporterExportedItemProfile extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateExporterExportedItem = this.updateExporterExportedItem.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getExporterExportedItem = this.getExporterExportedItem.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            exporter_id: '',
            exporter_name: '',
            registration_number: '',
            exported_item: '',
            exported_item_description: '',
            hash_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_organisation: null,
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EditExporterExportedItem + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getExporterExportedItem();

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                hash_organisation: user_profile.organisation,
                logged_in_username: user_profile.username
            }, () => {
                this.checkAccessRight();
            });

        }

    }

    exp_id

    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:exported_item', 'action:exported_item'], 'view') === false) || this.state.hash_organisation !== this.props.match.params.exp_id) {
            this.props.history.replace(`/exporter/${this.state.hash_organisation}/exported/items/`);
        }

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getExporterExportedItem() {

        let exporterExportedItemId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/exporter/exported/items/specific/${exporterExportedItemId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({
                    exporter_id: response.data.data[0].exporter_id,
                    exporter_name: response.data.data[0].exporter_name || '',
                    registration_number: response.data.data[0].registration_number,
                    exported_item: response.data.data[0].exported_item || '',
                    exported_item_description: response.data.data[0].exported_item_description || '',
                    hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });


                this.setState({ preloader: null });

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                   


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveExporterExportedItem} ${this.refs.exported_item.value.trim()}`)) {



            let exporter_id = this.state.exporter_id;

            let exported_item = this.refs.exported_item.value;
            let exported_item_description = this.refs.exported_item_description.value;


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";


            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;



            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ExporterExportedItem.EditErrorTitle, [exporter_id, exported_item, exported_item_description, exported_item_description], [this.Language.DataField.Exporter, this.Language.DataField.ExportedItem, this.Language.DataField.Description, this.Language.DataField.Description], ["isRequired", "isRequired", "isRequired", "maxLength"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {


                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newExporterExportedItem = {
                        exporter_id: exporter_id,
                        exported_item: exported_item,
                        exported_item_description: exported_item_description,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }



         

                    this.setState({ preloader: true });
                    document.getElementById("update_exporter_exported_item_button").setAttribute("disabled", true);

                    this.updateExporterExportedItem(newExporterExportedItem);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }






    updateExporterExportedItem(newExporterExportedItem) {

        let exporterExportedItemId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/exporter/exported/items/${exporterExportedItemId}`,
            data: newExporterExportedItem
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
               

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.UpdateSuccess + newExporterExportedItem.exported_item, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_exporter_exported_item_button").removeAttribute("disabled");

            this.props.history.push(`/exporter/${this.state.hash_organisation}/exported/items/`);

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_exporter_exported_item_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_exporter_exported_item_button").setAttribute("disabled", true);


        let exporter_id = this.state.exporter_id;
        let exported_item = this.state.exported_item;
        let logged_in_username = this.state.logged_in_username;
        let exporterExportedItemId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteDataFieldSecurity = {
            exporter_id: exporter_id,
            exported_item: exported_item,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/exporter/exported/items/${exporterExportedItemId}`,
            data: deleteDataFieldSecurity
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.DeleteSuccess + deleteDataFieldSecurity.exported_item, 8000, 'rounded green');
            })


            document.getElementById("delete_exporter_exported_item_button").removeAttribute("disabled");
            this.props.history.replace(`/exporter/${this.state.hash_organisation}/exported/items/`);

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExporterExportedItem.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_exporter_exported_item_button").removeAttribute("disabled");

            });

    }




    render() {


        const exporterId = this.props.match.params.exp_id;
        const exporterExportedItemId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Organisation"} active_menu_level_2={""} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditExporterExportedItem}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ExporterProfile, this.Language.BreadCrumbLabel.ExporterExportedItems, this.Language.BreadCrumbLabel.EditExporterExportedItem]} links={[`/`, `/exporter/profile/${exporterId}`, `/exporter/${exporterId}/exported/items/`, `/exporter/${exporterId}/exported/items/edit/${exporterExportedItemId}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.EditExporterExportedItem}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="exporter_name" type="text" ref="exporter_name" value={this.state.exporter_name} />
                                                            <label htmlFor="exporter_name" className={(this.state.exporter_name && this.state.exporter_name !== null && typeof this.state.exporter_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Exporter} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="exported_item" type="text" ref="exported_item" value={this.state.exported_item} onChange={this.handleInputChange} />
                                                            <label htmlFor="exported_item" className={(this.state.exported_item && this.state.exported_item !== null && typeof this.state.exported_item !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ExportedItem} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="exported_item_description" className="materialize-textarea" data-length={this.state.text_limit} ref="exported_item_description" value={this.state.exported_item_description} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="exported_item_description" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.exported_item_description && this.state.exported_item_description !== null && typeof this.state.exported_item_description !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateExportedItem}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateExportedItem}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_exporter_exported_item_button">
                                                                {this.Language.ButtonLabel.UpdateExporterExportedItem}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s4">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/exporter/${this.state.hash_organisation}/exported/items/`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_exporter_exported_item_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteExporterExportedItem} ${this.state.exported_item}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditExporterExportedItemProfile;