import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class AddExporterCategory extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addExporterCategory = this.addExporterCategory.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.state = {
            message: '',
            status: '',
            preloader: true,
            logged_in_username: '',
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.CreateExporterCategory + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.setState({ preloader: null });

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }




    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if (this.Auth.checkAccessScope(['create:categories', 'action:categories'], 'view') === false) {
            this.props.history.replace('/categorisation/exporter');
        }

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }



    prepareData(return_option) {


        let category_name = this.refs.category_name.value;
        let category_description = this.refs.category_description.value;
        let category_level = this.refs.category_level.value;


        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;
        let last_updated_by = this.state.logged_in_username;


        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ExporterCategory.AddErrorTitle, [category_name, category_description, category_level, category_level, category_description], [this.Language.DataField.CategoryName, this.Language.DataField.Description, this.Language.DataField.CategoryLevel, this.Language.DataField.CategoryLevel, this.Language.DataField.Description], ["isRequired", "isRequired", "isRequired", "isNumber", "maxLength"], 0, this.state.text_limit) }, () => {

            if (this.state.error_messages === null) {


                const userOS = this.Browser.getOS();

                //make call to database 
                const newExporterCategory = {
                    category_name: category_name,
                    category_description: category_description,
                    category_level: Math.abs(category_level),
                    status: status,
                    created_by: created_by,
                    last_updated_by: last_updated_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken()
                }


                this.setState({ preloader: true });
                document.getElementById("save_exporter_category_button").setAttribute("disabled", true);


                this.addExporterCategory(newExporterCategory, return_option);


            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }



    clearForm() {

        document.getElementById("add_exporter_category_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }



    addExporterCategory(newExporterCategory, return_option) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/categorisation/exporter`,
            data: newExporterCategory
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.AddSuccess + newExporterCategory.category_name, 8000, 'rounded green');

            })


            this.setState({ preloader: null });
            document.getElementById("save_exporter_category_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/categorisation/exporter');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("save_exporter_category_button").removeAttribute("disabled");

            });

    }






    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Categorisation"} active_menu_level_2={"Exporter Categorisation"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.CreateExporterCategory}`} breadcrumbs={[this.Language.BreadCrumbLabel.Categorisation, this.Language.BreadCrumbLabel.ExporterCategories, this.Language.BreadCrumbLabel.AddExporterCategory]} links={[`/`, `/categorisation/exporter`, `/categorisation/exporter/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.AddExporterCategory}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_exporter_category_form" ref="add_exporter_category_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="category_name" type="text" ref="category_name" name="category_name" />
                                                            <label htmlFor="category_name" className="">
                                                                {this.Language.DataField.CategoryName} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="category_level" type="number" className="validate" ref="category_level" name="category_level" />
                                                            <label htmlFor="category_level" data-error={this.Language.DataFieldError.IntegerNumber} className="">
                                                                {this.Language.DataField.CategoryLevel} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="category_description" className="materialize-textarea" data-length={this.state.text_limit} ref="category_description"></textarea>
                                                            <label htmlFor="category_description" data-error={this.Language.DataFieldError.MaxLength}>
                                                                {this.Language.DataField.Description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateExporterCategory}
                                                                    <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateExporterCategory}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveExporterCategory.length <= lengthCheck ? "input-field col s6" : "input-field col s8"}>
                                                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_exporter_category_button">
                                                                {this.Language.ButtonLabel.SaveExporterCategory}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveExporterCategory.length <= lengthCheck ? "input-field col s6" : "input-field col s4"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/categorisation/exporter`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar  language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddExporterCategory;