import React, {Component} from 'react';
import FormatService from '../modules/FormatService';


class DataChart extends Component{
    constructor(props){
        super(props);
        this.state = {
            chartID: props.chartID || 'chartID',
            title: props.title || '',
            mainValue: props.mainValue || 0,
            mainValuePrefix: props.mainValuePrefix || '',
            mainValueSuffix: props.mainValueSuffix || '',
            subValue: props.subValue || '',
            subValuePrefix: props.subValuePrefix || '',
            subValueSuffix: props.subValueSuffix || '',
            subValueTextColour: props.subValueTextColour || '',
            Lang: props.Lang || "en",
            valueIsDecimal: props.valueIsDecimal || true,
            valueDecimalPlaces: props.valueDecimalPlaces || 2,
            subValueIsDecimal: props.subValueIsDecimal || false,
            subValueDecimalPlaces: props.subValueDecimalPlaces || 0,
            chartElement:  props.chartElement || null,
            badgeColour: props.badgeColour || 'gradient-45deg-light-blue-cyan',
            badgeValue: props.badgeValue || '',
            badgeValuePrefix: props.badgeValuePrefix || '',
            badgeValueSuffix: props.badgeValueSuffix || '',
            badgeStatus: props.badgeStatus || 'new',
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '4',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '4',
            link: props.link || '#!',
        }
        this.Format = new FormatService();

    }

    componentDidMount(){ 

        if (this.state.badgeColour === 'blue' || this.state.badgeColour === 'cyan') this.setState({badgeColour: 'gradient-45deg-light-blue-cyan'});
        if (this.state.badgeColour === 'purple' || this.state.badgeColour === 'orange') this.setState({badgeColour: 'gradient-45deg-purple-deep-orange'});
        if (this.state.badgeColour === 'green' || this.state.badgeColour === 'teal') this.setState({badgeColour: 'gradient-45deg-green-teal'});
        if (this.state.badgeColour === 'red' || this.state.badgeColour === 'pink') this.setState({badgeColour: 'gradient-45deg-red-pink'});
        if (this.state.badgeColour === 'amber') this.setState({badgeColour: 'gradient-45deg-amber-amber'});

    }

    render(){

        
        return (
            // <!--Data Chart start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div className="card pt-0 pb-0">
                    <div className="padding-2 ml-2">
                        <a href={this.state.link} ><span className={`${this.state.badgeStatus} badge ${this.state.badgeColour} gradient-shadow mt-2 mr-2 white-text`}>
                            {this.state.badgeValuePrefix} {this.state.badgeValue}{this.state.badgeValueSuffix}
                            {/* ::after */}
                        </span></a>
                        <p className="mt-2 mb-0">{this.state.title}</p>
                        <p className={`no-margin ${this.state.subValueTextColour}-text lighten-3`}>{this.state.subValuePrefix}  {this.state.subValue}{this.state.subValueSuffix}</p>
                        <h5>{this.state.mainValuePrefix} {this.Format.FormatLocaleNumber(this.state.mainValue, this.state.Lang, this.state.valueIsDecimal, this.state.valueDecimalPlaces)}{this.state.mainValueSuffix}</h5>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className={this.state.chartID}>
                                {this.state.chartElement}
                            </div>

                        </div>
                        {/* ::after */}
                    </div>
                </div>
            </div>
            // <!--Data Chart end-->
        )
    }
}

export default DataChart;