import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import URLService from '../modules/URLService';


const $ = require('jquery');


class BaselineDataProfile extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getBaselineDataProfile = this.getBaselineDataProfile.bind(this);
        this.state = {
            baselineDetails: '',
            baselineDataItems: [],
            baseline_create: null,
            baseline_edit: null,
            scopes: {},
            result: '',
            message: '',
            hash_organisation: ''
        }
        this.Auth = new AuthService();
        this.DateService = new DateService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.FormatService = new FormatService();
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
    
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.BaselineDataProfile + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

        // window.$('.collapsible').collapsible();

        
    }

    componentWillUnmount(){
        $('.collapsible').collapsible();
    
    }


    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null){
            this.setState({hash_user: user_profile.user,
                username: user_profile.username,
                scopes: user_profile.scopes,
                hash_organisation: user_profile.organisation}, () => {
                this.checkAccessRight();
                this.getAccessScopes();
                this.getBaselineDataProfile();
            });
        }

    }


    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ( (this.Auth.checkAccessScope(['read:baseline'], 'view') === false) || this.state.hash_organisation !== this.props.match.params.id){
             this.props.history.replace(default_home);   
        };
 
     }


     getAccessScopes(){

        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:baseline' || this.state.scopes['views'][i] === 'action:baseline') {
                    this.setState({baseline_create: true});
                }
                if (this.state.scopes['views'][i] === 'update:baseline' || this.state.scopes['views'][i] === 'action:baseline') {
                    this.setState({baseline_edit: true});
                } 
            }
        };

    }



     getBaselineDataProfile(){
        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/baseline/data/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {
                this.setState({baselineDetails: response.data.data}, () => {




                    if (this.state.baselineDetails && this.state.baselineDetails.length > 0) {

                        let baselineDataItems = []; // empty array for pushing in the baseline data items
                        let temp_header = null;
                        let temp_body = null;
                        let temp_breakline = null;
                        let temp_breakline_2 = null;
                        let len = this.state.baselineDetails.length;
                
                        for (let i = 0; i < len; i++) {

                            let temp_local_content_items = []; // empty array for pusing in local conetnt values
                            let temp_local_content = null;
                            let temp_counter = 0;

                            if ( i === 0 ) { // populate the baseline data for the first loop


                                for (let j = 0; j < len; j++) { // loop for the local content values which is embedded in the local content section of the collapsible collection

                                    if (this.state.baselineDetails[j].business_year === this.state.baselineDetails[i].business_year) {

                                        temp_counter = temp_counter + 1;

                                        temp_local_content =    <div className="row" key={j + this.state.baselineDetails[j].local_content_base_hash_id}>
                                                                    <div className="col s6">
                                                                        <span><b>{temp_counter}) {this.Language.DataField.ExportedItem}: </b> {this.state.baselineDetails[j].exported_item}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.ExportValue}: </b> {this.state.baselineDetails[j].local_content_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[j].export_value, this.Language.Lang, true, 2)}</span>
                                                                    </div>
                                                                    <div className="col s3">
                                                                        <span><b>{this.Language.DataField.ForeignContent}: </b> {this.state.baselineDetails[j].local_content_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[j].foreign_content, this.Language.Lang, true, 2)}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.LocalContent}: </b> {this.state.baselineDetails[j].local_content_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[j].local_content, this.Language.Lang, true, 2)}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.Percentage}: </b> { (!isNaN(parseFloat(this.state.baselineDetails[j].local_content) && !isNaN(parseFloat(this.state.baselineDetails[j].foreign_content)))) ?   this.FormatService.FormatLocaleNumber((parseFloat(this.state.baselineDetails[j].local_content)/ (parseFloat(this.state.baselineDetails[j].foreign_content) + parseFloat(this.state.baselineDetails[j].local_content)) * 100), this.Language.Lang, true, 2) + '%' : null}</span>
                                                                    </div>
                                                                    <div className="col s3">
                                                                        <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[j].local_content_base_status}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[j].local_content_base_is_approved ?  ((this.state.baselineDetails[j].local_content_base_is_approved === 'true') || (this.state.baselineDetails[j].local_content_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                        <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[j].is_submitted !== 'true') && (this.state.baselineDetails[j].is_submitted !== true) && (this.state.baselineDetails[j].local_content_base_is_approved !== 'true') && (this.state.baselineDetails[j].local_content_base_is_approved !== true) && this.state.baselineDetails[j].local_content_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[j].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                    </div>
                                                                </div>
                                        
                                        temp_local_content_items.push(temp_local_content);
                                        temp_local_content_items.push(<br key={j + 1 + this.state.baselineDetails[j].local_content_base_hash_id}/>);
                                        temp_local_content_items.push(<br key={j + 2 + this.state.baselineDetails[j].local_content_base_hash_id}/>);

                                    }
                                                                
                                }



                                temp_header =   <ul id="business-year-details" className="collection z-depth-1" key={i + this.state.baselineDetails[i].hash_id + 'header'}>
                                                    <li className="collection-item">
                                                        <div className="row">
                                                            <div className="col s12">
                                                                <b className="teal-text">{this.Language.DataField.BusinessYear}: {this.state.baselineDetails[i].business_year}</b>
                                                                <p></p>
                                                                <span>{this.Language.PageLabel.ForNextYearsExports} {parseInt(this.state.baselineDetails[i].business_year, 10) + 1}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>

                                temp_body =     <ul className="collapsible" data-collapsible="expandable" key={i + 1 + this.state.baselineDetails[i].hash_id + 'body'}>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">supervisor_account</i>{this.Language.DataField.Employment}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.TotalStaffStrength}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].total_staff_strength, this.Language.Lang, false, 0)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.TotalNigerianStaff}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].total_nigerian_staff, this.Language.Lang, false, 0)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].employment_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].employment_base_is_approved ?  ((this.state.baselineDetails[i].employment_base_is_approved === 'true') || (this.state.baselineDetails[i].employment_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].employment_base_is_approved !== 'true') && (this.state.baselineDetails[i].employment_base_is_approved !== true) && this.state.baselineDetails[i].employment_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">trending_up</i>{this.Language.DataField.ExportGrowth}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.TotalExportValue}: </b>{this.state.baselineDetails[i].export_growth_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].total_export_value, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.ExportGrowthRate}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].export_growth_rate, this.Language.Lang, true, 2)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].export_growth_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].export_growth_base_is_approved ?  ((this.state.baselineDetails[i].export_growth_base_is_approved === 'true') || (this.state.baselineDetails[i].export_growth_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].export_growth_base_is_approved !== 'true') && (this.state.baselineDetails[i].export_growth_base_is_approved !== true) && this.state.baselineDetails[i].export_growth_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">account_balance_wallet</i>{this.Language.DataField.CapitalInvestment}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Additions}: </b> {this.state.baselineDetails[i].investment_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].additions, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.OpenningNBV}: </b> {this.state.baselineDetails[i].investment_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].opening_nbv, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.CapitalInvestmentGrowth}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].capital_investment_growth, this.Language.Lang, true, 2)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].investment_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].investment_base_is_approved ?  ((this.state.baselineDetails[i].investment_base_is_approved === 'true') || (this.state.baselineDetails[i].investment_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].investment_base_is_approved !== 'true') && (this.state.baselineDetails[i].investment_base_is_approved !== true) && this.state.baselineDetails[i].investment_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">chrome_reader_mode</i>{this.Language.DataField.LocalContent}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            {temp_local_content_items}
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">timeline</i>{this.Language.DataField.LocalValueAdded}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Turnover}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].turnover, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.OtherIncomes}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].other_incomes, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.BoughtInGoodsAndMaterials}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].bought_in_goods_and_materials, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.LocalValueAdded}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].local_value_added, this.Language.Lang, true, 2)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].local_value_added_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].local_value_added_base_is_approved ?  ((this.state.baselineDetails[i].local_value_added_base_is_approved === 'true') || (this.state.baselineDetails[i].local_value_added_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].local_value_added_base_is_approved !== 'true') && (this.state.baselineDetails[i].local_value_added_base_is_approved !== true) && this.state.baselineDetails[i].local_value_added_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">check_circle</i>{this.Language.DataField.PrioritySector}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.ExporterCategory}: </b> {this.state.baselineDetails[i].exporter_category}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.PrioritySector}: </b> {this.state.baselineDetails[i].priority_sector ?  ((this.state.baselineDetails[i].priority_sector === 'true') || (this.state.baselineDetails[i].priority_sector === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.PrioritySectorScore}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].priority_sector_score, this.Language.Lang, false, 0)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].priority_sector_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].priority_sector_base_is_approved ?  ((this.state.baselineDetails[i].priority_sector_base_is_approved === 'true') || (this.state.baselineDetails[i].priority_sector_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].priority_sector_base_is_approved !== 'true') && (this.state.baselineDetails[i].priority_sector_base_is_approved !== true) && this.state.baselineDetails[i].priority_sector_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">import_contacts</i>{this.Language.DataField.Notes}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                        <span><b>{this.Language.DataField.Notes}:</b> {this.state.baselineDetails[i].notes}</span>
                                                        <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && this.state.baselineDetails[i].baseline_notes_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                        <p></p>
                                                        <span><b>{this.Language.DataField.ReviewerNotes}:</b> {this.state.baselineDetails[i].reviewer_notes}</span>
                                                        </div>
                                                    </li>
                                                </ul>

                                temp_breakline = <br key={i + 2 + this.state.baselineDetails[i].hash_id  + 'br1'}/>
                                temp_breakline_2 = <br key={i + 3 + this.state.baselineDetails[i].hash_id  + 'br2'}/>


                                baselineDataItems.push(temp_header);
                                baselineDataItems.push(temp_body);
                                baselineDataItems.push(temp_breakline);
                                baselineDataItems.push(temp_breakline_2);


                            } else if ( i > 0 && this.state.baselineDetails[i].business_year !== this.state.baselineDetails[i - 1].business_year) {


                                for (let j = 0; j < len; j++) { // loop for the local content values which is embedded in the local content section of the collapsible collection

                                    if (this.state.baselineDetails[j].business_year === this.state.baselineDetails[i].business_year) {

                                        temp_counter = temp_counter + 1;

                                        temp_local_content =    <div className="row" key={j + this.state.baselineDetails[j].local_content_base_hash_id}>
                                                                    <div className="col s6">
                                                                        <span><b>{temp_counter}) {this.Language.DataField.ExportedItem}: </b> {this.state.baselineDetails[j].exported_item}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.ExportValue}: </b> {this.state.baselineDetails[j].local_content_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[j].export_value, this.Language.Lang, true, 2)}</span>
                                                                    </div>
                                                                    <div className="col s3">
                                                                        <span><b>{this.Language.DataField.ForeignContent}: </b> {this.state.baselineDetails[j].local_content_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[j].foreign_content, this.Language.Lang, true, 2)}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.LocalContent}: </b> {this.state.baselineDetails[j].local_content_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[j].local_content, this.Language.Lang, true, 2)}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.Percentage}: </b> { (!isNaN(parseFloat(this.state.baselineDetails[j].local_content) && !isNaN(parseFloat(this.state.baselineDetails[j].foreign_content)))) ?   this.FormatService.FormatLocaleNumber((parseFloat(this.state.baselineDetails[j].local_content)/ (parseFloat(this.state.baselineDetails[j].foreign_content) + parseFloat(this.state.baselineDetails[j].local_content)) * 100), this.Language.Lang, true, 2) + '%' : null}</span>
                                                                    </div>
                                                                    <div className="col s3">
                                                                        <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[j].local_content_base_status}</span>
                                                                        <p></p>
                                                                        <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[j].local_content_base_is_approved ?  ((this.state.baselineDetails[j].local_content_base_is_approved === 'true') || (this.state.baselineDetails[j].local_content_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                        <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[j].is_submitted !== 'true') && (this.state.baselineDetails[j].is_submitted !== true) && (this.state.baselineDetails[j].local_content_base_is_approved !== 'true') && (this.state.baselineDetails[j].local_content_base_is_approved !== true) && this.state.baselineDetails[j].local_content_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[j].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                    </div>
                                                                </div>
                                        
                                        temp_local_content_items.push(temp_local_content);
                                        temp_local_content_items.push(<br key={j + 1 + this.state.baselineDetails[j].local_content_base_hash_id}/>);
                                        temp_local_content_items.push(<br key={j + 2 + this.state.baselineDetails[j].local_content_base_hash_id}/>);

                                    }
                                                                
                                }



                                temp_header =   <ul id="business-year-details" className="collection z-depth-1" key={i + this.state.baselineDetails[i].hash_id  + 'header2'}>
                                                    <li className="collection-item">
                                                        <div className="row">
                                                            <div className="col s12">
                                                                <b className="teal-text">{this.Language.DataField.BusinessYear}: {this.state.baselineDetails[i].business_year}</b>
                                                                <p></p>
                                                                <span>{this.Language.PageLabel.ForNextYearsExports} {parseInt(this.state.baselineDetails[i].business_year, 10) + 1}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>

                                temp_body =     <ul className="collapsible" data-collapsible="expandable" key={i + 1 + this.state.baselineDetails[i].hash_id + 'body2'}>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">supervisor_account</i>{this.Language.DataField.Employment}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.TotalStaffStrength}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].total_staff_strength, this.Language.Lang, false, 0)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.TotalNigerianStaff}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].total_nigerian_staff, this.Language.Lang, false, 0)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].employment_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].employment_base_is_approved ?  ((this.state.baselineDetails[i].employment_base_is_approved === 'true') || (this.state.baselineDetails[i].employment_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].employment_base_is_approved !== 'true') && (this.state.baselineDetails[i].employment_base_is_approved !== true) && this.state.baselineDetails[i].employment_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">trending_up</i>{this.Language.DataField.ExportGrowth}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.TotalExportValue}: </b> {this.state.baselineDetails[i].export_growth_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].total_export_value, this.Language.Lang, true, 2)} </span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.ExportGrowthRate}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].export_growth_rate, this.Language.Lang, true, 2)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].export_growth_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].export_growth_base_is_approved ?  ((this.state.baselineDetails[i].export_growth_base_is_approved === 'true') || (this.state.baselineDetails[i].export_growth_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].export_growth_base_is_approved !== 'true') && (this.state.baselineDetails[i].export_growth_base_is_approved !== true) && this.state.baselineDetails[i].export_growth_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">account_balance_wallet</i>{this.Language.DataField.CapitalInvestment}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Additions}: </b> {this.state.baselineDetails[i].investment_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].additions, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.OpenningNBV}: </b> {this.state.baselineDetails[i].investment_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].opening_nbv, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.CapitalInvestmentGrowth}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].capital_investment_growth, this.Language.Lang, true, 2)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].investment_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].investment_base_is_approved ?  ((this.state.baselineDetails[i].investment_base_is_approved === 'true') || (this.state.baselineDetails[i].investment_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].investment_base_is_approved !== 'true') && (this.state.baselineDetails[i].investment_base_is_approved !== true) && this.state.baselineDetails[i].investment_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">chrome_reader_mode</i>{this.Language.DataField.LocalContent}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            {temp_local_content_items}
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">timeline</i>{this.Language.DataField.LocalValueAdded}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Turnover}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].turnover, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.OtherIncomes}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].other_incomes, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.BoughtInGoodsAndMaterials}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].bought_in_goods_and_materials, this.Language.Lang, true, 2)}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.LocalValueAdded}: </b> {this.state.baselineDetails[i].local_value_added_base_currency} {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].local_value_added, this.Language.Lang, true, 2)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].local_value_added_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].local_value_added_base_is_approved ?  ((this.state.baselineDetails[i].local_value_added_base_is_approved === 'true') || (this.state.baselineDetails[i].local_value_added_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].local_value_added_base_is_approved !== 'true') && (this.state.baselineDetails[i].local_value_added_base_is_approved !== true) && this.state.baselineDetails[i].local_value_added_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">check_circle</i>{this.Language.DataField.PrioritySector}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                            <div className="row">
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.ExporterCategory}: </b> {this.state.baselineDetails[i].exporter_category}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.PrioritySector}: </b> {this.state.baselineDetails[i].priority_sector ?  ((this.state.baselineDetails[i].priority_sector === 'true') || (this.state.baselineDetails[i].priority_sector === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.PrioritySectorScore}: </b> {this.FormatService.FormatLocaleNumber(this.state.baselineDetails[i].priority_sector_score, this.Language.Lang, false, 0)}</span>
                                                                </div>
                                                                <div className="col s6">
                                                                    <span><b>{this.Language.DataField.Status}: </b> {this.state.baselineDetails[i].priority_sector_base_status}</span>
                                                                    <p></p>
                                                                    <span><b>{this.Language.DataField.IsApproved}: </b> {this.state.baselineDetails[i].priority_sector_base_is_approved ?  ((this.state.baselineDetails[i].priority_sector_base_is_approved === 'true') || (this.state.baselineDetails[i].priority_sector_base_is_approved === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</span>
                                                                    <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && (this.state.baselineDetails[i].priority_sector_base_is_approved !== 'true') && (this.state.baselineDetails[i].priority_sector_base_is_approved !== true) && this.state.baselineDetails[i].priority_sector_base_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="collapsible-header cyan lighten-1 white-text">
                                                        <i className="material-icons">import_contacts</i>{this.Language.DataField.Notes}</div>
                                                        <div className="collapsible-body white" style={{display: 'none'}}>
                                                        <span><b>{this.Language.DataField.Notes}:</b> {this.state.baselineDetails[i].notes}</span>
                                                        <p></p>
{(this.state.baseline_edit && (this.state.baselineDetails[i].is_submitted !== 'true') && (this.state.baselineDetails[i].is_submitted !== true) && this.state.baselineDetails[i].baseline_notes_hash_id !== null) &&  <Link to={`/exporter/${exporterId}/baseline/profile/edit/${this.state.baselineDetails[i].business_year}`} className="btn-flat waves-effect"><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>}
                                                        <p></p>
                                                        <span><b>{this.Language.DataField.ReviewerNotes}:</b> {this.state.baselineDetails[i].reviewer_notes}</span>
                                                        </div>
                                                    </li>
                                                </ul>

                                temp_breakline = <br key={i + 2 + this.state.baselineDetails[i].hash_id  + 'br3'}/>
                                temp_breakline_2 = <br key={i + 3 + this.state.baselineDetails[i].hash_id + 'br4'}/>


                                baselineDataItems.push(temp_header);
                                baselineDataItems.push(temp_body);
                                baselineDataItems.push(temp_breakline);
                                baselineDataItems.push(temp_breakline_2);

                            

                            }


                        }


                        this.setState({baselineDataItems:  baselineDataItems});

                    }




                })
                

                this.setState({result: response.data.status}, () => {
                })

                this.setState({message: response.data.message}, () => {
                })
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx


                  if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                } else {
                  // Something happened in setting up the request that triggered an Error

                }

              });
    }







    render(){


        let exporterId = this.props.match.params.id;
        window.$('.collapsible').collapsible();

 

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Baseline Data"} active_menu_level_2={"Baseline Profile"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.BaselineDataProfile}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.BaselineDataProfile]} links={[`/`, `/exporter/${exporterId}/baseline/profile`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
{(this.state.baseline_create) && <br/>}
{(this.state.baseline_create) &&  <Link className="waves-effect waves-light btn right" to={`/exporter/${this.state.hash_organisation}/baseline/profile/add`}><i className="material-icons left">library_add</i>{this.Language.ButtonLabel.CaptureBaselineData}</Link>}
{(this.state.baseline_create) &&  <br/>}
{(this.state.baseline_create) &&  <br/>}

                                <div id="profile-page" className="section">
                                    <div id="profile-page-content" className="row">

                                        {this.state.baselineDataItems}

                                    </div>
                                    <br/>
                                    <br/>
                                    {/* <Link className="waves-effect waves-light btn" to={`/exporter/profile/edit/${this.state.exporterDetails.hash_id}`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>  */}

                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]  
        )
    }
}

export default BaselineDataProfile;