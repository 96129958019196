import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import FileService from '../modules/FileService';

import axios from 'axios';

const $ = require('jquery');

class DataTemplateFields extends Component {
  constructor(props) {
    super(props);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getDataTemplateFields = this.getDataTemplateFields.bind(this);
    this.getMoreRecords = this.getMoreRecords.bind(this);
    this.downloadTemplate = this.downloadTemplate.bind(this);
    this.state = {
      datatemplatefields: null,
      numberOfRecords: 0,
      currentNumberOfRecords: 300,
      paginationItem: null,
      result: '',
      message: '',
      token: '',
      preloader: true,
      dataTemplateFieldDataItems: null,
      data_templates_use: null,
      select_field_values_template_download: [],
      scopes: {},
      paginationGetMoreDataButton: null
    };
    this.Auth = new AuthService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.DateService = new DateService();
    this.apiURL = URLService.prototype.getApiURL();
    this.File = new FileService();
    this.returnURLQuery = URLService.prototype.getReturnURLQuery();
  }

  componentWillMount() {
    this.checkAccessRight();
    this.getProfile();
  }

  componentDidMount() {
    const table_name = this.props.match.params.table_name;
    document.title = this.Language.PageTitle.DataTemplates + ': ' + table_name + ' - ' + this.Language.EEGWebPortal;
    // this.checkAccessRight();
    this.getAccessScopes();

    this.getDataTemplateFields(this.state.currentNumberOfRecords);
    this.setState({
      paginationGetMoreDataButton: (
        <li className="input-field" onClick={this.getMoreRecords}>
          <a href={'#!'} className="waves-effect waves-teal teal-text">
            {this.Language.Pagination.GetData}
            <i className="material-icons right">refresh</i>
          </a>
        </li>
      )
    });

    const select_template_list_values = [
      {item_id: 'New', item_name: this.Language.DataField.NewDataTemplate},
      {item_id: 'Update', item_name: this.Language.DataField.UpdateDataTemplate},
      {item_id: 'Delete', item_name: this.Language.DataField.DeleteDataTemplate}
    ];

    const select_field_values_template_download = select_template_list_values.map((list_value, i) => {
      return <SelectField pick_list_id={list_value.item_id} key={i} item_name={list_value.item_name} />;
    });

    this.setState({
      select_field_values_template_download: select_field_values_template_download
    });
  }

  checkAccessRight() {
    let default_home = '/';
    const dashboards = this.Auth.getDashboards() || [];
    if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${'5'}`;

    if (this.Auth.checkAccessScope(['read:template', 'action:template', 'read:data', 'action:data'], 'view') === false) {
      this.props.history.replace(default_home);
    }
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState({scopes: user_profile.scopes});
    }
  }

  getAccessScopes() {
    // Check the views scope
    if (this.state.scopes['views']) {
      let len = this.state.scopes['views'].length;

      for (let i = 0; i < len; i++) {
        if (this.state.scopes['views'][i] === 'create:template' || this.state.scopes['views'][i] === 'update:template' || this.state.scopes['views'][i] === 'action:template') {
          this.setState({data_templates_use: true});
        }
      }
    }
  }

  getMoreRecords() {
    let currentNumberOfRecords = $('#current_number_of_records').val();

    this.setState({
      dataTemplateFieldDataItems: null,
      paginationItem: null,
      datatemplatefields: null,
      currentNumberOfRecords: currentNumberOfRecords,
      preloader: true
    });

    this.getDataTemplateFields(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input
  }

  getDataTemplateFields(currentNumberOfRecords) {
    const table_name = this.props.match.params.table_name;
    const action_type = 'update_data';

    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
    if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

    axios
      .get(`${this.apiURL}/api/v1/data/management/table/${table_name}/${action_type}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
      .then(response => {
        this.setState({numberOfRecords: response.data.data_count}); // count of the total result expected. especially if not all the records are retrieve at a go.

        this.setState({datatemplatefields: response.data.data}, () => {
          this.setState({
            dataTemplateFieldDataItems: (
              <DataTable
                tableName={''}
                fields={['table_name', 'column_name', 'data_type']}
                fieldDataTypes={['String', 'String', 'String']}
                fieldDecimalPlaces={[0, 0, 0]}
                dateFormat={'long_date'}
                timeFormat={'h12'}
                headers={[this.Language.DataTableHeader.TableName, this.Language.DataTableHeader.ColumnName, this.Language.DataTableHeader.DataType]}
                data={this.state.datatemplatefields}
                sortType={'ascending'}
                sortPosition={1}
                thStyle={[400, 519, 146]}
                link={'/data/management/templates/'}
                return_query_prefix={this.returnURLQuery}
                current_path={this.props.history.location.pathname}
                link_status={false}
                linkToBlankTarget={false}
                linkToBlankTargetToken={''}
                download={false}
                downloadFileName={this.Language.DownloadFileName.DataTableDetails}
                emptyMessage={this.Language.EmptyMessage.DataTableDetails}
                downloadFields={['table_name', 'column_name', 'data_type']}
              />
            )
          });

          this.setState({
            paginationItem: (
              <PaginationGetDataLimit
                button={this.state.paginationGetMoreDataButton}
                numberOfRecords={this.state.numberOfRecords}
                currentNumberOfRecords={this.state.currentNumberOfRecords}
                language={this.Language}
              />
            )
          });
          this.setState({preloader: null});
        });

        this.setState({result: response.data.status}, () => {
        });

        this.setState({message: response.data.message}, () => {
        });
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  }

  downloadTemplate(event) {
    event.preventDefault();

    let template_option = this.refs.template_option.value;
    if (template_option === 'Select Template Download Option') template_option = '';

    if (template_option !== '') {
      const template_len = this.state.datatemplatefields.length;

      if (template_len > 0) {
        const table_name = this.props.match.params.table_name;
        let entries_array_1 = [];
        let entries_array_2 = [];
        let entries_array_3 = [];
        let download_fields = [];

        if (template_option === 'New') {
          for (let i = 0; i < template_len; i++) {
            if (i > 0) {
              // exclude the first column which should be the primary keey field
              entries_array_1.push([this.state.datatemplatefields[i].column_name, this.state.datatemplatefields[i].data_type]);
              entries_array_2.push([this.state.datatemplatefields[i].column_name, this.state.datatemplatefields[i].is_nullable]);
              download_fields.push(this.state.datatemplatefields[i].column_name);

              if (this.state.datatemplatefields[i].data_type === 'cidr') entries_array_3.push([this.state.datatemplatefields[i].column_name, '192.168.100.128/25']);
              if (this.state.datatemplatefields[i].data_type === 'smallint') entries_array_3.push([this.state.datatemplatefields[i].column_name, 32767]);
              if (this.state.datatemplatefields[i].data_type === 'inet') entries_array_3.push([this.state.datatemplatefields[i].column_name, '192.168.100.128/25']);
              if (this.state.datatemplatefields[i].data_type === 'boolean') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'true']);
              if (this.state.datatemplatefields[i].data_type === 'numeric') entries_array_3.push([this.state.datatemplatefields[i].column_name, 32767.23]);
              if (this.state.datatemplatefields[i].data_type === 'timestamp with time zone') entries_array_3.push([this.state.datatemplatefields[i].column_name, '2004-10-19 10:23:54+02']);
              if (this.state.datatemplatefields[i].data_type === 'timestamp') entries_array_3.push([this.state.datatemplatefields[i].column_name, '2004-10-19 10:23:54']);
              if (this.state.datatemplatefields[i].data_type === 'bigint') entries_array_3.push([this.state.datatemplatefields[i].column_name, 922337203]);
              if (this.state.datatemplatefields[i].data_type === 'date') entries_array_3.push([this.state.datatemplatefields[i].column_name, '2004-10-19']);
              if (this.state.datatemplatefields[i].data_type === 'interval') entries_array_3.push([this.state.datatemplatefields[i].column_name, '1 year 2 months 3 days 4 hours 5 minutes 6 seconds']);
              if (this.state.datatemplatefields[i].data_type === 'text') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'Sample text']);
              if (this.state.datatemplatefields[i].data_type === 'char') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'A']);
              if (this.state.datatemplatefields[i].data_type === 'character varying') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'Sample text']);
              if (this.state.datatemplatefields[i].data_type === 'macaddr') entries_array_3.push([this.state.datatemplatefields[i].column_name, '08:00:2b:01:02:03']);
              if (this.state.datatemplatefields[i].data_type === 'integer') entries_array_3.push([this.state.datatemplatefields[i].column_name, 2147483647]);
              if (this.state.datatemplatefields[i].data_type === 'double precision') entries_array_3.push([this.state.datatemplatefields[i].column_name, 23.1234]);
              if (this.state.datatemplatefields[i].data_type === 'real') entries_array_3.push([this.state.datatemplatefields[i].column_name, 23.1234]);
              if (this.state.datatemplatefields[i].data_type === 'ARRAY') entries_array_3.push([this.state.datatemplatefields[i].column_name, '{"Value", "Value-1"}']);
            }
          }
        }

        if (template_option === 'Update') {
          for (let i = 0; i < template_len; i++) {
            entries_array_1.push([this.state.datatemplatefields[i].column_name, this.state.datatemplatefields[i].data_type]);
            entries_array_2.push([this.state.datatemplatefields[i].column_name, this.state.datatemplatefields[i].is_nullable]);
            download_fields.push(this.state.datatemplatefields[i].column_name);

            if (this.state.datatemplatefields[i].data_type === 'cidr') entries_array_3.push([this.state.datatemplatefields[i].column_name, '192.168.100.128/25']);
            if (this.state.datatemplatefields[i].data_type === 'smallint') entries_array_3.push([this.state.datatemplatefields[i].column_name, 32767]);
            if (this.state.datatemplatefields[i].data_type === 'inet') entries_array_3.push([this.state.datatemplatefields[i].column_name, '192.168.100.128/25']);
            if (this.state.datatemplatefields[i].data_type === 'boolean') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'true']);
            if (this.state.datatemplatefields[i].data_type === 'numeric') entries_array_3.push([this.state.datatemplatefields[i].column_name, 32767.23]);
            if (this.state.datatemplatefields[i].data_type === 'timestamp with time zone') entries_array_3.push([this.state.datatemplatefields[i].column_name, '2004-10-19 10:23:54+02']);
            if (this.state.datatemplatefields[i].data_type === 'timestamp') entries_array_3.push([this.state.datatemplatefields[i].column_name, '2004-10-19 10:23:54']);
            if (this.state.datatemplatefields[i].data_type === 'bigint') entries_array_3.push([this.state.datatemplatefields[i].column_name, 922337203]);
            if (this.state.datatemplatefields[i].data_type === 'date') entries_array_3.push([this.state.datatemplatefields[i].column_name, '2004-10-19']);
            if (this.state.datatemplatefields[i].data_type === 'interval') entries_array_3.push([this.state.datatemplatefields[i].column_name, '1 year 2 months 3 days 4 hours 5 minutes 6 seconds']);
            if (this.state.datatemplatefields[i].data_type === 'text') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'Sample text']);
            if (this.state.datatemplatefields[i].data_type === 'char') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'A']);
            if (this.state.datatemplatefields[i].data_type === 'character varying') entries_array_3.push([this.state.datatemplatefields[i].column_name, 'Sample text']);
            if (this.state.datatemplatefields[i].data_type === 'macaddr') entries_array_3.push([this.state.datatemplatefields[i].column_name, '08:00:2b:01:02:03']);
            if (this.state.datatemplatefields[i].data_type === 'integer') entries_array_3.push([this.state.datatemplatefields[i].column_name, 2147483647]);
            if (this.state.datatemplatefields[i].data_type === 'double precision') entries_array_3.push([this.state.datatemplatefields[i].column_name, 23.1234]);
            if (this.state.datatemplatefields[i].data_type === 'real') entries_array_3.push([this.state.datatemplatefields[i].column_name, 23.1234]);
            if (this.state.datatemplatefields[i].data_type === 'ARRAY') entries_array_3.push([this.state.datatemplatefields[i].column_name, '{"Value", "Value-1"}']);
          }
        }


        if (template_option === 'Delete') {

          entries_array_1.push(['record_id', 'text']);
          entries_array_2.push(['record_id', 'NO']);
          entries_array_3.push(['record_id', 'record001']);
          download_fields.push('record_id');

        }

        const entries_1 = new Map([...entries_array_1]);
        const entries_2 = new Map([...entries_array_2]);
        const entries_3 = new Map([...entries_array_3]);

        const object_1 = Object.fromEntries(entries_1);
        const object_2 = Object.fromEntries(entries_2);
        const object_3 = Object.fromEntries(entries_3);
        const final_data_array = [object_1, object_2, object_3];

        this.File.downloadCSV(final_data_array, this.Language.DownloadFileName.DataTemplate + ' - ' + template_option + ' - ' + table_name, download_fields);
      } else {
        window.Materialize.toast(this.Language.NodeMessage.DataTemplate.NoTemplateFieldsToDownload, 8000, 'rounded deep-orange darken-4');
      }
    } else {
      window.Materialize.toast(this.Language.NodeMessage.DataTemplate.NoTemplateOptionSelected, 8000, 'rounded deep-orange darken-4');
    }
  }

  render() {
    const table_name = this.props.match.params.table_name;

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Data Management'} active_menu_level_2={'Data Templates'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.DataTemplates}: ${table_name}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.DataManagement, this.Language.BreadCrumbLabel.DataTemplates, table_name]}
              links={[`/data/management/templates`, `/data/management/templates`, `/data/management/template/${table_name}`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <br />
              <div className="row">
                <div className="input-field col s6">
                  <div className="select-wrapper initialized">
                    <span className="caret">▼</span>
                    <select data-select-id="select-options-template_option" className="initialized" name="template_option" id="template_option" ref="template_option">
                      <option value="Select Template Download Option">{this.Language.DataFieldDefault.SelectTemplateDownloadOption}</option>
                      {this.state.select_field_values_template_download.map((template_value, i) => {
                        return template_value;
                      })}
                    </select>
                  </div>
                  <label>{this.Language.DataField.TemplateDownloadOption} *</label>
                </div>
                <div className="input-field col s6">
                  {this.state.data_templates_use && (
                    <Link className="waves-effect waves-light btn left" to={`#!`} onClick={this.downloadTemplate}>
                      <i className="material-icons left">border_bottom</i>
                      {this.Language.ButtonLabel.DownloadTemplate}
                    </Link>
                  )}
                </div>
              </div>
              <br />
              {this.state.preloader && (
                <div className="progress teal lighten-4">
                  <div className="indeterminate teal"></div>
                </div>
              )}
              {this.state.dataTemplateFieldDataItems}
              <br />
              <br />
              {this.state.paginationItem}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default DataTemplateFields;
