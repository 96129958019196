import React, { Component } from 'react';
import axios from 'axios';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';


const $ = require('jquery');


class Devices extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getDevices = this.getDevices.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            deviceDetails: [],
            numberOfRecords: 0,
            currentNumberOfRecords: 50,
            paginationItem: null,
            result: '',
            message: '',
            preloader: true,
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {
        this.checkAccessRight();
    }

    componentDidMount() {
        document.title = this.Language.PageTitle.UserDevices + ' - ' + this.Language.EEGWebPortal;

        this.getDevices(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['read:devices', 'action:devices'], 'view') === false)) {
            this.props.history.replace(default_home);
        };

    }


    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            paginationItem: null,
            deviceDetails: [],
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getDevices(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }




    getDevices(currentNumberOfRecords) {

        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/user/devices?tk=${this.Auth.getToken()}&record_limit=${currentNumberOfRecords}`)
            .then(response => {


                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                if (response.data.data) {
                    this.setState({ deviceDetails: response.data.data || [] }, () => {


                        this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                        this.setState({ preloader: null });
                    })
                }

                this.setState({ result: response.data.status });

                this.setState({ message: response.data.message });


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            });

    }




    render() {

        let deviceCardsOdd = null

        if (this.state.deviceDetails && this.state.deviceDetails !== null && typeof this.state.deviceDetails !== 'undefined') {
          deviceCardsOdd = this.state.deviceDetails.map((device, i) => {
            if (i % 2 === 0) {
              return [
                <div className="card white" key={i}>
                  <div className="card-content teal-text">
                    <span className="card-title">
                      {i + 1}. {this.Language.DataField.DeviceName}: <b>{device.device_name}</b> <i className="material-icons right">devices</i>
                    </span>
                    <span>
                      {this.Language.DataField.Username}: <b>{device.username}</b>{' '}
                    </span>
                    <br />
                    <div className="divider"></div>
                    <br />
                    <p>
                      <b> {this.Language.PageLabel.DeviceDetails} </b>
                    </p>
                    <p>
                      {this.Language.DataField.Model}: <b>{device.device_model}</b>{' '}
                    </p>
                    <p>
                      {this.Language.DataField.OS}: <b>{device.device_os}</b>{' '}
                    </p>
                    <p>
                      {this.Language.DataField.Browser}: <b>{device.browser}</b>{' '}
                    </p>
                    <p>
                      {this.Language.DataField.Location}: <b>{device.location_used}</b>{' '}
                    </p>
                  </div>
                </div>,
                <br key={i + 1} />,
                <div className="divider" key={i + 2}></div>
              ];
            } else {
              return null;
            }
          });

        }


        let deviceCardsEven = null

        if (this.state.deviceDetails && this.state.deviceDetails !== null && typeof this.state.deviceDetails !== 'undefined') {
        deviceCardsEven = this.state.deviceDetails.map((device, i) => {
            if ((i % 2) > 0) {
                return (
                    [
                        <div className="card white" key={i}>
                            <div className="card-content teal-text">
                                <span className="card-title">{i + 1}. {this.Language.DataField.DeviceName}: <b>{device.device_name}</b> <i className="material-icons right">devices</i></span>
                                <span>{this.Language.DataField.Username}: <b>{device.username}</b> </span>
                                <br />
                                <div className="divider"></div>
                                <br />
                                <p><b> {this.Language.PageLabel.DeviceDetails} </b></p>
                                <p>{this.Language.DataField.Model}: <b>{device.device_model}</b> </p>
                                <p>{this.Language.DataField.OS}: <b>{device.device_os}</b> </p>
                                <p>{this.Language.DataField.Browser}: <b>{device.browser}</b> </p>
                                <p>{this.Language.DataField.Location}: <b>{device.location_used}</b> </p>
                            </div>
                        </div>,
                        <br key={i + 1} />,
                        <div className="divider" key={i + 2}></div>
                    ]
                )
            } else {
                return (
                    null
                )
            }
        })

    }



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"User Management"} active_menu_level_2={"User Account Devices"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.UserDevices}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.UserDevices]} links={[`/users`, `/user/devices`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">

                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}

                                <div id="profile-page" className="section">
                                    <div id="profile-page-content" className="row">
                                        <div id="profile-page-sidebar" className="col s12 m6">
                                            {deviceCardsOdd}
                                        </div>
                                        <div id="profile-page-wall" className="col s12 m6">
                                            {deviceCardsEven}
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    {this.state.paginationItem}

                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <br />
                                <br />
                                <br />
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default Devices;