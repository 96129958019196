import React, {Component} from 'react';
import DatatableHeader from './DatatableHeader';
import DatatableFooter from './DatatableFooter';
import DatatableBody from './DatatableBody';
import FileService from '../modules/FileService';
import {Link} from 'react-router-dom';
import LanguageService from '../modules/LanguageService';

const $ = require('jquery');
$.DataTable = require('datatables.net');


class DataTable extends Component{
    constructor(props){
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
        this.state = {
            tableName: props.tableName,
            fields: props.fields,
            fieldDataTypes: props.fieldDataTypes,
            fieldDecimalPlaces: props.fieldDecimalPlaces,
            dateFormat: props.dateFormat,
            timeFormat: props.timeFormat,
            headers: props.headers,
            tableBodyData: props.data,
            sortType: props.sortType,
            sortPosition: props.sortPosition,
            thStyle: props.thStyle,
            link: props.link,
            current_path: props.current_path,
            return_query_prefix: props.return_query_prefix,
            link_status: props.link_status,
            download: props.download,
            downloadFileName: props.downloadFileName,
            downloadFields: props.downloadFields,
            emptyMessage: props.emptyMessage,
            linkToBlankTarget: props.linkToBlankTarget || false,
            linkToBlankTargetToken: props.linkToBlankTargetToken || '',
        }
        this.File = new FileService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        

    }


    componentWillMount() {

    }

    

    componentDidMount() {
        
        $(this.refs.main).DataTable({
            "language": {
                "emptyTable": this.Language.DataTable.language.emptyTable,
                "info": this.Language.DataTable.language.info,
                "infoEmpty": this.Language.DataTable.language.infoEmpty,
                "infoFiltered": this.Language.DataTable.language.infoFiltered,
                "infoPostFix": this.Language.DataTable.language.infoPostFix,
                "infoThousands": this.Language.DataTable.language.infoThousands,
                "lengthMenu": this.Language.DataTable.language.lengthMenu,
                "loadingRecords": this.Language.DataTable.language.loadingRecords,
                "processing": this.Language.DataTable.language.processing,
                "search": this.Language.DataTable.language.search,
                "zeroRecords": this.Language.DataTable.language.zeroRecords,
                "paginate": {
                    "first": this.Language.DataTable.language.paginate.first,
                    "last": this.Language.DataTable.language.paginate.last,
                    "next": this.Language.DataTable.language.paginate.next,
                    "previous": this.Language.DataTable.language.paginate.previous
                },
                "aria": {
                    "sortAscending": this.Language.DataTable.language.aria.sortAscending,
                    "sortDescending": this.Language.DataTable.language.aria.sortDescending
                },
                "decimal": this.Language.DataTable.language.decimal,
                "thousands": this.Language.DataTable.language.thousands
            }
        });

        window.$('select').material_select();
    }

    componentWillUnmount(){
       $('.data-table-simple-wrapper')
       .find('data-table-simple-wrapper')
       .DataTable()
       .destroy(true);
    }

    shouldComponentUpdate() {
        return true;
    }


    downloadFile() {

        this.File.downloadCSV(this.state.tableBodyData, this.state.downloadFileName, this.state.downloadFields);
        
    }


    render(){

        if (this.state.tableBodyData && this.state.tableBodyData.length > 0){
        
            return (
                // <!--Data Table start-->
                <div id="table-datatables">
                    <span><h4 className="header">{this.state.tableName}</h4>{(this.state.download === true) ? <Link to={`#!`} onClick={this.downloadFile}><i className="material-icons right">file_download</i><div className="right black-text">{this.Language.DataTableLabel.DownloadFile}</div></Link> : null }</span>
                    <div className="row">
                    <div className="col s12">
                            <div id="data-table-simple-wrapper" className="dataTables_wrapper">
                                <table id="data-table-simple" className="responsive-table display dataTable" cellSpacing="0" role="grid" aria-describedby="data-table-simple_info" ref="main">
                                    <DatatableHeader headers={this.state.headers} sortPosition={this.state.sortPosition} sortType={this.state.sortType} thStyle={this.state.thStyle}/>
                                    <DatatableFooter footers={this.state.headers}/>
                                    <DatatableBody fields={this.state.fields} fieldDataTypes={this.state.fieldDataTypes} fieldDecimalPlaces={this.state.fieldDecimalPlaces} tableBodyData={this.state.tableBodyData} dateFormat={this.state.dateFormat} timeFormat={this.state.timeFormat} link={this.state.link} return_query_prefix={this.state.return_query_prefix} current_path={this.state.current_path} link_status={this.state.link_status} linkToBlankTarget={this.state.linkToBlankTarget} linkToBlankTargetToken={this.state.linkToBlankTargetToken} language={this.Language}/>
                                </table>
                                {/* ::after */}
                            </div>
                    </div>
                    {/* ::after */}
                    </div>
                </div>
                // <!--Data Table end-->
            )

        } else { // If no data is provided show an empty table with a single empty and message

            return (
                // <!--Empty Data Table start-->
                <div id="table-datatables">
                    <span><h4 className="header">{this.state.tableName}</h4></span>
                    <div className="row">
                    <div className="col s12">
                            <div id="data-table-simple-wrapper" className="dataTables_wrapper">
                                <table id="data-table-simple" className="responsive-table display dataTable" cellSpacing="0" role="grid" aria-describedby="data-table-simple_info" ref="main">
                                    <DatatableHeader headers={this.state.headers} sortPosition={1} sortType={"ascending"} thStyle={this.state.thStyle}/>
                                    <DatatableFooter footers={this.state.headers}/>
                                    {/* This returns an empty row with the empty data error message included */}
                                    <tbody>
                                        <tr role="row" className="odd">
                                            {this.state.headers.map((rowitem,  i) => {

                                                    if (i === 0) {

                                                        return(
                                                            <td className="sorting_1">{this.state.emptyMessage}</td>
                                                        )

                                                    } else {

                                                        return(
                                                            <td></td>
                                                        )

                                                    }

                                                })}
                                        </tr>
                                    </tbody>
                                </table>
                                {/* ::after */}
                            </div>
                    </div>
                    {/* ::after */}
                    </div>
                </div>
                // <!--Empty Data Table end-->
            )

        }



    }
}

export default DataTable;