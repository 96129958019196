import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditUserRole extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateUserRole = this.updateUserRole.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getUserRole = this.getUserRole.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            role_title: '',
            role_description: '',
            role_type: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_user_role: [],
            preloader: true,
            user_role_status: "Inactive",
            temp_user_role_status: "Inactive",
            logged_in_username: '',
            text_limit: 3000,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        
        this.getProfile();
        this.checkAccessRight();
        this.getUserRole();

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['update:roles', 'action:roles'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("user_type");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getUserRole() {

        let roleId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/user/roles/${roleId}?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    role_title: response.data.data[0].role_title,
                    role_description: response.data.data[0].role_description,
                    role_type: response.data.data[0].role_type,
                    user_role_hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                    document.title =
                      this.Language.PageTitle.EditUserRole.BeginningPhrase + ' ' + this.state.role_title + ' ' + this.Language.PageTitle.EditUserRole.EndPhrase + ' - ' + this.Language.EEGWebPortal;
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.EditUserRole} ${this.refs.role_title.value.trim()}`)) {



            let role_title = this.refs.role_title.value.trim();
            let role_description = this.refs.role_description.value;

            let role_type = this.refs.role_type.value;
            if (role_type === "Select Role Type") role_type = "";


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.UserRole.EditErrorTitle, [role_title, role_description, role_description, role_type], [this.Language.DataField.RoleTitle, this.Language.DataField.RoleDescription, this.Language.DataField.RoleDescription, this.Language.DataField.RoleType], ["isRequired", "isRequired", "maxLength", "isRequired"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {

                    if (role_type === "Select Role Type") role_type = null;

                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newUserRole = {
                        role_title: role_title,
                        role_description: role_description,
                        role_type: role_type,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_user_role_button").setAttribute("disabled", true);

                    this.updateUserRole(newUserRole);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_user_role = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'user_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_user_role: select_field_values_user_role });



                    };

                })


                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    updateUserRole(newUserRole) {

        let roleId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/user/roles/${roleId}`,
            data: newUserRole
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.UserRole.UpdateSuccess + newUserRole.role_title, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_user_role_button").removeAttribute("disabled");

            this.props.history.push('/settings/user/roles');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.UserRole.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserRole.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.UserRole.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_user_role_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_user_role_button").setAttribute("disabled", true);


        let role_title = this.state.role_title;
        let logged_in_username = this.state.logged_in_username;
        let roleId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteUserRole = {
            role_title: role_title,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/user/roles/${roleId}`,
            data: deleteUserRole
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.Language.NodeMessage.UserRole.DeleteSuccess + deleteUserRole.role_title, 8000, 'rounded green');
            })


            document.getElementById("delete_user_role_button").removeAttribute("disabled");
            this.props.history.replace('/settings/user/roles');

        })
            .catch(error => {
                if (error.response) {

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserRole.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_user_role_button").removeAttribute("disabled");

            });

    }




    render() {




        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"User Roles"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditUserRole.BeginningPhrase} ${this.state.role_title} ${this.Language.PageTitle.EditUserRole.EndPhrase}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.UserRoles, this.Language.BreadCrumbLabel.EditUserRole]} links={[`/settings/modules`, `/settings/user/roles`, `/settings/user/roles/edit/`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditUserRole}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">assignment_ind</i>
                                                            <input id="role_title" name="role_title" type="text" ref="role_title" value={this.state.role_title} />
                                                            <label htmlFor="role_title" className={(this.state.role_title && this.state.role_title !== null && typeof this.state.role_title !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.RoleTitle} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="role_description" className="materialize-textarea" data-length={this.state.text_limit} name="role_description" ref="role_description" value={this.state.role_description} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="role_description" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.role_description && this.state.role_description !== null && typeof this.state.role_description !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.RoleDescription} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-role_type" className="initialized" name="role_type" ref="role_type">
                                                                    {this.state.role_type && <option value={this.state.role_type} selected>{this.state.role_type}</option>}
                                                                    <option value="Select Role Type">{this.Language.DataFieldDefault.SelectRoleType}</option>
                                                                    {this.state.select_field_values_user_role.map((user_role_type_value, i) => {
                                                                        return (
                                                                            user_role_type_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.RoleType} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateUserRole}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateUserRole}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_user_role_button">
                                                                {this.Language.ButtonLabel.UpdateUserRole}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s2">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/settings/user/roles`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_user_role_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteUserRole} ${this.state.role_title}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditUserRole;