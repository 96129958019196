import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditECCUtilisation extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateECC = this.updateECC.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getECC = this.getECC.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.state = {
            certificate_number: '',
            date_issued: '',
            exporter_id: '',
            exporter_name: '',
            nxp_number: '',
            export_year: '',
            currency: '',
            certificate_value: '',
            certificate_value_words: '',
            value_utilised: '',
            balance_value: '',
            utilising_agency: '',
            is_utilised: false,
            is_utilised_initial_value: false,
            utilised_by: '',
            utilised_at: '',
            is_transfered: false,
            is_transfered_initial_value: false,
            transfered_by: '',
            transfered_at: '',
            transfered_to: '',
            transfer_approved: false,
            transfer_approved_initial_value: false,
            transfer_approved_by: '',
            transfer_approved_at: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            is_approved: false,
            is_approved_initial_value: false,
            approved_by: '',
            approved_at: '',
            hash_id: String,
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_organisation: [],
            hash_organisation: '',
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.Format = new FormatService();
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        
        this.getProfile();

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                logged_in_username: user_profile.username,
                hash_organisation: user_profile.partner
            }, () => {
                this.checkAccessRight();
                this.getECC();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:ecc_utilisation', 'action:ecc_utilisation'], 'view') === false)) {
            this.props.history.replace('/exporters/ecc/utilisation');
        } else {
            this.getDropdownValues(`single_organisation:${this.state.hash_organisation}`);
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }




    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {



                        const select_field_values_organisation = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'single_organisation') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_organisation: select_field_values_organisation });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    getECC() {

        let eccId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);


        axios.get(`${this.apiURL}/api/v1/eccs/specific/${eccId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {



                this.setState({
                    certificate_number: response.data.data[0].certificate_number || '',
                    date_issued: this.DateService.convertToCalendarFullDate(response.data.data[0].date_issued),
                    exporter_id: response.data.data[0].exporter_id || '',
                    exporter_name: response.data.data[0].exporter_name || '',
                    nxp_number: response.data.data[0].nxp_number || '',
                    export_year: response.data.data[0].export_year || '',
                    currency: response.data.data[0].currency || '',
                    certificate_value: response.data.data[0].new_certificate_value || '',
                    certificate_value_words: response.data.data[0].certificate_value_words || '',
                    value_utilised: response.data.data[0].new_value_utilised || '',
                    balance_value: response.data.data[0].new_balance_value || '',
                    utilising_agency: response.data.data[0].utilising_agency || '',
                    is_utilised: (response.data.data[0].is_utilised === true || response.data.data[0].is_utilised === 'true') ? true : false || false,
                    is_utilised_initial_value: (response.data.data[0].is_utilised === 'true' || response.data.data[0].is_utilised === true) ? true : false || false,
                    utilised_by: response.data.data[0].utilised_by || '',
                    utilised_at: this.DateService.convertToCalendarFullDate(response.data.data[0].utilised_at),
                    is_transfered: (response.data.data[0].is_transfered === true || response.data.data[0].is_transfered === 'true') ? true : false || false,
                    is_transfered_initial_value: (response.data.data[0].is_transfered === 'true' || response.data.data[0].is_transfered === true) ? true : false || false,
                    transfered_by: response.data.data[0].transfered_by || '',
                    transfered_at: this.DateService.convertToCalendarFullDate(response.data.data[0].transfered_at),
                    transfered_to: response.data.data[0].transfered_to || "",
                    transfer_approved: (response.data.data[0].transfer_approved === true || response.data.data[0].transfer_approved === 'true') ? true : false || false,
                    transfer_approved_initial_value: (response.data.data[0].transfer_approved === 'true' || response.data.data[0].transfer_approved === true) ? true : false || false,
                    transfer_approved_by: response.data.data[0].transfer_approved_by || '',
                    transfer_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].transfer_approved_at),
                    is_approved: (response.data.data[0].is_approved === true || response.data.data[0].is_approved === 'true') ? true : false || false,
                    is_approved_initial_value: (response.data.data[0].is_approved === true || response.data.data[0].is_approved === 'true') ? true : false || false,
                    approved_by: response.data.data[0].approved_by || '',
                    approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].approved_at),
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                    document.title = this.Language.PageTitle.EditECCUtilisation + ' ' + this.state.certificate_number + ' - ' + this.Language.EEGWebPortal;
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;


        let certificate_value = this.state.certificate_value || 0;
        let value_utilised = this.refs.value_utilised.value || 0;


        this.setState({
            [name]: value
        });



        if (name === 'value_utilised') {

            if (!isNaN(parseFloat(certificate_value)) && isFinite(certificate_value) && !isNaN(parseFloat(value_utilised)) && isFinite(value_utilised)) {
                this.setState({ balance_value: certificate_value - value_utilised });
            } else {
                this.setState({ balance_value: 0 });
            }
        }


    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;

        let certificate_value = this.state.certificate_value;
        let value_utilised = this.refs.value_utilised.value;


        if (name === 'is_utilised') {
            this.setState({ [name]: !this.state.is_utilised }, () => {

                if (this.state.is_utilised === true && (value_utilised === '0' || value_utilised === '')) {
                    this.setState({ value_utilised: certificate_value }, () => {
                        this.setState({ balance_value: certificate_value - this.state.value_utilised });
                    });

                }


                if (this.state.is_utilised === false) {
                    this.setState({ value_utilised: 0 }, () => {
                        this.setState({ balance_value: certificate_value });
                    });

                }

            });
        }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveECC} ${this.state.certificate_number}`)) {


            let certificate_number = this.state.certificate_number;
            let date_issued = this.state.date_issued;

            let exporter_id = this.state.exporter_id;
            if (exporter_id === "Select Exporter") exporter_id = "";

            let nxp_number = this.state.nxp_number;
            if (nxp_number === "Select NXP Number") nxp_number = "";

            let currency = this.state.currency;
            if (currency === "Select Currency") currency = "";

            let certificate_value = this.state.certificate_value;
            // let certificate_value_words = this.state.certificate_value_words;

            let temp_certificate_value = certificate_value || 0;
            let certificate_value_words = this.Format.numberToWords(temp_certificate_value, 'and') + ' ' + this.Language.DataField.NairaOnly;


            let value_utilised = this.refs.value_utilised.value;
            let balance_value = this.refs.balance_value.value;

            if (value_utilised === '0') value_utilised = 0;
            if (balance_value === '0' || balance_value === '0.0') balance_value = 0;


            let utilising_agency = this.refs.utilising_agency.value;
            if (utilising_agency === "Select Utilising Agency") utilising_agency = "";

            let currentdate = new Date();


            let is_utilised_initial_value = this.state.is_utilised_initial_value;
            let is_utilised = this.refs.is_utilised.checked;
            let utilised_by = null;
            let utilised_at = null;
            if (is_utilised_initial_value === true && is_utilised === true) {
                utilised_by = this.state.utilised_by;
                utilised_at = this.state.utilised_at;
            }
            if (is_utilised_initial_value === false && is_utilised === true) {
                utilised_by = this.state.logged_in_username;
                utilised_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
            }


            let is_transfered_initial_value = this.state.is_transfered_initial_value;
            let is_transfered = this.state.is_transfered;
            let transfered_by = null;
            let transfered_at = null;
            if (is_transfered_initial_value === true && is_transfered === true) {
                transfered_by = this.state.transfered_by;
                transfered_at = this.state.transfered_at;
            }
            if (is_transfered_initial_value === false && is_transfered === true) {
                transfered_by = this.state.logged_in_username;
                transfered_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
            }



            let transfered_to = this.state.transfered_to.toLowerCase();
            if (transfered_to === "enter name of company") {
                transfered_to = "";
            } else {
                transfered_to = this.state.transfered_to;
            }


            let transfer_approved_initial_value = this.state.transfer_approved_initial_value;
            let transfer_approved = this.state.transfer_approved;
            let transfer_approved_by = null;
            let transfer_approved_at = null;
            if (transfer_approved_initial_value === true && transfer_approved === true) {
                transfer_approved_by = this.state.transfer_approved_by;
                transfer_approved_at = this.state.transfer_approved_at;
            }
            if (transfer_approved_initial_value === false && transfer_approved === true) {
                transfer_approved_by = this.state.logged_in_username;
                transfer_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
            }



            let is_approved_initial_value = this.state.is_approved_initial_value;
            let is_approved = this.state.is_approved;
            let approved_by = null;
            let approved_at = null;
            if (is_approved_initial_value === true && is_approved === true) {
                approved_by = this.state.approved_by;
                approved_at = this.state.approved_at;
            }
            if (is_approved_initial_value === false && is_approved === true) {
                approved_by = this.state.logged_in_username;
                approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
            }


            let status = this.state.status;

            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;



            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ECC.AddErrorTitle, [certificate_number, date_issued, exporter_id, nxp_number, currency, certificate_value, certificate_value, value_utilised, value_utilised, balance_value, balance_value, utilising_agency], [this.Language.DataField.CertificateNumber, this.Language.DataField.DateIssued, this.Language.DataField.Exporter, this.Language.DataField.NXPNumber, this.Language.DataField.Currency, this.Language.DataField.CertificateValue, this.Language.DataField.CertificateValue, this.Language.DataField.ValueUtilised, this.Language.DataField.ValueUtilised, this.Language.DataField.BalanceValue, this.Language.DataField.BalanceValue, this.Language.DataField.UtilisingAgency], ["isRequired", "isRequiredDate", "isRequired", "isRequired", "isRequired", "isRequired", "isNumber", "isRequired", "isNumber", "isRequired", "isNumber", "isRequired"], 0, 0) }, () => {

                if (this.state.error_messages === null) {

                    if (exporter_id === "Select Exporter") exporter_id = null;
                    if (nxp_number === "Select NXP Number") nxp_number = null;
                    if (utilising_agency === "Select Utilising Agency") utilising_agency = null;
                    if (transfered_to === "enter name of company") transfered_to = null;
                    if (currency === "Select Currency") currency = null;

                    if (value_utilised === '0' || value_utilised === 0) {
                        value_utilised = 0;
                    } else {
                        value_utilised = Math.abs(value_utilised);
                    }


                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newECC = {
                        certificate_number: certificate_number,
                        date_issued: date_issued,
                        exporter_id: exporter_id,
                        nxp_number: nxp_number,
                        currency: currency,
                        certificate_value: Math.abs(certificate_value) || 0,
                        certificate_value_words: certificate_value_words,
                        value_utilised: value_utilised,
                        balance_value: Math.abs(balance_value) || 0,
                        utilising_agency: utilising_agency,
                        is_utilised: is_utilised,
                        utilised_by: utilised_by,
                        utilised_at: utilised_at,
                        is_transfered: is_transfered,
                        transfered_by: transfered_by,
                        transfered_at: transfered_at,
                        transfered_to: transfered_to,
                        transfer_approved: transfer_approved,
                        transfer_approved_by: transfer_approved_by,
                        transfer_approved_at: transfer_approved_at,
                        status: status,
                        is_approved: is_approved,
                        approved_by: approved_by,
                        approved_at: approved_at,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_ecc_button").setAttribute("disabled", true);

                    this.updateECC(newECC);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }





    updateECC(newECC) {

        let eccId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/eccs/${eccId}`,
            data: newECC
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ECC.UpdateSuccess + newECC.certificate_number, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_ecc_button").removeAttribute("disabled");

            this.props.history.push('/exporters/ecc/utilisation');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ECC.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ECC.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.ECC.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/exporters/ecc/utilisation');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_ecc_button").removeAttribute("disabled");

            });

    }





    render() {


        // let eccId = this.props.match.params.id;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"ECCs"} active_menu_level_2={"ECC Utilisation"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditECCUtilisation} ${this.state.certificate_number}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ECCUtilisationSummary, this.Language.BreadCrumbLabel.EditECCUtilisation]} links={[`/`, `/exporters/ecc/utilisation`, `/exporters/ecc/utilisation/edit`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditECCUtilisation}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>


                                                    <div className="card teal lighten-2">
                                                        <div className="card-content white-text">
                                                            <span className="card-title">{this.Language.DataField.Exporter}: <b>{this.state.exporter_name}</b> </span>
                                                            <p>
                                                                {this.Language.DataField.CertificateNumber}: <b>{this.state.certificate_number}</b>
                                                            </p>
                                                            <p> {this.Language.DataField.DateIssued}: <b>{this.state.date_issued}</b> </p>
                                                            <p> {this.Language.DataField.NXPNumber}: <b>{this.state.nxp_number}</b> </p>
                                                            <p> {this.Language.DataField.CertificateValue}: <b>{this.state.currency} {this.Format.FormatLocaleNumber(this.state.certificate_value, this.Language.Lang, true, 2)}</b> </p>
                                                            <p> {this.Language.DataField.CertificateValue}: <b>{this.state.certificate_value_words}</b> </p>
                                                            <p> {this.Language.DataField.IsTransferred}: <b>{this.state.is_transfered ? ((this.state.is_transfered === 'true') || (this.state.is_transfered === true) ? <span className="white-text">{this.Language.DataValue.Yes}</span> : <span className="white-text">{this.Language.DataValue.No}</span>) : null}</b> </p>
                                                            <p> {this.Language.DataField.TransferApproved}: <b>{this.state.transfer_approved ? ((this.state.transfer_approved === 'true') || (this.state.transfer_approved === true) ? <span className="white-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null}</b> </p>
                                                            <p> {this.Language.DataField.TransferredTo}: <b>{this.state.transfered_to}</b> </p>
                                                            <p> {this.Language.DataField.IsApproved}: <b>{this.state.is_approved ? ((this.state.is_approved === 'true') || (this.state.is_approved === true) ? <span className="white-text">{this.Language.DataValue.Yes}</span> : <span className="white-text">{this.Language.DataValue.No}</span>) : null}</b> </p>
                                                            <p> {this.Language.DataField.Status}: <b>{this.state.status ? (this.state.status === 'Active' ? <span className="white-text">{this.Language.DataValue.Active}</span> : <span className="white-text">{this.Language.DataValue.Inactive}</span>) : null}</b> </p>
                                                        </div>
                                                    </div>


                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="is_utilised" ref="is_utilised" checked={this.state.is_utilised} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="is_utilised">{this.Language.DataField.IsUtilised}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="value_utilised" type="number" className="validate" ref="value_utilised" value={this.state.value_utilised} onChange={this.handleInputChange} />
                                                            <label htmlFor="value_utilised" data-error={this.Language.DataFieldError.IntegerNumber} className={((this.state.value_utilised && this.state.value_utilised !== null && typeof this.state.value_utilised !== 'undefined') || this.state.value_utilised === 0) ? "active" : ""}>
                                                                {this.Language.DataField.ValueUtilised} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="balance_value" type="number" className="validate" ref="balance_value" value={this.state.balance_value} />
                                                            <label htmlFor="balance_value" data-error={this.Language.DataFieldError.IntegerNumber} className={((this.state.balance_value && this.state.balance_value !== null && typeof this.state.balance_value !== 'undefined') || this.state.balance_value === 0) ? "active" : ""}>
                                                                {this.Language.DataField.BalanceValue} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-utilising_agency" className="initialized" name="utilising_agency" ref="utilising_agency">
                                                                    {this.state.utilising_agency && <option value={this.state.utilising_agency} selected>{this.state.utilising_agency}</option>}
                                                                    <option value="Select Utilising Agency">{this.Language.DataFieldDefault.SelectPartnerAgency}</option>
                                                                    {this.state.select_field_values_organisation.map((organisation_value, i) => {
                                                                        return (
                                                                            organisation_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.UtilisingAgency} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_ecc_button">
                                                                {this.Language.ButtonLabel.UpdateECC}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s5">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/exporters/ecc/utilisation`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditECCUtilisation;