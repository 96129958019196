import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');

class ExporterExportedItemsProfile extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getExporterExportedItems = this.getExporterExportedItems.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            exporterExportedItems: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 500,
            paginationItem: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            exporterExportedItemDataItems: null,
            exporter_exported_item_create: null,
            exporter_exported_item_edit: null,
            hash_organisation: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {


    }

    componentDidMount() {
        document.title = this.Language.PageTitle.ExporterExportedItems + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

        this.getExporterExportedItems(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })


    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['read:exported_item', 'action:exported_item'], 'view') === false) || this.state.hash_organisation !== this.props.match.params.id) {
            this.props.history.replace(`/exporter/profile/${this.state.hash_organisation}`);
        } else {
            this.getAccessScopes();
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null) {
            this.setState({
                hash_organisation: user_profile.organisation,
                scopes: user_profile.scopes
            }, () => {
                this.checkAccessRight();
            });
        }

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:exported_item' || this.state.scopes['views'][i] === 'action:exported_item') {
                    this.setState({ exporter_exported_item_create: true });
                }
                if (this.state.scopes['views'][i] === 'update:exported_item' || this.state.scopes['views'][i] === 'action:exported_item') {
                    this.setState({ exporter_exported_item_edit: true });
                }
            }
        };

    }



    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            exporterExportedItemDataItems: null,
            paginationItem: null,
            exporterExportedItems: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getExporterExportedItems(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }



    getExporterExportedItems(currentNumberOfRecords) {

        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/exporter/exported/items/all/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ exporterExportedItems: response.data.data }, () => {

                    this.setState({
                        exporterExportedItemDataItems: <DataTable tableName={''}
                            fields={['exporter_name', 'exported_item', 'exported_item_description', 'status']}
                            fieldDataTypes={['String', 'String', 'String', 'Status']}
                            fieldDecimalPlaces={[0, 0, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.Exporter, this.Language.DataTableHeader.ExportedProduct, this.Language.DataTableHeader.Description, this.Language.DataTableHeader.Status]}
                            data={this.state.exporterExportedItems}
                            sortType={"ascending"} sortPosition={1} thStyle={[281, 364, 340, 100]}
                            link={`/exporter/${this.state.hash_organisation}/exported/items/edit/`} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={this.state.exporter_exported_item_edit} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.ExporterExportedItems} emptyMessage={this.Language.EmptyMessage.ExporterExportedItems}
                            downloadFields={['exporter_name', 'registration_number', 'exported_item', 'exported_item_description', 'status']} />
                    })

                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });
                })

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    render() {

        let exporterId = this.props.match.params.id;

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Organisation"} active_menu_level_2={""} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.ExporterExportedItems}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ExporterProfile, this.Language.BreadCrumbLabel.ExporterExportedItems]} links={[`/`, `/exporter/profile/${exporterId}`, `/exporter/${exporterId}/exported/items/`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                {(this.state.exporter_exported_item_create) && <Link className="waves-effect waves-light btn right" to={`/exporter/${exporterId}/exported/items/add`}><i className="material-icons left">library_add</i>{this.Language.ButtonLabel.AddExportedItem}</Link>}
                                <br />
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.exporterExportedItemDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default ExporterExportedItemsProfile;