import React, { Component } from 'react';
import FormatService from '../modules/FormatService';



class PaginationGetDataLimit extends Component{
    constructor(props){
        super(props);
        this.state = {
            numberOfRecords: props.numberOfRecords,
            currentNumberOfRecords: props.currentNumberOfRecords,
            language: props.language,
            optionsList: [],
            button: props.button
            
        }
        this.Format = new FormatService();
        

    }


    componentDidMount(){

        // prepare the list of values for the dropdown list
        this.setState({optionsList: this.Format.GenerateStepNumberListWithinRange(0, this.state.numberOfRecords) || []}, () => {
            window.$('select').material_select();
        });
        

    }




    render(){

        let currentNumberOfRecords = this.state.currentNumberOfRecords;
        let numberOfRecords = this.state.numberOfRecords;

        if (currentNumberOfRecords !== 'All records') {
            // these values is being forced to an integer using parseInt due to instances where the value is seen as string instead of integer
            currentNumberOfRecords = parseInt(currentNumberOfRecords, 10);
            numberOfRecords = parseInt(numberOfRecords, 10);
        } else {
            // these values is being forced to an integer using parseInt due to instances where the value is seen as string instead of integer
            currentNumberOfRecords = parseInt(numberOfRecords, 10);
            numberOfRecords = parseInt(numberOfRecords, 10);
        }
         


        if (currentNumberOfRecords >= numberOfRecords) currentNumberOfRecords = numberOfRecords;
        const numberOfRecordsLeft = numberOfRecords - currentNumberOfRecords;



        const optionsList = this.state.optionsList.map((option_value,  i) => {
            if (parseInt(option_value, 10) !== currentNumberOfRecords){
                return(
                    <option value={option_value} key={i + 1}>{option_value}</option>
                )
            } else {
                return(
                    null
                )
            }

        })


        return (
            <ul className="pagination">
                <li className="" >
                    <br/>
                    <b>{this.state.language.Pagination.RecordsRetrieved} {" :"}</b>
                </li>
                <li className="input-field col s3" >
                    <div className="select-wrapper initialized">
                        <span className="caret">▼</span>
                        <select data-select-id="select-options-current_number_of_records" className="initialized" id="current_number_of_records" name="current_number_of_records" ref="current_number_of_records">
                            <option value={currentNumberOfRecords}>{currentNumberOfRecords} ({this.state.language.Pagination.Retrieved})</option>
                            {optionsList}
                            <option value={"All records"}>{this.state.language.Pagination.AllRecords}</option>
                        </select>
                    </div>
                    <label>
                    </label>
                </li>
                {this.state.button}
                <li className="" >
                    <br/>
                    <b>({this.state.language.Pagination.RecordsLeft} {numberOfRecordsLeft || 0})</b>
                </li>
            </ul>
        )

    }
}

export default PaginationGetDataLimit;