import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class AddDataFieldSecurity extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addAnnualRegistration = this.addAnnualRegistration.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.getExportYears = this.getExportYears.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.uncheckAll = this.uncheckAll.bind(this);
        this.state = {
            message: '',
            status: '',
            pick_list_values: [],
            select_field_values_exporter: [],
            select_field_values_export_year: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.AddAnnualRegistration + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getExportYears();

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getExportYears() {

        let year_temp = null;
        let select_field_values_export_year = [];

        for (let i = 0; i < 20; i++) { // populate the values in the export year dropdown
            if (i === 0) {
                year_temp = new Date();
                year_temp = year_temp.getFullYear();
                select_field_values_export_year.push(year_temp - 5); // add five precedding years to the list
                select_field_values_export_year.push(year_temp - 4);
                select_field_values_export_year.push(year_temp - 3);
                select_field_values_export_year.push(year_temp - 2);
                select_field_values_export_year.push(year_temp - 1);
                select_field_values_export_year.push(year_temp);
            } else {
                select_field_values_export_year.push(select_field_values_export_year[select_field_values_export_year.length - 1] + 1); // get the last element in the array and add 1 to it before adding the value back to the array
            }
        }

        this.setState({ select_field_values_export_year: select_field_values_export_year });



    }

    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if (this.Auth.checkAccessScope(['create:annual_reg', 'action:annual_reg'], 'view') === false) {
            this.props.history.replace('/exporters/annual/registration');
        } else {
            this.getDropdownValues("exporter");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }


    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }





    prepareData(return_option) {


        let export_year = this.refs.export_year.value;
        if (export_year === "Select Export Year") export_year = "";

        let selected = document.querySelectorAll('#exporter option:checked'); // this is to get values from a multiple select field
        let exporter_array = Array.from(selected).map((el) => el.value);




        let status = "Active";

        let created_by = this.state.logged_in_username;
        let last_updated_by = this.state.logged_in_username;


        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.AnnualRegistration.AddErrorTitle, [export_year, exporter_array], [this.Language.DataField.ExportYear, this.Language.DataField.Exporter], ["isRequired", "isArray"], 0, 0) }, () => {

            if (this.state.error_messages === null) {


                if (export_year === "Select Export Year") export_year = null;

                if (exporter_array !== null && typeof exporter_array !== 'undefined') {


                    const userOS = this.Browser.getOS();

                    //make call to database 
                    let newAnnualRegistration = {
                        export_year: export_year,
                        exporter_id: exporter_array,
                        status: status,
                        created_by: created_by,
                        last_updated_by: last_updated_by,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }



                    this.setState({ preloader: true });
                    document.getElementById("save_annual_registration_button").setAttribute("disabled", true);

                    this.addAnnualRegistration(newAnnualRegistration, return_option);

                }

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }



    clearForm() {

        document.getElementById("add_annual_registration_form").reset();
        document.getElementById("export_year").reset();
        document.getElementById("exporter").reset();

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {

                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_exporter = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'exporter') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_exporter: select_field_values_exporter });



                        const select_field_values_export_year = this.state.select_field_values_export_year.map((export_year_value, i) => {
                            return (
                                <SelectField pick_list_id={export_year_value} key={i} item_name={export_year_value} />
                            )
                        })

                        this.setState({ select_field_values_export_year: select_field_values_export_year });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    addAnnualRegistration(newAnnualRegistration, return_option) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/exporter/annual/registration`,
            data: newAnnualRegistration
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.AddSuccess + newAnnualRegistration.export_year, 8000, 'rounded green');

            })


            this.setState({ preloader: null });
            document.getElementById("save_annual_registration_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/exporters/annual/registration');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("save_annual_registration_button").removeAttribute("disabled");

            });

    }


    checkAll() {
        // code to check all exporters in the dropdown option 
        window.$('select[name="exporter"] option:not(:disabled)').not(':selected').prop('selected', true);
        // window.$('.dropdown-content.multiple-select-dropdown input[type="checkbox"]:not(:checked)').not(':disabled').prop('checked', 'checked');
        window.$('select').material_select();
    }


    uncheckAll() {
        // code to uncheck all exporters in the dropdown option 
        $('select[name="exporter"] option:selected').not(':disabled').prop('selected', false);
        // $('.dropdown-content.multiple-select-dropdown input[type="checkbox"]:checked').not(':disabled').prop('checked', '');
        window.$('select').material_select();
    }






    render() {

        const lengthCheck = 25;

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Exporter Management"} active_menu_level_2={"Annual Registration"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.AddAnnualRegistration}`} breadcrumbs={[this.Language.BreadCrumbLabel.ExporterManagement, this.Language.BreadCrumbLabel.AnnualRegistration, this.Language.BreadCrumbLabel.AddAnnualRegistration]} links={[`/exporters`, `/exporters/annual/registration`, `/exporters/annual/registration/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.AddAnnualRegistration}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_annual_registration_form" ref="add_annual_registration_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s3">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-export_year" className="initialized" name="export_year" ref="export_year">
                                                                    <option value="Select Export Year">{this.Language.DataFieldDefault.SelectExportYear}</option>
                                                                    {this.state.select_field_values_export_year.map((export_year_value, i) => {
                                                                        return (
                                                                            export_year_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.ExportYear} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s2">
                                                            <Link to={'#!'} className="btn teal lighten-2 waves-effect waves-light box-shadow-none border-round mr-1" name="check_all" id="check_all" onClick={() => { this.checkAll() }}>
                                                                {this.Language.ButtonLabel.CheckAll}
                                                            </Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <Link to={'#!'} className="btn teal lighten-2 waves-effect waves-light box-shadow-none border-round mr-1" name="uncheck_all" id="uncheck_all" onClick={() => { this.uncheckAll() }}>
                                                                {this.Language.ButtonLabel.UncheckAll}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">account_balance</i>
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select multiple="multiple" size={5} className="initialized" name="exporter" id="exporter" ref="exporter">
                                                                    <option value="Select Exporters" disabled >{this.Language.DataFieldDefault.SelectExporters}</option>
                                                                    {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                                                        return (
                                                                            exporter_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Exporter} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveAnnualRegistration.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_annual_registration_button">
                                                                {this.Language.ButtonLabel.SaveAnnualRegistration}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveAnnualRegistration.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/exporters/annual/registration`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar  language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddDataFieldSecurity;