import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');


class UserSpecificSignins extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getUserSignins = this.getUserSignins.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            userSignins: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 200,
            paginationItem: null,
            username: '',
            name: '',
            result: '',
            message: '',
            preloader: true,
            userSigninDataItems: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();

    }

    componentDidMount() {
        

        this.getUserSignins(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:user', 'action:user'], 'view') === false) {
            this.props.history.replace(default_home);
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                username: user_profile.username,
                name: user_profile.name
            }, () => {
                document.title = this.state.username + ' ' + this.Language.PageTitle.UserSignins + ' - ' + this.Language.EEGWebPortal;
            });
        }

    }


    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            userSigninDataItems: null,
            paginationItem: null,
            userSignins: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getUserSignins(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }



    getUserSignins(currentNumberOfRecords) {

        let userId = this.props.match.params.id;
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/user/signin/history/${userId}?tk=${this.Auth.getToken()}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.


                this.setState({ userSignins: response.data.data }, () => {

                    this.setState({
                        userSigninDataItems: <DataTable tableName={''}
                            fields={['username', 'device_name', 'device_os', 'browser', 'signin_time', 'sign_out_time']}
                            fieldDataTypes={['String', 'String', 'String', 'String', 'Date', 'Date']}
                            fieldDecimalPlaces={[0, 0, 0, 0, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.Username, this.Language.DataTableHeader.DeviceName, this.Language.DataTableHeader.DeviceOS, this.Language.DataTableHeader.Browser, this.Language.DataTableHeader.Signin, this.Language.DataTableHeader.Signout]}
                            data={this.state.userSignins}
                            sortType={"descending"} sortPosition={5} thStyle={[151, 200, 124, 114, 251, 245]}
                            link={"/user/profile/signins"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={false} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={`${this.state.username} ${this.Language.DownloadFileName.UserSignins}`} emptyMessage={this.Language.EmptyMessage.UserSignins}
                            downloadFields={['username', 'device_name', 'device_os', 'browser', 'signin_time', 'sign_out_time']} />
                    })

                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });

                })

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    render() {

        let userId = this.props.match.params.id;

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Signin History"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.state.username} ${this.Language.PageTitle.UserSignins}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.UserProfile, this.Language.BreadCrumbLabel.Signins]} links={[`/`, `/user/profile/${userId}`, `/user/profile/signins/${userId}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.userSigninDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default UserSpecificSignins;