import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import axios from 'axios';



class BaselineThresholdWeight extends Component{
    constructor(props){
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getBaselineThresholdWeight = this.getBaselineThresholdWeight.bind(this);
        this.state = {
            baselineThresholdWeightItems: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            baselineThresholdWeightDataItems:  null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();
        
    }


    componentWillMount(){
        this.checkAccessRight();
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.BaselineThresholdAndWeight + ' - ' + this.Language.EEGWebPortal;
        this.getBaselineThresholdWeight();

        
    }


    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

       if ( this.Auth.checkAccessScope(['read:baselines', 'action:baselines'], 'view') === false){
            this.props.history.replace(default_home);   
       };

    }





    getBaselineThresholdWeight(){

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/baseline/data/threshold/weight?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({baselineThresholdWeightItems: response.data.data}, () => {

                        this.setState({baselineThresholdWeightDataItems: <DataTable tableName={''} 
                                        fields={['parameter', 'threshold', 'weight', 'start_year', 'end_year', 'is_approved', 'approved_by', 'status']} 
                                        fieldDataTypes={['String', 'Number', 'Number', 'Date-Year', 'Date-Year', 'Boolean', 'String', 'Status']}
                                        fieldDecimalPlaces={[0, 2, 2, 0, 0, 0, 0, 0]}
                                        dateFormat={'long_date'}
                                        timeFormat={'h12'}
                                        headers={[this.Language.DataTableHeader.Parameter, this.Language.DataTableHeader.Threshold, this.Language.DataTableHeader.Weight, this.Language.DataTableHeader.StartYear, this.Language.DataTableHeader.EndYear, this.Language.DataTableHeader.IsApproved, this.Language.DataTableHeader.ApprovedBy, this.Language.DataTableHeader.Status]} 
                                        data={this.state.baselineThresholdWeightItems} 
                                        sortType={"ascending"} sortPosition={1} thStyle={[225, 221, 164, 100, 100, 100, 100, 75]}
                                        link={"/baseline/data//threshold/weight/"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={false} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.BaselineThresholdAndWeight} emptyMessage={this.Language.EmptyMessage.BaselineThresholdAndWeight}
                                        downloadFields={['parameter', 'threshold', 'weight', 'start_year', 'end_year', 'is_approved', 'approved_by', 'approved_at', 'status']}/> 
                                        })

                    this.setState({preloader: null});
                })

                this.setState({result: response.data.status}, () => {
                })

                this.setState({message: response.data.message}, () => {
                })

                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx


                    if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                  

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                } else {
                  // Something happened in setting up the request that triggered an Error
                }
              });

    }



    render(){

         return ( 
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Baseline Data"} active_menu_level_2={"Baseline Threshold & Weight"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.BaselineThresholdAndWeight}`} breadcrumbs={[this.Language.BreadCrumbLabel.BaselineData, this.Language.BreadCrumbLabel.BaselineThresholdAndWeight]} links={[`/exporter/baseline/data`, `/exporter/baseline/threshold/weight`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <br/>
                                {this.state.preloader   &&  <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}
                                    {this.state.baselineThresholdWeightDataItems}
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                

                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]
            
        )
    }
}

export default BaselineThresholdWeight;