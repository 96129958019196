import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import SelectField from '../components/SelectField';

import ReactDOM from 'react-dom';

const $ = require('jquery');


class LanguageAndTimeZone extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getLanguageTimeZoneSetting = this.getLanguageTimeZoneSetting.bind(this);
        this.state = {
            hash_id: '',
            user_time_zone: '(GMT+00:00) UTC',
            user_time_zone_full_value: 'UTC***(GMT+00:00) UTC***UTC',
            pick_list_values_time_zone: [],
            language: 'English',
            languages: ['English', 'Français', '中文'],
            preloader: true,
            logged_in_username: '',
            hash_user: '',
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        
    }


    componentWillMount(){
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.LanguageAndTimeZone + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getLanguageTimeZoneSetting();

        this.setState({pick_list_values_time_zone: this.DateService.getTimezoneList(this.Language.Lang),
            languages: LanguageService.prototype.getLanguageList()
        });

        window.$(ReactDOM.findDOMNode(this.refs.language)).on('change',this.changeCurrentLanguage.bind(this));
        window.$(ReactDOM.findDOMNode(this.refs.user_time_zone)).on('change',this.changeCurrentTimeZone.bind(this));

    }



    componentDidUpdate(){
       
    }

    componentWillUnmount(){
        $('select').material_select();
    }

    


    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile){
            this.setState({logged_in_username: user_profile.username,
                hash_user: user_profile.user}, () => {
                this.checkAccessRight();
            });
            
        }

    }


    checkAccessRight(){

        if ( (this.Auth.checkAccessScope(['read:user', 'update:user', 'action:user'], 'view') === false || this.state.hash_user !== this.props.match.params.id) ){
             this.props.history.replace(`/`);   
        } else {

        };
 
     }







     getLanguageTimeZoneSetting(){

        let user_time_zone_full_value = this.DateService.getUserTimeZone();
        let user_time_zone = user_time_zone_full_value.split("***");
        user_time_zone = user_time_zone[1] || user_time_zone || '(GMT+00:00) UTC';
        


        this.setState({
            language: this.Language.Language,
            user_time_zone: user_time_zone,
            user_time_zone_full_value: user_time_zone_full_value,
            preloader: null
        })


        window.$('select').material_select();



    }





    changeCurrentTimeZone(event){

        event.preventDefault();

        const user_time_zone = this.refs.user_time_zone.value;

        if(this.state.user_time_zone !== user_time_zone) {
            this.setState({user_time_zone: user_time_zone});

            DateService.prototype.setUserTimeZone(user_time_zone);
            window.location.reload();
        }
       
    }



    changeCurrentLanguage(event){

        event.preventDefault();

        let lang = this.refs.language.value;

        if(this.state.language !== lang) {
            this.setState({language: lang});

            LanguageService.prototype.setTranslation(lang);
            window.location.reload();
        }

     }




    render(){

        const select_field_values_language = this.state.languages.map((language,  i) => {
            if (language !== this.state.language){
                return(
                    <option value={language} key={i}>{language}</option>
                )
            } else {
                return(
                    null
                )
            }
        })



        const select_field_values_time_zone_region = this.state.pick_list_values_time_zone.map((pick_list_value,  i) => {
                return(
                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name}/>
                )
        })

        window.$('select').material_select();


        

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Language and Time Zone"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.LanguageAndTimeZone}`} breadcrumbs={[this.Language.BreadCrumbLabel.Profile, this.Language.BreadCrumbLabel.LanguageAndTimeZone,]} links={[`/user/profile/${this.state.hash_user}`, `/settings/language/timezone/${this.state.hash_user}`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                    {this.state.error_messages}

                                        <div className="card-panel">

                {this.state.preloader   &&  <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.CurrentLanguageAndTimeZone}</h4>
                                            <div className="row">

                                                <div className="container">

                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-language" name="language" ref="language">
                                                                    <option value={this.state.language}>{this.state.language}</option>
                                                                        {select_field_values_language}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.CurrentLanguage}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-user_time_zone" name="user_time_zone" ref="user_time_zone">
                                                                    <option value={this.state.user_time_zone_full_value}>{this.state.user_time_zone}</option>
                                                                        {select_field_values_time_zone_region}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.CurrentTimeZone}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                </div>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]  
        )
    }
}

export default LanguageAndTimeZone;