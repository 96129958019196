import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class AddPasswordConfiguration extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addPasswordConfiguration = this.addPasswordConfiguration.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            text_limit: 5,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.AddPasswordConfiguration + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }



    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['create:password_config', 'action:password_config'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.setState({ preloader: null });
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }



    prepareData(return_option) {


        let password = this.refs.password.value + '';


        if (password.length > 0) {

            let limit_pasword_length = this.refs.limit_pasword_length.checked;

            let min_password_length = this.refs.min_password_length.value.replace(/\s/g, "");
            let max_password_length = this.refs.max_password_length.value.replace(/\s/g, "");

            let allow_alphanumeric = this.refs.allow_alphanumeric.checked;
            let allow_capital = this.refs.allow_capital.checked;
            let allow_special_char = this.refs.allow_special_char.checked;
            let allow_user_id = this.refs.allow_user_id.checked;
            let must_contain_lower_case = this.refs.must_contain_lower_case.checked;
            let must_contain_upper_case = this.refs.must_contain_upper_case.checked;
            let must_contain_special_character = this.refs.must_contain_special_character.checked;
            let allow_consectutive_character = this.refs.allow_consectutive_character.checked;
            let allow_password_reuse = this.refs.allow_password_reuse.checked;

            let allow_password_reuse_after = this.refs.allow_password_reuse_after.value.replace(/\s/g, "");

            let check_password_history = this.refs.check_password_history.checked;

            let password_history_count = this.refs.password_history_count.value.replace(/\s/g, "");
            let password_expiry_days = this.refs.password_expiry_days.value.replace(/\s/g, "");


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let username = this.state.logged_in_username;
            let created_by = this.state.logged_in_username;



            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.PasswordConfiguration.AddErrorTitle, [min_password_length, min_password_length, min_password_length, max_password_length, max_password_length, max_password_length, allow_password_reuse_after, allow_password_reuse_after, allow_password_reuse_after, password_history_count, password_history_count, password_history_count, password_expiry_days, password_expiry_days, password_expiry_days], [this.Language.DataField.MinimumPasswordLength, this.Language.DataField.MinimumPasswordLength, this.Language.DataField.MinimumPasswordLength, this.Language.DataField.MaximumPasswordLength, this.Language.DataField.MaximumPasswordLength, this.Language.DataField.MaximumPasswordLength, this.Language.DataField.AllowPasswordToBeReusedAfter, this.Language.DataField.AllowPasswordToBeReusedAfter, this.Language.DataField.AllowPasswordToBeReusedAfter, this.Language.DataField.CheckNumberOfPreviousPasswordHistoryBeforeAllowingPasswordReuse, this.Language.DataField.CheckNumberOfPreviousPasswordHistoryBeforeAllowingPasswordReuse, this.Language.DataField.CheckNumberOfPreviousPasswordHistoryBeforeAllowingPasswordReuse, this.Language.DataField.NumberOfDaysBeforePasswordExpiry, this.Language.DataField.NumberOfDaysBeforePasswordExpiry, this.Language.DataField.NumberOfDaysBeforePasswordExpiry], ["isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {


                    const userOS = this.Browser.getOS();


                    //make call to database 
                    const newPasswordConfiguration = {
                        username: username,
                        password: password,
                        limit_pasword_length: limit_pasword_length,
                        min_password_length: Math.abs(min_password_length) || null,
                        max_password_length: Math.abs(max_password_length) || null,
                        allow_alphanumeric: allow_alphanumeric,
                        allow_capital: allow_capital,
                        allow_special_char: allow_special_char,
                        allow_user_id: allow_user_id,
                        must_contain_lower_case: must_contain_lower_case,
                        must_contain_upper_case: must_contain_upper_case,
                        must_contain_special_character: must_contain_special_character,
                        allow_consectutive_character: allow_consectutive_character,
                        allow_password_reuse: allow_password_reuse,
                        allow_password_reuse_after: Math.abs(allow_password_reuse_after) || null,
                        check_password_history: check_password_history,
                        password_history_count: Math.abs(password_history_count) || null,
                        password_expiry_days: Math.abs(password_expiry_days) || null,
                        status: status,
                        created_by: created_by,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }




                    this.setState({ preloader: true });
                    document.getElementById("create_password_configuration_button").setAttribute("disabled", true);

                    this.addPasswordConfiguration(newPasswordConfiguration, return_option);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });


        } else {

            window.Materialize.toast(this.Language.ReactValidationMessage.PasswordConfiguration.PleaseEnterYourPassword, 4000, 'rounded deep-orange darken-4');
        }


    }


    clearForm() {

        document.getElementById("add_password_configuration_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }



    addPasswordConfiguration(newPasswordConfiguration, return_option) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/user/password/config`,
            data: newPasswordConfiguration
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.AddSuccess, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("create_password_configuration_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/security/password/configuration');
            } else {
                this.clearForm();
            }



        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 401) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.IncorrectUsernamePassword, 8000, 'rounded deep-orange darken-4');
                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {

                } else {
                    // Something happened in setting up the request that triggered an Error

                }

                this.setState({ preloader: null });
                document.getElementById("create_password_configuration_button").removeAttribute("disabled");

            });

    }




    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Security"} active_menu_level_2={"Password Configuration"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.AddPasswordConfiguration}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.PasswordConfiguration, this.Language.BreadCrumbLabel.AddPasswordConfiguration]} links={[`/users`, `/security/password/configuration`, `/security/password/configuration/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.AddANewPasswordConfiguration}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_password_configuration_form" ref="add_password_configuration_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="password" name="password" type="password" ref="password" />
                                                            <label htmlFor="password" className="">
                                                                {this.Language.DataField.EnterYourPassword} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="limit_pasword_length" ref="limit_pasword_length" name="limit_pasword_length" />
                                                                <label htmlFor="limit_pasword_length">{this.Language.DataField.LimitPasswordLength}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="min_password_length" name="min_password_length" type="number" className="validate" data-length={this.state.text_limit} ref="min_password_length" />
                                                            <label htmlFor="min_password_length" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.MinimumPasswordLength} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="max_password_length" name="max_password_length" type="number" className="validate" data-length={this.state.text_limit} ref="max_password_length" />
                                                            <label htmlFor="max_password_length" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.MaximumPasswordLength} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_alphanumeric" ref="allow_alphanumeric" name="allow_alphanumeric" />
                                                                <label htmlFor="allow_alphanumeric">{this.Language.DataField.AllowAlphanumericCharacters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_capital" ref="allow_capital" name="allow_capital" />
                                                                <label htmlFor="allow_capital">{this.Language.DataField.AllowUpperCaseLetters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_special_char" ref="allow_special_char" name="allow_special_char" />
                                                                <label htmlFor="allow_special_char">{this.Language.DataField.AllowSpecialCharacters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_user_id" ref="allow_user_id" name="allow_user_id" />
                                                                <label htmlFor="allow_user_id">{this.Language.DataField.AllowUsernameInPassword}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="must_contain_lower_case" ref="must_contain_lower_case" name="must_contain_lower_case" />
                                                                <label htmlFor="must_contain_lower_case">{this.Language.DataField.MustContainLowerCaseLetters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="must_contain_upper_case" ref="must_contain_upper_case" name="must_contain_upper_case" />
                                                                <label htmlFor="must_contain_upper_case">{this.Language.DataField.MustContainUpperCaseLetters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="must_contain_special_character" ref="must_contain_special_character" name="must_contain_special_character" />
                                                                <label htmlFor="must_contain_special_character">{this.Language.DataField.MustContainSpecialCharacters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_consectutive_character" ref="allow_consectutive_character" name="allow_consectutive_character" />
                                                                <label htmlFor="allow_consectutive_character">{this.Language.DataField.AllowConsecutiveCharacters}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="allow_password_reuse" ref="allow_password_reuse" name="allow_password_reuse" />
                                                                <label htmlFor="allow_password_reuse">{this.Language.DataField.AllowPasswordReuse}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s8">
                                                            <input id="allow_password_reuse_after" name="allow_password_reuse_after" type="number" className="validate" data-length={this.state.text_limit} ref="allow_password_reuse_after" />
                                                            <label htmlFor="allow_password_reuse_after" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.AllowPasswordReuseAfer} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="check_password_history" ref="check_password_history" name="check_password_history" />
                                                                <label htmlFor="check_password_history">{this.Language.DataField.CheckPasswordHistory}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="password_history_count" name="password_history_count" type="number" className="validate" data-length={this.state.text_limit} ref="password_history_count" />
                                                            <label htmlFor="password_history_count" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.PasswordHistoryCount} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="password_expiry_days" name="password_expiry_days" type="number" className="validate" data-length={this.state.text_limit} ref="password_expiry_days" />
                                                            <label htmlFor="password_expiry_days" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.PasswordExpiryDays} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivatePasswordConfiguration}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivatePasswordConfiguration}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveNewPasswordConfiguration.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_password_configuration_button">
                                                                {this.Language.ButtonLabel.SaveNewPasswordConfiguration}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveNewPasswordConfiguration.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/security/password/configuration`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddPasswordConfiguration;