import React, { Component } from 'react';
import {Link} from 'react-router-dom';


class CircularButtonItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            accessScope: props.accessScope,
            hash_user: props.hash_user,
            hash_exporter: props.hash_exporter,
            language: props.language,
        }
    }



    render(){

        switch(this.state.accessScope) {
            case 'create:users':
            case 'action:users':
                    return(
                        <li><Link to={'/users/add'} className="btn-floating tooltipped yellow darken-1" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.AddANewUser}><i className="material-icons">account_circle</i></Link></li>
                    )
                    // break;
            
            case 'create:exporters':
            case 'action:exporters':
                    return(
                        <li><Link to={'/exporters/add'} className="btn-floating tooltipped green" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.AddANewExporter}><i className="material-icons">account_balance</i></Link></li>
                    )
                    // break;

            case 'create:baselines':
            case 'action:baselines':
                    return(
                        <li><Link to={`/exporter/baseline/data/add`} className="btn-floating tooltipped teal" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.AddANewBaselineDataForAnExporter}><i className="material-icons">business_center</i></Link></li>
                    )
                    // break;

            case 'create:baseline':
            case 'action:baseline':
                    return(
                        <li><Link to={`/exporter/${this.state.hash_exporter}/baseline/profile/add`} className="btn-floating tooltipped blue" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.AddANewBaselineData}><i className="material-icons">business_center</i></Link></li>
                    )
                    // break;

            case 'create:claims':
            case 'action:claims':
                    return(
                        <li><Link to={`/exporters/eeg/claims/add`} className="btn-floating tooltipped green" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.AddANewNXPClaimForAnExporter}><i className="material-icons">language</i></Link></li>
                    )
                    // break;

            case 'create:claim':
            case 'action:claim':
                    return(
                        <li><Link to={`/exporter/${this.state.hash_exporter}/eeg/claim/applications/add`} className="btn-floating tooltipped blue" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.AddANewNXPClaim}><i className="material-icons">language</i></Link></li>
                    )
                    // break;

            default:
                    return(
                        null
                    )
        }
    }
}

export default CircularButtonItem;