import React, {Component} from 'react';


class DataCardCirclePicture extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: props.title || '',
            subTitle: props.subTitle || '',
            textColour: props.textColour || 'white',
            cardColour: props.cardColour || 'gradient-45deg-light-blue-cyan',
            srcImage: props.srcImage || '',
            srcImageAlt: props.srcImageAlt || '',
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '3',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '3',
        }

    }

    componentDidMount(){ 

        if (this.state.cardColour === 'blue' || this.state.cardColour === 'cyan') this.setState({cardColour: 'gradient-45deg-light-blue-cyan'});
        if (this.state.cardColour === 'purple' || this.state.cardColour === 'orange') this.setState({cardColour: 'gradient-45deg-purple-deep-orange'});
        if (this.state.cardColour === 'green' || this.state.cardColour === 'teal') this.setState({cardColour: 'gradient-45deg-green-teal'});
        if (this.state.cardColour === 'red' || this.state.cardColour === 'pink') this.setState({cardColour: 'gradient-45deg-red-pink'});
        if (this.state.cardColour === 'amber') this.setState({cardColour: 'gradient-45deg-amber-amber'});


    }

    render(){

        
        return (
            // <!--Data Chart start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div className={`card gradient-shadow ${this.state.cardColour} border-radius-3`}>
                    <div className="card-content center">
                    <img src={this.state.srcImage} alt={this.state.srcImageAlt} className="width-40 border-round z-depth-5"/>
                    <h5 className={`${this.state.textColour}-text lighten-4`}>{this.state.title}</h5>
                    <p className={`${this.state.textColour}-text lighten-4`}>{this.state.subTitle}</p>
                    </div>
                </div>
            </div>
            // <!--Data Chart end-->
        )
    }
}

export default DataCardCirclePicture;