import React, {Component} from 'react';


class DataChartAnalytics extends Component{
    constructor(props){
        super(props);
        this.state = {
            chartID1: props.chartID1 || 'chartID1',
            chartID2: props.chartID2 || 'chartID2',
            chartID3: props.chartID3 || 'chartID3',
            title: props.title || '',
            mainValue: props.mainValue || 0,
            mainValuePrefix: props.mainValuePrefix || '',
            mainValueSuffix: props.mainValueSuffix || '',
            mainValue2: props.mainValue2 || 0,
            mainValuePrefix2: props.mainValuePrefix2 || '',
            mainValueSuffix2: props.mainValueSuffix2 || '',
            subValue: props.subValue || 0,
            subValuePrefix: props.subValuePrefix || '',
            subValueSuffix: props.subValueSuffix || '',
            chartText2: props.chartText2 || '',
            cardColour: props.cardColour || 'cyan',
            textColour: props.textColour || 'white',
            filterColour: props.filterColour || 'red',
            showArrowIcon: props.showArrowIcon || true,
            arrowIconDirection: props.arrowIconDirection || 'up',
            Option1Text: props.Option1Text || '',
            Option2Text: props.Option2Text || '',
            chartElement1:  props.chartElement1 || null,
            chartElement2:  props.chartElement2 || null,
            chartElement3:  props.chartElement3 || null,
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '8',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '8',
            cardRevealTitle: props.cardRevealTitle || '',
            dataTableHeader: props.dataTableHeader || [],
            dataTableRows: props.dataTableRows || [[]]
        }

    }


    render(){



        
        return (
            // <!--Data Chart Analytics start-->
                <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                  <div className="card">
                    <div className="card-move-up waves-effect waves-block waves-light">
                      <div className={`move-up ${this.state.cardColour} darken-1`}>
                        <div>
                          <span className={`chart-title ${this.state.textColour}-text`}>{this.state.title}</span>
                          <div className={`chart-revenue ${this.state.cardColour} darken-2 ${this.state.textColour}-text`}>
                            <p className="chart-revenue-total">{this.state.mainValuePrefix} {this.state.mainValue}{this.state.mainValueSuffix}</p>
                            <p className="chart-revenue-per">
{(this.state.showArrowIcon === true)? ((this.state.arrowIconDirection === "up")? <i className={`material-icons green-text`}>arrow_drop_up</i>:<i className={`material-icons red-text`}>arrow_drop_down</i>) : null } 
                             {this.state.subValuePrefix} {this.state.subValue}{this.state.subValueSuffix}</p>
                          </div>
                          <div className="switch chart-revenue-switch right">
                            <label className={`${this.state.cardColour}-text text-lighten-5`}>
                            {this.state.Option1Text}
                              <input type="checkbox"/>
                              <span className="lever"></span> {this.state.Option2Text}
                            </label>
                          </div>
                        </div>
                        <div className={this.state.chartID1}>
                          <div className="trending-line-chart-wrapper">
                            {this.state.chartElement1}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-content">
                      <a href={"#!"} className={`btn-floating btn-move-up waves-effect waves-light ${this.state.filterColour} accent-2 z-depth-4 right`}>
                        <i className="material-icons activator">filter_list</i>
                      </a>
                      <div className="col s12 m5 l6 left">
                        <div id={this.state.chartID2}>
                          <div id="doughnut-chart-wrapper">
                            {this.state.chartElement2}
                          </div>
                        </div>
                      </div>
                      <div className="col s12 m5 l6 right">
                        <div className="trending-bar-chart-wrapper">
                          <div className={this.state.chartID3}>
                            {this.state.chartElement3}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-reveal">
                      <span className="card-title grey-text text-darken-4">{this.state.cardRevealTitle}
                        <i className="material-icons right">close</i>
                      </span>
                      <table className="responsive-table">
                        <thead>
                          <tr>
                          {this.state.dataTableHeader.map((header,  i) => {

                            return (
                              <th key={'th' + i} data-field={header}>{header}</th>
                            )
                            
                          })}
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.dataTableRows.map((rowitems,  i) => {
                            
                            return (
                              <tr key={'tr' + i}>
                                {rowitems.map((rowitem, j) => {
                                  return (
                                    <td key={'td' + i + j}>{rowitem}</td>
                                  )
                                })}
                              </tr>
                            )

                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            // <!--Data Chart Analytics end-->
        )
    }
}

export default DataChartAnalytics;