import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');


class AddAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addAnnouncement = this.addAnnouncement.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_announcement_type: [],
            select_field_values_announcement_category: [],
            select_field_values_announcement_status: [],
            select_field_values_publish_status: [],
            select_field_values_published_to: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.ComposeAnnouncement + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

        window.$('select').material_select();

    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['create:announcements', 'action:announcements'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("announcement_type,announcement_category,announcement_status,publish_status,published_to");
        };

    }



    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }



    prepareData(return_option) {


        let announcement_code = this.refs.announcement_code.value.trim();
        let announcement_title = this.refs.announcement_title.value;
        let announcement = this.refs.announcement.value;

        let announcement_type = this.refs.announcement_type.value;
        if (announcement_type === "Select Announcement Type") announcement_type = "";

        let announcement_category = this.refs.announcement_category.value;
        if (announcement_category === "Select Announcement Category") announcement_category = "";

        let announcement_status = this.refs.announcement_status.value;
        if (announcement_status === "Select Announcement Status") announcement_status = "";

        let publish_status = this.refs.publish_status.value;
        if (publish_status === "Select Publish Status") publish_status = "";

        let published_to = this.refs.published_to.value;
        if (published_to === "Select Publish To Option") published_to = "";


        let requires_approval = true; // force the require approval flag to true
        let is_approved = this.refs.is_approved.checked;

        let approved_by = null;
        if (is_approved) approved_by = this.state.logged_in_username;

        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;




        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.Announcement.AddErrorTitle, [announcement_code, announcement_title, announcement, announcement_type, announcement_category, announcement_status, publish_status, published_to], [this.Language.DataField.AnnouncementCode, this.Language.DataField.AnnouncementTitle, this.Language.DataField.Announcement, this.Language.DataField.AnnouncementType, this.Language.DataField.AnnouncementCategory, this.Language.DataField.AnnouncementStatus, this.Language.DataField.PublishStatus, this.Language.DataField.PublishedTo], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

            if (this.state.error_messages === null) {


                if (announcement_type === "Select Announcement Type") announcement_type = null;
                if (announcement_category === "Select Announcement Category") announcement_category = null;
                if (announcement_status === "Select Announcement Status") announcement_status = null;
                if (publish_status === "Select Publish Status") publish_status = null;
                if (published_to === "Select Publish To Option") published_to = null;


                const userOS = this.Browser.getOS();


                //make call to database 
                let newAnnouncement = {
                    announcement_code: announcement_code,
                    announcement_title: announcement_title,
                    announcement: announcement,
                    announcement_type: announcement_type,
                    announcement_category: announcement_category,
                    announcement_status: announcement_status,
                    publish_status: publish_status,
                    published_to: published_to,
                    requires_approval: requires_approval,
                    is_approved: is_approved,
                    approved_by: approved_by,
                    status: status,
                    created_by: created_by,
                    last_updated_by: created_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }



                this.setState({ preloader: true });
                document.getElementById("create_announcement_button").setAttribute("disabled", true);

                this.addAnnouncement(newAnnouncement, return_option);

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }


    clearForm() {

        document.getElementById("add_announcement_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_announcement_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'announcement_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_announcement_type: select_field_values_announcement_type });



                        const select_field_values_announcement_category = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'announcement_category') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_announcement_category: select_field_values_announcement_category });



                        const select_field_values_announcement_status = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'announcement_status') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_announcement_status: select_field_values_announcement_status });



                        const select_field_values_publish_status = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'publish_status') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_publish_status: select_field_values_publish_status });



                        const select_field_values_published_to = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'published_to') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_published_to: select_field_values_published_to });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }



    addAnnouncement(newAnnouncement, return_option) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/user/announcements`,
            data: newAnnouncement
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.Announcement.AddSuccess, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("create_announcement_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push(`/announcements/all`);
            } else {
                this.clearForm();
            }

        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.Announcement.Error, 8000, 'rounded deep-orange darken-4');
                    }

                    //   this.props.history.push('/announcements');


                } else if (error.request) {

                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("create_announcement_button").removeAttribute("disabled");

            });

    }






    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->

                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Notifications"} active_menu_level_2={"Announcements"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.ComposeAnnouncement}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.Announcements]} links={[`/`, `/announcements/all`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.ComposeAnnouncement}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_announcement_form" ref="add_announcement_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <i className="material-icons prefix">announcement</i>
                                                            <input id="announcement_title" name="announcement_title" type="text" ref="announcement_title" />
                                                            <label htmlFor="announcement_title" className="">
                                                                {this.Language.DataField.AnnouncementTitle} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="announcement_code" name="announcement_code" type="text" ref="announcement_code" />
                                                            <label htmlFor="announcement_code" className="">
                                                                {this.Language.DataField.AnnouncementCode} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="announcement" className="materialize-textarea" ref="announcement"></textarea>
                                                            <label htmlFor="announcement">
                                                                {this.Language.DataField.Announcement}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select className="initialized" name="announcement_type" id="announcement_type" ref="announcement_type">
                                                                    <option value="Select Announcement Type" >{this.Language.DataFieldDefault.SelectAnnouncementType}</option>
                                                                    {this.state.select_field_values_announcement_type.map((announcement_type_value, i) => {
                                                                        return (
                                                                            announcement_type_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.AnnouncementType} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select className="initialized" name="announcement_category" id="announcement_category" ref="announcement_category">
                                                                    <option value="Select Announcement Category" >{this.Language.DataFieldDefault.SelectAnnouncementCategory}</option>
                                                                    {this.state.select_field_values_announcement_category.map((announcement_category_value, i) => {
                                                                        return (
                                                                            announcement_category_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.AnnouncementCategory} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select className="initialized" name="announcement_status" id="announcement_status" ref="announcement_status">
                                                                    <option value="Select Announcement Status" >{this.Language.DataFieldDefault.SelectAnnouncementStatus}</option>
                                                                    {this.state.select_field_values_announcement_status.map((announcement_status_value, i) => {
                                                                        return (
                                                                            announcement_status_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.AnnouncementStatus} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select className="initialized" name="publish_status" id="publish_status" ref="publish_status">
                                                                    <option value="Select Publish Status" >{this.Language.DataFieldDefault.SelectPublishStatus}</option>
                                                                    {this.state.select_field_values_publish_status.map((publish_status_value, i) => {
                                                                        return (
                                                                            publish_status_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.PublishStatus} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select className="initialized" name="published_to" id="published_to" ref="published_to">
                                                                    <option value="Select Publish To Option" >{this.Language.DataFieldDefault.SelectPublishToOption}</option>
                                                                    {this.state.select_field_values_published_to.map((published_to_value, i) => {
                                                                        return (
                                                                            published_to_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.PublishedTo} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="is_approved" ref="is_approved" />
                                                                <label htmlFor="is_approved">{this.Language.DataField.IsApproved}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s1">
                                                            {/* this div is used to give a space between the elements */}
                                                        </div>
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateAnnouncement}
                                                                    <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateAnnouncement}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.Save.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_announcement_button">
                                                                {this.Language.ButtonLabel.Save}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.Save.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/announcements/all`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddAnnouncement;