import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

import ReactDOM from 'react-dom';

const $ = require('jquery');



class EditExchangeRate extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateExchangeRate = this.updateExchangeRate.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getExchangeRate = this.getExchangeRate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            exchange_rate_name: '',
            base_currency_id: '',
            exchange_rate: '',
            target_currency_id: '',
            exchange_rate_date: '',
            valid_from_date: '',
            valid_to_date: '',
            is_default_exchange_rate: false,
            hash_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_currency: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EditExchangeRate + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();
        this.getExchangeRate();

        const monthsFull = this.Language.DatePicker.monthsFull.split(",");
        const monthsShort = this.Language.DatePicker.monthsShort.split(",");
        const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
        const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
        const today = this.Language.DatePicker.today;
        const clear = this.Language.DatePicker.clear;
        const close = this.Language.DatePicker.close;
        const labelMonthNext = this.Language.DatePicker.labelMonthNext;
        const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
        const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
        const labelYearSelect = this.Language.DatePicker.labelYearSelect;


        window.$('.datepicker').pickadate({
            monthsFull: monthsFull,
            monthsShort: monthsShort,
            weekdaysFull: weekdaysFull,
            weekdaysShort: weekdaysShort,
            today: today,
            clear: clear,
            close: close,
            labelMonthNext: labelMonthNext,
            labelMonthPrev: labelMonthPrev,
            labelMonthSelect: labelMonthSelect,
            labelYearSelect: labelYearSelect
            // firstDay: 1,
            // format: 'dd mmmm yyyy',
            // formatSubmit: 'yyyy/mm/dd',
            // selectMonths: true,
            // selectYears: true,
            // selectMonths: true, // Creates a dropdown to control month
            // selectYears: 15, // Creates a dropdown of 15 years to control year,
            // closeOnSelect: false, // Close upon selecting a date,
            // container: undefined, // ex. 'body' will append picker to body
        });

        window.$(ReactDOM.findDOMNode(this.refs.base_currency_id)).on('change', this.changeCurrency.bind(this));
        window.$(ReactDOM.findDOMNode(this.refs.target_currency_id)).on('change', this.changeCurrency.bind(this));

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:exchange_rates', 'action:exchange_rates'], 'view') === false)) {
            this.props.history.replace('/tools/exchange/rates');
        } else {
            this.getDropdownValues("currency");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getExchangeRate() {

        let exchangeRateId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/tools/exchange/rates/${exchangeRateId}?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    exchange_rate_name: response.data.data[0].exchange_rate_name,
                    base_currency_id: response.data.data[0].base_currency_id,
                    exchange_rate: response.data.data[0].exchange_rate,
                    target_currency_id: response.data.data[0].target_currency_id,
                    exchange_rate_date: this.DateService.convertToCalendarFullDate(response.data.data[0].exchange_rate_date),
                    valid_from_date: this.DateService.convertToCalendarFullDate(response.data.data[0].valid_from_date),
                    valid_to_date: this.DateService.convertToCalendarFullDate(response.data.data[0].valid_to_date),
                    is_default_exchange_rate: (response.data.data[0].is_default_exchange_rate === true || response.data.data[0].is_default_exchange_rate === 'true') ? true : false || false,
                    hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    changeCurrency(event) {

        event.preventDefault();

        let base_currency_id = this.refs.base_currency_id.value;
        if (base_currency_id === "Select Base Currency") base_currency_id = "";

        let target_currency_id = this.refs.target_currency_id.value;
        if (target_currency_id === "Select Target Currency") target_currency_id = "";

        this.setState({ exchange_rate_name: base_currency_id + '/' + target_currency_id });

    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


        if (name === 'is_default_exchange_rate') { this.setState({ [name]: !this.state.is_default_exchange_rate }); }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveExchangeRate} - ${this.refs.base_currency_id.value}/${this.refs.target_currency_id.value}, ${this.refs.exchange_rate_date.value}`)) {


            let base_currency_id = this.refs.base_currency_id.value;
            if (base_currency_id === "Select Base Currency") base_currency_id = "";

            let target_currency_id = this.refs.target_currency_id.value;
            if (target_currency_id === "Select Target Currency") target_currency_id = "";

            let exchange_rate_name = base_currency_id + '/' + target_currency_id; // Exchange Rate Pair name


            let exchange_rate = this.refs.exchange_rate.value;

            let exchange_rate_date = this.DateService.convertToFullDate(this.refs.exchange_rate_date.value);
            let valid_from_date = this.DateService.convertToFullDate(this.refs.valid_from_date.value);
            let valid_to_date = this.DateService.convertToFullDate(this.refs.valid_to_date.value);

            let is_default_exchange_rate = this.refs.is_default_exchange_rate.checked;


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";


            let last_updated_by = this.state.logged_in_username;


            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ExchangeRates.AddErrorTitle, [exchange_rate_name, base_currency_id, target_currency_id, exchange_rate, exchange_rate, exchange_rate_date, valid_from_date, valid_to_date], [this.Language.DataField.ExchangeRateName, this.Language.DataField.BaseCurrency, this.Language.DataField.TargetCurrency, this.Language.DataField.ExchangeRate, this.Language.DataField.ExchangeRate, this.Language.DataField.ExchangeRateDate, this.Language.DataField.ValidFromDate, this.Language.DataField.ValidToDate], ["isRequired", "isRequired", "isRequired", "isRequired", "isNumber", "isRequiredDate", "isRequiredDate", "isRequiredDate"], 0, 0) }, () => {

                if (this.state.error_messages === null) {

                    if (base_currency_id === "Select Base Currency") base_currency_id = null;
                    if (target_currency_id === "Select Target Currency") target_currency_id = null;


                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newExchangeRate = {
                        exchange_rate_name: exchange_rate_name,
                        base_currency_id: base_currency_id,
                        exchange_rate: Math.abs(exchange_rate) || null,
                        target_currency_id: target_currency_id,
                        exchange_rate_date: exchange_rate_date,
                        valid_from_date: valid_from_date,
                        valid_to_date: valid_to_date,
                        is_default_exchange_rate: is_default_exchange_rate,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_exchange_rate_button").setAttribute("disabled", true);

                    this.updateExchangeRate(newExchangeRate);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_currency = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'currency') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_currency: select_field_values_currency });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    updateExchangeRate(newExchangeRate) {

        let exchangeRateId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/tools/exchange/rates/${exchangeRateId}`,
            data: newExchangeRate
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.UpdateSuccess + newExchangeRate.exchange_rate_name + ', ' + newExchangeRate.exchange_rate_date, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_exchange_rate_button").removeAttribute("disabled");

            this.props.history.push('/tools/exchange/rates');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                 

                this.setState({ preloader: null });
                document.getElementById("update_exchange_rate_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_exchange_rate_button").setAttribute("disabled", true);


        let exchange_rate_name = this.state.exchange_rate_name;
        let exchange_rate_date = this.DateService.convertToFullDate(this.state.exchange_rate_date);
        let logged_in_username = this.state.logged_in_username;
        let exchangeRateId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteExchangeRate = {
            exchange_rate_name: exchange_rate_name,
            exchange_rate_date: exchange_rate_date,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/tools/exchange/rates/${exchangeRateId}`,
            data: deleteExchangeRate
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.DeleteSuccess + deleteExchangeRate.exchange_rate_name + ', ' + deleteExchangeRate.exchange_rate_date, 8000, 'rounded green');
            })


            document.getElementById("delete_exchange_rate_button").removeAttribute("disabled");
            this.props.history.replace('/tools/exchange/rates');

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_exchange_rate_button").removeAttribute("disabled");

            });

    }



    // continue from here

    render() {


        // let exchangeRateId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Tools"} active_menu_level_2={"Exchange Rates"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditExchangeRate}`} breadcrumbs={[this.Language.BreadCrumbLabel.Tools, this.Language.BreadCrumbLabel.ExchangeRates, this.Language.BreadCrumbLabel.EditExchangeRate]} links={[`/`, `/tools/exchange/rates`, `/tools/exchange/rates/edit`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditExchangeRate} - <b>{this.state.exchange_rate_name}</b></h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-base_currency_id" className="initialized" name="base_currency_id" id="base_currency_id" ref="base_currency_id">
                                                                    {this.state.base_currency_id && <option value={this.state.base_currency_id} selected>{this.state.base_currency_id}</option>}
                                                                    <option value="Select Base Currency" >{this.Language.DataFieldDefault.SelectBaseCurrency}</option>
                                                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                                                        return (
                                                                            currency_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.BaseCurrency} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-target_currency_id" className="initialized" name="target_currency_id" id="target_currency_id" ref="target_currency_id">
                                                                    {this.state.target_currency_id && <option value={this.state.target_currency_id} selected>{this.state.target_currency_id}</option>}
                                                                    <option value="Select Target Currency" >{this.Language.DataFieldDefault.SelectTargetCurrency}</option>
                                                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                                                        return (
                                                                            currency_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.TargetCurrency} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <input id="exchange_rate" type="number" step="any" className="validate" ref="exchange_rate" value={this.state.exchange_rate} onChange={this.handleInputChange} />
                                                            <label htmlFor="exchange_rate" data-error={this.Language.DataFieldError.DecimalNumber} className={((this.state.exchange_rate && this.state.exchange_rate !== null && typeof this.state.exchange_rate !== 'undefined') || this.state.exchange_rate === 0) ? "active" : ""}>
                                                                {this.Language.DataField.ExchangeRate} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input type="text" className="datepicker picker__input" ref="exchange_rate_date" readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" value={this.state.exchange_rate_date} />
                                                            <label htmlFor="exchange_rate_date" className={(this.state.exchange_rate_date && this.state.exchange_rate_date !== null && typeof this.state.exchange_rate_date !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ExchangeRateDate} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input type="text" className="datepicker picker__input" ref="valid_from_date" readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" value={this.state.valid_from_date} />
                                                            <label htmlFor="valid_from_date" className={(this.state.valid_from_date && this.state.valid_from_date !== null && typeof this.state.valid_from_date !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ValidFromDate} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input type="text" className="datepicker picker__input" ref="valid_to_date" readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" value={this.state.valid_to_date} />
                                                            <label htmlFor="valid_to_date" className={(this.state.valid_to_date && this.state.valid_to_date !== null && typeof this.state.valid_to_date !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ValidToDate} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <p>
                                                                <input type="checkbox" id="is_default_exchange_rate" ref="is_default_exchange_rate" checked={this.state.is_default_exchange_rate} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="is_default_exchange_rate">{this.Language.DataField.IsDefaultExchangeRate}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateExchangeRate}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateExchangeRate}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_exchange_rate_button">
                                                                {this.Language.ButtonLabel.UpdateExchangeRate}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s4">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/tools/exchange/rates`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_exchange_rate_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteExchangeRate} ${this.state.exchange_rate_name}, ${this.state.exchange_rate_date}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditExchangeRate;