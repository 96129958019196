import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class AddLoginConfiguration extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addLoginConfiguration = this.addLoginConfiguration.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            text_limit: 12,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.AddLoginConfiguration + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }



    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['create:login_config', 'action:login_config'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.setState({ preloader: null });
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }



    prepareData(return_option) {


        let check_ip_address = this.refs.check_ip_address.checked;

        let login_timeout_interval_seconds = this.refs.login_timeout_interval_seconds.value.replace(/\s/g, "");
        let max_failed_attempt = this.refs.max_failed_attempt.value.replace(/\s/g, "");

        let require_validation_on_new_device_use = this.refs.require_validation_on_new_device_use.checked;
        let allow_mutiple_login_per_device = this.refs.allow_mutiple_login_per_device.checked;
        let send_notification_on_new_login = this.refs.send_notification_on_new_login.checked;
        let send_notification_on_new_device_use = this.refs.send_notification_on_new_device_use.checked;
        let send_email_on_new_login = this.refs.send_email_on_new_login.checked;
        let send_email_on_new_device_use = this.refs.send_email_on_new_device_use.checked;
        let send_sms_on_new_login = this.refs.send_sms_on_new_login.checked;
        let send_sms_on_new_device_use = this.refs.send_sms_on_new_device_use.checked;
        let global_two_step_verification = this.refs.global_two_step_verification.checked;
        let show_login_message = this.refs.show_login_message.checked;

        let login_message = this.refs.login_message.value;
        let security_question1 = this.refs.security_question1.value.trim();
        let security_question2 = this.refs.security_question2.value.trim();
        let security_question3 = this.refs.security_question3.value.trim();

        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let username = this.state.logged_in_username;
        let created_by = this.state.logged_in_username;



        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.LoginConfiguration.AddErrorTitle, [login_timeout_interval_seconds, login_timeout_interval_seconds, login_timeout_interval_seconds, max_failed_attempt, max_failed_attempt, login_message, security_question1, security_question2, security_question3], [this.Language.DataField.TimeoutInterval, this.Language.DataField.TimeoutInterval, this.Language.DataField.TimeoutInterval, this.Language.DataField.MaximumNumberofAttempts, this.Language.DataField.MaximumNumberofAttempts, this.Language.DataField.LoginMessage, this.Language.DataField.SecurityQuestion1, this.Language.DataField.SecurityQuestion2, this.Language.DataField.SecurityQuestion3], ["isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "isRequired", "isRequired", "isRequired", "isRequired"], 0, this.state.text_limit) }, () => {

            if (this.state.error_messages === null) {


                const userOS = this.Browser.getOS();

                //make call to database 
                const newLoginConfiguration = {
                    username: username,
                    check_ip_address: check_ip_address,
                    login_timeout_interval_seconds: Math.abs(login_timeout_interval_seconds) || null,
                    max_failed_attempt: Math.abs(max_failed_attempt) || null,
                    require_validation_on_new_device_use: require_validation_on_new_device_use,
                    allow_mutiple_login_per_device: allow_mutiple_login_per_device,
                    send_notification_on_new_login: send_notification_on_new_login,
                    send_notification_on_new_device_use: send_notification_on_new_device_use,
                    send_email_on_new_login: send_email_on_new_login,
                    send_email_on_new_device_use: send_email_on_new_device_use,
                    send_sms_on_new_login: send_sms_on_new_login,
                    send_sms_on_new_device_use: send_sms_on_new_device_use,
                    global_two_step_verification: global_two_step_verification,
                    show_login_message: show_login_message,
                    login_message: login_message,
                    security_question1: security_question1,
                    security_question2: security_question2,
                    security_question3: security_question3,
                    status: status,
                    created_by: created_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }




                this.setState({ preloader: true });
                document.getElementById("create_login_configuration_button").setAttribute("disabled", true);

                this.addLoginConfiguration(newLoginConfiguration, return_option);

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }


    clearForm() {

        document.getElementById("add_login_configuration_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }




    addLoginConfiguration(newLoginConfiguration, return_option) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/authenticate/login/config/`,
            data: newLoginConfiguration
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.AddSuccess, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("create_login_configuration_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/security/login/configuration');
            } else {
                this.clearForm();
            }



        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SaveError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.LoginConfiguration.AlreadyExist, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("create_login_configuration_button").removeAttribute("disabled");

            });

    }




    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Security"} active_menu_level_2={"Login Configuration"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.AddLoginConfiguration}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.LoginConfiguration, this.Language.BreadCrumbLabel.AddLoginConfiguration]} links={[`/users`, `/security/login/configuration`, `/security/login/configuration/edit`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.AddaNewLoginConfiguration}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_login_configuration_form" ref="add_login_configuration_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="login_timeout_interval_seconds" name="login_timeout_interval_seconds" type="number" className="validate" data-length={this.state.text_limit} ref="login_timeout_interval_seconds" />
                                                            <label htmlFor="login_timeout_interval_seconds" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.InactivityTimeoutInterval} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="max_failed_attempt" name="max_failed_attempt" type="number" className="validate" data-length="2" ref="max_failed_attempt" />
                                                            <label htmlFor="max_failed_attempt" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + "2"} className="">
                                                                {this.Language.DataField.MaxNumberofFailedLoginAttempts} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="check_ip_address" ref="check_ip_address" name="check_ip_address" />
                                                                <label htmlFor="check_ip_address">{this.Language.DataField.CheckUserIPAddress}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="require_validation_on_new_device_use" ref="require_validation_on_new_device_use" name="require_validation_on_new_device_use" />
                                                                <label htmlFor="require_validation_on_new_device_use">{this.Language.DataField.RequireValidationforNewDevices}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="allow_mutiple_login_per_device" ref="allow_mutiple_login_per_device" name="allow_mutiple_login_per_device" />
                                                                <label htmlFor="allow_mutiple_login_per_device">{this.Language.DataField.AllowMultipleUserLoginsOnADevice}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="send_notification_on_new_login" ref="send_notification_on_new_login" name="send_notification_on_new_login" />
                                                                <label htmlFor="send_notification_on_new_login">{this.Language.DataField.SendNotificationOnNewLogin}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="send_notification_on_new_device_use" ref="send_notification_on_new_device_use" name="send_notification_on_new_device_use" />
                                                                <label htmlFor="send_notification_on_new_device_use">{this.Language.DataField.SendNotificationOnNewDeviceLogins}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_email_on_new_login" ref="send_email_on_new_login" name="send_email_on_new_login" />
                                                                <label htmlFor="send_email_on_new_login">{this.Language.DataField.SendEmailOnNewLogin}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_email_on_new_device_use" ref="send_email_on_new_device_use" name="send_email_on_new_device_use" />
                                                                <label htmlFor="send_email_on_new_device_use">{this.Language.DataField.SendEmailOnNewDeviceLogins}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_sms_on_new_login" ref="send_sms_on_new_login" name="send_sms_on_new_login" />
                                                                <label htmlFor="send_sms_on_new_login">{this.Language.DataField.SendSMSOnNewLogin}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="send_sms_on_new_device_use" ref="send_sms_on_new_device_use" name="send_sms_on_new_device_use" />
                                                                <label htmlFor="send_sms_on_new_device_use">{this.Language.DataField.SendSMSOnNewDeviceLogins}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="global_two_step_verification" ref="global_two_step_verification" name="global_two_step_verification" />
                                                                <label htmlFor="global_two_step_verification">{this.Language.DataField.RequireTwoStepAuthenticationForAllUsers}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">question_answer</i>
                                                            <input id="security_question1" name="security_question1" type="text" ref="security_question1" />
                                                            <label htmlFor="security_question1" className="">
                                                                {this.Language.DataField.SecurityQuestion1} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="security_question2" name="security_question2" type="text" ref="security_question2" />
                                                            <label htmlFor="security_question2" className="">
                                                                {this.Language.DataField.SecurityQuestion2} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="security_question3" name="security_question3" type="text" ref="security_question3" />
                                                            <label htmlFor="security_question3" className="">
                                                                {this.Language.DataField.SecurityQuestion3} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="show_login_message" ref="show_login_message" name="show_login_message" />
                                                                <label htmlFor="show_login_message">{this.Language.DataField.ShowLoginMessage}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s8">
                                                            <textarea id="login_message" className="materialize-textarea" data-length="300" name="login_message" ref="login_message" ></textarea>
                                                            <label htmlFor="login_message" data-error={this.Language.DataFieldError.MaxLength}>
                                                                {this.Language.DataField.LoginMessage}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateLoginConfiguration}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateLoginConfiguration}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveNewLoginConfiguration.length <= lengthCheck ? "input-field col s5" : "input-field col s8"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_login_configuration_button">
                                                                {this.Language.ButtonLabel.SaveNewLoginConfiguration}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveNewLoginConfiguration.length <= lengthCheck ? "input-field col s7" : "input-field col s4"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/security/login/configuration`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddLoginConfiguration;