import React, {Component} from 'react';


class DataCardPicture extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: props.title || '',
            subTitle: props.subTitle || '',
            srcImage: props.srcImage || '',
            srcImageAlt: props.srcImageAlt || '',
            paragraphText: props.paragraphText || '',
            buttonValue: props.buttonValue || '',
            buttonValuePrefix: props.buttonValuePrefix || '',
            buttonValueSuffix: props.buttonValueSuffix || '',
            buttonColour: props.buttonColour || 'gradient-45deg-light-blue-cyan',
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '4',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '4',
        }

    }

    componentDidMount(){ 

        if (this.state.buttonColour === 'blue' || this.state.buttonColour === 'cyan') this.setState({buttonColour: 'gradient-45deg-light-blue-cyan'});
        if (this.state.buttonColour === 'purple' || this.state.buttonColour === 'orange') this.setState({buttonColour: 'gradient-45deg-purple-deep-orange'});
        if (this.state.buttonColour === 'green' || this.state.buttonColour === 'teal') this.setState({buttonColour: 'gradient-45deg-green-teal'});
        if (this.state.buttonColour === 'red' || this.state.buttonColour === 'pink') this.setState({buttonColour: 'gradient-45deg-red-pink'});
        if (this.state.buttonColour === 'amber') this.setState({buttonColour: 'gradient-45deg-amber-amber'});

    }

    render(){

        
        return (
            // <!--Data Card Picture start-->
                <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                    <div className="card">
                        <div className="card-content center">
                            <h6 className="card-title font-weight-400 mb-0">{this.state.title}</h6>
                            <img src={this.state.srcImage} alt={this.state.srcImageAlt}/>
                            <p><b>{this.state.subTitle}</b></p>
                            <p>{this.state.paragraphText}</p>
                        </div>
                        <div className="card-action border-non center">
                            <a href={"#!"} className={`waves-effect waves-light btn ${this.state.buttonColour} box-shadow`}>{this.state.buttonValuePrefix} {this.state.buttonValue}{this.state.buttonValueSuffix}</a>
                        </div>
                    </div>
                </div>
            // <!--Data Card Picture end-->
        )
    }
}

export default DataCardPicture;