import React, {Component} from 'react';


class DataCardAnalytics extends Component{
    constructor(props){
        super(props);
        this.state = {
            chartID: props.chartID || 'chartID',
            textColour: props.textColour || 'white',
            cardColour: props.cardColour || 'cyan',
            iconName: props.iconName || 'language',
            title: props.title || '',
            mainValue: props.mainValue || 0,
            mainValuePrefix: props.mainValuePrefix || '',
            mainValueSuffix: props.mainValueSuffix || '',
            showArrowIcon: props.showArrowIcon || false,
            arrowIconDirection: props.arrowIconDirection || 'up',
            subValue: props.subValue || 0,
            subValuePrefix: props.subValuePrefix || '',
            subValueSuffix: props.subValueSuffix || '',
            subTitle: props.subTitle || '',
            chartElement:  props.chartElement || null,
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '6',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '3',
        }

    }


    render(){


        
        return (
            // <!--Data Card Analytics start-->
            <div className={`center col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div className="card">
                <div className={`card-content ${this.state.cardColour} ${this.state.textColour}-text`}>
                    <p className="card-stats-title">
                    <i className="material-icons">{this.state.iconName}</i> {this.state.title}</p>
                    <h4 className="card-stats-number">{this.state.mainValuePrefix} {this.state.mainValue}{this.state.mainValueSuffix}</h4>
                    <p className="card-stats-compare">
                    {(this.state.showArrowIcon === true)? ((this.state.arrowIconDirection === "up")? <i className={`material-icons green-text`}>keyboard_arrow_up</i>:<i className={`material-icons red-text`}>keyboard_arrow_down</i>) : null } {this.state.subValuePrefix} {this.state.subValue}{this.state.subValueSuffix}
                    <span className={`${this.state.cardColour} text text-lighten-5`}>{this.state.subTitle}</span>
                    </p>
                </div>
                <div className={`card-action ${this.state.cardColour} darken-1`}>
                    <div id={this.state.chartID} className="center-align">
                        {this.state.chartElement}
                    </div>
                </div>
                </div>
            </div>
            // <!--Data Card Analytics end-->
        )
    }
}

export default DataCardAnalytics;