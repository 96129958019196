import React, { Component } from 'react';
import DatatableRowItem from './DatatableRowItem';


class DatatableBodyRow extends Component{
    constructor(props){
        super(props);
        this.state = {
            tableBodyRowData: props.tableBodyRowData,
            rowDataTypes: props.rowDataTypes,
            rowDecimalPlaces: props.rowDecimalPlaces,
            dateFormat: props.dateFormat,
            timeFormat: props.timeFormat,
            hash_id: props.hash_id,
            keyPosition: props.keyPosition,
            link: props.link,
            current_path: props.current_path,
            return_query_prefix: props.return_query_prefix,
            link_status: props.link_status,
            linkToBlankTarget: props.linkToBlankTarget,
            linkToBlankTargetToken: props.linkToBlankTargetToken,
            language: props.language
        }
    }

    render(){
        const bodyRowItems = this.state.tableBodyRowData.map((dataitem,  i) => {

            if (i === 1){
                return(
                    <DatatableRowItem key={i} hash_id={this.state.hash_id} rowItem={dataitem} rowItemDataType={this.state.rowDataTypes[i]} rowItemDecimalPlace={this.state.rowDecimalPlaces[i]} dateFormat={this.state.dateFormat} timeFormat={this.state.timeFormat} rowClassName={true} link={this.state.link} return_query_prefix={this.state.return_query_prefix} current_path={this.state.current_path} link_status={this.state.link_status} linkToBlankTarget={this.state.linkToBlankTarget} linkToBlankTargetToken={this.state.linkToBlankTargetToken} language={this.state.language}/>
                )
            } else {
                return(
                    <DatatableRowItem key={i} hash_id={this.state.hash_id} rowItem={dataitem} rowItemDataType={this.state.rowDataTypes[i]} rowItemDecimalPlace={this.state.rowDecimalPlaces[i]} dateFormat={this.state.dateFormat} timeFormat={this.state.timeFormat} rowClassName={false} link={this.state.link} return_query_prefix={this.state.return_query_prefix} current_path={this.state.current_path} link_status={this.state.link_status} linkToBlankTarget={this.state.linkToBlankTarget} linkToBlankTargetToken={this.state.linkToBlankTargetToken} language={this.state.language}/>
                )
            }
            
        })


        if((this.state.keyPosition % 2) === 0){
            return (
                <tr role="row" className="even">
                    {bodyRowItems}
                </tr>
            )
        } else {
            return (
                <tr role="row" className="odd">
                    {bodyRowItems}
                </tr>
            )
        }
        
    }
}

export default DatatableBodyRow;