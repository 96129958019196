import React, { Component } from 'react';
import DashboardLayout from './DashboardLayout';
import DashboardLayoutDummy from './DashboardLayoutDummy';


class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            dashboardDisplayType: props.dashboardDisplayType || "dummy",
            dashboardLayout: props.dashboardLayout || "4:DataCard__1:ValuesChart_1:ValuesChartVertical__3:DataChart",
            Lang: props.Lang || 'en',
            DetailsLabel: props.DetailsLabel || 'Details',
            elements: props.elements || [],
        }
    }

    render(){

        if (this.state.dashboardDisplayType === "real") {

            return (
                // <!--Dashboard start-->
                <DashboardLayout dashboardLayout={this.state.dashboardLayout} Lang={this.state.Lang} elements={this.state.elements} DetailsLabel={this.state.DetailsLabel}/>
                // <!--Dashboard end-->
            )

        } else {

            return (
                // <!--Dummy Dashboard start-->
                <DashboardLayoutDummy dashboardLayout={this.state.dashboardLayout} Lang={this.state.Lang} elements={this.state.elements} DetailsLabel={this.state.DetailsLabel}/>
                // <!--Dummy Dashboard end-->
            )
        }
        
    }
}

export default Dashboard;