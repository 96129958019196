import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import ReactDOM from 'react-dom';


class Signin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            user: {
                username: '',
                password: ''
            },
            language: 'English',
            // languageShortcode: 'en',
            languages: ['English', 'Français', '中文'],
            status: '',
            auth: '',
            validation_error: '',
            token: null,
            refresh_token: null,
            change_password: false,
            failed_attempt_count: 0,
            max_failed_attempt: 5,
            failed_attempt_count_from_server_flag: false,
            login_message: 'Welcome to the EEG Portal',
            redirect: null,
            message: '',
            token_lock_user: null,
            preloader: null,
            breakline_element: null
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.signin = this.signin.bind(this);
        this.lockAccount = this.lockAccount.bind(this);
        this.Browser = new BrowserService();
        this.DateService = new DateService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
    }


    componentDidMount() {
      document.title = this.Language.ButtonLabel.SignIn + ' - ' + this.Language.EEGWebPortal;

        this.setState({
            language: this.Language.Language,
            languages: LanguageService.prototype.getLanguageList()
        });


        // let lang = this.LanguageService.getSessionLanguage();

        // switch (this.state.language) {
        //     case 'English':
        //         this.setState({languageShortcode: 'en'});
        //         break;

        //     case 'Français':
        //         this.setState({languageShortcode: 'fr'});
        //         break;

        //     case '中文':
        //         this.setState({languageShortcode: 'zh'});
        //         break;

        //     default:

        // }

        window.$('select').material_select();
        window.$(ReactDOM.findDOMNode(this.refs.language)).on('change', this.changeLanguage.bind(this));

    }


    changeLanguage(event) {

        event.preventDefault();

        let lang = this.refs.language.value;

        if (this.state.language !== lang) {
            this.setState({ language: lang });

            LanguageService.prototype.setTranslation(lang);
            window.location.reload();
        }

    }


    signin(newSignin) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/authenticate`,
            data: newSignin
        }).then(response => {
            this.setState({ status: response.data.status }, () => {
            })

            this.setState({ token: response.data.token }, () => {
                let payload = JSON.parse(atob(this.state.token.split('.')[1]));
                AuthService.authenticateUser(this.state.token); // Save the token in session storage
                let time_zone_region = payload.time_zone_region || 'UTC***(GMT+00:00) Coordinated Universal Time - UTC***UTC';
                this.DateService.setUserTimeZone(time_zone_region);
            })



            this.setState({ login_message: response.data.login_message }, () => {
                if (this.state.login_message !== null) {
                    window.Materialize.toast(this.state.login_message, 8000, 'rounded green');
                }
            })

            this.setState({ message: response.data.message }, () => {
                window.Materialize.toast(this.state.message, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            this.setState({ breakline_element: null });
            document.getElementById("signin_submit_button").removeAttribute("disabled");

            let redirect = response.data.redirect;


            if (redirect !== null) {
                this.props.history.replace(redirect);
            } else {
                this.props.history.replace('/');
            };


        })
            .catch(error => {
                if (error.response) {



                    window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');


                    if (error.response.data.failed_attempt === true) {

                        if (this.state.failed_attempt_count_from_server_flag === false) {

                            this.setState({ max_failed_attempt: error.response.data.max_failed_attempt });
                            this.setState({ token_lock_user: error.response.data.token });
                            this.setState({ failed_attempt_count_from_server_flag: true });
                        }

                        this.setState({ failed_attempt_count: this.state.failed_attempt_count + 1 });


                        let try_count = this.state.max_failed_attempt - this.state.failed_attempt_count;

                        if (try_count > 0) {

                            window.Materialize.toast(`${this.Language.ConfirmMessage.SigninAbouttoLockMessage} ${try_count}`, 8000, 'rounded deep-orange accent-3');

                        } else {

                            window.Materialize.toast(`${this.Language.ConfirmMessage.SigninLockMessage}`, 8000, 'rounded deep-orange accent-3');
                        }


                        if (this.state.failed_attempt_count >= this.state.max_failed_attempt) {
                            let username = this.refs.username.value;
                            let redirect = error.response.data.redirect;

                            const newLockUserAccount = {
                                username: username,
                                redirect: redirect,
                                token: this.state.token_lock_user,
                            }


                            this.lockAccount(newLockUserAccount, redirect);
                        };


                    } else {




                    }



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                 

                this.setState({ preloader: null });
                document.getElementById("signin_submit_button").removeAttribute("disabled");
                document.getElementById("username").removeAttribute("disabled");
                document.getElementById("password").removeAttribute("disabled");
                document.getElementById("forgot_password").hidden = false;
                this.setState({ breakline_element: null });

            });




    }




    onSubmit(event) {
        // prevent default action. in this case, action is the form submission event
        event.preventDefault();


        let username = this.refs.username.value + '';
        let password = this.refs.password.value + '';

        const userOS = this.Browser.getOS();

        if (username.length > 0 && password.length > 0) {
            const newSignin = {
                username: this.refs.username.value,
                password: this.refs.password.value,
                device_name: this.refs.username.value + '\'s ' + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: "web"
            }



            this.setState({ preloader: true });
            document.getElementById("signin_submit_button").setAttribute("disabled", true);
            document.getElementById("username").setAttribute("disabled", true);
            document.getElementById("password").setAttribute("disabled", true);
            document.getElementById("forgot_password").hidden = true;
            this.setState({ breakline_element: true });
            this.signin(newSignin);

        } else {

            window.Materialize.toast(`${this.Language.ConfirmMessage.SigninEnterMessage}`, 4000, 'rounded deep-orange darken-4');
        };

    }




    lockAccount(newLockUserAccount, redirect) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1${redirect}`,
            data: newLockUserAccount
        }).then(response => {

            this.setState({ status: response.data.status }, () => {

            })


            this.setState({ message: response.data.message }, () => {
                window.Materialize.toast(this.state.message, 8000, 'rounded deep-orange darken-4');
            })


        })
            .catch(error => {
                if (error.response) {


                    window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

            });

    }



    /**
     * Render the component.
     */
    render() {


        const divStyle = {
            position: 'relative',
            top: '50%',
            WebkitTransform: 'translateY(50%)',
            msTransform: 'translateY(50%)',
            transform: 'translateY(25%)'
        };


        const select_field_values_language = this.state.languages.map((language, i) => {
            if (language !== this.state.language) {
                return (
                    <option value={language} key={i}>{language}</option>
                )
            } else {
                return (
                    null
                )
            }
        })


        return (
          <div div="login-page" className="row">
            <div className="col m3 l4"></div>
            <div className="vertical-form sign-in col s12 m7 l4 z-depth-4 card-panel" style={divStyle}>
              {this.state.preloader && (
                <div className="progress teal lighten-4">
                  <div className="indeterminate teal"></div>
                </div>
              )}

              {/* <form className="login-form" onSubmit={this.onSubmit.bind(this)}> */}

              <form className="" onSubmit={this.onSubmit.bind(this)}>
                <div className="row">
                  <div className="input-field col s12 center">
                    <img src="/assets/images/logo/nepc_logo_small.png" alt="" className="responsive-img valign" />
                    <div className="card-content green-text darken-4">
                      <span className="card-title center">
                        <h4>{this.Language.PageLabel.EEGWebPortal}</h4>
                      </span>
                      <p className="center">{this.Language.PageLabel.SignIntoThePortal}</p>
                    </div>
                  </div>
                </div>

                <br />

                <div className="container">
                  <div className="field-line">
                    <div className="input-field col s12">
                      <input id="username" type="text" ref="username" />
                      <label htmlFor="username">{this.Language.DataField.Username}</label>
                    </div>
                  </div>

                  <div className="field-line">
                    <div className="input-field col s12">
                      <input id="password" type="password" ref="password" />
                      <label htmlFor="password">{this.Language.DataField.Password}</label>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row col s12">
                      <input type="submit" value={this.Language.ButtonLabel.SignIn} className="waves-effect waves-light col s12 btn right green" id="signin_submit_button" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s8">
                      <p id="forgot_password">
                        <Link to={'/password/forgot'}>{this.Language.DataField.ForgotPassword}</Link>
                      </p>
                    </div>
                    <div className="col s4">
                      <div className="select-wrapper initialized">
                        <span className="caret">▼</span>
                        <select data-select-id="select-options-language" className="initialized teal-text" ref="language">
                          <option value={this.state.language}>{this.state.language}</option>
                          {select_field_values_language}
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.breakline_element && <br />}
                  {this.state.breakline_element && <br />}
                </div>
              </form>
              <br />
              <br />

              <div className="row">
                <div className="center darken-4">
                  <span className="center">
                    <a className="grey-text" href="http://nepc.gov.ng/" target="_blank" rel="noopener noreferrer">
                      {this.Language.BaseOrganisation} {' | '}
                    </a>
                    <a className="green-text" href="http://nepc.gov.ng/" target="_blank" rel="noopener noreferrer">
                      {this.Language.PageLabel.Privacy}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col m2 l4"></div>
          </div>
        );
    }

}

export default Signin;