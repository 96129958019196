import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import Pagination from '../components/Pagination';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import axios from 'axios';


class Notifications extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
        this.selectNotification = this.selectNotification.bind(this);
        this.updateNotification = this.updateNotification.bind(this);
        this.deleteNotification = this.deleteNotification.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.state = {
            notifications: [],
            messageTitle: '',
            notification_label: '',
            senderName: '',
            receiverName: '',
            notification_date_formated: '',
            notificationMessage: '',
            notificationPosition: '',
            notification_hash_id: '',
            message_selected: null,
            defaultNumberOfPages: 1,
            defaultRecordsPerPage: 5,
            defaultActivePage: 1,
            PaginationElement: null,
            hash_user: '',
            username: '',
            name: '',
            email: '',
            result: '',
            message: '',
            token: '',
            preloader: true,
            NotiifcationDataItems: null,
            notification_create: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();

    }



    componentWillMount() {
        this.getProfile();

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.Notifications + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();
        this.getNotifications();


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:notification', 'action:notification', 'read:notifications', 'action:notifications'], 'view') === false || this.state.hash_user !== this.props.match.params.id) {
            this.props.history.replace(default_home);
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                username: user_profile.username,
                name: user_profile.name,
                email: user_profile.email,
                scopes: user_profile.scopes,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });
        }

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:notifications' || this.state.scopes['views'][i] === 'action:notifications') {
                    this.setState({ notification_create: true });
                    break;
                }
            }
        };

    }

    getNotifications() {

        let userId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/user/notifications/${userId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({ notifications: response.data.data }, () => {

                    this.setState({ preloader: null });

                    let leftChevronEnable = true;
                    let rightChevronEnable = true;
                    let numberOfPages = 0;
                    if (this.state.notifications) numberOfPages = Math.ceil(this.state.notifications.length / this.state.defaultRecordsPerPage);

                    if (this.state.defaultActivePage <= 1) leftChevronEnable = false;
                    if (this.state.defaultActivePage >= numberOfPages) rightChevronEnable = false;

                    this.setState({ PaginationElement: <Pagination numberOfPages={numberOfPages} activePage={this.state.defaultActivePage} leftChevronEnable={leftChevronEnable} rightChevronEnable={rightChevronEnable} handlePaginationChange={this.handlePaginationChange} /> });

                })

                this.setState({ result: response.data.status }, () => {
                })

                this.setState({ message: response.data.message }, () => {
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js

                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            });

    }


    selectNotification(position) {

        let temp_array_of_objects = this.state.notifications.concat();

        let len = temp_array_of_objects.length;


        for (let i = 0; i < len; i++) {

            if (i !== position) temp_array_of_objects[i].message_selected_status = '';

        }

        temp_array_of_objects[position].message_selected_status = 'selected';
        if (temp_array_of_objects[position].notification_status === 'New') {

            temp_array_of_objects[position].notification_status = 'Read';
            this.updateNotification('Read', temp_array_of_objects[position].hash_id, position);
        }

        this.setState({ notifications: temp_array_of_objects }, () => {
            this.setState({ messageTitle: this.state.notifications[position].message_title });
            this.setState({ notification_label: this.state.notifications[position].notification_label });
            this.setState({ senderName: this.state.notifications[position].sender_first_name + ' ' + this.state.notifications[position].sender_last_name });
            this.setState({ receiverName: this.state.notifications[position].receiver_first_name + ' ' + this.state.notifications[position].receiver_last_name });
            this.setState({ notification_date_formated: this.state.notifications[position].notification_date_formated });
            this.setState({ message_selected: true });
            this.setState({ notificationMessage: this.state.notifications[position].message });
            this.setState({ notification_hash_id: this.state.notifications[position].hash_id });
            this.setState({ notificationPosition: position });
        });

    }




    updateNotification(action, hash_id, position) {


        //update the state to flag the notification as unread
        if (action === 'New') {
            let temp_array_of_objects = this.state.notifications.concat();
            temp_array_of_objects[position].notification_status = 'New';
            this.setState({ notifications: temp_array_of_objects });
        }


        let logged_in_username = this.state.username;

        const userOS = this.Browser.getOS();

        const newNotificationAction = {
          read_action: action,
          last_updated_by: logged_in_username,
          device_name: logged_in_username + "'s " + userOS,
          device_model: userOS,
          device_os: userOS,
          browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
          location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
          app_source: 'web',
          token: this.Auth.getToken()
        };


        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/user/notifications/read/${hash_id}`,
            data: newNotificationAction
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
               
            })


        })
            .catch(error => {
                if (error.response) {

                    

                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                


            });
    }


    deleteNotification(hash_id, position) {

        if (window.confirm(`${this.Language.ConfirmMessage.DeleteNotification}`)) {

            //update the state to flag the notification as unread
            if (position && hash_id) {

                let temp_array_of_objects = this.state.notifications.concat();
                temp_array_of_objects = temp_array_of_objects.filter((value, index, array) => {
                    return index !== position;
                });


                this.setState({ notifications: temp_array_of_objects });



                let logged_in_username = this.state.username;

                const userOS = this.Browser.getOS();

                const newNotificationAction = {
                  last_updated_by: logged_in_username,
                  device_name: logged_in_username + "'s " + userOS,
                  device_model: userOS,
                  device_os: userOS,
                  browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                  location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                  app_source: 'web',
                  token: this.Auth.getToken()
                };


                axios.request({
                    method: 'delete',
                    url: `${this.apiURL}/api/v1/user/notifications/${hash_id}`,
                    data: newNotificationAction
                }).then(response => {

                    this.setState({ status: response.data.status })

                    this.setState({ message: response.data.message }, () => {
                        
                    })


                })
                    .catch(error => {
                        if (error.response) {

                            

                        } else if (error.request) {
                            
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            
                        }
                        


                    });

            }

        }

    }


    handlePaginationChange(activePage) {

        this.setState({ defaultActivePage: activePage }); // change the value of active page so that the new set of records are displayed


    }



    render() {

        let notifications = null;


        if (this.state.notifications) {

            notifications = this.state.notifications.map((notification, i) => {


                if (i >= (this.state.defaultRecordsPerPage * (this.state.defaultActivePage - 1)) && i <= (this.state.defaultRecordsPerPage * this.state.defaultActivePage) - 1) { // restrict the number of records to be displayed


                    let classValue = 'collection-item avatar ' + notification.message_selected_status;
                    let nameValue = 'email-title grey-text';
                    let textValue = 'grey-text ultra-small';
                    let circleStatus = 'circle grey darken-1';

                    if (notification.notification_status === 'New') {
                        classValue = classValue + ' email-unread';
                        textValue = 'blue-text ultra-small';
                        nameValue = 'email-title blue-text';
                        circleStatus = 'circle light-blue';
                    }

                    return (
                        <li className={classValue} onClick={() => { this.selectNotification(i) }} key={i}>
                            <span className={circleStatus}>{notification.sender_first_name.substring(0, 1)}</span>
                            <span className={nameValue}>{`${notification.sender_first_name} ${notification.sender_last_name}`}</span>
                            <p className="truncate grey-text ultra-small">{notification.message_title}</p>
                            <span className={(notification.notification_label === '') ? null : ((notification.notification_label === 'Inbox') ? "task-cat teal accent-4 right" : "task-cat orange accent-4 right")}>{(notification.notification_label === '') ? null : ((notification.notification_label === 'Inbox') ? this.Language.DataValue.Inbox : this.Language.DataValue.Outbox)}</span>
                            <div className="secondary-content email-time">
                                <span className={textValue}>{notification.notification_date_formated}</span>
                            </div>
                        </li>
                    )

                } else {

                    return (
                        null
                    )

                }

            })

        }




        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Notifications"} active_menu_level_2={"Notifications"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.Notifications}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.Notifications]} links={[`/`, `/notifications/${this.state.hash_user}`]} button_link={'/users/add'} button_icon={'person_add'} button_name={'Create Account'} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                {(this.state.notification_create) && <Link className="waves-effect waves-light btn right" to={`/notifications/compose`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.ComposeMessage}</Link>}
                                <br />
                                <br />

                                {/* <!--Mail App Section - Start--> */}
                                <div id="mail-app" className="section">
                                    <div className="row">
                                        <div className="col s12">
                                            <nav className="green">
                                                <div className="nav-wrapper">
                                                    <div className="left col s12 m5 l5">
                                                        <ul>
                                                            <li>
                                                                <i className="material-icons">email</i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col s12 m7 l7 hide-on-med-and-down">
                                                        {this.state.message_selected && <ul className="right">
                                                            <li onClick={() => { this.updateNotification('New', this.state.notification_hash_id, this.state.notificationPosition) }}>
                                                                <Link to={"#!"} className="tooltipped" data-position="left" data-delay="50" data-tooltip={this.Language.ToolTip.MarkAsUnread}>
                                                                    <i className="material-icons">markunread_mailbox</i>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={"#!"} className="tooltipped" data-position="left" data-delay="50" data-tooltip={this.Language.ToolTip.ComposeMessage}>
                                                                    <i className="material-icons">edit</i>
                                                                </Link>
                                                            </li>
                                                            <li onClick={() => { this.deleteNotification(this.state.notification_hash_id, this.state.notificationPosition) }}>
                                                                <Link to={"#!"} className="tooltipped" data-position="left" data-delay="50" data-tooltip={this.Language.ToolTip.DeleteMessage}>
                                                                    <i className="material-icons">delete</i>
                                                                </Link>
                                                            </li>
                                                        </ul>}
                                                    </div>
                                                </div>
                                            </nav>
                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}
                                        </div>
                                        <div className="col s12">
                                            <div id="email-list" className="col s12 m5 l5 card-panel z-depth-1">
                                                <ul className="collection">
                                                    {notifications}
                                                </ul>
                                                {this.state.PaginationElement}
                                                {/* <Pagination numberOfPages={Math.ceil(this.state.notifications.length / this.state.defaultRecordsPerPage)} activePage={this.state.defaultActivePage} leftChevronEnable={leftChevronEnable} rightChevronEnable={rightChevronEnable} handlePaginationChange={this.handlePaginationChange}/> */}
                                            </div>
                                            <div id="email-details" className="col s12 m7 l7 card-panel">
                                                <p className="email-subject truncate">{this.state.messageTitle}
                                                    <span className={(this.state.notification_label === '') ? null : ((this.state.notification_label === 'Inbox') ? "task-cat teal accent-4 right" : "task-cat orange accent-4 right")}>{(this.state.notification_label === '') ? null : ((this.state.notification_label === 'Inbox') ? this.Language.DataValue.Inbox : this.Language.DataValue.Outbox)}</span>
                                                </p>
                                                <hr className="grey-text text-lighten-2" />
                                                <div className="email-content-wrap">
                                                    <div className="row">
                                                        <div className="col s10 m10 l10">
                                                            <ul className="collection">
                                                                <li className="collection-item avatar">
                                                                    {this.state.message_selected && <img src="/images/user.png" alt="" className="circle" />}
                                                                    <span className="email-title">{this.state.senderName}</span>
                                                                    {this.state.message_selected && <p className="truncate grey-text ultra-small">{this.Language.DataValue.To} {this.state.receiverName}</p>}
                                                                    <p className="grey-text ultra-small">{this.state.notification_date_formated}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="email-content">
                                                       {this.state.notificationMessage.split("\n").map((message_value, i) => {
                                                            return <p key={i}>{message_value}</p>;
                                                        })}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="email-reply">
                                                    {this.state.message_selected && <div className="row">
                                                        <div className="col s4 m4 l4 center-align">
                                                            <a href="#!">
                                                                <i className="material-icons">reply</i>
                                                            </a>
                                                            <p className="ultra-small">{this.Language.DataValue.Reply}</p>
                                                        </div>

                                                        <div className="col s4 m4 l4 center-align">
                                                            <a href="#!">
                                                                <i className="material-icons">forward</i>
                                                            </a>
                                                            <p className="ultra-small">{this.Language.DataValue.Forward}</p>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Mail App Section - End--> */}

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default Notifications;