import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormatService from '../modules/FormatService';
import DateService from '../modules/DateService';


class DatatableRowItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowItem: props.rowItem,
            rowItemDataType: props.rowItemDataType,
            rowItemDecimalPlace: props.rowItemDecimalPlace,
            dateFormat: props.dateFormat,
            timeFormat: props.timeFormat,
            hash_id: props.hash_id,
            rowClassName: props.rowClassName,
            link: props.link,
            current_path: props.current_path,
            return_query_prefix: props.return_query_prefix,
            link_status: props.link_status,
            linkToBlankTarget: props.linkToBlankTarget || false,
            linkToBlankTargetToken: props.linkToBlankTargetToken || '',
            language: props.language,
        }
        this.Format = new FormatService();
        this.DateService = new DateService();

    }

    render() {

        let rowValue = this.state.rowItem;
        let rowValueDataType = this.state.rowItemDataType;
        let rowValueDecimalPlace = this.state.rowItemDecimalPlace;
        let dateFormat = this.state.dateFormat;
        let timeFormat = this.state.timeFormat;

        let tdStyle = null;

        //row value data types includes - 'String', 'Number', 'Boolean', 'Date', 'Date-Year', 'Symbol'

        if (rowValueDataType === 'Boolean' && (rowValue === true || rowValue === 'true')) rowValue = this.state.language.DataValue.Yes;
        if (rowValueDataType === 'Boolean' && (rowValue === false || rowValue === 'false')) rowValue = this.state.language.DataValue.No;

        if (rowValueDataType === 'Status' && rowValue === 'Active') rowValue = this.state.language.DataValue.Active;
        if (rowValueDataType === 'Status' && rowValue === 'Inactive') rowValue = this.state.language.DataValue.Inactive;
        if (rowValueDataType === 'Boolean' || rowValueDataType === 'Status') tdStyle = { textAlign: 'center' };

        if (rowValueDataType === 'Number' && rowValueDecimalPlace > 0) rowValue = this.Format.FormatLocaleNumber(rowValue, this.state.language.Lang, true, rowValueDecimalPlace);
        if (rowValueDataType === 'Number' && rowValueDecimalPlace <= 0) rowValue = this.Format.FormatLocaleNumber(rowValue, this.state.language.Lang, false, rowValueDecimalPlace);
        if (rowValueDataType === 'Number') tdStyle = { textAlign: 'right' };

        if (rowValueDataType === 'Date' && dateFormat !== null && typeof dateFormat !== 'undefined' && timeFormat !== null && typeof timeFormat !== 'undefined') {

            rowValue = rowValue + '';
            rowValue = this.DateService.convertToDisplayDate(rowValue);
            rowValue = this.Format.FormatLocaleDate(rowValue, this.state.language.Lang, dateFormat, timeFormat);

        }

        if (rowValueDataType === 'Date-Year' || rowValueDataType === 'Symbol') tdStyle = { textAlign: 'center' };// for year values only


        if (rowValue === null || rowValue === 'null' || rowValue === undefined || rowValue === 'undefined') rowValue = ''; // force null and undefined values to empty string


        if (this.state.link_status === true) { // check if there should be links in the data table row cells


            if (this.state.linkToBlankTarget === true) {

                if (this.state.rowClassName === true) {

                    return (
                        <td className="sorting_1" style={tdStyle}><Link to={`${this.state.link}${this.state.hash_id}${this.state.return_query_prefix}${this.state.current_path}&eut=${this.state.linkToBlankTargetToken}`} target="_blank" className="black-text">{rowValue}</Link></td>
                    )

                } else {

                    return (
                        <td style={tdStyle}><Link to={`${this.state.link}${this.state.hash_id}${this.state.return_query_prefix}${this.state.current_path}&eut=${this.state.linkToBlankTargetToken}`} target="_blank" className="black-text">{rowValue}</Link></td>
                    )
                }


            } else {

                if (this.state.rowClassName === true) {

                    return (
                        <td className="sorting_1" style={tdStyle}><Link to={`${this.state.link}${this.state.hash_id}${this.state.return_query_prefix}${this.state.current_path}`} className="black-text">{rowValue}</Link></td>
                    )

                } else {

                    return (
                        <td style={tdStyle}><Link to={`${this.state.link}${this.state.hash_id}${this.state.return_query_prefix}${this.state.current_path}`} className="black-text">{rowValue}</Link></td>
                    )
                }


            }


        } else {

            if (this.state.rowClassName === true) {

                return (
                    <td className="sorting_1" style={tdStyle}>{rowValue}</td>
                )

            } else {

                return (
                    <td style={tdStyle}>{rowValue}</td>
                )
            }


        }

    }
}

export default DatatableRowItem;