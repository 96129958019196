import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import Pagination from '../components/Pagination';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import axios from 'axios';


class AnnouncementView extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getAnnouncements = this.getAnnouncements.bind(this);
        this.selectAnnouncement = this.selectAnnouncement.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.state = {
            announcements: [],
            announcement_title: '',
            announcement_category: '',
            announcement_code: '',
            announcement_date_formated: '',
            announcement: '',
            announcementPosition: '',
            announcement_hash_id: '',
            announcement_selected: null,
            defaultNumberOfPages: 1,
            defaultRecordsPerPage: 5,
            defaultActivePage: 1,
            PaginationElement: null,
            hash_user: '',
            username: '',
            name: '',
            email: '',
            numberOfRecords: 0,
            currentNumberOfRecords: 'All records',
            result: '',
            message: '',
            token: '',
            preloader: true,
            NotiifcationDataItems: null,
            announcement_create: null,
            scopes: {},
        }
        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();

    }



    componentWillMount() {


    }

    componentDidMount() {
        document.title = this.Language.PageTitle.Announcements + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getAnnouncements(this.state.currentNumberOfRecords);


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:announcement', 'action:announcement'], 'view') === false) {
            this.props.history.replace(default_home);
        } else {
            this.getAccessScopes();
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                hash_user: user_profile.user,
                username: user_profile.username,
                name: user_profile.name,
                email: user_profile.email,
                scopes: user_profile.scopes
            }, () => {
                this.checkAccessRight();
            });
        }

    }


    getAccessScopes() {


        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:announcements' || this.state.scopes['views'][i] === 'action:announcements') {
                    this.setState({ announcement_create: true });
                    break;
                }
            }
        };

    }

    getAnnouncements(currentNumberOfRecords) {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/user/announcements/approved/published?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ announcements: response.data.data }, () => {

                    this.setState({ preloader: null });

                    let leftChevronEnable = true;
                    let rightChevronEnable = true;
                    let numberOfPages = 0;
                    if (this.state.announcements) numberOfPages = Math.ceil(this.state.announcements.length / this.state.defaultRecordsPerPage);

                    if (this.state.defaultActivePage <= 1) leftChevronEnable = false;
                    if (this.state.defaultActivePage >= numberOfPages) rightChevronEnable = false;

                    this.setState({ PaginationElement: <Pagination numberOfPages={numberOfPages} activePage={this.state.defaultActivePage} leftChevronEnable={leftChevronEnable} rightChevronEnable={rightChevronEnable} handlePaginationChange={this.handlePaginationChange} /> });

                })

                this.setState({ result: response.data.status }, () => {
                })

                this.setState({ message: response.data.message }, () => {
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js

                } else {
                    // Something happened in setting up the request that triggered an Error

                }

            });

    }


    selectAnnouncement(position) {

        let temp_array_of_objects = this.state.announcements.concat();

        let len = temp_array_of_objects.length;


        for (let i = 0; i < len; i++) {

            if (i !== position) temp_array_of_objects[i].announcement_selected_status = '';

        }

        temp_array_of_objects[position].announcement_selected_status = 'selected';

        this.setState({ announcements: temp_array_of_objects }, () => {
            this.setState({ announcement_title: this.state.announcements[position].announcement_title });
            this.setState({ announcement_category: this.state.announcements[position].announcement_category });
            this.setState({ announcement_code: this.state.announcements[position].announcement_code });
            this.setState({ announcement_date_formated: this.state.announcements[position].announcement_date_formated });
            this.setState({ announcement_selected: true });
            this.setState({ announcement: this.state.announcements[position].announcement });
            this.setState({ announcement_hash_id: this.state.announcements[position].hash_id });
            this.setState({ announcementPosition: position });
        });

    }



    handlePaginationChange(activePage) {

        this.setState({ defaultActivePage: activePage }); // change the value of active page so that the new set of records are displayed


    }



    render() {

        let announcements = null;


        if (this.state.announcements) {

            announcements = this.state.announcements.map((announcement, i) => {


                if (i >= (this.state.defaultRecordsPerPage * (this.state.defaultActivePage - 1)) && i <= (this.state.defaultRecordsPerPage * this.state.defaultActivePage) - 1) { // restrict the number of records to be displayed


                    let classValue = 'collection-item avatar ' + announcement.announcement_selected_status;
                    let nameValue = 'email-title grey-text';
                    let textValue = 'grey-text ultra-small';
                    let circleStatus = 'circle grey darken-1';

                    if (announcement.publish_status === 'Published') {
                        classValue = classValue + ' email-unread';
                        textValue = 'blue-text ultra-small';
                        nameValue = 'email-title blue-text';
                        circleStatus = 'circle light-blue';
                    }

                    return (
                        <li className={classValue} onClick={() => { this.selectAnnouncement(i) }} key={i}>
                            <span className={circleStatus}>A</span>
                            <span className={nameValue}>{`${announcement.announcement_code}`}</span>
                            <p className="truncate grey-text ultra-small">{announcement.announcement_title}</p>
                            <div className="secondary-content email-time">
                                <span className={textValue}>{announcement.announcement_date_formated}</span>
                            </div>
                        </li>
                    )

                } else {

                    return (
                        null
                    )

                }

            })

        }




        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Notifications"} active_menu_level_2={"Announcements"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.Announcements}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.Announcements]} links={[`/`, `/announcements`]} button_link={'/users/add'} button_icon={'person_add'} button_name={'Create Account'} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                {(this.state.announcement_create) && <Link className="waves-effect waves-light btn right" to={`/announcements/all`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.ManageAnnouncements}</Link>}
                                <br />
                                <br />

                                {/* <!--Mail App Section - Start--> */}
                                <div id="mail-app" className="section">
                                    <div className="row">
                                        <div className="col s12">
                                            <nav className="green">
                                                <div className="nav-wrapper">
                                                    <div className="left col s12 m5 l5">
                                                        <ul>
                                                            <li>
                                                                <i className="material-icons">email</i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col s12 m7 l7 hide-on-med-and-down">
                                                    </div>
                                                </div>
                                            </nav>
                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}
                                        </div>
                                        <div className="col s12">
                                            <div id="email-list" className="col s12 m5 l5 card-panel z-depth-1">
                                                <ul className="collection">
                                                    {announcements}
                                                </ul>
                                                {this.state.PaginationElement}
                                                {/* <Pagination numberOfPages={Math.ceil(this.state.notifications.length / this.state.defaultRecordsPerPage)} activePage={this.state.defaultActivePage} leftChevronEnable={leftChevronEnable} rightChevronEnable={rightChevronEnable} handlePaginationChange={this.handlePaginationChange}/> */}
                                            </div>
                                            <div id="email-details" className="col s12 m7 l7 card-panel">
                                                <p className="email-subject truncate">{this.state.announcement_title}
                                                    <span className="task-cat teal accent-4 right">{this.state.announcement_category}</span>
                                                </p>
                                                <hr className="grey-text text-lighten-2" />
                                                <div className="email-content-wrap">
                                                    <div className="row">
                                                        <div className="col s10 m10 l10">
                                                            <ul className="collection">
                                                                <li className="collection-item avatar">
                                                                    {this.state.announcement_selected && <span className='circle light-blue'>A</span>}
                                                                    <span className="email-title">{this.state.announcement_code}</span>
                                                                    {this.state.announcement_selected && <p className="truncate grey-text ultra-small">{this.Language.DataField.ToAllUsers}</p>}
                                                                    <p className="grey-text ultra-small">{this.state.announcement_date_formated}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="email-content">
                                                        {this.state.announcement.split("\n").map((announcement, i) => {
                                                            return <p key={i}>{announcement}</p>;
                                                        })}
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Mail App Section - End--> */}

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AnnouncementView;