import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');


class AddNotification extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addNotification = this.addNotification.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        // this.chipsAdd = this.chipsAdd.bind(this);
        // this.chipsRemove = this.chipsRemove.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_user_account: [],
            usernameChips: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.ComposeNotification + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

        // window.$('.chips').material_chip();
        // window.$('.chips-placeholder').material_chip({
        //     placeholder: this.Language.DataField.EnterUsername,
        //     // secondaryPlaceholder: '+Tag',
        //   });

        //   window.$('.chips-initial').material_chip({
        //     data: [{
        //       tag: 'Apple',
        //     }, {
        //       tag: 'Microsoft',
        //     }, {
        //       tag: 'Google',
        //     }],
        //   });



        // window.$('.chips-autocomplete').material_chip({
        //     autocompleteOptions: {
        //       data: {
        //         'Apple': null,
        //         'Microsoft': null,
        //         'Google': null
        //       },
        //       limit: Infinity,
        //       minLength: 1
        //     }
        //   });

        window.$('select').material_select();


    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['create:notifications', 'action:notifications'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("user_account");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }





    onSubmit(event) {

        event.preventDefault();


        let message = this.refs.message.value;
        let message_title = this.refs.message_title.value;
        let send_title = this.refs.message_title.value;

        let sender_id = this.state.logged_in_username;
        let sender_type = 'user';

        let owner_id = this.state.logged_in_username;
        let owner_type = 'user';

        let selected = document.querySelectorAll('#user_account option:checked'); // this is to get values from a multiple select field
        let receiver_id_array = Array.from(selected).map((el) => el.value);

        let receiver_type = 'user';


        let currentdate = new Date();

        let notification_status = 'New';
        let delivery_report = 'Delivered';

        let schedule_send_date = currentdate.toString(); // force the schedule date and time to the current date and time 
        let schedule_send_time = currentdate.toString(); // force the schedule date and time to the current date and time 

        let schedule_send_frequency = 1;
        let url = '/notifications/';
        let applink = '/notifications/';
        let requires_approval = false;
        let is_approved = true;

        let approved_by = null;
        if (is_approved) approved_by = this.state.logged_in_username;

        let status = "Active";
        let created_by = this.state.logged_in_username;



        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.Notification.AddErrorTitle, [message, message_title], [this.Language.DataField.Message, this.Language.DataField.MessageTitle], ["isRequired", "isRequired"], 0, 0) }, () => {

            if (this.state.error_messages === null) {


                let len = receiver_id_array.length;

                for (let i = 0; i < len; i++) { // write to the API for the selected usernames
                    if (receiver_id_array !== null && typeof receiver_id_array !== 'undefined' && receiver_id_array[i] !== "Select User Account") {


                        const userOS = this.Browser.getOS();

                        //make call to database 
                        let newNotification = {
                            message: message,
                            message_title: message_title,
                            send_title: send_title,
                            sender_id: sender_id,
                            sender_type: sender_type,
                            owner_id: owner_id,
                            owner_type: owner_type,
                            receiver_id: receiver_id_array[i],
                            receiver_type: receiver_type,
                            notification_status: notification_status,
                            delivery_report: delivery_report,
                            schedule_send_date: schedule_send_date,
                            schedule_send_time: schedule_send_time,
                            schedule_send_frequency: schedule_send_frequency,
                            url: url,
                            applink: applink,
                            requires_approval: requires_approval,
                            is_approved: is_approved,
                            approved_by: approved_by,
                            status: status,
                            created_by: created_by,
                            last_updated_by: created_by,
                            device_name: this.state.logged_in_username + '\'s ' + userOS,
                            device_model: userOS,
                            device_os: userOS,
                            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                            app_source: "web",
                            token: this.Auth.getToken(),
                        }



                        this.setState({ preloader: true });
                        document.getElementById("create_notification_button").setAttribute("disabled", true);

                        this.addNotification(newNotification);

                    }
                }

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }


    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_user_account = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'user_account') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_user_account: select_field_values_user_account });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }



    addNotification(newNotification) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/user/notifications`,
            data: newNotification
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.Notification.AddSuccess, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("create_notification_button").removeAttribute("disabled");

            this.props.history.push(`/notifications/${this.state.hash_user}`);

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.Notification.Error, 8000, 'rounded deep-orange darken-4');
                    }

                    //   this.props.history.push('/notifications');


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("create_notification_button").removeAttribute("disabled");

            });

    }


    // chipsAdd(chip){


    //     let tempChipsArray = this.state.usernameChips;
    //     let len = this.state.usernameChips.length;

    //     if (tempChipsArray[len - 1] !== chip.tag ) { // to prevent the array push and state update from happening multiple times
    //         tempChipsArray.push(chip.tag);
    //         this.setState({usernameChips: tempChipsArray}, () => {
    //         });
    //     }

    // }

    // chipsRemove(chip){

    //     let tempChipsArray = this.state.usernameChips;

    //     tempChipsArray = tempChipsArray.filter(value => {
    //         return value !== chip.tag;
    //         });

    //     if (tempChipsArray.length !== this.state.usernameChips.length) { // to prevent the state update from happening multiple times
    //         this.setState({usernameChips: tempChipsArray}, () => {
    //         });
    //     }


    // }




    render() {

        // window.$('.chips').on('chip.add', (event, chip) => {
        //     // you have the added chip here
        //     this.chipsAdd(chip);
        //   });

        // window.$('.chips').on('chip.delete', (event, chip) => {
        //     // you have the deleted chip here
        //     this.chipsRemove(chip);
        //   });

        // // window.$('.chips').on('chip.select', function(event, chip){
        // //     // you have the selected chip here
        // //   });



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->

                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Notifications"} active_menu_level_2={"Notifications"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.ComposeNotification}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.Notifications]} links={[`/`, `/notifications/${this.state.hash_user}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}



                                            <h4 className="header2">{this.Language.PageLabel.ComposeNotification}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    {/* <div className="row">
                                                        <div className="chips chips-placeholder" data-index="0" data-initialized="true">
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <i className="material-icons prefix">email</i>
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select multiple className="initialized" name="user_account" id="user_account" ref="user_account">
                                                                    <option value="Select User Account" disabled >{this.Language.DataFieldDefault.SelectUserAccount}</option>
                                                                    {this.state.select_field_values_user_account.map((user_account_value, i) => {
                                                                        return (
                                                                            user_account_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.To} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="message_title" name="message_title" type="text" ref="message_title" />
                                                            <label htmlFor="message_title" className="">
                                                                {this.Language.DataField.MessageTitle} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="message" className="materialize-textarea" name="message" ref="message"></textarea>
                                                            <label htmlFor="message">
                                                                {this.Language.DataField.Message} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_notification_button">
                                                                {this.Language.ButtonLabel.Send}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s6">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/notifications/${this.state.hash_user}`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddNotification;