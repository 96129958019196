import React, {Component} from 'react';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';

class Footer extends Component{
    constructor(props){
        super(props);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.state = {
            Language: props.language.Language,
            language: props.language,
            user_time_zone: '(GMT+00:00) UTC',
            // pick_list_values_time_zone: []
        }
        this.LanguageService = new LanguageService();
        this.DateService = new DateService();
        
    }



    componentDidMount(){
        // let lang = this.LanguageService.getSessionLanguage();

        // switch (lang) {
        //     case 'en':
        //         this.setState({Language: 'English'});
        //         break;

        //     case 'fr':
        //         this.setState({Language: 'Français'});
        //         break;

        //     case 'zh':
        //         this.setState({Language: '中文'});
        //         break;

        //     default:
        
        // }

        let user_time_zone = this.DateService.getUserTimeZone();
        user_time_zone = user_time_zone.split("***");
        user_time_zone = user_time_zone[1] || user_time_zone || '(GMT+00:00) UTC';

        this.setState({user_time_zone: user_time_zone});

        // pick_list_values_time_zone: this.DateService.getTimezoneList(this.state.language.Lang)
        
    }


    changeLanguage(lang){

        if(this.state.Language !== lang) {
            this.setState({Language: lang});

            this.LanguageService.setTranslation(lang);
            window.location.reload();
        }

     }


    render(){

        // const select_field_values_time_zone_region = this.state.pick_list_values_time_zone.map((pick_list_value,  i) => {
        //     return(
        //         <li data-value={pick_list_value.pick_list_id} key={i}><a href="#!" onClick={() => {}}>{pick_list_value.item_name}</a></li>
        //     )
        // })



        return (
            // <!-- START FOOTER -->
            <footer className="page-footer footer-fixed gradient-45deg-green-teal">

                <div className="footer-copyright">

                    <div className="container">
                
                            <span className="hide-on-small-only">
                                © 2019 {this.state.language.Copyright}   <a className="grey-text text-lighten-4" href="http://nepc.gov.ng/" target="_blank" rel="noopener noreferrer">{this.state.language.BaseOrganisation}</a>
                            </span>          

                            {/* <a className='dropdown-button grey-text text-lighten-4  hide-on-med-and-down right' href='#!' data-activates='dropdown_time_zone'>{this.state.user_time_zone}<i className="material-icons right">arrow_drop_down</i></a> */}

                            <a href={"#!"} className='grey-text text-lighten-4  hide-on-med-and-down right'>{this.state.user_time_zone}</a>

                            <a className='dropdown-button grey-text text-lighten-4  hide-on-small-only right' href={'#!'} data-activates='dropdown_language'>{this.state.Language}<i className="material-icons right">arrow_drop_down</i></a>


                            <ul id="dropdown_language" className="dropdown-content">
                                <li data-value="fr"><a href="#!" onClick={() => {this.changeLanguage('Français')}}>Français</a></li>
                                {/* <li data-value="zh"><a href="#!" onClick={() => {this.changeLanguage('中文')}}>中文</a></li> */}
                                <li className="divider"></li>
                                <li data-value="en"><a href="#!" onClick={() => {this.changeLanguage('English')}}>English</a></li>
                            </ul>

                            {/* <ul id="dropdown_time_zone" className="dropdown-content">
                                {select_field_values_time_zone_region}
                            </ul> */}

                    </div>

                </div>

            </footer>
        //   <!-- END FOOTER -->
        )
    }
}

export default Footer;