import React, {Component} from 'react';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import ValuesChart from '../components/ValuesChart';
import DataChart from '../components/DataChart';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import BrowserService from '../modules/BrowserService';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import URLService from '../modules/URLService';



class TrackProductivity extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.state = {
            hash_user: '',
            username: '',
            name: '',
            email: '',
            organisation: '',
            user_role: '',
            productivity_access: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.Format = new FormatService();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

        
    }

    componentWillMount(){
        this.checkAccessRight();
        this.getProfile();

    }

    componentDidMount(){
        document.title = this.Language.PageTitle.TrackProductivity + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();
        // this.checkAccessRight();
        
    }

    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null){
            this.setState({hash_user: user_profile.user,
                username: user_profile.username,
                name: user_profile.name,
                email: user_profile.email,
                organisation: user_profile.organisation,
                user_role: user_profile.user_role,
                scopes: user_profile.scopes
            });
        }

    }

    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

       if ( this.Auth.checkAccessScope(['read:productivity', 'action:productivity'], 'view') === false){
            this.props.history.replace(default_home);   
       };

    }



    getAccessScopes(){

        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'read:productivity' || this.state.scopes['views'][i] === 'action:productivity') {
                    this.setState({productivity_access: true});
                    break; // exit the loop
                }
            }
        };

    }



    render(){


        return ( 
                [
                    // <LoaderWrapper key={0}/>,
                    <Header key={1} history={this.props.history} language={this.Language}/>,
                    
                    // <!-- START MAIN -->
                    <div id="main" key={2}>
                        <div className="wrapper green lighten-5">
                            <LeftSideBar active_menu_level_1={"Tools"} active_menu_level_2={"Track Productivity"} history={this.props.history} language={this.Language}/>
                            {/* <!-- START CONTENT --> */}
                            <section id="content">
                                <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.TrackProductivity}`} breadcrumbs={[this.Language.BreadCrumbLabel.Tools, this.Language.BreadCrumbLabel.TrackProductivity]} links={[`/`, `/tools/productivity`]} language={this.Language}/>
                                {/* <!--start container--> */}
{this.state.productivity_access   &&    <div className="container">
                                    <ValuesChart />
                                    <DataChart />
                                    <DataTable tableName={this.Language.DataTableLabel.UserAccounts} 
                                        fields={['Name', 'Position', 'Office', 'Number', 'Start date', 'Pending Claims']} 
                                        fieldDataTypes={['String', 'String', 'String', 'String', 'Date-Year','Number']}
                                        fieldDecimalPlaces={[0, 0, 0, 0, 0, 0]}
                                        dateFormat={'long_date'}
                                        timeFormat={'h12'}
                                        headers={[this.Language.DataTableHeader.Name, this.Language.DataTableHeader.Position, this.Language.DataTableHeader.Office, this.Language.DataTableHeader.RCNumber, this.Language.DataTableHeader.StartDate, this.Language.DataTableHeader.Category]} 
                                        data={[{'Name': 'Adamu Shehu', 'Position': 'Accountant', 'Office': 'Abuja', 'Number': '33', 'Start date': '2008', 'Pending Claims': '162,700'},{'Name': 'segun Babalola', 'Position': 'Accountant', 'Office': 'Abuja', 'Number': '33', 'Start date': '2008', 'Pending Claims': '162,700'},{'Name': 'Obinna Ezinne', 'Position': 'Accountant', 'Office': 'Abuja', 'Number': '33', 'Start date': '2008', 'Pending Claims': '162,700'}]} 
                                        sortType={"ascending"} sortPosition={2} thStyle={[351, 221, 164, 74, 145, 130]} link={''} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={false} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.HomeUserAccounts} emptyMessage={this.Language.DataTableLabel.EmptyMessage} 
                                        downloadFields={['Name', 'Position', 'Office', 'Number', 'Start date', 'Pending Claims']}/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>



                                </div> }
                                {/* <!--end container--> */}
                            </section>
                            {/* <!-- END CONTENT --> */}
                            <FloatingActionButton language={this.Language}/>
                            {/* <RightSideBar language={this.Language}/> */}
                        </div>
                        {/* <!-- END WRAPPER --> */}
                    </div>
                    // <!-- END MAIN --> */
                    // <!-- //////////////////////////////////////////////////////////////////////////// -->
                    ,
                    <Footer key={3} language={this.Language}/>
                ]

        )
    }
}

export default TrackProductivity;