import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';




class PasswordConfiguration extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getPasswordConfiguration = this.getPasswordConfiguration.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.state = {
            passwordConfigurationDetails: [],
            limit_pasword_length: false,
            min_password_length: 4,
            max_password_length: 30,
            allow_alphanumeric: false,
            allow_capital: false,
            allow_special_char: false,
            allow_user_id: false,
            must_contain_lower_case: false,
            must_contain_upper_case: false,
            must_contain_special_character: false,
            allow_consectutive_character: false,
            allow_password_reuse: false,
            allow_password_reuse_after: 10,
            check_password_history: false,
            password_history_count: 10,
            password_expiry_days: 90,
            hash_id: '',
            status: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_user: '',
            password_config_update: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.PasswordConfiguration + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

    }



    componentDidUpdate(){
       
    }



    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile){
            this.setState({scopes: user_profile.scopes,
                logged_in_username: user_profile.username,
                hash_user: user_profile.user}, () => {
                this.checkAccessRight();
            });
            
        }

    }


    checkAccessRight(){

        if ( (this.Auth.checkAccessScope(['read:password_config', 'action:password_config'], 'view') === false) ){
             this.props.history.replace(`/`);   
        } else {
            this.getAccessScopes();
            this.getPasswordConfiguration();
        };
 
     }



     getAccessScopes(){

        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'update:password_config' || this.state.scopes['views'][i] === 'action:password_config') this.setState({password_config_update: true});
                
            }
        };

    }



    getPasswordConfiguration(){


        axios.get(`${this.apiURL}/api/v1/user/password/config?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    passwordConfigurationDetails: response.data.data,
                    limit_pasword_length: (response.data.data[0].limit_pasword_length === true || response.data.data[0].limit_pasword_length === 'true')? true : false || false,
                    min_password_length: response.data.data[0].min_password_length || this.state.min_password_length,
                    max_password_length: response.data.data[0].max_password_length || this.state.max_password_length,
                    allow_alphanumeric: (response.data.data[0].allow_alphanumeric === true || response.data.data[0].allow_alphanumeric === 'true')? true : false || false,
                    allow_capital: (response.data.data[0].allow_capital === true || response.data.data[0].allow_capital === 'true')? true : false || false,
                    allow_special_char: (response.data.data[0].allow_special_char === true || response.data.data[0].allow_special_char === 'true')? true : false || false,
                    allow_user_id: (response.data.data[0].allow_user_id === true || response.data.data[0].allow_user_id === 'true')? true : false || false,
                    must_contain_lower_case: (response.data.data[0].must_contain_lower_case === true || response.data.data[0].must_contain_lower_case === 'true')? true : false || false,
                    must_contain_upper_case: (response.data.data[0].must_contain_upper_case === true || response.data.data[0].must_contain_upper_case === 'true')? true : false || false,
                    must_contain_special_character: (response.data.data[0].must_contain_special_character === true || response.data.data[0].must_contain_special_character === 'true')? true : false || false,
                    allow_consectutive_character: (response.data.data[0].allow_consectutive_character === true || response.data.data[0].allow_consectutive_character === 'true')? true : false || false,
                    allow_password_reuse: (response.data.data[0].allow_password_reuse === true || response.data.data[0].allow_password_reuse === 'true')? true : false || false,
                    allow_password_reuse_after: response.data.data[0].allow_password_reuse_after || this.state.allow_password_reuse_after,
                    check_password_history: (response.data.data[0].check_password_history === true || response.data.data[0].check_password_history === 'true')? true : false || false,
                    password_history_count: response.data.data[0].password_history_count || this.state.password_history_count,
                    password_expiry_days: response.data.data[0].password_expiry_days || this.state.password_expiry_days,
                    hash_id: response.data.data[0].hash_id || '',
                    status: response.data.data[0].status || ''
                });



                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })

                this.setState({preloader: null});
                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  


                    if(error.response.data.message[0].msg){

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                            if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }
                    

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                

              });

              this.setState({preloader: null});

    }






    render(){

        const passwordConfigCards = this.state.passwordConfigurationDetails.map((passwordConfig,  i) => {


                return(
                    [         
                        <ul id="profile-page-about-details" className="collection z-depth-1" key={passwordConfig.hash_id}>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.LimitPasswordLength}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.limit_pasword_length !== null && typeof passwordConfig.limit_pasword_length !== 'undefined' ?  ((passwordConfig.limit_pasword_length === 'true') || (passwordConfig.limit_pasword_length === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.MinimumPasswordLength}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.min_password_length}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.MaximumPasswordLength}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.max_password_length}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.AllowAlphanumericCharactersInPassword}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.allow_alphanumeric !== null && typeof passwordConfig.allow_alphanumeric !== 'undefined' ?  ((passwordConfig.allow_alphanumeric === 'true') || (passwordConfig.allow_alphanumeric === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.AllowCapitalLettersInPassword}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.allow_capital !== null && typeof passwordConfig.allow_capital !== 'undefined' ?  ((passwordConfig.allow_capital === 'true') || (passwordConfig.allow_capital === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.AllowSpecialCharactersInPassword}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.allow_special_char !== null && typeof passwordConfig.allow_special_char !== 'undefined' ?  ((passwordConfig.allow_special_char === 'true') || (passwordConfig.allow_special_char === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.AllowUsernameToBeUsedInPassword}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.allow_user_id !== null && typeof passwordConfig.allow_user_id !== 'undefined' ?  ((passwordConfig.allow_user_id === 'true') || (passwordConfig.allow_user_id === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.PasswordMustContainAtLeastOneLowerCaseCharacter}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.must_contain_lower_case !== null && typeof passwordConfig.must_contain_lower_case !== 'undefined' ?  ((passwordConfig.must_contain_lower_case === 'true') || (passwordConfig.must_contain_lower_case === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.PasswordMustContainAtLeastOneUpperCaseCharacter}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.must_contain_upper_case !== null && typeof passwordConfig.must_contain_upper_case !== 'undefined' ?  ((passwordConfig.must_contain_upper_case === 'true') || (passwordConfig.must_contain_upper_case === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.PasswordMustContainAtLeastOneSpecialCharacter}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.must_contain_special_character !== null && typeof passwordConfig.must_contain_special_character !== 'undefined' ?  ((passwordConfig.must_contain_special_character === 'true') || (passwordConfig.must_contain_special_character === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.AllowUseOfConsecutiveCharactersInPassword}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.allow_consectutive_character !== null && typeof passwordConfig.allow_consectutive_character !== 'undefined' ?  ((passwordConfig.allow_consectutive_character === 'true') || (passwordConfig.allow_consectutive_character === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.AllowPasswordToBeReusedOrRepeated}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.allow_password_reuse !== null && typeof passwordConfig.allow_password_reuse !== 'undefined' ?  ((passwordConfig.allow_password_reuse === 'true') || (passwordConfig.allow_password_reuse === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.AllowPasswordToBeReusedAfter}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.allow_password_reuse_after}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.CheckPasswordHistory}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.check_password_history !== null && typeof passwordConfig.check_password_history !== 'undefined' ?  ((passwordConfig.check_password_history === 'true') || (passwordConfig.check_password_history === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.CheckNumberOfPreviousPasswordHistoryBeforeAllowingPasswordReuse}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.password_history_count}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.NumberOfDaysBeforePasswordExpiry}:
                                    </div>
                                    <div className="col s7 right-align"><b>{passwordConfig.password_expiry_days}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.Status}:
                                    </div>
                                    <div className="col s7 right-align"><b>{(passwordConfig.status === 'Active') ? this.Language.DataValue.Active : this.Language.DataValue.Inactive}</b></div>
                                </div>
                            </li>
                        </ul>,
                        <br key={passwordConfig.hash_id + 1}/>,
                    ]
                )

        })
        



        return [
          // <LoaderWrapper key={0}/>,
          <Header key={1} history={this.props.history} language={this.Language} />,

          // <!-- START MAIN -->
          <div id="main" key={2}>
            <div className="wrapper green lighten-5">
              <LeftSideBar active_menu_level_1={'Security'} active_menu_level_2={'Password Configuration'} history={this.props.history} language={this.Language} />
              {/* <!-- START CONTENT --> */}
              <section id="content">
                <BreadcrumbsWrapper
                  page_title={`${this.Language.PageTitle.PasswordConfiguration}`}
                  breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.PasswordConfiguration]}
                  links={[`/users`, `/security/password/configuration`]}
                  language={this.Language}
                />
                {/* <!--start container--> */}
                <div className="container">
                  <div className="row">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <div id="profile-page-sidebar" className="col s12 m7">
                      <div id="profile-page-content" className="row">
                        {passwordConfigCards}

                        {this.state.passwordConfigurationDetails.length > 0 && this.state.password_config_update ? (
                          <Link className="waves-effect waves-light btn" to={`/security/password/configuration/edit/${this.state.hash_id}`}>
                            <i className="material-icons left">edit</i>
                            {this.Language.ButtonLabel.Edit}
                          </Link>
                        ) : (
                          <Link className="waves-effect waves-light btn" to={`/security/password/configuration/add`}>
                            <i className="material-icons left">add</i>
                            {this.Language.ButtonLabel.Add}
                          </Link>
                        )}
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                {/* <!--end container--> */}
              </section>
              {/* <!-- END CONTENT --> */}
              <FloatingActionButton language={this.Language} />
              {/* <RightSideBar language={this.Language}/> */}
            </div>
            {/* <!-- END WRAPPER --> */}
          </div>,
          // <!-- END MAIN --> */
          // <!-- //////////////////////////////////////////////////////////////////////////// -->
          <Footer key={3} language={this.Language} />
        ];
    }
}

export default PasswordConfiguration;