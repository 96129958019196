import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');


class EditProductCategorisation extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateProductCategory = this.updateProductCategory.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getProductCategory = this.getProductCategory.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            product: '',
            product_description: '',
            categorisation: '',
            hash_id: '',
            status: '',
            status_active: false,
            pick_list_values: [],
            select_field_values_categorisation: [],
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EditProductCategory + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();
        this.getProductCategory();


    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:categories', 'action:categories'], 'view') === false)) {
            this.props.history.replace('/categorisation/product');
        } else {
            this.getDropdownValues("categorisation");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getProductCategory() {

        let productCategoryId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/categorisation/product/${productCategoryId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({
                    product: response.data.data[0].product || '',
                    product_description: response.data.data[0].product_description || '',
                    categorisation: response.data.data[0].categorisation || '',
                    hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                   
                })

                this.setState({ message: response.data.message }, () => {
                   
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {

                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {



                        const select_field_values_categorisation = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'categorisation') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_categorisation: select_field_values_categorisation });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }




    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveProductCategory} ${this.refs.product.value}`)) {

            let product = this.refs.product.value;
            let product_description = this.refs.product_description.value;

            let categorisation = this.refs.categorisation.value;
            if (categorisation === "Select Categorisation") categorisation = "";


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";


            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;

            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ProductCategorisation.EditErrorTitle, [product, product_description, product_description, categorisation], [this.Language.DataField.Product, this.Language.DataField.Description, this.Language.DataField.Description, this.Language.DataField.Categorisation], ["isRequired", "isRequired", "maxLength", "isRequired"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {


                    if (categorisation === "Select Categorisation") categorisation = null;

                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newProductCategorisation = {
                        product: product,
                        product_description: product_description,
                        categorisation: categorisation,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_product_category_button").setAttribute("disabled", true);

                    this.updateProductCategory(newProductCategorisation);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }






    updateProductCategory(newProductCategorisation) {

        let productCategoryId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/categorisation/product/${productCategoryId}`,
            data: newProductCategorisation
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ProductCategorisation.UpdateSuccess + newProductCategorisation.product, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_product_category_button").removeAttribute("disabled");

            this.props.history.push('/categorisation/product');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ProductCategorisation.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ProductCategorisation.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.ProductCategorisation.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/categorisation/product');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_product_category_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_product_category_button").setAttribute("disabled", true);


        let product = this.state.product;
        let logged_in_username = this.state.logged_in_username;
        let productCategoryId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteProductCategory = {
            product: product,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/categorisation/product/${productCategoryId}`,
            data: deleteProductCategory
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.Language.NodeMessage.ProductCategorisation.DeleteSuccess + deleteProductCategory.product, 8000, 'rounded green');
            })


            document.getElementById("delete_product_category_button").removeAttribute("disabled");
            this.props.history.replace('/categorisation/product');

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ProductCategorisation.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                 

                document.getElementById("delete_product_category_button").removeAttribute("disabled");

            });

    }




    render() {


        // let productCategoryId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Categorisation"} active_menu_level_2={"Product Categorisation"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditProductCategory}`} breadcrumbs={[this.Language.BreadCrumbLabel.Categorisation, this.Language.BreadCrumbLabel.ProductCategories, this.Language.BreadCrumbLabel.EditProductCategory]} links={[`/`, `/categorisation/product/`, `/categorisation/product/edit`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditProductCategory}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>

                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="product" type="text" ref="product" value={this.state.product} onChange={this.handleInputChange} />
                                                            <label htmlFor="product" className={(this.state.product && this.state.product !== null && typeof this.state.product !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Product} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-categorisation" className="initialized" name="categorisation" ref="categorisation">
                                                                    {this.state.categorisation && <option value={this.state.categorisation} selected>{this.state.categorisation}</option>}
                                                                    <option value="Select Categorisation">{this.Language.DataFieldDefault.SelectCategorisation}</option>
                                                                    {this.state.select_field_values_categorisation.map((categorisation_value, i) => {
                                                                        return (
                                                                            categorisation_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Categorisation} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="product_description" className="materialize-textarea" data-length={this.state.text_limit} ref="product_description" value={this.state.product_description} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="product_description" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.product_description && this.state.product_description !== null && typeof this.state.product_description !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateProductCategory}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateProductCategory}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_product_category_button">
                                                                {this.Language.ButtonLabel.UpdateProductCategory}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s4">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/categorisation/product`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_product_category_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteProductCategory} ${this.state.product}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditProductCategorisation;