import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

class UserSecurityQuestions extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getSecurityQuestions = this.getSecurityQuestions.bind(this);
        this.UpdateSecurityQuestions = this.UpdateSecurityQuestions.bind(this);
        this.state = {
            hash_user: '',
            username: '',
            name: '',
            email: '',
            organisation: '',
            user_role: '',
            security_question_1: '',
            security_question_2: '',
            security_question_3: '',
            security_answer_1: '',
            security_answer_2: '',
            security_answer_3: '',
            preloader: true,
            message: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }

    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();
    }

    componentDidMount() {
        
        // this.checkAccessRight();
        this.getSecurityQuestions();

    }

    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }

    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null) {
            this.setState({});
            this.setState({
                hash_user: user_profile.user,
                username: user_profile.username
            }, () => {
                document.title = this.Language.PageTitle.EditSecurityQuestions.BeginningPhrase + ' ' + this.state.username + this.Language.PageTitle.EditSecurityQuestions.EndPhrase + ' - ' + this.Language.EEGWebPortal;
            });
            // this.setState({name: user_profile.name});
            // this.setState({email: user_profile.email});
            // this.setState({organisation: user_profile.organisation});
            // this.setState({user_role: user_profile.user_role});
        }

    }

    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:user', 'action:user'], 'view') === false && this.state.hash_user === this.props.match.params.id) {
            this.props.history.replace(default_home);
        };

    }

    onSubmit(event) {

        event.preventDefault();

        if (window.confirm(`${this.Language.ConfirmMessage.EditSecurityQuestions}`)) {

            let username = this.state.username;
            let security_question_1 = this.refs.security_question_1.value;
            let security_answer_1 = this.refs.security_answer_1.value;
            let security_question_2 = this.refs.security_question_2.value;
            let security_answer_2 = this.refs.security_answer_2.value;
            let security_question_3 = this.refs.security_question_3.value;
            let security_answer_3 = this.refs.security_answer_3.value;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.SecurityQuestions.EditErrorTitle, [security_question_1, security_question_2, security_question_3, security_answer_1, security_answer_2, security_answer_3], [this.Language.DataField.SecurityQuestion1, this.Language.DataField.SecurityQuestion2, this.Language.DataField.SecurityQuestion3, this.Language.DataField.Answer1, this.Language.DataField.Answer2, this.Language.DataField.Answer3], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

                if (this.state.error_messages === null) {

                    const userOS = this.Browser.getOS();


                    //make call to database 
                    const newSecurityQuestions = {
                        username: username,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                        security_question_1: security_question_1,
                        security_answer_1: security_answer_1,
                        security_question_2: security_question_2,
                        security_answer_2: security_answer_2,
                        security_question_3: security_question_3,
                        security_answer_3: security_answer_3
                    }


                    this.setState({ preloader: true });
                    document.getElementById("update_security_question_button").setAttribute("disabled", true);

                    this.UpdateSecurityQuestions(newSecurityQuestions);


                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                };

            });

        }


    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    getSecurityQuestions() {
        let userId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/users/sq/${userId}?tk=${this.Auth.getToken()}`)
            .then(response => {

                this.setState({
                    username: response.data.data[0].username,
                    security_question_1: response.data.data[0].security_question1,
                    security_answer_1: response.data.data[0].security_answer1,
                    security_question_2: response.data.data[0].security_question2,
                    security_answer_2: response.data.data[0].security_answer2,
                    security_question_3: response.data.data[0].security_question3,
                    security_answer_3: response.data.data[0].security_answer3,
                });


                this.setState({ preloader: null });



            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    
                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }


    UpdateSecurityQuestions(newSecurityQuestions) {

        let userId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/users/sq/${userId}`,
            data: newSecurityQuestions
        }).then(response => {
            this.setState({ status: response.data.status }, () => {
                
            })



            this.setState({ preloader: null });
            document.getElementById("update_security_question_button").removeAttribute("disabled");


            this.setState({ message: response.data.message }, () => {
                
                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.SecurityQuestions.UpdateSuccess + newSecurityQuestions.username, 8000, 'rounded green');
            })


        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SecurityQuestions.UpdateError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.SecurityQuestions.DoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_security_question_button").removeAttribute("disabled");

            });
    }




    render() {


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"User Management"} active_menu_level_2={"User Accounts"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditSecurityQuestions.BeginningPhrase} ${this.state.username}${this.Language.PageTitle.EditSecurityQuestions.EndPhrase}`} breadcrumbs={[this.Language.BreadCrumbLabel.SecurityQuestions]} links={[`/user/profile/sq/${this.state.hash_user}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            {/* <h4 className="header2">Answer the Security Questions to Signin </h4> */}
                                            <div className="row">

                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">help</i>
                                                            <input id="security_question_1" name="security_question_1" type="text" ref="security_question_1" value={this.state.security_question_1} />
                                                            <label htmlFor="security_question_1" className={(this.state.security_question_1 && this.state.security_question_1 !== null && typeof this.state.security_question_1 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SecurityQuestion1}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="security_answer_1" type="text" ref="security_answer_1" name="security_answer_1" value={this.state.security_answer_1} onChange={this.handleInputChange} />
                                                            <label htmlFor="security_answer_1" className={(this.state.security_answer_1 && this.state.security_answer_1 !== null && typeof this.state.security_answer_1 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Answer}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">help</i>
                                                            <input id="security_question_2" name="security_question_2" type="text" ref="security_question_2" value={this.state.security_question_2} />
                                                            <label htmlFor="security_question_2" className={(this.state.security_question_2 && this.state.security_question_2 !== null && typeof this.state.security_question_2 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SecurityQuestion2}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="security_answer_2" type="text" ref="security_answer_2" name="security_answer_2" value={this.state.security_answer_2} onChange={this.handleInputChange} />
                                                            <label htmlFor="security_answer_2" className={(this.state.security_answer_2 && this.state.security_answer_2 !== null && typeof this.state.security_answer_2 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Answer}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">help</i>
                                                            <input id="security_question_3" name="security_question_3" type="text" ref="security_question_3" value={this.state.security_question_3} />
                                                            <label htmlFor="security_question_3" className={(this.state.security_question_3 && this.state.security_question_3 !== null && typeof this.state.security_question_3 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SecurityQuestion3}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="security_answer_3" type="text" ref="security_answer_3" name="security_answer_3" value={this.state.security_answer_3} onChange={this.handleInputChange} />
                                                            <label htmlFor="security_answer_3" className={(this.state.security_answer_3 && this.state.security_answer_3 !== null && typeof this.state.security_answer_3 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Answer}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_security_question_button">
                                                                {this.Language.ButtonLabel.UpdateSecurityQuestions}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s6">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/user/profile/${this.state.hash_user}`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />

                                                </form>



                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <br />
                                <br />

                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default UserSecurityQuestions;