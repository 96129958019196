import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';



class SystemModules extends Component{
    constructor(props){
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getModules = this.getModules.bind(this);
        this.state = {
            moduleDetails: [],
            result: '',
            message: '',
            modules_create: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
        this.checkAccessRight();
        this.getProfile();
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.SystemModules + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();
        this.getModules();
    }


    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ( (this.Auth.checkAccessScope(['read:modules', 'action:modules'], 'view') === false)){
             this.props.history.replace(default_home);   
        };
 
     }


     getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile){
            this.setState({scopes: user_profile.scopes});
        }

    }



    getAccessScopes(){

        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:modules' || this.state.scopes['views'][i] === 'action:modules') {
                    this.setState({modules_create: true});
                    break;
                }
            }
        };


    }




     getModules(){

        axios.get(`${this.apiURL}/api/v1/settings/modules?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({moduleDetails: response.data.data}, () => {

                })

                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })

                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  


                    if(error.response.data.message[0].msg){

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                            if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                    }
                    

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });

    }




    render(){

        const moduleCardsOdd = this.state.moduleDetails.map((moduleItem,  i) => {
            if ((i % 2) === 0) {

                return(
                [
                    <div className="card green lighten-1" key={i}>
                        <div className="card-content white-text">
                            <span className="card-title">{moduleItem.module_number + 1}. <b>{moduleItem.module_name}</b> <Link to={`/settings/modules/edit/${moduleItem.hash_id}`}><span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.ViewDetails}></span></Link></span>
                            <p>{this.Language.DataField.Description}: {moduleItem.module_description}</p>
                        </div>
                    </div>,
                    <ul id="module-details" className="collection z-depth-1" key={i + 1}>
                    <li className="collection-item">
                      <div className="row">
                        <div className="col s5">
                          <i className="material-icons left">toc</i> {this.Language.DataField.MenuPosition}</div>
                        <div className="col s7 right-align"><b>{moduleItem.module_menu_position + 1}</b></div>
                      </div>
                    </li>
                    <li className="collection-item">
                      <div className="row">
                        <div className="col s5">
                          <i className="material-icons left">language</i> {this.Language.DataField.ModuleURL}</div>
                        <div className="col s7 right-align"><b>{moduleItem.module_url}</b></div>
                      </div>
                    </li>
                    <li className="collection-item">
                      <div className="row">
                        <div className="col s5">
                          <i className="material-icons left">code</i> {this.Language.DataField.ModuleCode}</div>
                        <div className="col s7 right-align"><b>{moduleItem.module_code}</b></div>
                      </div>
                    </li>
                    <li className="collection-item">
                        <div className="row">
                        <div className="col s5">
                            <i className="material-icons left">check_circle</i> {this.Language.DataField.Status}</div>
                        <div className="col s7 right-align"><b>{moduleItem.status ?  ((moduleItem.status === 'Active') ?  this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null }</b></div>
                        </div>
                    </li>
                    <li className="collection-item">
                      <div className="row">
                        <div className="col s5">
                          <i className="material-icons left">view_quilt</i> {this.Language.DataField.ChildModule}</div>
                        <div className="col s7 right-align">{moduleItem.is_child_module ?  ((moduleItem.is_child_module === 'true') || (moduleItem.is_child_module === true) ?  <b><span className="green-text">{this.Language.DataValue.Yes}</span></b> : <b><span className="black-text">{this.Language.DataValue.No}</span></b>) : null }</div>
                      </div>
                    </li>
                    <li className="collection-item">
                      <div className="row">
                        <div className="col s5">
                          <i className="material-icons left">view_quilt</i> {this.Language.DataField.SubModuleQ}</div>
                        <div className="col s7 right-align">{moduleItem.is_sub_module ?  ((moduleItem.is_sub_module === 'true') || (moduleItem.is_sub_module === true) ?  <b><span className="green-text">{this.Language.DataValue.Yes}</span></b> : <b><span className="black-text">{this.Language.DataValue.No}</span></b>) : null }</div>
                      </div>
                    </li>
                    </ul>,
                    <br key={i + 2}/>,
                    <div className="divider" key={i + 3}></div>
                ]
                )
            } else {
                return (
                    null
                )
            }
        })


        const moduleCardsEven = this.state.moduleDetails.map((moduleItem,  i) => {
            if ((i % 2) > 0) {
                return(
                [
                    <div className="card green lighten-1" key={i}>
                        <div className="card-content white-text">
                            <span className="card-title">{moduleItem.module_number + 1}. <b>{moduleItem.module_name}</b> <Link to={`/settings/modules/edit/${moduleItem.hash_id}`}><span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.ViewDetails}></span></Link></span>
                            <p>{this.Language.DataField.Description}: {moduleItem.module_description}</p>
                        </div>
                    </div>,
                    <ul id="module-details" className="collection z-depth-1" key={i + 1}>
                    <li className="collection-item">
                        <div className="row">
                        <div className="col s5">
                            <i className="material-icons left">toc</i> {this.Language.DataField.MenuPosition}</div>
                        <div className="col s7 right-align"><b>{moduleItem.module_menu_position + 1}</b></div>
                        </div>
                    </li>
                    <li className="collection-item">
                        <div className="row">
                        <div className="col s5">
                            <i className="material-icons left">language</i> {this.Language.DataField.ModuleURL}</div>
                        <div className="col s7 right-align"><b>{moduleItem.module_url}</b></div>
                        </div>
                    </li>
                    <li className="collection-item">
                        <div className="row">
                        <div className="col s5">
                            <i className="material-icons left">code</i> {this.Language.DataField.ModuleCode}</div>
                        <div className="col s7 right-align"><b>{moduleItem.module_code}</b></div>
                        </div>
                    </li>
                    <li className="collection-item">
                        <div className="row">
                        <div className="col s5">
                            <i className="material-icons left">check_circle</i> {this.Language.DataField.Status}</div>
                        <div className="col s7 right-align"><b>{moduleItem.status ?  ((moduleItem.status === 'Active') ?  this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null }</b></div>
                        </div>
                    </li>
                    <li className="collection-item">
                        <div className="row">
                        <div className="col s5">
                            <i className="material-icons left">view_quilt</i> {this.Language.DataField.ChildModule}</div>
                        <div className="col s7 right-align">{moduleItem.is_child_module ?  ((moduleItem.is_child_module === 'true') || (moduleItem.is_child_module === true) ?  <b><span className="green-text">{this.Language.DataValue.Yes}</span></b> : <b><span className="black-text">{this.Language.DataValue.No}</span></b>) : null }</div>
                        </div>
                    </li>
                    <li className="collection-item">
                        <div className="row">
                        <div className="col s5">
                            <i className="material-icons left">view_quilt</i> {this.Language.DataField.SubModuleQ}</div>
                        <div className="col s7 right-align">{moduleItem.is_sub_module ?  ((moduleItem.is_sub_module === 'true') || (moduleItem.is_sub_module === true) ?  <b><span className="green-text">{this.Language.DataValue.Yes}</span></b> : <b><span className="black-text">{this.Language.DataValue.No}</span></b>) : null }</div>
                        </div>
                    </li>
                    </ul>,
                    <br key={i + 2}/>,
                    <div className="divider" key={i + 3}></div>
                ]
                )
            } else {
                return (
                    null
                )
            }
        })



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"System Modules"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.SystemModules}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.SystemModules]} links={[`/settings/modules`, `/settings/modules`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <br/>
{(this.state.modules_create) &&   <Link className="waves-effect waves-light btn right" to={`/settings/modules/add`}><i className="material-icons left">library_add</i>{this.Language.ButtonLabel.AddModule}</Link>}
                                <br/>
                                <br/>
                                <div id="profile-page" className="section">
                                    <div id="profile-page-content" className="row">
                                        <div id="profile-page-sidebar" className="col s12 m6">
                                            {moduleCardsOdd}
                                        </div>
                                        <div id="profile-page-wall" className="col s12 m6">
                                            {moduleCardsEven}
                                        </div>
                                    </div>

                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]  
        )
    }
}

export default SystemModules;