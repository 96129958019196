import React, { Component } from 'react';
import {Link} from 'react-router-dom';


class BreadcrumbsWrapperItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            itemName: props.itemName,
            link: props.link,
            position: props.position
        }
    }

    render(){
    
        if (this.state.position === 'first') {
            return (
                <li>
                    <Link to={this.state.link} className="teal-text">{this.state.itemName}</Link>
                </li>
            )
        }

        if (this.state.position === 'middle') {
            return (
                <li>
                    {/* ::before */}
                    <Link to={this.state.link} className="teal-text">{this.state.itemName}</Link>
                </li>
            )
        }

        if (this.state.position === 'last') {
            return (
                <li className="active">
                    {/* ::before */}
                    {this.state.itemName}
                </li>
            )
        }


        return (
            <li>
                <Link to={this.state.link}>{this.state.itemName}</Link>
            </li>
        )

    }
}

export default BreadcrumbsWrapperItem;