export default class FormatService {
  FormatLocaleNumber(number, lang, is_decimal, decimal_places) {
    // format numbers to the locale format
    try {
      if (
        number !== null &&
        typeof number !== 'undefined' &&
        lang !== null &&
        typeof lang !== 'undefined' &&
        is_decimal !== null &&
        typeof is_decimal !== 'undefined' &&
        decimal_places !== null &&
        typeof decimal_places !== 'undefined'
      ) {
        let temp_number = number;
        if (is_decimal === true) {
          if (typeof number === 'string') number = parseFloat(number).toLocaleString(lang, {style: 'decimal', maximumFractionDigits: decimal_places, minimumFractionDigits: decimal_places});
          if (typeof number !== 'string') number = number.toLocaleString(lang, {style: 'decimal', maximumFractionDigits: decimal_places, minimumFractionDigits: decimal_places});
        } else {
          if (typeof number === 'string') number = parseFloat(number).toLocaleString(lang);
          if (typeof number !== 'string') number = number.toLocaleString(lang);
        }

        if (isNaN(temp_number) && isFinite(temp_number) === false) {
          return 0.0;
        } else {
          return number;
        }
      } else {
        return null;
      }
    } catch (error) {
      if (isNaN(number)) {
        return 0.0;
      } else {
        return number;
      }
    }
  }

  FormatLocaleDate(date_value, lang, date_option, time_option) {
    // format dates to the locale format
    try {
      if (date_value !== null && typeof date_value !== 'undefined' && lang !== null && typeof lang !== 'undefined') {
        // @date_option possible values
        // 'short_date' = 17/07/2018
        // 'long_date' = 17 July 2018 exchange rates and claims status
        // 'short_date_time' = 17/07/2018 08:17:24 pm
        // 'long_date_time' =  Tuesday , 31 Jul 2018 08:17:24 pm online users, user signins, audit activities, signin history/user signins, password history
        // 'full_date_time' =  Tuesday , 31 July 2018 08:17:24 pm
        // 'short_time' or 'long_time' or 'time_only' = 08:17:24 pm

        // @time_option possible values
        // 'h12' = 08:17:24 pm
        // 'h24' = 20:17:24

        // UTC is used for this conversion with the assumption that the timezone has been used to select the date value and
        // it no longer needs timezone conversion at this point only locale conversion is required.
        let options = {}; // empty object for options
        let h12 = true; //Show time in 12 hours format.
        const timeZone = 'UTC';

        if (time_option === 'h24') h12 = false; // Use false to show time in 24 hours format

        if (date_option === 'short_date') {
          // 'short_date' = 17/07/2018

          options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: timeZone
          };
        }

        if (date_option === 'long_date') {
          // 'long_date' = 17 July 2018

          options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: timeZone
          };
        }

        if (date_option === 'short_date_time') {
          // 'short_date_time' = 17/07/2018 08:17:24 pm

          options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: h12,
            timeZone: timeZone
          };
        }

        if (date_option === 'long_date_time') {
          // 'long_date_time' =  Tuesday , 31 Jul 2018 08:17:24 pm

          options = {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: h12,
            timeZone: timeZone
          };
        }

        if (date_option === 'full_date_time') {
          // 'full_date_time' =  Tuesday , 31 July 2018 08:17:24 pm

          options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: h12,
            timeZone: timeZone
          };
        }

        if (date_option === 'full_date_time') {
          // 'short_time' or 'long_time' or 'time_only' = 08:17:24 pm

          options = {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: h12,
            timeZone: timeZone
          };
        }

        date_value = new Intl.DateTimeFormat(lang, options).format(date_value);
        // date_value = (date_value).toLocaleString(lang, options);

        return date_value;
      } else {
        return null;
      }
    } catch (error) {
      return date_value;
    }
  }

  numberToWords(n, custom_join_character) {
    // Convert an integer to its words representation

    let string = n.toString();
    let word = null;

    const and = custom_join_character || 'and';

    /* Is number zero? */
    if (parseInt(string, 10) === 0) {
      return 'zero';
    }

    /* Array of units as words */
    let units = [
      '',
      'One',
      'Two',
      'Three',
      'Four',
      'Five',
      'Six',
      'Seven',
      'Eight',
      'Nine',
      'Ten',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen'
    ];

    /* Array of tens as words */
    let tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    /* Array of scales as words */
    let scales = [
      '',
      'Thousand',
      'Million',
      'Billion',
      'Trillion',
      'Quadrillion',
      'Quintillion',
      'Sextillion',
      'Septillion',
      'Octillion',
      'Nonillion',
      'Decillion',
      'Undecillion',
      'Duodecillion',
      'Tredecillion',
      'Quatttuor-decillion',
      'Quindecillion',
      'Sexdecillion',
      'Septen-decillion',
      'Octodecillion',
      'Novemdecillion',
      'Vigintillion',
      'Centillion'
    ];

    /* Split user arguemnt into 3 digit chunks from right to left */
    let start = string.length;
    let chunks = [];
    while (start > 0) {
      let end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    let chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return '';
    }

    /* Stringify each integer in each chunk */
    let words = [];
    for (let i = 0; i < chunksLen; i++) {
      let chunk = parseInt(chunks[i], 10);

      if (chunk) {
        /* Split chunk into array of individual integers */
        let ints = chunks[i]
          .split('')
          .reverse()
          .map(parseFloat);

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word);
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || (!i && chunksLen)) {
            words.push(and);
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + ' Hundred');
        }
      }
    }

    return words.reverse().join(' ');
  }

  GenerateStepNumberListWithinRange(start_value, end_value) {
    if (start_value !== null && typeof start_value !== 'undefined' && end_value !== null && typeof end_value !== 'undefined') {
      try {
        let step = 0;
        let NumberList = [];
        let start = parseInt(start_value, 10);
        let end = parseInt(end_value, 10);

        if (end > start) {
          if (start >= 0 && end <= 10) step = 5;
          if (start >= 0 && end >= 10 && end <= 100) step = 50;
          if (start >= 0 && end >= 100 && end <= 1000) step = 100;
          if (start >= 0 && end >= 1000 && end <= 5000) step = 500;
          if (start >= 0 && end >= 5000 && end <= 10000) step = 1000;
          if (start >= 0 && end >= 10000 && end <= 50000) step = 5000;
          if (start >= 0 && end >= 50000 && end <= 100000) step = 10000;
          if (start >= 0 && end >= 100000 && end <= 1000000) step = 100000;
          if (start >= 0 && end >= 1000000 && end <= 10000000) step = 500000;
          if (start >= 0 && end >= 100000000 && end <= 1000000000) step = 10000000;
          if (start >= 0 && end >= 1000000000 && end >= 1000000000) step = 100000000; // maximum check is if number is above 1 billion

          if (start > 0) NumberList.push(start);

          while (start < end) {
            start = start + step;
            NumberList.push(start);
          }
        } else {
          if (end >= 0 && start <= 10) step = 5;
          if (end >= 0 && start >= 10 && start <= 100) step = 50;
          if (end >= 0 && start >= 100 && start <= 1000) step = 100;
          if (end >= 0 && start >= 1000 && start <= 5000) step = 500;
          if (end >= 0 && start >= 5000 && start <= 10000) step = 1000;
          if (end >= 0 && start >= 10000 && start <= 50000) step = 5000;
          if (end >= 0 && start >= 50000 && start <= 100000) step = 10000;
          if (end >= 0 && start >= 100000 && start <= 1000000) step = 100000;
          if (end >= 0 && start >= 1000000 && start <= 10000000) step = 500000;
          if (end >= 0 && start >= 10000000 && start <= 1000000000) step = 10000000;
          if (end >= 0 && start >= 1000000000 && start >= 1000000000) step = 100000000; // maximum check is if number is above 1 billion

          if (end > 0) NumberList.push(end);

          while (end < start) {
            end = end + step;
            NumberList.push(end);
          }
        }

        return NumberList;
      } catch (error) {
        return [start_value, end_value]; // return a base array with the start and end numbers if the process fails
      }
    }
  }

  RoundToDecimalPlaces(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
  }

  ReplaceTextInString(full_text, old_text, new_text) {
    return full_text.replace(new RegExp(old_text, 'gi'), new_text);
  }
}
