import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';




class EditExporterCategory extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateExporterCategory = this.updateExporterCategory.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getExporterCategory = this.getExporterCategory.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            category_name: '',
            category_description: '',
            category_level: '',
            hash_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        
        this.getProfile();
        this.checkAccessRight();
        this.getExporterCategory();
        this.setState({ preloader: null });


    }



    componentDidUpdate() {

    }


    componentWillUnmount() {

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:categories', 'action:categories'], 'view') === false)) {
            this.props.history.replace('/categorisation/exporter');
        }

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getExporterCategory() {

        let exporterCategoryId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/categorisation/exporter/${exporterCategoryId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({
                    category_name: response.data.data[0].category_name || '',
                    category_description: response.data.data[0].category_description || '',
                    category_level: response.data.data[0].category_level || '',
                    hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                    document.title = this.Language.PageTitle.EditExporterCategory.BeginningPhrase + ' ' +this.state.category_name + ' ' + this.Language.PageTitle.EditExporterCategory.EndPhrase + ' - ' + this.Language.EEGWebPortal;
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveExporterCategory} ${this.refs.category_name.value.trim()}`)) {

            let category_name = this.refs.category_name.value;
            let category_description = this.refs.category_description.value;
            let category_level = this.refs.category_level.value.trim();


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";


            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ExporterCategory.EditErrorTitle, [category_name, category_description, category_description, category_level, category_level], [this.Language.DataField.CategoryName, this.Language.DataField.Description, this.Language.DataField.Description, this.Language.DataField.CategoryLevel, this.Language.DataField.CategoryLevel], ["isRequired", "isRequired", "maxLength", "isRequired", "isNumber"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {



                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newExporterCategory = {
                        category_name: category_name,
                        category_description: category_description,
                        category_level: Math.abs(category_level) || null,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_exporter_category_button").setAttribute("disabled", true);

                    this.updateExporterCategory(newExporterCategory);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }






    updateExporterCategory(newExporterCategory) {

        let exporterCategoryId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/categorisation/exporter/${exporterCategoryId}`,
            data: newExporterCategory
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.UpdateSuccess + newExporterCategory.category_name, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_exporter_category_button").removeAttribute("disabled");

            this.props.history.push('/categorisation/exporter');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_exporter_category_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_exporter_category_button").setAttribute("disabled", true);


        let category_name = this.state.category_name;
        let logged_in_username = this.state.logged_in_username;
        let exporterCategoryId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteExporterCategory = {
            category_name: category_name,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/categorisation/exporter/${exporterCategoryId}`,
            data: deleteExporterCategory
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.DeleteSuccess + deleteExporterCategory.category_name, 8000, 'rounded green');
            })


            document.getElementById("delete_exporter_category_button").removeAttribute("disabled");
            this.props.history.replace('/categorisation/exporter');

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExporterCategory.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_exporter_category_button").removeAttribute("disabled");

            });

    }




    render() {


        // let exporterCategoryId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Categorisation"} active_menu_level_2={"Exporter Categorisation"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditExporterCategory.BeginningPhrase} ${this.state.category_name} ${this.Language.PageTitle.EditExporterCategory.EndPhrase}`} breadcrumbs={[this.Language.BreadCrumbLabel.Categorisation, this.Language.BreadCrumbLabel.ExporterCategories, this.Language.BreadCrumbLabel.EditExporterCategory]} links={[`/`, `/categorisation/exporter/`, `/categorisation/exporter/edit`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditExporterCategory}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>

                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="category_name" type="text" ref="category_name" value={this.state.category_name} onChange={this.handleInputChange} />
                                                            <label htmlFor="category_name" className={(this.state.category_name && this.state.category_name !== null && typeof this.state.category_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.CategoryName} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="category_level" type="number" className="validate" ref="category_level" value={this.state.category_level} onChange={this.handleInputChange} />
                                                            <label htmlFor="category_level" data-error={this.Language.DataFieldError.IntegerNumber} className={(this.state.category_level && this.state.category_level !== null && typeof this.state.category_level !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.CategoryLevel} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="category_description" className="materialize-textarea" data-length={this.state.text_limit} ref="category_description" value={this.state.category_description} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="category_description" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.category_description && this.state.category_description !== null && typeof this.state.category_description !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateExporterCategory}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateExporterCategory}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_exporter_category_button">
                                                                {this.Language.ButtonLabel.UpdateExporterCategory}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s4">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/categorisation/exporter`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_exporter_category_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteExporterCategory} ${this.state.category_name}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditExporterCategory;