import LanguageService from './LanguageService';

const getTranslation = LanguageService.prototype.getTranslation();
const language = getTranslation.default;

export default class DateService {

    convertSecondstoPeriodSince(seconds_value) { // Get the time period since the event has happened

        let years, months, weeks, days, hours, minutes, seconds = 0;
        let remainder = seconds_value;


        if (remainder > 31557600) {
            years = Math.floor((remainder / 31557600));
            remainder = (remainder % 31557600);
        };


        if (remainder > (86400 * 30)) {
            months = Math.floor((remainder / (86400 * 30)));
            remainder = (remainder % (86400 * 30));
        }


        if (remainder > (86400 * 7)) {
            weeks = Math.floor((remainder / (86400 * 7)));
            remainder = (remainder % (86400 * 7));
        }


        if (remainder > (86400)) {
            days = Math.floor((remainder / (86400)));
            remainder = (remainder % (86400));
        }


        if (remainder > (3600)) {
            hours = Math.floor((remainder / (3600)));
            remainder = (remainder % (3600));
        }


        if (remainder > (60)) {
            minutes = Math.floor((remainder / (60)));
            remainder = (remainder % (60));
        }


        if (remainder < (60)) {
            seconds = remainder;
            remainder = 0;
        }


        let time_phrase = language.TimePhrase.AMomentAgo;

        if (years === 1) {
            time_phrase = language.TimePhrase.AboutAYearAgo;
        } else if (years > 0) {
            time_phrase = language.TimePhrase.YearsAgo.multiple.BeginningPhrase + years + language.TimePhrase.YearsAgo.multiple.EndPhrase;
        } else if (months === 1) {
            time_phrase = language.TimePhrase.MonthsAgo.one.BeginningPhrase + months + language.TimePhrase.MonthsAgo.one.EndPhrase;
        } else if (months > 0) {
            time_phrase = language.TimePhrase.MonthsAgo.multiple.BeginningPhrase + months + language.TimePhrase.MonthsAgo.multiple.EndPhrase;
        } else if (weeks === 1) {
            time_phrase = language.TimePhrase.WeeksAgo.one.BeginningPhrase + weeks + language.TimePhrase.WeeksAgo.one.EndPhrase;
        } else if (weeks > 0) {
            time_phrase = language.TimePhrase.WeeksAgo.multiple.BeginningPhrase + weeks + language.TimePhrase.WeeksAgo.multiple.EndPhrase;
        } else if (days === 1) {
            time_phrase = language.TimePhrase.DaysAgo.one.BeginningPhrase + days + language.TimePhrase.DaysAgo.one.EndPhrase;
        } else if (days > 0) {
            time_phrase = language.TimePhrase.DaysAgo.multiple.BeginningPhrase + days + language.TimePhrase.DaysAgo.multiple.EndPhrase;
        } else if (hours === 1) {
            time_phrase = language.TimePhrase.HoursAgo.one.BeginningPhrase + hours + language.TimePhrase.HoursAgo.one.EndPhrase;
        } else if (hours > 0) {
            time_phrase = language.TimePhrase.HoursAgo.multiple.BeginningPhrase + hours + language.TimePhrase.HoursAgo.multiple.EndPhrase;
        } else if (minutes === 1) {
            time_phrase = language.TimePhrase.MinutesAgo.one.BeginningPhrase + minutes + language.TimePhrase.MinutesAgo.one.EndPhrase;
        } else if (minutes > 0) {
            time_phrase = language.TimePhrase.MinutesAgo.multiple.BeginningPhrase + minutes + language.TimePhrase.MinutesAgo.multiple.EndPhrase;
        } else if (seconds === 1) {
            time_phrase = language.TimePhrase.SecondsAgo.one.BeginningPhrase + seconds + language.TimePhrase.SecondsAgo.one.EndPhrase;
        } else if (seconds > 0) {
            time_phrase = language.TimePhrase.SecondsAgo.multiple.BeginningPhrase + seconds + language.TimePhrase.SecondsAgo.multiple.EndPhrase;
        } else {
            time_phrase = language.TimePhrase.AMomentAgo;
        }

        return time_phrase;


    }


    convertSecondstoDays(seconds) {

        if (seconds !== null && seconds !== '' && typeof seconds !== 'undefined' && (!isNaN(parseFloat(seconds)) && isFinite(seconds)) === true) {
            return Math.round(parseInt(seconds, 10) / 86400);
        } else {
            return seconds;
        }

    }


    convertToFullDate(dateValue) { // Convert from short date 30 April, 2018 to postgresql date 2018-04-30 00:00:00+00

        let defaultDate = language.Calendar.DefaultDate;

        try {


            if (dateValue !== null && dateValue !== "") {
                let dateValueArray = dateValue.split(" ");

                let dayPart = dateValueArray[0];
                let monthPart = dateValueArray[1].replace(/,/g, '');
                let yearPart = dateValueArray[2];

                if (dayPart.length === 1) dayPart = '0' + dayPart;

                switch (monthPart) {
                    case language.Calendar.MonthsFull.January:
                    case "January":
                        monthPart = "01";
                        break;

                    case language.Calendar.MonthsFull.February:
                    case "February":
                        monthPart = "02";
                        break;

                    case language.Calendar.MonthsFull.March:
                    case "March":
                        monthPart = "03";
                        break;

                    case language.Calendar.MonthsFull.April:
                    case "April":
                        monthPart = "04";
                        break;

                    case language.Calendar.MonthsFull.May:
                    case "May":
                        monthPart = "05";
                        break;

                    case language.Calendar.MonthsFull.June:
                    case "June":
                        monthPart = "06";
                        break;

                    case language.Calendar.MonthsFull.July:
                    case "July":
                        monthPart = "07";
                        break;

                    case language.Calendar.MonthsFull.August:
                    case "August":
                        monthPart = "08";
                        break;

                    case language.Calendar.MonthsFull.September:
                    case "September":
                        monthPart = "09";
                        break;

                    case language.Calendar.MonthsFull.October:
                    case "October":
                        monthPart = "10";
                        break;

                    case language.Calendar.MonthsFull.November:
                    case "November":
                        monthPart = "11";
                        break;

                    case language.Calendar.MonthsFull.December:
                    case "December":
                        monthPart = "12";
                        break;

                    default:
                        monthPart = "01";
                }


                let convertedDate = yearPart + '-' + monthPart + '-' + dayPart + ' 00:00:00+00'

                return convertedDate;


            } else {
                return defaultDate;
            }


        }
        catch (error) {
            return defaultDate;
        }
    }


    convertToCalendarFullDate(dateValue) { // Convert from postgresql date 2018-04-08 00:00:00+00 or Sun Apr 08 2018 00:00:00 GMT+0100 (W. Central africa Standard Time) or 8 April 2018 to short date 8 April, 2018 

        //This is usually used for GET API calls where the date is not already be pre-formated from the database in a particular format.
        //This is used for formating the date to be populated into a Date Input Field

        // let defaultDate = "1970-01-01 00:00:00+00";
        let defaultDate = language.Calendar.DefaultDateShort;

        try {

            if (dateValue !== null && dateValue !== "" && typeof dateValue !== 'undefined') {

                const dateValue_temp = dateValue.substring(0, 1);

                // Convert this type of date - Sun Apr 08 2018 00:00:00 GMT+0100 (W. Central africa Standard Time)
                if (dateValue_temp === "S" || dateValue_temp === "M" || dateValue_temp === "T" || dateValue_temp === "W" || dateValue_temp === "F") {

                    let dateValueArray = dateValue.split(" ");
                    let dayPart = dateValueArray[2];
                    let monthPart = dateValueArray[1];
                    let yearPart = dateValueArray[3];

                    if (dayPart.substring(0, 1) === "0") dayPart = dayPart.substring(1, 2);


                    switch (monthPart) {
                        case language.Calendar.MonthsShort.Jan:
                        case "Jan":
                            monthPart = language.Calendar.MonthsFull.January;
                            break;

                        case language.Calendar.MonthsShort.Feb:
                        case "Feb":
                            monthPart = language.Calendar.MonthsFull.February;
                            break;

                        case language.Calendar.MonthsShort.Mar:
                        case "Mar":
                            monthPart = language.Calendar.MonthsFull.March;
                            break;

                        case language.Calendar.MonthsShort.Apr:
                        case "Apr":
                            monthPart = language.Calendar.MonthsFull.April;
                            break;

                        case language.Calendar.MonthsShort.May:
                        case "May":
                            monthPart = language.Calendar.MonthsFull.May;
                            break;

                        case language.Calendar.MonthsShort.Jun:
                        case "Jun":
                            monthPart = language.Calendar.MonthsFull.June;
                            break;

                        case language.Calendar.MonthsShort.Jul:
                        case "Jul":
                            monthPart = language.Calendar.MonthsFull.July;
                            break;

                        case language.Calendar.MonthsShort.Aug:
                        case "Aug":
                            monthPart = language.Calendar.MonthsFull.August;
                            break;

                        case language.Calendar.MonthsShort.Sep:
                        case "Sep":
                            monthPart = language.Calendar.MonthsFull.September;
                            break;

                        case language.Calendar.MonthsShort.Oct:
                        case "Oct":
                            monthPart = language.Calendar.MonthsFull.October;
                            break;

                        case language.Calendar.MonthsShort.Nov:
                        case "Nov":
                            monthPart = language.Calendar.MonthsFull.November;
                            break;

                        case language.Calendar.MonthsShort.Dec:
                        case "Dec":
                            monthPart = language.Calendar.MonthsFull.December;
                            break;

                        default:
                            monthPart = language.Calendar.MonthsFull.January;

                    }

                    let convertedDate = dayPart + ' ' + monthPart + ', ' + yearPart
                    return convertedDate;


                } else if (dateValue.substring(4, 5) === "-" && dateValue.substring(7, 8) === "-") {

                    let dayPart = dateValue.substring(8, 10);
                    let monthPart = dateValue.substring(5, 7);
                    let yearPart = dateValue.substring(0, 4);

                    if (dayPart.substring(0, 1) === "0") dayPart = dayPart.substring(1, 2);


                    switch (monthPart) {
                        case '01':
                            monthPart = language.Calendar.MonthsFull.January;
                            break;

                        case '02':
                            monthPart = language.Calendar.MonthsFull.February;
                            break;

                        case '03':
                            monthPart = language.Calendar.MonthsFull.March;
                            break;

                        case '04':
                            monthPart = language.Calendar.MonthsFull.April;
                            break;

                        case '05':
                            monthPart = language.Calendar.MonthsFull.May;
                            break;

                        case '06':
                            monthPart = language.Calendar.MonthsFull.June;
                            break;

                        case '07':
                            monthPart = language.Calendar.MonthsFull.July;
                            break;

                        case '08':
                            monthPart = language.Calendar.MonthsFull.August;
                            break;

                        case '09':
                            monthPart = language.Calendar.MonthsFull.September;
                            break;

                        case '10':
                            monthPart = language.Calendar.MonthsFull.October;
                            break;

                        case '11':
                            monthPart = language.Calendar.MonthsFull.November;
                            break;

                        case '12':
                            monthPart = language.Calendar.MonthsFull.December;
                            break;

                        default:
                            monthPart = language.Calendar.MonthsFull.January;
                    }

                    let convertedDate = dayPart + ' ' + monthPart + ', ' + yearPart
                    return convertedDate;


                } else { // 8 April 2018

                    let dateValueArray = dateValue.split(" ");
                    let dayPart = dateValueArray[0];
                    let monthPart = dateValueArray[1];
                    let yearPart = dateValueArray[dateValueArray.length - 1]; // get the last element in the array for the year part


                    const monthPart_temp = monthPart.substring(0, 1).toUpperCase();

                    if (monthPart_temp === "J" || monthPart_temp === "F" || monthPart_temp === "M" || monthPart_temp === "A" || monthPart_temp === "S" || monthPart_temp === "O" || monthPart_temp === "N" || monthPart_temp === "D") {


                        switch (monthPart) {
                            case language.Calendar.MonthsFull.January:
                            case language.Calendar.MonthsShort.Jan:
                            case "January":
                            case "Jan":
                                monthPart = language.Calendar.MonthsFull.January;
                                break;

                            case language.Calendar.MonthsFull.February:
                            case language.Calendar.MonthsShort.Feb:
                            case "February":
                            case "Feb":
                                monthPart = language.Calendar.MonthsFull.February;
                                break;

                            case language.Calendar.MonthsFull.March:
                            case language.Calendar.MonthsShort.Mar:
                            case "March":
                            case "Mar":
                                monthPart = language.Calendar.MonthsFull.March;
                                break;

                            case language.Calendar.MonthsFull.April:
                            case language.Calendar.MonthsShort.Apr:
                            case "April":
                            case "Apr":
                                monthPart = language.Calendar.MonthsFull.April;
                                break;

                            case language.Calendar.MonthsFull.May:
                            case language.Calendar.MonthsShort.May:
                            case "May":
                                monthPart = language.Calendar.MonthsFull.May;
                                break;

                            case language.Calendar.MonthsFull.June:
                            case language.Calendar.MonthsShort.Jun:
                            case "June":
                            case "Jun":
                                monthPart = language.Calendar.MonthsFull.June;
                                break;

                            case language.Calendar.MonthsFull.July:
                            case language.Calendar.MonthsShort.Jul:
                            case "July":
                            case "Jul":
                                monthPart = language.Calendar.MonthsFull.July;
                                break;

                            case language.Calendar.MonthsFull.August:
                            case language.Calendar.MonthsShort.Aug:
                            case "August":
                            case "Aug":
                                monthPart = language.Calendar.MonthsFull.August;
                                break;

                            case language.Calendar.MonthsFull.September:
                            case language.Calendar.MonthsShort.Sep:
                            case "September":
                            case "Sep":
                                monthPart = language.Calendar.MonthsFull.September;
                                break;

                            case language.Calendar.MonthsFull.October:
                            case language.Calendar.MonthsShort.Oct:
                            case "October":
                            case "Oct":
                                monthPart = language.Calendar.MonthsFull.October;
                                break;

                            case language.Calendar.MonthsFull.November:
                            case language.Calendar.MonthsShort.Nov:
                            case "November":
                            case "Nov":
                                monthPart = language.Calendar.MonthsFull.November;
                                break;

                            case language.Calendar.MonthsFull.December:
                            case language.Calendar.MonthsShort.Dec:
                            case "December":
                            case "Dec":
                                monthPart = language.Calendar.MonthsFull.December;
                                break;

                            default:
                                monthPart = language.Calendar.MonthsFull.January;

                        }

                        let convertedDate = dayPart + ' ' + monthPart + ', ' + yearPart
                        return convertedDate;

                    } else {

                        return dateValue; // consider changing this back to defaultDate latter

                    }

                }


            } else {
                return defaultDate;
            }

        }
        catch (error) {
            return defaultDate;
        }

    }


    convertToDisplayDate(dateValue) {

        //This is the logic for conversions of dates like this - Monday , 12 Feb 2018 12:23:21 am.
        //This is usually used for GET API calls where the date is expected to already be pre-formated from the database in a particular format
        //This is used for formating the date to be displayed for the user. For display either in a data table, form or just in a page.

        // @date_option possible values
        // 'normal_date' = 17 July 2018
        // 'short_date' = 17/07/2018 - On a second look there is nothing to convert here. Maybe just consider re-ordering the day and month.
        // 'short_date_time' = 17/07/2018 08:17:24 pm - On a second look there is nothing to convert here. Maybe just consider re-ordering the day and month.
        // 'long_date_time' =  Tuesday , 31 Jul 2018 08:17:24 pm
        // 'full_date_time' =  Tuesday , 31 July 2018 08:17:24 pm

        let defaultDate = language.Calendar.DefaultDateShort;


        try {

            if (dateValue !== null && dateValue !== "" && typeof dateValue !== 'undefined') {

                const dateValue_temp = dateValue.substring(0, 1);

                // Convert this type of date - Tuesday , 31 Jul 2018 08:17:24 pm or Tuesday , 31 July 2018 08:17:24 pm
                if (dateValue_temp === "S" || dateValue_temp === "M" || dateValue_temp === "T" || dateValue_temp === "W" || dateValue_temp === "F") {


                    let dateValueArray1 = dateValue.split(",");
                    let weekDayPart = dateValueArray1[0].trim();

                    let dateValueArray2 = dateValueArray1[1].trim().split(" ");

                    let dayPart = dateValueArray2[0] || '';
                    let monthPart = dateValueArray2[1] || '';
                    let yearPart = dateValueArray2[2] || '';
                    let timePart = dateValueArray2[3] || '';
                    let periodPart = dateValueArray2[4] || '';

                    if (dayPart.length === 1) dayPart = '0' + dayPart; // add leading zero to the day part


                    switch (weekDayPart) {
                        case language.Calendar.weekdaysFull.Sunday:
                        case "Sunday":
                        case "sunday":
                        case "SUNDAY":
                            weekDayPart = language.Calendar.weekdaysFull.Sunday;
                            break;

                        case language.Calendar.weekdaysFull.Monday:
                        case "Monday":
                        case "monday":
                        case "MONDAY":
                            weekDayPart = language.Calendar.weekdaysFull.Monday;
                            break;

                        case language.Calendar.weekdaysFull.Tuesday:
                        case "Tuesday":
                        case "tuesday":
                        case "TUESDAY":
                            weekDayPart = language.Calendar.weekdaysFull.Tuesday;
                            break;

                        case language.Calendar.weekdaysFull.Wednesday:
                        case "Wednesday":
                        case "wednesday":
                        case "WEDNESDAY":
                            weekDayPart = language.Calendar.weekdaysFull.Wednesday;
                            break;

                        case language.Calendar.weekdaysFull.Thursday:
                        case "Thursday":
                        case "thursday":
                        case "THURSDAY":
                            weekDayPart = language.Calendar.weekdaysFull.Thursday;
                            break;

                        case language.Calendar.weekdaysFull.Friday:
                        case "Friday":
                        case "friday":
                        case "FRIDAY":
                            weekDayPart = language.Calendar.weekdaysFull.Friday;
                            break;

                        case language.Calendar.weekdaysFull.Saturday:
                        case "Saturday":
                        case "saturday":
                        case "SATURDAY":
                            weekDayPart = language.Calendar.weekdaysFull.Saturday;
                            break;


                        default:
                            weekDayPart = language.Calendar.weekdaysFull.Thursday;

                    }



                    switch (monthPart) {
                        case language.Calendar.MonthsFull.January:
                        case language.Calendar.MonthsShort.Jan:
                        case "January":
                        case "Jan":
                            monthPart = language.Calendar.MonthsFull.January;
                            break;

                        case language.Calendar.MonthsFull.February:
                        case language.Calendar.MonthsShort.Feb:
                        case "February":
                        case "Feb":
                            monthPart = language.Calendar.MonthsFull.February;
                            break;

                        case language.Calendar.MonthsFull.March:
                        case language.Calendar.MonthsShort.Mar:
                        case "March":
                        case "Mar":
                            monthPart = language.Calendar.MonthsFull.March;
                            break;

                        case language.Calendar.MonthsFull.April:
                        case language.Calendar.MonthsShort.Apr:
                        case "April":
                        case "Apr":
                            monthPart = language.Calendar.MonthsFull.April;
                            break;

                        case language.Calendar.MonthsFull.May:
                        case language.Calendar.MonthsShort.May:
                        case "May":
                            monthPart = language.Calendar.MonthsFull.May;
                            break;

                        case language.Calendar.MonthsFull.June:
                        case language.Calendar.MonthsShort.Jun:
                        case "June":
                        case "Jun":
                            monthPart = language.Calendar.MonthsFull.June;
                            break;

                        case language.Calendar.MonthsFull.July:
                        case language.Calendar.MonthsShort.Jul:
                        case "July":
                        case "Jul":
                            monthPart = language.Calendar.MonthsFull.July;
                            break;

                        case language.Calendar.MonthsFull.August:
                        case language.Calendar.MonthsShort.Aug:
                        case "August":
                        case "Aug":
                            monthPart = language.Calendar.MonthsFull.August;
                            break;

                        case language.Calendar.MonthsFull.September:
                        case language.Calendar.MonthsShort.Sep:
                        case "September":
                        case "Sep":
                            monthPart = language.Calendar.MonthsFull.September;
                            break;

                        case language.Calendar.MonthsFull.October:
                        case language.Calendar.MonthsShort.Oct:
                        case "October":
                        case "Oct":
                            monthPart = language.Calendar.MonthsFull.October;
                            break;

                        case language.Calendar.MonthsFull.November:
                        case language.Calendar.MonthsShort.Nov:
                        case "November":
                        case "Nov":
                            monthPart = language.Calendar.MonthsFull.November;
                            break;

                        case language.Calendar.MonthsFull.December:
                        case language.Calendar.MonthsShort.Dec:
                        case "December":
                        case "Dec":
                            monthPart = language.Calendar.MonthsFull.December;
                            break;

                        default:
                            monthPart = language.Calendar.MonthsFull.January;

                    }



                    switch (periodPart) {
                        case "AM":
                        case language.DataValue.AM:
                            periodPart = language.DataValue.AM;
                            break;

                        case "am":
                        case language.DataValue.am:
                            periodPart = language.DataValue.am;
                            break;

                        case "PM":
                        case language.DataValue.PM:
                            periodPart = language.DataValue.PM;
                            break;

                        case "pm":
                        case language.DataValue.pm:
                            periodPart = language.DataValue.pm;
                            break;

                        default:
                        // DO nothing

                    }




                    switch (language.TimeFormat) {
                        case "h12":
                        case "H12":
                        case "12":
                            let timePart_temp_array1 = timePart.split(":");
                            timePart = timePart_temp_array1[0] + language.HoursTimeFormatSeperatorFirstPart12 + timePart_temp_array1[1] + language.HoursTimeFormatSeperatorSecondPart12 + timePart_temp_array1[2];
                            break;

                        case "h24":
                        case "H24":
                        case "24":
                            let timePart_temp_array2 = timePart.split(":");
                            let timePart_temp = timePart_temp_array2[0];
                            timePart_temp = parseInt(timePart_temp, 10) + 12 // add twelve hours to the time to make it show in 24hours format
                            if (timePart_temp >= 24) timePart_temp = '00'; // force to 00 if its 12 midnight and above 
                            timePart = timePart_temp + language.HoursTimeFormatSeperatorFirstPart24 + timePart_temp_array2[1] + language.HoursTimeFormatSeperatorSecondPart24 + timePart_temp_array2[2];
                            periodPart = '';
                            break;

                        default:
                        // DO nothing

                    }



                    //Tuesday , 31 Jul 2018 08:17:24 pm or Tuesday , 31 July 2018 08:17:24 pm
                    let convertedDate = weekDayPart + ', ' + dayPart + ' ' + monthPart + ' ' + yearPart + ' ' + timePart + ' ' + periodPart
                    convertedDate = convertedDate.trim();

                    return convertedDate;


                } else if (dateValue_temp === "0" || dateValue_temp === "1" || dateValue_temp === "2" || dateValue_temp === "3" || dateValue_temp === "4" || dateValue_temp === "5" || dateValue_temp === "6" || dateValue_temp === "7" || dateValue_temp === "8" || dateValue_temp === "9") {

                    // Convert this type of date - 17 July 2018

                    let dateValueArray = dateValue.trim().split(" ");
                    let dayPart = dateValueArray[0].trim() || '';
                    let monthPart = dateValueArray[1].trim() || '';
                    let yearPart = dateValueArray.pop() || ''; // get the last item from the array

                    if (dayPart.length === 1) dayPart = '0' + dayPart; // add leading zero to the day part


                    switch (monthPart) {
                        case language.Calendar.MonthsFull.January:
                        case language.Calendar.MonthsShort.Jan:
                        case "January":
                        case "Jan":
                            monthPart = language.Calendar.MonthsFull.January;
                            break;

                        case language.Calendar.MonthsFull.February:
                        case language.Calendar.MonthsShort.Feb:
                        case "February":
                        case "Feb":
                            monthPart = language.Calendar.MonthsFull.February;
                            break;

                        case language.Calendar.MonthsFull.March:
                        case language.Calendar.MonthsShort.Mar:
                        case "March":
                        case "Mar":
                            monthPart = language.Calendar.MonthsFull.March;
                            break;

                        case language.Calendar.MonthsFull.April:
                        case language.Calendar.MonthsShort.Apr:
                        case "April":
                        case "Apr":
                            monthPart = language.Calendar.MonthsFull.April;
                            break;

                        case language.Calendar.MonthsFull.May:
                        case language.Calendar.MonthsShort.May:
                        case "May":
                            monthPart = language.Calendar.MonthsFull.May;
                            break;

                        case language.Calendar.MonthsFull.June:
                        case language.Calendar.MonthsShort.Jun:
                        case "June":
                        case "Jun":
                            monthPart = language.Calendar.MonthsFull.June;
                            break;

                        case language.Calendar.MonthsFull.July:
                        case language.Calendar.MonthsShort.Jul:
                        case "July":
                        case "Jul":
                            monthPart = language.Calendar.MonthsFull.July;
                            break;

                        case language.Calendar.MonthsFull.August:
                        case language.Calendar.MonthsShort.Aug:
                        case "August":
                        case "Aug":
                            monthPart = language.Calendar.MonthsFull.August;
                            break;

                        case language.Calendar.MonthsFull.September:
                        case language.Calendar.MonthsShort.Sep:
                        case "September":
                        case "Sep":
                            monthPart = language.Calendar.MonthsFull.September;
                            break;

                        case language.Calendar.MonthsFull.October:
                        case language.Calendar.MonthsShort.Oct:
                        case "October":
                        case "Oct":
                            monthPart = language.Calendar.MonthsFull.October;
                            break;

                        case language.Calendar.MonthsFull.November:
                        case language.Calendar.MonthsShort.Nov:
                        case "November":
                        case "Nov":
                            monthPart = language.Calendar.MonthsFull.November;
                            break;

                        case language.Calendar.MonthsFull.December:
                        case language.Calendar.MonthsShort.Dec:
                        case "December":
                        case "Dec":
                            monthPart = language.Calendar.MonthsFull.December;
                            break;

                        default:
                            monthPart = language.Calendar.MonthsFull.January;

                    }


                    //17 July 2018
                    let convertedDate = dayPart + ' ' + monthPart + ' ' + yearPart
                    convertedDate = convertedDate.trim();

                    return convertedDate;



                } else {

                    return dateValue; // This returns the value passed in as is. You can consider changing this back to defaultDate later if really required.

                }

            } else {
                return defaultDate;
            }

        }
        catch (error) {
            return defaultDate;
        }

    }


    convertToFullTime(timeValue) {
        // Convert from short time 12:35PM to postgresql time 12:35:00+00

        let defaultTime = language.Calendar.DefaultTime;

        try {
            if (timeValue !== null && timeValue !== '') {
            let timeValueArray = timeValue.split(':');

            let hourPart = timeValueArray[0];
            hourPart = parseInt(hourPart, 10);
            let minutePart = timeValueArray[1].substring(0, 2);
            minutePart = parseInt(minutePart, 10);
            let timePeriodPart = timeValueArray[1].substring(2);

            if (timePeriodPart === 'PM') hourPart = hourPart + 12;


            let convertedTime = hourPart + ':' + minutePart + ':00';

            return convertedTime;
            } else {
            return defaultTime;
            }
        } catch (error) {
            return defaultTime;
        }


    }


    removeInvalidDateValue(date_value) {

        let new_date_value = date_value + ''; // force the value to be string;

        const invalid_dates = ['20 November, 1969', '20 November  1969', '31 December, 1969', '19 November, 1969', '01 January, 1970', '01 January 1970', '01 January   1970', '1 January, 1970', '20 November, 1970', '20 November 1970', '01 January, 1970'];
        const len = invalid_dates.length;

        for (let i = 0; i < len; i++) {
            if (new_date_value === invalid_dates[i] || new_date_value === null || new_date_value === 'null' || new_date_value === '' || typeof new_date_value === 'undefined') new_date_value = '';
        }

        return new_date_value

    }



    getUserTimeZoneKeyValue(use_default_timezone) {
        try {

            let time_zone_key = this.getUserTimeZone(use_default_timezone);

            time_zone_key = time_zone_key.split("***"); // extract the result into its constituent parts
            time_zone_key = time_zone_key[0] || time_zone_key || 'UTC';

            return time_zone_key;
        }
        catch (error) {
            return 'UTC';
        }
    }


    setUserTimeZone(user_time_zone_value) {

        let TimeZone = 'UTC***(GMT+00:00) Coordinated Universal Time - UTC***UTC';
        // Europe/Busingen***(GMT+02:00) Central European Summer Time - Busingen***CEST

        try {

            if (user_time_zone_value !== null && typeof user_time_zone_value !== 'undefined' && user_time_zone_value !== 'undefined') {

                TimeZone = user_time_zone_value;
                sessionStorage.setItem('ep_timezone', TimeZone);

            }

        }
        catch (error) {
            sessionStorage.setItem('ep_timezone', TimeZone);
        }


    }




    getUserTimeZone(use_default_timezone) { // Get the timezone of the user. Check session storage first then check the browser for the timezoe else check the token for the default timezone if use default is indicated 

        let userTimezone = 'UTC'; //default system timezone to UTC / GMT
        let userTimezoneCity = userTimezone;
        let userTimezoneAbbrev = 'UTC';
        let userTimezoneName = 'Coordinated Universal Time';
        let userTimezoneOffset = '+00';
        let TimeZone = userTimezone + '***(GMT' + userTimezoneOffset + ':00) ' + userTimezoneName + ' - ' + userTimezoneCity + '***' + userTimezoneAbbrev;
        // Europe/Busingen***(GMT+02:00) Central European Summer Time - Busingen***CEST


        if (use_default_timezone === true) { // check the default timezone in the token if the default flag is set to true else go through the check process > SessionStorage > Browser > Token > Default values


            let token = sessionStorage.getItem('ep_token');
            let payload = this.decode(token);
            userTimezone = payload.time_zone_region;


            if (userTimezone !== null && typeof userTimezone !== 'undefined' && userTimezone !== 'undefined') {

                TimeZone = userTimezone;

                sessionStorage.setItem('ep_timezone', TimeZone);
                return TimeZone;

            } else {
                throw new Error(); // this forces the execution to move to the catch section of this try-catch
            }

        } else {

            try { // check the session storage for the user timezone
                userTimezone = sessionStorage.getItem('ep_timezone');

                if (userTimezone !== null && typeof userTimezone !== 'undefined' && userTimezone !== 'undefined') {

                    TimeZone = userTimezone;

                    return TimeZone;

                } else {
                    throw new Error(); // this forces the execution to move to the catch section of this try-catch
                }

            }
            catch (error) {

                try { // check the browser for the user timezone
                    // const system_date = new Date();
                    // const timezone_offset = system_date.getTimezoneOffset() / 60; // offset in hours WAT will be  -1. // This method returns the time zone difference, in minutes, from current locale (host system settings) to UTC.

                    const userRegion = new Intl.DateTimeFormat();
                    const options = userRegion.resolvedOptions();

                    userTimezone = options.timeZone; // this will give the tiemzone name or short name see comments below for sample outputs


                    // options.locale;          // "en-US"
                    // options.calendar;        // "gregory"
                    // options.numberingSystem; // "latn"
                    // options.timeZone;        // "Africa/Lagos"
                    // options.month;           // "numeric"


                    if (userTimezone !== null && typeof userTimezone !== 'undefined' && userTimezone !== 'undefined') {

                        userTimezoneAbbrev = this.getTimezoneAbbrev(userTimezone); // get the timezone abbreviation

                        TimeZone = userTimezone + '***' + userTimezoneAbbrev
                        sessionStorage.setItem('ep_timezone', TimeZone);
                        return TimeZone;

                    } else {
                        throw new Error(); // this forces the execution to move to the catch section of this try-catch
                    }

                }
                catch (error) { // if all previous options fail, check the token again before sending back the default timezone of UTC (GMT)

                    try {

                        let token = sessionStorage.getItem('ep_token');
                        let payload = this.decode(token);
                        userTimezone = payload.time_zone_region;


                        if (userTimezone !== null && typeof userTimezone !== 'undefined' && userTimezone !== 'undefined') {

                            userTimezoneAbbrev = this.getTimezoneAbbrev(userTimezone); // get the timezone abbreviation

                            TimeZone = userTimezone + '***' + userTimezoneAbbrev
                            sessionStorage.setItem('ep_timezone', TimeZone);
                            return TimeZone;

                        } else {
                            throw new Error(); // this forces the execution to move to the catch section of this try-catch
                        }

                    }
                    catch (error) {


                        sessionStorage.setItem('ep_timezone', TimeZone);
                        return TimeZone;

                    }


                }

            }

        }
    }


    decode(token) {
        try {
            let payload = JSON.parse(atob(token.split('.')[1]));
            return payload;
        } catch (error) {
            return null;
        }
    }



    getTimezoneAbbrev(userTimezone) {

        let userTimezoneAbbrev = 'UTC';
        let userTimezoneName = 'Coordinated Universal Time';
        let userTimezoneOffset = '+00:00';
        let userTimezoneCity = userTimezone.split("/");
        userTimezoneCity = userTimezoneCity[1] || userTimezoneCity[0] || userTimezoneCity || 'UTC';



        switch (userTimezone) {
            // offset of 00:00:00
            case 'Africa/Abidjan':
            case 'Africa/Accra':
            case 'Africa/Bamako':
            case 'Africa/Banjul':
            case 'Africa/Bissau':
            case 'Africa/Conakry':
            case 'Africa/Dakar':
            case 'Africa/Freetown':
            case 'Africa/Lome':
            case 'Africa/Monrovia':
            case 'Africa/Nouakchott':
            case 'Africa/Ouagadougou':
            case 'Africa/Sao_Tome':
            case 'Africa/Timbuktu':
            case 'America/Danmarkshavn':
            case 'Atlantic/Reykjavik':
            case 'Atlantic/St_Helena':
            case 'Etc/GMT':
            case 'Etc/GMT+0':
            case 'Etc/GMT-0':
            case 'Etc/GMT0':
            case 'Etc/Greenwich':
            case 'GMT':
            case 'GMT+0':
            case 'GMT-0':
            case 'GMT0':
            case 'Greenwich':
            case 'Iceland':
                userTimezoneAbbrev = "GMT";
                userTimezoneName = "Greenwich Mean Time";
                userTimezoneOffset = "+00:00";
                break;

            case 'America/Scoresbysund':
            case 'Atlantic/Azores':
            case 'Etc/Universal':
            case 'Etc/UTC':
            case 'Etc/Zulu':
            case 'Universal':
            case 'UTC':
            case 'Zulu':
                userTimezoneAbbrev = "UTC";
                userTimezoneName = "Coordinated Universal Time";
                userTimezoneOffset = "+00:00";
                break;

            case 'Etc/UCT':
            case 'UCT':
                userTimezoneAbbrev = "UCT";
                userTimezoneName = "Coordinated Universal Time";
                userTimezoneOffset = "+00:00";
                break;


            // offset of 01:00:00
            case 'Etc/GMT-1':
            case '1':
                userTimezoneAbbrev = "GMT+1"; // it is stated as 1 in the timezone abbrev mapping
                userTimezoneName = "GMT+1";
                userTimezoneOffset = "+01:00";
                break;

            case 'Europe/Belfast':
            case 'Europe/Guernsey':
            case 'Europe/Isle_of_Man':
            case 'Europe/Jersey':
            case 'Europe/London':
            case 'GB':
            case 'GB-Eire':
            case 'BST':
                userTimezoneAbbrev = "BST";
                userTimezoneName = "British Summer Time";
                userTimezoneOffset = "+01:00";
                break;

            case 'Africa/Algiers':
            case 'Africa/Tunis':
                userTimezoneAbbrev = "CET";
                userTimezoneName = "Central European Time";
                userTimezoneOffset = "+01:00";
                break;

            case 'Eire':
            case 'Europe/Dublin':
                userTimezoneAbbrev = "IST";
                userTimezoneName = "Irish Standard Time";
                userTimezoneOffset = "+01:00";
                break;

            case 'Africa/Bangui':
            case 'Africa/Brazzaville':
            case 'Africa/Douala':
            case 'Africa/Kinshasa':
            case 'Africa/Lagos':
            case 'Africa/Libreville':
            case 'Africa/Luanda':
            case 'Africa/Malabo':
            case 'Africa/Ndjamena':
            case 'Africa/Niamey':
            case 'Africa/Porto-Novo':
            case 'Africa/Windhoek':
            case 'WAT':
                userTimezoneAbbrev = "WAT";
                userTimezoneName = "West Africa Time";
                userTimezoneOffset = "+01:00";
                break;

            case 'Africa/Casablanca':
            case 'Africa/El_Aaiun':
            case 'Atlantic/Canary':
            case 'Atlantic/Faeroe':
            case 'Atlantic/Faroe':
            case 'Atlantic/Madeira':
            case 'Europe/Lisbon':
            case 'Portugal':
            case 'WET':
            case 'WEST':
                userTimezoneAbbrev = "WEST";
                userTimezoneName = "Western European Summer Time";
                userTimezoneOffset = "+01:00";
                break;


            // offset of 02:00:00
            case 'Antarctica/Troll':
            case 'Etc/GMT-2':
            case '2':
                userTimezoneAbbrev = "GMT+2"; // it is stated as 2 in the timezone abbrev mapping
                userTimezoneName = "GMT+2";
                userTimezoneOffset = "+02:00";
                break;

            case 'Africa/Blantyre':
            case 'Africa/Bujumbura':
            case 'Africa/Gaborone':
            case 'Africa/Harare':
            case 'Africa/Kigali':
            case 'Africa/Lubumbashi':
            case 'Africa/Lusaka':
            case 'Africa/Maputo':
            case 'CAT':
                userTimezoneAbbrev = "CAT";
                userTimezoneName = "Central Africa Time";
                userTimezoneOffset = "+02:00";
                break;

            case 'Africa/Ceuta':
            case 'Arctic/Longyearbyen':
            case 'Atlantic/Jan_Mayen':
            case 'CET':
            case 'Europe/Amsterdam':
            case 'Europe/Andorra':
            case 'Europe/Belgrade':
            case 'Europe/Berlin':
            case 'Europe/Bratislava':
            case 'Europe/Brussels':
            case 'Europe/Budapest':
            case 'Europe/Busingen':
            case 'Europe/Copenhagen':
            case 'Europe/Gibraltar':
            case 'Europe/Ljubljana':
            case 'Europe/Luxembourg':
            case 'Europe/Madrid':
            case 'Europe/Malta':
            case 'Europe/Monaco':
            case 'Europe/Oslo':
            case 'Europe/Paris':
            case 'Europe/Podgorica':
            case 'Europe/Prague':
            case 'Europe/Rome':
            case 'Europe/San_Marino':
            case 'Europe/Sarajevo':
            case 'Europe/Skopje':
            case 'Europe/Stockholm':
            case 'Europe/Tirane':
            case 'Europe/Vaduz':
            case 'Europe/Vatican':
            case 'Europe/Vienna':
            case 'Europe/Warsaw':
            case 'Europe/Zagreb':
            case 'Europe/Zurich':
            case 'Poland':
            case 'CEST':
                userTimezoneAbbrev = "CEST";
                userTimezoneName = "Central European Summer Time";
                userTimezoneOffset = "+02:00";
                break;

            case 'Africa/Cairo':
            case 'Africa/Tripoli':
            case 'Egypt':
            case 'Europe/Kaliningrad':
            case 'Libya':
                userTimezoneAbbrev = "EET";
                userTimezoneName = "Eastern European Time";
                userTimezoneOffset = "+02:00";
                break;

            case 'MET':
            case 'MEST':
                userTimezoneAbbrev = "MEST";
                userTimezoneName = "Middle European Summer Time";
                userTimezoneOffset = "+02:00";
                break;

            case 'Africa/Johannesburg':
            case 'Africa/Maseru':
            case 'Africa/Mbabane':
            case 'SAST':
                userTimezoneAbbrev = "SAST";
                userTimezoneName = "South African Standard Time";
                userTimezoneOffset = "+02:00";
                break;



            // offset of 03:00:00
            case 'Antarctica/Syowa':
            case 'Asia/Aden':
            case 'Asia/Baghdad':
            case 'Asia/Bahrain':
            case 'Asia/Famagusta':
            case 'Asia/Istanbul':
            case 'Asia/Kuwait':
            case 'Asia/Qatar':
            case 'Asia/Riyadh':
            case 'Etc/GMT-3':
            case 'Europe/Istanbul':
            case 'Europe/Kirov':
            case 'Europe/Minsk':
            case 'Europe/Volgograd':
            case 'Turkey':
            case '3':
                userTimezoneAbbrev = "GMT+3"; // it is stated as 3 in the timezone abbrev mapping
                userTimezoneName = "GMT+3";
                userTimezoneOffset = "+03:00";
                break;

            case 'Africa/Addis_Ababa':
            case 'Africa/Asmara':
            case 'Africa/Asmera':
            case 'Africa/Dar_es_Salaam':
            case 'Africa/Djibouti':
            case 'Africa/Juba':
            case 'Africa/Kampala':
            case 'Africa/Khartoum':
            case 'Africa/Mogadishu':
            case 'Africa/Nairobi':
            case 'Indian/Antananarivo':
            case 'Indian/Comoro':
            case 'Indian/Mayotte':
            case 'EAT':
                userTimezoneAbbrev = "EAT";
                userTimezoneName = "Eastern Africa Time";
                userTimezoneOffset = "+03:00";
                break;

            case 'Asia/Amman':
            case 'Asia/Beirut':
            case 'Asia/Damascus':
            case 'Asia/Gaza':
            case 'Asia/Hebron':
            case 'Asia/Nicosia':
            case 'EET':
            case 'Europe/Athens':
            case 'Europe/Bucharest':
            case 'Europe/Chisinau':
            case 'Europe/Helsinki':
            case 'Europe/Kiev':
            case 'Europe/Mariehamn':
            case 'Europe/Nicosia':
            case 'Europe/Riga':
            case 'Europe/Sofia':
            case 'Europe/Tallinn':
            case 'Europe/Tiraspol':
            case 'Europe/Uzhgorod':
            case 'Europe/Vilnius':
            case 'Europe/Zaporozhye':
            case 'EEST':
                userTimezoneAbbrev = "EEST";
                userTimezoneName = "Eastern European Summer Time";
                userTimezoneOffset = "+03:00";
                break;

            case 'Asia/Jerusalem':
            case 'Asia/Tel_Aviv':
            case 'Israel':
            case 'IDT':
                userTimezoneAbbrev = "IDT";
                userTimezoneName = "Israel Daylight Time";
                userTimezoneOffset = "+03:00";
                break;

            case 'Europe/Moscow':
            case 'Europe/Simferopol':
            case 'W-SU':
            case 'MSK':
                userTimezoneAbbrev = "MSK";
                userTimezoneName = "Moscow Standard Time";
                userTimezoneOffset = "+03:00";
                break;




            // offset of 04:00:00
            case 'Asia/Baku':
            case 'Asia/Dubai':
            case 'Asia/Muscat':
            case 'Asia/Tbilisi':
            case 'Asia/Yerevan':
            case 'Etc/GMT-4':
            case 'Europe/Astrakhan':
            case 'Europe/Samara':
            case 'Europe/Saratov':
            case 'Europe/Ulyanovsk':
            case 'Indian/Mahe':
            case 'Indian/Mauritius':
            case 'Indian/Reunion':
                userTimezoneAbbrev = "GMT+4"; // it is stated as 4 in the timezone abbrev mapping
                userTimezoneName = "GMT+4";
                userTimezoneOffset = "+04:00";
                break;


            // offset of 04:30:00
            case 'Asia/Kabul':
            case 'Asia/Tehran':
            case 'Iran':
            case '430':
                userTimezoneAbbrev = "GMT+4:30";  // it is stated as 430 in the timezone abbrev mapping
                userTimezoneName = "GMT+4:30";
                userTimezoneOffset = "+04:30";
                break;


            // offset of 05:00:00
            case 'Antarctica/Mawson':
            case 'Asia/Aqtau':
            case 'Asia/Aqtobe':
            case 'Asia/Ashgabat':
            case 'Asia/Ashkhabad':
            case 'Asia/Atyrau':
            case 'Asia/Dushanbe':
            case 'Asia/Oral':
            case 'Asia/Samarkand':
            case 'Asia/Tashkent':
            case 'Asia/Yekaterinburg':
            case 'Etc/GMT-5':
            case 'Indian/Kerguelen':
            case 'Indian/Maldives':
            case '5':
                userTimezoneAbbrev = "GMT+5"; // it is stated as 5 in the timezone abbrev mapping
                userTimezoneName = "GMT+5";
                userTimezoneOffset = "+05:00";
                break;

            case 'Asia/Karachi':
            case 'PKT':
                userTimezoneAbbrev = "PKT";
                userTimezoneName = "Pakistan Standard Time";
                userTimezoneOffset = "+05:00";
                break;



            // offset of 05:30:00
            case 'Asia/Colombo':
            case '530':
                userTimezoneAbbrev = "GMT+5:30"; // it is stated as 530 in the timezone abbrev mapping
                userTimezoneName = "GMT+5:30";
                userTimezoneOffset = "+05:30";
                break;

            case 'Asia/Calcutta':
            case 'Asia/Kolkata':
                userTimezoneAbbrev = "IST";
                userTimezoneName = "India Standard Time";
                userTimezoneOffset = "+05:30";
                break;



            // offset of 05:45:00
            case 'Asia/Kathmandu':
            case 'Asia/Katmandu':
            case '545':
                userTimezoneAbbrev = "GMT+5:45"; // it is stated as 545 in the timezone abbrev mapping
                userTimezoneName = "GMT+5:45";
                userTimezoneOffset = "+05:45";
                break;



            // offset of 06:00:00
            case 'Antarctica/Vostok':
            case 'Asia/Almaty':
            case 'Asia/Bishkek':
            case 'Asia/Dacca':
            case 'Asia/Dhaka':
            case 'Asia/Kashgar':
            case 'Asia/Omsk':
            case 'Asia/Qyzylorda':
            case 'Asia/Thimbu':
            case 'Asia/Thimphu':
            case 'Asia/Urumqi':
            case 'Etc/GMT-6':
            case 'Indian/Chagos':
            case '6':
                userTimezoneAbbrev = "GMT+6"; // it is stated as 6 in the timezone abbrev mapping
                userTimezoneName = "GMT+6";
                userTimezoneOffset = "+06:00";
                break;


            // offset of 06:30:00
            case 'Asia/Rangoon':
            case 'Asia/Yangon':
            case 'Indian/Cocos':
            case '630':
                userTimezoneAbbrev = "GMT+6:30"; // it is stated as 630 in the timezone abbrev mapping
                userTimezoneName = "GMT+6:30";
                userTimezoneOffset = "+06:30";
                break;


            // offset of 07:00:00
            case 'Antarctica/Davis':
            case 'Asia/Bangkok':
            case 'Asia/Barnaul':
            case 'Asia/Hovd':
            case 'Asia/Ho_Chi_Minh':
            case 'Asia/Krasnoyarsk':
            case 'Asia/Novokuznetsk':
            case 'Asia/Novosibirsk':
            case 'Asia/Phnom_Penh':
            case 'Asia/Saigon':
            case 'Asia/Tomsk':
            case 'Asia/Vientiane':
            case 'Etc/GMT-7':
            case 'Indian/Christmas':
            case '7':
                userTimezoneAbbrev = "GMT+7"; // it is stated as 7 in the timezone abbrev mapping
                userTimezoneName = "GMT+7";
                userTimezoneOffset = "+07:00";
                break;

            case 'Asia/Jakarta':
            case 'Asia/Pontianak':
            case 'WIB':
                userTimezoneAbbrev = "WIB";
                userTimezoneName = "Western Indonesia Time";
                userTimezoneOffset = "+07:00";
                break;



            // offset of 08:00:00
            case 'Asia/Brunei':
            case 'Asia/Choibalsan':
            case 'Asia/Irkutsk':
            case 'Asia/Kuala_Lumpur':
            case 'Asia/Kuching':
            case 'Asia/Manila':
            case 'Asia/Singapore':
            case 'Asia/Ulaanbaatar':
            case 'Asia/Ulan_Bator':
            case 'Etc/GMT-8':
            case 'Singapore':
            case '8':
                userTimezoneAbbrev = "GMT+8"; // it is stated as 8 in the timezone abbrev mapping
                userTimezoneName = "GMT+8";
                userTimezoneOffset = "+08:00";
                break;

            case 'Australia/Perth':
            case 'Australia/West':
            case 'AWST':
                userTimezoneAbbrev = "AWST";
                userTimezoneName = "Australian Western Standard Time";
                userTimezoneOffset = "+08:00";
                break;

            case 'Asia/Chongqing':
            case 'Asia/Chungking':
            case 'Asia/Harbin':
            case 'Asia/Macao':
            case 'Asia/Macau':
            case 'Asia/Shanghai':
            case 'Asia/Taipei':
            case 'PRC':
            case 'ROC':
                userTimezoneAbbrev = "CST";
                userTimezoneName = "China Standard Time";
                userTimezoneOffset = "+08:00";
                break;

            case 'Asia/Hong_Kong':
            case 'Hongkong':
            case 'HKT':
                userTimezoneAbbrev = "HKT";
                userTimezoneName = "Hong Kong Time";
                userTimezoneOffset = "+08:00";
                break;

            case 'Asia/Makassar':
            case 'Asia/Ujung_Pandang':
            case 'WITA':
                userTimezoneAbbrev = "WITA";
                userTimezoneName = "Central Indonesian Time";
                userTimezoneOffset = "+08:00";
                break;



            // offset of 08:30:00
            case 'Asia/Pyongyang':
                userTimezoneAbbrev = "KST";
                userTimezoneName = "Korea Standard Time";
                userTimezoneOffset = "+08:30";
                break;



            // offset of 08:45:00
            case 'Australia/Eucla':
            case '845':
                userTimezoneAbbrev = "GMT+8:45"; // it is stated as 845 in the timezone abbrev mapping
                userTimezoneName = "GMT+8:45";
                userTimezoneOffset = "+08:45";
                break;



            // offset of 09:00:00
            case 'Asia/Chita':
            case 'Asia/Dili':
            case 'Asia/Khandyga':
            case 'Asia/Yakutsk':
            case 'Etc/GMT-9':
            case 'Pacific/Palau':
            case '9':
                userTimezoneAbbrev = "GMT+9"; // it is stated as 9 in the timezone abbrev mapping
                userTimezoneName = "GMT+9";
                userTimezoneOffset = "+09:00";
                break;

            case 'Asia/Tokyo':
            case 'Japan':
            case 'JST':
                userTimezoneAbbrev = "JST";
                userTimezoneName = "Japan Standard Time";
                userTimezoneOffset = "+09:00";
                break;

            case 'Asia/Seoul':
            case 'ROK':
                userTimezoneAbbrev = "KST";
                userTimezoneName = "Korea Standard Time";
                userTimezoneOffset = "+09:00";
                break;

            case 'Asia/Jayapura':
            case 'WIT':
                userTimezoneAbbrev = "WIT";
                userTimezoneName = "Eastern Indonesia Time";
                userTimezoneOffset = "+09:00";
                break;



            // offset of 09:30:00
            case 'Australia/Adelaide':
            case 'Australia/Broken_Hill':
            case 'Australia/Darwin':
            case 'Australia/North':
            case 'Australia/South':
            case 'Australia/Yancowinna':
            case 'ACST':
                userTimezoneAbbrev = "ACST";
                userTimezoneName = "Australian Central Standard Time";
                userTimezoneOffset = "+09:30";
                break;



            // offset of 10:00:00
            case 'Antarctica/DumontDUrville':
            case 'Asia/Ust-Nera':
            case 'Asia/Vladivostok':
            case 'Etc/GMT-10':
            case 'Pacific/Chuuk':
            case 'Pacific/Port_Moresby':
            case 'Pacific/Truk':
            case 'Pacific/Yap':
            case '10':
                userTimezoneAbbrev = "GMT+10"; // it is stated as 10 in the timezone abbrev mapping
                userTimezoneName = "GMT+10";
                userTimezoneOffset = "+10:00";
                break;

            case 'Australia/ACT':
            case 'Australia/Brisbane':
            case 'Australia/Canberra':
            case 'Australia/Currie':
            case 'Australia/Hobart':
            case 'Australia/Lindeman':
            case 'Australia/Melbourne':
            case 'Australia/NSW':
            case 'Australia/Queensland':
            case 'Australia/Sydney':
            case 'Australia/Tasmania':
            case 'Australia/Victoria':
            case 'AEST':
                userTimezoneAbbrev = "AEST";
                userTimezoneName = "Australian Eastern Standard Time";
                userTimezoneOffset = "+10:00";
                break;

            case 'Pacific/Guam':
            case 'Pacific/Saipan':
            case 'ChST':
                userTimezoneAbbrev = "ChST";
                userTimezoneName = "Chamorro Standard Time";
                userTimezoneOffset = "+10:00";
                break;



            // offset of 10:30:00
            case 'Australia/LHI':
            case 'Australia/Lord_Howe':
            case '1030':
                userTimezoneAbbrev = "GMT+10:30"; // it is stated as 1030 in the timezone abbrev mapping
                userTimezoneName = "GMT+10:30";
                userTimezoneOffset = "+10:30";
                break;



            // offset of 11:00:00
            case 'Antarctica/Casey':
            case 'Antarctica/Macquarie':
            case 'Asia/Magadan':
            case 'Asia/Sakhalin':
            case 'Asia/Srednekolymsk':
            case 'Etc/GMT-11':
            case 'Pacific/Bougainville':
            case 'Pacific/Efate':
            case 'Pacific/Guadalcanal':
            case 'Pacific/Kosrae':
            case 'Pacific/Norfolk':
            case 'Pacific/Noumea':
            case 'Pacific/Pohnpei':
            case 'Pacific/Ponape':
            case '11':
                userTimezoneAbbrev = "GMT+11"; // it is stated as 11 in the timezone abbrev mapping
                userTimezoneName = "GMT+11";
                userTimezoneOffset = "+11:00";
                break;



            // offset of 12:00:00
            case 'Asia/Anadyr':
            case 'Asia/Kamchatka':
            case 'Etc/GMT-12':
            case 'Kwajalein':
            case 'Pacific/Fiji':
            case 'Pacific/Funafuti':
            case 'Pacific/Kwajalein':
            case 'Pacific/Majuro':
            case 'Pacific/Nauru':
            case 'Pacific/Tarawa':
            case 'Pacific/Wake':
            case 'Pacific/Wallis':
            case '12':
                userTimezoneAbbrev = "GMT+12"; // it is stated as 12 in the timezone abbrev mapping
                userTimezoneName = "GMT+12";
                userTimezoneOffset = "+12:00";
                break;

            case 'Antarctica/McMurdo':
            case 'Antarctica/South_Pole':
            case 'NZ':
            case 'Pacific/Auckland':
            case 'NZST':
                userTimezoneAbbrev = "NZST";
                userTimezoneName = "New Zealand Standard Time";
                userTimezoneOffset = "+12:00";
                break;



            // offset of 12:45:00
            case 'NZ-CHAT':
            case 'Pacific/Chatham':
            case '1245':
                userTimezoneAbbrev = "GMT+12:45"; // it is stated as 1245 in the timezone abbrev mapping
                userTimezoneName = "GMT+12:45";
                userTimezoneOffset = "+12:45";
                break;


            // offset of 13:00:00
            case 'Etc/GMT-13':
            case 'Pacific/Apia':
            case 'Pacific/Enderbury':
            case 'Pacific/Fakaofo':
            case 'Pacific/Tongatapu':
            case '13':
                userTimezoneAbbrev = "GMT+13"; // it is stated as 13 in the timezone abbrev mapping
                userTimezoneName = "GMT+13";
                userTimezoneOffset = "+13:00";
                break;



            // offset of 14:00:00
            case 'Etc/GMT-14':
            case 'Pacific/Kiritimati':
            case '14':
                userTimezoneAbbrev = "GMT+14"; // it is stated as 14 in the timezone abbrev mapping
                userTimezoneName = "GMT+14";
                userTimezoneOffset = "+14:00";
                break;



            // offset of -01:00:00
            case 'Atlantic/Cape_Verde':
            case 'Etc/GMT+1':
            case '-1':
                userTimezoneAbbrev = "GMT-1"; // it is stated as -1 in the timezone abbrev mapping
                userTimezoneName = "GMT-1";
                userTimezoneOffset = "-01:00";
                break;


            // offset of -02:00:00
            case 'America/Godthab':
            case 'America/Miquelon':
            case 'America/Noronha':
            case 'Atlantic/South_Georgia':
            case 'Brazil/DeNoronha':
            case 'Etc/GMT+2':
            case '-2':
                userTimezoneAbbrev = "GMT-2"; // it is stated as -2 in the timezone abbrev mapping
                userTimezoneName = "GMT-2";
                userTimezoneOffset = "-02:00";
                break;



            // offset of -02:30:00
            case 'America/St_Johns':
            case 'Canada/Newfoundland':
            case 'NDT':
                userTimezoneAbbrev = "NDT";
                userTimezoneName = "Newfoundland Daylight Time";
                userTimezoneOffset = "-02:30";
                break;


            // offset of -03:00:00
            case 'America/Araguaina':
            case 'America/Argentina/Buenos_Aires':
            case 'America/Argentina/Catamarca':
            case 'America/Argentina/ComodRivadavia':
            case 'America/Argentina/Cordoba':
            case 'America/Argentina/Jujuy':
            case 'America/Argentina/La_Rioja':
            case 'America/Argentina/Mendoza':
            case 'America/Argentina/Rio_Gallegos':
            case 'America/Argentina/Salta':
            case 'America/Argentina/San_Juan':
            case 'America/Argentina/San_Luis':
            case 'America/Argentina/Tucuman':
            case 'America/Argentina/Ushuaia':
            case 'America/Bahia':
            case 'America/Belem':
            case 'America/Buenos_Aires':
            case 'America/Catamarca':
            case 'America/Cayenne':
            case 'America/Cordoba':
            case 'America/Fortaleza':
            case 'America/Jujuy':
            case 'America/Maceio':
            case 'America/Mendoza':
            case 'America/Montevideo':
            case 'America/Paramaribo':
            case 'America/Punta_Arenas':
            case 'America/Recife':
            case 'America/Rosario':
            case 'America/Santarem':
            case 'America/Sao_Paulo':
            case 'Antarctica/Palmer':
            case 'Antarctica/Rothera':
            case 'Atlantic/Stanley':
            case 'Brazil/East':
            case 'Etc/GMT+3':
            case '-3':
                userTimezoneAbbrev = "GMT-3"; // it is stated as -3 in the timezone abbrev mapping
                userTimezoneName = "GMT-3";
                userTimezoneOffset = "-03:00";
                break;

            case 'America/Glace_Bay':
            case 'America/Goose_Bay':
            case 'America/Halifax':
            case 'America/Moncton':
            case 'America/Thule':
            case 'Atlantic/Bermuda':
            case 'Canada/Atlantic':
            case 'ADT':
                userTimezoneAbbrev = "ADT";
                userTimezoneName = "Atlantic Daylight Time";
                userTimezoneOffset = "-03:00";
                break;



            // offset of -04:00:00
            case 'America/Asuncion':
            case 'America/Boa_Vista':
            case 'America/Campo_Grande':
            case 'America/Caracas':
            case 'America/Cuiaba':
            case 'America/Guyana':
            case 'America/La_Paz':
            case 'America/Manaus':
            case 'America/Porto_Velho':
            case 'America/Santiago':
            case 'Brazil/West':
            case 'Chile/Continental':
            case 'Etc/GMT+4':
            case '-4':
                userTimezoneAbbrev = "GMT-4"; // it is stated as -4 in the timezone abbrev mapping
                userTimezoneName = "GMT-4";
                userTimezoneOffset = "-04:00";
                break;

            case 'America/Anguilla':
            case 'America/Antigua':
            case 'America/Aruba':
            case 'America/Barbados':
            case 'America/Blanc-Sablon':
            case 'America/Curacao':
            case 'America/Dominica':
            case 'America/Grand_Turk':
            case 'America/Grenada':
            case 'America/Guadeloupe':
            case 'America/Kralendijk':
            case 'America/Lower_Princes':
            case 'America/Marigot':
            case 'America/Martinique':
            case 'America/Montserrat':
            case 'America/Port_of_Spain':
            case 'America/Puerto_Rico':
            case 'America/Santo_Domingo':
            case 'America/St_Barthelemy':
            case 'America/St_Kitts':
            case 'America/St_Lucia':
            case 'America/St_Thomas':
            case 'America/St_Vincent':
            case 'America/Tortola':
            case 'America/Virgin':
            case 'AST':
                userTimezoneAbbrev = "AST";
                userTimezoneName = "Atlantic Standard Time";
                userTimezoneOffset = "-04:00";
                break;

            case 'America/Havana':
            case 'Cuba':
                userTimezoneAbbrev = "CDT";
                userTimezoneName = "Cuba Daylight Time";
                userTimezoneOffset = "-04:00";
                break;

            case 'America/Detroit':
            case 'America/Fort_Wayne':
            case 'America/Indiana/Indianapolis':
            case 'America/Indiana/Marengo':
            case 'America/Indiana/Petersburg':
            case 'America/Indiana/Vevay':
            case 'America/Indiana/Vincennes':
            case 'America/Indiana/Winamac':
            case 'America/Indianapolis':
            case 'America/Iqaluit':
            case 'America/Kentucky/Louisville':
            case 'America/Kentucky/Monticello':
            case 'America/Louisville':
            case 'America/Montreal':
            case 'America/Nassau':
            case 'America/New_York':
            case 'America/Nipigon':
            case 'America/Pangnirtung':
            case 'America/Port-au-Prince':
            case 'America/Thunder_Bay':
            case 'America/Toronto':
            case 'Canada/Eastern':
            case 'EST5EDT':
            case 'posixrules':
            case 'US/East-Indiana':
            case 'US/Eastern':
            case 'US/Michigan':
            case 'EDT':
                userTimezoneAbbrev = "EDT";
                userTimezoneName = "Eastern Daylight Time";
                userTimezoneOffset = "-04:00";
                break;




            // offset of -05:00:00
            case 'America/Bogota':
            case 'America/Eirunepe':
            case 'America/Guayaquil':
            case 'America/Lima':
            case 'America/Porto_Acre':
            case 'America/Rio_Branco':
            case 'Brazil/Acre':
            case 'Etc/GMT+5':
            case '-5':
                userTimezoneAbbrev = "GMT-5"; // it is stated as -5 in the timezone abbrev mapping
                userTimezoneName = "GMT-5";
                userTimezoneOffset = "-05:00";
                break;

            case 'America/Bahia_Banderas':
            case 'America/Chicago':
            case 'America/Indiana/Knox':
            case 'America/Indiana/Tell_City':
            case 'America/Knox_IN':
            case 'America/Matamoros':
            case 'America/Menominee':
            case 'America/Merida':
            case 'America/Mexico_City':
            case 'America/Monterrey':
            case 'America/North_Dakota/Beulah':
            case 'America/North_Dakota/Center':
            case 'America/North_Dakota/New_Salem':
            case 'America/Rainy_River':
            case 'America/Rankin_Inlet':
            case 'America/Resolute':
            case 'America/Winnipeg':
            case 'Canada/Central':
            case 'CST6CDT':
            case 'Mexico/General':
            case 'US/Central':
            case 'US/Indiana-Starke':
                userTimezoneAbbrev = "CDT";
                userTimezoneName = "Central Daylight Time";
                userTimezoneOffset = "-05:00";
                break;

            case 'America/Atikokan':
            case 'America/Cancun':
            case 'America/Cayman':
            case 'America/Coral_Harbour':
            case 'America/Jamaica':
            case 'America/Panama':
            case 'EST':
            case 'Jamaica':
                userTimezoneAbbrev = "EST";
                userTimezoneName = "Eastern Standard Time";
                userTimezoneOffset = "-05:00";
                break;



            // offset of -06:00:00
            case 'Chile/EasterIsland':
            case 'Etc/GMT+6':
            case 'Pacific/Easter':
            case 'Pacific/Galapagos':
            case '-6':
                userTimezoneAbbrev = "GMT-6"; // it is stated as -6 in the timezone abbrev mapping
                userTimezoneName = "GMT-6";
                userTimezoneOffset = "-06:00";
                break;

            case 'America/Belize':
            case 'America/Costa_Rica':
            case 'America/El_Salvador':
            case 'America/Guatemala':
            case 'America/Managua':
            case 'America/Regina':
            case 'America/Swift_Current':
            case 'America/Tegucigalpa':
            case 'Canada/East-Saskatchewan':
            case 'Canada/Saskatchewan':
                userTimezoneAbbrev = "CST";
                userTimezoneName = "Central Standard Time";
                userTimezoneOffset = "-06:00";
                break;

            case 'America/Boise':
            case 'America/Cambridge_Bay':
            case 'America/Chihuahua':
            case 'America/Denver':
            case 'America/Edmonton':
            case 'America/Inuvik':
            case 'America/Mazatlan':
            case 'America/Ojinaga':
            case 'America/Shiprock':
            case 'America/Yellowknife':
            case 'Canada/Mountain':
            case 'Mexico/BajaSur':
            case 'MST7MDT':
            case 'Navajo':
            case 'US/Mountain':
            case 'MDT':
                userTimezoneAbbrev = "MDT";
                userTimezoneName = "Mountain Daylight Time";
                userTimezoneOffset = "-06:00";
                break;



            // offset of -07:00:00
            case 'Etc/GMT+7':
            case '-7':
                userTimezoneAbbrev = "GMT-7"; // it is stated as -7 in the timezone abbrev mapping
                userTimezoneName = "GMT-7";
                userTimezoneOffset = "-07:00";
                break;

            case 'America/Creston':
            case 'America/Dawson_Creek':
            case 'America/Fort_Nelson':
            case 'America/Hermosillo':
            case 'America/Phoenix':
            case 'MST':
            case 'US/Arizona':
                userTimezoneAbbrev = "MST";
                userTimezoneName = "Mountain Standard Time";
                userTimezoneOffset = "-07:00";
                break;

            case 'America/Dawson':
            case 'America/Ensenada':
            case 'America/Los_Angeles':
            case 'America/Santa_Isabel':
            case 'America/Tijuana':
            case 'America/Vancouver':
            case 'America/Whitehorse':
            case 'Canada/Pacific':
            case 'Canada/Yukon':
            case 'Mexico/BajaNorte':
            case 'PST8PDT':
            case 'US/Pacific':
            case 'US/Pacific-New':
            case 'PDT':
                userTimezoneAbbrev = "PDT";
                userTimezoneName = "Pacific Daylight Time";
                userTimezoneOffset = "-07:00";
                break;



            // offset of -08:00:00
            case 'Etc/GMT+8':
            case 'Pacific/Pitcairn':
            case '-8':
                userTimezoneAbbrev = "GMT-8"; // it is stated as -8 in the timezone abbrev mapping
                userTimezoneName = "GMT-8";
                userTimezoneOffset = "-08:00";
                break;

            case 'America/Anchorage':
            case 'America/Juneau':
            case 'America/Metlakatla':
            case 'America/Nome':
            case 'America/Sitka':
            case 'America/Yakutat':
            case 'US/Alaska':
            case 'AKDT':
                userTimezoneAbbrev = "AKDT";
                userTimezoneName = "Alaska Daylight Time";
                userTimezoneOffset = "-08:00";
                break;


            // offset of -09:00:00
            case 'Etc/GMT+9':
            case 'Pacific/Gambier':
            case '-9':
                userTimezoneAbbrev = "GMT-9"; // it is stated as -9 in the timezone abbrev mapping
                userTimezoneName = "GMT-9";
                userTimezoneOffset = "-09:00";
                break;

            case 'America/Adak':
            case 'America/Atka':
            case 'US/Aleutian':
            case 'HDT':
                userTimezoneAbbrev = "HDT";
                userTimezoneName = "Hawaii-Aleutian Daylight Time";
                userTimezoneOffset = "-09:00";
                break;



            // offset of -09:30:00
            case 'Pacific/Marquesas':
            case '-930':
                userTimezoneAbbrev = "GMT-9:30"; // it is stated as -930 in the timezone abbrev mapping
                userTimezoneName = "GMT-9:30";
                userTimezoneOffset = "-09:30";
                break;



            // offset of -10:00:00
            case 'Etc/GMT+10':
            case 'Pacific/Rarotonga':
            case 'Pacific/Tahiti':
            case '-10':
                userTimezoneAbbrev = "GMT-10"; // it is stated as -10 in the timezone abbrev mapping
                userTimezoneName = "GMT-10";
                userTimezoneOffset = "-10:00";
                break;

            case 'HST':
            case 'Pacific/Honolulu':
            case 'Pacific/Johnston':
            case 'US/Hawaii':
                userTimezoneAbbrev = "HST";
                userTimezoneName = "Hawaii Standard Time";
                userTimezoneOffset = "-10:00";
                break;



            // offset of -11:00:00
            case 'Etc/GMT+11':
            case 'Pacific/Niue':
            case '-11':
                userTimezoneAbbrev = "GMT-11"; // it is stated as -11 in the timezone abbrev mapping
                userTimezoneName = "GMT-11";
                userTimezoneOffset = "-11:00";
                break;

            case 'Pacific/Midway':
            case 'Pacific/Pago_Pago':
            case 'Pacific/Samoa':
            case 'US/Samoa':
            case 'SST':
                userTimezoneAbbrev = "SST";
                userTimezoneName = "Samoa Standard Time";
                userTimezoneOffset = "-11:00";
                break;



            // offset of -12:00:00
            case 'Etc/GMT+12':
            case '-12':
                userTimezoneAbbrev = "GMT-12"; // it is stated as -12 in the timezone abbrev mapping
                userTimezoneName = "GMT-12";
                userTimezoneOffset = "-12:00";
                break;


            default:

        }

        return '(GMT' + userTimezoneOffset + ') ' + userTimezoneName + ' - ' + userTimezoneCity + '***' + userTimezoneAbbrev;


    }



    getTimezoneList(lang) {

        const time_zone_list_en = [
            { pick_list_id: 'Pacific/Midway***(GMT-11:00) Samoa Standard Time - Midway***SST', item_name: '(GMT-11:00) Samoa Standard Time - Midway', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Niue***(GMT-11:00) Niue***GMT-11', item_name: '(GMT-11:00) Niue', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Pago_Pago***(GMT-11:00) Samoa Standard Time - Pago Pago***SST', item_name: '(GMT-11:00) Samoa Standard Time - Pago Pago', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Samoa***(GMT-11:00) Samoa Standard Time - Samoa***SST', item_name: '(GMT-11:00) Samoa Standard Time - Samoa', category: 'time_zone_region' },
            { pick_list_id: 'US/Samoa***(GMT-11:00) Samoa Standard Time - Samoa***SST', item_name: '(GMT-11:00) Samoa Standard Time - Samoa', category: 'time_zone_region' },
            { pick_list_id: 'HST***(GMT-10:00) Hawaii Standard Time - HST***HST', item_name: '(GMT-10:00) Hawaii Standard Time - HST', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Honolulu***(GMT-10:00) Hawaii Standard Time - Honolulu***HST', item_name: '(GMT-10:00) Hawaii Standard Time - Honolulu', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Johnston***(GMT-10:00) Hawaii Standard Time - Johnston***HST', item_name: '(GMT-10:00) Hawaii Standard Time - Johnston', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Rarotonga***(GMT-10:00) Rarotonga***GMT-10', item_name: '(GMT-10:00) Rarotonga', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Tahiti***(GMT-10:00) Tahiti***GMT-10', item_name: '(GMT-10:00) Tahiti', category: 'time_zone_region' },
            { pick_list_id: 'US/Hawaii***(GMT-10:00) Hawaii Standard Time - Hawaii***HST', item_name: '(GMT-10:00) Hawaii Standard Time - Hawaii', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Marquesas***(GMT-09:30) Marquesas***GMT-9:30', item_name: '(GMT-09:30) Marquesas', category: 'time_zone_region' },
            { pick_list_id: 'America/Adak***(GMT-09:00) Hawaii-Aleutian Daylight Time - Adak***HDT', item_name: '(GMT-09:00) Hawaii-Aleutian Daylight Time - Adak', category: 'time_zone_region' },
            { pick_list_id: 'America/Atka***(GMT-09:00) Hawaii-Aleutian Daylight Time - Atka***HDT', item_name: '(GMT-09:00) Hawaii-Aleutian Daylight Time - Atka', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Gambier***(GMT-09:00) Gambier***GMT-9', item_name: '(GMT-09:00) Gambier', category: 'time_zone_region' },
            { pick_list_id: 'US/Aleutian***(GMT-09:00) Hawaii-Aleutian Daylight Time - Aleutian***HDT', item_name: '(GMT-09:00) Hawaii-Aleutian Daylight Time - Aleutian', category: 'time_zone_region' },
            { pick_list_id: 'America/Anchorage***(GMT-08:00) Alaska Daylight Time - Anchorage***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Anchorage', category: 'time_zone_region' },
            { pick_list_id: 'America/Juneau***(GMT-08:00) Alaska Daylight Time - Juneau***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Juneau', category: 'time_zone_region' },
            { pick_list_id: 'America/Metlakatla***(GMT-08:00) Alaska Daylight Time - Metlakatla***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Metlakatla', category: 'time_zone_region' },
            { pick_list_id: 'America/Nome***(GMT-08:00) Alaska Daylight Time - Nome***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Nome', category: 'time_zone_region' },
            { pick_list_id: 'America/Sitka***(GMT-08:00) Alaska Daylight Time - Sitka***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Sitka', category: 'time_zone_region' },
            { pick_list_id: 'America/Yakutat***(GMT-08:00) Alaska Daylight Time - Yakutat***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Yakutat', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Pitcairn***(GMT-08:00) Pitcairn***GMT-8', item_name: '(GMT-08:00) Pitcairn', category: 'time_zone_region' },
            { pick_list_id: 'US/Alaska***(GMT-08:00) Alaska Daylight Time - Alaska***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Alaska', category: 'time_zone_region' },
            { pick_list_id: 'America/Creston***(GMT-07:00) Mountain Standard Time - Creston***MST', item_name: '(GMT-07:00) Mountain Standard Time - Creston', category: 'time_zone_region' },
            { pick_list_id: 'America/Dawson***(GMT-07:00) Pacific Daylight Time - Dawson***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Dawson', category: 'time_zone_region' },
            { pick_list_id: 'America/Dawson_Creek***(GMT-07:00) Mountain Standard Time - Dawson Creek***MST', item_name: '(GMT-07:00) Mountain Standard Time - Dawson Creek', category: 'time_zone_region' },
            { pick_list_id: 'America/Ensenada***(GMT-07:00) Pacific Daylight Time - Ensenada***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Ensenada', category: 'time_zone_region' },
            { pick_list_id: 'America/Fort_Nelson***(GMT-07:00) Mountain Standard Time - Fort Nelson***MST', item_name: '(GMT-07:00) Mountain Standard Time - Fort Nelson', category: 'time_zone_region' },
            { pick_list_id: 'America/Hermosillo***(GMT-07:00) Mountain Standard Time - Hermosillo***MST', item_name: '(GMT-07:00) Mountain Standard Time - Hermosillo', category: 'time_zone_region' },
            { pick_list_id: 'America/Los_Angeles***(GMT-07:00) Pacific Daylight Time - Los Angeles***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Los Angeles', category: 'time_zone_region' },
            { pick_list_id: 'America/Phoenix***(GMT-07:00) Mountain Standard Time - Phoenix***MST', item_name: '(GMT-07:00) Mountain Standard Time - Phoenix', category: 'time_zone_region' },
            { pick_list_id: 'America/Santa_Isabel***(GMT-07:00) Pacific Daylight Time - Santa Isabel***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Santa Isabel', category: 'time_zone_region' },
            { pick_list_id: 'America/Tijuana***(GMT-07:00) Pacific Daylight Time - Tijuana***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Tijuana', category: 'time_zone_region' },
            { pick_list_id: 'America/Vancouver***(GMT-07:00) Pacific Daylight Time - Vancouver***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Vancouver', category: 'time_zone_region' },
            { pick_list_id: 'America/Whitehorse***(GMT-07:00) Pacific Daylight Time - Whitehorse***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Whitehorse', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Pacific***(GMT-07:00) Pacific Daylight Time - Pacific***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Pacific', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Yukon***(GMT-07:00) Pacific Daylight Time - Yukon***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Yukon', category: 'time_zone_region' },
            { pick_list_id: 'Mexico/BajaNorte***(GMT-07:00) Pacific Daylight Time - BajaNorte***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - BajaNorte', category: 'time_zone_region' },
            { pick_list_id: 'MST***(GMT-07:00) Mountain Standard Time - MST***MST', item_name: '(GMT-07:00) Mountain Standard Time - MST', category: 'time_zone_region' },
            { pick_list_id: 'US/Arizona***(GMT-07:00) Mountain Standard Time - Arizona***MST', item_name: '(GMT-07:00) Mountain Standard Time - Arizona', category: 'time_zone_region' },
            { pick_list_id: 'US/Pacific***(GMT-07:00) Pacific Daylight Time - Pacific***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Pacific', category: 'time_zone_region' },
            { pick_list_id: 'US/Pacific-New***(GMT-07:00) Pacific Daylight Time - Pacific-New***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Pacific-New', category: 'time_zone_region' },
            { pick_list_id: 'America/Belize***(GMT-06:00) Central Standard Time - Belize***CST', item_name: '(GMT-06:00) Central Standard Time - Belize', category: 'time_zone_region' },
            { pick_list_id: 'America/Boise***(GMT-06:00) Mountain Daylight Time - Boise***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Boise', category: 'time_zone_region' },
            { pick_list_id: 'America/Cambridge_Bay***(GMT-06:00) Mountain Daylight Time - Cambridge Bay***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Cambridge Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Chihuahua***(GMT-06:00) Mountain Daylight Time - Chihuahua***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Chihuahua', category: 'time_zone_region' },
            { pick_list_id: 'America/Costa_Rica***(GMT-06:00) Central Standard Time - Costa Rica***CST', item_name: '(GMT-06:00) Central Standard Time - Costa Rica', category: 'time_zone_region' },
            { pick_list_id: 'America/Denver***(GMT-06:00) Mountain Daylight Time - Denver***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Denver', category: 'time_zone_region' },
            { pick_list_id: 'America/Edmonton***(GMT-06:00) Mountain Daylight Time - Edmonton***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Edmonton', category: 'time_zone_region' },
            { pick_list_id: 'America/El_Salvador***(GMT-06:00) Central Standard Time - El Salvador***CST', item_name: '(GMT-06:00) Central Standard Time - El Salvador', category: 'time_zone_region' },
            { pick_list_id: 'America/Guatemala***(GMT-06:00) Central Standard Time - Guatemala***CST', item_name: '(GMT-06:00) Central Standard Time - Guatemala', category: 'time_zone_region' },
            { pick_list_id: 'America/Inuvik***(GMT-06:00) Mountain Daylight Time - Inuvik***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Inuvik', category: 'time_zone_region' },
            { pick_list_id: 'America/Managua***(GMT-06:00) Central Standard Time - Managua***CST', item_name: '(GMT-06:00) Central Standard Time - Managua', category: 'time_zone_region' },
            { pick_list_id: 'America/Mazatlan***(GMT-06:00) Mountain Daylight Time - Mazatlan***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Mazatlan', category: 'time_zone_region' },
            { pick_list_id: 'America/Ojinaga***(GMT-06:00) Mountain Daylight Time - Ojinaga***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Ojinaga', category: 'time_zone_region' },
            { pick_list_id: 'America/Regina***(GMT-06:00) Central Standard Time - Regina***CST', item_name: '(GMT-06:00) Central Standard Time - Regina', category: 'time_zone_region' },
            { pick_list_id: 'America/Shiprock***(GMT-06:00) Mountain Daylight Time - Shiprock***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Shiprock', category: 'time_zone_region' },
            { pick_list_id: 'America/Swift_Current***(GMT-06:00) Central Standard Time - Swift Current***CST', item_name: '(GMT-06:00) Central Standard Time - Swift Current', category: 'time_zone_region' },
            { pick_list_id: 'America/Tegucigalpa***(GMT-06:00) Central Standard Time - Tegucigalpa***CST', item_name: '(GMT-06:00) Central Standard Time - Tegucigalpa', category: 'time_zone_region' },
            { pick_list_id: 'America/Yellowknife***(GMT-06:00) Mountain Daylight Time - Yellowknife***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Yellowknife', category: 'time_zone_region' },
            { pick_list_id: 'Canada/East-Saskatchewan***(GMT-06:00) Central Standard Time - East-Saskatchewan***CST', item_name: '(GMT-06:00) Central Standard Time - East-Saskatchewan', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Mountain***(GMT-06:00) Mountain Daylight Time - Mountain***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Mountain', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Saskatchewan***(GMT-06:00) Central Standard Time - Saskatchewan***CST', item_name: '(GMT-06:00) Central Standard Time - Saskatchewan', category: 'time_zone_region' },
            { pick_list_id: 'Chile/EasterIsland***(GMT-06:00) EasterIsland***GMT-6', item_name: '(GMT-06:00) EasterIsland', category: 'time_zone_region' },
            { pick_list_id: 'Mexico/BajaSur***(GMT-06:00) Mountain Daylight Time - BajaSur***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - BajaSur', category: 'time_zone_region' },
            { pick_list_id: 'Navajo***(GMT-06:00) Mountain Daylight Time - Navajo***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Navajo', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Easter***(GMT-06:00) Easter***GMT-6', item_name: '(GMT-06:00) Easter', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Galapagos***(GMT-06:00) Galapagos***GMT-6', item_name: '(GMT-06:00) Galapagos', category: 'time_zone_region' },
            { pick_list_id: 'US/Mountain***(GMT-06:00) Mountain Daylight Time - Mountain***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Mountain', category: 'time_zone_region' },
            { pick_list_id: 'America/Atikokan***(GMT-05:00) Eastern Standard Time - Atikokan***EST', item_name: '(GMT-05:00) Eastern Standard Time - Atikokan', category: 'time_zone_region' },
            { pick_list_id: 'America/Bahia_Banderas***(GMT-05:00) Central Daylight Time - Bahia Banderas***CDT', item_name: '(GMT-05:00) Central Daylight Time - Bahia Banderas', category: 'time_zone_region' },
            { pick_list_id: 'America/Bogota***(GMT-05:00) Bogota***GMT-5', item_name: '(GMT-05:00) Bogota', category: 'time_zone_region' },
            { pick_list_id: 'America/Cancun***(GMT-05:00) Eastern Standard Time - Cancun***EST', item_name: '(GMT-05:00) Eastern Standard Time - Cancun', category: 'time_zone_region' },
            { pick_list_id: 'America/Cayman***(GMT-05:00) Eastern Standard Time - Cayman***EST', item_name: '(GMT-05:00) Eastern Standard Time - Cayman', category: 'time_zone_region' },
            { pick_list_id: 'America/Chicago***(GMT-05:00) Central Daylight Time - Chicago***CDT', item_name: '(GMT-05:00) Central Daylight Time - Chicago', category: 'time_zone_region' },
            { pick_list_id: 'America/Coral_Harbour***(GMT-05:00) Eastern Standard Time - Coral Harbour***EST', item_name: '(GMT-05:00) Eastern Standard Time - Coral Harbour', category: 'time_zone_region' },
            { pick_list_id: 'America/Eirunepe***(GMT-05:00) Eirunepe***GMT-5', item_name: '(GMT-05:00) Eirunepe', category: 'time_zone_region' },
            { pick_list_id: 'America/Guayaquil***(GMT-05:00) Guayaquil***GMT-5', item_name: '(GMT-05:00) Guayaquil', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Knox***(GMT-05:00) Central Daylight Time - Indiana/Knox***CDT', item_name: '(GMT-05:00) Central Daylight Time - Indiana/Knox', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Tell_City***(GMT-05:00) Central Daylight Time - Indiana/Tell City***CDT', item_name: '(GMT-05:00) Central Daylight Time - Indiana/Tell City', category: 'time_zone_region' },
            { pick_list_id: 'America/Jamaica***(GMT-05:00) Eastern Standard Time - Jamaica***EST', item_name: '(GMT-05:00) Eastern Standard Time - Jamaica', category: 'time_zone_region' },
            { pick_list_id: 'America/Knox_IN***(GMT-05:00) Central Daylight Time - Knox IN***CDT', item_name: '(GMT-05:00) Central Daylight Time - Knox IN', category: 'time_zone_region' },
            { pick_list_id: 'America/Lima***(GMT-05:00) Lima***GMT-5', item_name: '(GMT-05:00) Lima', category: 'time_zone_region' },
            { pick_list_id: 'America/Matamoros***(GMT-05:00) Central Daylight Time - Matamoros***CDT', item_name: '(GMT-05:00) Central Daylight Time - Matamoros', category: 'time_zone_region' },
            { pick_list_id: 'America/Menominee***(GMT-05:00) Central Daylight Time - Menominee***CDT', item_name: '(GMT-05:00) Central Daylight Time - Menominee', category: 'time_zone_region' },
            { pick_list_id: 'America/Merida***(GMT-05:00) Central Daylight Time - Merida***CDT', item_name: '(GMT-05:00) Central Daylight Time - Merida', category: 'time_zone_region' },
            { pick_list_id: 'America/Mexico_City***(GMT-05:00) Central Daylight Time - Mexico City***CDT', item_name: '(GMT-05:00) Central Daylight Time - Mexico City', category: 'time_zone_region' },
            { pick_list_id: 'America/Monterrey***(GMT-05:00) Central Daylight Time - Monterrey***CDT', item_name: '(GMT-05:00) Central Daylight Time - Monterrey', category: 'time_zone_region' },
            { pick_list_id: 'America/North_Dakota/Beulah***(GMT-05:00) Central Daylight Time - North Dakota/Beulah***CDT', item_name: '(GMT-05:00) Central Daylight Time - North Dakota/Beulah', category: 'time_zone_region' },
            { pick_list_id: 'America/North_Dakota/Center***(GMT-05:00) Central Daylight Time - North Dakota/Center***CDT', item_name: '(GMT-05:00) Central Daylight Time - North Dakota/Center', category: 'time_zone_region' },
            { pick_list_id: 'America/North_Dakota/New_Salem***(GMT-05:00) Central Daylight Time - North Dakota/New Salem***CDT', item_name: '(GMT-05:00) Central Daylight Time - North Dakota/New Salem', category: 'time_zone_region' },
            { pick_list_id: 'America/Panama***(GMT-05:00) Eastern Standard Time - Panama***EST', item_name: '(GMT-05:00) Eastern Standard Time - Panama', category: 'time_zone_region' },
            { pick_list_id: 'America/Porto_Acre***(GMT-05:00) Porto Acre***GMT-5', item_name: '(GMT-05:00) Porto Acre', category: 'time_zone_region' },
            { pick_list_id: 'America/Rainy_River***(GMT-05:00) Central Daylight Time - Rainy River***CDT', item_name: '(GMT-05:00) Central Daylight Time - Rainy River', category: 'time_zone_region' },
            { pick_list_id: 'America/Rankin_Inlet***(GMT-05:00) Central Daylight Time - Rankin Inlet***CDT', item_name: '(GMT-05:00) Central Daylight Time - Rankin Inlet', category: 'time_zone_region' },
            { pick_list_id: 'America/Resolute***(GMT-05:00) Central Daylight Time - Resolute***CDT', item_name: '(GMT-05:00) Central Daylight Time - Resolute', category: 'time_zone_region' },
            { pick_list_id: 'America/Rio_Branco***(GMT-05:00) Rio Branco***GMT-5', item_name: '(GMT-05:00) Rio Branco', category: 'time_zone_region' },
            { pick_list_id: 'America/Winnipeg***(GMT-05:00) Central Daylight Time - Winnipeg***CDT', item_name: '(GMT-05:00) Central Daylight Time - Winnipeg', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/Acre***(GMT-05:00) Acre***GMT-5', item_name: '(GMT-05:00) Acre', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Central***(GMT-05:00) Central Daylight Time - Central***CDT', item_name: '(GMT-05:00) Central Daylight Time - Central', category: 'time_zone_region' },
            { pick_list_id: 'EST***(GMT-05:00) Eastern Standard Time - EST***EST', item_name: '(GMT-05:00) Eastern Standard Time - EST', category: 'time_zone_region' },
            { pick_list_id: 'Jamaica***(GMT-05:00) Eastern Standard Time - Jamaica***EST', item_name: '(GMT-05:00) Eastern Standard Time - Jamaica', category: 'time_zone_region' },
            { pick_list_id: 'Mexico/General***(GMT-05:00) Central Daylight Time - General***CDT', item_name: '(GMT-05:00) Central Daylight Time - General', category: 'time_zone_region' },
            { pick_list_id: 'US/Central***(GMT-05:00) Central Daylight Time - Central***CDT', item_name: '(GMT-05:00) Central Daylight Time - Central', category: 'time_zone_region' },
            { pick_list_id: 'US/Indiana-Starke***(GMT-05:00) Central Daylight Time - Indiana-Starke***CDT', item_name: '(GMT-05:00) Central Daylight Time - Indiana-Starke', category: 'time_zone_region' },
            { pick_list_id: 'America/Anguilla***(GMT-04:00) Atlantic Standard Time - Anguilla***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Anguilla', category: 'time_zone_region' },
            { pick_list_id: 'America/Antigua***(GMT-04:00) Atlantic Standard Time - Antigua***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Antigua', category: 'time_zone_region' },
            { pick_list_id: 'America/Aruba***(GMT-04:00) Atlantic Standard Time - Aruba***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Aruba', category: 'time_zone_region' },
            { pick_list_id: 'America/Asuncion***(GMT-04:00) Asuncion***GMT-4', item_name: '(GMT-04:00) Asuncion', category: 'time_zone_region' },
            { pick_list_id: 'America/Barbados***(GMT-04:00) Atlantic Standard Time - Barbados***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Barbados', category: 'time_zone_region' },
            { pick_list_id: 'America/Blanc-Sablon***(GMT-04:00) Atlantic Standard Time - Blanc-Sablon***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Blanc-Sablon', category: 'time_zone_region' },
            { pick_list_id: 'America/Boa_Vista***(GMT-04:00) Boa Vista***GMT-4', item_name: '(GMT-04:00) Boa Vista', category: 'time_zone_region' },
            { pick_list_id: 'America/Campo_Grande***(GMT-04:00) Campo Grande***GMT-4', item_name: '(GMT-04:00) Campo Grande', category: 'time_zone_region' },
            { pick_list_id: 'America/Caracas***(GMT-04:00) Caracas***GMT-4', item_name: '(GMT-04:00) Caracas', category: 'time_zone_region' },
            { pick_list_id: 'America/Cuiaba***(GMT-04:00) Cuiaba***GMT-4', item_name: '(GMT-04:00) Cuiaba', category: 'time_zone_region' },
            { pick_list_id: 'America/Curacao***(GMT-04:00) Atlantic Standard Time - Curacao***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Curacao', category: 'time_zone_region' },
            { pick_list_id: 'America/Detroit***(GMT-04:00) Eastern Daylight Time - Detroit***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Detroit', category: 'time_zone_region' },
            { pick_list_id: 'America/Dominica***(GMT-04:00) Atlantic Standard Time - Dominica***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Dominica', category: 'time_zone_region' },
            { pick_list_id: 'America/Fort_Wayne***(GMT-04:00) Eastern Daylight Time - Fort Wayne***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Fort Wayne', category: 'time_zone_region' },
            { pick_list_id: 'America/Grand_Turk***(GMT-04:00) Atlantic Standard Time - Grand Turk***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Grand Turk', category: 'time_zone_region' },
            { pick_list_id: 'America/Grenada***(GMT-04:00) Atlantic Standard Time - Grenada***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Grenada', category: 'time_zone_region' },
            { pick_list_id: 'America/Guadeloupe***(GMT-04:00) Atlantic Standard Time - Guadeloupe***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Guadeloupe', category: 'time_zone_region' },
            { pick_list_id: 'America/Guyana***(GMT-04:00) Guyana***GMT-4', item_name: '(GMT-04:00) Guyana', category: 'time_zone_region' },
            { pick_list_id: 'America/Havana***(GMT-04:00) Cuba Daylight Time - Havana***CDT', item_name: '(GMT-04:00) Cuba Daylight Time - Havana', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Indianapolis***(GMT-04:00) Eastern Daylight Time - Indiana/Indianapolis***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Indianapolis', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Marengo***(GMT-04:00) Eastern Daylight Time - Indiana/Marengo***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Marengo', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Petersburg***(GMT-04:00) Eastern Daylight Time - Indiana/Petersburg***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Petersburg', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Vevay***(GMT-04:00) Eastern Daylight Time - Indiana/Vevay***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Vevay', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Vincennes***(GMT-04:00) Eastern Daylight Time - Indiana/Vincennes***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Vincennes', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Winamac***(GMT-04:00) Eastern Daylight Time - Indiana/Winamac***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Winamac', category: 'time_zone_region' },
            { pick_list_id: 'America/Indianapolis***(GMT-04:00) Eastern Daylight Time - Indianapolis***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indianapolis', category: 'time_zone_region' },
            { pick_list_id: 'America/Iqaluit***(GMT-04:00) Eastern Daylight Time - Iqaluit***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Iqaluit', category: 'time_zone_region' },
            { pick_list_id: 'America/Kentucky/Louisville***(GMT-04:00) Eastern Daylight Time - Kentucky/Louisville***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Kentucky/Louisville', category: 'time_zone_region' },
            { pick_list_id: 'America/Kentucky/Monticello***(GMT-04:00) Eastern Daylight Time - Kentucky/Monticello***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Kentucky/Monticello', category: 'time_zone_region' },
            { pick_list_id: 'America/Kralendijk***(GMT-04:00) Atlantic Standard Time - Kralendijk***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Kralendijk', category: 'time_zone_region' },
            { pick_list_id: 'America/La_Paz***(GMT-04:00) La Paz***GMT-4', item_name: '(GMT-04:00) La Paz', category: 'time_zone_region' },
            { pick_list_id: 'America/Louisville***(GMT-04:00) Eastern Daylight Time - Louisville***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Louisville', category: 'time_zone_region' },
            { pick_list_id: 'America/Lower_Princes***(GMT-04:00) Atlantic Standard Time - Lower Princes***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Lower Princes', category: 'time_zone_region' },
            { pick_list_id: 'America/Manaus***(GMT-04:00) Manaus***GMT-4', item_name: '(GMT-04:00) Manaus', category: 'time_zone_region' },
            { pick_list_id: 'America/Marigot***(GMT-04:00) Atlantic Standard Time - Marigot***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Marigot', category: 'time_zone_region' },
            { pick_list_id: 'America/Martinique***(GMT-04:00) Atlantic Standard Time - Martinique***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Martinique', category: 'time_zone_region' },
            { pick_list_id: 'America/Montreal***(GMT-04:00) Eastern Daylight Time - Montreal***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Montreal', category: 'time_zone_region' },
            { pick_list_id: 'America/Montserrat***(GMT-04:00) Atlantic Standard Time - Montserrat***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Montserrat', category: 'time_zone_region' },
            { pick_list_id: 'America/Nassau***(GMT-04:00) Eastern Daylight Time - Nassau***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Nassau', category: 'time_zone_region' },
            { pick_list_id: 'America/New_York***(GMT-04:00) Eastern Daylight Time - New York***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - New York', category: 'time_zone_region' },
            { pick_list_id: 'America/Nipigon***(GMT-04:00) Eastern Daylight Time - Nipigon***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Nipigon', category: 'time_zone_region' },
            { pick_list_id: 'America/Pangnirtung***(GMT-04:00) Eastern Daylight Time - Pangnirtung***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Pangnirtung', category: 'time_zone_region' },
            { pick_list_id: 'America/Port-au-Prince***(GMT-04:00) Eastern Daylight Time - Port-au-Prince***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Port-au-Prince', category: 'time_zone_region' },
            { pick_list_id: 'America/Porto_Velho***(GMT-04:00) Porto Velho***GMT-4', item_name: '(GMT-04:00) Porto Velho', category: 'time_zone_region' },
            { pick_list_id: 'America/Port_of_Spain***(GMT-04:00) Atlantic Standard Time - Port of Spain***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Port of Spain', category: 'time_zone_region' },
            { pick_list_id: 'America/Puerto_Rico***(GMT-04:00) Atlantic Standard Time - Puerto Rico***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Puerto Rico', category: 'time_zone_region' },
            { pick_list_id: 'America/Santiago***(GMT-04:00) Santiago***GMT-4', item_name: '(GMT-04:00) Santiago', category: 'time_zone_region' },
            { pick_list_id: 'America/Santo_Domingo***(GMT-04:00) Atlantic Standard Time - Santo Domingo***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Santo Domingo', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Barthelemy***(GMT-04:00) Atlantic Standard Time - St Barthelemy***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Barthelemy', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Kitts***(GMT-04:00) Atlantic Standard Time - St Kitts***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Kitts', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Lucia***(GMT-04:00) Atlantic Standard Time - St Lucia***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Lucia', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Thomas***(GMT-04:00) Atlantic Standard Time - St Thomas***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Thomas', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Vincent***(GMT-04:00) Atlantic Standard Time - St Vincent***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Vincent', category: 'time_zone_region' },
            { pick_list_id: 'America/Thunder_Bay***(GMT-04:00) Eastern Daylight Time - Thunder Bay***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Thunder Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Toronto***(GMT-04:00) Eastern Daylight Time - Toronto***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Toronto', category: 'time_zone_region' },
            { pick_list_id: 'America/Tortola***(GMT-04:00) Atlantic Standard Time - Tortola***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Tortola', category: 'time_zone_region' },
            { pick_list_id: 'America/Virgin***(GMT-04:00) Atlantic Standard Time - Virgin***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Virgin', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/West***(GMT-04:00) West***GMT-4', item_name: '(GMT-04:00) West', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Eastern***(GMT-04:00) Eastern Daylight Time - Eastern***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Eastern', category: 'time_zone_region' },
            { pick_list_id: 'Chile/Continental***(GMT-04:00) Continental***GMT-4', item_name: '(GMT-04:00) Continental', category: 'time_zone_region' },
            { pick_list_id: 'Cuba***(GMT-04:00) Cuba Daylight Time - Cuba***CDT', item_name: '(GMT-04:00) Cuba Daylight Time - Cuba', category: 'time_zone_region' },
            { pick_list_id: 'posixrules***(GMT-04:00) Eastern Daylight Time - posixrules***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - posixrules', category: 'time_zone_region' },
            { pick_list_id: 'US/East-Indiana***(GMT-04:00) Eastern Daylight Time - East-Indiana***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - East-Indiana', category: 'time_zone_region' },
            { pick_list_id: 'US/Eastern***(GMT-04:00) Eastern Daylight Time - Eastern***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Eastern', category: 'time_zone_region' },
            { pick_list_id: 'US/Michigan***(GMT-04:00) Eastern Daylight Time - Michigan***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Michigan', category: 'time_zone_region' },
            { pick_list_id: 'America/Araguaina***(GMT-03:00) Araguaina***GMT-3', item_name: '(GMT-03:00) Araguaina', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Buenos_Aires***(GMT-03:00) Argentina/Buenos Aires***GMT-3', item_name: '(GMT-03:00) Argentina/Buenos Aires', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Catamarca***(GMT-03:00) Argentina/Catamarca***GMT-3', item_name: '(GMT-03:00) Argentina/Catamarca', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/ComodRivadavia***(GMT-03:00) Argentina/ComodRivadavia***GMT-3', item_name: '(GMT-03:00) Argentina/ComodRivadavia', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Cordoba***(GMT-03:00) Argentina/Cordoba***GMT-3', item_name: '(GMT-03:00) Argentina/Cordoba', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Jujuy***(GMT-03:00) Argentina/Jujuy***GMT-3', item_name: '(GMT-03:00) Argentina/Jujuy', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/La_Rioja***(GMT-03:00) Argentina/La Rioja***GMT-3', item_name: '(GMT-03:00) Argentina/La Rioja', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Mendoza***(GMT-03:00) Argentina/Mendoza***GMT-3', item_name: '(GMT-03:00) Argentina/Mendoza', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Rio_Gallegos***(GMT-03:00) Argentina/Rio Gallegos***GMT-3', item_name: '(GMT-03:00) Argentina/Rio Gallegos', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Salta***(GMT-03:00) Argentina/Salta***GMT-3', item_name: '(GMT-03:00) Argentina/Salta', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/San_Juan***(GMT-03:00) Argentina/San Juan***GMT-3', item_name: '(GMT-03:00) Argentina/San Juan', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/San_Luis***(GMT-03:00) Argentina/San Luis***GMT-3', item_name: '(GMT-03:00) Argentina/San Luis', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Tucuman***(GMT-03:00) Argentina/Tucuman***GMT-3', item_name: '(GMT-03:00) Argentina/Tucuman', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Ushuaia***(GMT-03:00) Argentina/Ushuaia***GMT-3', item_name: '(GMT-03:00) Argentina/Ushuaia', category: 'time_zone_region' },
            { pick_list_id: 'America/Bahia***(GMT-03:00) Bahia***GMT-3', item_name: '(GMT-03:00) Bahia', category: 'time_zone_region' },
            { pick_list_id: 'America/Belem***(GMT-03:00) Belem***GMT-3', item_name: '(GMT-03:00) Belem', category: 'time_zone_region' },
            { pick_list_id: 'America/Buenos_Aires***(GMT-03:00) Buenos Aires***GMT-3', item_name: '(GMT-03:00) Buenos Aires', category: 'time_zone_region' },
            { pick_list_id: 'America/Catamarca***(GMT-03:00) Catamarca***GMT-3', item_name: '(GMT-03:00) Catamarca', category: 'time_zone_region' },
            { pick_list_id: 'America/Cayenne***(GMT-03:00) Cayenne***GMT-3', item_name: '(GMT-03:00) Cayenne', category: 'time_zone_region' },
            { pick_list_id: 'America/Cordoba***(GMT-03:00) Cordoba***GMT-3', item_name: '(GMT-03:00) Cordoba', category: 'time_zone_region' },
            { pick_list_id: 'America/Fortaleza***(GMT-03:00) Fortaleza***GMT-3', item_name: '(GMT-03:00) Fortaleza', category: 'time_zone_region' },
            { pick_list_id: 'America/Glace_Bay***(GMT-03:00) Atlantic Daylight Time - Glace Bay***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Glace Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Goose_Bay***(GMT-03:00) Atlantic Daylight Time - Goose Bay***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Goose Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Halifax***(GMT-03:00) Atlantic Daylight Time - Halifax***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Halifax', category: 'time_zone_region' },
            { pick_list_id: 'America/Jujuy***(GMT-03:00) Jujuy***GMT-3', item_name: '(GMT-03:00) Jujuy', category: 'time_zone_region' },
            { pick_list_id: 'America/Maceio***(GMT-03:00) Maceio***GMT-3', item_name: '(GMT-03:00) Maceio', category: 'time_zone_region' },
            { pick_list_id: 'America/Mendoza***(GMT-03:00) Mendoza***GMT-3', item_name: '(GMT-03:00) Mendoza', category: 'time_zone_region' },
            { pick_list_id: 'America/Moncton***(GMT-03:00) Atlantic Daylight Time - Moncton***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Moncton', category: 'time_zone_region' },
            { pick_list_id: 'America/Montevideo***(GMT-03:00) Montevideo***GMT-3', item_name: '(GMT-03:00) Montevideo', category: 'time_zone_region' },
            { pick_list_id: 'America/Paramaribo***(GMT-03:00) Paramaribo***GMT-3', item_name: '(GMT-03:00) Paramaribo', category: 'time_zone_region' },
            { pick_list_id: 'America/Punta_Arenas***(GMT-03:00) Punta Arenas***GMT-3', item_name: '(GMT-03:00) Punta Arenas', category: 'time_zone_region' },
            { pick_list_id: 'America/Recife***(GMT-03:00) Recife***GMT-3', item_name: '(GMT-03:00) Recife', category: 'time_zone_region' },
            { pick_list_id: 'America/Rosario***(GMT-03:00) Rosario***GMT-3', item_name: '(GMT-03:00) Rosario', category: 'time_zone_region' },
            { pick_list_id: 'America/Santarem***(GMT-03:00) Santarem***GMT-3', item_name: '(GMT-03:00) Santarem', category: 'time_zone_region' },
            { pick_list_id: 'America/Sao_Paulo***(GMT-03:00) Sao Paulo***GMT-3', item_name: '(GMT-03:00) Sao Paulo', category: 'time_zone_region' },
            { pick_list_id: 'America/Thule***(GMT-03:00) Atlantic Daylight Time - Thule***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Thule', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Palmer***(GMT-03:00) Palmer***GMT-3', item_name: '(GMT-03:00) Palmer', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Rothera***(GMT-03:00) Rothera***GMT-3', item_name: '(GMT-03:00) Rothera', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Bermuda***(GMT-03:00) Atlantic Daylight Time - Bermuda***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Bermuda', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Stanley***(GMT-03:00) Stanley***GMT-3', item_name: '(GMT-03:00) Stanley', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/East***(GMT-03:00) East***GMT-3', item_name: '(GMT-03:00) East', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Atlantic***(GMT-03:00) Atlantic Daylight Time - Atlantic***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Atlantic', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Johns***(GMT-02:30) Newfoundland Daylight Time - St Johns***NDT', item_name: '(GMT-02:30) Newfoundland Daylight Time - St Johns', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Newfoundland***(GMT-02:30) Newfoundland Daylight Time - Newfoundland***NDT', item_name: '(GMT-02:30) Newfoundland Daylight Time - Newfoundland', category: 'time_zone_region' },
            { pick_list_id: 'America/Godthab***(GMT-02:00) Godthab***GMT-2', item_name: '(GMT-02:00) Godthab', category: 'time_zone_region' },
            { pick_list_id: 'America/Miquelon***(GMT-02:00) Miquelon***GMT-2', item_name: '(GMT-02:00) Miquelon', category: 'time_zone_region' },
            { pick_list_id: 'America/Noronha***(GMT-02:00) Noronha***GMT-2', item_name: '(GMT-02:00) Noronha', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/South_Georgia***(GMT-02:00) South Georgia***GMT-2', item_name: '(GMT-02:00) South Georgia', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/DeNoronha***(GMT-02:00) DeNoronha***GMT-2', item_name: '(GMT-02:00) DeNoronha', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Cape_Verde***(GMT-01:00) Cape Verde***GMT-1', item_name: '(GMT-01:00) Cape Verde', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Abidjan***(GMT+00:00) Greenwich Mean Time - Abidjan***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Abidjan', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Accra***(GMT+00:00) Greenwich Mean Time - Accra***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Accra', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bamako***(GMT+00:00) Greenwich Mean Time - Bamako***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Bamako', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Banjul***(GMT+00:00) Greenwich Mean Time - Banjul***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Banjul', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bissau***(GMT+00:00) Greenwich Mean Time - Bissau***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Bissau', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Conakry***(GMT+00:00) Greenwich Mean Time - Conakry***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Conakry', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Dakar***(GMT+00:00) Greenwich Mean Time - Dakar***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Dakar', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Freetown***(GMT+00:00) Greenwich Mean Time - Freetown***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Freetown', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lome***(GMT+00:00) Greenwich Mean Time - Lome***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Lome', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Monrovia***(GMT+00:00) Greenwich Mean Time - Monrovia***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Monrovia', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Nouakchott***(GMT+00:00) Greenwich Mean Time - Nouakchott***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Nouakchott', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Ouagadougou***(GMT+00:00) Greenwich Mean Time - Ouagadougou***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Ouagadougou', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Sao_Tome***(GMT+00:00) Greenwich Mean Time - Sao Tome***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Sao Tome', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Timbuktu***(GMT+00:00) Greenwich Mean Time - Timbuktu***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Timbuktu', category: 'time_zone_region' },
            { pick_list_id: 'America/Danmarkshavn***(GMT+00:00) Greenwich Mean Time - Danmarkshavn***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Danmarkshavn', category: 'time_zone_region' },
            { pick_list_id: 'America/Scoresbysund***(GMT+00:00) Coordinated Universal Time - Scoresbysund***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Scoresbysund', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Azores***(GMT+00:00) Coordinated Universal Time - Azores***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Azores', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Reykjavik***(GMT+00:00) Greenwich Mean Time - Reykjavik***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Reykjavik', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/St_Helena***(GMT+00:00) Greenwich Mean Time - St Helena***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - St Helena', category: 'time_zone_region' },
            { pick_list_id: 'GMT***(GMT+00:00) Greenwich Mean Time - GMT***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - GMT', category: 'time_zone_region' },
            { pick_list_id: 'Greenwich***(GMT+00:00) Greenwich Mean Time - Greenwich***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Greenwich', category: 'time_zone_region' },
            { pick_list_id: 'Iceland***(GMT+00:00) Greenwich Mean Time - Iceland***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Iceland', category: 'time_zone_region' },
            { pick_list_id: 'Universal***(GMT+00:00) Coordinated Universal Time - Universal***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Universal', category: 'time_zone_region' },
            { pick_list_id: 'UTC***(GMT+00:00) Coordinated Universal Time - UTC***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - UTC', category: 'time_zone_region' },
            { pick_list_id: 'Zulu***(GMT+00:00) Coordinated Universal Time - Zulu***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Zulu', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Algiers***(GMT+01:00) Central European Time - Algiers***CET', item_name: '(GMT+01:00) Central European Time - Algiers', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bangui***(GMT+01:00) West Africa Time - Bangui***WAT', item_name: '(GMT+01:00) West Africa Time - Bangui', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Brazzaville***(GMT+01:00) West Africa Time - Brazzaville***WAT', item_name: '(GMT+01:00) West Africa Time - Brazzaville', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Casablanca***(GMT+01:00) Western European Summer Time - Casablanca***WEST', item_name: '(GMT+01:00) Western European Summer Time - Casablanca', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Douala***(GMT+01:00) West Africa Time - Douala***WAT', item_name: '(GMT+01:00) West Africa Time - Douala', category: 'time_zone_region' },
            { pick_list_id: 'Africa/El_Aaiun***(GMT+01:00) Western European Summer Time - El Aaiun***WEST', item_name: '(GMT+01:00) Western European Summer Time - El Aaiun', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Kinshasa***(GMT+01:00) West Africa Time - Kinshasa***WAT', item_name: '(GMT+01:00) West Africa Time - Kinshasa', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lagos***(GMT+01:00) West Africa Time - Lagos***WAT', item_name: '(GMT+01:00) West Africa Time - Lagos', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Libreville***(GMT+01:00) West Africa Time - Libreville***WAT', item_name: '(GMT+01:00) West Africa Time - Libreville', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Luanda***(GMT+01:00) West Africa Time - Luanda***WAT', item_name: '(GMT+01:00) West Africa Time - Luanda', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Malabo***(GMT+01:00) West Africa Time - Malabo***WAT', item_name: '(GMT+01:00) West Africa Time - Malabo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Ndjamena***(GMT+01:00) West Africa Time - Ndjamena***WAT', item_name: '(GMT+01:00) West Africa Time - Ndjamena', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Niamey***(GMT+01:00) West Africa Time - Niamey***WAT', item_name: '(GMT+01:00) West Africa Time - Niamey', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Porto-Novo***(GMT+01:00) West Africa Time - Porto-Novo***WAT', item_name: '(GMT+01:00) West Africa Time - Porto-Novo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Tunis***(GMT+01:00) Central European Time - Tunis***CET', item_name: '(GMT+01:00) Central European Time - Tunis', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Windhoek***(GMT+01:00) West Africa Time - Windhoek***WAT', item_name: '(GMT+01:00) West Africa Time - Windhoek', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Canary***(GMT+01:00) Western European Summer Time - Canary***WEST', item_name: '(GMT+01:00) Western European Summer Time - Canary', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Faeroe***(GMT+01:00) Western European Summer Time - Faeroe***WEST', item_name: '(GMT+01:00) Western European Summer Time - Faeroe', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Faroe***(GMT+01:00) Western European Summer Time - Faroe***WEST', item_name: '(GMT+01:00) Western European Summer Time - Faroe', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Madeira***(GMT+01:00) Western European Summer Time - Madeira***WEST', item_name: '(GMT+01:00) Western European Summer Time - Madeira', category: 'time_zone_region' },
            { pick_list_id: 'Eire***(GMT+01:00) Irish Standard Time - Eire***IST', item_name: '(GMT+01:00) Irish Standard Time - Eire', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Belfast***(GMT+01:00) British Summer Time - Belfast***BST', item_name: '(GMT+01:00) British Summer Time - Belfast', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Dublin***(GMT+01:00) Irish Standard Time - Dublin***IST', item_name: '(GMT+01:00) Irish Standard Time - Dublin', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Guernsey***(GMT+01:00) British Summer Time - Guernsey***BST', item_name: '(GMT+01:00) British Summer Time - Guernsey', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Isle_of_Man***(GMT+01:00) British Summer Time - Isle of Man***BST', item_name: '(GMT+01:00) British Summer Time - Isle of Man', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Jersey***(GMT+01:00) British Summer Time - Jersey***BST', item_name: '(GMT+01:00) British Summer Time - Jersey', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Lisbon***(GMT+01:00) Western European Summer Time - Lisbon***WEST', item_name: '(GMT+01:00) Western European Summer Time - Lisbon', category: 'time_zone_region' },
            { pick_list_id: 'Europe/London***(GMT+01:00) British Summer Time - London***BST', item_name: '(GMT+01:00) British Summer Time - London', category: 'time_zone_region' },
            { pick_list_id: 'GB***(GMT+01:00) British Summer Time - GB***BST', item_name: '(GMT+01:00) British Summer Time - GB', category: 'time_zone_region' },
            { pick_list_id: 'GB-Eire***(GMT+01:00) British Summer Time - GB-Eire***BST', item_name: '(GMT+01:00) British Summer Time - GB-Eire', category: 'time_zone_region' },
            { pick_list_id: 'Portugal***(GMT+01:00) Western European Summer Time - Portugal***WEST', item_name: '(GMT+01:00) Western European Summer Time - Portugal', category: 'time_zone_region' },
            { pick_list_id: 'WET***(GMT+01:00) Western European Summer Time - WET***WEST', item_name: '(GMT+01:00) Western European Summer Time - WET', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Blantyre***(GMT+02:00) Central Africa Time - Blantyre***CAT', item_name: '(GMT+02:00) Central Africa Time - Blantyre', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bujumbura***(GMT+02:00) Central Africa Time - Bujumbura***CAT', item_name: '(GMT+02:00) Central Africa Time - Bujumbura', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Cairo***(GMT+02:00) Eastern European Time - Cairo***EET', item_name: '(GMT+02:00) Eastern European Time - Cairo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Ceuta***(GMT+02:00) Central European Summer Time - Ceuta***CEST', item_name: '(GMT+02:00) Central European Summer Time - Ceuta', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Gaborone***(GMT+02:00) Central Africa Time - Gaborone***CAT', item_name: '(GMT+02:00) Central Africa Time - Gaborone', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Harare***(GMT+02:00) Central Africa Time - Harare***CAT', item_name: '(GMT+02:00) Central Africa Time - Harare', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Johannesburg***(GMT+02:00) South African Standard Time - Johannesburg***SAST', item_name: '(GMT+02:00) South African Standard Time - Johannesburg', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Kigali***(GMT+02:00) Central Africa Time - Kigali***CAT', item_name: '(GMT+02:00) Central Africa Time - Kigali', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lubumbashi***(GMT+02:00) Central Africa Time - Lubumbashi***CAT', item_name: '(GMT+02:00) Central Africa Time - Lubumbashi', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lusaka***(GMT+02:00) Central Africa Time - Lusaka***CAT', item_name: '(GMT+02:00) Central Africa Time - Lusaka', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Maputo***(GMT+02:00) Central Africa Time - Maputo***CAT', item_name: '(GMT+02:00) Central Africa Time - Maputo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Maseru***(GMT+02:00) South African Standard Time - Maseru***SAST', item_name: '(GMT+02:00) South African Standard Time - Maseru', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Mbabane***(GMT+02:00) South African Standard Time - Mbabane***SAST', item_name: '(GMT+02:00) South African Standard Time - Mbabane', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Tripoli***(GMT+02:00) Eastern European Time - Tripoli***EET', item_name: '(GMT+02:00) Eastern European Time - Tripoli', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Troll***(GMT+02:00) Troll***GMT+02', item_name: '(GMT+02:00) Troll', category: 'time_zone_region' },
            { pick_list_id: 'Arctic/Longyearbyen***(GMT+02:00) Central European Summer Time - Longyearbyen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Longyearbyen', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Jan_Mayen***(GMT+02:00) Central European Summer Time - Jan Mayen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Jan Mayen', category: 'time_zone_region' },
            { pick_list_id: 'CET***(GMT+02:00) Central European Summer Time - CET***CEST', item_name: '(GMT+02:00) Central European Summer Time - CET', category: 'time_zone_region' },
            { pick_list_id: 'Egypt***(GMT+02:00) Eastern European Time - Egypt***EET', item_name: '(GMT+02:00) Eastern European Time - Egypt', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Amsterdam***(GMT+02:00) Central European Summer Time - Amsterdam***CEST', item_name: '(GMT+02:00) Central European Summer Time - Amsterdam', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Andorra***(GMT+02:00) Central European Summer Time - Andorra***CEST', item_name: '(GMT+02:00) Central European Summer Time - Andorra', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Belgrade***(GMT+02:00) Central European Summer Time - Belgrade***CEST', item_name: '(GMT+02:00) Central European Summer Time - Belgrade', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Berlin***(GMT+02:00) Central European Summer Time - Berlin***CEST', item_name: '(GMT+02:00) Central European Summer Time - Berlin', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Bratislava***(GMT+02:00) Central European Summer Time - Bratislava***CEST', item_name: '(GMT+02:00) Central European Summer Time - Bratislava', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Brussels***(GMT+02:00) Central European Summer Time - Brussels***CEST', item_name: '(GMT+02:00) Central European Summer Time - Brussels', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Budapest***(GMT+02:00) Central European Summer Time - Budapest***CEST', item_name: '(GMT+02:00) Central European Summer Time - Budapest', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Busingen***(GMT+02:00) Central European Summer Time - Busingen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Busingen', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Copenhagen***(GMT+02:00) Central European Summer Time - Copenhagen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Copenhagen', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Gibraltar***(GMT+02:00) Central European Summer Time - Gibraltar***CEST', item_name: '(GMT+02:00) Central European Summer Time - Gibraltar', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Kaliningrad***(GMT+02:00) Eastern European Time - Kaliningrad***EET', item_name: '(GMT+02:00) Eastern European Time - Kaliningrad', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Ljubljana***(GMT+02:00) Central European Summer Time - Ljubljana***CEST', item_name: '(GMT+02:00) Central European Summer Time - Ljubljana', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Luxembourg***(GMT+02:00) Central European Summer Time - Luxembourg***CEST', item_name: '(GMT+02:00) Central European Summer Time - Luxembourg', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Madrid***(GMT+02:00) Central European Summer Time - Madrid***CEST', item_name: '(GMT+02:00) Central European Summer Time - Madrid', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Malta***(GMT+02:00) Central European Summer Time - Malta***CEST', item_name: '(GMT+02:00) Central European Summer Time - Malta', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Monaco***(GMT+02:00) Central European Summer Time - Monaco***CEST', item_name: '(GMT+02:00) Central European Summer Time - Monaco', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Oslo***(GMT+02:00) Central European Summer Time - Oslo***CEST', item_name: '(GMT+02:00) Central European Summer Time - Oslo', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Paris***(GMT+02:00) Central European Summer Time - Paris***CEST', item_name: '(GMT+02:00) Central European Summer Time - Paris', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Podgorica***(GMT+02:00) Central European Summer Time - Podgorica***CEST', item_name: '(GMT+02:00) Central European Summer Time - Podgorica', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Prague***(GMT+02:00) Central European Summer Time - Prague***CEST', item_name: '(GMT+02:00) Central European Summer Time - Prague', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Rome***(GMT+02:00) Central European Summer Time - Rome***CEST', item_name: '(GMT+02:00) Central European Summer Time - Rome', category: 'time_zone_region' },
            { pick_list_id: 'Europe/San_Marino***(GMT+02:00) Central European Summer Time - San Marino***CEST', item_name: '(GMT+02:00) Central European Summer Time - San Marino', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Sarajevo***(GMT+02:00) Central European Summer Time - Sarajevo***CEST', item_name: '(GMT+02:00) Central European Summer Time - Sarajevo', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Skopje***(GMT+02:00) Central European Summer Time - Skopje***CEST', item_name: '(GMT+02:00) Central European Summer Time - Skopje', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Stockholm***(GMT+02:00) Central European Summer Time - Stockholm***CEST', item_name: '(GMT+02:00) Central European Summer Time - Stockholm', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Tirane***(GMT+02:00) Central European Summer Time - Tirane***CEST', item_name: '(GMT+02:00) Central European Summer Time - Tirane', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vaduz***(GMT+02:00) Central European Summer Time - Vaduz***CEST', item_name: '(GMT+02:00) Central European Summer Time - Vaduz', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vatican***(GMT+02:00) Central European Summer Time - Vatican***CEST', item_name: '(GMT+02:00) Central European Summer Time - Vatican', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vienna***(GMT+02:00) Central European Summer Time - Vienna***CEST', item_name: '(GMT+02:00) Central European Summer Time - Vienna', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Warsaw***(GMT+02:00) Central European Summer Time - Warsaw***CEST', item_name: '(GMT+02:00) Central European Summer Time - Warsaw', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Zagreb***(GMT+02:00) Central European Summer Time - Zagreb***CEST', item_name: '(GMT+02:00) Central European Summer Time - Zagreb', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Zurich***(GMT+02:00) Central European Summer Time - Zurich***CEST', item_name: '(GMT+02:00) Central European Summer Time - Zurich', category: 'time_zone_region' },
            { pick_list_id: 'Libya***(GMT+02:00) Eastern European Time - Libya***EET', item_name: '(GMT+02:00) Eastern European Time - Libya', category: 'time_zone_region' },
            { pick_list_id: 'MET***(GMT+02:00) Middle European Summer Time - MET***MEST', item_name: '(GMT+02:00) Middle European Summer Time - MET', category: 'time_zone_region' },
            { pick_list_id: 'Poland***(GMT+02:00) Central European Summer Time - Poland***CEST', item_name: '(GMT+02:00) Central European Summer Time - Poland', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Addis_Ababa***(GMT+03:00) Eastern Africa Time - Addis Ababa***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Addis Ababa', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Asmara***(GMT+03:00) Eastern Africa Time - Asmara***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Asmara', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Asmera***(GMT+03:00) Eastern Africa Time - Asmera***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Asmera', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Dar_es_Salaam***(GMT+03:00) Eastern Africa Time - Dar es Salaam***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Dar es Salaam', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Djibouti***(GMT+03:00) Eastern Africa Time - Djibouti***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Djibouti', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Juba***(GMT+03:00) Eastern Africa Time - Juba***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Juba', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Kampala***(GMT+03:00) Eastern Africa Time - Kampala***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Kampala', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Khartoum***(GMT+03:00) Eastern Africa Time - Khartoum***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Khartoum', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Mogadishu***(GMT+03:00) Eastern Africa Time - Mogadishu***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Mogadishu', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Nairobi***(GMT+03:00) Eastern Africa Time - Nairobi***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Nairobi', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Syowa***(GMT+03:00) Syowa***GMT+03', item_name: '(GMT+03:00) Syowa', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Aden***(GMT+03:00) Aden***GMT+03', item_name: '(GMT+03:00) Aden', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Amman***(GMT+03:00) Eastern European Summer Time - Amman***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Amman', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Baghdad***(GMT+03:00) Baghdad***GMT+03', item_name: '(GMT+03:00) Baghdad', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Bahrain***(GMT+03:00) Bahrain***GMT+03', item_name: '(GMT+03:00) Bahrain', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Beirut***(GMT+03:00) Eastern European Summer Time - Beirut***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Beirut', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Damascus***(GMT+03:00) Eastern European Summer Time - Damascus***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Damascus', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Famagusta***(GMT+03:00) Famagusta***GMT+03', item_name: '(GMT+03:00) Famagusta', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Gaza***(GMT+03:00) Eastern European Summer Time - Gaza***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Gaza', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Hebron***(GMT+03:00) Eastern European Summer Time - Hebron***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Hebron', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Istanbul***(GMT+03:00) Istanbul***GMT+03', item_name: '(GMT+03:00) Istanbul', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Jerusalem***(GMT+03:00) Israel Daylight Time - Jerusalem***IDT', item_name: '(GMT+03:00) Israel Daylight Time - Jerusalem', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kuwait***(GMT+03:00) Kuwait***GMT+03', item_name: '(GMT+03:00) Kuwait', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Nicosia***(GMT+03:00) Eastern European Summer Time - Nicosia***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Nicosia', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Qatar***(GMT+03:00) Qatar***GMT+03', item_name: '(GMT+03:00) Qatar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Riyadh***(GMT+03:00) Riyadh***GMT+03', item_name: '(GMT+03:00) Riyadh', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Tel_Aviv***(GMT+03:00) Israel Daylight Time - Tel Aviv***IDT', item_name: '(GMT+03:00) Israel Daylight Time - Tel Aviv', category: 'time_zone_region' },
            { pick_list_id: 'EET***(GMT+03:00) Eastern European Summer Time - EET***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - EET', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Athens***(GMT+03:00) Eastern European Summer Time - Athens***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Athens', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Bucharest***(GMT+03:00) Eastern European Summer Time - Bucharest***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Bucharest', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Chisinau***(GMT+03:00) Eastern European Summer Time - Chisinau***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Chisinau', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Helsinki***(GMT+03:00) Eastern European Summer Time - Helsinki***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Helsinki', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Istanbul***(GMT+03:00) Istanbul***GMT+03', item_name: '(GMT+03:00) Istanbul', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Kiev***(GMT+03:00) Eastern European Summer Time - Kiev***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Kiev', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Kirov***(GMT+03:00) Kirov***GMT+03', item_name: '(GMT+03:00) Kirov', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Mariehamn***(GMT+03:00) Eastern European Summer Time - Mariehamn***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Mariehamn', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Minsk***(GMT+03:00) Minsk***GMT+03', item_name: '(GMT+03:00) Minsk', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Moscow***(GMT+03:00) Moscow Standard Time - Moscow***MSK', item_name: '(GMT+03:00) Moscow Standard Time - Moscow', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Nicosia***(GMT+03:00) Eastern European Summer Time - Nicosia***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Nicosia', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Riga***(GMT+03:00) Eastern European Summer Time - Riga***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Riga', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Simferopol***(GMT+03:00) Moscow Standard Time - Simferopol***MSK', item_name: '(GMT+03:00) Moscow Standard Time - Simferopol', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Sofia***(GMT+03:00) Eastern European Summer Time - Sofia***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Sofia', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Tallinn***(GMT+03:00) Eastern European Summer Time - Tallinn***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Tallinn', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Tiraspol***(GMT+03:00) Eastern European Summer Time - Tiraspol***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Tiraspol', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Uzhgorod***(GMT+03:00) Eastern European Summer Time - Uzhgorod***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Uzhgorod', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vilnius***(GMT+03:00) Eastern European Summer Time - Vilnius***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Vilnius', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Volgograd***(GMT+03:00) Volgograd***GMT+03', item_name: '(GMT+03:00) Volgograd', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Zaporozhye***(GMT+03:00) Eastern European Summer Time - Zaporozhye***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Zaporozhye', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Antananarivo***(GMT+03:00) Eastern Africa Time - Antananarivo***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Antananarivo', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Comoro***(GMT+03:00) Eastern Africa Time - Comoro***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Comoro', category: 'time_zone_region' },
            { pick_list_id: 'Israel***(GMT+03:00) Israel Daylight Time - Israel***IDT', item_name: '(GMT+03:00) Israel Daylight Time - Israel', category: 'time_zone_region' },
            { pick_list_id: 'Turkey***(GMT+03:00) Turkey***GMT+03', item_name: '(GMT+03:00) Turkey', category: 'time_zone_region' },
            { pick_list_id: 'W-SU***(GMT+03:00) Moscow Standard Time - W-SU***MSK', item_name: '(GMT+03:00) Moscow Standard Time - W-SU', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Baku***(GMT+04:00) Baku***GMT+04', item_name: '(GMT+04:00) Baku', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Dubai***(GMT+04:00) Dubai***GMT+04', item_name: '(GMT+04:00) Dubai', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Dhaka***(GMT+06:00) Dhaka***GMT+6', item_name: '(GMT+06:00) Dhaka', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kashgar***(GMT+06:00) Kashgar***GMT+6', item_name: '(GMT+06:00) Kashgar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Omsk***(GMT+06:00) Omsk***GMT+6', item_name: '(GMT+06:00) Omsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Qyzylorda***(GMT+06:00) Qyzylorda***GMT+6', item_name: '(GMT+06:00) Qyzylorda', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Thimbu***(GMT+06:00) Thimbu***GMT+6', item_name: '(GMT+06:00) Thimbu', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Thimphu***(GMT+06:00) Thimphu***GMT+6', item_name: '(GMT+06:00) Thimphu', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Urumqi***(GMT+06:00) Urumqi***GMT+6', item_name: '(GMT+06:00) Urumqi', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Chagos***(GMT+06:00) Chagos***GMT+6', item_name: '(GMT+06:00) Chagos', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Rangoon***(GMT+06:30) Rangoon***GMT+6:30', item_name: '(GMT+06:30) Rangoon', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Yangon***(GMT+06:30) Yangon***GMT+6:30', item_name: '(GMT+06:30) Yangon', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Cocos***(GMT+06:30) Cocos***GMT+6:30', item_name: '(GMT+06:30) Cocos', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Davis***(GMT+07:00) Davis***GMT+7', item_name: '(GMT+07:00) Davis', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Bangkok***(GMT+07:00) Bangkok***GMT+7', item_name: '(GMT+07:00) Bangkok', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Barnaul***(GMT+07:00) Barnaul***GMT+7', item_name: '(GMT+07:00) Barnaul', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Hovd***(GMT+07:00) Hovd***GMT+7', item_name: '(GMT+07:00) Hovd', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ho_Chi_Minh***(GMT+07:00) Ho Chi Minh***GMT+7', item_name: '(GMT+07:00) Ho Chi Minh', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Jakarta***(GMT+07:00) Western Indonesia Time - Jakarta***WIB', item_name: '(GMT+07:00) Western Indonesia Time - Jakarta', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Krasnoyarsk***(GMT+07:00) Krasnoyarsk***GMT+7', item_name: '(GMT+07:00) Krasnoyarsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Novokuznetsk***(GMT+07:00) Novokuznetsk***GMT+7', item_name: '(GMT+07:00) Novokuznetsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Novosibirsk***(GMT+07:00) Novosibirsk***GMT+7', item_name: '(GMT+07:00) Novosibirsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Phnom_Penh***(GMT+07:00) Phnom Penh***GMT+7', item_name: '(GMT+07:00) Phnom Penh', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Pontianak***(GMT+07:00) Western Indonesia Time - Pontianak***WIB', item_name: '(GMT+07:00) Western Indonesia Time - Pontianak', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Saigon***(GMT+07:00) Saigon***GMT+7', item_name: '(GMT+07:00) Saigon', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Tomsk***(GMT+07:00) Tomsk***GMT+7', item_name: '(GMT+07:00) Tomsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Vientiane***(GMT+07:00) Vientiane***GMT+7', item_name: '(GMT+07:00) Vientiane', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Christmas***(GMT+07:00) Christmas***GMT+7', item_name: '(GMT+07:00) Christmas', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Brunei***(GMT+08:00) Brunei***GMT+8', item_name: '(GMT+08:00) Brunei', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Choibalsan***(GMT+08:00) Choibalsan***GMT+8', item_name: '(GMT+08:00) Choibalsan', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Chongqing***(GMT+08:00) China Standard Time - Chongqing***CST', item_name: '(GMT+08:00) China Standard Time - Chongqing', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Chungking***(GMT+08:00) China Standard Time - Chungking***CST', item_name: '(GMT+08:00) China Standard Time - Chungking', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Harbin***(GMT+08:00) China Standard Time - Harbin***CST', item_name: '(GMT+08:00) China Standard Time - Harbin', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Hong_Kong***(GMT+08:00) Hong Kong Time - Hong Kong***HKT', item_name: '(GMT+08:00) Hong Kong Time - Hong Kong', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Irkutsk***(GMT+08:00) Irkutsk***GMT+8', item_name: '(GMT+08:00) Irkutsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kuala_Lumpur***(GMT+08:00) Kuala Lumpur***GMT+8', item_name: '(GMT+08:00) Kuala Lumpur', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kuching***(GMT+08:00) Kuching***GMT+8', item_name: '(GMT+08:00) Kuching', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Macao***(GMT+08:00) China Standard Time - Macao***CST', item_name: '(GMT+08:00) China Standard Time - Macao', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Macau***(GMT+08:00) China Standard Time - Macau***CST', item_name: '(GMT+08:00) China Standard Time - Macau', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Makassar***(GMT+08:00) Central Indonesian Time - Makassar***WITA', item_name: '(GMT+08:00) Central Indonesian Time - Makassar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Manila***(GMT+08:00) Manila***GMT+8', item_name: '(GMT+08:00) Manila', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Shanghai***(GMT+08:00) China Standard Time - Shanghai***CST', item_name: '(GMT+08:00) China Standard Time - Shanghai', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Singapore***(GMT+08:00) Singapore***GMT+8', item_name: '(GMT+08:00) Singapore', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Taipei***(GMT+08:00) China Standard Time - Taipei***CST', item_name: '(GMT+08:00) China Standard Time - Taipei', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ujung_Pandang***(GMT+08:00) Central Indonesian Time - Ujung Pandang***WITA', item_name: '(GMT+08:00) Central Indonesian Time - Ujung Pandang', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ulaanbaatar***(GMT+08:00) Ulaanbaatar***GMT+8', item_name: '(GMT+08:00) Ulaanbaatar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ulan_Bator***(GMT+08:00) Ulan Bator***GMT+8', item_name: '(GMT+08:00) Ulan Bator', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Perth***(GMT+08:00) Australian Western Standard Time - Perth***AWST', item_name: '(GMT+08:00) Australian Western Standard Time - Perth', category: 'time_zone_region' },
            { pick_list_id: 'Australia/West***(GMT+08:00) Australian Western Standard Time - West***AWST', item_name: '(GMT+08:00) Australian Western Standard Time - West', category: 'time_zone_region' },
            { pick_list_id: 'Hongkong***(GMT+08:00) Hong Kong Time - Hongkong***HKT', item_name: '(GMT+08:00) Hong Kong Time - Hongkong', category: 'time_zone_region' },
            { pick_list_id: 'PRC***(GMT+08:00) China Standard Time - PRC***CST', item_name: '(GMT+08:00) China Standard Time - PRC', category: 'time_zone_region' },
            { pick_list_id: 'ROC***(GMT+08:00) China Standard Time - ROC***CST', item_name: '(GMT+08:00) China Standard Time - ROC', category: 'time_zone_region' },
            { pick_list_id: 'Singapore***(GMT+08:00) Singapore***GMT+8', item_name: '(GMT+08:00) Singapore', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Pyongyang***(GMT+08:30) Korea Standard Time - Pyongyang***KST', item_name: '(GMT+08:30) Korea Standard Time - Pyongyang', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Eucla***(GMT+08:45) Eucla***GMT+8:45', item_name: '(GMT+08:45) Eucla', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Chita***(GMT+09:00) Chita***GMT+9', item_name: '(GMT+09:00) Chita', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Dili***(GMT+09:00) Dili***GMT+9', item_name: '(GMT+09:00) Dili', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Jayapura***(GMT+09:00) Eastern Indonesia Time - Jayapura***WIT', item_name: '(GMT+09:00) Eastern Indonesia Time - Jayapura', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Khandyga***(GMT+09:00) Khandyga***GMT+9', item_name: '(GMT+09:00) Khandyga', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Seoul***(GMT+09:00) Korea Standard Time - Seoul***KST', item_name: '(GMT+09:00) Korea Standard Time - Seoul', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Tokyo***(GMT+09:00) Japan Standard Time - Tokyo***JST', item_name: '(GMT+09:00) Japan Standard Time - Tokyo', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Yakutsk***(GMT+09:00) Yakutsk***GMT+9', item_name: '(GMT+09:00) Yakutsk', category: 'time_zone_region' },
            { pick_list_id: 'Japan***(GMT+09:00) Japan Standard Time - Japan***JST', item_name: '(GMT+09:00) Japan Standard Time - Japan', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Palau***(GMT+09:00) Palau***GMT+9', item_name: '(GMT+09:00) Palau', category: 'time_zone_region' },
            { pick_list_id: 'ROK***(GMT+09:00) Korea Standard Time - ROK***KST', item_name: '(GMT+09:00) Korea Standard Time - ROK', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Adelaide***(GMT+09:30) Australian Central Standard Time - Adelaide***ACST', item_name: '(GMT+09:30) Australian Central Standard Time - Adelaide', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Broken_Hill***(GMT+09:30) Australian Central Standard Time - Broken Hill***ACST', item_name: '(GMT+09:30) Australian Central Standard Time - Broken Hill', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Darwin***(GMT+09:30) Australian Central Standard Time - Darwin***ACST', item_name: '(GMT+09:30) Australian Central Standard Time - Darwin', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/DumontDUrville***(GMT+10:00) DumontDUrville***GMT+10', item_name: '(GMT+10:00) DumontDUrville', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ust-Nera***(GMT+10:00) Ust-Nera***GMT+10', item_name: '(GMT+10:00) Ust-Nera', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Vladivostok***(GMT+10:00) Vladivostok***GMT+10', item_name: '(GMT+10:00) Vladivostok', category: 'time_zone_region' },
            { pick_list_id: 'Australia/ACT***(GMT+10:00) Australian Eastern Standard Time - ACT***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - ACT', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Brisbane***(GMT+10:00) Australian Eastern Standard Time - Brisbane***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Brisbane', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Canberra***(GMT+10:00) Australian Eastern Standard Time - Canberra***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Canberra', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Currie***(GMT+10:00) Australian Eastern Standard Time - Currie***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Currie', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Hobart***(GMT+10:00) Australian Eastern Standard Time - Hobart***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Hobart', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Lindeman***(GMT+10:00) Australian Eastern Standard Time - Lindeman***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Lindeman', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Melbourne***(GMT+10:00) Australian Eastern Standard Time - Melbourne***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Melbourne', category: 'time_zone_region' },
            { pick_list_id: 'Australia/NSW***(GMT+10:00) Australian Eastern Standard Time - NSW***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - NSW', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Queensland***(GMT+10:00) Australian Eastern Standard Time - Queensland***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Queensland', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Sydney***(GMT+10:00) Australian Eastern Standard Time - Sydney***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Sydney', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Tasmania***(GMT+10:00) Australian Eastern Standard Time - Tasmania***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Tasmania', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Victoria***(GMT+10:00) Australian Eastern Standard Time - Victoria***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Victoria', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Chuuk***(GMT+10:00) Chuuk***GMT+10', item_name: '(GMT+10:00) Chuuk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Guam***(GMT+10:00) Chamorro Standard Time - Guam***ChST', item_name: '(GMT+10:00) Chamorro Standard Time - Guam', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Port_Moresby***(GMT+10:00) Port Moresby***GMT+10', item_name: '(GMT+10:00) Port Moresby', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Saipan***(GMT+10:00) Chamorro Standard Time - Saipan***ChST', item_name: '(GMT+10:00) Chamorro Standard Time - Saipan', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Truk***(GMT+10:00) Truk***GMT+10', item_name: '(GMT+10:00) Truk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Yap***(GMT+10:00) Yap***GMT+10', item_name: '(GMT+10:00) Yap', category: 'time_zone_region' },
            { pick_list_id: 'Australia/LHI***(GMT+10:30) LHI***GMT+10:30', item_name: '(GMT+10:30) LHI', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Lord_Howe***(GMT+10:30) Lord Howe***GMT+10:30', item_name: '(GMT+10:30) Lord Howe', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Casey***(GMT+11:00) Casey***GMT+11', item_name: '(GMT+11:00) Casey', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Macquarie***(GMT+11:00) Macquarie***GMT+11', item_name: '(GMT+11:00) Macquarie', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Magadan***(GMT+11:00) Magadan***GMT+11', item_name: '(GMT+11:00) Magadan', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Srednekolymsk***(GMT+11:00) Srednekolymsk***GMT+11', item_name: '(GMT+11:00) Srednekolymsk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Bougainville***(GMT+11:00) Bougainville***GMT+11', item_name: '(GMT+11:00) Bougainville', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Efate***(GMT+11:00) Efate***GMT+11', item_name: '(GMT+11:00) Efate', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Guadalcanal***(GMT+11:00) Guadalcanal***GMT+11', item_name: '(GMT+11:00) Guadalcanal', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Kosrae***(GMT+11:00) Kosrae***GMT+11', item_name: '(GMT+11:00) Kosrae', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Norfolk***(GMT+11:00) Norfolk***GMT+11', item_name: '(GMT+11:00) Norfolk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Noumea***(GMT+11:00) Noumea***GMT+11', item_name: '(GMT+11:00) Noumea', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Pohnpei***(GMT+11:00) Pohnpei***GMT+11', item_name: '(GMT+11:00) Pohnpei', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Ponape***(GMT+11:00) Ponape***GMT+11', item_name: '(GMT+11:00) Ponape', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/McMurdo***(GMT+12:00) New Zealand Standard Time - McMurdo***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - McMurdo', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/South_Pole***(GMT+12:00) New Zealand Standard Time - South Pole***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - South Pole', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Anadyr***(GMT+12:00) Anadyr***GMT+12', item_name: '(GMT+12:00) Anadyr', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kamchatka***(GMT+12:00) Kamchatka***GMT+12', item_name: '(GMT+12:00) Kamchatka', category: 'time_zone_region' },
            { pick_list_id: 'Kwajalein***(GMT+12:00) Kwajalein***GMT+12', item_name: '(GMT+12:00) Kwajalein', category: 'time_zone_region' },
            { pick_list_id: 'NZ***(GMT+12:00) New Zealand Standard Time - NZ***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - NZ', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Auckland***(GMT+12:00) New Zealand Standard Time - Auckland***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - Auckland', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Fiji***(GMT+12:00) Fiji***GMT+12', item_name: '(GMT+12:00) Fiji', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Funafuti***(GMT+12:00) Funafuti***GMT+12', item_name: '(GMT+12:00) Funafuti', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Kwajalein***(GMT+12:00) Kwajalein***GMT+12', item_name: '(GMT+12:00) Kwajalein', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Majuro***(GMT+12:00) Majuro***GMT+12', item_name: '(GMT+12:00) Majuro', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Nauru***(GMT+12:00) Nauru***GMT+12', item_name: '(GMT+12:00) Nauru', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Tarawa***(GMT+12:00) Tarawa***GMT+12', item_name: '(GMT+12:00) Tarawa', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Wake***(GMT+12:00) Wake***GMT+12', item_name: '(GMT+12:00) Wake', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Wallis***(GMT+12:00) Wallis***GMT+12', item_name: '(GMT+12:00) Wallis', category: 'time_zone_region' },
            { pick_list_id: 'NZ-CHAT***(GMT+12:45) NZ-CHAT***GMT+12:45', item_name: '(GMT+12:45) NZ-CHAT', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Chatham***(GMT+12:45) Chatham***GMT+12:45', item_name: '(GMT+12:45) Chatham', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Apia***(GMT+13:00) Apia***GMT+13', item_name: '(GMT+13:00) Apia', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Enderbury***(GMT+13:00) Enderbury***GMT+13', item_name: '(GMT+13:00) Enderbury', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Fakaofo***(GMT+13:00) Fakaofo***GMT+13', item_name: '(GMT+13:00) Fakaofo', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Tongatapu***(GMT+13:00) Tongatapu***GMT+13', item_name: '(GMT+13:00) Tongatapu', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Kiritimati***(GMT+14:00) Kiritimati***GMT+14', item_name: '(GMT+14:00) Kiritimati', category: 'time_zone_region' }
        ]









        const time_zone_list_fr = [
            { pick_list_id: 'Pacific/Midway***(GMT-11:00) Samoa Standard Time - Midway***SST', item_name: '(GMT-11:00) Samoa Standard Time - Midway', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Niue***(GMT-11:00) Niue***GMT-11', item_name: '(GMT-11:00) Niue', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Pago_Pago***(GMT-11:00) Samoa Standard Time - Pago Pago***SST', item_name: '(GMT-11:00) Samoa Standard Time - Pago Pago', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Samoa***(GMT-11:00) Samoa Standard Time - Samoa***SST', item_name: '(GMT-11:00) Samoa Standard Time - Samoa', category: 'time_zone_region' },
            { pick_list_id: 'US/Samoa***(GMT-11:00) Samoa Standard Time - Samoa***SST', item_name: '(GMT-11:00) Samoa Standard Time - Samoa', category: 'time_zone_region' },
            { pick_list_id: 'HST***(GMT-10:00) Hawaii Standard Time - HST***HST', item_name: '(GMT-10:00) Hawaii Standard Time - HST', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Honolulu***(GMT-10:00) Hawaii Standard Time - Honolulu***HST', item_name: '(GMT-10:00) Hawaii Standard Time - Honolulu', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Johnston***(GMT-10:00) Hawaii Standard Time - Johnston***HST', item_name: '(GMT-10:00) Hawaii Standard Time - Johnston', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Rarotonga***(GMT-10:00) Rarotonga***GMT-10', item_name: '(GMT-10:00) Rarotonga', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Tahiti***(GMT-10:00) Tahiti***GMT-10', item_name: '(GMT-10:00) Tahiti', category: 'time_zone_region' },
            { pick_list_id: 'US/Hawaii***(GMT-10:00) Hawaii Standard Time - Hawaii***HST', item_name: '(GMT-10:00) Hawaii Standard Time - Hawaii', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Marquesas***(GMT-09:30) Marquesas***GMT-9:30', item_name: '(GMT-09:30) Marquesas', category: 'time_zone_region' },
            { pick_list_id: 'America/Adak***(GMT-09:00) Hawaii-Aleutian Daylight Time - Adak***HDT', item_name: '(GMT-09:00) Hawaii-Aleutian Daylight Time - Adak', category: 'time_zone_region' },
            { pick_list_id: 'America/Atka***(GMT-09:00) Hawaii-Aleutian Daylight Time - Atka***HDT', item_name: '(GMT-09:00) Hawaii-Aleutian Daylight Time - Atka', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Gambier***(GMT-09:00) Gambier***GMT-9', item_name: '(GMT-09:00) Gambier', category: 'time_zone_region' },
            { pick_list_id: 'US/Aleutian***(GMT-09:00) Hawaii-Aleutian Daylight Time - Aleutian***HDT', item_name: '(GMT-09:00) Hawaii-Aleutian Daylight Time - Aleutian', category: 'time_zone_region' },
            { pick_list_id: 'America/Anchorage***(GMT-08:00) Alaska Daylight Time - Anchorage***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Anchorage', category: 'time_zone_region' },
            { pick_list_id: 'America/Juneau***(GMT-08:00) Alaska Daylight Time - Juneau***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Juneau', category: 'time_zone_region' },
            { pick_list_id: 'America/Metlakatla***(GMT-08:00) Alaska Daylight Time - Metlakatla***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Metlakatla', category: 'time_zone_region' },
            { pick_list_id: 'America/Nome***(GMT-08:00) Alaska Daylight Time - Nome***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Nome', category: 'time_zone_region' },
            { pick_list_id: 'America/Sitka***(GMT-08:00) Alaska Daylight Time - Sitka***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Sitka', category: 'time_zone_region' },
            { pick_list_id: 'America/Yakutat***(GMT-08:00) Alaska Daylight Time - Yakutat***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Yakutat', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Pitcairn***(GMT-08:00) Pitcairn***GMT-8', item_name: '(GMT-08:00) Pitcairn', category: 'time_zone_region' },
            { pick_list_id: 'US/Alaska***(GMT-08:00) Alaska Daylight Time - Alaska***AKDT', item_name: '(GMT-08:00) Alaska Daylight Time - Alaska', category: 'time_zone_region' },
            { pick_list_id: 'America/Creston***(GMT-07:00) Mountain Standard Time - Creston***MST', item_name: '(GMT-07:00) Mountain Standard Time - Creston', category: 'time_zone_region' },
            { pick_list_id: 'America/Dawson***(GMT-07:00) Pacific Daylight Time - Dawson***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Dawson', category: 'time_zone_region' },
            { pick_list_id: 'America/Dawson_Creek***(GMT-07:00) Mountain Standard Time - Dawson Creek***MST', item_name: '(GMT-07:00) Mountain Standard Time - Dawson Creek', category: 'time_zone_region' },
            { pick_list_id: 'America/Ensenada***(GMT-07:00) Pacific Daylight Time - Ensenada***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Ensenada', category: 'time_zone_region' },
            { pick_list_id: 'America/Fort_Nelson***(GMT-07:00) Mountain Standard Time - Fort Nelson***MST', item_name: '(GMT-07:00) Mountain Standard Time - Fort Nelson', category: 'time_zone_region' },
            { pick_list_id: 'America/Hermosillo***(GMT-07:00) Mountain Standard Time - Hermosillo***MST', item_name: '(GMT-07:00) Mountain Standard Time - Hermosillo', category: 'time_zone_region' },
            { pick_list_id: 'America/Los_Angeles***(GMT-07:00) Pacific Daylight Time - Los Angeles***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Los Angeles', category: 'time_zone_region' },
            { pick_list_id: 'America/Phoenix***(GMT-07:00) Mountain Standard Time - Phoenix***MST', item_name: '(GMT-07:00) Mountain Standard Time - Phoenix', category: 'time_zone_region' },
            { pick_list_id: 'America/Santa_Isabel***(GMT-07:00) Pacific Daylight Time - Santa Isabel***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Santa Isabel', category: 'time_zone_region' },
            { pick_list_id: 'America/Tijuana***(GMT-07:00) Pacific Daylight Time - Tijuana***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Tijuana', category: 'time_zone_region' },
            { pick_list_id: 'America/Vancouver***(GMT-07:00) Pacific Daylight Time - Vancouver***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Vancouver', category: 'time_zone_region' },
            { pick_list_id: 'America/Whitehorse***(GMT-07:00) Pacific Daylight Time - Whitehorse***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Whitehorse', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Pacific***(GMT-07:00) Pacific Daylight Time - Pacific***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Pacific', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Yukon***(GMT-07:00) Pacific Daylight Time - Yukon***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Yukon', category: 'time_zone_region' },
            { pick_list_id: 'Mexico/BajaNorte***(GMT-07:00) Pacific Daylight Time - BajaNorte***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - BajaNorte', category: 'time_zone_region' },
            { pick_list_id: 'MST***(GMT-07:00) Mountain Standard Time - MST***MST', item_name: '(GMT-07:00) Mountain Standard Time - MST', category: 'time_zone_region' },
            { pick_list_id: 'US/Arizona***(GMT-07:00) Mountain Standard Time - Arizona***MST', item_name: '(GMT-07:00) Mountain Standard Time - Arizona', category: 'time_zone_region' },
            { pick_list_id: 'US/Pacific***(GMT-07:00) Pacific Daylight Time - Pacific***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Pacific', category: 'time_zone_region' },
            { pick_list_id: 'US/Pacific-New***(GMT-07:00) Pacific Daylight Time - Pacific-New***PDT', item_name: '(GMT-07:00) Pacific Daylight Time - Pacific-New', category: 'time_zone_region' },
            { pick_list_id: 'America/Belize***(GMT-06:00) Central Standard Time - Belize***CST', item_name: '(GMT-06:00) Central Standard Time - Belize', category: 'time_zone_region' },
            { pick_list_id: 'America/Boise***(GMT-06:00) Mountain Daylight Time - Boise***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Boise', category: 'time_zone_region' },
            { pick_list_id: 'America/Cambridge_Bay***(GMT-06:00) Mountain Daylight Time - Cambridge Bay***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Cambridge Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Chihuahua***(GMT-06:00) Mountain Daylight Time - Chihuahua***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Chihuahua', category: 'time_zone_region' },
            { pick_list_id: 'America/Costa_Rica***(GMT-06:00) Central Standard Time - Costa Rica***CST', item_name: '(GMT-06:00) Central Standard Time - Costa Rica', category: 'time_zone_region' },
            { pick_list_id: 'America/Denver***(GMT-06:00) Mountain Daylight Time - Denver***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Denver', category: 'time_zone_region' },
            { pick_list_id: 'America/Edmonton***(GMT-06:00) Mountain Daylight Time - Edmonton***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Edmonton', category: 'time_zone_region' },
            { pick_list_id: 'America/El_Salvador***(GMT-06:00) Central Standard Time - El Salvador***CST', item_name: '(GMT-06:00) Central Standard Time - El Salvador', category: 'time_zone_region' },
            { pick_list_id: 'America/Guatemala***(GMT-06:00) Central Standard Time - Guatemala***CST', item_name: '(GMT-06:00) Central Standard Time - Guatemala', category: 'time_zone_region' },
            { pick_list_id: 'America/Inuvik***(GMT-06:00) Mountain Daylight Time - Inuvik***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Inuvik', category: 'time_zone_region' },
            { pick_list_id: 'America/Managua***(GMT-06:00) Central Standard Time - Managua***CST', item_name: '(GMT-06:00) Central Standard Time - Managua', category: 'time_zone_region' },
            { pick_list_id: 'America/Mazatlan***(GMT-06:00) Mountain Daylight Time - Mazatlan***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Mazatlan', category: 'time_zone_region' },
            { pick_list_id: 'America/Ojinaga***(GMT-06:00) Mountain Daylight Time - Ojinaga***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Ojinaga', category: 'time_zone_region' },
            { pick_list_id: 'America/Regina***(GMT-06:00) Central Standard Time - Regina***CST', item_name: '(GMT-06:00) Central Standard Time - Regina', category: 'time_zone_region' },
            { pick_list_id: 'America/Shiprock***(GMT-06:00) Mountain Daylight Time - Shiprock***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Shiprock', category: 'time_zone_region' },
            { pick_list_id: 'America/Swift_Current***(GMT-06:00) Central Standard Time - Swift Current***CST', item_name: '(GMT-06:00) Central Standard Time - Swift Current', category: 'time_zone_region' },
            { pick_list_id: 'America/Tegucigalpa***(GMT-06:00) Central Standard Time - Tegucigalpa***CST', item_name: '(GMT-06:00) Central Standard Time - Tegucigalpa', category: 'time_zone_region' },
            { pick_list_id: 'America/Yellowknife***(GMT-06:00) Mountain Daylight Time - Yellowknife***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Yellowknife', category: 'time_zone_region' },
            { pick_list_id: 'Canada/East-Saskatchewan***(GMT-06:00) Central Standard Time - East-Saskatchewan***CST', item_name: '(GMT-06:00) Central Standard Time - East-Saskatchewan', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Mountain***(GMT-06:00) Mountain Daylight Time - Mountain***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Mountain', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Saskatchewan***(GMT-06:00) Central Standard Time - Saskatchewan***CST', item_name: '(GMT-06:00) Central Standard Time - Saskatchewan', category: 'time_zone_region' },
            { pick_list_id: 'Chile/EasterIsland***(GMT-06:00) EasterIsland***GMT-6', item_name: '(GMT-06:00) EasterIsland', category: 'time_zone_region' },
            { pick_list_id: 'Mexico/BajaSur***(GMT-06:00) Mountain Daylight Time - BajaSur***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - BajaSur', category: 'time_zone_region' },
            { pick_list_id: 'Navajo***(GMT-06:00) Mountain Daylight Time - Navajo***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Navajo', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Easter***(GMT-06:00) Easter***GMT-6', item_name: '(GMT-06:00) Easter', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Galapagos***(GMT-06:00) Galapagos***GMT-6', item_name: '(GMT-06:00) Galapagos', category: 'time_zone_region' },
            { pick_list_id: 'US/Mountain***(GMT-06:00) Mountain Daylight Time - Mountain***MDT', item_name: '(GMT-06:00) Mountain Daylight Time - Mountain', category: 'time_zone_region' },
            { pick_list_id: 'America/Atikokan***(GMT-05:00) Eastern Standard Time - Atikokan***EST', item_name: '(GMT-05:00) Eastern Standard Time - Atikokan', category: 'time_zone_region' },
            { pick_list_id: 'America/Bahia_Banderas***(GMT-05:00) Central Daylight Time - Bahia Banderas***CDT', item_name: '(GMT-05:00) Central Daylight Time - Bahia Banderas', category: 'time_zone_region' },
            { pick_list_id: 'America/Bogota***(GMT-05:00) Bogota***GMT-5', item_name: '(GMT-05:00) Bogota', category: 'time_zone_region' },
            { pick_list_id: 'America/Cancun***(GMT-05:00) Eastern Standard Time - Cancun***EST', item_name: '(GMT-05:00) Eastern Standard Time - Cancun', category: 'time_zone_region' },
            { pick_list_id: 'America/Cayman***(GMT-05:00) Eastern Standard Time - Cayman***EST', item_name: '(GMT-05:00) Eastern Standard Time - Cayman', category: 'time_zone_region' },
            { pick_list_id: 'America/Chicago***(GMT-05:00) Central Daylight Time - Chicago***CDT', item_name: '(GMT-05:00) Central Daylight Time - Chicago', category: 'time_zone_region' },
            { pick_list_id: 'America/Coral_Harbour***(GMT-05:00) Eastern Standard Time - Coral Harbour***EST', item_name: '(GMT-05:00) Eastern Standard Time - Coral Harbour', category: 'time_zone_region' },
            { pick_list_id: 'America/Eirunepe***(GMT-05:00) Eirunepe***GMT-5', item_name: '(GMT-05:00) Eirunepe', category: 'time_zone_region' },
            { pick_list_id: 'America/Guayaquil***(GMT-05:00) Guayaquil***GMT-5', item_name: '(GMT-05:00) Guayaquil', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Knox***(GMT-05:00) Central Daylight Time - Indiana/Knox***CDT', item_name: '(GMT-05:00) Central Daylight Time - Indiana/Knox', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Tell_City***(GMT-05:00) Central Daylight Time - Indiana/Tell City***CDT', item_name: '(GMT-05:00) Central Daylight Time - Indiana/Tell City', category: 'time_zone_region' },
            { pick_list_id: 'America/Jamaica***(GMT-05:00) Eastern Standard Time - Jamaica***EST', item_name: '(GMT-05:00) Eastern Standard Time - Jamaica', category: 'time_zone_region' },
            { pick_list_id: 'America/Knox_IN***(GMT-05:00) Central Daylight Time - Knox IN***CDT', item_name: '(GMT-05:00) Central Daylight Time - Knox IN', category: 'time_zone_region' },
            { pick_list_id: 'America/Lima***(GMT-05:00) Lima***GMT-5', item_name: '(GMT-05:00) Lima', category: 'time_zone_region' },
            { pick_list_id: 'America/Matamoros***(GMT-05:00) Central Daylight Time - Matamoros***CDT', item_name: '(GMT-05:00) Central Daylight Time - Matamoros', category: 'time_zone_region' },
            { pick_list_id: 'America/Menominee***(GMT-05:00) Central Daylight Time - Menominee***CDT', item_name: '(GMT-05:00) Central Daylight Time - Menominee', category: 'time_zone_region' },
            { pick_list_id: 'America/Merida***(GMT-05:00) Central Daylight Time - Merida***CDT', item_name: '(GMT-05:00) Central Daylight Time - Merida', category: 'time_zone_region' },
            { pick_list_id: 'America/Mexico_City***(GMT-05:00) Central Daylight Time - Mexico City***CDT', item_name: '(GMT-05:00) Central Daylight Time - Mexico City', category: 'time_zone_region' },
            { pick_list_id: 'America/Monterrey***(GMT-05:00) Central Daylight Time - Monterrey***CDT', item_name: '(GMT-05:00) Central Daylight Time - Monterrey', category: 'time_zone_region' },
            { pick_list_id: 'America/North_Dakota/Beulah***(GMT-05:00) Central Daylight Time - North Dakota/Beulah***CDT', item_name: '(GMT-05:00) Central Daylight Time - North Dakota/Beulah', category: 'time_zone_region' },
            { pick_list_id: 'America/North_Dakota/Center***(GMT-05:00) Central Daylight Time - North Dakota/Center***CDT', item_name: '(GMT-05:00) Central Daylight Time - North Dakota/Center', category: 'time_zone_region' },
            { pick_list_id: 'America/North_Dakota/New_Salem***(GMT-05:00) Central Daylight Time - North Dakota/New Salem***CDT', item_name: '(GMT-05:00) Central Daylight Time - North Dakota/New Salem', category: 'time_zone_region' },
            { pick_list_id: 'America/Panama***(GMT-05:00) Eastern Standard Time - Panama***EST', item_name: '(GMT-05:00) Eastern Standard Time - Panama', category: 'time_zone_region' },
            { pick_list_id: 'America/Porto_Acre***(GMT-05:00) Porto Acre***GMT-5', item_name: '(GMT-05:00) Porto Acre', category: 'time_zone_region' },
            { pick_list_id: 'America/Rainy_River***(GMT-05:00) Central Daylight Time - Rainy River***CDT', item_name: '(GMT-05:00) Central Daylight Time - Rainy River', category: 'time_zone_region' },
            { pick_list_id: 'America/Rankin_Inlet***(GMT-05:00) Central Daylight Time - Rankin Inlet***CDT', item_name: '(GMT-05:00) Central Daylight Time - Rankin Inlet', category: 'time_zone_region' },
            { pick_list_id: 'America/Resolute***(GMT-05:00) Central Daylight Time - Resolute***CDT', item_name: '(GMT-05:00) Central Daylight Time - Resolute', category: 'time_zone_region' },
            { pick_list_id: 'America/Rio_Branco***(GMT-05:00) Rio Branco***GMT-5', item_name: '(GMT-05:00) Rio Branco', category: 'time_zone_region' },
            { pick_list_id: 'America/Winnipeg***(GMT-05:00) Central Daylight Time - Winnipeg***CDT', item_name: '(GMT-05:00) Central Daylight Time - Winnipeg', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/Acre***(GMT-05:00) Acre***GMT-5', item_name: '(GMT-05:00) Acre', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Central***(GMT-05:00) Central Daylight Time - Central***CDT', item_name: '(GMT-05:00) Central Daylight Time - Central', category: 'time_zone_region' },
            { pick_list_id: 'EST***(GMT-05:00) Eastern Standard Time - EST***EST', item_name: '(GMT-05:00) Eastern Standard Time - EST', category: 'time_zone_region' },
            { pick_list_id: 'Jamaica***(GMT-05:00) Eastern Standard Time - Jamaica***EST', item_name: '(GMT-05:00) Eastern Standard Time - Jamaica', category: 'time_zone_region' },
            { pick_list_id: 'Mexico/General***(GMT-05:00) Central Daylight Time - General***CDT', item_name: '(GMT-05:00) Central Daylight Time - General', category: 'time_zone_region' },
            { pick_list_id: 'US/Central***(GMT-05:00) Central Daylight Time - Central***CDT', item_name: '(GMT-05:00) Central Daylight Time - Central', category: 'time_zone_region' },
            { pick_list_id: 'US/Indiana-Starke***(GMT-05:00) Central Daylight Time - Indiana-Starke***CDT', item_name: '(GMT-05:00) Central Daylight Time - Indiana-Starke', category: 'time_zone_region' },
            { pick_list_id: 'America/Anguilla***(GMT-04:00) Atlantic Standard Time - Anguilla***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Anguilla', category: 'time_zone_region' },
            { pick_list_id: 'America/Antigua***(GMT-04:00) Atlantic Standard Time - Antigua***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Antigua', category: 'time_zone_region' },
            { pick_list_id: 'America/Aruba***(GMT-04:00) Atlantic Standard Time - Aruba***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Aruba', category: 'time_zone_region' },
            { pick_list_id: 'America/Asuncion***(GMT-04:00) Asuncion***GMT-4', item_name: '(GMT-04:00) Asuncion', category: 'time_zone_region' },
            { pick_list_id: 'America/Barbados***(GMT-04:00) Atlantic Standard Time - Barbados***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Barbados', category: 'time_zone_region' },
            { pick_list_id: 'America/Blanc-Sablon***(GMT-04:00) Atlantic Standard Time - Blanc-Sablon***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Blanc-Sablon', category: 'time_zone_region' },
            { pick_list_id: 'America/Boa_Vista***(GMT-04:00) Boa Vista***GMT-4', item_name: '(GMT-04:00) Boa Vista', category: 'time_zone_region' },
            { pick_list_id: 'America/Campo_Grande***(GMT-04:00) Campo Grande***GMT-4', item_name: '(GMT-04:00) Campo Grande', category: 'time_zone_region' },
            { pick_list_id: 'America/Caracas***(GMT-04:00) Caracas***GMT-4', item_name: '(GMT-04:00) Caracas', category: 'time_zone_region' },
            { pick_list_id: 'America/Cuiaba***(GMT-04:00) Cuiaba***GMT-4', item_name: '(GMT-04:00) Cuiaba', category: 'time_zone_region' },
            { pick_list_id: 'America/Curacao***(GMT-04:00) Atlantic Standard Time - Curacao***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Curacao', category: 'time_zone_region' },
            { pick_list_id: 'America/Detroit***(GMT-04:00) Eastern Daylight Time - Detroit***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Detroit', category: 'time_zone_region' },
            { pick_list_id: 'America/Dominica***(GMT-04:00) Atlantic Standard Time - Dominica***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Dominica', category: 'time_zone_region' },
            { pick_list_id: 'America/Fort_Wayne***(GMT-04:00) Eastern Daylight Time - Fort Wayne***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Fort Wayne', category: 'time_zone_region' },
            { pick_list_id: 'America/Grand_Turk***(GMT-04:00) Atlantic Standard Time - Grand Turk***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Grand Turk', category: 'time_zone_region' },
            { pick_list_id: 'America/Grenada***(GMT-04:00) Atlantic Standard Time - Grenada***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Grenada', category: 'time_zone_region' },
            { pick_list_id: 'America/Guadeloupe***(GMT-04:00) Atlantic Standard Time - Guadeloupe***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Guadeloupe', category: 'time_zone_region' },
            { pick_list_id: 'America/Guyana***(GMT-04:00) Guyana***GMT-4', item_name: '(GMT-04:00) Guyana', category: 'time_zone_region' },
            { pick_list_id: 'America/Havana***(GMT-04:00) Cuba Daylight Time - Havana***CDT', item_name: '(GMT-04:00) Cuba Daylight Time - Havana', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Indianapolis***(GMT-04:00) Eastern Daylight Time - Indiana/Indianapolis***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Indianapolis', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Marengo***(GMT-04:00) Eastern Daylight Time - Indiana/Marengo***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Marengo', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Petersburg***(GMT-04:00) Eastern Daylight Time - Indiana/Petersburg***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Petersburg', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Vevay***(GMT-04:00) Eastern Daylight Time - Indiana/Vevay***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Vevay', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Vincennes***(GMT-04:00) Eastern Daylight Time - Indiana/Vincennes***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Vincennes', category: 'time_zone_region' },
            { pick_list_id: 'America/Indiana/Winamac***(GMT-04:00) Eastern Daylight Time - Indiana/Winamac***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indiana/Winamac', category: 'time_zone_region' },
            { pick_list_id: 'America/Indianapolis***(GMT-04:00) Eastern Daylight Time - Indianapolis***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Indianapolis', category: 'time_zone_region' },
            { pick_list_id: 'America/Iqaluit***(GMT-04:00) Eastern Daylight Time - Iqaluit***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Iqaluit', category: 'time_zone_region' },
            { pick_list_id: 'America/Kentucky/Louisville***(GMT-04:00) Eastern Daylight Time - Kentucky/Louisville***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Kentucky/Louisville', category: 'time_zone_region' },
            { pick_list_id: 'America/Kentucky/Monticello***(GMT-04:00) Eastern Daylight Time - Kentucky/Monticello***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Kentucky/Monticello', category: 'time_zone_region' },
            { pick_list_id: 'America/Kralendijk***(GMT-04:00) Atlantic Standard Time - Kralendijk***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Kralendijk', category: 'time_zone_region' },
            { pick_list_id: 'America/La_Paz***(GMT-04:00) La Paz***GMT-4', item_name: '(GMT-04:00) La Paz', category: 'time_zone_region' },
            { pick_list_id: 'America/Louisville***(GMT-04:00) Eastern Daylight Time - Louisville***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Louisville', category: 'time_zone_region' },
            { pick_list_id: 'America/Lower_Princes***(GMT-04:00) Atlantic Standard Time - Lower Princes***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Lower Princes', category: 'time_zone_region' },
            { pick_list_id: 'America/Manaus***(GMT-04:00) Manaus***GMT-4', item_name: '(GMT-04:00) Manaus', category: 'time_zone_region' },
            { pick_list_id: 'America/Marigot***(GMT-04:00) Atlantic Standard Time - Marigot***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Marigot', category: 'time_zone_region' },
            { pick_list_id: 'America/Martinique***(GMT-04:00) Atlantic Standard Time - Martinique***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Martinique', category: 'time_zone_region' },
            { pick_list_id: 'America/Montreal***(GMT-04:00) Eastern Daylight Time - Montreal***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Montreal', category: 'time_zone_region' },
            { pick_list_id: 'America/Montserrat***(GMT-04:00) Atlantic Standard Time - Montserrat***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Montserrat', category: 'time_zone_region' },
            { pick_list_id: 'America/Nassau***(GMT-04:00) Eastern Daylight Time - Nassau***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Nassau', category: 'time_zone_region' },
            { pick_list_id: 'America/New_York***(GMT-04:00) Eastern Daylight Time - New York***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - New York', category: 'time_zone_region' },
            { pick_list_id: 'America/Nipigon***(GMT-04:00) Eastern Daylight Time - Nipigon***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Nipigon', category: 'time_zone_region' },
            { pick_list_id: 'America/Pangnirtung***(GMT-04:00) Eastern Daylight Time - Pangnirtung***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Pangnirtung', category: 'time_zone_region' },
            { pick_list_id: 'America/Port-au-Prince***(GMT-04:00) Eastern Daylight Time - Port-au-Prince***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Port-au-Prince', category: 'time_zone_region' },
            { pick_list_id: 'America/Porto_Velho***(GMT-04:00) Porto Velho***GMT-4', item_name: '(GMT-04:00) Porto Velho', category: 'time_zone_region' },
            { pick_list_id: 'America/Port_of_Spain***(GMT-04:00) Atlantic Standard Time - Port of Spain***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Port of Spain', category: 'time_zone_region' },
            { pick_list_id: 'America/Puerto_Rico***(GMT-04:00) Atlantic Standard Time - Puerto Rico***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Puerto Rico', category: 'time_zone_region' },
            { pick_list_id: 'America/Santiago***(GMT-04:00) Santiago***GMT-4', item_name: '(GMT-04:00) Santiago', category: 'time_zone_region' },
            { pick_list_id: 'America/Santo_Domingo***(GMT-04:00) Atlantic Standard Time - Santo Domingo***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Santo Domingo', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Barthelemy***(GMT-04:00) Atlantic Standard Time - St Barthelemy***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Barthelemy', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Kitts***(GMT-04:00) Atlantic Standard Time - St Kitts***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Kitts', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Lucia***(GMT-04:00) Atlantic Standard Time - St Lucia***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Lucia', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Thomas***(GMT-04:00) Atlantic Standard Time - St Thomas***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Thomas', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Vincent***(GMT-04:00) Atlantic Standard Time - St Vincent***AST', item_name: '(GMT-04:00) Atlantic Standard Time - St Vincent', category: 'time_zone_region' },
            { pick_list_id: 'America/Thunder_Bay***(GMT-04:00) Eastern Daylight Time - Thunder Bay***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Thunder Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Toronto***(GMT-04:00) Eastern Daylight Time - Toronto***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Toronto', category: 'time_zone_region' },
            { pick_list_id: 'America/Tortola***(GMT-04:00) Atlantic Standard Time - Tortola***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Tortola', category: 'time_zone_region' },
            { pick_list_id: 'America/Virgin***(GMT-04:00) Atlantic Standard Time - Virgin***AST', item_name: '(GMT-04:00) Atlantic Standard Time - Virgin', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/West***(GMT-04:00) West***GMT-4', item_name: '(GMT-04:00) West', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Eastern***(GMT-04:00) Eastern Daylight Time - Eastern***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Eastern', category: 'time_zone_region' },
            { pick_list_id: 'Chile/Continental***(GMT-04:00) Continental***GMT-4', item_name: '(GMT-04:00) Continental', category: 'time_zone_region' },
            { pick_list_id: 'Cuba***(GMT-04:00) Cuba Daylight Time - Cuba***CDT', item_name: '(GMT-04:00) Cuba Daylight Time - Cuba', category: 'time_zone_region' },
            { pick_list_id: 'posixrules***(GMT-04:00) Eastern Daylight Time - posixrules***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - posixrules', category: 'time_zone_region' },
            { pick_list_id: 'US/East-Indiana***(GMT-04:00) Eastern Daylight Time - East-Indiana***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - East-Indiana', category: 'time_zone_region' },
            { pick_list_id: 'US/Eastern***(GMT-04:00) Eastern Daylight Time - Eastern***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Eastern', category: 'time_zone_region' },
            { pick_list_id: 'US/Michigan***(GMT-04:00) Eastern Daylight Time - Michigan***EDT', item_name: '(GMT-04:00) Eastern Daylight Time - Michigan', category: 'time_zone_region' },
            { pick_list_id: 'America/Araguaina***(GMT-03:00) Araguaina***GMT-3', item_name: '(GMT-03:00) Araguaina', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Buenos_Aires***(GMT-03:00) Argentina/Buenos Aires***GMT-3', item_name: '(GMT-03:00) Argentina/Buenos Aires', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Catamarca***(GMT-03:00) Argentina/Catamarca***GMT-3', item_name: '(GMT-03:00) Argentina/Catamarca', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/ComodRivadavia***(GMT-03:00) Argentina/ComodRivadavia***GMT-3', item_name: '(GMT-03:00) Argentina/ComodRivadavia', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Cordoba***(GMT-03:00) Argentina/Cordoba***GMT-3', item_name: '(GMT-03:00) Argentina/Cordoba', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Jujuy***(GMT-03:00) Argentina/Jujuy***GMT-3', item_name: '(GMT-03:00) Argentina/Jujuy', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/La_Rioja***(GMT-03:00) Argentina/La Rioja***GMT-3', item_name: '(GMT-03:00) Argentina/La Rioja', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Mendoza***(GMT-03:00) Argentina/Mendoza***GMT-3', item_name: '(GMT-03:00) Argentina/Mendoza', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Rio_Gallegos***(GMT-03:00) Argentina/Rio Gallegos***GMT-3', item_name: '(GMT-03:00) Argentina/Rio Gallegos', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Salta***(GMT-03:00) Argentina/Salta***GMT-3', item_name: '(GMT-03:00) Argentina/Salta', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/San_Juan***(GMT-03:00) Argentina/San Juan***GMT-3', item_name: '(GMT-03:00) Argentina/San Juan', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/San_Luis***(GMT-03:00) Argentina/San Luis***GMT-3', item_name: '(GMT-03:00) Argentina/San Luis', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Tucuman***(GMT-03:00) Argentina/Tucuman***GMT-3', item_name: '(GMT-03:00) Argentina/Tucuman', category: 'time_zone_region' },
            { pick_list_id: 'America/Argentina/Ushuaia***(GMT-03:00) Argentina/Ushuaia***GMT-3', item_name: '(GMT-03:00) Argentina/Ushuaia', category: 'time_zone_region' },
            { pick_list_id: 'America/Bahia***(GMT-03:00) Bahia***GMT-3', item_name: '(GMT-03:00) Bahia', category: 'time_zone_region' },
            { pick_list_id: 'America/Belem***(GMT-03:00) Belem***GMT-3', item_name: '(GMT-03:00) Belem', category: 'time_zone_region' },
            { pick_list_id: 'America/Buenos_Aires***(GMT-03:00) Buenos Aires***GMT-3', item_name: '(GMT-03:00) Buenos Aires', category: 'time_zone_region' },
            { pick_list_id: 'America/Catamarca***(GMT-03:00) Catamarca***GMT-3', item_name: '(GMT-03:00) Catamarca', category: 'time_zone_region' },
            { pick_list_id: 'America/Cayenne***(GMT-03:00) Cayenne***GMT-3', item_name: '(GMT-03:00) Cayenne', category: 'time_zone_region' },
            { pick_list_id: 'America/Cordoba***(GMT-03:00) Cordoba***GMT-3', item_name: '(GMT-03:00) Cordoba', category: 'time_zone_region' },
            { pick_list_id: 'America/Fortaleza***(GMT-03:00) Fortaleza***GMT-3', item_name: '(GMT-03:00) Fortaleza', category: 'time_zone_region' },
            { pick_list_id: 'America/Glace_Bay***(GMT-03:00) Atlantic Daylight Time - Glace Bay***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Glace Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Goose_Bay***(GMT-03:00) Atlantic Daylight Time - Goose Bay***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Goose Bay', category: 'time_zone_region' },
            { pick_list_id: 'America/Halifax***(GMT-03:00) Atlantic Daylight Time - Halifax***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Halifax', category: 'time_zone_region' },
            { pick_list_id: 'America/Jujuy***(GMT-03:00) Jujuy***GMT-3', item_name: '(GMT-03:00) Jujuy', category: 'time_zone_region' },
            { pick_list_id: 'America/Maceio***(GMT-03:00) Maceio***GMT-3', item_name: '(GMT-03:00) Maceio', category: 'time_zone_region' },
            { pick_list_id: 'America/Mendoza***(GMT-03:00) Mendoza***GMT-3', item_name: '(GMT-03:00) Mendoza', category: 'time_zone_region' },
            { pick_list_id: 'America/Moncton***(GMT-03:00) Atlantic Daylight Time - Moncton***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Moncton', category: 'time_zone_region' },
            { pick_list_id: 'America/Montevideo***(GMT-03:00) Montevideo***GMT-3', item_name: '(GMT-03:00) Montevideo', category: 'time_zone_region' },
            { pick_list_id: 'America/Paramaribo***(GMT-03:00) Paramaribo***GMT-3', item_name: '(GMT-03:00) Paramaribo', category: 'time_zone_region' },
            { pick_list_id: 'America/Punta_Arenas***(GMT-03:00) Punta Arenas***GMT-3', item_name: '(GMT-03:00) Punta Arenas', category: 'time_zone_region' },
            { pick_list_id: 'America/Recife***(GMT-03:00) Recife***GMT-3', item_name: '(GMT-03:00) Recife', category: 'time_zone_region' },
            { pick_list_id: 'America/Rosario***(GMT-03:00) Rosario***GMT-3', item_name: '(GMT-03:00) Rosario', category: 'time_zone_region' },
            { pick_list_id: 'America/Santarem***(GMT-03:00) Santarem***GMT-3', item_name: '(GMT-03:00) Santarem', category: 'time_zone_region' },
            { pick_list_id: 'America/Sao_Paulo***(GMT-03:00) Sao Paulo***GMT-3', item_name: '(GMT-03:00) Sao Paulo', category: 'time_zone_region' },
            { pick_list_id: 'America/Thule***(GMT-03:00) Atlantic Daylight Time - Thule***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Thule', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Palmer***(GMT-03:00) Palmer***GMT-3', item_name: '(GMT-03:00) Palmer', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Rothera***(GMT-03:00) Rothera***GMT-3', item_name: '(GMT-03:00) Rothera', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Bermuda***(GMT-03:00) Atlantic Daylight Time - Bermuda***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Bermuda', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Stanley***(GMT-03:00) Stanley***GMT-3', item_name: '(GMT-03:00) Stanley', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/East***(GMT-03:00) East***GMT-3', item_name: '(GMT-03:00) East', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Atlantic***(GMT-03:00) Atlantic Daylight Time - Atlantic***ADT', item_name: '(GMT-03:00) Atlantic Daylight Time - Atlantic', category: 'time_zone_region' },
            { pick_list_id: 'America/St_Johns***(GMT-02:30) Newfoundland Daylight Time - St Johns***NDT', item_name: '(GMT-02:30) Newfoundland Daylight Time - St Johns', category: 'time_zone_region' },
            { pick_list_id: 'Canada/Newfoundland***(GMT-02:30) Newfoundland Daylight Time - Newfoundland***NDT', item_name: '(GMT-02:30) Newfoundland Daylight Time - Newfoundland', category: 'time_zone_region' },
            { pick_list_id: 'America/Godthab***(GMT-02:00) Godthab***GMT-2', item_name: '(GMT-02:00) Godthab', category: 'time_zone_region' },
            { pick_list_id: 'America/Miquelon***(GMT-02:00) Miquelon***GMT-2', item_name: '(GMT-02:00) Miquelon', category: 'time_zone_region' },
            { pick_list_id: 'America/Noronha***(GMT-02:00) Noronha***GMT-2', item_name: '(GMT-02:00) Noronha', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/South_Georgia***(GMT-02:00) South Georgia***GMT-2', item_name: '(GMT-02:00) South Georgia', category: 'time_zone_region' },
            { pick_list_id: 'Brazil/DeNoronha***(GMT-02:00) DeNoronha***GMT-2', item_name: '(GMT-02:00) DeNoronha', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Cape_Verde***(GMT-01:00) Cape Verde***GMT-1', item_name: '(GMT-01:00) Cape Verde', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Abidjan***(GMT+00:00) Greenwich Mean Time - Abidjan***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Abidjan', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Accra***(GMT+00:00) Greenwich Mean Time - Accra***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Accra', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bamako***(GMT+00:00) Greenwich Mean Time - Bamako***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Bamako', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Banjul***(GMT+00:00) Greenwich Mean Time - Banjul***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Banjul', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bissau***(GMT+00:00) Greenwich Mean Time - Bissau***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Bissau', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Conakry***(GMT+00:00) Greenwich Mean Time - Conakry***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Conakry', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Dakar***(GMT+00:00) Greenwich Mean Time - Dakar***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Dakar', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Freetown***(GMT+00:00) Greenwich Mean Time - Freetown***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Freetown', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lome***(GMT+00:00) Greenwich Mean Time - Lome***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Lome', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Monrovia***(GMT+00:00) Greenwich Mean Time - Monrovia***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Monrovia', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Nouakchott***(GMT+00:00) Greenwich Mean Time - Nouakchott***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Nouakchott', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Ouagadougou***(GMT+00:00) Greenwich Mean Time - Ouagadougou***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Ouagadougou', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Sao_Tome***(GMT+00:00) Greenwich Mean Time - Sao Tome***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Sao Tome', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Timbuktu***(GMT+00:00) Greenwich Mean Time - Timbuktu***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Timbuktu', category: 'time_zone_region' },
            { pick_list_id: 'America/Danmarkshavn***(GMT+00:00) Greenwich Mean Time - Danmarkshavn***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Danmarkshavn', category: 'time_zone_region' },
            { pick_list_id: 'America/Scoresbysund***(GMT+00:00) Coordinated Universal Time - Scoresbysund***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Scoresbysund', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Azores***(GMT+00:00) Coordinated Universal Time - Azores***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Azores', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Reykjavik***(GMT+00:00) Greenwich Mean Time - Reykjavik***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Reykjavik', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/St_Helena***(GMT+00:00) Greenwich Mean Time - St Helena***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - St Helena', category: 'time_zone_region' },
            { pick_list_id: 'GMT***(GMT+00:00) Greenwich Mean Time - GMT***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - GMT', category: 'time_zone_region' },
            { pick_list_id: 'Greenwich***(GMT+00:00) Greenwich Mean Time - Greenwich***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Greenwich', category: 'time_zone_region' },
            { pick_list_id: 'Iceland***(GMT+00:00) Greenwich Mean Time - Iceland***GMT', item_name: '(GMT+00:00) Greenwich Mean Time - Iceland', category: 'time_zone_region' },
            { pick_list_id: 'Universal***(GMT+00:00) Coordinated Universal Time - Universal***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Universal', category: 'time_zone_region' },
            { pick_list_id: 'UTC***(GMT+00:00) Coordinated Universal Time - UTC***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - UTC', category: 'time_zone_region' },
            { pick_list_id: 'Zulu***(GMT+00:00) Coordinated Universal Time - Zulu***UTC', item_name: '(GMT+00:00) Coordinated Universal Time - Zulu', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Algiers***(GMT+01:00) Central European Time - Algiers***CET', item_name: '(GMT+01:00) Central European Time - Algiers', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bangui***(GMT+01:00) West Africa Time - Bangui***WAT', item_name: '(GMT+01:00) West Africa Time - Bangui', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Brazzaville***(GMT+01:00) West Africa Time - Brazzaville***WAT', item_name: '(GMT+01:00) West Africa Time - Brazzaville', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Casablanca***(GMT+01:00) Western European Summer Time - Casablanca***WEST', item_name: '(GMT+01:00) Western European Summer Time - Casablanca', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Douala***(GMT+01:00) West Africa Time - Douala***WAT', item_name: '(GMT+01:00) West Africa Time - Douala', category: 'time_zone_region' },
            { pick_list_id: 'Africa/El_Aaiun***(GMT+01:00) Western European Summer Time - El Aaiun***WEST', item_name: '(GMT+01:00) Western European Summer Time - El Aaiun', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Kinshasa***(GMT+01:00) West Africa Time - Kinshasa***WAT', item_name: '(GMT+01:00) West Africa Time - Kinshasa', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lagos***(GMT+01:00) West Africa Time - Lagos***WAT', item_name: '(GMT+01:00) West Africa Time - Lagos', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Libreville***(GMT+01:00) West Africa Time - Libreville***WAT', item_name: '(GMT+01:00) West Africa Time - Libreville', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Luanda***(GMT+01:00) West Africa Time - Luanda***WAT', item_name: '(GMT+01:00) West Africa Time - Luanda', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Malabo***(GMT+01:00) West Africa Time - Malabo***WAT', item_name: '(GMT+01:00) West Africa Time - Malabo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Ndjamena***(GMT+01:00) West Africa Time - Ndjamena***WAT', item_name: '(GMT+01:00) West Africa Time - Ndjamena', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Niamey***(GMT+01:00) West Africa Time - Niamey***WAT', item_name: '(GMT+01:00) West Africa Time - Niamey', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Porto-Novo***(GMT+01:00) West Africa Time - Porto-Novo***WAT', item_name: '(GMT+01:00) West Africa Time - Porto-Novo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Tunis***(GMT+01:00) Central European Time - Tunis***CET', item_name: '(GMT+01:00) Central European Time - Tunis', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Windhoek***(GMT+01:00) West Africa Time - Windhoek***WAT', item_name: '(GMT+01:00) West Africa Time - Windhoek', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Canary***(GMT+01:00) Western European Summer Time - Canary***WEST', item_name: '(GMT+01:00) Western European Summer Time - Canary', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Faeroe***(GMT+01:00) Western European Summer Time - Faeroe***WEST', item_name: '(GMT+01:00) Western European Summer Time - Faeroe', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Faroe***(GMT+01:00) Western European Summer Time - Faroe***WEST', item_name: '(GMT+01:00) Western European Summer Time - Faroe', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Madeira***(GMT+01:00) Western European Summer Time - Madeira***WEST', item_name: '(GMT+01:00) Western European Summer Time - Madeira', category: 'time_zone_region' },
            { pick_list_id: 'Eire***(GMT+01:00) Irish Standard Time - Eire***IST', item_name: '(GMT+01:00) Irish Standard Time - Eire', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Belfast***(GMT+01:00) British Summer Time - Belfast***BST', item_name: '(GMT+01:00) British Summer Time - Belfast', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Dublin***(GMT+01:00) Irish Standard Time - Dublin***IST', item_name: '(GMT+01:00) Irish Standard Time - Dublin', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Guernsey***(GMT+01:00) British Summer Time - Guernsey***BST', item_name: '(GMT+01:00) British Summer Time - Guernsey', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Isle_of_Man***(GMT+01:00) British Summer Time - Isle of Man***BST', item_name: '(GMT+01:00) British Summer Time - Isle of Man', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Jersey***(GMT+01:00) British Summer Time - Jersey***BST', item_name: '(GMT+01:00) British Summer Time - Jersey', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Lisbon***(GMT+01:00) Western European Summer Time - Lisbon***WEST', item_name: '(GMT+01:00) Western European Summer Time - Lisbon', category: 'time_zone_region' },
            { pick_list_id: 'Europe/London***(GMT+01:00) British Summer Time - London***BST', item_name: '(GMT+01:00) British Summer Time - London', category: 'time_zone_region' },
            { pick_list_id: 'GB***(GMT+01:00) British Summer Time - GB***BST', item_name: '(GMT+01:00) British Summer Time - GB', category: 'time_zone_region' },
            { pick_list_id: 'GB-Eire***(GMT+01:00) British Summer Time - GB-Eire***BST', item_name: '(GMT+01:00) British Summer Time - GB-Eire', category: 'time_zone_region' },
            { pick_list_id: 'Portugal***(GMT+01:00) Western European Summer Time - Portugal***WEST', item_name: '(GMT+01:00) Western European Summer Time - Portugal', category: 'time_zone_region' },
            { pick_list_id: 'WET***(GMT+01:00) Western European Summer Time - WET***WEST', item_name: '(GMT+01:00) Western European Summer Time - WET', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Blantyre***(GMT+02:00) Central Africa Time - Blantyre***CAT', item_name: '(GMT+02:00) Central Africa Time - Blantyre', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Bujumbura***(GMT+02:00) Central Africa Time - Bujumbura***CAT', item_name: '(GMT+02:00) Central Africa Time - Bujumbura', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Cairo***(GMT+02:00) Eastern European Time - Cairo***EET', item_name: '(GMT+02:00) Eastern European Time - Cairo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Ceuta***(GMT+02:00) Central European Summer Time - Ceuta***CEST', item_name: '(GMT+02:00) Central European Summer Time - Ceuta', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Gaborone***(GMT+02:00) Central Africa Time - Gaborone***CAT', item_name: '(GMT+02:00) Central Africa Time - Gaborone', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Harare***(GMT+02:00) Central Africa Time - Harare***CAT', item_name: '(GMT+02:00) Central Africa Time - Harare', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Johannesburg***(GMT+02:00) South African Standard Time - Johannesburg***SAST', item_name: '(GMT+02:00) South African Standard Time - Johannesburg', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Kigali***(GMT+02:00) Central Africa Time - Kigali***CAT', item_name: '(GMT+02:00) Central Africa Time - Kigali', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lubumbashi***(GMT+02:00) Central Africa Time - Lubumbashi***CAT', item_name: '(GMT+02:00) Central Africa Time - Lubumbashi', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Lusaka***(GMT+02:00) Central Africa Time - Lusaka***CAT', item_name: '(GMT+02:00) Central Africa Time - Lusaka', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Maputo***(GMT+02:00) Central Africa Time - Maputo***CAT', item_name: '(GMT+02:00) Central Africa Time - Maputo', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Maseru***(GMT+02:00) South African Standard Time - Maseru***SAST', item_name: '(GMT+02:00) South African Standard Time - Maseru', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Mbabane***(GMT+02:00) South African Standard Time - Mbabane***SAST', item_name: '(GMT+02:00) South African Standard Time - Mbabane', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Tripoli***(GMT+02:00) Eastern European Time - Tripoli***EET', item_name: '(GMT+02:00) Eastern European Time - Tripoli', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Troll***(GMT+02:00) Troll***GMT+02', item_name: '(GMT+02:00) Troll', category: 'time_zone_region' },
            { pick_list_id: 'Arctic/Longyearbyen***(GMT+02:00) Central European Summer Time - Longyearbyen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Longyearbyen', category: 'time_zone_region' },
            { pick_list_id: 'Atlantic/Jan_Mayen***(GMT+02:00) Central European Summer Time - Jan Mayen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Jan Mayen', category: 'time_zone_region' },
            { pick_list_id: 'CET***(GMT+02:00) Central European Summer Time - CET***CEST', item_name: '(GMT+02:00) Central European Summer Time - CET', category: 'time_zone_region' },
            { pick_list_id: 'Egypt***(GMT+02:00) Eastern European Time - Egypt***EET', item_name: '(GMT+02:00) Eastern European Time - Egypt', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Amsterdam***(GMT+02:00) Central European Summer Time - Amsterdam***CEST', item_name: '(GMT+02:00) Central European Summer Time - Amsterdam', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Andorra***(GMT+02:00) Central European Summer Time - Andorra***CEST', item_name: '(GMT+02:00) Central European Summer Time - Andorra', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Belgrade***(GMT+02:00) Central European Summer Time - Belgrade***CEST', item_name: '(GMT+02:00) Central European Summer Time - Belgrade', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Berlin***(GMT+02:00) Central European Summer Time - Berlin***CEST', item_name: '(GMT+02:00) Central European Summer Time - Berlin', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Bratislava***(GMT+02:00) Central European Summer Time - Bratislava***CEST', item_name: '(GMT+02:00) Central European Summer Time - Bratislava', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Brussels***(GMT+02:00) Central European Summer Time - Brussels***CEST', item_name: '(GMT+02:00) Central European Summer Time - Brussels', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Budapest***(GMT+02:00) Central European Summer Time - Budapest***CEST', item_name: '(GMT+02:00) Central European Summer Time - Budapest', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Busingen***(GMT+02:00) Central European Summer Time - Busingen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Busingen', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Copenhagen***(GMT+02:00) Central European Summer Time - Copenhagen***CEST', item_name: '(GMT+02:00) Central European Summer Time - Copenhagen', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Gibraltar***(GMT+02:00) Central European Summer Time - Gibraltar***CEST', item_name: '(GMT+02:00) Central European Summer Time - Gibraltar', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Kaliningrad***(GMT+02:00) Eastern European Time - Kaliningrad***EET', item_name: '(GMT+02:00) Eastern European Time - Kaliningrad', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Ljubljana***(GMT+02:00) Central European Summer Time - Ljubljana***CEST', item_name: '(GMT+02:00) Central European Summer Time - Ljubljana', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Luxembourg***(GMT+02:00) Central European Summer Time - Luxembourg***CEST', item_name: '(GMT+02:00) Central European Summer Time - Luxembourg', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Madrid***(GMT+02:00) Central European Summer Time - Madrid***CEST', item_name: '(GMT+02:00) Central European Summer Time - Madrid', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Malta***(GMT+02:00) Central European Summer Time - Malta***CEST', item_name: '(GMT+02:00) Central European Summer Time - Malta', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Monaco***(GMT+02:00) Central European Summer Time - Monaco***CEST', item_name: '(GMT+02:00) Central European Summer Time - Monaco', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Oslo***(GMT+02:00) Central European Summer Time - Oslo***CEST', item_name: '(GMT+02:00) Central European Summer Time - Oslo', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Paris***(GMT+02:00) Central European Summer Time - Paris***CEST', item_name: '(GMT+02:00) Central European Summer Time - Paris', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Podgorica***(GMT+02:00) Central European Summer Time - Podgorica***CEST', item_name: '(GMT+02:00) Central European Summer Time - Podgorica', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Prague***(GMT+02:00) Central European Summer Time - Prague***CEST', item_name: '(GMT+02:00) Central European Summer Time - Prague', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Rome***(GMT+02:00) Central European Summer Time - Rome***CEST', item_name: '(GMT+02:00) Central European Summer Time - Rome', category: 'time_zone_region' },
            { pick_list_id: 'Europe/San_Marino***(GMT+02:00) Central European Summer Time - San Marino***CEST', item_name: '(GMT+02:00) Central European Summer Time - San Marino', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Sarajevo***(GMT+02:00) Central European Summer Time - Sarajevo***CEST', item_name: '(GMT+02:00) Central European Summer Time - Sarajevo', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Skopje***(GMT+02:00) Central European Summer Time - Skopje***CEST', item_name: '(GMT+02:00) Central European Summer Time - Skopje', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Stockholm***(GMT+02:00) Central European Summer Time - Stockholm***CEST', item_name: '(GMT+02:00) Central European Summer Time - Stockholm', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Tirane***(GMT+02:00) Central European Summer Time - Tirane***CEST', item_name: '(GMT+02:00) Central European Summer Time - Tirane', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vaduz***(GMT+02:00) Central European Summer Time - Vaduz***CEST', item_name: '(GMT+02:00) Central European Summer Time - Vaduz', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vatican***(GMT+02:00) Central European Summer Time - Vatican***CEST', item_name: '(GMT+02:00) Central European Summer Time - Vatican', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vienna***(GMT+02:00) Central European Summer Time - Vienna***CEST', item_name: '(GMT+02:00) Central European Summer Time - Vienna', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Warsaw***(GMT+02:00) Central European Summer Time - Warsaw***CEST', item_name: '(GMT+02:00) Central European Summer Time - Warsaw', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Zagreb***(GMT+02:00) Central European Summer Time - Zagreb***CEST', item_name: '(GMT+02:00) Central European Summer Time - Zagreb', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Zurich***(GMT+02:00) Central European Summer Time - Zurich***CEST', item_name: '(GMT+02:00) Central European Summer Time - Zurich', category: 'time_zone_region' },
            { pick_list_id: 'Libya***(GMT+02:00) Eastern European Time - Libya***EET', item_name: '(GMT+02:00) Eastern European Time - Libya', category: 'time_zone_region' },
            { pick_list_id: 'MET***(GMT+02:00) Middle European Summer Time - MET***MEST', item_name: '(GMT+02:00) Middle European Summer Time - MET', category: 'time_zone_region' },
            { pick_list_id: 'Poland***(GMT+02:00) Central European Summer Time - Poland***CEST', item_name: '(GMT+02:00) Central European Summer Time - Poland', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Addis_Ababa***(GMT+03:00) Eastern Africa Time - Addis Ababa***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Addis Ababa', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Asmara***(GMT+03:00) Eastern Africa Time - Asmara***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Asmara', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Asmera***(GMT+03:00) Eastern Africa Time - Asmera***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Asmera', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Dar_es_Salaam***(GMT+03:00) Eastern Africa Time - Dar es Salaam***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Dar es Salaam', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Djibouti***(GMT+03:00) Eastern Africa Time - Djibouti***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Djibouti', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Juba***(GMT+03:00) Eastern Africa Time - Juba***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Juba', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Kampala***(GMT+03:00) Eastern Africa Time - Kampala***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Kampala', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Khartoum***(GMT+03:00) Eastern Africa Time - Khartoum***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Khartoum', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Mogadishu***(GMT+03:00) Eastern Africa Time - Mogadishu***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Mogadishu', category: 'time_zone_region' },
            { pick_list_id: 'Africa/Nairobi***(GMT+03:00) Eastern Africa Time - Nairobi***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Nairobi', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Syowa***(GMT+03:00) Syowa***GMT+03', item_name: '(GMT+03:00) Syowa', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Aden***(GMT+03:00) Aden***GMT+03', item_name: '(GMT+03:00) Aden', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Amman***(GMT+03:00) Eastern European Summer Time - Amman***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Amman', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Baghdad***(GMT+03:00) Baghdad***GMT+03', item_name: '(GMT+03:00) Baghdad', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Bahrain***(GMT+03:00) Bahrain***GMT+03', item_name: '(GMT+03:00) Bahrain', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Beirut***(GMT+03:00) Eastern European Summer Time - Beirut***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Beirut', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Damascus***(GMT+03:00) Eastern European Summer Time - Damascus***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Damascus', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Famagusta***(GMT+03:00) Famagusta***GMT+03', item_name: '(GMT+03:00) Famagusta', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Gaza***(GMT+03:00) Eastern European Summer Time - Gaza***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Gaza', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Hebron***(GMT+03:00) Eastern European Summer Time - Hebron***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Hebron', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Istanbul***(GMT+03:00) Istanbul***GMT+03', item_name: '(GMT+03:00) Istanbul', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Jerusalem***(GMT+03:00) Israel Daylight Time - Jerusalem***IDT', item_name: '(GMT+03:00) Israel Daylight Time - Jerusalem', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kuwait***(GMT+03:00) Kuwait***GMT+03', item_name: '(GMT+03:00) Kuwait', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Nicosia***(GMT+03:00) Eastern European Summer Time - Nicosia***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Nicosia', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Qatar***(GMT+03:00) Qatar***GMT+03', item_name: '(GMT+03:00) Qatar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Riyadh***(GMT+03:00) Riyadh***GMT+03', item_name: '(GMT+03:00) Riyadh', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Tel_Aviv***(GMT+03:00) Israel Daylight Time - Tel Aviv***IDT', item_name: '(GMT+03:00) Israel Daylight Time - Tel Aviv', category: 'time_zone_region' },
            { pick_list_id: 'EET***(GMT+03:00) Eastern European Summer Time - EET***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - EET', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Athens***(GMT+03:00) Eastern European Summer Time - Athens***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Athens', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Bucharest***(GMT+03:00) Eastern European Summer Time - Bucharest***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Bucharest', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Chisinau***(GMT+03:00) Eastern European Summer Time - Chisinau***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Chisinau', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Helsinki***(GMT+03:00) Eastern European Summer Time - Helsinki***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Helsinki', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Istanbul***(GMT+03:00) Istanbul***GMT+03', item_name: '(GMT+03:00) Istanbul', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Kiev***(GMT+03:00) Eastern European Summer Time - Kiev***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Kiev', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Kirov***(GMT+03:00) Kirov***GMT+03', item_name: '(GMT+03:00) Kirov', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Mariehamn***(GMT+03:00) Eastern European Summer Time - Mariehamn***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Mariehamn', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Minsk***(GMT+03:00) Minsk***GMT+03', item_name: '(GMT+03:00) Minsk', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Moscow***(GMT+03:00) Moscow Standard Time - Moscow***MSK', item_name: '(GMT+03:00) Moscow Standard Time - Moscow', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Nicosia***(GMT+03:00) Eastern European Summer Time - Nicosia***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Nicosia', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Riga***(GMT+03:00) Eastern European Summer Time - Riga***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Riga', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Simferopol***(GMT+03:00) Moscow Standard Time - Simferopol***MSK', item_name: '(GMT+03:00) Moscow Standard Time - Simferopol', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Sofia***(GMT+03:00) Eastern European Summer Time - Sofia***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Sofia', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Tallinn***(GMT+03:00) Eastern European Summer Time - Tallinn***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Tallinn', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Tiraspol***(GMT+03:00) Eastern European Summer Time - Tiraspol***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Tiraspol', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Uzhgorod***(GMT+03:00) Eastern European Summer Time - Uzhgorod***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Uzhgorod', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Vilnius***(GMT+03:00) Eastern European Summer Time - Vilnius***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Vilnius', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Volgograd***(GMT+03:00) Volgograd***GMT+03', item_name: '(GMT+03:00) Volgograd', category: 'time_zone_region' },
            { pick_list_id: 'Europe/Zaporozhye***(GMT+03:00) Eastern European Summer Time - Zaporozhye***EEST', item_name: '(GMT+03:00) Eastern European Summer Time - Zaporozhye', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Antananarivo***(GMT+03:00) Eastern Africa Time - Antananarivo***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Antananarivo', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Comoro***(GMT+03:00) Eastern Africa Time - Comoro***EAT', item_name: '(GMT+03:00) Eastern Africa Time - Comoro', category: 'time_zone_region' },
            { pick_list_id: 'Israel***(GMT+03:00) Israel Daylight Time - Israel***IDT', item_name: '(GMT+03:00) Israel Daylight Time - Israel', category: 'time_zone_region' },
            { pick_list_id: 'Turkey***(GMT+03:00) Turkey***GMT+03', item_name: '(GMT+03:00) Turkey', category: 'time_zone_region' },
            { pick_list_id: 'W-SU***(GMT+03:00) Moscow Standard Time - W-SU***MSK', item_name: '(GMT+03:00) Moscow Standard Time - W-SU', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Baku***(GMT+04:00) Baku***GMT+04', item_name: '(GMT+04:00) Baku', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Dubai***(GMT+04:00) Dubai***GMT+04', item_name: '(GMT+04:00) Dubai', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Dhaka***(GMT+06:00) Dhaka***GMT+6', item_name: '(GMT+06:00) Dhaka', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kashgar***(GMT+06:00) Kashgar***GMT+6', item_name: '(GMT+06:00) Kashgar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Omsk***(GMT+06:00) Omsk***GMT+6', item_name: '(GMT+06:00) Omsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Qyzylorda***(GMT+06:00) Qyzylorda***GMT+6', item_name: '(GMT+06:00) Qyzylorda', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Thimbu***(GMT+06:00) Thimbu***GMT+6', item_name: '(GMT+06:00) Thimbu', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Thimphu***(GMT+06:00) Thimphu***GMT+6', item_name: '(GMT+06:00) Thimphu', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Urumqi***(GMT+06:00) Urumqi***GMT+6', item_name: '(GMT+06:00) Urumqi', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Chagos***(GMT+06:00) Chagos***GMT+6', item_name: '(GMT+06:00) Chagos', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Rangoon***(GMT+06:30) Rangoon***GMT+6:30', item_name: '(GMT+06:30) Rangoon', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Yangon***(GMT+06:30) Yangon***GMT+6:30', item_name: '(GMT+06:30) Yangon', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Cocos***(GMT+06:30) Cocos***GMT+6:30', item_name: '(GMT+06:30) Cocos', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Davis***(GMT+07:00) Davis***GMT+7', item_name: '(GMT+07:00) Davis', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Bangkok***(GMT+07:00) Bangkok***GMT+7', item_name: '(GMT+07:00) Bangkok', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Barnaul***(GMT+07:00) Barnaul***GMT+7', item_name: '(GMT+07:00) Barnaul', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Hovd***(GMT+07:00) Hovd***GMT+7', item_name: '(GMT+07:00) Hovd', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ho_Chi_Minh***(GMT+07:00) Ho Chi Minh***GMT+7', item_name: '(GMT+07:00) Ho Chi Minh', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Jakarta***(GMT+07:00) Western Indonesia Time - Jakarta***WIB', item_name: '(GMT+07:00) Western Indonesia Time - Jakarta', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Krasnoyarsk***(GMT+07:00) Krasnoyarsk***GMT+7', item_name: '(GMT+07:00) Krasnoyarsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Novokuznetsk***(GMT+07:00) Novokuznetsk***GMT+7', item_name: '(GMT+07:00) Novokuznetsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Novosibirsk***(GMT+07:00) Novosibirsk***GMT+7', item_name: '(GMT+07:00) Novosibirsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Phnom_Penh***(GMT+07:00) Phnom Penh***GMT+7', item_name: '(GMT+07:00) Phnom Penh', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Pontianak***(GMT+07:00) Western Indonesia Time - Pontianak***WIB', item_name: '(GMT+07:00) Western Indonesia Time - Pontianak', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Saigon***(GMT+07:00) Saigon***GMT+7', item_name: '(GMT+07:00) Saigon', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Tomsk***(GMT+07:00) Tomsk***GMT+7', item_name: '(GMT+07:00) Tomsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Vientiane***(GMT+07:00) Vientiane***GMT+7', item_name: '(GMT+07:00) Vientiane', category: 'time_zone_region' },
            { pick_list_id: 'Indian/Christmas***(GMT+07:00) Christmas***GMT+7', item_name: '(GMT+07:00) Christmas', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Brunei***(GMT+08:00) Brunei***GMT+8', item_name: '(GMT+08:00) Brunei', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Choibalsan***(GMT+08:00) Choibalsan***GMT+8', item_name: '(GMT+08:00) Choibalsan', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Chongqing***(GMT+08:00) China Standard Time - Chongqing***CST', item_name: '(GMT+08:00) China Standard Time - Chongqing', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Chungking***(GMT+08:00) China Standard Time - Chungking***CST', item_name: '(GMT+08:00) China Standard Time - Chungking', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Harbin***(GMT+08:00) China Standard Time - Harbin***CST', item_name: '(GMT+08:00) China Standard Time - Harbin', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Hong_Kong***(GMT+08:00) Hong Kong Time - Hong Kong***HKT', item_name: '(GMT+08:00) Hong Kong Time - Hong Kong', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Irkutsk***(GMT+08:00) Irkutsk***GMT+8', item_name: '(GMT+08:00) Irkutsk', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kuala_Lumpur***(GMT+08:00) Kuala Lumpur***GMT+8', item_name: '(GMT+08:00) Kuala Lumpur', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kuching***(GMT+08:00) Kuching***GMT+8', item_name: '(GMT+08:00) Kuching', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Macao***(GMT+08:00) China Standard Time - Macao***CST', item_name: '(GMT+08:00) China Standard Time - Macao', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Macau***(GMT+08:00) China Standard Time - Macau***CST', item_name: '(GMT+08:00) China Standard Time - Macau', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Makassar***(GMT+08:00) Central Indonesian Time - Makassar***WITA', item_name: '(GMT+08:00) Central Indonesian Time - Makassar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Manila***(GMT+08:00) Manila***GMT+8', item_name: '(GMT+08:00) Manila', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Shanghai***(GMT+08:00) China Standard Time - Shanghai***CST', item_name: '(GMT+08:00) China Standard Time - Shanghai', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Singapore***(GMT+08:00) Singapore***GMT+8', item_name: '(GMT+08:00) Singapore', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Taipei***(GMT+08:00) China Standard Time - Taipei***CST', item_name: '(GMT+08:00) China Standard Time - Taipei', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ujung_Pandang***(GMT+08:00) Central Indonesian Time - Ujung Pandang***WITA', item_name: '(GMT+08:00) Central Indonesian Time - Ujung Pandang', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ulaanbaatar***(GMT+08:00) Ulaanbaatar***GMT+8', item_name: '(GMT+08:00) Ulaanbaatar', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ulan_Bator***(GMT+08:00) Ulan Bator***GMT+8', item_name: '(GMT+08:00) Ulan Bator', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Perth***(GMT+08:00) Australian Western Standard Time - Perth***AWST', item_name: '(GMT+08:00) Australian Western Standard Time - Perth', category: 'time_zone_region' },
            { pick_list_id: 'Australia/West***(GMT+08:00) Australian Western Standard Time - West***AWST', item_name: '(GMT+08:00) Australian Western Standard Time - West', category: 'time_zone_region' },
            { pick_list_id: 'Hongkong***(GMT+08:00) Hong Kong Time - Hongkong***HKT', item_name: '(GMT+08:00) Hong Kong Time - Hongkong', category: 'time_zone_region' },
            { pick_list_id: 'PRC***(GMT+08:00) China Standard Time - PRC***CST', item_name: '(GMT+08:00) China Standard Time - PRC', category: 'time_zone_region' },
            { pick_list_id: 'ROC***(GMT+08:00) China Standard Time - ROC***CST', item_name: '(GMT+08:00) China Standard Time - ROC', category: 'time_zone_region' },
            { pick_list_id: 'Singapore***(GMT+08:00) Singapore***GMT+8', item_name: '(GMT+08:00) Singapore', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Pyongyang***(GMT+08:30) Korea Standard Time - Pyongyang***KST', item_name: '(GMT+08:30) Korea Standard Time - Pyongyang', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Eucla***(GMT+08:45) Eucla***GMT+8:45', item_name: '(GMT+08:45) Eucla', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Chita***(GMT+09:00) Chita***GMT+9', item_name: '(GMT+09:00) Chita', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Dili***(GMT+09:00) Dili***GMT+9', item_name: '(GMT+09:00) Dili', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Jayapura***(GMT+09:00) Eastern Indonesia Time - Jayapura***WIT', item_name: '(GMT+09:00) Eastern Indonesia Time - Jayapura', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Khandyga***(GMT+09:00) Khandyga***GMT+9', item_name: '(GMT+09:00) Khandyga', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Seoul***(GMT+09:00) Korea Standard Time - Seoul***KST', item_name: '(GMT+09:00) Korea Standard Time - Seoul', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Tokyo***(GMT+09:00) Japan Standard Time - Tokyo***JST', item_name: '(GMT+09:00) Japan Standard Time - Tokyo', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Yakutsk***(GMT+09:00) Yakutsk***GMT+9', item_name: '(GMT+09:00) Yakutsk', category: 'time_zone_region' },
            { pick_list_id: 'Japan***(GMT+09:00) Japan Standard Time - Japan***JST', item_name: '(GMT+09:00) Japan Standard Time - Japan', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Palau***(GMT+09:00) Palau***GMT+9', item_name: '(GMT+09:00) Palau', category: 'time_zone_region' },
            { pick_list_id: 'ROK***(GMT+09:00) Korea Standard Time - ROK***KST', item_name: '(GMT+09:00) Korea Standard Time - ROK', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Adelaide***(GMT+09:30) Australian Central Standard Time - Adelaide***ACST', item_name: '(GMT+09:30) Australian Central Standard Time - Adelaide', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Broken_Hill***(GMT+09:30) Australian Central Standard Time - Broken Hill***ACST', item_name: '(GMT+09:30) Australian Central Standard Time - Broken Hill', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Darwin***(GMT+09:30) Australian Central Standard Time - Darwin***ACST', item_name: '(GMT+09:30) Australian Central Standard Time - Darwin', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/DumontDUrville***(GMT+10:00) DumontDUrville***GMT+10', item_name: '(GMT+10:00) DumontDUrville', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Ust-Nera***(GMT+10:00) Ust-Nera***GMT+10', item_name: '(GMT+10:00) Ust-Nera', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Vladivostok***(GMT+10:00) Vladivostok***GMT+10', item_name: '(GMT+10:00) Vladivostok', category: 'time_zone_region' },
            { pick_list_id: 'Australia/ACT***(GMT+10:00) Australian Eastern Standard Time - ACT***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - ACT', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Brisbane***(GMT+10:00) Australian Eastern Standard Time - Brisbane***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Brisbane', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Canberra***(GMT+10:00) Australian Eastern Standard Time - Canberra***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Canberra', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Currie***(GMT+10:00) Australian Eastern Standard Time - Currie***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Currie', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Hobart***(GMT+10:00) Australian Eastern Standard Time - Hobart***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Hobart', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Lindeman***(GMT+10:00) Australian Eastern Standard Time - Lindeman***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Lindeman', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Melbourne***(GMT+10:00) Australian Eastern Standard Time - Melbourne***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Melbourne', category: 'time_zone_region' },
            { pick_list_id: 'Australia/NSW***(GMT+10:00) Australian Eastern Standard Time - NSW***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - NSW', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Queensland***(GMT+10:00) Australian Eastern Standard Time - Queensland***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Queensland', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Sydney***(GMT+10:00) Australian Eastern Standard Time - Sydney***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Sydney', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Tasmania***(GMT+10:00) Australian Eastern Standard Time - Tasmania***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Tasmania', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Victoria***(GMT+10:00) Australian Eastern Standard Time - Victoria***AEST', item_name: '(GMT+10:00) Australian Eastern Standard Time - Victoria', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Chuuk***(GMT+10:00) Chuuk***GMT+10', item_name: '(GMT+10:00) Chuuk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Guam***(GMT+10:00) Chamorro Standard Time - Guam***ChST', item_name: '(GMT+10:00) Chamorro Standard Time - Guam', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Port_Moresby***(GMT+10:00) Port Moresby***GMT+10', item_name: '(GMT+10:00) Port Moresby', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Saipan***(GMT+10:00) Chamorro Standard Time - Saipan***ChST', item_name: '(GMT+10:00) Chamorro Standard Time - Saipan', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Truk***(GMT+10:00) Truk***GMT+10', item_name: '(GMT+10:00) Truk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Yap***(GMT+10:00) Yap***GMT+10', item_name: '(GMT+10:00) Yap', category: 'time_zone_region' },
            { pick_list_id: 'Australia/LHI***(GMT+10:30) LHI***GMT+10:30', item_name: '(GMT+10:30) LHI', category: 'time_zone_region' },
            { pick_list_id: 'Australia/Lord_Howe***(GMT+10:30) Lord Howe***GMT+10:30', item_name: '(GMT+10:30) Lord Howe', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Casey***(GMT+11:00) Casey***GMT+11', item_name: '(GMT+11:00) Casey', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/Macquarie***(GMT+11:00) Macquarie***GMT+11', item_name: '(GMT+11:00) Macquarie', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Magadan***(GMT+11:00) Magadan***GMT+11', item_name: '(GMT+11:00) Magadan', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Srednekolymsk***(GMT+11:00) Srednekolymsk***GMT+11', item_name: '(GMT+11:00) Srednekolymsk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Bougainville***(GMT+11:00) Bougainville***GMT+11', item_name: '(GMT+11:00) Bougainville', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Efate***(GMT+11:00) Efate***GMT+11', item_name: '(GMT+11:00) Efate', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Guadalcanal***(GMT+11:00) Guadalcanal***GMT+11', item_name: '(GMT+11:00) Guadalcanal', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Kosrae***(GMT+11:00) Kosrae***GMT+11', item_name: '(GMT+11:00) Kosrae', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Norfolk***(GMT+11:00) Norfolk***GMT+11', item_name: '(GMT+11:00) Norfolk', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Noumea***(GMT+11:00) Noumea***GMT+11', item_name: '(GMT+11:00) Noumea', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Pohnpei***(GMT+11:00) Pohnpei***GMT+11', item_name: '(GMT+11:00) Pohnpei', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Ponape***(GMT+11:00) Ponape***GMT+11', item_name: '(GMT+11:00) Ponape', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/McMurdo***(GMT+12:00) New Zealand Standard Time - McMurdo***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - McMurdo', category: 'time_zone_region' },
            { pick_list_id: 'Antarctica/South_Pole***(GMT+12:00) New Zealand Standard Time - South Pole***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - South Pole', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Anadyr***(GMT+12:00) Anadyr***GMT+12', item_name: '(GMT+12:00) Anadyr', category: 'time_zone_region' },
            { pick_list_id: 'Asia/Kamchatka***(GMT+12:00) Kamchatka***GMT+12', item_name: '(GMT+12:00) Kamchatka', category: 'time_zone_region' },
            { pick_list_id: 'Kwajalein***(GMT+12:00) Kwajalein***GMT+12', item_name: '(GMT+12:00) Kwajalein', category: 'time_zone_region' },
            { pick_list_id: 'NZ***(GMT+12:00) New Zealand Standard Time - NZ***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - NZ', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Auckland***(GMT+12:00) New Zealand Standard Time - Auckland***NZST', item_name: '(GMT+12:00) New Zealand Standard Time - Auckland', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Fiji***(GMT+12:00) Fiji***GMT+12', item_name: '(GMT+12:00) Fiji', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Funafuti***(GMT+12:00) Funafuti***GMT+12', item_name: '(GMT+12:00) Funafuti', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Kwajalein***(GMT+12:00) Kwajalein***GMT+12', item_name: '(GMT+12:00) Kwajalein', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Majuro***(GMT+12:00) Majuro***GMT+12', item_name: '(GMT+12:00) Majuro', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Nauru***(GMT+12:00) Nauru***GMT+12', item_name: '(GMT+12:00) Nauru', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Tarawa***(GMT+12:00) Tarawa***GMT+12', item_name: '(GMT+12:00) Tarawa', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Wake***(GMT+12:00) Wake***GMT+12', item_name: '(GMT+12:00) Wake', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Wallis***(GMT+12:00) Wallis***GMT+12', item_name: '(GMT+12:00) Wallis', category: 'time_zone_region' },
            { pick_list_id: 'NZ-CHAT***(GMT+12:45) NZ-CHAT***GMT+12:45', item_name: '(GMT+12:45) NZ-CHAT', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Chatham***(GMT+12:45) Chatham***GMT+12:45', item_name: '(GMT+12:45) Chatham', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Apia***(GMT+13:00) Apia***GMT+13', item_name: '(GMT+13:00) Apia', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Enderbury***(GMT+13:00) Enderbury***GMT+13', item_name: '(GMT+13:00) Enderbury', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Fakaofo***(GMT+13:00) Fakaofo***GMT+13', item_name: '(GMT+13:00) Fakaofo', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Tongatapu***(GMT+13:00) Tongatapu***GMT+13', item_name: '(GMT+13:00) Tongatapu', category: 'time_zone_region' },
            { pick_list_id: 'Pacific/Kiritimati***(GMT+14:00) Kiritimati***GMT+14', item_name: '(GMT+14:00) Kiritimati', category: 'time_zone_region' }
        ]





        if (lang === 'en') { // English

            return time_zone_list_en;

        } else if (lang === 'fr') { // French

            return time_zone_list_fr;

        } else {

            return time_zone_list_en;

        }

    }






}