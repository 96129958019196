import React, { Component } from 'react';
import FormatService from '../modules/FormatService';


class DataCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            iconName: props.iconName || "language",
            title: props.title,
            totalValue: props.totalValue || 0,
            Lang: props.Lang || "en",
            valueIsDecimal: props.valueIsDecimal || true,
            valueDecimalPlaces: props.valueDecimalPlaces || 2,
            subCategoryTitle: props.subCategoryTitle,
            subCategoryValue: props.subCategoryValue || 0,
            subValueIsDecimal: props.subValueIsDecimal || false,
            subValueDecimalPlaces: props.subValueDecimalPlaces || 0,
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '6',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '3',
            cardColour: props.cardColour || 'cyan',
            textColour: props.textColour || 'white',
            hoverable: props.hoverable
        }
        this.Format = new FormatService();
    }

    render(){
        return (
            // <!--Data Card start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div className={`card ${(this.state.hoverable === true) ? "hoverable":""} ${this.state.cardColour} gradient-shadow min-height-100 ${this.state.textColour}-text`}>
                    <div className="padding-4">
                        <div className="col s5 m5">
                            <i className="material-icons background-round mt-5">{this.state.iconName}</i>
                            <p>{this.state.title}</p>
                        </div>
                        <div className="col s7 m7 right-align">
                            <h5 className="mb-0">{this.Format.FormatLocaleNumber(this.state.subCategoryValue, this.state.Lang, this.state.subValueIsDecimal, this.state.subValueDecimalPlaces)} </h5>
                            <p className="no-margin">{this.state.subCategoryTitle}</p>
                            <p>{this.Format.FormatLocaleNumber(this.state.totalValue, this.state.Lang, this.state.valueIsDecimal, this.state.valueDecimalPlaces)}</p>
                        </div>
                    </div>
                </div>
            </div>
            // <!--Data Card end-->
        )
    }
}

export default DataCard;