import React, { Component } from 'react';



class SelectField extends Component{
    constructor(props){
        super(props);
        this.state = {
            pick_list_id: props.pick_list_id,
            item_name: props.item_name,

        }
    }

    componentDidMount(){


            
    }

    render(){

            return (
                <option value={this.state.pick_list_id}>{this.state.item_name}</option>
            )

    }
}

export default SelectField;