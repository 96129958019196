import React, {Component} from 'react';
import {Link} from 'react-router-dom';




class HeaderNotificationItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            user: props.user,
            notification_hash_id: props.notification_hash_id,
            notification_time: props.notification_time,
            message_title: props.message_title,
            seconds_since_created: props.seconds_since_created,
            DateService: props.DateService,
        }


    }


    componentWillMount(){

    }


    render(){

        let time_phrase = this.state.DateService.convertSecondstoPeriodSince(this.state.seconds_since_created);
        
        
        return (
            <li>
                <Link to={`/notifications/${this.state.user}/${this.state.notification_hash_id}`} className="grey-text text-darken-2">
                    <span className="material-icons icon-bg-circle deep-orange small">info</span>
                    {this.state.message_title}
                </Link>
                <time className="media-meta" dateTime={this.state.notification_time}>{time_phrase}</time>
            </li>
        )
    }
}

export default HeaderNotificationItem;