import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');

class ECCUtilisationSummary extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getECCs = this.getECCs.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            eccs: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 500,
            paginationItem: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            eccDataItems: null,
            ecc_utilisation_edit: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.ECCUtilisationSummary + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();

        this.getECCs(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:ecc_utilisation', 'action:ecc_utilisation'], 'view') === false) {
            this.props.history.replace(default_home);
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ scopes: user_profile.scopes });
        }

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'update:ecc_utilisation' || this.state.scopes['views'][i] === 'action:ecc_utilisation') {
                    this.setState({ ecc_utilisation_edit: true });
                }
            }
        };


    }


    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            eccDataItems: null,
            paginationItem: null,
            eccs: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getECCs(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }




    getECCs(currentNumberOfRecords) {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/eccs//utilisation/summary?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ eccs: response.data.data }, () => {

                    this.setState({
                        eccDataItems: <DataTable tableName={''}
                            fields={['certificate_number', 'exporter_name', 'nxp_number', 'export_year', 'is_utilised', 'certificate_value', 'value_utilised', 'utilising_agency', 'transfered_to', 'transfer_approved']}
                            fieldDataTypes={['String', 'String', 'String', 'Date-Year', 'Boolean', 'Number', 'Number', 'String', 'String', 'Boolean']}
                            fieldDecimalPlaces={[0, 0, 0, 0, 0, 2, 2, 0, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.CertificateNumber, this.Language.DataTableHeader.Exporter, this.Language.DataTableHeader.NXPNumber, this.Language.DataTableHeader.ExportYear, this.Language.DataTableHeader.Utilised, this.Language.DataTableHeader.Value, this.Language.DataTableHeader.ValueUtilised, this.Language.DataTableHeader.UtilisingAgency, this.Language.DataTableHeader.TransferedTo, this.Language.DataTableHeader.TransferApproved]}
                            data={this.state.eccs}
                            sortType={"ascending"} sortPosition={1} thStyle={[51, 121, 164, 100, 100, 100, 145, 130, 100, 74]}
                            link={"/exporters/ecc/utilisation/edit/"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={this.state.ecc_utilisation_edit} linkToBlankTarget={false} linkToBlankTargetToken={''} download={false} downloadFileName={this.Language.DownloadFileName.ECCs} emptyMessage={this.Language.EmptyMessage.ECCs}
                            downloadFields={['certificate_number', 'date_issued', 'exporter_name', 'nxp_number', 'export_year', 'certificate_value', 'value_utilised', 'balance_value', 'is_utilised', 'utilising_agency', 'is_transfered', 'transfer_approved', 'is_approved', 'transfered_to']} />
                    })

                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });
                })

                this.setState({ result: response.data.status }, () => {
                })

                this.setState({ message: response.data.message }, () => {
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            });

    }



    render() {

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"ECCs"} active_menu_level_2={"ECC Utilisation"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.ECCUtilisationSummary}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ECCUtilisationSummary]} links={[`/`, `/exporters/ecc/utilisation`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.eccDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default ECCUtilisationSummary;