import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');


class EEGRates extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getEEGRates = this.getEEGRates.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            EEGRateItems: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 500,
            paginationItem: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            EEGRateDataItems: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EEGRates + ' - ' + this.Language.EEGWebPortal;

        this.getEEGRates(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:baselines', 'action:baselines'], 'view') === false) {
            this.props.history.replace(default_home);
        };

    }


    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        

        this.setState({
            EEGRateDataItems: null,
            paginationItem: null,
            EEGRateItems: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getEEGRates(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }




    getEEGRates(currentNumberOfRecords) {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';


        axios.get(`${this.apiURL}/api/v1/baseline/eeg/rates?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ EEGRateItems: response.data.data }, () => {

                    this.setState({
                      EEGRateDataItems: (
                        <DataTable
                          tableName={''}
                          fields={[
                            'business_year',
                            'exporter_name',
                            'exporter_category',
                            'export_growth_score',
                            'investment_growth_score',
                            'local_content_score',
                            'value_addition_score',
                            'employment_score',
                            'priority_sector_score',
                            'total_score',
                            'eeg_rate'
                          ]}
                          fieldDataTypes={['Date-Year', 'String', 'String', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number']}
                          fieldDecimalPlaces={[0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2]}
                          dateFormat={'long_date'}
                          timeFormat={'h12'}
                          headers={[
                            this.Language.DataTableHeader.BusinessYear,
                            this.Language.DataTableHeader.Exporter,
                            this.Language.DataTableHeader.ExporterCategory,
                            this.Language.DataTableHeader.ExportGrowthScore,
                            this.Language.DataTableHeader.InvestmentGrowthScore,
                            this.Language.DataTableHeader.LocalContentScore,
                            this.Language.DataTableHeader.ValueAdditionScore,
                            this.Language.DataTableHeader.EmploymentScore,
                            this.Language.DataTableHeader.PrioritySectorScore,
                            this.Language.DataTableHeader.TotalScore,
                            this.Language.DataTableHeader.EEGRate
                          ]}
                          data={this.state.EEGRateItems}
                          sortType={'ascending'}
                          sortPosition={1}
                          thStyle={[100, 221, 100, 100, 100, 100, 100, 75, 74, 51, 64]}
                          link={'/baseline/rates/'}
                          return_query_prefix={this.returnURLQuery}
                          current_path={this.props.history.location.pathname}
                          link_status={false}
                          linkToBlankTarget={false}
                          linkToBlankTargetToken={''}
                          download={true}
                          downloadFileName={this.Language.DownloadFileName.EEGRates}
                          emptyMessage={this.Language.EmptyMessage.EEGRates}
                          downloadFields={[
                            'business_year',
                            'exporter_name',
                            'exporter_category',
                            'export_growth_score',
                            'investment_growth_score',
                            'local_content_score',
                            'value_addition_score',
                            'employment_score',
                            'priority_sector_score',
                            'total_score',
                            'eeg_rate'
                          ]}
                        />
                      )
                    });

                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });
                })

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    render() {

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Baseline Data"} active_menu_level_2={"EEG Rates"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EEGRates}`} breadcrumbs={[this.Language.BreadCrumbLabel.BaselineData, this.Language.BreadCrumbLabel.EEGRates]} links={[`/exporter/baseline/data`, `/exporter/baseline/rates`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.EEGRateDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default EEGRates;