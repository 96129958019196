import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import FileUpload from '../components/FileUpload';
import URLService from '../modules/URLService';

const $ = require('jquery');

class AddBaselineData extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.addBaselineData = this.addBaselineData.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.addLocalContentItem = this.addLocalContentItem.bind(this);
    this.removeLocalContentItem = this.removeLocalContentItem.bind(this);
    this.submitBaselineData = this.submitBaselineData.bind(this);
    this.addBaselineDataFinal = this.addBaselineDataFinal.bind(this);
    this.getDropdownValues = this.getDropdownValues.bind(this);
    this.getExportYears = this.getExportYears.bind(this);
    this.state = {
      message: '',
      status: '',
      local_content_items_array: [],
      total_local_content_items_amount: 0,
      total_local_content_value: 0,
      total_foreign_content_value: 0,
      local_content_validation_message: '',
      pick_list_values: [],
      select_field_values_exporter: [],
      select_field_values_exported_item: [],
      select_field_values_business_year: [],
      select_field_values_currency: [],
      submit_baseline_data_button_caption: null,
      data_badge_caption_baseline_notes: '',
      data_badge_caption_employment_base: '',
      data_badge_caption_export_growth_base: '',
      data_badge_caption_investment_base: '',
      data_badge_caption_local_content_base: '',
      data_badge_caption_local_value_added_base: '',
      data_badge_caption_priority_sector_base: '',
      local_content_validation_class_value: 'row red-text',
      submitbuttondisabled: false,
      preloader: true,
      logged_in_username: '',
      text_limit: 3000,
      error_messages: null,
      fileUploadItem_1: null,
      fileUploadItem_2: null,
      fileUploadItem_3: null,
      fileUploadItem_4: null,
      fileUploadItem_5: null,
      fileUploadItem_6: null,
      fileUploadItem_7: null,
      close_error_messages: (
        <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
          <span aria-hidden="true">×</span>
        </button>
      )
    };
    this.Auth = new AuthService();
    this.Validate = new ValidationService();
    this.DateService = new DateService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.Format = new FormatService();
    this.apiURL = URLService.prototype.getApiURL();
  }

  componentWillMount() {}

  componentDidMount() {
    document.title = this.Language.PageTitle.AddBaselineData + ' - ' + this.Language.EEGWebPortal;
    this.getProfile();
    this.setState({
      data_badge_caption_baseline_notes: this.Language.ButtonLabel.Save,
      data_badge_caption_employment_base: this.Language.ButtonLabel.Save,
      data_badge_caption_export_growth_base: this.Language.ButtonLabel.Save,
      data_badge_caption_investment_base: this.Language.ButtonLabel.Save,
      data_badge_caption_local_content_base: this.Language.ButtonLabel.Save,
      data_badge_caption_local_value_added_base: this.Language.ButtonLabel.Save,
      data_badge_caption_priority_sector_base: this.Language.ButtonLabel.Save,
      submitbuttondisabled: false
    });

    window.$('.dropify').dropify({
      messages: {
        default: this.Language.FileUpload.Default,
        replace: this.Language.FileUpload.Replace,
        remove: this.Language.FileUpload.Remove,
        error: this.Language.FileUpload.Error
      }
    });
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    $('select').material_select('destroy');
  }

  getExportYears() {
    // let year_temp = null;
    // let select_field_values_business_year = [];

    // for (let i = 0; i < 20; i++) { // populate the values in the export year dropdown
    //     if (i === 0) {
    //         year_temp = new Date();
    //         year_temp = year_temp.getFullYear();
    //         select_field_values_business_year.push(year_temp - 5); // add five precedding years to the list
    //         select_field_values_business_year.push(year_temp - 4);
    //         select_field_values_business_year.push(year_temp - 3);
    //         select_field_values_business_year.push(year_temp - 2);
    //         select_field_values_business_year.push(year_temp - 1);
    //         select_field_values_business_year.push(year_temp);
    //     } else {
    //         select_field_values_business_year.push(select_field_values_business_year[select_field_values_business_year.length - 1] + 1); // get the last element in the array and add 1 to it before adding the value back to the array
    //     }
    // }

    // this.setState({ select_field_values_business_year: select_field_values_business_year });

    axios
      .get(`${this.apiURL}/api/v1/baseline/years/active/exporter/exporter/exporter_category`, {
        params: {
          tk: this.Auth.getToken()
        }
      })
      .then(response => {
        const baseline_years = response.data.data;

        const select_field_values_business_year = baseline_years.map((baseline_year, i) => {
          return <SelectField pick_list_id={baseline_year.business_year} key={i} item_name={baseline_year.business_year} />;
        });

        this.setState({
          select_field_values_business_year: select_field_values_business_year
        });
        window.$('select').material_select();
      })
      .catch(error => {});
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState({logged_in_username: user_profile.username}, () => {
        this.setState({
          fileUploadItem_1: (
            <FileUpload
              fieldName={this.Language.PageLabel.BaselineForms}
              fileExtensions={'pdf png jpeg jpg doc docx txt xls xlsx csv'}
              fieldID={'baseline-file-001'}
              maxFileSize={'10M'}
              defaultFile={''}
              fileName={'Baseline_Forms'}
              pageDataCategory={'Baseline_Forms'}
              pageName={'AddBaselineData'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
            />
          ),
          fileUploadItem_2: (
            <FileUpload
              fieldName={this.Language.PageLabel.AuditedStatementCurrent}
              fileExtensions={'pdf png jpeg jpg doc docx txt'}
              fieldID={'baseline-file-002'}
              maxFileSize={'10M'}
              defaultFile={''}
              fileName={'Audited_Statement_current'}
              pageDataCategory={'Audited_Statement'}
              pageName={'AddBaselineData'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
            />
          ),
          fileUploadItem_3: (
            <FileUpload
              fieldName={this.Language.PageLabel.ManagementAccountCurrent}
              fileExtensions={'pdf png jpeg jpg doc docx txt'}
              fieldID={'baseline-file-003'}
              maxFileSize={'10M'}
              defaultFile={''}
              fileName={'Management_Account_Current'}
              pageDataCategory={'Management_Account'}
              pageName={'AddBaselineData'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
            />
          ),
          fileUploadItem_4: (
            <FileUpload
              fieldName={this.Language.PageLabel.ProjectedFinancialStatement}
              fileExtensions={'pdf png jpeg jpg doc docx txt'}
              fieldID={'baseline-file-004'}
              maxFileSize={'10M'}
              defaultFile={''}
              fileName={'Projected_Statement'}
              pageDataCategory={'Projected_Statement'}
              pageName={'AddBaselineData'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
            />
          ),
          fileUploadItem_5: (
            <FileUpload
              fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear1}
              fileExtensions={'pdf png jpeg jpg doc docx txt'}
              fieldID={'baseline-file-005'}
              maxFileSize={'10M'}
              defaultFile={''}
              fileName={'Audited_Statement_Pre_Year_1'}
              pageDataCategory={'Audited_Statement'}
              pageName={'AddBaselineData'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
            />
          ),
          fileUploadItem_6: (
            <FileUpload
              fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear2}
              fileExtensions={'pdf png jpeg jpg doc docx txt'}
              fieldID={'baseline-file-006'}
              maxFileSize={'10M'}
              defaultFile={''}
              fileName={'Audited_Statement_Pre_Year_2'}
              pageDataCategory={'Audited_Statement'}
              pageName={'AddBaselineData'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
            />
          ),
          fileUploadItem_7: (
            <FileUpload
              fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear3}
              fileExtensions={'pdf png jpeg jpg doc docx txt'}
              fieldID={'baseline-file-007'}
              maxFileSize={'10M'}
              defaultFile={''}
              fileName={'Audited_Statement_Pre_Year_3'}
              pageDataCategory={'Audited_Statement'}
              pageName={'AddBaselineData'}
              loggedInUsername={this.state.logged_in_username}
              RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
            />
          )
        });

        this.checkAccessRight();
      });
    }
  }

  checkAccessRight() {
    if (this.Auth.checkAccessScope(['create:baselines', 'action:baselines'], 'view') === false) {
      this.props.history.replace('/exporter/baseline/data');
    } else {
      this.getDropdownValues('exporter_id_and_hash,currency,exported_item');
      this.getExportYears();
    }
  }

  handleCloseAlert() {
    this.setState({error_messages: null}); // change the value of error_message to close the alert box
  }

  addLocalContentItem() {
    let local_content_base_currency = this.refs.local_content_base_currency.value;
    if (local_content_base_currency === 'Select Currency') local_content_base_currency = '';

    let exported_item = this.refs.exported_item.value;
    if (exported_item === 'Select Exported Item') exported_item = '';

    let foreign_content = this.refs.foreign_content.value;
    let local_content = this.refs.local_content.value;
    let export_value = this.refs.export_value.value;
    let local_content_base_is_approved = this.refs.local_content_base_is_approved.checked;
    let local_content_approved_value = this.Language.DataValue.No;
    if (local_content_base_is_approved === true) local_content_approved_value = this.Language.DataValue.Yes;
    let local_content_base_status = 'Inactive';
    if (this.refs.activate_switch_local_content_base.checked) local_content_base_status = 'Active';
    if (!this.refs.activate_switch_local_content_base.checked) local_content_base_status = 'Inactive';

    if (!isNaN(parseFloat(export_value)) && isFinite(export_value) && !isNaN(parseFloat(local_content)) && isFinite(local_content) && !isNaN(parseFloat(local_content)) && isFinite(local_content)) {
      if (
        local_content_base_currency !== '' &&
        local_content_base_currency !== null &&
        typeof local_content_base_currency !== 'undefined' &&
        exported_item !== '' &&
        exported_item !== null &&
        typeof exported_item !== 'undefined' &&
        local_content > 0 &&
        (export_value > 0 || foreign_content > 0)
      ) {
        const newLocalContentItem = {
          local_content_base_currency: local_content_base_currency,
          exported_item: exported_item,
          export_value: Math.abs(parseFloat(export_value)),
          local_content: Math.abs(parseFloat(local_content)),
          foreign_content: Math.abs(parseFloat(foreign_content)),
          local_content_base_is_approved: local_content_base_is_approved,
          local_content_approved_value: local_content_approved_value,
          local_content_base_status: local_content_base_status
        };

        let temp_local_content_items_array = this.state.local_content_items_array;
        temp_local_content_items_array.push(newLocalContentItem);
        this.setState({
          local_content_items_array: temp_local_content_items_array,
          total_local_content_items_amount: parseFloat(this.state.total_local_content_items_amount) + parseFloat(newLocalContentItem.export_value),
          total_local_content_value: parseFloat(this.state.total_local_content_value) + parseFloat(newLocalContentItem.local_content),
          total_foreign_content_value: parseFloat(this.state.total_foreign_content_value) + parseFloat(newLocalContentItem.foreign_content),
          local_content_validation_message: ''
        });
      } else {
        this.setState({
          local_content_validation_class_value: 'row red-text',
          local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidation
        });
      }
    } else {
      this.setState({
        local_content_validation_class_value: 'row red-text',
        local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidationDetails
      });
    }
  }

  removeLocalContentItem(position, export_value, local_content_value, foreign_content_value) {
    let temp_local_content_items_array = this.state.local_content_items_array;
    temp_local_content_items_array = temp_local_content_items_array.filter((value, i) => i !== position);
    this.setState({
      local_content_items_array: temp_local_content_items_array,
      total_local_content_items_amount: parseFloat(this.state.total_local_content_items_amount) - parseFloat(export_value),
      total_local_content_value: parseFloat(this.state.total_local_content_value) - parseFloat(local_content_value),
      total_foreign_content_value: parseFloat(this.state.total_foreign_content_value) - parseFloat(foreign_content_value),
      local_content_validation_message: ''
    });
  }

  submitBaselineData(baseline_option) {
    let exporter_id_temp = this.refs.exporter_id.value;
    let exporter_id = '';
    let exporter_hash_id = '';
    let exporter_id_temp_array = ['', ''];
    if (exporter_id_temp === 'Select Exporter') {
      exporter_id_temp = '';
    } else {
      exporter_id_temp_array = exporter_id_temp.split('<:>');
      exporter_id = exporter_id_temp_array[0];
      exporter_hash_id = exporter_id_temp_array[1];
    }

    // let exporter_name = this.refs.exporter_name.value;

    let business_year = this.refs.business_year.value;
    if (business_year === 'Select Business Year') business_year = '';

    let created_by = this.state.logged_in_username;
    let last_updated_by = this.state.logged_in_username;

    // The section below handles the data to be assigned based on the baseline option

    if (baseline_option === 'baseline_notes') {
      let notes = this.refs.notes.value;
      let reviewer_notes = this.refs.reviewer_notes.value;
      let baseline_note_status = 'Inactive';
      if (this.refs.activate_switch_baseline_notes.checked) baseline_note_status = 'Active';
      if (!this.refs.activate_switch_baseline_notes.checked) baseline_note_status = 'Inactive';

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
            [exporter_id, business_year, notes, notes, reviewer_notes],
            [this.Language.DataField.Exporter, this.Language.DataField.BusinessYear, this.Language.DataField.Notes, this.Language.DataField.Notes, this.Language.DataField.ReviewerNotes],
            ['isRequired', 'isRequired', 'isRequired', 'maxLength', 'maxLength'],
            0,
            this.state.text_limit
          )
        },
        () => {
          if (this.state.error_messages === null) {
            if (exporter_id === 'Select Exporter') exporter_id = null;
            if (business_year === 'Select Business Year') business_year = null;

            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              business_year: business_year,
              notes: notes,
              reviewer_notes: reviewer_notes,
              baseline_option: baseline_option,
              status: baseline_note_status,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true});

            this.addBaselineData(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }

    if (baseline_option === 'employment_base') {
      let total_staff_strength = this.refs.total_staff_strength.value;
      let total_nigerian_staff = this.refs.total_nigerian_staff.value;
      let employment_base_status = 'Inactive';
      if (this.refs.activate_switch_employment_base.checked) employment_base_status = 'Active';
      if (!this.refs.activate_switch_employment_base.checked) employment_base_status = 'Inactive';

      let employment_base_is_approved = this.refs.employment_base_is_approved.checked;
      let employment_base_approved_by = null;
      if (employment_base_is_approved) employment_base_approved_by = this.state.logged_in_username;

      let employment_base_approved_at = null;
      if (employment_base_is_approved) {
        let currentdate = new Date();
        employment_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
            [exporter_id, business_year, total_staff_strength, total_staff_strength, total_nigerian_staff, total_nigerian_staff],
            [
              this.Language.DataField.Exporter,
              this.Language.DataField.BusinessYear,
              this.Language.DataField.TotalStaffStrength,
              this.Language.DataField.TotalStaffStrength,
              this.Language.DataField.TotalNigerianStaff,
              this.Language.DataField.TotalNigerianStaff
            ],
            ['isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              business_year: business_year,
              total_staff_strength: Math.abs(total_staff_strength) || null,
              total_nigerian_staff: Math.abs(total_nigerian_staff) || null,
              baseline_option: baseline_option,
              status: employment_base_status,
              is_approved: employment_base_is_approved,
              approved_by: employment_base_approved_by,
              approved_at: employment_base_approved_at,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'Web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true});

            this.addBaselineData(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }

    if (baseline_option === 'export_growth_base') {
      let export_growth_base_currency = this.refs.export_growth_base_currency.value;
      if (export_growth_base_currency === 'Select Currency') export_growth_base_currency = '';

      let total_export_value = this.refs.total_export_value.value;
      let export_growth_rate = this.refs.export_growth_rate.value;
      let export_growth_base_status = 'Inactive';
      if (this.refs.activate_switch_export_growth_base.checked) export_growth_base_status = 'Active';
      if (!this.refs.activate_switch_export_growth_base.checked) export_growth_base_status = 'Inactive';

      let export_growth_base_is_approved = this.refs.export_growth_base_is_approved.checked;
      let export_growth_base_approved_by = null;
      if (export_growth_base_is_approved) export_growth_base_approved_by = this.state.logged_in_username;

      let export_growth_base_approved_at = null;
      if (export_growth_base_is_approved) {
        let currentdate = new Date();
        export_growth_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
            [exporter_id, business_year, export_growth_base_currency, total_export_value, total_export_value, export_growth_rate, export_growth_rate],
            [
              this.Language.DataField.Exporter,
              this.Language.DataField.BusinessYear,
              this.Language.DataField.Currency,
              this.Language.DataField.TotalExportValue,
              this.Language.DataField.TotalExportValue,
              this.Language.DataField.ExportGrowth,
              this.Language.DataField.ExportGrowth
            ],
            ['isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            if (export_growth_base_currency === 'Select Currency') export_growth_base_currency = null;

            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              business_year: business_year,
              currency: export_growth_base_currency,
              total_export_value: Math.abs(total_export_value) || null,
              export_growth_rate: Math.abs(export_growth_rate) || null,
              baseline_option: baseline_option,
              status: export_growth_base_status,
              is_approved: export_growth_base_is_approved,
              approved_by: export_growth_base_approved_by,
              approved_at: export_growth_base_approved_at,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'Web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true});

            this.addBaselineData(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }

    if (baseline_option === 'investment_base') {
      let investment_base_currency = this.refs.investment_base_currency.value;
      if (investment_base_currency === 'Select Currency') investment_base_currency = '';

      let additions = this.refs.additions.value;
      let opening_nbv = this.refs.opening_nbv.value;
      let capital_investment_growth = this.refs.capital_investment_growth.value;
      let investment_base_status = 'Inactive';
      if (this.refs.activate_switch_investment_base.checked) investment_base_status = 'Active';
      if (!this.refs.activate_switch_investment_base.checked) investment_base_status = 'Inactive';

      let investment_base_is_approved = this.refs.investment_base_is_approved.checked;
      let investment_base_approved_by = null;
      if (investment_base_is_approved) investment_base_approved_by = this.state.logged_in_username;

      let investment_base_approved_at = null;
      if (investment_base_is_approved) {
        let currentdate = new Date();
        investment_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
            [exporter_id, business_year, investment_base_currency, additions, additions, opening_nbv, opening_nbv, capital_investment_growth, capital_investment_growth],
            [
              this.Language.DataField.Exporter,
              this.Language.DataField.BusinessYear,
              this.Language.DataField.Currency,
              this.Language.DataField.Additions,
              this.Language.DataField.Additions,
              this.Language.DataField.OpenningNBV,
              this.Language.DataField.OpenningNBV,
              this.Language.DataField.CapitalInvestment,
              this.Language.DataField.CapitalInvestment
            ],
            ['isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            if (investment_base_currency === 'Select Currency') investment_base_currency = null;

            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              business_year: business_year,
              currency: investment_base_currency,
              additions: Math.abs(additions) || null,
              opening_nbv: Math.abs(opening_nbv) || null,
              capital_investment_growth: Math.abs(capital_investment_growth) || null,
              baseline_option: baseline_option,
              status: investment_base_status,
              is_approved: investment_base_is_approved,
              approved_by: investment_base_approved_by,
              approved_at: investment_base_approved_at,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'Web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true});

            this.addBaselineData(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }

    if (baseline_option === 'local_content_base') {
      // let local_content_base_currency = this.refs.local_content_base_currency.value;
      // if(local_content_base_currency === "Select Currency") local_content_base_currency = "";

      // let exported_item = this.refs.exported_item.value;
      // if(exported_item === "Select Exported Item") exported_item = "";

      // let foreign_content = this.refs.foreign_content.value;
      // let local_content = this.refs.local_content.value;
      // let export_value = this.refs.export_value.value;
      // let local_content_base_status = "Inactive";
      // if(this.refs.activate_switch_local_content_base.checked) local_content_base_status = "Active";
      // if(!this.refs.activate_switch_local_content_base.checked) local_content_base_status = "Inactive";

      let local_content_items_array = this.state.local_content_items_array;
      let local_content_items_validation = false;
      if (this.state.local_content_items_array && this.state.local_content_items_array.length <= 0) {
        local_content_items_validation = true;
      }

      let local_content_base_is_approved = this.refs.local_content_base_is_approved.checked;
      let local_content_base_approved_by = null;
      if (local_content_base_is_approved) local_content_base_approved_by = this.state.logged_in_username;

      let local_content_base_approved_at = null;
      if (local_content_base_is_approved) {
        let currentdate = new Date();
        local_content_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      // this.setState({error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.BaselineData.AddErrorTitle, [exporter_id, business_year, local_content_base_currency, foreign_content, foreign_content, local_content, local_content, export_value, export_value],[this.Language.DataField.Exporter, this.Language.DataField.BusinessYear, this.Language.DataField.Currency, this.Language.DataField.ForeignContent, this.Language.DataField.ForeignContent, this.Language.DataField.LocalContent, this.Language.DataField.LocalContent, this.Language.DataField.ExportValue, this.Language.DataField.ExportValue],["isRequired", "isRequired", "isRequired", "isRequired", "isNumber", "isRequired", "isNumber", "isRequired", "isNumber"],0,0)}, () => {

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
            [exporter_id, business_year, local_content_items_validation, local_content_items_array],
            [this.Language.DataField.Exporter, this.Language.DataField.BusinessYear, this.Language.NodeMessage.BaselineData.NoLocalContentItemCaptured, this.Language.DataField.LocalContentItems],
            ['isRequired', 'isRequired', 'ShowCustomMessage', 'isArray'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            // if(local_content_base_currency === "Select Currency") local_content_base_currency = null;
            // if(exported_item === "Select Exported Item") exported_item = null;

            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              business_year: business_year,
              // currency: local_content_base_currency,
              // exported_item: exported_item,
              // foreign_content: Math.abs(foreign_content) || null,
              // local_content: Math.abs(local_content) || null,
              // export_value: Math.abs(export_value) || null,
              local_content_items_array: local_content_items_array,
              baseline_option: baseline_option,
              // status: local_content_base_status,
              // is_approved: local_content_base_is_approved,
              approved_by: local_content_base_approved_by,
              approved_at: local_content_base_approved_at,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'Web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true});

            this.addBaselineData(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }

    if (baseline_option === 'local_value_added_base') {
      let local_value_added_base_currency = this.refs.local_value_added_base_currency.value;
      if (local_value_added_base_currency === 'Select Currency') local_value_added_base_currency = '';

      let turnover = this.refs.turnover.value;
      let other_incomes = this.refs.other_incomes.value;
      let bought_in_goods_and_materials = this.refs.bought_in_goods_and_materials.value;
      let local_value_added = this.refs.local_value_added.value;
      let local_value_added_base_status = 'Inactive';
      if (this.refs.activate_switch_local_value_added_base.checked) local_value_added_base_status = 'Active';
      if (!this.refs.activate_switch_local_value_added_base.checked) local_value_added_base_status = 'Inactive';

      let local_value_added_base_is_approved = this.refs.local_value_added_base_is_approved.checked;
      let local_value_added_base_approved_by = null;
      if (local_value_added_base_is_approved) local_value_added_base_approved_by = this.state.logged_in_username;

      let local_value_added_base_approved_at = null;
      if (local_value_added_base_is_approved) {
        let currentdate = new Date();
        local_value_added_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
            [exporter_id, business_year, local_value_added_base_currency, turnover, turnover, other_incomes, other_incomes, bought_in_goods_and_materials, bought_in_goods_and_materials],
            [
              this.Language.DataField.Exporter,
              this.Language.DataField.BusinessYear,
              this.Language.DataField.Currency,
              this.Language.DataField.Turnover,
              this.Language.DataField.Turnover,
              this.Language.DataField.OtherIncomes,
              this.Language.DataField.OtherIncomes,
              this.Language.DataField.BoughtInGoodsAndMaterials,
              this.Language.DataField.BoughtInGoodsAndMaterials
            ],
            ['isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            if (local_value_added_base_currency === 'Select Currency') local_value_added_base_currency = null;

            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              business_year: business_year,
              currency: local_value_added_base_currency,
              turnover: Math.abs(turnover) || null,
              other_incomes: Math.abs(other_incomes) || null,
              bought_in_goods_and_materials: Math.abs(bought_in_goods_and_materials) || null,
              local_value_added: Math.abs(local_value_added) || null,
              baseline_option: baseline_option,
              status: local_value_added_base_status,
              is_approved: local_value_added_base_is_approved,
              approved_by: local_value_added_base_approved_by,
              approved_at: local_value_added_base_approved_at,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'Web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true});

            this.addBaselineData(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }

    if (baseline_option === 'priority_sector_base') {
      let priority_sector = this.refs.priority_sector.checked;
      let priority_sector_base_status = 'Inactive';
      if (this.refs.activate_switch_priority_sector_base.checked) priority_sector_base_status = 'Active';
      if (!this.refs.activate_switch_priority_sector_base.checked) priority_sector_base_status = 'Inactive';

      let priority_sector_base_is_approved = this.refs.priority_sector_base_is_approved.checked;
      let priority_sector_base_approved_by = null;
      if (priority_sector_base_is_approved) priority_sector_base_approved_by = this.state.logged_in_username;

      let priority_sector_base_approved_at = null;
      if (priority_sector_base_is_approved) {
        let currentdate = new Date();
        priority_sector_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
            [exporter_id, business_year],
            [this.Language.DataField.Exporter, this.Language.DataField.BusinessYear],
            ['isRequired', 'isRequired'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              business_year: business_year,
              priority_sector: priority_sector,
              baseline_option: baseline_option,
              status: priority_sector_base_status,
              is_approved: priority_sector_base_is_approved,
              approved_by: priority_sector_base_approved_by,
              approved_at: priority_sector_base_approved_at,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'Web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true});

            this.addBaselineData(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }

    if (baseline_option === 'submit_baseline_data_final') {
      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.BaselineData.SubmitErrorTitle,
            [exporter_id, business_year],
            [this.Language.DataField.Exporter, this.Language.DataField.BusinessYear],
            ['isRequired', 'isRequired'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            const userOS = this.Browser.getOS();

            //make call to database
            const newBaselineData = {
              exporter_id: exporter_id,
              exporter_hash_id: exporter_hash_id,
              business_year: business_year,
              baseline_option: baseline_option,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'web',
              token: this.Auth.getToken()
            };


            this.setState({preloader: true, submitbuttondisabled: true});

            this.addBaselineDataFinal(newBaselineData);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }
  }

  addBaselineDataFinal(newBaselineData) {
    axios
      .request({
        method: 'put',
        url: `${this.apiURL}/api/v1/baseline/data/submit/baseline/${newBaselineData.exporter_hash_id}`,
        data: newBaselineData
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {
          if (response.status === 200 && newBaselineData.baseline_option === 'submit_baseline_data_final')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.SubmitSuccessBaselineData + newBaselineData.business_year, 8000, 'rounded green');
        });

        this.setState({preloader: null});

        if (newBaselineData.baseline_option === 'submit_baseline_data_final')
          this.setState({
            submit_baseline_data_button_caption: this.Language.ButtonLabel.Submitted
          });
      })
      .catch(error => {
        if (error.response) {

          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.BaselineData.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400)
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.IncompleteData + ' ' + error.response.data.baseline_message, 10000, 'rounded deep-orange darken-4');
            if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.BaselineData.AlreadySubmitted, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null, submitbuttondisabled: false});
        // document.getElementById("save_baseline_data_button").removeAttribute("disabled");
      });
  }

  getDropdownValues(pick_list_names) {
    axios
      .get(`${this.apiURL}/api/v1/settings/picklists`, {
        params: {
          tk: this.Auth.getToken(),
          pick_list_names: pick_list_names
        }
      })
      .then(response => {
        this.setState({pick_list_values: response.data.data}, () => {

          if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {
            const select_field_values_exporter = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'exporter_id_and_hash') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({
              select_field_values_exporter: select_field_values_exporter
            });

            const select_field_values_exported_item = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'exported_item') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({
              select_field_values_exported_item: select_field_values_exported_item
            });

            const select_field_values_currency = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'currency' && pick_list_value.item_name === 'NGN') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({
              select_field_values_currency: select_field_values_currency
            });

            // const select_field_values_business_year = this.state.select_field_values_business_year.map((business_year_value, i) => {
            //     return (
            //         <SelectField pick_list_id={business_year_value} key={i} item_name={business_year_value} />
            //     )
            // })

            // this.setState({ select_field_values_business_year: select_field_values_business_year });
          }
        });

        window.$('select').material_select();
        this.setState({preloader: null});
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null});
      });
  }

  addBaselineData(newBaselineData) {
    axios
      .request({
        method: 'post',
        url: `${this.apiURL}/api/v1/baseline/data`,
        data: newBaselineData
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {
          if (response.status === 200 && newBaselineData.baseline_option === 'baseline_notes')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessBaselineNote + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'employment_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessEmploymentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'export_growth_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessExportGrowthBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'investment_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessInvestmentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'local_content_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessLocalContentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'local_value_added_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessLocalValueBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'priority_sector_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessPrioritySectorBase + newBaselineData.business_year, 8000, 'rounded green');
        });

        this.setState({preloader: null});

        if (newBaselineData.baseline_option === 'baseline_notes')
          this.setState({
            data_badge_caption_baseline_notes: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'employment_base')
          this.setState({
            data_badge_caption_employment_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'export_growth_base')
          this.setState({
            data_badge_caption_export_growth_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'investment_base')
          this.setState({
            data_badge_caption_investment_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'local_content_base')
          this.setState({
            data_badge_caption_local_content_base: this.Language.ButtonLabel.ItemSaved,
            local_content_items_array: [], // clear the array of local content items
            local_content_validation_class_value: 'row green-text',
            local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidationSaved
          });
        if (newBaselineData.baseline_option === 'local_value_added_base')
          this.setState({
            data_badge_caption_local_value_added_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'priority_sector_base')
          this.setState({
            data_badge_caption_priority_sector_base: this.Language.ButtonLabel.ItemSaved
          });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.BaselineData.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.BaselineData.AlreadyExist, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null});
        // document.getElementById("save_baseline_data_button").removeAttribute("disabled");

        if (newBaselineData.baseline_option === 'baseline_notes')
          this.setState({
            data_badge_caption_baseline_notes: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'employment_base')
          this.setState({
            data_badge_caption_employment_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'export_growth_base')
          this.setState({
            data_badge_caption_export_growth_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'investment_base')
          this.setState({
            data_badge_caption_investment_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'local_content_base')
          this.setState({
            data_badge_caption_local_content_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'local_value_added_base')
          this.setState({
            data_badge_caption_local_value_added_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'priority_sector_base')
          this.setState({
            data_badge_caption_priority_sector_base: this.Language.ButtonLabel.Save
          });
      });
  }

  render() {
    $('.switch')
      .find('input[type=checkbox]')
      .prop('checked', true);

    $('.employment_base_is_approved')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.export_growth_base_is_approved')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.investment_base_is_approved')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.local_content_base_is_approved')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.local_value_added_base_is_approved')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.priority_sector_base_is_approved')
      .find('input[type=checkbox]')
      .prop('checked', true);

    window.$('.collapsible').collapsible();


    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Baseline Data'} active_menu_level_2={'Baseline Data Capture'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.AddBaselineData}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.BaselineData, this.Language.BreadCrumbLabel.BaselineDataSummary, this.Language.BreadCrumbLabel.AddBaselineData]}
              links={[`/`, `/exporter/baseline/data/`, `/exporter/baseline/data/add`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <div className="row">
                <div className="col s12 l12">
                  {this.state.error_messages}

                  <div className="card-panel">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <h4 className="header2">{this.Language.PageLabel.AddBaselineData}</h4>
                    <div className="row">
                      <div className="row">
                        <div className="input-field col s8">
                          <div className="select-wrapper initialized">
                            <span className="caret">▼</span>
                            <select data-select-id="select-options-exporter_id" className="initialized" name="exporter_id" id="exporter_id" ref="exporter_id">
                              <option value="Select Exporter">{this.Language.DataFieldDefault.SelectExporter}</option>
                              {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                return exporter_value;
                              })}
                            </select>
                          </div>
                          <label>{this.Language.DataField.Exporter} *</label>
                        </div>
                        <div className="input-field col s4">
                          <div className="select-wrapper initialized">
                            <span className="caret">▼</span>
                            <select data-select-id="select-options-business_year" className="initialized" name="business_year" ref="business_year" id="business_year">
                              <option value="Select Business Year">{this.Language.DataFieldDefault.SelectBusinessYear}</option>
                              {this.state.select_field_values_business_year.map((business_year_value, i) => {
                                return business_year_value;
                              })}
                            </select>
                          </div>
                          <label>
                            {this.Language.DataField.BusinessYear} * - {this.Language.PageLabel.ForSubsequentYearsExports}
                          </label>
                        </div>
                      </div>

                      <ul className="collapsible" data-collapsible="expandable">
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">supervisor_account</i>
                            {this.Language.DataField.Employment}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s4">
                                <input id="total_staff_strength" name="total_staff_strength" type="number" ref="total_staff_strength" className="validate" />
                                <label htmlFor="total_staff_strength" data-error={this.Language.DataFieldError.IntegerNumber} className="">
                                  {this.Language.DataField.TotalStaffStrength} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input id="total_nigerian_staff" name="total_nigerian_staff" type="number" ref="total_nigerian_staff" className="validate" />
                                <label htmlFor="total_nigerian_staff" data-error={this.Language.DataFieldError.IntegerNumber} className="">
                                  {this.Language.DataField.TotalNigerianStaff} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="employment_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="employment_base_is_approved" ref="employment_base_is_approved" />
                                  <label htmlFor="employment_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_employment_base" ref="activate_switch_employment_base" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('employment_base');
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_employment_base}></span>
                                </Link>
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.EmploymentBase}>help_outline</i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">trending_up</i>
                            {this.Language.DataField.ExportGrowth}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id="select-options-export_growth_base_currency"
                                    className="initialized"
                                    name="export_growth_base_currency"
                                    id="export_growth_base_currency"
                                    ref="export_growth_base_currency">
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s5">
                                <input id="total_export_value" name="total_export_value" type="number" step="any" className="validate" ref="total_export_value" />
                                <label htmlFor="total_export_value" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.TotalExportValue} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s5">
                                <input id="export_growth_rate" name="export_growth_rate" type="number" step="any" className="validate" ref="export_growth_rate" />
                                <label htmlFor="export_growth_rate" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.ExportGrowth} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="export_growth_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="export_growth_base_is_approved" ref="export_growth_base_is_approved" />
                                  <label htmlFor="export_growth_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_export_growth_base" ref="activate_switch_export_growth_base" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('export_growth_base');
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_export_growth_base}></span>
                                </Link>
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.ExportGrowthBase}>help_outline</i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">account_balance_wallet</i>
                            {this.Language.DataField.CapitalInvestment}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id="select-options-investment_base_currency"
                                    className="initialized"
                                    name="investment_base_currency"
                                    id="investment_base_currency"
                                    ref="investment_base_currency">
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s3">
                                <input id="additions" name="additions" type="number" step="any" className="validate" ref="additions" />
                                <label htmlFor="additions" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.Additions} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s3">
                                <input id="opening_nbv" name="opening_nbv" type="number" step="any" className="validate" ref="opening_nbv" />
                                <label htmlFor="opening_nbv" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.OpenningNBV} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s3">
                                <input id="capital_investment_growth" name="capital_investment_growth" type="number" step="any" className="validate" ref="capital_investment_growth" />
                                <label htmlFor="capital_investment_growth" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.CapitalInvestmentGrowth} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="investment_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="investment_base_is_approved" ref="investment_base_is_approved" />
                                  <label htmlFor="investment_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_investment_base" ref="activate_switch_investment_base" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('investment_base');
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_investment_base}></span>
                                </Link>
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.InvestmentBase}>help_outline</i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">chrome_reader_mode</i>
                            {this.Language.DataField.LocalContent}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s8">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select data-select-id="select-options-exported_item" className="initialized" name="exported_item" id="exported_item" ref="exported_item">
                                    <option value="Select Exported Item">{this.Language.DataFieldDefault.SelectExportedItem}</option>
                                    {this.state.select_field_values_exported_item.map((exported_item_value, i) => {
                                      return exported_item_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.ExportedItem} *</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id="select-options-local_content_base_currency"
                                    className="initialized"
                                    name="local_content_base_currency"
                                    id="local_content_base_currency"
                                    ref="local_content_base_currency">
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s6">
                                <input id="export_value" name="export_value" type="number" step="any" className="validate" ref="export_value" />
                                <label htmlFor="export_value" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.ExportValue} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s6">
                                <input id="foreign_content" name="foreign_content" type="number" step="any" className="validate" ref="foreign_content" />
                                <label htmlFor="foreign_content" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.ForeignContent} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <input id="local_content" name="local_content" type="number" step="any" className="validate" ref="local_content" />
                                <label htmlFor="local_content" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.LocalContent} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="local_content_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="local_content_base_is_approved" ref="local_content_base_is_approved" />
                                  <label htmlFor="local_content_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_local_content_base" ref="activate_switch_local_content_base" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className={this.state.local_content_validation_class_value}>
                              <div className="col s12">{this.state.local_content_validation_message}</div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <Link to={`#!`} onClick={this.addLocalContentItem}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.AddLocalContentItem}></span>
                                </Link>
                              </div>
                            </div>
                            <div className="row teal-text">
                              <div className="col s2">
                                <span>
                                  <b>{this.Language.DataField.LocalContentItems}:</b> {this.state.local_content_items_array.length && this.state.local_content_items_array.length > 0 ? this.state.local_content_items_array.length : null}
                                </span>
                              </div>
                              <div className="col s3">
                                <span>
                                  <b>{this.Language.DataField.TotalExportValue}:</b> {isNaN(this.state.total_local_content_items_amount) === false ? this.Format.FormatLocaleNumber(parseFloat(this.state.total_local_content_items_amount), this.Language.Lang,true,2) : null}
                                </span>
                              </div>
                              <div className="col s3">
                                <span>
                                  <b>{this.Language.DataField.TotalLocalContent}:</b> {isNaN(this.state.total_local_content_value) === false ? this.Format.FormatLocaleNumber(parseFloat(this.state.total_local_content_value), this.Language.Lang,true,2) : null}
                                </span>
                              </div>
                              <div className="col s3">
                                <span>
                                  <b>{this.Language.DataField.TotalForeignContent}:</b> {isNaN(this.state.total_foreign_content_value) === false ? this.Format.FormatLocaleNumber(parseFloat(this.state.total_foreign_content_value), this.Language.Lang,true,2) : null}
                                </span>
                              </div>
                            </div>
                            <br />
                            {this.state.local_content_items_array.map((local_content_item, i) => {
                              return [
                                <div className="row" key={i}>
                                  <div className="col s6">
                                    <span>
                                      <b>
                                        {i + 1}. {this.Language.DataField.ExportedItem}:{' '}
                                      </b>{' '}
                                      {local_content_item.exported_item}
                                    </span>
                                    <p></p>
                                    <span>
                                      <b> {this.Language.DataField.ExportValue}: </b> {local_content_item.local_content_base_currency}{' '}
                                      {this.Format.FormatLocaleNumber(local_content_item.export_value, this.Language.Lang, true, 2)}
                                    </span>
                                  </div>
                                  <div className="col s3">
                                    <span>
                                      <b>{this.Language.DataField.LocalContent}: </b> {local_content_item.local_content_base_currency}{' '}
                                      {this.Format.FormatLocaleNumber(local_content_item.local_content, this.Language.Lang, true, 2)}
                                    </span>
                                    <p></p>
                                    <span>
                                      <b>{this.Language.DataField.ForeignContent}: </b> {local_content_item.local_content_base_currency}{' '}
                                      {this.Format.FormatLocaleNumber(local_content_item.foreign_content, this.Language.Lang, true, 2)}
                                    </span>
                                    <p></p>
                                    <span>
                                      <b>{this.Language.DataField.Percentage}: </b>{' '}
                                      {!isNaN(parseFloat(local_content_item.local_content) && !isNaN(parseFloat(local_content_item.foreign_content)))
                                        ? this.Format.FormatLocaleNumber(
                                            (parseFloat(local_content_item.local_content) / (parseFloat(local_content_item.foreign_content) + parseFloat(local_content_item.local_content))) * 100,
                                            this.Language.Lang,
                                            true,
                                            2
                                          ) + '%'
                                        : null}
                                    </span>
                                    <p></p>
                                  </div>
                                  <div className="col s3">
                                    <span>
                                      <b>{this.Language.DataField.IsApproved}: </b> {local_content_item.local_content_approved_value}
                                    </span>
                                    <p></p>
                                    <span>
                                      <b>{this.Language.DataField.Status}: </b> {local_content_item.local_content_base_status}
                                    </span>
                                    <p></p>
                                    <Link
                                      to={`#!`}
                                      onClick={() => {
                                        this.removeLocalContentItem(i, local_content_item.export_value, local_content_item.local_content, local_content_item.foreign_content);
                                      }}>
                                      <span className="new badge grey left" data-badge-caption={this.Language.ButtonLabel.Remove}></span>
                                    </Link>
                                  </div>
                                </div>,
                                <div className="divider" key={i + 1}></div>,
                                <br key={i + 2} />
                              ];
                            })}

                            <div className="row">
                              <div className="col s12">
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('local_content_base');
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_local_content_base}></span>
                                </Link>
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.LocalContentBase}>help_outline</i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">timeline</i>
                            {this.Language.DataField.LocalValueAdded}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id="select-options-local_value_added_base_currency"
                                    className="initialized"
                                    name="local_value_added_base_currency"
                                    id="local_value_added_base_currency"
                                    ref="local_value_added_base_currency">
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s6">
                                <input id="turnover" name="turnover" type="number" step="any" className="validate" ref="turnover" />
                                <label htmlFor="turnover" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.Turnover} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s6">
                                <input id="other_incomes" name="other_incomes" type="number" step="any" className="validate" ref="other_incomes" />
                                <label htmlFor="other_incomes" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.OtherIncomes} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <input id="bought_in_goods_and_materials" name="bought_in_goods_and_materials" type="number" step="any" className="validate" ref="bought_in_goods_and_materials" />
                                <label htmlFor="bought_in_goods_and_materials" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.BoughtInGoodsAndMaterials} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <input id="local_value_added" name="local_value_added" type="number" step="any" className="validate" ref="local_value_added" />
                                <label htmlFor="local_value_added" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.LocalValueAdded} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="local_value_added_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="local_value_added_base_is_approved" ref="local_value_added_base_is_approved" />
                                  <label htmlFor="local_value_added_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_local_value_added_base" ref="activate_switch_local_value_added_base" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('local_value_added_base');
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_local_value_added_base}></span>
                                </Link>
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.LocalValueAddedBase}>help_outline</i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">check_circle</i>
                            {this.Language.DataField.PrioritySector}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="priority_sector col s4">
                                <p>
                                  <input type="checkbox" id="priority_sector" ref="priority_sector" />
                                  <label htmlFor="priority_sector">{this.Language.DataField.PrioritySector}</label>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="priority_sector_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="priority_sector_base_is_approved" ref="priority_sector_base_is_approved" />
                                  <label htmlFor="priority_sector_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_priority_sector_base" ref="activate_switch_priority_sector_base" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('priority_sector_base');
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_priority_sector_base}></span>
                                </Link>
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.PrioritySector}>help_outline</i>
                          </div>
                        </li>

                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">import_contacts</i>
                            {this.Language.DataField.Notes}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s12">
                                <textarea id="notes" className="materialize-textarea" data-length={this.state.text_limit} ref="notes"></textarea>
                                <label htmlFor="notes" data-error={this.Language.DataFieldError.MaxLength}>
                                  {this.Language.DataField.Notes}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s12">
                                <textarea id="reviewer_notes" className="materialize-textarea" data-length={this.state.text_limit} ref="reviewer_notes"></textarea>
                                <label htmlFor="reviewer_notes" data-error={this.Language.DataFieldError.MaxLength}>
                                  {this.Language.DataField.ReviewerNotes}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_baseline_notes" ref="activate_switch_baseline_notes" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('baseline_notes');
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_baseline_notes}></span>
                                </Link>
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.Notes}>help_outline</i>
                          </div>
                        </li>
                      </ul>

                      {/* ::after */}
                    </div>

                    <Link
                      className={`waves-effect waves-light btn grey lighten-3 cyan-text left ${this.state.submitbuttondisabled ? 'disabled' : ''}`}
                      to={`#!`}
                      onClick={() => {
                        if (window.confirm(`${this.Language.ConfirmMessage.SubmitBaseline} ${this.refs.business_year.value === 'Select Business Year' ? '' : this.refs.business_year.value}`)) {
                          this.submitBaselineData('submit_baseline_data_final');
                        }
                      }}>
                      {this.state.submit_baseline_data_button_caption || this.Language.ButtonLabel.SubmitBaseline}
                    </Link>
                    <br />
                    <br />
                  </div>
                </div>
                {/* ::after */}
              </div>
              <br />
              <br />
              <br />
              <h4 className="header2">{this.Language.PageLabel.UploadBaselineDocuments}</h4>
              {this.state.fileUploadItem_1}
              {/* <FileUpload fieldName={this.Language.PageLabel.BaselineForms} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-001'} maxFileSize={'10M'} defaultFile={''} fileName={'Baseline_Forms'} pageDataCategory={"Baseline_Forms"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_2}
              {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementCurrent} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-002'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_current'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_3}
              {/* <FileUpload fieldName={this.Language.PageLabel.ManagementAccountCurrent} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-003'} maxFileSize={'10M'} defaultFile={''} fileName={'Management_Account_Current'} pageDataCategory={"Management_Account"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_4}
              {/* <FileUpload fieldName={this.Language.PageLabel.ProjectedFinancialStatement} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-004'} maxFileSize={'10M'} defaultFile={''} fileName={'Projected_Statement'} pageDataCategory={"Projected_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_5}
              {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear1} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-005'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_Pre_Year_1'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_6}
              {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear2} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-006'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_Pre_Year_2'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_7}
              {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear3} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-007'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_Pre_Year_3'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar  language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default AddBaselineData;
