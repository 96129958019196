import React, {Component} from 'react';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import { Link, NavLink } from 'react-router-dom';
import URLService from '../modules/URLService';
// import PerfectScrollbar from 'perfect-scrollbar';

// const $ = require('jquery');




class LeftSideBar extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.handleSignout = this.handleSignout.bind(this);
        this.state = {
            ps: null,
            scopes: {},
            hash_user: '',
            username: '',
            name: '',
            email: '',
            organisation: '', 
            partner_id: '',
            user_role: '',
            message: '',
            // menu Access States
            dashboard: null,
            notification: null,
            notifications: null,
            announcement: null,
            announcements: null,
            user: null,
            users: null,
            exporter: null,
            exporters: null,
            annual_reg: null,
            exported_item: null,
            exported_items: null,
            partner: null,
            partners: null,
            partner_reg: null,
            category: null,
            categories: null,
            baseline: null,
            baselines: null,
            baseline_years: null,
            claim: null,
            claims: null,
            claim_status: null,
            claims_status: null,
            ecc: null,
            eccs: null,
            ecc_utilisation: null,
            ecc_verification: null,
            exchange_rates: null,
            productivity: null,
            device: null,
            devices: null,
            modules: null,
            roles: null,
            access_right: null,
            access_right_object: null,
            pick_lists: null,
            revoke_token: null,
            password_config: null,
            login_config: null,
            change_password: null,
            data_field_security: null,
            audit_activities: null,
            document: null,
            documents: null,
            download: null,
            downloads: null,
            upload: null,
            uploads: null,
            search: null,
            tables: null,
            template: null,
            data: null,
            table_records: null,
            orphaned_records: null,
            delete_mgt: null,
            view_encrypted_data: null,
            active_menu_level_1: props.active_menu_level_1 || '',
            active_menu_level_2: props.active_menu_level_2 || '',
            language: props.language,
            default_dashboard: '/home'

        };

        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount(){
        this.getProfile();

    }


    componentDidMount(){
        this.getAccessScopes();

        // const elem = document.querySelector('.sidenav');
        // const instance = window.Materialize.Sidenav.init(elem, options);

        // const ps = new PerfectScrollbar('#ps-container');

        // const container = document.querySelector('#left-sidebar-nav');
        // // console.log(container);
        // const ps = new PerfectScrollbar(container);

        // ps.update();
        // this.setState({ps: ps});

    }

    shouldComponentUpdate(){
        return true;
    }


    componentWillUnmount(){
        // this.setState({ps: this.state.ps.destroy})
        // const container = document.querySelector('#left-sidebar-nav');
        // const ps = new PerfectScrollbar(container);
        // ps.destroy();
        // ps = null;
        
        // this.setState({ps: null});
        
    }


    getProfile(){

        let user_profile = this.Auth.getProfile();


        if (user_profile){
            this.setState({scopes: user_profile.scopes,
                hash_user: user_profile.user,
                username: user_profile.username,
                name: user_profile.name,
                email: user_profile.email,
                organisation: user_profile.organisation,
                user_role: user_profile.user_role,
                partner_id: user_profile.partner
            });
        }

        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) this.setState({default_dashboard: `/dashboard/${dashboards[0].toLowerCase()}/${'5'}`});


    }

    getAccessScopes(){


        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'read:dashboard' || this.state.scopes['views'][i] === 'action:dashboard') this.setState({dashboard: true});
                if (this.state.scopes['views'][i] === 'read:notification' || this.state.scopes['views'][i] === 'action:notification') this.setState({notification: true});
                if (this.state.scopes['views'][i] === 'read:notifications' || this.state.scopes['views'][i] === 'action:notifications') this.setState({notifications: true});
                if (this.state.scopes['views'][i] === 'read:announcement' || this.state.scopes['views'][i] === 'action:announcement') this.setState({announcement: true});
                if (this.state.scopes['views'][i] === 'read:announcements' || this.state.scopes['views'][i] === 'action:announcements') this.setState({announcements: true});
                if (this.state.scopes['views'][i] === 'read:user' || this.state.scopes['views'][i] === 'action:user') this.setState({user: true});
                if (this.state.scopes['views'][i] === 'read:users' || this.state.scopes['views'][i] === 'action:users') this.setState({users: true});
                if (this.state.scopes['views'][i] === 'read:exporter' || this.state.scopes['views'][i] === 'action:exporter') this.setState({exporter: true});
                if (this.state.scopes['views'][i] === 'read:exporters' || this.state.scopes['views'][i] === 'action:exporters') this.setState({exporters: true});
                if (this.state.scopes['views'][i] === 'read:annual_reg' || this.state.scopes['views'][i] === 'action:annual_reg') this.setState({annual_reg: true});
                if (this.state.scopes['views'][i] === 'read:exported_item' || this.state.scopes['views'][i] === 'action:exported_item') this.setState({exported_item: true});
                if (this.state.scopes['views'][i] === 'read:exported_items' || this.state.scopes['views'][i] === 'action:exported_items') this.setState({exported_items: true});
                if (this.state.scopes['views'][i] === 'read:partner' || this.state.scopes['views'][i] === 'action:partner') this.setState({partner: true});
                if (this.state.scopes['views'][i] === 'read:partners' || this.state.scopes['views'][i] === 'action:partners') this.setState({partners: true});
                if (this.state.scopes['views'][i] === 'read:partner_reg' || this.state.scopes['views'][i] === 'action:partner_reg') this.setState({partner_reg: true});
                if (this.state.scopes['views'][i] === 'read:category' || this.state.scopes['views'][i] === 'action:category') this.setState({category: true});
                if (this.state.scopes['views'][i] === 'read:categories' || this.state.scopes['views'][i] === 'action:categories') this.setState({categories: true});
                if (this.state.scopes['views'][i] === 'read:baseline' || this.state.scopes['views'][i] === 'action:baseline') this.setState({baseline: true});
                if (this.state.scopes['views'][i] === 'read:baselines' || this.state.scopes['views'][i] === 'action:baselines') this.setState({baselines: true});
                if (this.state.scopes['views'][i] === 'read:baseline_years' || this.state.scopes['views'][i] === 'action:baseline_years') this.setState({baseline_years: true});
                if (this.state.scopes['views'][i] === 'read:claim' || this.state.scopes['views'][i] === 'action:claim') this.setState({claim: true});
                if (this.state.scopes['views'][i] === 'read:claims' || this.state.scopes['views'][i] === 'action:claims') this.setState({claims: true});
                if (this.state.scopes['views'][i] === 'read:claim_status' || this.state.scopes['views'][i] === 'action:claim_status') this.setState({claim_status: true});
                if (this.state.scopes['views'][i] === 'read:claims_status' || this.state.scopes['views'][i] === 'action:claims_status') this.setState({claims_status: true});
                if (this.state.scopes['views'][i] === 'read:ecc' || this.state.scopes['views'][i] === 'action:ecc') this.setState({ecc: true});
                if (this.state.scopes['views'][i] === 'read:eccs' || this.state.scopes['views'][i] === 'action:eccs') this.setState({eccs: true});
                if (this.state.scopes['views'][i] === 'read:ecc_utilisation' || this.state.scopes['views'][i] === 'action:ecc_utilisation') this.setState({ecc_utilisation: true});
                if (this.state.scopes['views'][i] === 'read:ecc_verification' || this.state.scopes['views'][i] === 'action:ecc_verification') this.setState({ecc_verification: true});
                if (this.state.scopes['views'][i] === 'read:exchange_rates' || this.state.scopes['views'][i] === 'action:exchange_rates') this.setState({exchange_rates: true});
                if (this.state.scopes['views'][i] === 'read:productivity' || this.state.scopes['views'][i] === 'action:productivity') this.setState({productivity: true});
                if (this.state.scopes['views'][i] === 'read:device' || this.state.scopes['views'][i] === 'action:devices') this.setState({device: true});
                if (this.state.scopes['views'][i] === 'read:devices' || this.state.scopes['views'][i] === 'action:devices') this.setState({devices: true});
                if (this.state.scopes['views'][i] === 'read:modules' || this.state.scopes['views'][i] === 'action:modules') this.setState({modules: true});
                if (this.state.scopes['views'][i] === 'read:roles' || this.state.scopes['views'][i] === 'action:roles') this.setState({roles: true});
                if (this.state.scopes['views'][i] === 'read:access_right' || this.state.scopes['views'][i] === 'action:access_right') this.setState({access_right: true});
                if (this.state.scopes['views'][i] === 'read:access_right_object' || this.state.scopes['views'][i] === 'action:access_right_object') this.setState({access_right_object: true});
                if (this.state.scopes['views'][i] === 'read:pick_lists' || this.state.scopes['views'][i] === 'action:pick_lists') this.setState({pick_lists: true});
                if (this.state.scopes['views'][i] === 'read:revoke_token' || this.state.scopes['views'][i] === 'action:revoke_token') this.setState({revoke_token: true});
                if (this.state.scopes['views'][i] === 'read:password_config' || this.state.scopes['views'][i] === 'action:password_config') this.setState({password_config: true});
                if (this.state.scopes['views'][i] === 'read:login_config' || this.state.scopes['views'][i] === 'action:login_config') this.setState({login_config: true});
                if (this.state.scopes['views'][i] === 'create:change_password' ||  this.state.scopes['views'][i] === 'update:change_password' || this.state.scopes['views'][i] === 'action:change_password') this.setState({change_password: true});
                if (this.state.scopes['views'][i] === 'read:data_field_security' || this.state.scopes['views'][i] === 'action:data_field_security') this.setState({data_field_security: true});
                if (this.state.scopes['views'][i] === 'read:audit_activities' || this.state.scopes['views'][i] === 'action:audit_activities') this.setState({audit_activities: true});
                if (this.state.scopes['views'][i] === 'read:document' || this.state.scopes['views'][i] === 'action:document') this.setState({document: true});
                if (this.state.scopes['views'][i] === 'read:documents' || this.state.scopes['views'][i] === 'action:documents') this.setState({documents: true});
                if (this.state.scopes['views'][i] === 'read:download' || this.state.scopes['views'][i] === 'action:download') this.setState({download: true});
                if (this.state.scopes['views'][i] === 'read:downloads' || this.state.scopes['views'][i] === 'action:downloads') this.setState({downloads: true});
                if (this.state.scopes['views'][i] === 'read:upload' || this.state.scopes['views'][i] === 'action:upload') this.setState({upload: true});
                if (this.state.scopes['views'][i] === 'read:uploads' || this.state.scopes['views'][i] === 'action:uploads') this.setState({uploads: true});
                if (this.state.scopes['views'][i] === 'read:search' || this.state.scopes['views'][i] === 'action:search') this.setState({search: true});
                if (this.state.scopes['views'][i] === 'read:tables' || this.state.scopes['views'][i] === 'action:tables') this.setState({ tables: true });
                if (this.state.scopes['views'][i] === 'read:template' || this.state.scopes['views'][i] === 'action:template') this.setState({ template: true });
                if (this.state.scopes['views'][i] === 'read:data' || this.state.scopes['views'][i] === 'action:data') this.setState({ data: true });
                if (this.state.scopes['views'][i] === 'read:table_records' || this.state.scopes['views'][i] === 'action:table_records') this.setState({ table_records: true });
                if (this.state.scopes['views'][i] === 'read:orphaned_records' || this.state.scopes['views'][i] === 'action:orphaned_records') this.setState({ orphaned_records: true });
                if (this.state.scopes['views'][i] === 'read:delete_mgt' || this.state.scopes['views'][i] === 'action:delete_mgt') this.setState({ delete_mgt: true });
            }
        };


        // Check the buttons scope
        if (this.state.scopes['buttons']) {
            let len = this.state.scopes['buttons'].length;

            for (let i = 0; i < len; i++) {


            }
            
        };

        // Check the processes scope
        if (this.state.scopes['processes']) {
            let len = this.state.scopes['processes'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['processes'][i] === 'read:view_encrypted_data') this.setState({view_encrypted_data: true});
            }

        };

    }



    handleSignout(){

        const userOS = this.Browser.getOS();

        const newSignout = {
          username: this.state.username,
          hash_id: this.state.hash_user,
          device_name: this.state.username + "'s " + userOS,
          device_model: userOS,
          device_os: userOS,
          browser: this.Browser.getBrowser() || this.state.language.DefaultBrowser,
          location_used: this.Browser.getLocation() || this.state.language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
          app_source: 'Web',
          token: this.Auth.getToken()
        };


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/authenticate/signout/${this.state.hash_user}?tk=${this.Auth.getToken()}`,
            data: newSignout
        }).then(response => {
                console.log(response.data.status);

                window.Materialize.toast(response.data.message, 8000, 'rounded green');            

            })
            .catch(function (error) {
                if (error.response) {
        
                  console.log(error.response.data.status);
                  console.log(error.response.data.message);
                // window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4'); // commented so that the signout failed messages do not show

                } else if (error.request) {
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
                //console.log(error.config);
              });


        this.Auth.removeLanguage();
        this.Auth.removeTimeZone();
        this.Auth.removeDashboards();
        this.Auth.logout();
        this.props.history.replace('/signin');
     }





    render(){


        const ulStyle = {
            transform: 'translateX(0%)'
          };

        const divStyle1 = {
            left: 0,
            bottom: 3
          };

        const divStyle2 = {
            top: 0,
            height: 550,
            right: 3
          };

        const divStyle3 = {
            display: 'block'
          };

        const divStyle4 = {
            left: 0,
            width: 0
        };

        const divStyle5 = {
            top: 0,
            height: 257
        };


        const lengthCheck = 25;
        const delay = 50;

        const dashboards = this.Auth.getDashboards() || [];


        return (
            // <!-- START LEFT SIDEBAR NAV-->
                <aside id="left-sidebar-nav" className="nav-expanded nav-lock nav-collapsible">
                    <div className="brand-sidebar green darken-3">
                        <h1 className="logo-wrapper">
                            <a href={this.state.default_dashboard} className="brand-logo darken-1">
                                <img src="/assets/images/logo/eeg_logo_small_100_61.png" alt="EEG Web Portal"/>
                                <span className="logo-text hide-on-med-and-down">{this.state.language.EEGPortal}</span>
                            </a>
                            <a href="#!" className="navbar-toggler">
                                <i className="material-icons">radio_button_checked</i>
                            </a>
                        </h1>
                    </div>
                    <ul id="slide-out" className="side-nav fixed leftside-navigation ps-container ps-active-y" ref="sidebar" style={ulStyle}>
                        <li className="no-padding">
                            <ul className="collapsible" data-collapsible="accordion">
    {this.state.dashboard &&    <li className={`bold ${(this.state.active_menu_level_1 === 'Dashboard') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Dashboard') ? 'active' : '' }`}>
                                        <i className="material-icons">dashboard</i>
                                        <span className={this.state.language.LeftSideBar.Dashboard.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Dashboard.length > lengthCheck ? this.state.language.LeftSideBar.Dashboard: ""}>{this.state.language.LeftSideBar.Dashboard}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Dashboard') ? divStyle3 : null }>
                                        <ul>
{dashboards.map((dashboard,  i) => {
                                    return(
                                        <li className={`${(this.state.active_menu_level_2 === dashboard + ' Dashboard') ? 'active green' : '' }`} key={i}>
                                            <NavLink to={`/dashboard/${dashboard.toLowerCase()}/${"5"}`}>
                                                <i className="material-icons">keyboard_arrow_right</i>
                                                <span className={dashboard.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={dashboard.length > lengthCheck ? dashboard: ""}>{dashboard}</span>
                                            </NavLink>
                                        </li>
                                    )
                                    })}

{/* initial dashboard menu items commented out to use the dashboard details gotten from the token and session storage

{(this.state.exporters)  &&                 <li className={`${(this.state.active_menu_level_2 === 'Exporters') ? 'active green' : '' }`}>
                                                <NavLink to={`/dashboard/exporters/${"5"}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Exporters.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Exporters.length > lengthCheck ? this.state.language.LeftSideBar.Exporters: ""}>{this.state.language.LeftSideBar.Exporters}</span>
                                                </NavLink>
                                            </li>}
{(this.state.exporter && this.state.organisation)  &&  <li className={`${(this.state.active_menu_level_2 === 'Exporter') ? 'active green' : '' }`}>
                                                <NavLink to={`/dashboard/exporter/${"5"}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Exporter.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Exporter.length > lengthCheck ? this.state.language.LeftSideBar.Exporter: ""}>{this.state.language.LeftSideBar.Exporter}</span>
                                                </NavLink>
                                            </li>}
{(this.state.partners )  &&                     <li className={`${(this.state.active_menu_level_2 === 'Partner Agencies') ? 'active green' : '' }`}>
                                                <NavLink to={`/dashboard/partners/${"5"}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.PartnerAgencies.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PartnerAgencies.length > lengthCheck ? this.state.language.LeftSideBar.PartnerAgencies: ""}>{this.state.language.LeftSideBar.PartnerAgencies}</span>
                                                </NavLink>
                                            </li>}
{(this.state.partner && this.state.partner_id)  &&  <li className={`${(this.state.active_menu_level_2 === 'Partner') ? 'active green' : '' }`}>
                                                <NavLink to={`/dashboard/partner/${"5"}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Partner.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Partner.length > lengthCheck ? this.state.language.LeftSideBar.Partner: ""}>{this.state.language.LeftSideBar.Partner}</span>
                                                </NavLink>
                                            </li>} 
                                        
                                        */} 
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.notification)  && <li className={`bold ${(this.state.active_menu_level_1 === 'Notifications') ? 'active' : '' }`}>
                                        <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Notifications') ? 'active' : '' }`}>
                                            <i className="material-icons">mail_outline</i>
                                            <span className={this.state.language.LeftSideBar.Notifications.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Notifications.length > lengthCheck ? this.state.language.LeftSideBar.Notifications: ""}>{this.state.language.LeftSideBar.Notifications}</span>
                                            {/* ::after */}
                                        </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Notifications') ? divStyle3 : null }>
                                        <ul>
    {(this.state.notification)  &&          <li className={`${(this.state.active_menu_level_2 === 'Notifications') ? 'active green' : '' }`}>
                                                <Link to={`/notifications/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Notifications.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Notifications.length > lengthCheck ? this.state.language.LeftSideBar.Notifications: ""}>{this.state.language.LeftSideBar.Notifications}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.announcement)  &&          <li className={`${(this.state.active_menu_level_2 === 'Announcements') ? 'active green' : '' }`}>
                                                <Link to={`/announcements`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Announcements.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Announcements.length > lengthCheck ? this.state.language.LeftSideBar.Announcements: ""}>{this.state.language.LeftSideBar.Announcements}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.notification)  &&          <li className={`${(this.state.active_menu_level_2 === 'Messages') ? 'active green' : '' }`}>
                                                <Link to={`/notifications/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Messages.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Messages.length > lengthCheck ? this.state.language.LeftSideBar.Messages: ""}>{this.state.language.LeftSideBar.Messages}</span>
                                                </Link>
                                            </li>
}
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.user)  &&      <li className={`bold ${(this.state.active_menu_level_1 === 'User Profile') ? 'active' : '' }`}>
                                    <Link to={`/user/profile/${this.state.hash_user}`} className="waves-effect waves-cyan">
                                        <i className="material-icons">account_circle</i>
                                        <span className={this.state.name.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.name.length > lengthCheck ? this.state.name: ""}>{this.state.name}</span>
                                    </Link>
                                </li>
    }
    {(this.state.exporter && this.state.organisation)  &&  <li className={`bold ${(this.state.active_menu_level_1 === 'Organisation') ? 'active' : '' }`}>
                                    <Link to={`/exporter/profile/${this.state.organisation}`} className="waves-effect waves-cyan">
                                        <i className="material-icons">location_city</i>
                                        <span className={this.state.language.LeftSideBar.Organisation.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Organisation.length > lengthCheck ? this.state.language.LeftSideBar.Organisation: ""}>{this.state.language.LeftSideBar.Organisation}</span>
                                    </Link>
                                </li>
    }
    {(this.state.partner && this.state.partner_id)  &&  <li className={`bold ${(this.state.active_menu_level_1 === 'Partner Agency') ? 'active' : '' }`}>
                                    <Link to={ `/partner/profile/${this.state.partner_id}`} className="waves-effect waves-cyan">
                                        <i className="material-icons">location_city</i>
                                        <span className={this.state.language.LeftSideBar.PartnerAgency.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PartnerAgency.length > lengthCheck ? this.state.language.LeftSideBar.PartnerAgency: ""}>{this.state.language.LeftSideBar.PartnerAgency}</span>
                                    </Link>
                                </li>
    }
    {(this.state.exporters)  && <li className={`bold ${(this.state.active_menu_level_1 === 'Exporter Management') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Exporter Management') ? 'active' : '' }`}>
                                        <i className="material-icons">account_balance</i>
                                        <span className={this.state.language.LeftSideBar.ExporterManagement.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ExporterManagement.length > lengthCheck ? this.state.language.LeftSideBar.ExporterManagement: ""}>{this.state.language.LeftSideBar.ExporterManagement}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Exporter Management') ? divStyle3 : null }>
                                        <ul>
    {(this.state.exporters)  &&             <li className={`${(this.state.active_menu_level_2 === 'Exporters') ? 'active green' : '' }`}>
                                                <Link to={`/exporters`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Exporters.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Exporters.length > lengthCheck ? this.state.language.LeftSideBar.Exporters: ""}>{this.state.language.LeftSideBar.Exporters}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.annual_reg)  &&            <li className={`${(this.state.active_menu_level_2 === 'Annual Registration') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/annual/registration`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.AnnualRegistration.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.AnnualRegistration.length > lengthCheck ? this.state.language.LeftSideBar.AnnualRegistration: ""}>{this.state.language.LeftSideBar.AnnualRegistration}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.exported_items)  &&        <li className={`${(this.state.active_menu_level_2 === 'Exported Items') ? 'active green' : '' }`}>
                                                <Link to={"/exporters/exported/items"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ExportedItems.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ExportedItems.length > lengthCheck ? this.state.language.LeftSideBar.ExportedItems: ""}>{this.state.language.LeftSideBar.ExportedItems}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.partners)  && <li className={`bold ${(this.state.active_menu_level_1 === 'Partner Management') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Partner Management') ? 'active' : '' }`}>
                                        <i className="material-icons">business</i>
                                        <span className={this.state.language.LeftSideBar.PartnerManagement.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PartnerManagement.length > lengthCheck ? this.state.language.LeftSideBar.PartnerManagement: ""}>{this.state.language.LeftSideBar.PartnerManagement}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Partner Management') ? divStyle3 : null }>
                                        <ul>
    {(this.state.partners)  &&              <li className={`${(this.state.active_menu_level_2 === 'Partner Agencies') ? 'active green' : '' }`}>
                                                <a href="/partners">
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.PartnerAgencies.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PartnerAgencies.length > lengthCheck ? this.state.language.LeftSideBar.PartnerAgencies: ""}>{this.state.language.LeftSideBar.PartnerAgencies}</span>
                                                </a>
                                            </li>
    }
    {(this.state.partner_reg)  &&           <li className={`${(this.state.active_menu_level_2 === 'Partner Registration') ? 'active green' : '' }`}>
                                                <a href="/partners/add">
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.PartnerRegistration.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PartnerRegistration.length > lengthCheck ? this.state.language.LeftSideBar.PartnerRegistration: ""}>{this.state.language.LeftSideBar.PartnerRegistration}</span>
                                                </a>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.categories) && <li className={`bold ${(this.state.active_menu_level_1 === 'Categorisation') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Categorisation') ? 'active' : '' }`}>
                                        <i className="material-icons">line_style</i>
                                        <span className={this.state.language.LeftSideBar.Categorisation.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Categorisation.length > lengthCheck ? this.state.language.LeftSideBar.Categorisation: ""}>{this.state.language.LeftSideBar.Categorisation}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Categorisation') ? divStyle3 : null }>
                                        <ul>
    {(this.state.categories)  &&            <li className={`${(this.state.active_menu_level_2 === 'Exporter Categorisation') ? 'active green' : '' }`}>
                                                <Link to={"/categorisation/exporter"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ExporterCategorisation.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ExporterCategorisation.length > lengthCheck ? this.state.language.LeftSideBar.ExporterCategorisation: ""}>{this.state.language.LeftSideBar.ExporterCategorisation}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.categories)  &&            <li className={`${(this.state.active_menu_level_2 === 'Product Categorisation') ? 'active green' : '' }`}>
                                                <Link to={"/categorisation/product"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ProductCategorisation.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ProductCategorisation.length > lengthCheck ? this.state.language.LeftSideBar.ProductCategorisation: ""}>{this.state.language.LeftSideBar.ProductCategorisation}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.baselines || this.state.baseline) && <li className={`bold ${(this.state.active_menu_level_1 === 'Baseline Data') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Baseline Data') ? 'active' : '' }`}>
                                        <i className="material-icons">business_center</i>
                                        <span className={this.state.language.LeftSideBar.BaselineData.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.BaselineData.length > lengthCheck ? this.state.language.LeftSideBar.BaselineData: ""}>{this.state.language.LeftSideBar.BaselineData}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Baseline Data') ? divStyle3 : null }>
                                        <ul>
    {(this.state.exporter && this.state.organisation && this.state.baseline)  &&            <li className={`${(this.state.active_menu_level_2 === 'Baseline Profile') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/${this.state.organisation}/baseline/profile`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.BaselineProfile.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.BaselineProfile.length > lengthCheck ? this.state.language.LeftSideBar.BaselineProfile: ""}>{this.state.language.LeftSideBar.BaselineProfile}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.baselines)  &&            <li className={`${(this.state.active_menu_level_2 === 'Baseline Summary') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/baseline/data`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.BaselineSummary.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.BaselineSummary.length > lengthCheck ? this.state.language.LeftSideBar.BaselineSummary: ""}>{this.state.language.LeftSideBar.BaselineSummary}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.baselines)  &&            <li className={`${(this.state.active_menu_level_2 === 'Baseline Data Capture') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/baseline/data/add`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.BaselineDataCapture.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.BaselineDataCapture.length > lengthCheck ? this.state.language.LeftSideBar.BaselineDataCapture: ""}>{this.state.language.LeftSideBar.BaselineDataCapture}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.baselines)  &&            <li className={`${(this.state.active_menu_level_2 === 'EEG Rates') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/baseline/rates`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.EEGRates.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.EEGRates.length > lengthCheck ? this.state.language.LeftSideBar.EEGRates: ""}>{this.state.language.LeftSideBar.EEGRates}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.exporter && this.state.organisation && this.state.baseline)  &&            <li className={`${(this.state.active_menu_level_2 === 'EEG Rates Profile') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/${this.state.organisation}/baseline/profile/rates`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.EEGRatesProfile.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.EEGRatesProfile.length > lengthCheck ? this.state.language.LeftSideBar.EEGRatesProfile: ""}>{this.state.language.LeftSideBar.EEGRatesProfile}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.baselines)  &&             <li className={`${(this.state.active_menu_level_2 === 'Baseline Threshold & Weight') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/baseline/threshold/weight`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.BaselineThresholdAndWeight.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.BaselineThresholdAndWeight.length > lengthCheck ? this.state.language.LeftSideBar.BaselineThresholdAndWeight: ""}>{this.state.language.LeftSideBar.BaselineThresholdAndWeight}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.partner_id && this.state.baseline_years)  &&        <li className={`${(this.state.active_menu_level_2 === 'Baseline Years') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/baseline/years`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.BaselineYears.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.BaselineYears.length > lengthCheck ? this.state.language.LeftSideBar.BaselineYears: ""}>{this.state.language.LeftSideBar.BaselineYears}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.claims || this.state.claim || this.state.claims_status || this.state.claim_status) && <li className={`bold ${(this.state.active_menu_level_1 === 'EEG Claims') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'EEG Claims') ? 'active' : '' }`}>
                                        <i className="material-icons">language</i>
                                        <span className={this.state.language.LeftSideBar.EEGClaims.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.EEGClaims.length > lengthCheck ? this.state.language.LeftSideBar.EEGClaims: ""}>{this.state.language.LeftSideBar.EEGClaims}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'EEG Claims') ? divStyle3 : null }>
                                        <ul>
    {(this.state.exporter && this.state.organisation && this.state.claim)  &&            <li className={`${(this.state.active_menu_level_2 === 'Claim Applications') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/${this.state.organisation}/eeg/claim/applications`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ClaimApplications.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ClaimApplications.length > lengthCheck ? this.state.language.LeftSideBar.ClaimApplications: ""}>{this.state.language.LeftSideBar.ClaimApplications}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.claim)  &&            <li className={`${(this.state.active_menu_level_2 === 'Claim Application Capture') ? 'active green' : '' }`}>
                                            <Link to={`/exporter/${this.state.organisation}/eeg/claim/applications/add`}>
                                                <i className="material-icons">keyboard_arrow_right</i>
                                                <span className={this.state.language.LeftSideBar.ClaimApplicationCapture.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ClaimApplicationCapture.length > lengthCheck ? this.state.language.LeftSideBar.ClaimApplicationCapture: ""}>{this.state.language.LeftSideBar.ClaimApplicationCapture}</span>
                                            </Link>
                                        </li>
    }
    {(this.state.claims)  &&            <li className={`${(this.state.active_menu_level_2 === 'Claims Summary') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/eeg/claims`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ClaimsSummary.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ClaimsSummary.length > lengthCheck ? this.state.language.LeftSideBar.ClaimsSummary: ""}>{this.state.language.LeftSideBar.ClaimsSummary}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.claims)  &&            <li className={`${(this.state.active_menu_level_2 === 'Claim Data Capture') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/eeg/claims/add`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ClaimDataCapture.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ClaimDataCapture.length > lengthCheck ? this.state.language.LeftSideBar.ClaimDataCapture: ""}>{this.state.language.LeftSideBar.ClaimDataCapture}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.claims_status)  &&            <li className={`${(this.state.active_menu_level_2 === 'Claims Status Summary') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/eeg/claims/status/`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ClaimsStatusSummary.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ClaimsStatusSummary.length > lengthCheck ? this.state.language.LeftSideBar.ClaimsStatusSummary: ""}>{this.state.language.LeftSideBar.ClaimsStatusSummary}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.claim_status)  &&             <li className={`${(this.state.active_menu_level_2 === 'Claims Status') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/${this.state.organisation}/eeg/claim/status`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ClaimsStatus.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ClaimsStatus.length > lengthCheck ? this.state.language.LeftSideBar.ClaimsStatus: ""}>{this.state.language.LeftSideBar.ClaimsStatus}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.eccs || this.state.ecc || this.state.ecc_utilisation || this.state.ecc_verification) && <li className={`bold ${(this.state.active_menu_level_1 === 'ECCs') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'ECCs') ? 'active' : '' }`}>
                                        <i className="material-icons">credit_card</i>
                                        <span className={this.state.language.LeftSideBar.ECCS.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ECCS.length > lengthCheck ? this.state.language.LeftSideBar.ECCS: ""}>{this.state.language.LeftSideBar.ECCS}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'ECCs') ? divStyle3 : null }>
                                        <ul>
    {(this.state.exporter && this.state.organisation && this.state.ecc)  &&            <li className={`${(this.state.active_menu_level_2 === 'ECC Profile') ? 'active green' : '' }`}>
                                                <Link to={`/exporter/${this.state.organisation}/ecc/profile`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ECCProfile.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ECCProfile.length > lengthCheck ? this.state.language.LeftSideBar.ECCProfile: ""}>{this.state.language.LeftSideBar.ECCProfile}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.eccs)  &&            <li className={`${(this.state.active_menu_level_2 === 'ECC Summary') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/ecc`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ECCSummary.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ECCSummary.length > lengthCheck ? this.state.language.LeftSideBar.ECCSummary: ""}>{this.state.language.LeftSideBar.ECCSummary}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.ecc_utilisation)  &&            <li className={`${(this.state.active_menu_level_2 === 'ECC Utilisation') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/ecc/utilisation`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ECCUtilisation.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ECCUtilisation.length > lengthCheck ? this.state.language.LeftSideBar.ECCUtilisation: ""}>{this.state.language.LeftSideBar.ECCUtilisation}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.ecc_verification)  &&            <li className={`${(this.state.active_menu_level_2 === 'ECC Verification') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/ecc/verification`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ECCVerification.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ECCVerification.length > lengthCheck ? this.state.language.LeftSideBar.ECCVerification: ""}>{this.state.language.LeftSideBar.ECCVerification}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.document || this.state.documents) && <li className={`bold ${(this.state.active_menu_level_1 === 'Documents') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Documents') ? 'active' : '' }`}>
                                        <i className="material-icons">import_contacts</i>
                                        <span className={this.state.language.LeftSideBar.Documents.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Documents.length > lengthCheck ? this.state.language.LeftSideBar.Documents: ""}>{this.state.language.LeftSideBar.Documents}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Documents') ? divStyle3 : null }>
                                        <ul>
    {(this.state.exporter && this.state.organisation && this.state.document)  &&   <li className={`${(this.state.active_menu_level_2 === 'Documents') ? 'active green' : '' }`} key>
                                                <Link to={`/exporter/${this.state.organisation}/documents`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Documents.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Documents.length > lengthCheck ? this.state.language.LeftSideBar.Documents: ""}>{this.state.language.LeftSideBar.Documents}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.documents)  &&            <li className={`${(this.state.active_menu_level_2 === 'Exporters Documents') ? 'active green' : '' }`}>
                                                <Link to={`/exporters/documents`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ExportersDocuments.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ExportersDocuments.length > lengthCheck ? this.state.language.LeftSideBar.ExportersDocuments: ""}>{this.state.language.LeftSideBar.ExportersDocuments}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.exchange_rates || this.state.search) && <li className={`bold ${(this.state.active_menu_level_1 === 'Tools') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Tools') ? 'active' : '' }`}>
                                        <i className="material-icons">build</i>
                                        <span className={this.state.language.LeftSideBar.Tools.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Tools.length > lengthCheck ? this.state.language.LeftSideBar.Tools: ""}>{this.state.language.LeftSideBar.Tools}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Tools') ? divStyle3 : null }>
                                        <ul>
    {(this.state.exchange_rates)  &&    <li className={`${(this.state.active_menu_level_2 === 'Exchange Rates') ? 'active green' : '' }`} key>
                                                <Link to={`/tools/exchange/rates`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ExchangeRates.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ExchangeRates.length > lengthCheck ? this.state.language.LeftSideBar.ExchangeRates: ""}>{this.state.language.LeftSideBar.ExchangeRates}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.search)  &&            <li className={`${(this.state.active_menu_level_2 === 'Search') ? 'active green' : '' }`}>
                                                <Link to={`/search`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Search.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Search.length > lengthCheck ? this.state.language.LeftSideBar.Search: ""}>{this.state.language.LeftSideBar.Search}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
                                <li className="li-hover">
                                    <p className="ultra-small margin more-text">{this.state.language.LeftSideBar.MORE}</p>
                                </li>
    {(this.state.users) &&      <li className={`bold ${(this.state.active_menu_level_1 === 'User Management') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'User Management') ? 'active' : '' }`}>
                                        <i className="material-icons">supervisor_account</i>
                                        <span className={this.state.language.LeftSideBar.UserManagement.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.UserManagement.length > lengthCheck ? this.state.language.LeftSideBar.UserManagement: ""}>{this.state.language.LeftSideBar.UserManagement}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'User Management') ? divStyle3 : null }>
                                        <ul>
    {(this.state.users)  &&                 <li className={`${(this.state.active_menu_level_2 === 'User Accounts') ? 'active green' : '' }`}>
                                                <Link to={`/users`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.UserAccounts.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.UserAccounts.length > lengthCheck ? this.state.language.LeftSideBar.UserAccounts: ""}>{this.state.language.LeftSideBar.UserAccounts}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.devices)  &&               <li className={`${(this.state.active_menu_level_2 === 'User Account Devices') ? 'active green' : '' }`}>
                                                <Link to={`/user/devices`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.UserAccountDevices.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.UserAccountDevices.length > lengthCheck ? this.state.language.LeftSideBar.UserAccountDevices: ""}>{this.state.language.LeftSideBar.UserAccountDevices}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.users)  &&                 <li className={`${(this.state.active_menu_level_2 === 'Online Users') ? 'active green' : '' }`}>
                                                <Link to={"/users/online"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.OnlineUsers.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.OnlineUsers.length > lengthCheck ? this.state.language.LeftSideBar.OnlineUsers: ""}>{this.state.language.LeftSideBar.OnlineUsers}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.users)  &&                 <li className={`${(this.state.active_menu_level_2 === 'User Signins') ? 'active green' : '' }`}>
                                                <Link to={"/user/signins"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.UserSignins.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.UserSignins.length > lengthCheck ? this.state.language.LeftSideBar.UserSignins: ""}>{this.state.language.LeftSideBar.UserSignins}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.users)  &&     <li className={`bold ${(this.state.active_menu_level_1 === 'Access Management') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Access Management') ? 'active' : '' }`}>
                                        <i className="material-icons">recent_actors</i>
                                        <span className={this.state.language.LeftSideBar.AccessManagement.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.AccessManagement.length > lengthCheck ? this.state.language.LeftSideBar.AccessManagement: ""}>{this.state.language.LeftSideBar.AccessManagement}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Access Management') ? divStyle3 : null }>
                                        <ul>
    {(this.state.modules)  &&               <li className={`${(this.state.active_menu_level_2 === 'System Modules') ? 'active green' : '' }`}>
                                                <Link to={`/settings/modules`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.SystemModules.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.SystemModules.length > lengthCheck ? this.state.language.LeftSideBar.SystemModules: ""}>{this.state.language.LeftSideBar.SystemModules}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.roles)  &&                 <li className={`${(this.state.active_menu_level_2 === 'User Roles') ? 'active green' : '' }`}>
                                                <Link to={`/settings/user/roles`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.UserRoles.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.UserRoles.length > lengthCheck ? this.state.language.LeftSideBar.UserRoles: ""}>{this.state.language.LeftSideBar.UserRoles}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.access_right)  &&          <li className={`${(this.state.active_menu_level_2 === 'Access Rights') ? 'active green' : '' }`}>
                                                <Link to={"/settings/access/rights"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.AccessRights.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.AccessRights.length > lengthCheck ? this.state.language.LeftSideBar.AccessRights: ""}>{this.state.language.LeftSideBar.AccessRights}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.access_right_object)  &&   <li className={`${(this.state.active_menu_level_2 === 'Access Right Objects') ? 'active green' : '' }`}>
                                                <Link to={"/settings/access/right/objects"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.AccessRightObjects.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.AccessRightObjects.length > lengthCheck ? this.state.language.LeftSideBar.AccessRightObjects: ""}>{this.state.language.LeftSideBar.AccessRightObjects}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.users) &&      <li className={`bold ${(this.state.active_menu_level_1 === 'Security') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Security') ? 'active' : '' }`}>
                                        <i className="material-icons">security</i>
                                        <span className={this.state.language.LeftSideBar.Security.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Security.length > lengthCheck ? this.state.language.LeftSideBar.Security: ""}>{this.state.language.LeftSideBar.Security}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Security') ? divStyle3 : null }>
                                        <ul>
    {(this.state.login_config)  &&          <li className={`${(this.state.active_menu_level_2 === 'Login Configuration') ? 'active green' : '' }`}>
                                                <Link to={"/security/login/configuration"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.LoginConfiguration.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.LoginConfiguration.length > lengthCheck ? this.state.language.LeftSideBar.LoginConfiguration: ""}>{this.state.language.LeftSideBar.LoginConfiguration}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.password_config)  &&       <li className={`${(this.state.active_menu_level_2 === 'Password Configuration') ? 'active green' : '' }`}>
                                                <Link to={"/security/password/configuration"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.PasswordConfiguration.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PasswordConfiguration.length > lengthCheck ? this.state.language.LeftSideBar.PasswordConfiguration: ""}>{this.state.language.LeftSideBar.PasswordConfiguration}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.data_field_security)  &&   <li className={`${(this.state.active_menu_level_2 === 'Data Field Security') ? 'active green' : '' }`}>
                                                <Link to={"/security/data/field/security"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.DataFieldSecurity.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.DataFieldSecurity.length > lengthCheck ? this.state.language.LeftSideBar.DataFieldSecurity: ""}>{this.state.language.LeftSideBar.DataFieldSecurity}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.audit_activities) &&       <li className={`${(this.state.active_menu_level_2 === 'Audit Activities') ? 'active green' : '' }`}>
                                                <Link to={"/security/audit/activities"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.AuditActivities.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.AuditActivities.length > lengthCheck ? this.state.language.LeftSideBar.AuditActivities: ""}>{this.state.language.LeftSideBar.AuditActivities}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.users)   &&                <li className={`${(this.state.active_menu_level_2 === 'Tenants') ? 'active green' : '' }`}>
                                                <Link to={"/security/tenants"}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Tenants.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Tenants.length > lengthCheck ? this.state.language.LeftSideBar.Tenants: ""}>{this.state.language.LeftSideBar.Tenants}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }
    {(this.state.user)   &&     <li className={`bold ${(this.state.active_menu_level_1 === 'Settings') ? 'active' : '' }`}>
                                    <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Settings') ? 'active' : '' }`}>
                                        <i className="material-icons">settings</i>
                                        <span className={this.state.language.LeftSideBar.Settings.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Settings.length > lengthCheck ? this.state.language.LeftSideBar.Settings: ""}>{this.state.language.LeftSideBar.Settings}</span>
                                        {/* ::after */}
                                    </a>
                                    <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Settings') ? divStyle3 : null }>
                                        <ul>
    {(this.state.notification)   &&         <li className={`${(this.state.active_menu_level_2 === 'Notification Settings') ? 'active green' : '' }`}>
                                                <Link to={`/settings/notification/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.NotificationSettings.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.NotificationSettings.length > lengthCheck ? this.state.language.LeftSideBar.NotificationSettings: ""}>{this.state.language.LeftSideBar.NotificationSettings}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.user)          &&         <li className={`${(this.state.active_menu_level_2 === 'Security Settings') ? 'active green' : '' }`}>
                                                <Link to={`/settings/security/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.SecuritySettings.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.SecuritySettings.length > lengthCheck ? this.state.language.LeftSideBar.SecuritySettings: ""}>{this.state.language.LeftSideBar.SecuritySettings}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.notifications)   &&        <li className={`${(this.state.active_menu_level_2 === 'User Notification Settings') ? 'active green' : '' }`}>
                                                <Link to={`/settings/notifications`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.UserNotificationSettings.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.UserNotificationSettings.length > lengthCheck ? this.state.language.LeftSideBar.UserNotificationSettings: ""}>{this.state.language.LeftSideBar.UserNotificationSettings}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.user)   &&              <li className={`${(this.state.active_menu_level_2 === 'Devices') ? 'active green' : '' }`}>
                                                <Link to={`/user/devices/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.Devices.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Devices.length > lengthCheck ? this.state.language.LeftSideBar.Devices: ""}>{this.state.language.LeftSideBar.Devices}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.user)   &&              <li className={`${(this.state.active_menu_level_2 === 'Device Settings') ? 'active green' : '' }`}>
                                                <Link to={`/settings/device/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.DeviceSettings.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.DeviceSettings.length > lengthCheck ? this.state.language.LeftSideBar.DeviceSettings: ""}>{this.state.language.LeftSideBar.DeviceSettings}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.user)   &&                 <li className={`${(this.state.active_menu_level_2 === 'Signin History') ? 'active green' : '' }`}>
                                                <Link to={`/user/profile/signins/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.SigninHistory.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.SigninHistory.length > lengthCheck ? this.state.language.LeftSideBar.SigninHistory: ""}>{this.state.language.LeftSideBar.SigninHistory}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.change_password)   &&      <li className={`${(this.state.active_menu_level_2 === 'Change Password') ? 'active green' : '' }`}>
                                                <Link to={`/password/change/user/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.ChangePassword.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.ChangePassword.length > lengthCheck ? this.state.language.LeftSideBar.ChangePassword: ""}>{this.state.language.LeftSideBar.ChangePassword}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.user)   &&                 <li className={`${(this.state.active_menu_level_2 === 'Password History') ? 'active green' : '' }`}>
                                                <Link to={`/user/profile/password/history/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.PasswordHistory.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PasswordHistory.length > lengthCheck ? this.state.language.LeftSideBar.PasswordHistory: ""}>{this.state.language.LeftSideBar.PasswordHistory}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.pick_lists)   &&           <li className={`${(this.state.active_menu_level_2 === 'Picklist Values') ? 'active green' : '' }`}>
                                                <Link to={`/settings/picklists`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.PicklistValues.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.PicklistValues.length > lengthCheck ? this.state.language.LeftSideBar.PicklistValues: ""}>{this.state.language.LeftSideBar.PicklistValues}</span>
                                                </Link>
                                            </li>
    }
    {(this.state.user)   &&                 <li className={`${(this.state.active_menu_level_2 === 'Language and Time Zone') ? 'active green' : '' }`}>
                                                <Link to={`/settings/language/timezone/${this.state.hash_user}`}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                    <span className={this.state.language.LeftSideBar.LanguageAndTimeZone.length <= lengthCheck ? "": "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.LanguageAndTimeZone.length > lengthCheck ? this.state.language.LeftSideBar.LanguageAndTimeZone: ""}>{this.state.language.LeftSideBar.LanguageAndTimeZone}</span>
                                                </Link>
                                            </li>
    }
                                        </ul>
                                    </div>
                                </li>
    }                        
    {(this.state.data) && <li className={`bold ${(this.state.active_menu_level_1 === 'Data Management') ? 'active' : ''}`}>
                                <a href={"#!"} className={`collapsible-header waves-effect waves-cyan ${(this.state.active_menu_level_1 === 'Data Management') ? 'active' : ''}`}>
                                    <i className="material-icons">computer</i>
                                    <span className={this.state.language.LeftSideBar.DataManagement.length <= lengthCheck ? "nav-text" : "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.DataManagement.length > lengthCheck ? this.state.language.LeftSideBar.DataManagement : ""}>{this.state.language.LeftSideBar.DataManagement}</span>
                                    {/* ::after */}
                                </a>
                                <div className="collapsible-body" style={(this.state.active_menu_level_1 === 'Data Management') ? divStyle3 : null}>
                                    <ul>
                                        {(this.state.tables) && <li className={`${(this.state.active_menu_level_2 === 'Data Tables') ? 'active green' : ''}`}>
                                            <Link to={`/data/management/tables`}>
                                                <i className="material-icons">keyboard_arrow_right</i>
                                                <span className={this.state.language.LeftSideBar.DataTables.length <= lengthCheck ? "" : "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.DataTables.length > lengthCheck ? this.state.language.LeftSideBar.DataTables : ""}>{this.state.language.LeftSideBar.DataTables}</span>
                                            </Link>
                                        </li>
                                        }
                                        {(this.state.template) && <li className={`${(this.state.active_menu_level_2 === 'Data Templates') ? 'active green' : ''}`}>
                                            <Link to={`/data/management/templates`}>
                                                <i className="material-icons">keyboard_arrow_right</i>
                                                <span className={this.state.language.LeftSideBar.DataTemplates.length <= lengthCheck ? "" : "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.DataTemplates.length > lengthCheck ? this.state.language.LeftSideBar.DataTemplates : ""}>{this.state.language.LeftSideBar.DataTemplates}</span>
                                            </Link>
                                        </li>
                                        }
                                        {(this.state.template) && <li className={`${(this.state.active_menu_level_2 === 'Data Download/Upload') ? 'active green' : ''}`}>
                                            <Link to={`/data/management/download/upload`}>
                                                <i className="material-icons">keyboard_arrow_right</i>
                                                <span className={this.state.language.LeftSideBar.DataDownloadUpload.length <= lengthCheck ? "" : "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.DataDownloadUpload.length > lengthCheck ? this.state.language.LeftSideBar.DataDownloadUpload : ""}>{this.state.language.LeftSideBar.DataDownloadUpload}</span>
                                            </Link>
                                        </li>
                                        }
                                        {(this.state.delete_mgt) && <li className={`${(this.state.active_menu_level_2 === 'Delete Management') ? 'active green' : ''}`}>
                                            <Link to={`/data/management/delete/management`}>
                                                <i className="material-icons">keyboard_arrow_right</i>
                                                <span className={this.state.language.LeftSideBar.DeleteManagement.length <= lengthCheck ? "" : "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.DeleteManagement.length > lengthCheck ? this.state.language.LeftSideBar.DeleteManagement : ""}>{this.state.language.LeftSideBar.DeleteManagement}</span>
                                            </Link>
                                        </li>
                                        }
                                        {(this.state.orphaned_records) && <li className={`${(this.state.active_menu_level_2 === 'Orphaned Records') ? 'active green' : ''}`}>
                                            <Link to={`/data/management/orphaned/records`}>
                                                <i className="material-icons">keyboard_arrow_right</i>
                                                <span className={this.state.language.LeftSideBar.OrphanedRecords.length <= lengthCheck ? "" : "tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.OrphanedRecords.length > lengthCheck ? this.state.language.LeftSideBar.OrphanedRecords : ""}>{this.state.language.LeftSideBar.OrphanedRecords}</span>
                                            </Link>
                                        </li>
                                        }
                                    </ul>
                                </div>
                            </li>
    }
                            </ul>
                        </li>
                
                        <li className={`bold ${(this.state.active_menu_level_1 === 'Help') ? 'active' : '' }`}>
                            <Link to={"/support/contacts"}>
                                <i className="material-icons">live_help</i>
                                <span className={this.state.language.LeftSideBar.Help.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Help.length > lengthCheck ? this.state.language.LeftSideBar.Help: ""}>{this.state.language.LeftSideBar.Help}</span>
                            </Link>
                        </li>
                        <li className={`bold ${(this.state.active_menu_level_1 === 'Support') ? 'active' : '' }`}>
                            <Link to={"/support/contacts"}>
                                <i className="material-icons">help_outline</i>
                                <span className={this.state.language.LeftSideBar.Support.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.Support.length > lengthCheck ? this.state.language.LeftSideBar.Support: ""}>{this.state.language.LeftSideBar.Support}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={"#!"} onClick={this.handleSignout.bind(this)}>
                                <i className="material-icons">power_settings_new</i>
                                <span className={this.state.language.LeftSideBar.SignOut.length <= lengthCheck ? "nav-text": "nav-text tooltipped"} data-position="right" data-delay={delay} data-tooltip={this.state.language.LeftSideBar.SignOut.length > lengthCheck ? this.state.language.LeftSideBar.SignOut: ""}>{this.state.language.LeftSideBar.SignOut}</span>
                            </Link>
                        </li>
                        <div className="ps-scrollbar-x-rail" style={divStyle1}>
                            <div className="ps-scrollbar-x" style={divStyle4}></div>
                        </div>
                        <div className="ps-scrollbar-y-rail" style={divStyle2}>
                            <div className="ps-scrollbar-y" style={divStyle5}></div>
                        </div>
                    </ul>
                    <Link to={"#!"} data-activates="slide-out" className="sidebar-collapse btn-floating btn-medium waves-effect waves-light hide-on-large-only gradient-45deg-light-blue-cyan gradient-shadow">
                        <i className="material-icons">menu</i>
                    </Link>
                </aside>
            // <!-- END LEFT SIDEBAR NAV-->
            // <!-- //////////////////////////////////////////////////////////////////////////// -->
        )
    }
}

export default LeftSideBar;