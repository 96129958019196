import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

import axios from 'axios';



class PickLists extends Component{
    constructor(props){
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getPickLists = this.getPickLists.bind(this);
        this.state = {
            picklists: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            picklistDataItems:  null,
            picklists_create: null,
            picklists_edit: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();
        
    }


    componentWillMount(){
        this.checkAccessRight();
        this.getProfile();
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.PicklistValues + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();
        this.getPickLists();
        
    }


    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

       if ( this.Auth.checkAccessScope(['read:pick_lists', 'action:pick_lists'], 'view') === false){
            this.props.history.replace(default_home);   
       };

    }


    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile){
            this.setState({scopes: user_profile.scopes});
        }

    }


    getAccessScopes(){

        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:pick_lists' || this.state.scopes['views'][i] === 'action:pick_lists') {
                    this.setState({picklists_create: true});
                }
                if (this.state.scopes['views'][i] === 'update:pick_lists' || this.state.scopes['views'][i] === 'action:pick_lists') {
                    this.setState({picklists_edit: true});
                }
            }
        };


    }


    getPickLists(){

        const pick_list_names = "view_all_picklists"; // this is to get all the pick list values

        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
              tk: this.Auth.getToken(),
              pick_list_names: pick_list_names,
            }
          })
            .then(response => {

                this.setState({picklists: response.data.data}, () => {
                    

                    this.setState({picklistDataItems: <DataTable tableName={''} 
                                    fields={['pick_list_category', 'item_name', 'item_code', 'item_description', 'pick_list_type', 'is_priority', 'status']}
                                    fieldDataTypes={['String', 'String', 'String', 'String', 'String', 'Boolean', 'Status']}
                                    fieldDecimalPlaces={[0, 0, 0, 0, 0, 0, 0]}
                                    dateFormat={'long_date'}
                                    timeFormat={'h12'}
                                    headers={[this.Language.DataTableHeader.Category, this.Language.DataTableHeader.ItemName, this.Language.DataTableHeader.ItemCode, this.Language.DataTableHeader.Description, this.Language.DataTableHeader.Type, this.Language.DataTableHeader.IsPriority, this.Language.DataTableHeader.Status]} 
                                    data={this.state.picklists} 
                                    sortType={"ascending"} sortPosition={4} thStyle={[151, 221, 164, 174, 145, 130, 100]}
                                    link={"/settings/picklist/edit/"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={this.state.picklists_edit} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.PickLists} emptyMessage={this.Language.EmptyMessage.PickLists} 
                                    downloadFields={['pick_list_category', 'item_name', 'item_code', 'item_description', 'pick_list_type', 'is_priority', 'status']}/> 
                                    })

                    this.setState({preloader: null});
                })

                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })

                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  

                  if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                  if (error.response.status === 400 ) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');
                  

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });

    }



    render(){

         return ( 
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Picklist Values"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.PicklistValues}`} breadcrumbs={[this.Language.BreadCrumbLabel.Settings, this.Language.BreadCrumbLabel.PicklistValues]} links={[`/settings`, `/settings/picklists`]} button_link={'/settings/picklist/'} button_icon={'person_add'} button_name={'Create Picklist'} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <br/>
{(this.state.picklists_create) &&   <Link className="waves-effect waves-light btn right" to={`/settings/picklist/add`}><i className="material-icons left">playlist_add</i>{this.Language.ButtonLabel.AddPicklistItem}</Link>}
                                <br/>
                                <br/>
                                {this.state.preloader   &&  <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}
                                    {this.state.picklistDataItems}
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                

                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]
            
        )
    }
}

export default PickLists;