import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
import BrowserService from '../modules/BrowserService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getUser = this.getUser.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.activateDeactivateUser = this.activateDeactivateUser.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
          userDetails: '',
          status: '',
          message: '',
          accont_status: 'Inactive',
          temp_accont_status: 'Inactive',
          logged_in_hash_user: '',
          logged_in_username: '',
          users_update: null,
          users_delete: null,
          scopes: {},
          return_back_to_url: '/users'
        };
        this.Auth = new AuthService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.getProfile();
        // this.checkAccessRight();

    }

    componentDidMount() {
      document.title = this.Language.PageTitle.UserProfile + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();
        this.getUser();


    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (this.props.history.location.search && this.props.history.location.search !== '' && this.props.history.location.search !== null && typeof this.props.history.location.search !== 'undefined') {
            let search = this.props.history.location.search || '';
            if (search.substring(0, 4) === this.returnURLQuery) {
                let returnURLPlaceHolder = search.substring(4);
                this.setState({
                  return_back_to_url: returnURLPlaceHolder || this.state.return_back_to_url
                });
            }
        }

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username,
                logged_in_hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['read:users', 'action:users'], 'view') === false) || (this.state.logged_in_hash_user === this.props.match.params.id)) {
            this.props.history.replace(this.state.return_back_to_url);
        };

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'update:users' || this.state.scopes['views'][i] === 'action:users') this.setState({ users_update: true });
                if (this.state.scopes['views'][i] === 'delete:users' || this.state.scopes['views'][i] === 'action:users') this.setState({ users_delete: true });
            }
        };


    }

    checkBoxChange() {

        let checkBoxState = this.refs.activate_switch.checked;

        if (checkBoxState) { // Activating
            if (window.confirm(`${this.Language.ConfirmMessage.ActivateUserAccount}`)) {
                // make call to the database here

                this.activateDeactivateUser('Activate');

                // window.Materialize.toast(`${this.state.userDetails.username} User Account activated`, 6000, 'rounded green accent-3');

                this.setState({ temp_accont_status: 'Active' });

            } else {

                this.refs.activate_switch.checked = false;

            };

        }
        else { //Deactivating
            if (window.confirm(`${this.Language.ConfirmMessage.DeactivateUserAccount}`)) {
                // make call to the database here
                this.activateDeactivateUser('Deactivate');
                // window.Materialize.toast(`${this.state.userDetails.username} User Account deactivated`, 6000, 'rounded deep-orange accent-3');

                this.setState({ temp_accont_status: 'Inactive' });

            } else {

                this.refs.activate_switch.checked = "checked";

            };

        };

    }



    getUser() {
        let userId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/users/${userId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {
                this.setState({ userDetails: response.data.data[0] }, () => {
                    
                })

                if (response.data.data[0].status === 'Active') {
                    this.setState({ accont_status: 'Active' });
                } else {
                    this.setState({ accont_status: 'Inactive' });
                }

                if (response.data.data[0].status === 'Active') {
                    this.setState({ temp_accont_status: 'Active' });
                } else {
                    this.setState({ temp_accont_status: 'Inactive' });
                }


                this.setState({ status: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                   
                })
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }


    activateDeactivateUser(activate_deactivate_action) {

        let username = this.state.userDetails.username;
        let logged_in_username = this.state.logged_in_username;
        let userIdTemp = this.state.userDetails.hash_id;

        const userOS = this.Browser.getOS();

        const newUserAccountAction = {
            username: username,
            activate_deactivate_action: activate_deactivate_action,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken()
        }


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/users/activate/${userIdTemp}`,
            data: newUserAccountAction
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200 && newUserAccountAction.activate_deactivate_action === 'Activate') window.Materialize.toast(this.Language.NodeMessage.UserAccount.ActivateSuccess + newUserAccountAction.username, 8000, 'rounded green');
                if (response.status === 200 && newUserAccountAction.activate_deactivate_action === 'Deactivate') window.Materialize.toast(this.Language.NodeMessage.UserAccount.DeactivateSuccess + newUserAccountAction.username, 8000, 'rounded green');
            })


        })
            .catch(error => {
                if (error.response) {

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.UserAccount.UpdateError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserAccount.DoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                


            });
    }




    onDelete() {

        document.getElementById("delete_user_account_button").setAttribute("disabled", true);


        let username = this.state.userDetails.username;
        let logged_in_username = this.state.logged_in_username;
        let userIdTemp = this.state.userDetails.hash_id;

        const userOS = this.Browser.getOS();

        const deleteUserAccount = {
            username: username,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/users/${userIdTemp}`,
            data: deleteUserAccount
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                window.Materialize.toast(this.Language.NodeMessage.UserAccount.DeleteSuccess + deleteUserAccount.username, 8000, 'rounded green');
            })


            document.getElementById("delete_user_account_button").removeAttribute("disabled");
            this.props.history.replace(this.state.return_back_to_url);

        })
            .catch(error => {
                if (error.response) {

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserAccount.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_user_account_button").removeAttribute("disabled");

            });

    }



    render() {

        let date_of_registration = this.state.userDetails.date_of_registration + '';
        date_of_registration = this.DateService.convertToCalendarFullDate(date_of_registration);

        let approved_at = this.state.userDetails.approved_at + '';
        approved_at = this.DateService.convertToCalendarFullDate(approved_at);

        let userId = this.props.match.params.id;

        let default_time_zone = this.state.userDetails.time_zone_region || '';
        default_time_zone = default_time_zone.split("***"); // split the time zone values  gotten from the database
        default_time_zone = default_time_zone[1];

        if (this.state.temp_accont_status === 'Active') {
            $(".switch").find("input[type=checkbox]").prop('checked', true);
        } else {
            $(".switch").find("input[type=checkbox]").prop('checked', false);
        }




        return [
          // <LoaderWrapper key={0}/>,
          <Header key={1} history={this.props.history} language={this.Language} />,

          // <!-- START MAIN -->
          <div id="main" key={2}>
            <div className="wrapper green lighten-5">
              <LeftSideBar active_menu_level_1={'User Management'} active_menu_level_2={'User Accounts'} history={this.props.history} language={this.Language} />
              {/* <!-- START CONTENT --> */}
              <section id="content">
                <BreadcrumbsWrapper
                  page_title={`${this.Language.PageTitle.UserProfile}`}
                  breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.UserAccounts, this.Language.BreadCrumbLabel.UserProfile]}
                  links={[`/users`, this.state.return_back_to_url, `/users/${userId}`]}
                  language={this.Language}
                />
                {/* <!--start container--> */}
                <div className="container">
                  <div id="profile-page" className="section">
                    <div id="profile-page-header" className="card" style={{overflow: 'hidden'}}>
                      <div className="card-image waves-effect waves-block waves-light">
                        <img className="activator" src="/assets/images/gallary/30.png" alt="" />
                      </div>
                      <figure className="card-profile-image">
                        <img src="/images/user.png" alt="" className="circle z-depth-2 responsive-img activator gradient-shadow" />
                        {/* <img src="/assets/images/avatar/avatar-7.png" alt="" className="circle z-depth-2 responsive-img activator gradient-45deg-light-blue-cyan gradient-shadow"/> */}
                      </figure>
                      <div className="card-content">
                        <div className="row pt-2">
                          <div className="col s12 m4 offset-m2">
                            <h4 className="card-title grey-text text-darken-4">
                              {this.state.userDetails.title} {this.state.userDetails.prefix} {this.state.userDetails.first_name} {this.state.userDetails.middle_name} {this.state.userDetails.last_name}{' '}
                              {this.state.userDetails.suffix}{' '}
                            </h4>
                            <p className="medium-small grey-text">{this.state.userDetails.username}</p>
                          </div>
                          <div className="col s12 m2 center-align">
                            <h4 className="card-title grey-text text-darken-4">{this.state.userDetails.exporter_name}</h4>
                            <p className="medium-small grey-text">{this.Language.DataField.Exporter}</p>
                          </div>
                          <div className="col s12 m2 center-align">
                            <h4 className="card-title grey-text text-darken-4">{date_of_registration}</h4>
                            <p className="medium-small grey-text">{this.Language.DataField.DateofRegistration}</p>
                          </div>
                          <div className="col s12 m1 center-align">
                            <h4 className="card-title grey-text text-darken-4">
                              {this.state.userDetails.status ? (
                                this.state.userDetails.status === 'Active' ? (
                                  <span className="green-text">{this.Language.DataValue.Active}</span>
                                ) : (
                                  <span className="red-text">{this.Language.DataValue.Inactive}</span>
                                )
                              ) : null}
                            </h4>
                            <p className="medium-small grey-text">{this.Language.DataField.Status}</p>
                          </div>
                          <div className="col s12 m1 right-align">
                            <a href={'#!'} className="btn-floating activator waves-effect waves-light teal accent-2 right">
                              <i className="material-icons">perm_identity</i>
                            </a>
                          </div>
                          {/* :;after */}
                        </div>
                      </div>
                      <div className="card-reveal" style={{display: 'none', transform: 'translateY(0px)'}}>
                        <p>
                          <span className="card-title grey-text text-darken-4">
                            {this.state.userDetails.title} {this.state.userDetails.prefix} {this.state.userDetails.first_name} {this.state.userDetails.middle_name} {this.state.userDetails.last_name}{' '}
                            {this.state.userDetails.suffix}
                            <i className="material-icons right">close</i>
                          </span>
                          <span>
                            <i className="material-icons cyan-text text-darken-2">perm_identity</i>
                            {this.state.userDetails.exporter_name}
                          </span>
                        </p>
                        <p>
                          {this.Language.DataField.PortalUserAccountFor} {this.state.userDetails.exporter_name}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">perm_phone_msg</i>
                          {this.state.userDetails.phone}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">email</i>
                          {this.state.userDetails.email}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">card_membership</i>
                          {date_of_registration}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">assignment_turned_in</i>
                          {this.Language.DataField.Role}: {this.state.userDetails.user_role}
                        </p>
                      </div>
                    </div>
                    <div id="profile-page-content" className="row">
                      <div id="profile-page-sidebar" className="col s12 m5">
                        <div className="card cyan">
                          <div className="card-content white-text">
                            <span className="card-title">
                              {this.Language.DataField.Username}: <b>{this.state.userDetails.username}</b>{' '}
                            </span>
                            <p>
                              {' '}
                              {this.Language.DataField.Role}: <b>{this.state.userDetails.user_role}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.InternalUser}:{' '}
                              <b>
                                {this.state.userDetails.internal_user !== null && typeof this.state.userDetails.internal_user !== 'undefined'
                                  ? this.state.userDetails.internal_user === 'true' || this.state.userDetails.internal_user === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.PartnerUser}:{' '}
                              <b>
                                {this.state.userDetails.partner_user !== null && typeof this.state.userDetails.partner_user !== 'undefined'
                                  ? this.state.userDetails.partner_user === 'true' || this.state.userDetails.partner_user === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Exporter}: <b>{this.state.userDetails.exporter_name}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.CreatedBy}: <b>{this.state.userDetails.created_by}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Approved}:{' '}
                              <b>
                                {this.state.userDetails.is_approved !== null && typeof this.state.userDetails.is_approved !== 'undefined'
                                  ? this.state.userDetails.is_approved === 'true' || this.state.userDetails.is_approved === true
                                    ? this.Language.DataValue.Yes
                                    : this.Language.DataValue.No
                                  : null}
                              </b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ApprovedBy}: <b>{this.state.userDetails.approved_by}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.ApprovedOn}: <b>{approved_at}</b>{' '}
                            </p>
                          </div>
                        </div>
                        <ul id="profile-page-about-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <b>{this.Language.PageLabel.ContactDetails}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">perm_phone_msg</i>
                                {this.Language.DataField.Phone}
                              </div>
                              <div className="col s7 right-align">{this.state.userDetails.phone}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">email</i>
                                {this.Language.DataField.Email}
                              </div>
                              <div className="col s7 right-align">{this.state.userDetails.email}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">language</i>
                                {this.Language.DataField.Language}
                              </div>
                              <div className="col s7 right-align">{this.state.userDetails.language_id}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">language</i>
                                {this.Language.DataField.DefaultTimeZone}
                              </div>
                              <div className="col s7 right-align">{default_time_zone}</div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">card_membership</i>
                                {this.Language.DataField.DateofRegistration}
                              </div>
                              <div className="col s7 right-align">{date_of_registration}</div>
                            </div>
                          </li>
                        </ul>
                        <br />
                        <br />
                      </div>
                      <div id="profile-page-wall" className="col s12 m7">
                        <ul id="profile-page-about-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <b>{this.Language.PageLabel.OtherDetailsandSettings} </b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableSMS}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_sms !== null && typeof this.state.userDetails.enable_sms !== 'undefined' ? (
                                  this.state.userDetails.enable_sms === 'true' || this.state.userDetails.enable_sms === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableEmail}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_email !== null && typeof this.state.userDetails.enable_email !== 'undefined' ? (
                                  this.state.userDetails.enable_email === 'true' || this.state.userDetails.enable_email === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableMobileApp}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_mobile_app !== null && typeof this.state.userDetails.enable_mobile_app !== 'undefined' ? (
                                  this.state.userDetails.enable_mobile_app === 'true' || this.state.userDetails.enable_mobile_app === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableNotifications}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_notifications !== null && typeof this.state.userDetails.enable_notifications !== 'undefined' ? (
                                  this.state.userDetails.enable_notifications === 'true' || this.state.userDetails.enable_notifications === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ReceiveNotificationsAsSMS}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.receive_notification_as_sms !== null && typeof this.state.userDetails.receive_notification_as_sms !== 'undefined' ? (
                                  this.state.userDetails.receive_notification_as_sms === 'true' || this.state.userDetails.receive_notification_as_sms === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ReceiveNotificationsAsEmail}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.receive_notification_as_email !== null && typeof this.state.userDetails.receive_notification_as_email !== 'undefined' ? (
                                  this.state.userDetails.receive_notification_as_email === 'true' || this.state.userDetails.receive_notification_as_email === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ProfileVisible}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.profile_is_visible !== null && typeof this.state.userDetails.profile_is_visible !== 'undefined' ? (
                                  this.state.userDetails.profile_is_visible === 'true' || this.state.userDetails.profile_is_visible === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.MergedWithAParentAccount}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.is_merged !== null && typeof this.state.userDetails.is_merged !== 'undefined' ? (
                                  this.state.userDetails.is_merged === 'true' || this.state.userDetails.is_merged === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.AccountFrozen}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.is_frozen !== null && typeof this.state.userDetails.is_frozen !== 'undefined' ? (
                                  this.state.userDetails.is_frozen === 'true' || this.state.userDetails.is_frozen === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.PasswordLocked}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.is_password_locked !== null && typeof this.state.userDetails.is_password_locked !== 'undefined' ? (
                                  this.state.userDetails.is_password_locked === 'true' || this.state.userDetails.is_password_locked === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableOfflineUsage}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_offline_user !== null && typeof this.state.userDetails.enable_offline_user !== 'undefined' ? (
                                  this.state.userDetails.enable_offline_user === 'true' || this.state.userDetails.enable_offline_user === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                        </ul>
                        {/* ::after */}
                      </div>
                    </div>
                    <br />
                    <br />
                    <Link className="waves-effect waves-light btn grey" to={this.state.return_back_to_url}>
                      <i className="material-icons left">arrow_back</i>
                      {this.Language.ButtonLabel.Back}
                    </Link>

                    {this.state.users_update && (
                      <Link className="waves-effect waves-light btn" to={`/users/edit/${this.state.userDetails.hash_id}`}>
                        <i className="material-icons left">edit</i>
                        {this.Language.ButtonLabel.Edit}
                      </Link>
                    )}

                    {this.state.users_delete && (
                      <button
                        className="waves-effect waves-light btn red right"
                        id="delete_user_account_button"
                        onClick={() => {
                          if (window.confirm(`${this.Language.ConfirmMessage.DeleteUserAccount} ${this.state.userDetails.username}`)) {
                            this.onDelete();
                          }
                        }}>
                        <i className="material-icons left">delete</i>
                        {this.Language.ButtonLabel.Delete}
                      </button>
                    )}

                    <br />
                    <br />
                    {this.state.users_update && (
                      <div className="collection-item border-none">
                        <div className="switch">
                          <label className="font-weight-600 black-text">
                            {this.Language.DataField.DeactivateAccount}
                            <input type="checkbox" name="activate_switch" ref="activate_switch" onClick={this.checkBoxChange} />
                            <span className="lever"></span>
                            {this.Language.DataField.ActivateAccount}
                          </label>
                        </div>
                      </div>
                    )}
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                {/* <!--end container--> */}
              </section>
              {/* <!-- END CONTENT --> */}
              <FloatingActionButton language={this.Language} />
              {/* <RightSideBar language={this.Language}/> */}
            </div>
            {/* <!-- END WRAPPER --> */}
          </div>,
          // <!-- END MAIN --> */
          // <!-- //////////////////////////////////////////////////////////////////////////// -->
          <Footer key={3} language={this.Language} />
        ];
    }
}

export default UserDetails;