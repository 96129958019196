import React, {Component} from 'react';
import FileService from '../modules/FileService';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LanguageService from '../modules/LanguageService';
import BrowserService from '../modules/BrowserService';

import AuthService from '../modules/AuthService';
import URLService from '../modules/URLService';
import Papa from 'papaparse';

const $ = require('jquery');

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.state = {
      fieldName: props.fieldName || 'Document',
      fileExtensions: props.fileExtensions || 'pdf png jpeg',
      fieldID: props.fieldID || 'input-file-now',
      maxFileSize: props.maxFileSize || '25M',
      defaultFile: props.defaultFile || '',
      fileName: props.fileName || 'Default_file_name',
      uploadStatus: false,
      preloader: null,
      fileURL: '',
      buttonLabel: '',
      buttondisabled: false,
      filedropzonedisabled: false,
      pageName: props.pageName || '',
      pageDataCategory: props.pageDataCategory || '',
      loggedInUsername: props.loggedInUsername,
      RequiredFields: props.RequiredFields
    };
    this.File = new FileService();
    this.Auth = new AuthService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.apiURL = URLService.prototype.getApiURL();
    this.Browser = new BrowserService();
  }

  componentWillMount() {}

  componentDidMount() {
    this.setState({buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});

    window.$('.dropify').dropify({
      messages: {
        default: this.Language.FileUpload.Default,
        replace: this.Language.FileUpload.Replace,
        remove: this.Language.FileUpload.Remove,
        error: this.Language.FileUpload.Error
      },
      error: {
        fileSize: 'The file size is too big ({{ value }} max).',
        minWidth: 'The image width is too small ({{ value }}}px min).',
        maxWidth: 'The image width is too big ({{ value }}}px max).',
        minHeight: 'The image height is too small ({{ value }}}px min).',
        maxHeight: 'The image height is too big ({{ value }}px max).',
        imageFormat: 'The image format is not allowed ({{ value }} only).'
      }
    });
  }

  componentWillUnmount() {
    window.$('.dropify').dropify({});
  }

  shouldComponentUpdate() {
    return true;
  }

  handleUploadFile(event) {
    event.preventDefault();

    this.setState({uploadStatus: true, buttondisabled: true, filedropzonedisabled: true, buttonLabel: this.Language.ButtonLabel.Uploading}, () => {
      if (this.uploadInput.files[0]) {
        this.setState({preloader: true});

        let original_file_name = this.uploadInput.files[0].name;
        let file_type = this.uploadInput.files[0].type;
        let file_extension = original_file_name.split('.').pop(); // get the file etension from the name

        let extension_list = this.state.fileExtensions;
        extension_list = extension_list.split(' '); // get the list of valid extensions

        let len = extension_list.length;
        let valid_extension = false;
        let valid_type = false;


        //check file extension
        for (let i = 0; i < len; i++) {
          if (file_extension === extension_list[i]) {
            valid_extension = true;
            break;
          }
        }

        //check file type
        for (let i = 0; i < len; i++) {
          if (
            file_type === 'text/plain' ||
            file_type === 'application/pdf' ||
            file_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            file_type === 'application/msword' ||
            file_type === 'image/jpeg' ||
            file_type === 'image/png' ||
            file_type === 'application/vnd.ms-excel' ||
            file_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file_type === 'text/csv'
          ) {
            valid_type = true;
            break;
          }
        }

        let new_extension = '';
        if (file_type === 'text/plain') new_extension = '.txt';
        if (file_type === 'application/pdf') new_extension = '.pdf';
        if (file_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') new_extension = '.docx';
        if (file_type === 'application/msword') new_extension = '.doc';
        if (file_type === 'image/jpeg') new_extension = '.jpg';
        if (file_type === 'image/png') new_extension = '.png';
        if (file_type === 'application/vnd.ms-excel') new_extension = '.xls';
        if (file_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') new_extension = '.xlsx';
        if (file_type === 'text/csv') new_extension = '.csv';

        if (valid_extension === true && valid_type === true) {
          let documentCategory = this.state.pageDataCategory;
          let fileNamePrefix = this.state.fileName;
          let timing = Math.round(new Date().getTime() / 1000);
          let fileName = `${fileNamePrefix}_${timing}${new_extension}`;
          let documentID = `${fileNamePrefix}_${timing}`;
          let additional_file_values = ``;
          let dataupload_file_vales = ``;

          if (this.state.pageName === 'AddBaselineData' || this.state.pageName === 'AddBaselineDataProfile') {
            let exporter_id_temp = $('#exporter_id').val();
            let exporter_id_temp_array = exporter_id_temp.split('<:>');
            let exporter_id = exporter_id_temp_array[0];
            if (exporter_id === 'Select Exporter') exporter_id = '';

            let business_year = $('#business_year').val();
            if (business_year === 'Select Business Year') business_year = '';

            let created_by = this.state.loggedInUsername;

            if (
              exporter_id !== '' &&
              exporter_id !== null &&
              typeof exporter_id !== 'undefined' &&
              business_year !== '' &&
              business_year !== null &&
              typeof business_year !== 'undefined' &&
              created_by !== '' &&
              created_by !== null &&
              typeof created_by !== 'undefined'
            ) {
              additional_file_values = `Baseline__${exporter_id}__${business_year}__${created_by}`;
            }
          }

          if (this.state.pageName === 'AddEEGClaim' || this.state.pageName === 'AddEEGClaimApplication') {
            let exporter_id_temp = $('#exporter_id').val();
            let exporter_id_temp_array = exporter_id_temp.split('<:>');
            let exporter_id = exporter_id_temp_array[0];
            if (exporter_id === 'Select Exporter') exporter_id = '';

            let nxp_number = $('#nxp_number').val();
            if (nxp_number === null || typeof nxp_number === 'undefined') nxp_number = '';

            let export_date = $('#export_date').val();
            if (export_date === null || typeof export_date === 'undefined' || export_date === '') export_date = '';
            export_date = export_date.split(' ');
            let export_year = export_date.pop();
            //30 April, 2018

            let created_by = this.state.loggedInUsername;

            if (
              exporter_id !== '' &&
              exporter_id !== null &&
              typeof exporter_id !== 'undefined' &&
              nxp_number !== '' &&
              nxp_number !== null &&
              typeof nxp_number !== 'undefined' &&
              export_date !== '' &&
              export_date !== null &&
              typeof export_date !== 'undefined' &&
              created_by !== '' &&
              created_by !== null &&
              typeof created_by !== 'undefined'
            ) {
              additional_file_values = `EEG Claims__${exporter_id}__${nxp_number}__${created_by}__${export_year}`;
            }
          }


          if (this.state.pageName === 'DataDownloadUpload' || this.state.pageName === 'DataDelete') {
            let table_name = $('#table_name').val();
            if (table_name === 'Select Table Name') table_name = '';

            let created_by = this.state.loggedInUsername;

            if (table_name !== '' && table_name !== null && typeof table_name !== 'undefined' && created_by !== '' && created_by !== null && typeof created_by !== 'undefined') {
              dataupload_file_vales = `DataUpload__${table_name}__${created_by}`;
            }
          }



          // For File Uploads
          if (additional_file_values !== '') {
            const temp_file_name = this.uploadInput.files[0].name;
            const max_file_name_length = 180;

            if (temp_file_name.length <= max_file_name_length) {
              const data = new FormData();
              data.append('file-to-upload', this.uploadInput.files[0]);
              data.append('originalfilename', this.uploadInput.files[0].name);
              data.append('filename', fileName);
              data.append('description', fileName);
              data.append('documentCategory', documentCategory);
              data.append('documentID', documentID);
              data.append('additionalFileValues', additional_file_values);



              axios
                .post(`${this.apiURL}/api/v1/file/upload?tk=${this.Auth.getToken()}`, data)
                .then(response => {
                  this.setState({
                    fileURL: temp_file_name,
                    uploadStatus: true,
                    filedropzonedisabled: true,
                    buttonLabel: this.Language.ButtonLabel.FileUploaded
                  });

                  this.setState({preloader: null});


                })
                .catch(error => {
                  if (error.response.data.message === 'Invalid file') {
                    window.Materialize.toast(this.Language.NodeMessage.FileUpload.InvalidFile + this.state.fileExtensions, 8000, 'rounded deep-orange darken-4');
                  }
                  if (error.response.data.message === 'Filename too long') {
                    window.Materialize.toast(this.Language.NodeMessage.FileUpload.FileNameTooLong + max_file_name_length, 8000, 'rounded deep-orange darken-4');
                  }
                  this.setState({buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
                });

              this.setState({preloader: null});
            } else {
              window.Materialize.toast(this.Language.NodeMessage.FileUpload.FileNameTooLong + max_file_name_length, 8000, 'rounded deep-orange darken-4');
              this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
            }
          } else {
            if (this.state.pageName !== 'DataDownloadUpload' && this.state.pageName !== 'DataDelete') {
              window.Materialize.toast(this.Language.NodeMessage.FileUpload.FieldsNotSelected + this.state.RequiredFields, 8000, 'rounded deep-orange darken-4');
              this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
            }
          
          }




          // For Data Upload from files
          if (dataupload_file_vales !== '') {

              let table_name = $('#table_name').val();
              if (table_name === 'Select Table Name') table_name = '';
            
              const reader = new FileReader();
              reader.readAsText(this.uploadInput.files[0]);

              reader.onload = () => {
                let file_content = reader.result;
                file_content = file_content.toString();

                const papa_config = {
                  delimiter: '', // auto-detect
                  newline: '', // auto-detect
                  quoteChar: '"',
                  escapeChar: '"',
                  header: false,
                  transformHeader: undefined,
                  dynamicTyping: false,
                  preview: 0,
                  encoding: '',
                  worker: false,
                  comments: false,
                  step: undefined,
                  complete: undefined,
                  error: undefined,
                  download: false,
                  downloadRequestHeaders: undefined,
                  downloadRequestBody: undefined,
                  skipEmptyLines: true, // set to skip empty lines
                  chunk: undefined,
                  fastMode: undefined,
                  beforeFirstChunk: undefined,
                  withCredentials: undefined,
                  transform: undefined,
                  delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
                };
                
                const converted_file_data_temp = Papa.parse(file_content, papa_config);

                

                if (converted_file_data_temp.meta.aborted === false && converted_file_data_temp.meta.truncated === false) {
                  if (converted_file_data_temp.errors.length === 0 || (this.state.pageName === 'DataDelete' && converted_file_data_temp.errors.length === 1)) {
                    const converted_file_data = converted_file_data_temp.data;

                    if (converted_file_data.length > 3) {
                      const data_headers = converted_file_data[0];
                      const data_types = converted_file_data[1];
                      const data_mandatory_flags = converted_file_data[2];

                      if (data_headers.length === data_types.length && data_types.length === data_mandatory_flags.length) {
                        const number_of_rows = converted_file_data.length;
                        const number_of_columns = data_headers.length;
                        let is_data_valid = true;
                        let validation_errors = [];

                        let data_finalised_for_upload = [];

                        // Validate the data to be uploaded
                        // start from row 3
                        for (let i = 3; i < number_of_rows; i++) {
                          if (converted_file_data[i].length === number_of_columns) {
                            let row_values = converted_file_data[i];
                            let row_value_len = converted_file_data[i].length;
                            let is_row_valid = true;

                            for (let j = 0; j < row_value_len; j++) {
                              if (data_mandatory_flags[j] === 'NO' && (row_values[j] === '' || row_values[j] === null || typeof row_values[j] === 'undefined')) {
                                is_data_valid = false;
                                is_row_valid = false;
                                validation_errors.push(this.Language.NodeMessage.FileUpload.MandatoryFieldHasNoValue + (i + 1) + ', ' + data_headers[j]);
                              } else {
                                if (
                                  data_types[j] !== 'text' &&
                                  data_types[j] !== 'char' &&
                                  data_types[j] !== 'character varying' &&
                                  data_types[j] !== 'smallint' &&
                                  data_types[j] !== 'numeric' &&
                                  data_types[j] !== 'bigint' &&
                                  data_types[j] !== 'double precision' &&
                                  data_types[j] !== 'integer' &&
                                  data_types[j] !== 'real' &&
                                  data_types[j] !== 'boolean' &&
                                  data_types[j] !== 'timestamp' &&
                                  data_types[j] !== 'timestamp with time zone' &&
                                  data_types[j] !== 'date' &&
                                  data_types[j] !== 'interval'
                                ) {
                                  is_data_valid = false;
                                  is_row_valid = false;
                                  validation_errors.push(this.Language.NodeMessage.FileUpload.NoDataTypeIndicated + j + ', ' + data_headers[j]);
                                }
                              }
                            }

                            if (is_row_valid === true) {
                              let temp_object = {};
                              for (let k = 0; k < row_value_len; k++) {
                                temp_object[data_headers[k]] = row_values[k];
                              }
                              data_finalised_for_upload.push(temp_object);
                            }
                          } else {
                            if (converted_file_data[i][0] !== '' && converted_file_data[i].length > 1) {
                              is_data_valid = false;
                              validation_errors.push(this.Language.NodeMessage.FileUpload.NumberofColumnsMisMatch + (i + 1));
                            }
                          }
                        }

                        if (is_data_valid === true) {
                          let update_existing_data = false;

                          let action_type = 'delete';
                          let undelete_deleted_data = false;
                          let undelete_and_activate_deleted_data = false;

                          if (this.state.pageName === 'DataDownloadUpload') {
                            update_existing_data = $('#update_existing_data').is(':checked');
                          }

                          if (this.state.pageName === 'DataDelete') {
                            undelete_deleted_data = $('#undelete_deleted_data').is(':checked');
                            undelete_and_activate_deleted_data = $('#undelete_and_activate_deleted_data').is(':checked');
                            if (undelete_deleted_data === true) action_type = 'undelete';
                            if (undelete_and_activate_deleted_data === true) action_type = 'undelete_activate';
                          }

                          let created_by = this.state.loggedInUsername;
                          let last_updated_by = this.state.loggedInUsername;
                          const temp_file_name = this.uploadInput.files[0].name;


                          // CHeck if the table is allowed for download
                          const can_upload_table_list_encrypted = ['user_accounts', 'organisations', 'exporters', 'eeg_claims'];

                          let encrypted_tables_len = can_upload_table_list_encrypted.length;
                          let allow_to_proceed = true;
                          let data_upload_table_option = 'new/ue';
                          if (update_existing_data === true) {
                            data_upload_table_option = 'update/ue';
                          }
                          let can_undelete_from_table = true;

                          for (let i = 0; i < encrypted_tables_len; i++) {
                            if (table_name === can_upload_table_list_encrypted[i]) {
                              data_upload_table_option = 'new/e';
                              if (update_existing_data === true) {
                                data_upload_table_option = 'update/e';
                              }
                              break;
                            }
                          }

                          const cannot_upload_table_list = [
                            'login_configurations',
                            'modules',
                            'audit_activities',
                            'access_rights',
                            'data_field_security',
                            'notifications',
                            'objects',
                            'password_change_history',
                            'password_configurations',
                            'system_access_right_objects',
                            'system_parameters',
                            'tenant',
                            'user_account_device_history',
                            'user_account_devices',
                            'online_users',
                            'user_signins'
                          ];

                          let exclude_tables_len = cannot_upload_table_list.length;

                          for (let i = 0; i < exclude_tables_len; i++) {
                            if (table_name === cannot_upload_table_list[i]) {
                              allow_to_proceed = false;
                              can_undelete_from_table = false;
                              break;
                            }
                          }

                          if (allow_to_proceed === true && can_undelete_from_table === true) {
                            const userOS = this.Browser.getOS();

                            //make call to database
                            const newDataUploadRequest = {
                              data: data_finalised_for_upload,
                              created_by: created_by,
                              last_updated_by: last_updated_by,
                              action_type: action_type,
                              device_name: this.state.logged_in_username + "'s " + userOS,
                              device_model: userOS,
                              device_os: userOS,
                              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                              app_source: 'web',
                              token: this.Auth.getToken()
                            };



                            let URL = `${this.apiURL}/api/v1/data/management/upload/data/${data_upload_table_option}/${table_name}`;

                            if (this.state.pageName === 'DataDownloadUpload') {
                              URL = `${this.apiURL}/api/v1/data/management/upload/data/${data_upload_table_option}/${table_name}`;
                            }

                            if (this.state.pageName === 'DataDelete') {
                              URL = `${this.apiURL}/api/v1/data/delete/management/undelete/delete/record/ue/${table_name}`;
                            }

                            axios
                              .request({
                                method: update_existing_data === true ? 'put' : 'post',
                                url: URL,
                                data: newDataUploadRequest
                              })
                              .then(response => {
                                this.setState({status: response.data.status});

                                if (response.status === 200) window.Materialize.toast(response.data.message, 8000, 'rounded green');
                                if (response.status === 500) window.Materialize.toast(response.data.message, 8000, 'rounded deep-orange darken-4');
                                if (response.status !== 200 && response.status !== 500) window.Materialize.toast(response.data.message, 8000, 'rounded deep-orange darken-4');

                                this.setState({
                                  fileURL: temp_file_name,
                                  uploadStatus: true,
                                  filedropzonedisabled: true,
                                  buttonLabel: this.Language.ButtonLabel.FileUploaded,
                                  preloader: null
                                });
                              })
                              .catch(error => {
                                if (error.response.data.data_validation_messages && error.response.data.data_validation_messages.length > 0) {
                                  
                                  let temp_error_message_array = [];
                                  const data_validation_messages_len = error.response.data.data_validation_messages.length;
                                  for (let v = 0; v < data_validation_messages_len; v++) {
                                    let temp_error_message_object = {};
                                    temp_error_message_object['S/N'] = v + 1;
                                    temp_error_message_object['errors'] = error.response.data.data_validation_messages[v];
                                    temp_error_message_array.push(temp_error_message_object);
                                  }
                                  
                                  this.File.downloadCSV(temp_error_message_array, 'Upload_Error_File', ['S/N', 'errors']);
                                  window.Materialize.toast(this.Language.NodeMessage.FileUpload.ErrorWithUploadReferToErrorFile, 8000, 'rounded deep-orange darken-4');
                                }

                                if (error.response.status === 500) window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');
                                if (error.response.status === 400) window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');
                                if (error.response.status !== 400 && error.response.status !== 500) window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');

                                this.setState({buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false, preloader: null});
                              });
                          }
                        } else {
                          let temp_validation_errors_len = validation_errors.length;
                          if (temp_validation_errors_len > 5) {
                            temp_validation_errors_len = 5;
                          }
                          let temp_error_message_array = [];
                          for (let l = 0; l < temp_validation_errors_len; l++) {
                            let temp_error_message_object = {};
                            temp_error_message_object['S/N'] = l + 1;
                            temp_error_message_object['errors'] = validation_errors[l];
                            temp_error_message_array.push(temp_error_message_object);
                          }

                            this.File.downloadCSV(temp_error_message_array, 'Upload_Error_File', ['S/N', 'errors']);
                            window.Materialize.toast(this.Language.NodeMessage.FileUpload.ErrorWithUploadReferToErrorFile, 8000, 'rounded deep-orange darken-4');
                      
                          this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
                        }
                      } else {
                        window.Materialize.toast(this.Language.NodeMessage.FileUpload.UseAppropriateDataTemplate + table_name, 8000, 'rounded deep-orange darken-4');
                        this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
                      }
                    } else {
                      window.Materialize.toast(this.Language.NodeMessage.FileUpload.UsePopulatedDataTemplate + table_name, 8000, 'rounded deep-orange darken-4');
                      this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
                    }
                  } else {
                    window.Materialize.toast(this.Language.NodeMessage.FileUpload.UploadError + this.uploadInput.files[0].name, 8000, 'rounded deep-orange darken-4');
                    this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
                  }
                } else {
                  window.Materialize.toast(this.Language.NodeMessage.FileUpload.UploadAbortedInterupted + this.uploadInput.files[0].name, 8000, 'rounded deep-orange darken-4');
                  this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
                }

              };;


          } else {
            if (
              (this.state.pageName !== 'AddEEGClaim' && this.state.pageName !== 'AddEEGClaimApplication' && this.state.pageName !== 'AddBaselineData') &&
              this.state.pageName !== 'AddBaselineDataProfile'
            ) {
              window.Materialize.toast(this.Language.NodeMessage.FileUpload.FieldsNotSelected + this.state.RequiredFields, 8000, 'rounded deep-orange darken-4');
              this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
            }
          }

        } else {
          window.Materialize.toast(this.Language.NodeMessage.FileUpload.InvalidFile + this.state.fileExtensions, 8000, 'rounded deep-orange darken-4');
          this.setState({preloader: null, buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
        }
      } else {
        window.Materialize.toast(this.Language.NodeMessage.FileUpload.NoFileSelected + this.state.fieldName, 8000, 'rounded deep-orange darken-4');
        this.setState({buttonLabel: this.Language.ButtonLabel.UploadFile, buttondisabled: false, filedropzonedisabled: false});
      }
    });
  }

  render() {
    return (
      // <!--File Upload Field start-->
      <div className="row section">
        <div className="col s12 m4 l3">
          <p>
            <b>{this.state.fieldName}</b>
          </p>
        </div>
        <div className="col s12 m8 l9">
          <form className="" onSubmit={this.handleUploadFile} encType="multipart/form-data">
            <input
              type="file"
              id={this.state.fieldID}
              className="dropify"
              name="file-to-upload"
              ref={ref => {
                this.uploadInput = ref;
              }}
              data-default-file={this.state.defaultFile}
              data-max-file-size={this.state.maxFileSize}
              data-allowed-file-extensions={this.state.fileExtensions}
              disabled={false}
            />
            {this.state.preloader && (
              <div className="progress teal lighten-4">
                {' '}
                <div className="indeterminate teal"></div>
              </div>
            )}
            <span>{this.state.fileURL}</span>
            <Link className={`waves-effect waves-light btn right ${this.state.uploadStatus ? 'green' : ''} ${this.state.buttondisabled ? 'disabled' : ''}`} to={`#!`} onClick={this.handleUploadFile}>
              <i className="material-icons left">file_upload</i>
              {this.state.buttonLabel}
            </Link>
          </form>
        </div>
      </div>
      // <!--File Upload Field end-->
    );
  }
}

export default FileUpload;
