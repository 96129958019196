import React, { Component } from 'react';
import DatatableFooterItem from './DatatableFooterItem';


class DatatableFooter extends Component{
    constructor(props){
        super(props);
        this.state = {
            footers: props.footers
        }
    }


    render(){
        const footerItems = this.state.footers.map((footeritem,  i) => {
            return(
                <DatatableFooterItem key={i} footerItem={footeritem} />
            )
        })

        return (
            <tfoot>
                <tr>
                    {footerItems}
                </tr>
            </tfoot>
        )
    }
}

export default DatatableFooter;