import * as url from '../url/url'

export default class URLService {
  getApiURL() {
    let apiURL = '';

    if (process.env.NODE_ENV === 'development') {
      apiURL = url.apiURL.development;
    } else {
      apiURL = url.apiURL.production;
    }

    return apiURL;
  }

  getSearchURL() {
    let searchURLPath = url.searchURL.path || '/search';
    return searchURLPath;
  }

  getSearchQuery() {
    let searchURLQuery = url.searchURL.query || '?q=';
    return searchURLQuery;
  }

  getReturnURLQuery() {
    let returnURLQuery = url.returnURL.query || '?rt=';
    return returnURLQuery;
  }

  getQueryVariable(variable) {
    const default_return_value = null;
    const query = window.location.search.substring(1); //"app=article&act=news_content&aid=160990"
    const vars = query.split('&'); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    const vars_len = vars.length;
    for (let i = 0; i < vars_len; i++) {
      const pair = vars[i].split('='); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return default_return_value;
  }
}