import React, { Component } from 'react';


class TaskCollectionList extends Component {
    constructor(props) {
        super(props);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.state = {
            collectionID: props.collectionID || 'task-card',
            title: props.title || '',
            subTitle: props.subTitle || '',
            cardColour: props.cardColour || 'teal',
            textColour: props.textColour || 'white',
            noRecordAvailable: props.noRecordAvailable || 'No records available',
            collectionValuesList: props.collectionValuesList || [],
            canEditCheckBoxes: props.canEditCheckBoxes || false,
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '4',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '4',
        }

    }


    handleCheckBoxClick(position) {

        if (this.state.canEditCheckBoxes === true || this.state.canEditCheckBoxes === 'true' || this.state.canEditCheckBoxes === null || typeof this.state.canEditCheckBoxes === 'undefined') {

            let temp_array_of_objects = this.state.collectionValuesList.concat();

            temp_array_of_objects[position].checked = !this.state.collectionValuesList[position].checked;

            this.setState({ collectionValuesList: temp_array_of_objects });

        }

    }


    render() {


        const collectionStyle = {
            touchAction: 'pan-y',
            WebkitUserDrag: 'none',
            WebkitTapHighlightColor: "transparent",
        };


        return (
            // <!--Task Collection List start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <ul id={this.state.collectionID} className="collection with-header">
                    <li className={`collection-header ${this.state.cardColour} accent-4`}>
                        <h4 className={`task-card-title ${this.state.textColour}-text`}>{this.state.title}</h4>
                        <p className={`task-card-date ${this.state.textColour}-text`}>{this.state.subTitle}</p>
                    </li>


                    {(this.state.collectionValuesList && this.state.collectionValuesList.length > 0) ?

                        this.state.collectionValuesList.map((collection_value, i) => {

                            if (collection_value && collection_value.title !== null && typeof collection_value.title !== 'undefined') {

                                let strikeThorugh = 'none'
                                if (collection_value.checked === true) strikeThorugh = 'line-through'

                                return (

                                    <li key={i} className="collection-item dismissable" style={collectionStyle}>
                                        <input type="checkbox" id={"task" + (i + 1)} checked={collection_value.checked} disabled={collection_value.disabled} onChange={() => { this.handleCheckBoxClick(i) }} />
                                        <label htmlFor={"task" + (i + 1)} style={{ textDecoration: strikeThorugh }} className="black-text">{collection_value.title}
                                        </label>
                                        <a href={collection_value.link} className="secondary-content">
                                            <span className="ultra-small">{collection_value.subTitle}</span>
                                        </a>
                                        <p></p>
                                        <span className={`task-cat ${collection_value.categoryColour}`}>{collection_value.categoryTitle}</span>
                                    </li>

                                )

                            } else {

                                return (
                                    null
                                )

                            }


                        }) : <li className="collection-item">
                            <div className="row">
                                <div className="col s6">
                                    <p className="collections-title">{this.state.noRecordAvailable}</p>
                                </div>
                            </div>
                        </li>

                    }

                </ul>
            </div>
            // <!--Task Collection List  end-->
        )
    }
}

export default TaskCollectionList;