// import React from 'react';

export default class BrowserService {

    // var navigator = (typeof window === 'undefined' ? global: window).navigator

    getBrowser() {

        try {
            
            let sBrowser, sUsrAg = window.navigator.userAgent;

            if(sUsrAg.indexOf("Chrome") > -1) {
                sBrowser = "Google Chrome";
            } else if (sUsrAg.indexOf("Safari") > -1) {
                sBrowser = "Apple Safari";
            } else if (sUsrAg.indexOf("Opera") > -1) {
                sBrowser = "Opera";
            } else if (sUsrAg.indexOf("Firefox") > -1) {
                sBrowser = "Mozilla Firefox";
            } else if (sUsrAg.indexOf("MSIE") > -1) {
                sBrowser = "Microsoft Internet Explorer";
            }

    
            return sBrowser;

        }
        catch (error) {
            return 'Default Browser';
        }
    }


    getLocation() {

        try {
            
            if ("geolocation" in window.navigator) {
                /* geolocation is available */


                function success(position) {
                    let latitude  = position.coords.latitude;
                    let longitude = position.coords.longitude;
                    return 'latitude: ' + latitude + ', longitude: ' + longitude;
                
                  }
                
                  function error() {
                    return 'Default Location';
                  }
                
                
                  window.navigator.geolocation.getCurrentPosition(success, error);

              } else {
                /* geolocation IS NOT available */
                return 'Default Location';
              }

        }
        catch (error) {
            return 'Default Location';
        }
    }



    getOS() {

        try {

            let sOS = 'Default OS';
            
            // "MacIntel", "Win32", "FreeBSD i386", "WebTV OS"

            if (typeof window.navigator.oscpu === 'undefined'){

                sOS = window.navigator.platform;

                switch (sOS) {
                    case 'Android':
                    case 'Linux armv7l':
                    case 'Linux i686':
                    case null:
                        sOS = "Android OS";
                        break;
        
                    case 'iPhone':
                    case 'iPhone Simulator':
                    case 'Pike v7.6 release 92':
                    case 'Pike v7.8 release 517':
                        sOS = "iPhone iOS";
                        break;
        
                    case 'iPod':
                    case 'iPod Simulator':
                        sOS = "iPod iOS";
                        break;

                    case 'iPad':
                    case 'iPad Simulator':
                        sOS = "iPad iOS";
                        break;

                    case 'Macintosh':
                    case 'MacIntel':
                    case 'MacPPC':
                    case 'Mac68K':
                        sOS = "macOS";
                        break;

                    case 'BlackBerry':
                        sOS = "BlackBerry";
                        break;

                    case 'FreeBSD':
                    case 'FreeBSD i386':
                    case 'FreeBSD amd64':
                        sOS = "FreeBSD";
                        break;

                    case 'Linux':
                    case 'Linux aarch64':
                    case 'Linux armv5tejl':
                    case 'Linux armv6l':
                    case 'Linux i686 on x86_64':
                    case 'Linux i686 X11':
                    case 'Linux MSM8960_v3.2.1.1_N_R069_Rev':
                    case 'Linux ppc64':
                    case 'Linux x86_64':
                    case 'Linux x86_64 X11':
                        sOS = "Linux OS";
                        break;
        
                    case 'OS/2':
                    case 'Pocket PC':
                    case 'Windows':
                    case 'Win16':
                    case 'Win32':
                    case 'WinCE':
                        sOS = "Windows OS";
                        break;

                    case '':
                        sOS = "Firefox OS";
                        break;

                    case 'New Nintendo 3DS':
                    case 'Nintendo DSi':
                    case 'Nintendo 3DS':
                    case 'Nintendo Wii':
                    case 'Nintendo WiiU':
                        sOS = "Nintendo OS";
                        break;

                    case 'OpenBSD':
                    case 'OpenBSD amd64':
                        sOS = "OpenBSD";
                        break;

                    case 'Nokia_Series_40':
                    case 'S60':
                    case 'Symbian':
                    case 'Symbian OS':
                        sOS = "Symbian/S40 OS";
                        break;

                    case 'Palm':
                    case 'PalmOS':
                    case 'webOS':
                        sOS = "Palm OS";
                        break;

                    case 'SunOS':
                    case 'SunOS i86pc':
                    case 'SunOS sun4u':
                        sOS = "Solaris OS";
                        break;

                    case 'Sony':
                    case 'PLAYSTATION 3':
                    case 'PlayStation 4':
                    case 'PSP':
                        sOS = "Sony Playstation OS";
                        break;

                    case 'HP-UX':
                        sOS = "Hewlett-Packard UniX";
                        break;

                    case 'masking-agent':
                        sOS = "Firefox";
                        break;

                    case 'WebTV OS':
                        sOS = "WebTV OS";
                        break;

                    case 'X11':
                        sOS = "X11 Window System";
                        break;

                    default:
                        sOS = "Default OS";
                }


            } else {
                sOS = window.navigator.oscpu;

            }

            return sOS;
            
        }
        catch (error) {
            return 'Default OS';
        }
    }



    getLanguage() {

        try {

            // Get language
            let sLanguage = window.navigator.userLanguage || window.navigator.language;


            if (sLanguage !== null  && typeof sLanguage !== 'undefined') {

                // Get primary standard
                sLanguage = sLanguage.substring( 0, 2 );

                return sLanguage;

            } else {
                return 'en';
            }

        }
        catch (error) {
            return 'en';
        }
    }

  
  }