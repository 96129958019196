import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');

class OrphanedRecordEntries extends Component {
  constructor(props) {
    super(props);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getOrphanedRecordEntries = this.getOrphanedRecordEntries.bind(this);
    this.getMoreRecords = this.getMoreRecords.bind(this);
    this.state = {
      orphanedrecordentries: null,
      numberOfRecords: 0,
      currentNumberOfRecords: 300,
      paginationItem: null,
      result: '',
      message: '',
      token: '',
      preloader: true,
      orphanedRecordEntriesDataItems: null,
      orphaned_record_entries_create: null,
      orphaned_record_entries_edit: null,
      scopes: {},
      paginationGetMoreDataButton: null
    };
    this.Auth = new AuthService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.DateService = new DateService();
    this.apiURL = URLService.prototype.getApiURL();
    this.returnURLQuery = URLService.prototype.getReturnURLQuery();
  }

  componentWillMount() {
    this.checkAccessRight();
    this.getProfile();
  }

  componentDidMount() {
    document.title = this.Language.PageTitle.OrphanedRecordEntries + ' - ' + this.Language.EEGWebPortal;
    // this.checkAccessRight();
    this.getAccessScopes();

    this.getOrphanedRecordEntries(this.state.currentNumberOfRecords);
    this.setState({
      paginationGetMoreDataButton: (
        <li className="input-field" onClick={this.getMoreRecords}>
          <a href={'#!'} className="waves-effect waves-teal teal-text">
            {this.Language.Pagination.GetData}
            <i className="material-icons right">refresh</i>
          </a>
        </li>
      )
    });
  }

  checkAccessRight() {
    let default_home = '/';
    const dashboards = this.Auth.getDashboards() || [];
    if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${'5'}`;

    if (this.Auth.checkAccessScope(['read:orphaned_records', 'action:orphaned_records'], 'view') === false) {
      this.props.history.replace(default_home);
    }
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState({scopes: user_profile.scopes});
    }
  }

  getAccessScopes() {
    // Check the views scope
    if (this.state.scopes['views']) {
      let len = this.state.scopes['views'].length;

      for (let i = 0; i < len; i++) {
        if (this.state.scopes['views'][i] === 'create:orphaned_records' || this.state.scopes['views'][i] === 'action:orphaned_records') {
          this.setState({orphaned_record_entries_create: true});
        }
        if (this.state.scopes['views'][i] === 'create:orphaned_records' || this.state.scopes['views'][i] === 'update:orphaned_records' || this.state.scopes['views'][i] === 'action:orphaned_records') {
          this.setState({orphaned_record_entries_edit: true});
        }
      }
    }
  }

  getMoreRecords() {
    let currentNumberOfRecords = $('#current_number_of_records').val();

    this.setState({
      orphanedRecordEntriesDataItems: null,
      paginationItem: null,
      orphanedrecordentries: null,
      currentNumberOfRecords: currentNumberOfRecords,
      preloader: true
    });

    this.getOrphanedRecordEntries(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input
  }

  getOrphanedRecordEntries(currentNumberOfRecords) {
    const table_name = this.props.match.params.table_name;
    let api_route = '/api/v1/data/management/orphaned/records/ue/table/';

    const encrypted_tables = ['user_accounts', 'organisations', 'exporters', 'eeg_claims'];
    // Check if the table is a table with encrypted columns
    const check_encrypted_len = encrypted_tables.length;
    for (let i = 0; i < check_encrypted_len; i++) {
      if (table_name === encrypted_tables[i]) {
        api_route = '/api/v1/data/management/orphaned/records/e/table/';
        break;
      }
    }

    const unencrypted_tables = [
      'login_configurations',
      'modules',
      'audit_activities',
      'access_rights',
      'data_field_security',
      'notifications',
      'objects',
      'password_change_history',
      'password_configurations',
      'system_access_right_objects',
      'system_parameters',
      'tenant',
      'user_account_device_history',
      'user_account_devices',
      'user_accounts',
      'organisations',
      'exporters',
      'eeg_claims',
      'online_users',
      'user_signins'
    ];
    // Check if the table is a table with all unencrypted columns
    const check_unencrypted_len = unencrypted_tables.length;
    for (let i = 0; i < check_unencrypted_len; i++) {
      if (table_name === unencrypted_tables[i]) {
        api_route = '/api/v1/data/management/orphaned/records/ue/table/';
        break;
      }
    }

    if (api_route !== '') {
      const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
      if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

      axios
        .get(`${this.apiURL}${api_route}${table_name}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
        .then(response => {
          this.setState({numberOfRecords: response.data.data_count}); // count of the total result expected. especially if not all the records are retrieve at a go.

          this.setState({orphanedrecordentries: response.data.data}, () => {
            this.setState({
              orphanedRecordEntriesDataItems: (
                <DataTable
                  tableName={''}
                  fields={['orphaned_record_type', 'id']}
                  fieldDataTypes={['String', 'String']}
                  fieldDecimalPlaces={[0, 0]}
                  dateFormat={'long_date'}
                  timeFormat={'h12'}
                  headers={[this.Language.DataTableHeader.RecordType, this.Language.DataTableHeader.ID]}
                  data={this.state.orphanedrecordentries}
                  sortType={'ascending'}
                  sortPosition={1}
                  thStyle={[696, 369]}
                  link={''}
                  return_query_prefix={this.returnURLQuery}
                  current_path={this.props.history.location.pathname}
                  link_status={false}
                  linkToBlankTarget={false}
                  linkToBlankTargetToken={''}
                  download={false}
                  downloadFileName={this.Language.DownloadFileName.OrphanedRecordEntries}
                  emptyMessage={this.Language.EmptyMessage.OrphanedRecordEntries}
                  downloadFields={['orphaned_record_type', 'id']}
                />
              )
            });

            this.setState({
              paginationItem: (
                <PaginationGetDataLimit
                  button={this.state.paginationGetMoreDataButton}
                  numberOfRecords={this.state.numberOfRecords}
                  currentNumberOfRecords={this.state.currentNumberOfRecords}
                  language={this.Language}
                />
              )
            });
            this.setState({preloader: null});
          });

          this.setState({result: response.data.status}, () => {
            
          });

          this.setState({message: response.data.message}, () => {
            
          });
        })
        .catch(function(error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            

            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            
          } else {
            // Something happened in setting up the request that triggered an Error
            
          }
          
        });
    }
  }

  render() {
    const table_name = this.props.match.params.table_name;

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Data Management'} active_menu_level_2={'Orphaned Records'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.OrphanedRecordEntries}: ${table_name}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.DataManagement, this.Language.BreadCrumbLabel.OrphanedRecords, this.Language.BreadCrumbLabel.OrphanedRecordEntries]}
              links={[`/data/management/tables`, `/data/management/orphaned/records`, `/data/management/orphaned/records`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <br />
              {this.state.preloader && (
                <div className="progress teal lighten-4">
                  <div className="indeterminate teal"></div>
                </div>
              )}
              {this.state.orphanedRecordEntriesDataItems}
              <br />
              <br />
              {this.state.paginationItem}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default OrphanedRecordEntries;
