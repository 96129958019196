import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');


class Users extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            users: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 200,
            paginationItem: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            userDataItems: null,
            users_create: null,
            users_edit: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.UserAccounts + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();

        this.getUsers(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:users', 'action:users'], 'view') === false) {
            this.props.history.replace(default_home);
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ scopes: user_profile.scopes });
        }

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:users' || this.state.scopes['views'][i] === 'action:users') {
                    this.setState({ users_create: true });
                }
                if (this.state.scopes['views'][i] === 'update:users' || this.state.scopes['views'][i] === 'action:users') {
                    this.setState({ users_edit: true });
                }
            }
        };


    }


    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            userDataItems: null,
            paginationItem: null,
            users: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getUsers(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }




    getUsers(currentNumberOfRecords) {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/users?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ users: response.data.data }, () => {

                    this.setState({
                        userDataItems: <DataTable tableName={''}
                            fields={['username', 'first_name', 'last_name', 'exporter_name', 'organisation_name', 'email', 'status']}
                            fieldDataTypes={['String', 'String', 'String', 'String', 'String', 'String', 'Status']}
                            fieldDecimalPlaces={[0, 0, 0, 0, 0, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.Username, this.Language.DataTableHeader.FirstName, this.Language.DataTableHeader.LastName, this.Language.DataTableHeader.Exporter, this.Language.DataTableHeader.PartnerAgency, this.Language.DataTableHeader.Email, this.Language.DataTableHeader.Status]}
                            data={this.state.users}
                            sortType={"ascending"} sortPosition={2} thStyle={[151, 221, 164, 274, 125, 100, 50]}
                            link={"/users/"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={this.state.users_edit} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.UserAccounts} emptyMessage={this.Language.EmptyMessage.UserAccounts}
                            downloadFields={['username', 'title', 'prefix', 'first_name', 'middle_name', 'last_name', 'suffix', 'exporter_name', 'organisation_name', 'email', 'status', 'internal_user', 'partner_user', 'user_role', 'user_type', 'new_date_of_registration', 'phone', 'email', 'language_id', 'enable_sms', 'enable_email', 'enable_mobile_app', 'enable_offline_user', 'enable_notifications', 'receive_notification_as_sms', 'receive_notification_as_email', 'is_approved', 'approved_by', 'new_approved_at']} />
                    })

                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });
                })

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    render() {

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"User Management"} active_menu_level_2={"User Accounts"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.UserAccounts}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.UserAccounts]} links={[`/users`, `/users`]} button_link={'/users/add'} button_icon={'person_add'} button_name={'Create Account'} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                {(this.state.users_create) && <Link className="waves-effect waves-light btn right" to={`/users/add`}><i className="material-icons left">person_add</i>{this.Language.ButtonLabel.CreateAccount}</Link>}
                                <br />
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.userDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default Users;