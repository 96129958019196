import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.addUser = this.addUser.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.getDropdownValues = this.getDropdownValues.bind(this);
    this.handlePasswordVisibility = this.handlePasswordVisibility.bind(this);
    this.state = {
      message: '',
      status: '',
      pick_list_values: [],
      pick_list_values_time_zone: [],
      select_field_values_user_role: [],
      select_field_values_user_type: [],
      select_field_values_exporter: [],
      select_field_values_organisation: [],
      select_field_values_title: [],
      select_field_values_language: [],
      select_field_values_time_zone_region: [],
      preloader: true,
      password_field_type: 'password',
      default_password: 'NewPassword123',
      first_time_page_load: true,
      hash_user: '',
      logged_in_username: '',
      text_limit: 300,
      error_messages: null,
      close_error_messages: (
        <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
          <span aria-hidden="true">×</span>
        </button>
      )
    };
    this.Auth = new AuthService();
    this.Validate = new ValidationService();
    this.DateService = new DateService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.apiURL = URLService.prototype.getApiURL();
  }

  componentWillMount() {}

  componentDidMount() {
    document.title = this.Language.PageTitle.CreateUserAccount + ' - ' + this.Language.EEGWebPortal;
    this.getProfile();

    const monthsFull = this.Language.DatePicker.monthsFull.split(',');
    const monthsShort = this.Language.DatePicker.monthsShort.split(',');
    const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(',');
    const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(',');
    const today = this.Language.DatePicker.today;
    const clear = this.Language.DatePicker.clear;
    const close = this.Language.DatePicker.close;
    const labelMonthNext = this.Language.DatePicker.labelMonthNext;
    const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
    const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
    const labelYearSelect = this.Language.DatePicker.labelYearSelect;

    window.$('.datepicker').pickadate({
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      today: today,
      clear: clear,
      close: close,
      labelMonthNext: labelMonthNext,
      labelMonthPrev: labelMonthPrev,
      labelMonthSelect: labelMonthSelect,
      labelYearSelect: labelYearSelect
      // firstDay: 1,
      // format: 'dd mmmm yyyy',
      // formatSubmit: 'yyyy/mm/dd',
      // selectMonths: true,
      // selectYears: true,
      // selectMonths: true, // Creates a dropdown to control month
      // selectYears: 15, // Creates a dropdown of 15 years to control year,
      // closeOnSelect: false, // Close upon selecting a date,
      // container: undefined, // ex. 'body' will append picker to body
    });

    this.setState({pick_list_values_time_zone: this.DateService.getTimezoneList(this.Language.Lang)});
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    $('select').material_select('destroy');
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState(
        {
          logged_in_username: user_profile.username,
          hash_user: user_profile.user
        },
        () => {
          this.checkAccessRight();
        }
      );
    }
  }

  checkAccessRight() {
    if (this.Auth.checkAccessScope(['read:users', 'action:users'], 'view') === false || this.state.hash_user === this.props.match.params.id) {
      this.props.history.replace('/users');
    } else {
      this.getDropdownValues('user_role,user_type,title,language,exporter,organisation');
    }
  }

  handleCloseAlert() {
    this.setState({error_messages: null}); // change the value of error_message to close the alert box
  }

  onSubmit(event) {
    event.preventDefault();
    this.prepareData('return_to_main_page');
  }

  prepareData(return_option) {

    this.setState({first_time_page_load: false}, () => {
      let username = this.refs.username.value.trim();
      let user_role = this.refs.user_role.value;
      if (user_role === 'Select User Role') user_role = '';

      let exporter = this.refs.exporter.value;
      if (exporter === 'Select Exporter' && this.refs.partner_user.checked === false && this.refs.internal_user.checked === false) exporter = '';

      let partner = this.refs.partner.value;
      if (partner === 'Select Partner Agency' && (this.refs.partner_user.checked === true || this.refs.internal_user.checked === true)) partner = '';

      let title = this.refs.title.value;
      if (title === 'Select Title') title = null;

      let prefix = this.refs.prefix.value;
      let suffix = this.refs.suffix.value;
      let first_name = this.refs.first_name.value;
      let middle_name = this.refs.middle_name.value;
      let last_name = this.refs.last_name.value;


      let date_of_registration = this.DateService.convertToFullDate(this.refs.date_of_registration.value);

      let language = this.refs.language.value;
      if (language === 'Select Language') language = '';

      let time_zone_region = this.refs.time_zone_region.value;
      if (time_zone_region === 'Select Default Time Zone') time_zone_region = '';

      let email = this.refs.email.value.trim();
      let phone = this.refs.phone.value.trim();

      let email_signature = this.refs.email_signature.value;
      let user_type = this.refs.user_type.value;
      if (user_type === 'Select User Type') user_type = '';

      let internal_user = this.refs.internal_user.checked;
      let partner_user = this.refs.partner_user.checked;
      let enable_sms = this.refs.enable_sms.checked;
      let enable_email = this.refs.enable_email.checked;
      let enable_mobile_app = this.refs.enable_mobile_app.checked;
      let enable_offline_user = this.refs.enable_offline_user.checked;

      let status = 'Inactive';
      if (this.refs.activate_switch.checked) status = 'Active';
      if (!this.refs.activate_switch.checked) status = 'Inactive';

      let is_frozen = this.refs.is_frozen.checked;
      let is_password_locked = this.refs.is_password_locked.checked;
      let password_change = this.refs.password_change.checked;
      let two_step_verification = this.refs.two_step_verification.checked;

      let sign_in_method = null;
      if (this.refs.signin_security_questions.checked) sign_in_method = 'Security Question';
      if (this.refs.signin_code.checked) sign_in_method = 'Code';
      if (this.refs.signin_na.checked) sign_in_method = null;

      let enable_notifications = this.refs.enable_notifications.checked;
      let receive_notification_as_sms = this.refs.receive_notification_as_sms.checked;
      let receive_notification_as_email = this.refs.receive_notification_as_email.checked;

      let use_default_password = false;
      if (this.refs.use_default_password.checked) use_default_password = true;

      let password = this.refs.password.value;
      let is_approved = this.refs.approve_account.checked;
      let approved_by = null;
      if (is_approved === true || is_approved === 'true') approved_by = this.state.logged_in_username;

      if (password !== this.state.default_password) use_default_password = false;

      let created_by = this.state.logged_in_username;
      let last_updated_by = this.state.logged_in_username;

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.UserAccount.AddErrorTitle,
            [username, user_role, exporter, partner, first_name, last_name, date_of_registration, email, email, phone, phone, user_type, password, language, time_zone_region, email_signature],
            [
              this.Language.DataField.Username,
              this.Language.DataField.UserRole,
              this.Language.DataField.Exporter,
              this.Language.DataField.PartnerAgency,
              this.Language.DataField.FirstName,
              this.Language.DataField.LastName,
              this.Language.DataField.DateofRegistration,
              this.Language.DataField.Email,
              this.Language.DataField.Email,
              this.Language.DataField.Phone,
              this.Language.DataField.Phone,
              this.Language.DataField.UserType,
              this.Language.DataField.Password,
              this.Language.DataField.Language,
              this.Language.DataField.DefaultTimeZone,
              this.Language.DataField.EmailSignature
            ],
            [
              'isRequired',
              'isRequired',
              'isRequired',
              'isRequired',
              'isRequired',
              'isRequired',
              'isRequiredDate',
              'isRequired',
              'isEmail',
              'isRequired',
              'isPhone',
              'isRequired',
              'isRequired',
              'isRequired',
              'isRequired',
              'maxLength'
            ],
            0,
            this.state.text_limit
          )
        },
        () => {
          if (this.state.error_messages === null) {
            if (exporter === 'Select Exporter') exporter = null;
            if (partner === 'Select Partner Agency') partner = null;

            if (language === 'Select Language') language = null;
            if (time_zone_region === 'Select Default Time Zone') time_zone_region = null;

            const userOS = this.Browser.getOS();

            //make call to database
            const newUserAccount = {
              username: username,
              user_role: user_role,
              exporter_id: exporter,
              partner_id: partner,
              title: title,
              prefix: prefix,
              suffix: suffix,
              first_name: first_name,
              middle_name: middle_name,
              last_name: last_name,
              date_of_registration: date_of_registration,
              language_id: language,
              time_zone_region: time_zone_region,
              email: email,
              phone: phone,
              email_signature: email_signature,
              user_type: user_type,
              internal_user: internal_user,
              partner_user: partner_user,
              enable_sms: enable_sms,
              enable_email: enable_email,
              enable_mobile_app: enable_mobile_app,
              enable_offline_user: enable_offline_user,
              status: status,
              is_frozen: is_frozen,
              is_password_locked: is_password_locked,
              password_change: password_change,
              two_step_verification: two_step_verification,
              sign_in_method: sign_in_method,
              enable_notifications: enable_notifications,
              receive_notification_as_sms: receive_notification_as_sms,
              receive_notification_as_email: receive_notification_as_email,
              use_default_password: use_default_password,
              password: password,
              is_approved: is_approved,
              approved_by: approved_by,
              created_by: created_by,
              last_updated_by: last_updated_by,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'web',
              token: this.Auth.getToken(),
              last_password_change: date_of_registration,
              profile_is_visible: true,
              password_expiry_date: date_of_registration
            };


            this.setState({preloader: true, first_time_page_load: false});
            document.getElementById('save_user_account_button').setAttribute('disabled', true);

            this.addUser(newUserAccount, return_option);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    });
  }

  clearForm() {
    document.getElementById('add_user_account_form').reset();

    $('.switch')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.password_change')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.enable_notifications')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.approve_account')
      .find('input[type=checkbox]')
      .prop('checked', true);
    $('.use_default_password')
      .find('input[type=checkbox]')
      .prop('checked', true);
  }

  getDropdownValues(pick_list_names) {
    axios
      .get(`${this.apiURL}/api/v1/settings/picklists`, {
        params: {
          tk: this.Auth.getToken(),
          pick_list_names: pick_list_names
        }
      })
      .then(response => {
        this.setState({pick_list_values: response.data.data}, () => {

          if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {
            const select_field_values_user_role = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'user_role') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_user_role: select_field_values_user_role});

            const select_field_values_user_type = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'user_type') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_user_type: select_field_values_user_type});

            const select_field_values_exporter = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'exporter') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_exporter: select_field_values_exporter});

            const select_field_values_organisation = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'organisation') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_organisation: select_field_values_organisation});

            const select_field_values_title = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'title') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_title: select_field_values_title});

            const select_field_values_language = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'language') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_language: select_field_values_language});

            const select_field_values_time_zone_region = this.state.pick_list_values_time_zone.map((pick_list_value, i) => {
              return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
            });

            this.setState({select_field_values_time_zone_region: select_field_values_time_zone_region});
          }
        });

        window.$('select').material_select();
        this.setState({preloader: null});
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null});
      });
  }

  addUser(newUserAccount, return_option) {
    axios
      .request({
        method: 'post',
        url: `${this.apiURL}/api/v1/users`,
        data: newUserAccount
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {

          if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.UserAccount.AddSuccess + newUserAccount.username, 8000, 'rounded green');
        });

        this.setState({preloader: null});
        document.getElementById('save_user_account_button').removeAttribute('disabled');

        if (return_option === 'return_to_main_page') {
          this.props.history.push('/users');
        } else {
          this.clearForm();
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response) {


          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.UserAccount.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserAccount.AlreadyExist, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null});
        document.getElementById('save_user_account_button').removeAttribute('disabled');
      });
  }

  handlePasswordVisibility(event) {

    this.setState({first_time_page_load: false})

      const target = event.target;
      const checked = target.checked;
      const name = target.id;

      const default_password_use_check = this.refs.use_default_password.checked;
      const password = this.refs.password.value;

      if (name === 'view_password') {
        if (checked === true) this.setState({password_field_type: 'text'});
        if (checked === false) this.setState({password_field_type: 'password'});
      }

      if (name === 'use_default_password') {
        if (checked === true) {
          $('#password').val(this.state.default_password);
          this.setState({password_field_type: 'text'});
        }
      }

      if (name === 'password' && password !== this.state.default_password) {
        $('.use_default_password')
          .find('input[type=checkbox]')
          .prop('checked', false);
      }

      if (default_password_use_check === true && password !== this.state.default_password) {
        $('.use_default_password')
          .find('input[type=checkbox]')
          .prop('checked', false);
      }

      if (default_password_use_check === false && password !== this.state.default_password) {
        $('.use_default_password')
          .find('input[type=checkbox]')
          .prop('checked', false);
      }
  }

  render() {
    if (this.state.first_time_page_load) {
      $('.switch')
        .find('input[type=checkbox]')
        .prop('checked', true);
      $('.password_change')
        .find('input[type=checkbox]')
        .prop('checked', true);
      $('.enable_notifications')
        .find('input[type=checkbox]')
        .prop('checked', true);
      $('.approve_account')
        .find('input[type=checkbox]')
        .prop('checked', true);
      $('.use_default_password')
        .find('input[type=checkbox]')
        .prop('checked', true);
    }


    // $('#select-options-user_role').on('change', 'select', function(){  });

    const lengthCheck = 25;

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'User Management'} active_menu_level_2={'User Accounts'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.CreateUserAccount}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.UserAccounts, this.Language.BreadCrumbLabel.AddUserAccount]}
              links={[`/users`, `/users`, `/users`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <div className="row">
                <div className="col s12 l12">
                  {this.state.error_messages}

                  <div className="card-panel">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <h4 className="header2">{this.Language.PageLabel.AddANewUserAccount}</h4>
                    <div className="row">
                      <form className="col s12" id="add_user_account_form" ref="add_user_account_form" onSubmit={this.onSubmit.bind(this)}>
                        <div className="row">
                          <div className="input-field col s6">
                            <i className="material-icons prefix">account_circle</i>
                            <input id="username" name="username" type="text" ref="username" />
                            <label htmlFor="username" className="">
                              {this.Language.DataField.Username} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select className="initialized" name="user_role" id="user_role" ref="user_role">
                                <option value="Select User Role">{this.Language.DataFieldDefault.SelectUserRole}</option>
                                {this.state.select_field_values_user_role.map((user_role_value, i) => {
                                  return user_role_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.UserRole} *</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s8">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-exporter" className="initialized" name="exporter" ref="exporter">
                                <option value="Select Exporter">{this.Language.DataFieldDefault.SelectExporter}</option>
                                {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                  return exporter_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.Exporter} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s4">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              {/* <input type="text" className="select-dropdown"  readOnly="true" data-activates="select-options-partner"/> */}
                              <select data-select-id="select-options-partner" className="initialized" name="partner" ref="partner">
                                <option value="Select Partner Agency">{this.Language.DataFieldDefault.SelectPartnerAgency}</option>
                                {this.state.select_field_values_organisation.map((organisation_value, i) => {
                                  return organisation_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.Partner} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s4">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              {/* <input type="text" className="select-dropdown"  readOnly="true" data-activates="select-options-title"/> */}
                              <select data-select-id="select-options-title" className="initialized" name="title" ref="title">
                                <option value="Select Title">{this.Language.DataFieldDefault.SelectTitle}</option>
                                {this.state.select_field_values_title.map((title_value, i) => {
                                  return title_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.Title}
                              {/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s4">
                            <input id="prefix" type="text" ref="prefix" />
                            <label htmlFor="prefix" className="">
                              {this.Language.DataField.Prefix}
                              {/* ::after */}
                            </label>
                          </div>
                          {/* ::after */}
                          <div className="input-field col s4">
                            <input id="suffix" type="text" ref="suffix" />
                            <label htmlFor="suffix" className="">
                              {this.Language.DataField.Suffix}
                              {/* ::after */}
                            </label>
                          </div>
                          {/* ::after */}
                        </div>
                        <div className="row">
                          <div className="input-field col s6">
                            <input id="first_name" type="text" ref="first_name" />
                            <label htmlFor="first_name">
                              {this.Language.DataField.FirstName} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input id="middle_name" type="text" ref="middle_name" />
                            <label htmlFor="middle_name">
                              {this.Language.DataField.MiddleName}
                              {/* ::after */}
                            </label>
                          </div>
                          {/* ::after */}
                        </div>
                        <div className="row">
                          <div className="input-field col s8">
                            <input id="last_name" type="text" ref="last_name" />
                            <label htmlFor="last_name">
                              {this.Language.DataField.LastName} *{/* ::after */}
                            </label>
                          </div>
                          {/* ::after */}
                        </div>
                        <div className="row">
                          <div className="input-field col s8">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="date_of_registration"
                              readOnly={false}
                              id="P875200303"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="P875200303_root"
                            />
                            <label htmlFor="date_of_registration">
                              {this.Language.DataField.DateofRegistration} *{/* ::after */}
                            </label>
                          </div>
                          {/* ::after */}
                          <div className="input-field col s4">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              {/* <input type="text" className="select-dropdown" readOnly="true" data-activates="select-options-language"/> */}
                              <select data-select-id="select-options-language" ref="language" name="language" className="initialized">
                                <option value="Select Language">{this.Language.DataFieldDefault.SelectLanguage}</option>
                                {this.state.select_field_values_language.map((language_value, i) => {
                                  return language_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.Language}
                              {/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s8">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-time_zone_region" ref="time_zone_region" name="time_zone_region" className="initialized">
                                <option value="Select Default Time Zone">{this.Language.DataFieldDefault.SelectDefaultTimeZone}</option>
                                {this.state.select_field_values_time_zone_region.map((timezone_value, i) => {
                                  return timezone_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.DefaultTimeZone}
                              {/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <h6>
                          <b>{this.Language.PageLabel.ContactDetails}</b>
                        </h6>
                        <div className="row">
                          <div className="input-field col s6">
                            <input id="email" type="email" className="validate" ref="email" />
                            <label htmlFor="email" data-error={this.Language.DataFieldError.Email} className="">
                              {this.Language.DataField.Email} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input id="phone" type="tel" className="validate" ref="phone" />
                            <label htmlFor="phone" data-error={this.Language.DataFieldError.Phone} className="">
                              {this.Language.DataField.Phone} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s12">
                            <textarea id="email_signature" className="materialize-textarea" data-length={this.state.text_limit} ref="email_signature"></textarea>
                            <label htmlFor="email_signature" data-error={this.Language.DataFieldError.MaxLength}>
                              {this.Language.DataField.EmailSignature}
                              {/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <h6>
                          <b>{this.Language.PageLabel.OtherDetailsandSettings}</b>
                        </h6>
                        <br />
                        <div className="row">
                          <div className="input-field col s5">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              {/* <input type="text" className="select-dropdown" readOnly="true" data-activates="select-options-user_type"/> */}
                              <select data-select-id="select-options-user_type" className="initialized" name="user_type" ref="user_type">
                                <option value="Select User Type">{this.Language.DataFieldDefault.SelectUserType}</option>
                                {this.state.select_field_values_user_type.map((user_type_value, i) => {
                                  return user_type_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.UserType} *{/* ::after */}
                            </label>
                          </div>
                          <div className="col s1">{/* this div is used to give a space between the elements */}</div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="internal_user" ref="internal_user" />
                              <label htmlFor="internal_user">{this.Language.DataField.InternalUser}</label>
                            </p>
                          </div>
                          <div className="col s3 right">
                            <p>
                              <input type="checkbox" id="partner_user" ref="partner_user" />
                              <label htmlFor="partner_user">{this.Language.DataField.PartnerUser}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="enable_sms" ref="enable_sms" name="enable_sms" />
                              <label htmlFor="enable_sms">{this.Language.DataField.EnableSMS}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="enable_email" ref="enable_email" name="enable_email" />
                              <label htmlFor="enable_email">{this.Language.DataField.EnableEmail}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="enable_mobile_app" ref="enable_mobile_app" name="enable_mobile_app" />
                              <label htmlFor="enable_mobile_app">{this.Language.DataField.EnableMobileApp}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="enable_offline_user" ref="enable_offline_user" name="enable_offline_user" />
                              <label htmlFor="enable_offline_user">{this.Language.DataField.EnableOfflineUsage}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col s3">
                            <br />
                            <div className="switch">
                              <label className="font-weight-600 black-text">
                                {this.Language.DataField.DeactivateAccount}
                                <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                <span className="lever"></span>
                                {this.Language.DataField.ActivateAccount}
                              </label>
                            </div>
                          </div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="is_frozen" ref="is_frozen" />
                              <label htmlFor="is_frozen">{this.Language.DataField.AccountFrozen}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="is_password_locked" ref="is_password_locked" />
                              <label htmlFor="is_password_locked">{this.Language.DataField.PasswordLocked}</label>
                            </p>
                          </div>
                          <div className="password_change col s3">
                            <p>
                              <input type="checkbox" id="password_change" ref="password_change" />
                              <label htmlFor="password_change">{this.Language.DataField.RequirePasswordChangeOnFirstLogin}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s4">
                            <br />
                            <p>
                              <input type="checkbox" id="two_step_verification" ref="two_step_verification" name="two_step_verification" />
                              <label htmlFor="two_step_verification">{this.Language.DataField.EnableTwoFactorAthentication}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <label htmlFor="signin_select">{this.Language.DataField.TwoFactorSigninMethod}</label>
                            <p>
                              <input name="signin_method" type="radio" id="signin_security_questions" ref="signin_security_questions" />
                              <label htmlFor="signin_security_questions">{this.Language.DataField.SecurityQuestion}</label>
                            </p>
                          </div>
                          <div className="col s3 center">
                            <br />
                            <p>
                              <input name="signin_method" type="radio" id="signin_code" ref="signin_code" />
                              <label htmlFor="signin_code">{this.Language.DataField.Code}</label>
                            </p>
                          </div>
                          <div className="col s2">
                            <br />
                            <p>
                              <input name="signin_method" type="radio" id="signin_na" ref="signin_na" />
                              <label htmlFor="signin_na">{this.Language.DataField.None}</label>
                            </p>
                          </div>
                          {/* ::after */}
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s4 enable_notifications">
                            <p>
                              <input type="checkbox" id="enable_notifications" ref="enable_notifications" />
                              <label htmlFor="enable_notifications">{this.Language.DataField.EnableNotifications}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="receive_notification_as_sms" ref="receive_notification_as_sms" />
                              <label htmlFor="receive_notification_as_sms">{this.Language.DataField.ReceiveNotificationsAsSMS}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="receive_notification_as_email" ref="receive_notification_as_email" />
                              <label htmlFor="receive_notification_as_email">{this.Language.DataField.ReceiveNotificationsAsEmail}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              id="password"
                              type={this.state.password_field_type}
                              ref="password"
                              name="password"
                              defaultValue={this.state.default_password}
                              onChange={this.handlePasswordVisibility}
                            />
                            <label htmlFor="password">
                              {this.Language.DataField.Password}
                              {/* ::after */}
                            </label>
                          </div>
                          <div className="col s6 view_password">
                            <p>
                              <input type="checkbox" id="view_password" ref="view_password" onChange={this.handlePasswordVisibility} />
                              <label htmlFor="view_password">
                                <b>{this.Language.DataField.ViewPassword}</b>
                              </label>
                            </p>
                          </div>
                          <div className="col s6 use_default_password">
                            <p>
                              <input type="checkbox" id="use_default_password" ref="use_default_password" onChange={this.handlePasswordVisibility} />
                              <label htmlFor="use_default_password">
                                <b>{this.Language.DataField.UseDefaultPassword}</b>
                              </label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s6 approve_account">
                            <p>
                              <input type="checkbox" id="approve_account" ref="approve_account" />
                              <label htmlFor="approve_account">
                                <b>{this.Language.DataField.ApproveAccount}</b>
                              </label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className={this.Language.ButtonLabel.SaveUserAccount.length <= lengthCheck ? 'input-field col s5' : 'input-field col s7'}>
                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_user_account_button">
                              {this.Language.ButtonLabel.SaveUserAccount}
                            </button>
                            <Link
                              className="waves-effect waves-light btn grey lighten-3 cyan-text right"
                              to={`#!`}
                              onClick={() => {
                                this.prepareData('stay_on_current_page');
                              }}>
                              {this.Language.ButtonLabel.SaveAndNew}
                            </Link>
                          </div>
                          {/* ::after */}
                          <div className={this.Language.ButtonLabel.SaveUserAccount.length <= lengthCheck ? 'input-field col s7' : 'input-field col s5'}>
                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/users`}>
                              <i className="material-icons left">cancel</i>
                              {this.Language.ButtonLabel.Cancel}
                            </Link>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                      </form>
                      {/* ::after */}
                    </div>
                  </div>
                </div>
                {/* ::after */}
              </div>
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default AddUser;
