import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';




class SecuritySettings extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateSecuritySetting = this.updateSecuritySetting.bind(this);
        this.getSecuritySetting = this.getSecuritySetting.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.state = {
            username: '',
            hash_id: '',
            exporter_name: '',
            organisation_name: '',
            two_step_verification: false,
            sign_in_method: '',
            enable_mobile_app: false,
            enable_offline_user: false,
            signin_code: false,
            signin_security_questions: false,
            signin_na: false,
            status: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_user: ''
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.SecuritySettings + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getSecuritySetting();

    }



    componentDidUpdate() {

    }



    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:user', 'action:user'], 'view') === false || this.state.hash_user !== this.props.match.params.id)) {
            this.props.history.replace(`/user/profile/${this.state.hash_user}`);
        } else {

        };

    }





    getSecuritySetting() {

        let userId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/settings/security/${userId}?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    hash_id: response.data.data[0].hash_id,
                    username: response.data.data[0].username,
                    exporter_name: response.data.data[0].exporter_name || '',
                    organisation_name: response.data.data[0].organisation_name || '',
                    two_step_verification: (response.data.data[0].two_step_verification === true || response.data.data[0].two_step_verification === 'true') ? true : false || false,
                    sign_in_method: response.data.data[0].sign_in_method || '',
                    enable_mobile_app: (response.data.data[0].enable_mobile_app === true || response.data.data[0].enable_mobile_app === 'true') ? true : false || false,
                    enable_offline_user: (response.data.data[0].enable_offline_user === true || response.data.data[0].enable_offline_user === 'true') ? true : false || false,
                    status: response.data.data[0].status || '',
                });


                if (this.state.sign_in_method === 'Security Question') this.setState({ signin_security_questions: true });
                if (this.state.sign_in_method === 'Code') this.setState({ signin_code: true });
                if (this.state.sign_in_method === 'None' || this.state.sign_in_method === '' || this.state.sign_in_method === null || typeof this.state.sign_in_method === 'undefined')
                  this.setState({signin_na: true});



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                this.setState({ preloader: null });

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;

        if (name === 'signin_security_questions') {
            this.setState({
                signin_security_questions: true,
                signin_code: false,
                signin_na: false
            });
        }

        if (name === 'signin_code') {
            this.setState({
                signin_code: true,
                signin_security_questions: false,
                signin_na: false
            });
        }

        if (name === 'signin_na') {
            this.setState({
                signin_na: true,
                signin_security_questions: false,
                signin_code: false
            });
        }

        if (name === 'two_step_verification') { this.setState({ [name]: !this.state.two_step_verification }); }
        if (name === 'enable_mobile_app') { this.setState({ [name]: !this.state.enable_mobile_app }); }
        if (name === 'enable_offline_user') { this.setState({ [name]: !this.state.enable_offline_user }); }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.UpdateSecuritySetting}`)) {


            let username = this.state.username;
            let hash_id = this.state.hash_id;

            let two_step_verification = this.refs.two_step_verification.checked;

            let sign_in_method = 'Code';
            if (this.refs.signin_security_questions.checked) sign_in_method = 'Security Question';
            if (this.refs.signin_code.checked) sign_in_method = 'Code';
            if (this.refs.signin_na.checked) {
                sign_in_method = 'None';
                two_step_verification = false;
            }

            let enable_mobile_app = this.refs.enable_mobile_app.checked;
            let enable_offline_user = this.refs.enable_offline_user.checked;

            let last_updated_by = this.state.logged_in_username;



            const userOS = this.Browser.getOS();

            //make call to database 
            const newSecuritySetting = {
                hash_id: hash_id,
                username: username,
                two_step_verification: two_step_verification,
                sign_in_method: sign_in_method,
                enable_mobile_app: enable_mobile_app,
                enable_offline_user: enable_offline_user,
                last_updated_by: last_updated_by,
                device_name: this.state.logged_in_username + '\'s ' + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: "web",
                token: this.Auth.getToken(),
            }




            this.setState({ preloader: true });
            document.getElementById("update_security_setting_button").setAttribute("disabled", true);

            this.updateSecuritySetting(newSecuritySetting);

        }

    }




    updateSecuritySetting(newSecuritySetting) {

        let userId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/settings/security/${userId}`,
            data: newSecuritySetting
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.SecuritySetting.UpdateSuccess + newSecuritySetting.username, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_security_setting_button").removeAttribute("disabled");

            // this.props.history.push(`/user/profile/${this.state.hash_user}`);


        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {


                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SecuritySetting.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.SecuritySetting.DoesNotExist, 8000, 'rounded deep-orange darken-4');

                    }



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_security_setting_button").removeAttribute("disabled");

            });

    }




    render() {




        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Security Settings"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.SecuritySettings}`} breadcrumbs={[this.Language.BreadCrumbLabel.Profile, this.Language.BreadCrumbLabel.SecuritySettings]} links={[`/user/profile/${this.state.hash_user}`, `/settings/security/${this.state.hash_user}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.SecuritySettings}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <br />
                                                            <p>
                                                                <input type="checkbox" id="two_step_verification" ref="two_step_verification" name="two_step_verification" checked={this.state.two_step_verification} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="two_step_verification">{this.Language.DataField.EnableTwoFactorAthentication}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <label htmlFor="signin_select">{this.Language.DataField.TwoFactorSigninMethod}</label>
                                                            <p>
                                                                <input name="signin_method" type="radio" id="signin_security_questions" ref="signin_security_questions" checked={this.state.signin_security_questions} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="signin_security_questions">{this.Language.DataField.SecurityQuestion}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3 center">
                                                            <br />
                                                            <p>
                                                                <input name="signin_method" type="radio" id="signin_code" ref="signin_code" checked={this.state.signin_code} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="signin_code">{this.Language.DataField.Code}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s2">
                                                            <br />
                                                            <p>
                                                                <input name="signin_method" type="radio" id="signin_na" ref="signin_na" checked={this.state.signin_na} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="signin_na">{this.Language.DataField.None}</label>
                                                            </p>
                                                        </div>
                                                        {/* ::after */}
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="enable_mobile_app" ref="enable_mobile_app" name="enable_mobile_app" checked={this.state.enable_mobile_app} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_mobile_app">{this.Language.DataField.EnableMobileApp}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="enable_offline_user" ref="enable_offline_user" name="enable_offline_user" checked={this.state.enable_offline_user} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_offline_user">{this.Language.DataField.EnableOfflineUsage}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_security_setting_button">
                                                                {this.Language.ButtonLabel.UpdateSecuritySettings}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s2">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/user/profile/${this.state.hash_user}`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default SecuritySettings;