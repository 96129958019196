import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import * as serviceWorker from './serviceWorker';


// const supportsHistory = 'pushState' in window.history
// <BrowserRouter forceRefresh={!supportsHistory}>

ReactDOM.render(
        // <BrowserRouter forceRefresh={true} basename="/portal">
          <BrowserRouter forceRefresh={true}>
          <App />
        </BrowserRouter>
, document.getElementById('root'));
serviceWorker.unregister();
