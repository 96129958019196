import React, {Component} from 'react';
// import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import FileUpload from '../components/FileUpload';
import URLService from '../modules/URLService';

const $ = require('jquery');

class EditBaselineDataProfile extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.updateBaselineData = this.updateBaselineData.bind(this);
    this.addBaselineData = this.addBaselineData.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.getBaselineData = this.getBaselineData.bind(this);
    this.getBaselineNotesHistory = this.getBaselineNotesHistory.bind(this);
    this.getBaselineDocuments = this.getBaselineDocuments.bind(this);
    this.getAccessScopes = this.getAccessScopes.bind(this);
    this.submitBaselineData = this.submitBaselineData.bind(this);
    this.onDeleteBaselineData = this.onDeleteBaselineData.bind(this);
    this.onDeleteBaselineDocument = this.onDeleteBaselineDocument.bind(this);
    this.addLocalContentItem = this.addLocalContentItem.bind(this);
    this.removeLocalContentItem = this.removeLocalContentItem.bind(this);
    this.addBaselineDataFinal = this.addBaselineDataFinal.bind(this);
    this.getDropdownValues = this.getDropdownValues.bind(this);
    this.getExportYears = this.getExportYears.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.state = {
      exporter_id: '',
      exporter_name: '',
      business_year: '',
      is_submitted: false,
      is_submitable_business_year: false,
      status: '',
      status_active: '',
      is_approved_initial_value: false,
      is_approved: false,
      approved_by: '',
      approved_at: '',
      last_updated_by: '',
      notes: '',
      reviewer_notes: '',
      baseline_note_status: '',
      baseline_note_status_active: false,
      baseline_note_last_updated_by: '',
      baseline_note_last_updated_on_check: '',
      baseline_note_hash_id: '',
      total_staff_strength: '',
      total_nigerian_staff: '',
      employment_base_status: '',
      employment_base_status_active: false,
      employment_base_last_updated_by: '',
      employment_base_is_approved_initial_value: false,
      employment_base_is_approved: false,
      employment_base_approved_by: '',
      employment_base_approved_at: '',
      employment_base_last_updated_on_check: '',
      employment_base_hash_id: '',
      export_growth_base_currency: '',
      total_export_value: '',
      export_growth_rate: '',
      export_growth_base_status: '',
      export_growth_base_status_active: false,
      export_growth_base_last_updated_by: '',
      export_growth_base_is_approved_initial_value: false,
      export_growth_base_is_approved: false,
      export_growth_base_approved_by: '',
      export_growth_base_approved_at: '',
      export_growth_base_last_updated_on_check: '',
      export_growth_base_hash_id: '',
      investment_base_currency: '',
      additions: '',
      opening_nbv: '',
      capital_investment_growth: '',
      investment_base_status: '',
      investment_base_status_active: false,
      investment_base_last_updated_by: '',
      investment_base_is_approved_initial_value: false,
      investment_base_is_approved: false,
      investment_base_approved_by: '',
      investment_base_approved_at: '',
      investment_base_last_updated_on_check: '',
      investment_base_hash_id: '',
      exported_items_array: [],
      total_local_content_items_amount: 0,
      total_local_content_value: 0,
      total_foreign_content_value: 0,
      local_content_validation_message: '',
      local_content_validation_class_value: 'row red-text',
      local_value_added_base_currency: '',
      turnover: '',
      other_incomes: '',
      bought_in_goods_and_materials: '',
      local_value_added: '',
      local_value_added_base_status: '',
      local_value_added_base_status_active: false,
      local_value_added_base_last_updated_by: '',
      local_value_added_base_is_approved_initial_value: false,
      local_value_added_base_is_approved: false,
      local_value_added_base_approved_by: '',
      local_value_added_base_approved_at: '',
      local_value_added_base_last_updated_on_check: '',
      local_value_added_base_hash_id: '',
      exporter_category: '',
      priority_sector: false,
      priority_sector_score: '',
      priority_sector_base_status: '',
      priority_sector_base_status_active: false,
      priority_sector_base_last_updated_by: '',
      priority_sector_base_is_approved_initial_value: false,
      priority_sector_base_is_approved: false,
      priority_sector_base_approved_by: '',
      priority_sector_base_approved_at: '',
      priority_sector_base_last_updated_on_check: '',
      priority_sector_base_hash_id: '',
      result: '',
      message: '',
      response_status: '',
      pick_list_values: [],
      select_field_values_exporter: [],
      select_field_values_exported_item: [],
      select_field_values_business_year: [],
      select_field_values_currency: [],
      submit_baseline_data_button_caption: null,
      data_badge_caption_baseline_notes: '',
      data_badge_caption_employment_base: '',
      data_badge_caption_export_growth_base: '',
      data_badge_caption_investment_base: '',
      data_badge_caption_local_value_added_base: '',
      data_badge_caption_priority_sector_base: '',
      data_badge_caption_baseline_notes_delete: '',
      data_badge_caption_employment_base_delete: '',
      data_badge_caption_export_growth_base_delete: '',
      data_badge_caption_investment_base_delete: '',
      data_badge_caption_local_value_added_base_delete: '',
      data_badge_caption_priority_sector_base_delete: '',
      baseline_notes_history: [],
      baseline_documents: [],
      submitbuttondisabled: false,
      preloader: true,
      hash_user: '',
      hash_organisation: '',
      logged_in_username: '',
      baselines_update: null,
      baselines_delete: null,
      scopes: {},
      text_limit: 3000,
      error_messages: null,
      fileUploadItem_1: null,
      fileUploadItem_2: null,
      fileUploadItem_3: null,
      fileUploadItem_4: null,
      fileUploadItem_5: null,
      fileUploadItem_6: null,
      fileUploadItem_7: null,
      close_error_messages: (
        <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
          <span aria-hidden="true">×</span>
        </button>
      )
    };
    this.Auth = new AuthService();
    this.Validate = new ValidationService();
    this.DateService = new DateService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.FormatService = new FormatService();
    this.apiURL = URLService.prototype.getApiURL();
  }

  componentWillMount() {}

  componentDidMount() {
    document.title = this.Language.PageTitle.EditBaselineData + ' - ' + this.Language.EEGWebPortal;
    this.getProfile();
    this.getBaselineData();
    this.getBaselineNotesHistory();
    this.getBaselineDocuments();
    this.setState({
      data_badge_caption_baseline_notes: this.Language.ButtonLabel.Save,
      data_badge_caption_employment_base: this.Language.ButtonLabel.Save,
      data_badge_caption_export_growth_base: this.Language.ButtonLabel.Save,
      data_badge_caption_investment_base: this.Language.ButtonLabel.Save,
      data_badge_caption_local_value_added_base: this.Language.ButtonLabel.Save,
      data_badge_caption_priority_sector_base: this.Language.ButtonLabel.Save,
      data_badge_caption_baseline_notes_delete: this.Language.ButtonLabel.Delete,
      data_badge_caption_employment_base_delete: this.Language.ButtonLabel.Delete,
      data_badge_caption_export_growth_base_delete: this.Language.ButtonLabel.Delete,
      data_badge_caption_investment_base_delete: this.Language.ButtonLabel.Delete,
      data_badge_caption_local_value_added_base_delete: this.Language.ButtonLabel.Delete,
      data_badge_caption_priority_sector_base_delete: this.Language.ButtonLabel.Delete,
      submitbuttondisabled: false
    });

    window.$('.dropify').dropify({
      messages: {
        default: this.Language.FileUpload.Default,
        replace: this.Language.FileUpload.Replace,
        remove: this.Language.FileUpload.Remove,
        error: this.Language.FileUpload.Error
      }
    });
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    $('select').material_select('destroy');
  }

  getExportYears() {
    axios
      .get(`${this.apiURL}/api/v1/baseline/years/active/exporter/${this.state.hash_organisation}/exporter_category`, {
        params: {
          tk: this.Auth.getToken()
        }
      })
      .then(response => {
        const baseline_years = response.data.data;

        const select_field_values_business_year = baseline_years.map((baseline_year, i) => {
          return <SelectField pick_list_id={baseline_year.business_year} key={i} item_name={baseline_year.business_year} />;
        });

        this.setState({
          select_field_values_business_year: select_field_values_business_year
        });
        window.$('select').material_select();
      })
      .catch(error => {});
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState(
        {
          scopes: user_profile.scopes,
          logged_in_username: user_profile.username,
          hash_user: user_profile.user,
          hash_organisation: user_profile.organisation
        },
        () => {
          this.setState({
            fileUploadItem_1: (
              <FileUpload
                fieldName={this.Language.PageLabel.BaselineForms}
                fileExtensions={'pdf png jpeg jpg doc docx txt xls xlsx csv'}
                fieldID={'baseline-file-001'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Baseline_Forms'}
                pageDataCategory={'Baseline_Forms'}
                pageName={'AddBaselineData'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
              />
            ),
            fileUploadItem_2: (
              <FileUpload
                fieldName={this.Language.PageLabel.AuditedStatementCurrent}
                fileExtensions={'pdf png jpeg jpg doc docx txt'}
                fieldID={'baseline-file-002'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Audited_Statement_current'}
                pageDataCategory={'Audited_Statement'}
                pageName={'AddBaselineData'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
              />
            ),
            fileUploadItem_3: (
              <FileUpload
                fieldName={this.Language.PageLabel.ManagementAccountCurrent}
                fileExtensions={'pdf png jpeg jpg doc docx txt'}
                fieldID={'baseline-file-003'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Management_Account_Current'}
                pageDataCategory={'Management_Account'}
                pageName={'AddBaselineData'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
              />
            ),
            fileUploadItem_4: (
              <FileUpload
                fieldName={this.Language.PageLabel.ProjectedFinancialStatement}
                fileExtensions={'pdf png jpeg jpg doc docx txt'}
                fieldID={'baseline-file-004'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Projected_Statement'}
                pageDataCategory={'Projected_Statement'}
                pageName={'AddBaselineData'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
              />
            ),
            fileUploadItem_5: (
              <FileUpload
                fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear1}
                fileExtensions={'pdf png jpeg jpg doc docx txt'}
                fieldID={'baseline-file-005'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Audited_Statement_Pre_Year_1'}
                pageDataCategory={'Audited_Statement'}
                pageName={'AddBaselineData'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
              />
            ),
            fileUploadItem_6: (
              <FileUpload
                fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear2}
                fileExtensions={'pdf png jpeg jpg doc docx txt'}
                fieldID={'baseline-file-006'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Audited_Statement_Pre_Year_2'}
                pageDataCategory={'Audited_Statement'}
                pageName={'AddBaselineData'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
              />
            ),
            fileUploadItem_7: (
              <FileUpload
                fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear3}
                fileExtensions={'pdf png jpeg jpg doc docx txt'}
                fieldID={'baseline-file-007'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Audited_Statement_Pre_Year_3'}
                pageDataCategory={'Audited_Statement'}
                pageName={'AddBaselineData'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.BusinessYear}
              />
            )
          });

          this.checkAccessRight();
          this.getAccessScopes();
        }
      );
    }
  }

  checkAccessRight() {
    if (this.Auth.checkAccessScope(['update:baseline', 'action:baseline'], 'view') === false || this.state.hash_organisation !== this.props.match.params.id) {
      this.props.history.replace(`/exporter/${this.state.hash_organisation}/baseline/profile`);
    } else {
      this.getDropdownValues(`single_exporter:${this.state.hash_organisation},currency,single_exported_item:${this.state.hash_organisation}`);
      this.getExportYears();
    }
  }

  getAccessScopes() {
    // Check the views scope
    if (this.state.scopes['views']) {
      let len = this.state.scopes['views'].length;

      for (let i = 0; i < len; i++) {
        if (this.state.scopes['views'][i] === 'update:baselines' || this.state.scopes['views'][i] === 'action:baselines') this.setState({baselines_update: true});
        if (this.state.scopes['views'][i] === 'delete:baselines' || this.state.scopes['views'][i] === 'action:baselines') this.setState({baselines_delete: true});
      }
    }
  }

  handleCloseAlert() {
    this.setState({error_messages: null}); // change the value of error_message to close the alert box
  }

  getBaselineData() {
    let exporterId = this.props.match.params.id;
    let businessYear = this.props.match.params.yr;
    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

    axios
      .get(`${this.apiURL}/api/v1/baseline/data/${exporterId}/${businessYear}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
      .then(response => {
        this.setState({
          exporter_id: response.data.data[0].exporter_id || '',
          exporter_name: response.data.data[0].exporter_name || '',
          business_year: response.data.data[0].business_year || '',
          is_submitted: response.data.data[0].is_submitted === 'true' || response.data.data[0].is_submitted === true ? true : false || false,
          is_submitable_business_year: response.data.data[0].is_submitable_business_year === 'true' || response.data.data[0].is_submitable_business_year === true ? true : false || false,
          notes: response.data.data[0].notes || '',
          reviewer_notes: response.data.data[0].reviewer_notes || '',
          baseline_note_status: response.data.data[0].baseline_note_status || '',
          baseline_note_status_active: response.data.data[0].baseline_note_status === 'Active' ? true : false,
          baseline_note_last_updated_by: response.data.data[0].baseline_note_last_updated_by || '',
          baseline_note_last_updated_on_check: response.data.data[0].baseline_note_last_updated_on_check || '',
          baseline_note_hash_id: response.data.data[0].baseline_notes_hash_id || '',
          total_staff_strength: response.data.data[0].total_staff_strength || '',
          total_nigerian_staff: response.data.data[0].total_nigerian_staff || '',
          employment_base_status: response.data.data[0].employment_base_status || '',
          employment_base_status_active: response.data.data[0].employment_base_status === 'Active' ? true : false,
          employment_base_last_updated_by: response.data.data[0].employment_base_last_updated_by || '',
          employment_base_is_approved_initial_value: response.data.data[0].employment_base_is_approved === 'true' || response.data.data[0].employment_base_is_approved === true ? true : false || false,
          employment_base_is_approved: response.data.data[0].employment_base_is_approved === 'true' || response.data.data[0].employment_base_is_approved === true ? true : false || false,
          employment_base_approved_by: response.data.data[0].employment_base_approved_by || '',
          employment_base_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].employment_base_approved_at),
          employment_base_last_updated_on_check: response.data.data[0].employment_base_last_updated_on_check || '',
          employment_base_hash_id: response.data.data[0].employment_base_hash_id || '',
          export_growth_base_currency: response.data.data[0].export_growth_base_currency || '',
          total_export_value: response.data.data[0].total_export_value || '',
          export_growth_rate: response.data.data[0].export_growth_rate || '',
          export_growth_base_status: response.data.data[0].export_growth_base_status || '',
          export_growth_base_status_active: response.data.data[0].export_growth_base_status === 'Active' ? true : false,
          export_growth_base_last_updated_by: response.data.data[0].export_growth_base_last_updated_by || '',
          export_growth_base_is_approved_initial_value:
            response.data.data[0].export_growth_base_is_approved === 'true' || response.data.data[0].export_growth_base_is_approved === true ? true : false || false,
          export_growth_base_is_approved: response.data.data[0].export_growth_base_is_approved === 'true' || response.data.data[0].export_growth_base_is_approved === true ? true : false || false,
          export_growth_base_approved_by: response.data.data[0].export_growth_base_approved_by || '',
          export_growth_base_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].export_growth_base_approved_at),
          export_growth_base_last_updated_on_check: response.data.data[0].export_growth_base_last_updated_on_check || '',
          export_growth_base_hash_id: response.data.data[0].export_growth_base_hash_id || '',
          investment_base_currency: response.data.data[0].investment_base_currency || '',
          additions: response.data.data[0].additions || '',
          opening_nbv: response.data.data[0].opening_nbv || '',
          capital_investment_growth: response.data.data[0].capital_investment_growth || '',
          investment_base_status: response.data.data[0].investment_base_status || '',
          investment_base_status_active: response.data.data[0].investment_base_status === 'Active' ? true : false,
          investment_base_last_updated_by: response.data.data[0].investment_base_last_updated_by || '',
          investment_base_is_approved_initial_value: response.data.data[0].investment_base_is_approved === 'true' || response.data.data[0].investment_base_is_approved === true ? true : false || false,
          investment_base_is_approved: response.data.data[0].investment_base_is_approved === 'true' || response.data.data[0].investment_base_is_approved === true ? true : false || false,
          investment_base_approved_by: response.data.data[0].investment_base_approved_by || '',
          investment_base_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].investment_base_approved_at),
          investment_base_last_updated_on_check: response.data.data[0].investment_base_last_updated_on_check || '',
          investment_base_hash_id: response.data.data[0].investment_base_hash_id || '',
          local_value_added_base_currency: response.data.data[0].local_value_added_base_currency || '',
          turnover: response.data.data[0].turnover || '',
          other_incomes: response.data.data[0].other_incomes || '',
          bought_in_goods_and_materials: response.data.data[0].bought_in_goods_and_materials || '',
          local_value_added: response.data.data[0].local_value_added || '',
          local_value_added_base_status: response.data.data[0].local_value_added_base_status || '',
          local_value_added_base_status_active: response.data.data[0].local_value_added_base_status === 'Active' ? true : false,
          local_value_added_base_last_updated_by: response.data.data[0].local_value_added_base_last_updated_by || '',
          local_value_added_base_is_approved_initial_value:
            response.data.data[0].local_value_added_base_is_approved === 'true' || response.data.data[0].local_value_added_base_is_approved === true ? true : false || false,
          local_value_added_base_is_approved:
            response.data.data[0].local_value_added_base_is_approved === 'true' || response.data.data[0].local_value_added_base_is_approved === true ? true : false || false,
          local_value_added_base_approved_by: response.data.data[0].local_value_added_base_approved_by || '',
          local_value_added_base_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].local_value_added_base_approved_at),
          local_value_added_base_last_updated_on_check: response.data.data[0].local_value_added_base_last_updated_on_check || '',
          local_value_added_base_hash_id: response.data.data[0].local_value_added_base_hash_id || '',
          exporter_category: response.data.data[0].exporter_category || '',
          priority_sector: response.data.data[0].priority_sector === 'true' || response.data.data[0].priority_sector === true ? true : false || false,
          priority_sector_score: response.data.data[0].priority_sector_score || '',
          priority_sector_base_status: response.data.data[0].priority_sector_base_status || '',
          priority_sector_base_status_active: response.data.data[0].priority_sector_base_status === 'Active' ? true : false,
          priority_sector_base_last_updated_by: response.data.data[0].priority_sector_base_last_updated_by || '',
          priority_sector_base_is_approved_initial_value:
            response.data.data[0].priority_sector_base_is_approved === 'true' || response.data.data[0].priority_sector_base_is_approved === true ? true : false || false,
          priority_sector_base_is_approved:
            response.data.data[0].priority_sector_base_is_approved === 'true' || response.data.data[0].priority_sector_base_is_approved === true ? true : false || false,
          priority_sector_base_approved_by: response.data.data[0].priority_sector_base_approved_by || '',
          priority_sector_base_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].priority_sector_base_approved_at),
          priority_sector_base_last_updated_on_check: response.data.data[0].priority_sector_base_last_updated_on_check || '',
          priority_sector_base_hash_id: response.data.data[0].priority_sector_base_hash_id || ''
        });

        //exported_items_array: [],

        let len = response.data.data.length;
        let temp_exported_item = null;
        let temp_exported_items_array = [];

        let total_local_content_items_amount = parseFloat(this.state.total_local_content_items_amount);
        let total_local_content_value = parseFloat(this.state.total_local_content_value);
        let total_foreign_content_value = parseFloat(this.state.total_foreign_content_value);
        let local_content_validation_message = '';

        for (let i = 0; i < len; i++) {
          if (
            response.data.data[i].local_content_base_id !== '' &&
            response.data.data[i].local_content_base_id !== 'null' &&
            response.data.data[i].local_content_base_id !== null &&
            typeof response.data.data[i].local_content_base_id !== 'undefined' &&
            response.data.data[i].exported_item !== '' &&
            response.data.data[i].exported_item !== 'null' &&
            response.data.data[i].exported_item !== null &&
            typeof response.data.data[i].exported_item !== 'undefined'
          ) {
            temp_exported_item = {
              new_or_old_record: 'old',
              exported_item: response.data.data[i].exported_item || '',
              local_content_base_currency: response.data.data[i].local_content_base_currency || '',
              foreign_content: response.data.data[i].foreign_content || '',
              local_content: response.data.data[i].local_content || '',
              export_value: response.data.data[i].export_value || '',
              local_content_base_status: response.data.data[i].local_content_base_status || '',
              local_content_base_status_active: response.data.data[i].local_content_base_status === 'Active' ? true : false,
              local_content_base_last_updated_by: response.data.data[i].local_content_base_last_updated_by || '',
              local_content_base_is_approved_initial_value:
                response.data.data[i].local_content_base_is_approved === 'true' || response.data.data[i].local_content_base_is_approved === true ? true : false || false,
              local_content_base_is_approved: response.data.data[i].local_content_base_is_approved === 'true' || response.data.data[i].local_content_base_is_approved === true ? true : false || false,
              local_content_base_approved_by: response.data.data[i].local_content_base_approved_by || '',
              local_content_base_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[i].local_content_base_approved_at),
              local_content_base_last_updated_on_check: response.data.data[i].local_content_base_last_updated_on_check || '',
              local_content_base_hash_id: response.data.data[i].local_content_base_hash_id || '',
              data_badge_caption_local_content_base: this.Language.ButtonLabel.Save,
              data_badge_caption_local_content_base_delete: this.Language.ButtonLabel.Delete
            };

            temp_exported_items_array.push(temp_exported_item);

            let new_temp_exported_item = parseFloat(temp_exported_item.export_value);
            if (isNaN(new_temp_exported_item) === true) new_temp_exported_item = 0;

            let new_temp_local_content = parseFloat(temp_exported_item.local_content);
            if (isNaN(new_temp_local_content) === true) new_temp_local_content = 0;

            let new_temp_foreign_content = parseFloat(temp_exported_item.foreign_content);
            if (isNaN(new_temp_foreign_content) === true) new_temp_foreign_content = 0;

            total_local_content_items_amount = total_local_content_items_amount + parseFloat(temp_exported_item.export_value);
            total_local_content_value = total_local_content_value + parseFloat(temp_exported_item.local_content);
            total_foreign_content_value = total_foreign_content_value + parseFloat(temp_exported_item.foreign_content);
          }
        }

        this.setState({
          exported_items_array: temp_exported_items_array,
          total_local_content_items_amount: total_local_content_items_amount,
          total_local_content_value: total_local_content_value,
          total_foreign_content_value: total_foreign_content_value,
          local_content_validation_message: local_content_validation_message
        });

        this.setState({status_active: true});

        if (this.state.status === 'Active') {
          this.setState({status_active: true});
        } else {
          this.setState({status_active: false});
        }

        this.setState({response_status: response.data.status}, () => {
        });

        this.setState({message: response.data.message}, () => {
        });

        window.$('select').material_select();
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx


          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

        } else {
          // Something happened in setting up the request that triggered an Error

        }
      });
  }

  getBaselineNotesHistory() {
    let exporterId = this.props.match.params.id;
    let businessYear = this.props.match.params.yr;
    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

    axios
      .get(`${this.apiURL}/api/v1/baseline/data/notes/history/${exporterId}/${businessYear}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
      .then(response => {
        if (response.data.data && response.data.data !== null && typeof response.data.data !== 'undefined') {
          this.setState({baseline_notes_history: response.data.data}, () => {
          });
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx


          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  }

  getBaselineDocuments() {
    let exporterId = this.props.match.params.id;
    let businessYear = this.props.match.params.yr;
    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

    axios
      .get(`${this.apiURL}/api/v1/file/documents/baseline/${exporterId}/${businessYear}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
      .then(response => {
        if (response.data.data && response.data.data !== null && typeof response.data.data !== 'undefined') {
          this.setState({baseline_documents: response.data.data}, () => {
          });
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx


          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    if (name.substring(0, 16) === 'foreign_content_' || name.substring(0, 14) === 'local_content_' || name.substring(0, 13) === 'export_value_') {
      let temp_exported_items_array = this.state.exported_items_array;
      const len = temp_exported_items_array.length;

      for (let i = 0; i < len; i++) {
        if (name === 'foreign_content_' + i) {
          temp_exported_items_array[i].foreign_content = value;
          this.setState({exported_items_array: temp_exported_items_array});
        }

        if (name === 'local_content_' + i) {
          temp_exported_items_array[i].local_content = value;
          this.setState({exported_items_array: temp_exported_items_array});
        }

        if (name === 'export_value_' + i) {
          temp_exported_items_array[i].export_value = value;
          this.setState({exported_items_array: temp_exported_items_array});
        }
      }
    } else {
      // if not a local content base field item

      this.setState({
        [name]: value
      });
    }
  }

  handleCheckBoxChange(event) {
    const target = event.target;
    // const checked = target.checked;
    const name = target.id;

    if (name === 'activate_switch') {
      this.setState({
        status_active: !this.state.status_active,
        status: this.state.status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name === 'activate_switch_baseline_notes') {
      this.setState({
        baseline_note_status_active: !this.state.baseline_note_status_active,
        baseline_note_status: this.state.baseline_note_status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name === 'activate_switch_employment_base' && this.state.employment_base_is_approved_initial_value === false) {
      this.setState({
        employment_base_status_active: !this.state.employment_base_status_active,
        employment_base_status: this.state.employment_base_status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name === 'activate_switch_export_growth_base' && this.state.export_growth_base_is_approved_initial_value === false) {
      this.setState({
        export_growth_base_status_active: !this.state.export_growth_base_status_active,
        export_growth_base_status: this.state.export_growth_base_status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name === 'activate_switch_investment_base' && this.state.investment_base_is_approved === false) {
      this.setState({
        investment_base_status_active: !this.state.investment_base_status_active,
        investment_base_status: this.state.investment_base_status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name.substring(0, 35) === 'activate_switch_local_content_base_') {
      let temp_exported_items_array = this.state.exported_items_array;
      const len = temp_exported_items_array.length;

      for (let i = 0; i < len; i++) {
        if (name === 'activate_switch_local_content_base_' + i && temp_exported_items_array[i].local_content_base_is_approved_initial_value === false) {
          temp_exported_items_array[i].local_content_base_status_active = !temp_exported_items_array[i].local_content_base_status_active;
          temp_exported_items_array[i].local_content_base_status = temp_exported_items_array[i].local_content_base_status === 'Active' ? 'Inactive' : 'Active';
          this.setState({exported_items_array: temp_exported_items_array});
        }
      }
    }

    if (name === 'activate_switch_local_value_added_base' && this.state.local_value_added_base_is_approved_initial_value === false) {
      this.setState({
        local_value_added_base_status_active: !this.state.local_value_added_base_status_active,
        local_value_added_base_status: this.state.local_value_added_base_status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name === 'activate_switch_priority_sector_base' && this.state.priority_sector_base_is_approved_initial_value === false) {
      this.setState({
        priority_sector_base_status_active: !this.state.priority_sector_base_status_active,
        priority_sector_base_status: this.state.priority_sector_base_status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name === 'employment_base_is_approved' && this.state.employment_base_is_approved_initial_value === false) {
      this.setState({[name]: !this.state.employment_base_is_approved});
    }
    if (name === 'export_growth_base_is_approved' && this.state.export_growth_base_is_approved_initial_value === false) {
      this.setState({[name]: !this.state.export_growth_base_is_approved});
    }
    if (name === 'investment_base_is_approved' && this.state.investment_base_is_approved_initial_value === false) {
      this.setState({[name]: !this.state.investment_base_is_approved});
    }

    if (name.substring(0, 31) === 'local_content_base_is_approved_') {
      let temp_exported_items_array = this.state.exported_items_array;
      const len = temp_exported_items_array.length;

      for (let i = 0; i < len; i++) {
        if (name === 'local_content_base_is_approved_' + i && temp_exported_items_array[i].local_content_base_is_approved_initial_value === false) {
          temp_exported_items_array[i].local_content_base_is_approved = !temp_exported_items_array[i].local_content_base_is_approved;
          this.setState({exported_items_array: temp_exported_items_array});
        }
      }
    }

    if (name === 'local_value_added_base_is_approved' && this.state.local_value_added_base_is_approved_initial_value === false) {
      this.setState({[name]: !this.state.local_value_added_base_is_approved});
    }
    if (name === 'priority_sector' && this.state.priority_sector_base_is_approved_initial_value === false) {
      this.setState({[name]: !this.state.priority_sector});
    }
    if (name === 'priority_sector_base_is_approved' && this.state.priority_sector_base_is_approved_initial_value === false) {
      this.setState({[name]: !this.state.priority_sector_base_is_approved});
    }
  }

  addLocalContentItem() {
    let local_content_base_currency_new = this.refs.local_content_base_currency_new.value;
    if (local_content_base_currency_new === 'Select Currency') local_content_base_currency_new = '';

    let exported_item_new = this.refs.exported_item_new.value;
    if (exported_item_new === 'Select Exported Item') exported_item_new = '';

    let foreign_content_new = this.refs.foreign_content_new.value;
    let local_content_new = this.refs.local_content_new.value;
    let export_value_new = this.refs.export_value_new.value;
    let local_content_base_is_approved_new = false;
    let local_content_approved_value_new = this.Language.DataValue.No;
    if (local_content_base_is_approved_new === true) local_content_approved_value_new = this.Language.DataValue.Yes;
    let local_content_base_status_new = 'Inactive';
    if (this.refs.activate_switch_local_content_base_new.checked) local_content_base_status_new = 'Active';
    if (!this.refs.activate_switch_local_content_base_new.checked) local_content_base_status_new = 'Inactive';
    let local_content_base_status_active = this.refs.activate_switch_local_content_base_new.checked;

    let local_content_base_last_updated_on_check = '';

    if (
      !isNaN(parseFloat(export_value_new)) &&
      isFinite(export_value_new) &&
      !isNaN(parseFloat(local_content_new)) &&
      isFinite(local_content_new) &&
      !isNaN(parseFloat(local_content_new)) &&
      isFinite(local_content_new)
    ) {
      if (
        local_content_base_currency_new !== '' &&
        local_content_base_currency_new !== null &&
        typeof local_content_base_currency_new !== 'undefined' &&
        exported_item_new !== '' &&
        exported_item_new !== null &&
        typeof exported_item_new !== 'undefined' &&
        local_content_new > 0 &&
        (export_value_new > 0 || foreign_content_new > 0)
      ) {
        const newLocalContentItem = {
          new_or_old_record: 'new',
          local_content_base_currency: local_content_base_currency_new,
          exported_item: exported_item_new,
          export_value: Math.abs(parseFloat(export_value_new)),
          local_content: Math.abs(parseFloat(local_content_new)),
          foreign_content: Math.abs(parseFloat(foreign_content_new)),
          local_content_base_is_approved: local_content_base_is_approved_new,
          local_content_approved_value: local_content_approved_value_new,
          local_content_base_status: local_content_base_status_new,
          data_badge_caption_local_content_base: this.Language.ButtonLabel.SaveNewItem,
          data_badge_caption_local_content_base_delete: this.Language.ButtonLabel.Remove,
          local_content_base_is_approved_initial_value: false,
          local_content_base_approved_by: this.state.logged_in_username,
          local_content_base_status_active: local_content_base_status_active,
          local_content_base_last_updated_on_check: local_content_base_last_updated_on_check,
          local_content_base_hash_id: 'new_item_hash_id'
        };

        let temp_total_local_content_items_amount = parseFloat(this.state.total_local_content_items_amount);
        if (isNaN(temp_total_local_content_items_amount) === true) temp_total_local_content_items_amount = 0;

        let temp_export_value = parseFloat(newLocalContentItem.export_value);
        if (isNaN(temp_export_value) === true) temp_export_value = 0;

        let temp_total_local_content_value = parseFloat(this.state.total_local_content_value);
        if (isNaN(temp_total_local_content_value) === true) temp_total_local_content_value = 0;

        let temp_local_content = parseFloat(newLocalContentItem.local_content);
        if (isNaN(temp_local_content) === true) temp_local_content = 0;

        let temp_total_foreign_content_value = parseFloat(this.state.total_foreign_content_value);
        if (isNaN(temp_total_foreign_content_value) === true) temp_total_foreign_content_value = 0;

        let temp_foreign_content = parseFloat(newLocalContentItem.foreign_content);
        if (isNaN(temp_foreign_content) === true) temp_foreign_content = 0;

        let temp_exported_items_array = this.state.exported_items_array;
        temp_exported_items_array.push(newLocalContentItem);
        this.setState({
          exported_items_array: temp_exported_items_array,
          total_local_content_items_amount: temp_total_local_content_items_amount + temp_export_value,
          total_local_content_value: temp_total_local_content_value + temp_local_content,
          total_foreign_content_value: temp_total_foreign_content_value + temp_foreign_content,
          local_content_validation_message: ''
        });
      } else {
        this.setState({
          local_content_validation_class_value: 'row red-text',
          local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidation
        });
      }
    } else {
      this.setState({
        local_content_validation_class_value: 'row red-text',
        local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidationDetails
      });
    }
  }

  removeLocalContentItem(position, export_value, local_content_value, foreign_content_value) {
    let temp_exported_items_array = this.state.exported_items_array;
    temp_exported_items_array = temp_exported_items_array.filter((value, i) => i !== position);
    this.setState({
      exported_items_array: temp_exported_items_array,
      total_local_content_items_amount: parseFloat(this.state.total_local_content_items_amount) - parseFloat(export_value),
      total_local_content_value: parseFloat(this.state.total_local_content_value) - parseFloat(local_content_value),
      total_foreign_content_value: parseFloat(this.state.total_foreign_content_value) - parseFloat(foreign_content_value),
      local_content_validation_message: ''
    });
  }

  submitBaselineData(baseline_option, position) {
    let message = this.Language.ConfirmMessage.EditBaseline; // default message

    if (baseline_option === 'baseline_notes') message = this.Language.ConfirmMessage.EditBaselineNote;
    if (baseline_option === 'employment_base') message = this.Language.ConfirmMessage.EditEmploymentBase;
    if (baseline_option === 'export_growth_base') message = this.Language.ConfirmMessage.EditExportGrowth;
    if (baseline_option === 'investment_base') message = this.Language.ConfirmMessage.EditInvestmentBase;
    if (baseline_option === 'local_content_base') message = this.Language.ConfirmMessage.EditLocalContent;
    if (baseline_option === 'local_value_added_base') message = this.Language.ConfirmMessage.EditLocalValueAdded;
    if (baseline_option === 'priority_sector_base') message = this.Language.ConfirmMessage.EditPrioritySector;

    if (window.confirm(`${message}`)) {
      // let exporter_id = this.state.exporter_id; // reconsider taking the exporter and business year from the state as agaisnt using refs
      let exporter_id = this.refs.exporter_id.value;
      if (exporter_id === 'Select Exporter') exporter_id = '';

      // let exporter_name = this.refs.exporter_name.value;

      // let business_year = this.state.business_year;
      let business_year = this.refs.business_year.value;
      if (business_year === 'Select Business Year') business_year = '';

      let last_updated_by = this.state.logged_in_username;

      // The section below handles the data to be assigned based on the baseline option

      if (baseline_option === 'baseline_notes') {
        let notes = this.refs.notes.value;
        let reviewer_notes = this.state.reviewer_notes;
        let baseline_note_status = 'Inactive';
        if (this.refs.activate_switch_baseline_notes.checked) baseline_note_status = 'Active';
        if (!this.refs.activate_switch_baseline_notes.checked) baseline_note_status = 'Inactive';

        let baseline_note_last_updated_on_check = this.state.baseline_note_last_updated_on_check;

        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
              [exporter_id, business_year, notes, notes],
              [this.Language.DataField.Exporter, this.Language.DataField.BusinessYear, this.Language.DataField.Notes, this.Language.DataField.Notes],
              ['isRequired', 'isRequired', 'isRequired', 'maxLength'],
              0,
              this.state.text_limit
            )
          },
          () => {
            if (this.state.error_messages === null) {
              if (exporter_id === 'Select Exporter') exporter_id = null;
              if (business_year === 'Select Business Year') business_year = null;

              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                hash_id: this.state.baseline_note_hash_id,
                notes: notes,
                reviewer_notes: reviewer_notes,
                baseline_option: baseline_option,
                status: baseline_note_status,
                created_by: last_updated_by,
                last_updated_by: last_updated_by,
                baseline_note_last_updated_on_check: baseline_note_last_updated_on_check,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true});

              this.updateBaselineData(newBaselineData, position);
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }

      if (baseline_option === 'employment_base') {
        let total_staff_strength = this.refs.total_staff_strength.value;
        let total_nigerian_staff = this.refs.total_nigerian_staff.value;
        let employment_base_status = 'Inactive';
        if (this.refs.activate_switch_employment_base.checked) employment_base_status = 'Active';
        if (!this.refs.activate_switch_employment_base.checked) employment_base_status = 'Inactive';

        let employment_base_is_approved_initial_value = this.state.employment_base_is_approved_initial_value;
        let employment_base_is_approved = this.state.employment_base_is_approved;
        let employment_base_approved_by = null;
        if (employment_base_is_approved_initial_value === true && employment_base_is_approved === true) employment_base_approved_by = this.state.employment_base_approved_by;
        if (employment_base_is_approved_initial_value === false && employment_base_is_approved === true) {
          employment_base_approved_by = this.state.logged_in_username;
          this.setState({
            employment_base_approved_by: this.state.logged_in_username
          });
        }

        let employment_base_approved_at = null;
        if (employment_base_is_approved_initial_value === true && employment_base_is_approved === true) {
          // if the baseline item had been approved before now take the approved at value
          employment_base_approved_at = this.state.employment_base_approved_at;
        }

        if (employment_base_is_approved_initial_value === false && employment_base_is_approved === true) {
          // if the baseline item has just been approved then take the new approved at value
          let currentdate = new Date();
          employment_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
        }

        let employment_base_last_updated_on_check = this.state.employment_base_last_updated_on_check;

        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
              [exporter_id, business_year, total_staff_strength, total_staff_strength, total_nigerian_staff, total_nigerian_staff],
              [
                this.Language.DataField.Exporter,
                this.Language.DataField.BusinessYear,
                this.Language.DataField.TotalStaffStrength,
                this.Language.DataField.TotalStaffStrength,
                this.Language.DataField.TotalNigerianStaff,
                this.Language.DataField.TotalNigerianStaff
              ],
              ['isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
              0,
              0
            )
          },
          () => {
            if (this.state.error_messages === null) {
              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                hash_id: this.state.employment_base_hash_id,
                total_staff_strength: Math.abs(total_staff_strength) || null,
                total_nigerian_staff: Math.abs(total_nigerian_staff) || null,
                baseline_option: baseline_option,
                status: employment_base_status,
                is_approved: employment_base_is_approved,
                approved_by: employment_base_approved_by,
                approved_at: employment_base_approved_at,
                created_by: last_updated_by,
                last_updated_by: last_updated_by,
                employment_base_last_updated_on_check: employment_base_last_updated_on_check,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true});

              this.updateBaselineData(newBaselineData, position);
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }

      if (baseline_option === 'export_growth_base') {
        let export_growth_base_currency = this.refs.export_growth_base_currency.value;
        if (export_growth_base_currency === 'Select Currency') export_growth_base_currency = '';

        let total_export_value = this.refs.total_export_value.value;
        let export_growth_rate = this.state.export_growth_rate;
        let export_growth_base_status = 'Inactive';
        if (this.refs.activate_switch_export_growth_base.checked) export_growth_base_status = 'Active';
        if (!this.refs.activate_switch_export_growth_base.checked) export_growth_base_status = 'Inactive';

        let export_growth_base_is_approved_initial_value = this.state.export_growth_base_is_approved_initial_value;
        let export_growth_base_is_approved = this.state.export_growth_base_is_approved;
        let export_growth_base_approved_by = null;
        if (export_growth_base_is_approved_initial_value === true && export_growth_base_is_approved === true) export_growth_base_approved_by = this.state.export_growth_base_approved_by;
        if (export_growth_base_is_approved_initial_value === false && export_growth_base_is_approved === true) {
          export_growth_base_approved_by = this.state.logged_in_username;
          this.setState({
            export_growth_base_approved_by: this.state.logged_in_username
          });
        }

        let export_growth_base_approved_at = null;
        if (export_growth_base_is_approved_initial_value === true && export_growth_base_is_approved === true) {
          // if the baseline item had been approved before now take the approved at value
          export_growth_base_approved_at = this.state.export_growth_base_approved_at;
        }

        if (export_growth_base_is_approved_initial_value === false && export_growth_base_is_approved === true) {
          // if the baseline item has just been approved then take the new approved at value
          let currentdate = new Date();
          export_growth_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
        }

        let export_growth_base_last_updated_on_check = this.state.export_growth_base_last_updated_on_check;

        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
              [exporter_id, business_year, export_growth_base_currency, total_export_value, total_export_value],
              [
                this.Language.DataField.Exporter,
                this.Language.DataField.BusinessYear,
                this.Language.DataField.Currency,
                this.Language.DataField.TotalExportValue,
                this.Language.DataField.TotalExportValue
              ],
              ['isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber'],
              0,
              0
            )
          },
          () => {
            if (this.state.error_messages === null) {
              if (export_growth_base_currency === 'Select Currency') export_growth_base_currency = null;

              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                hash_id: this.state.export_growth_base_hash_id,
                currency: export_growth_base_currency,
                total_export_value: Math.abs(total_export_value) || null,
                export_growth_rate: Math.abs(export_growth_rate) || null,
                baseline_option: baseline_option,
                status: export_growth_base_status,
                is_approved: export_growth_base_is_approved,
                approved_by: export_growth_base_approved_by,
                approved_at: export_growth_base_approved_at,
                created_by: last_updated_by,
                last_updated_by: last_updated_by,
                export_growth_base_last_updated_on_check: export_growth_base_last_updated_on_check,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true});

              this.updateBaselineData(newBaselineData, position);
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }

      if (baseline_option === 'investment_base') {
        let investment_base_currency = this.refs.investment_base_currency.value;
        if (investment_base_currency === 'Select Currency') investment_base_currency = '';

        let additions = this.refs.additions.value;
        let opening_nbv = this.refs.opening_nbv.value;
        let capital_investment_growth = this.state.capital_investment_growth;
        let investment_base_status = 'Inactive';
        if (this.refs.activate_switch_investment_base.checked) investment_base_status = 'Active';
        if (!this.refs.activate_switch_investment_base.checked) investment_base_status = 'Inactive';

        let investment_base_is_approved_initial_value = this.state.investment_base_is_approved_initial_value;
        let investment_base_is_approved = this.state.investment_base_is_approved;
        let investment_base_approved_by = null;
        if (investment_base_is_approved_initial_value === true && investment_base_is_approved === true) investment_base_approved_by = this.state.investment_base_approved_by;
        if (investment_base_is_approved_initial_value === false && investment_base_is_approved === true) {
          investment_base_approved_by = this.state.logged_in_username;
          this.setState({
            investment_base_approved_by: this.state.logged_in_username
          });
        }

        let investment_base_approved_at = null;
        if (investment_base_is_approved_initial_value === true && investment_base_is_approved === true) {
          // if the baseline item had been approved before now take the approved at value
          investment_base_approved_at = this.state.investment_base_approved_at;
        }

        if (investment_base_is_approved_initial_value === false && investment_base_is_approved === true) {
          // if the baseline item has just been approved then take the new approved at value
          let currentdate = new Date();
          investment_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
        }

        let investment_base_last_updated_on_check = this.state.investment_base_last_updated_on_check;

        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
              [exporter_id, business_year, investment_base_currency, additions, additions, opening_nbv, opening_nbv],
              [
                this.Language.DataField.Exporter,
                this.Language.DataField.BusinessYear,
                this.Language.DataField.Currency,
                this.Language.DataField.Additions,
                this.Language.DataField.Additions,
                this.Language.DataField.OpenningNBV,
                this.Language.DataField.OpenningNBV
              ],
              ['isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
              0,
              0
            )
          },
          () => {
            if (this.state.error_messages === null) {
              if (investment_base_currency === 'Select Currency') investment_base_currency = null;

              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                hash_id: this.state.investment_base_hash_id,
                currency: investment_base_currency,
                additions: Math.abs(additions) || null,
                opening_nbv: Math.abs(opening_nbv) || null,
                capital_investment_growth: Math.abs(capital_investment_growth) || null,
                baseline_option: baseline_option,
                status: investment_base_status,
                is_approved: investment_base_is_approved,
                approved_by: investment_base_approved_by,
                approved_at: investment_base_approved_at,
                created_by: last_updated_by,
                last_updated_by: last_updated_by,
                investment_base_last_updated_on_check: investment_base_last_updated_on_check,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true});

              this.updateBaselineData(newBaselineData, position);
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }

      if (baseline_option === 'local_content_base') {
        let temp_exported_items_array = this.state.exported_items_array;

        let new_or_old_record = temp_exported_items_array[position].new_or_old_record;

        let local_content_base_currency = temp_exported_items_array[position].local_content_base_currency;
        if (local_content_base_currency === 'Select Currency') local_content_base_currency = '';

        let exported_item = temp_exported_items_array[position].exported_item;
        if (exported_item === 'Select Exported Item') exported_item = '';

        let foreign_content = temp_exported_items_array[position].foreign_content;
        let local_content = temp_exported_items_array[position].local_content;
        let export_value = temp_exported_items_array[position].export_value;
        let local_content_base_status = 'Inactive';
        if (temp_exported_items_array[position].local_content_base_status_active) local_content_base_status = 'Active';
        if (!temp_exported_items_array[position].local_content_base_status_active) local_content_base_status = 'Inactive';

        let local_content_base_is_approved_initial_value = temp_exported_items_array[position].local_content_base_is_approved_initial_value;
        let local_content_base_is_approved = temp_exported_items_array[position].local_content_base_is_approved;
        let local_content_base_approved_by = null;
        if (local_content_base_is_approved_initial_value === true && local_content_base_is_approved === true)
          local_content_base_approved_by = temp_exported_items_array[position].local_content_base_approved_by;
        if (local_content_base_is_approved_initial_value === false && local_content_base_is_approved === true) {
          local_content_base_approved_by = this.state.logged_in_username;
          this.setState({
            local_content_base_approved_by: this.state.logged_in_username
          });
        }

        let local_content_base_approved_at = null;
        if (local_content_base_is_approved_initial_value === true && local_content_base_is_approved === true) {
          // if the baseline item had been approved before now take the approved at value
          local_content_base_approved_at = temp_exported_items_array[position].local_content_base_approved_at;
        }

        if (local_content_base_is_approved_initial_value === false && local_content_base_is_approved === true) {
          // if the baseline item has just been approved then take the new approved at value
          let currentdate = new Date();
          local_content_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
        }

        const hash_id = temp_exported_items_array[position].local_content_base_hash_id;

        let local_content_base_last_updated_on_check = temp_exported_items_array[position].local_content_base_last_updated_on_check;

        const local_content_items_array = [
          {
            currency: temp_exported_items_array[position].local_content_base_currency,
            exported_item: temp_exported_items_array[position].exported_item,
            export_value: Math.abs(parseFloat(temp_exported_items_array[position].export_value)) || null,
            local_content: Math.abs(parseFloat(temp_exported_items_array[position].local_content)) || null,
            foreign_content: Math.abs(parseFloat(temp_exported_items_array[position].foreign_content)) || null,
            is_approved: temp_exported_items_array[position].local_content_base_is_approved,
            local_content_base_is_approved: temp_exported_items_array[position].local_content_base_is_approved,
            status: temp_exported_items_array[position].local_content_base_status,
            approved_by: local_content_base_approved_by,
            approved_at: local_content_base_approved_at,
            created_by: last_updated_by,
            last_updated_by: last_updated_by,
            local_content_base_last_updated_on_check: local_content_base_last_updated_on_check
          }
        ];

        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
              [exporter_id, business_year, local_content_base_currency, foreign_content, foreign_content, local_content, local_content, export_value, export_value],
              [
                this.Language.DataField.Exporter,
                this.Language.DataField.BusinessYear,
                this.Language.DataField.Currency,
                this.Language.DataField.ForeignContent,
                this.Language.DataField.ForeignContent,
                this.Language.DataField.LocalContent,
                this.Language.DataField.LocalContent,
                this.Language.DataField.ExportValue,
                this.Language.DataField.ExportValue
              ],
              ['isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
              0,
              0
            )
          },
          () => {
            if (this.state.error_messages === null) {
              if (local_content_base_currency === 'Select Currency') local_content_base_currency = null;
              if (exported_item === 'Select Exported Item') exported_item = null;

              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                hash_id: hash_id,
                currency: local_content_base_currency,
                exported_item: exported_item,
                foreign_content: Math.abs(foreign_content) || null,
                local_content: Math.abs(local_content) || null,
                export_value: Math.abs(export_value) || null,
                local_content_items_array: local_content_items_array,
                baseline_option: baseline_option,
                status: local_content_base_status,
                is_approved: local_content_base_is_approved,
                approved_by: local_content_base_approved_by,
                approved_at: local_content_base_approved_at,
                created_by: last_updated_by,
                last_updated_by: last_updated_by,
                local_content_base_last_updated_on_check: local_content_base_last_updated_on_check,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true});

              if (new_or_old_record === 'old') this.updateBaselineData(newBaselineData, position);
              if (new_or_old_record === 'new') this.addBaselineData(newBaselineData, position);
              if (new_or_old_record === 'already_saved') {
                this.setState(
                  {
                    preloader: null,
                    local_content_validation_class_value: 'row orange-text',
                    local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidationAlreadySaved
                  },
                  () => {
                    window.Materialize.toast(this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidationAlreadySaved, 8000, 'rounded deep-orange darken-4');
                  }
                );
              }
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }

      if (baseline_option === 'local_value_added_base') {
        let local_value_added_base_currency = this.refs.local_value_added_base_currency.value;
        if (local_value_added_base_currency === 'Select Currency') local_value_added_base_currency = '';

        let turnover = this.refs.turnover.value;
        let other_incomes = this.refs.other_incomes.value;
        let bought_in_goods_and_materials = this.refs.bought_in_goods_and_materials.value;
        let local_value_added = this.state.local_value_added;
        let local_value_added_base_status = 'Inactive';
        if (this.refs.activate_switch_local_value_added_base.checked) local_value_added_base_status = 'Active';
        if (!this.refs.activate_switch_local_value_added_base.checked) local_value_added_base_status = 'Inactive';

        let local_value_added_base_is_approved_initial_value = this.state.local_value_added_base_is_approved_initial_value;
        let local_value_added_base_is_approved = this.state.local_value_added_base_is_approved;
        let local_value_added_base_approved_by = null;
        if (local_value_added_base_is_approved_initial_value === true && local_value_added_base_is_approved === true)
          local_value_added_base_approved_by = this.state.local_value_added_base_approved_by;
        if (local_value_added_base_is_approved_initial_value === false && local_value_added_base_is_approved === true) {
          local_value_added_base_approved_by = this.state.logged_in_username;
          this.setState({
            local_value_added_base_approved_by: this.state.logged_in_username
          });
        }

        let local_value_added_base_approved_at = null;
        if (local_value_added_base_is_approved_initial_value === true && local_value_added_base_is_approved === true) {
          // if the baseline item had been approved before now take the approved at value
          local_value_added_base_approved_at = this.state.local_value_added_base_approved_at;
        }

        if (local_value_added_base_is_approved_initial_value === false && local_value_added_base_is_approved === true) {
          // if the baseline item has just been approved then take the new approved at value
          let currentdate = new Date();
          local_value_added_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
        }

        let local_value_added_base_last_updated_on_check = this.state.local_value_added_base_last_updated_on_check;

        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
              [exporter_id, business_year, local_value_added_base_currency, turnover, turnover, other_incomes, other_incomes, bought_in_goods_and_materials, bought_in_goods_and_materials],
              [
                this.Language.DataField.Exporter,
                this.Language.DataField.BusinessYear,
                this.Language.DataField.Currency,
                this.Language.DataField.Turnover,
                this.Language.DataField.Turnover,
                this.Language.DataField.OtherIncomes,
                this.Language.DataField.OtherIncomes,
                this.Language.DataField.BoughtInGoodsAndMaterials,
                this.Language.DataField.BoughtInGoodsAndMaterials
              ],
              ['isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber', 'isRequired', 'isNumber'],
              0,
              0
            )
          },
          () => {
            if (this.state.error_messages === null) {
              if (local_value_added_base_currency === 'Select Currency') local_value_added_base_currency = null;

              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                hash_id: this.state.local_value_added_base_hash_id,
                currency: local_value_added_base_currency,
                turnover: Math.abs(turnover) || null,
                other_incomes: Math.abs(other_incomes) || null,
                bought_in_goods_and_materials: Math.abs(bought_in_goods_and_materials) || null,
                local_value_added: Math.abs(local_value_added) || null,
                baseline_option: baseline_option,
                status: local_value_added_base_status,
                is_approved: local_value_added_base_is_approved,
                approved_by: local_value_added_base_approved_by,
                approved_at: local_value_added_base_approved_at,
                created_by: last_updated_by,
                last_updated_by: last_updated_by,
                local_value_added_base_last_updated_on_check: local_value_added_base_last_updated_on_check,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true});

              this.updateBaselineData(newBaselineData, position);
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }

      if (baseline_option === 'priority_sector_base') {
        let priority_sector = this.refs.priority_sector.checked;
        let priority_sector_base_status = 'Inactive';
        if (this.refs.activate_switch_priority_sector_base.checked) priority_sector_base_status = 'Active';
        if (!this.refs.activate_switch_priority_sector_base.checked) priority_sector_base_status = 'Inactive';

        let priority_sector_base_is_approved_initial_value = this.state.priority_sector_base_is_approved_initial_value;
        let priority_sector_base_is_approved = this.state.priority_sector_base_is_approved;
        let priority_sector_base_approved_by = null;
        if (priority_sector_base_is_approved_initial_value === true && priority_sector_base_is_approved === true) priority_sector_base_approved_by = this.state.priority_sector_base_approved_by;
        if (priority_sector_base_is_approved_initial_value === false && priority_sector_base_is_approved === true) {
          priority_sector_base_approved_by = this.state.logged_in_username;
          this.setState({
            priority_sector_base_approved_by: this.state.logged_in_username
          });
        }

        let priority_sector_base_approved_at = null;
        if (priority_sector_base_is_approved_initial_value === true && priority_sector_base_is_approved === true) {
          // if the baseline item had been approved before now take the approved at value
          priority_sector_base_approved_at = this.state.priority_sector_base_approved_at;
        }

        if (priority_sector_base_is_approved_initial_value === false && priority_sector_base_is_approved === true) {
          // if the baseline item has just been approved then take the new approved at value
          let currentdate = new Date();
          priority_sector_base_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
        }

        let priority_sector_base_last_updated_on_check = this.state.priority_sector_base_last_updated_on_check;

        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.AddErrorTitle,
              [exporter_id, business_year],
              [this.Language.DataField.Exporter, this.Language.DataField.BusinessYear],
              ['isRequired', 'isRequired'],
              0,
              0
            )
          },
          () => {
            if (this.state.error_messages === null) {
              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                hash_id: this.state.priority_sector_base_hash_id,
                priority_sector: priority_sector,
                baseline_option: baseline_option,
                status: priority_sector_base_status,
                is_approved: priority_sector_base_is_approved,
                approved_by: priority_sector_base_approved_by,
                approved_at: priority_sector_base_approved_at,
                created_by: last_updated_by,
                last_updated_by: last_updated_by,
                priority_sector_base_last_updated_on_check: priority_sector_base_last_updated_on_check,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true});

              this.updateBaselineData(newBaselineData, position);
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }

      if (baseline_option === 'submit_baseline_data_final') {
        this.setState(
          {
            error_messages: this.Validate.validation(
              this.state.close_error_messages,
              this.Language.ReactValidationMessage.BaselineData.SubmitErrorTitle,
              [exporter_id, business_year],
              [this.Language.DataField.Exporter, this.Language.DataField.BusinessYear],
              ['isRequired', 'isRequired'],
              0,
              0
            )
          },
          () => {
            if (this.state.error_messages === null) {
              const userOS = this.Browser.getOS();

              //make call to database
              const newBaselineData = {
                exporter_id: exporter_id,
                business_year: business_year,
                baseline_option: baseline_option,
                last_updated_by: last_updated_by,
                device_name: this.state.logged_in_username + "'s " + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: 'web',
                token: this.Auth.getToken()
              };


              this.setState({preloader: true, submitbuttondisabled: true});

              this.addBaselineDataFinal(newBaselineData);
            } else {
              window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }
          }
        );
      }
    }
  }

  addBaselineDataFinal(newBaselineData) {
    axios
      .request({
        method: 'put',
        url: `${this.apiURL}/api/v1/baseline/data/submit/baseline/${this.state.hash_organisation}`,
        data: newBaselineData
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {
          if (response.status === 200 && newBaselineData.baseline_option === 'submit_baseline_data_final')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.SubmitSuccessBaselineData + newBaselineData.business_year, 8000, 'rounded green');
        });

        this.setState({preloader: null});

        if (newBaselineData.baseline_option === 'submit_baseline_data_final')
          this.setState({
            submit_baseline_data_button_caption: this.Language.ButtonLabel.Submitted
          });
      })
      .catch(error => {
        if (error.response) {


          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.BaselineData.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400)
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.IncompleteData + ' ' + error.response.data.baseline_message, 10000, 'rounded deep-orange darken-4');
            if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.BaselineData.AlreadySubmitted, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {

        } else {
          // Something happened in setting up the request that triggered an Error

        }


        this.setState({preloader: null, submitbuttondisabled: false});
        // document.getElementById("save_baseline_data_button").removeAttribute("disabled");
      });
  }

  getDropdownValues(pick_list_names) {
    axios
      .get(`${this.apiURL}/api/v1/settings/picklists`, {
        params: {
          tk: this.Auth.getToken(),
          pick_list_names: pick_list_names
        }
      })
      .then(response => {
        this.setState({pick_list_values: response.data.data}, () => {

          if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {
            const select_field_values_exporter = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'single_exporter') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({
              select_field_values_exporter: select_field_values_exporter
            });

            const select_field_values_exported_item = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'single_exported_item') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({
              select_field_values_exported_item: select_field_values_exported_item
            });

            const select_field_values_currency = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'currency' && pick_list_value.item_name === 'NGN') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({
              select_field_values_currency: select_field_values_currency
            });

            const select_field_values_business_year = this.state.select_field_values_business_year.map((business_year_value, i) => {
              return <SelectField pick_list_id={business_year_value} key={i} item_name={business_year_value} />;
            });

            this.setState({
              select_field_values_business_year: select_field_values_business_year
            });
          }
        });

        window.$('select').material_select();
        this.setState({preloader: null});
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {

        } else {
          // Something happened in setting up the request that triggered an Error

        }


        this.setState({preloader: null});
      });
  }

  updateBaselineData(newBaselineData, position) {
    let exporterId = this.props.match.params.id;

    axios
      .request({
        method: 'put',
        url: `${this.apiURL}/api/v1/baseline/data/${exporterId}`,
        data: newBaselineData
      })
      .then(response => {
        this.setState({response_status: response.data.status});

        this.setState({message: response.data.message}, () => {


          if (response.status === 200 && newBaselineData.baseline_option === 'baseline_notes')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateSuccessBaselineNote + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'employment_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateSuccessEmploymentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'export_growth_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateSuccessExportGrowthBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'investment_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateSuccessInvestmentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'local_content_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateSuccessLocalContentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'local_value_added_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateSuccessLocalValueBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'priority_sector_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateSuccessPrioritySectorBase + newBaselineData.business_year, 8000, 'rounded green');
        });

        this.setState({preloader: null});

        if (newBaselineData.baseline_option === 'baseline_notes')
          this.setState({
            data_badge_caption_baseline_notes: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'employment_base')
          this.setState({
            data_badge_caption_employment_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'export_growth_base')
          this.setState({
            data_badge_caption_export_growth_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'investment_base')
          this.setState({
            data_badge_caption_investment_base: this.Language.ButtonLabel.ItemSaved
          });

        if (newBaselineData.baseline_option === 'local_content_base') {
          let temp_exported_items_array = this.state.exported_items_array;
          const len = temp_exported_items_array.length;

          for (let i = 0; i < len; i++) {
            if (i === position) {
              temp_exported_items_array[position].data_badge_caption_local_content_base = this.Language.ButtonLabel.ItemSaved;
              this.setState({
                exported_items_array: temp_exported_items_array
              });
            }
          }
        }

        if (newBaselineData.baseline_option === 'local_value_added_base')
          this.setState({
            data_badge_caption_local_value_added_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'priority_sector_base')
          this.setState({
            data_badge_caption_priority_sector_base: this.Language.ButtonLabel.ItemSaved
          });
      })
      .catch(error => {
        if (error.response) {
          // if the record does not exit then attempt to create a new entry
          if (error.response.status === 400) {
            let message = '';
            if (newBaselineData.baseline_option === 'baseline_notes') message = this.Language.ConfirmMessage.AddBaselineNote;
            if (newBaselineData.baseline_option === 'employment_base') message = this.Language.ConfirmMessage.AddEmploymentBase;
            if (newBaselineData.baseline_option === 'export_growth_base') message = this.Language.ConfirmMessage.AddExportGrowth;
            if (newBaselineData.baseline_option === 'investment_base') message = this.Language.ConfirmMessage.AddInvestmentBase;
            if (newBaselineData.baseline_option === 'local_content_base') message = this.Language.ConfirmMessage.AddLocalContent;
            if (newBaselineData.baseline_option === 'local_value_added_base') message = this.Language.ConfirmMessage.AddLocalValueAdded;
            if (newBaselineData.baseline_option === 'priority_sector_base') message = this.Language.ConfirmMessage.AddPrioritySector;

            if (window.confirm(`${message}`)) {
              this.addBaselineData(newBaselineData, position);
            }
          }
          if (error.response.status !== 400) {


            if (error.response.data.message[0].msg) {
              let len = error.response.data.message.length;

              for (let i = 0; i < len; i++) {
                window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
              }
            } else {
              if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateError, 8000, 'rounded deep-orange darken-4');
              if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.BaselineData.DoesNotExist, 8000, 'rounded deep-orange darken-4');
              if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.BaselineData.UpdateConflict, 8000, 'rounded deep-orange darken-4');
            }
          }
        } else if (error.request) {

        } else {
          // Something happened in setting up the request that triggered an Error

        }


        this.setState({preloader: null});

        if (newBaselineData.baseline_option === 'baseline_notes')
          this.setState({
            data_badge_caption_baseline_notes: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'employment_base')
          this.setState({
            data_badge_caption_employment_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'export_growth_base')
          this.setState({
            data_badge_caption_export_growth_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'investment_base')
          this.setState({
            data_badge_caption_investment_base: this.Language.ButtonLabel.Save
          });

        if (newBaselineData.baseline_option === 'local_content_base') {
          let temp_exported_items_array = this.state.exported_items_array;
          const len = temp_exported_items_array.length;

          for (let i = 0; i < len; i++) {
            if (i === position) {
              temp_exported_items_array[position].data_badge_caption_local_content_base = this.Language.ButtonLabel.Save;
              this.setState({
                exported_items_array: temp_exported_items_array
              });
            }
          }
        }

        if (newBaselineData.baseline_option === 'local_value_added_base')
          this.setState({
            data_badge_caption_local_value_added_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'priority_sector_base')
          this.setState({
            data_badge_caption_priority_sector_base: this.Language.ButtonLabel.Save
          });
      });
  }

  addBaselineData(newBaselineData, position) {
    axios
      .request({
        method: 'post',
        url: `${this.apiURL}/api/v1/baseline/data`,
        data: newBaselineData
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {
          if (response.status === 200 && newBaselineData.baseline_option === 'baseline_notes')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessBaselineNote + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'employment_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessEmploymentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'export_growth_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessExportGrowthBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'investment_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessInvestmentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'local_content_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessLocalContentBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'local_value_added_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessLocalValueBase + newBaselineData.business_year, 8000, 'rounded green');
          if (response.status === 200 && newBaselineData.baseline_option === 'priority_sector_base')
            window.Materialize.toast(this.Language.NodeMessage.BaselineData.AddSuccessPrioritySectorBase + newBaselineData.business_year, 8000, 'rounded green');
        });

        this.setState({preloader: null});

        if (newBaselineData.baseline_option === 'baseline_notes')
          this.setState({
            data_badge_caption_baseline_notes: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'employment_base')
          this.setState({
            data_badge_caption_employment_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'export_growth_base')
          this.setState({
            data_badge_caption_export_growth_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'investment_base')
          this.setState({
            data_badge_caption_investment_base: this.Language.ButtonLabel.ItemSaved
          });

        if (newBaselineData.baseline_option === 'local_content_base') {
          let temp_exported_items_array = this.state.exported_items_array;
          const len = temp_exported_items_array.length;

          for (let i = 0; i < len; i++) {
            if (i === position) {
              temp_exported_items_array[position].data_badge_caption_local_content_base = this.Language.ButtonLabel.ItemSaved;
              temp_exported_items_array[position].new_or_old_record = 'already_saved';
              this.setState({
                exported_items_array: temp_exported_items_array,
                local_content_validation_class_value: 'row green-text',
                local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidationSaved
              });
            }
          }
        }

        if (newBaselineData.baseline_option === 'local_value_added_base')
          this.setState({
            data_badge_caption_local_value_added_base: this.Language.ButtonLabel.ItemSaved
          });
        if (newBaselineData.baseline_option === 'priority_sector_base')
          this.setState({
            data_badge_caption_priority_sector_base: this.Language.ButtonLabel.ItemSaved
          });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.BaselineData.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.BaselineData.AlreadyExist, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error

        }


        this.setState({preloader: null});
        // document.getElementById("save_baseline_data_button").removeAttribute("disabled");

        if (newBaselineData.baseline_option === 'baseline_notes')
          this.setState({
            data_badge_caption_baseline_notes: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'employment_base')
          this.setState({
            data_badge_caption_employment_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'export_growth_base')
          this.setState({
            data_badge_caption_export_growth_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'investment_base')
          this.setState({
            data_badge_caption_investment_base: this.Language.ButtonLabel.Save
          });

        if (newBaselineData.baseline_option === 'local_content_base') {
          let temp_exported_items_array = this.state.exported_items_array;
          const len = temp_exported_items_array.length;

          for (let i = 0; i < len; i++) {
            if (i === position) {
              temp_exported_items_array[position].data_badge_caption_local_content_base = this.Language.ButtonLabel.Save;
              this.setState({
                exported_items_array: temp_exported_items_array
              });
              this.setState({
                exported_items_array: temp_exported_items_array,
                local_content_validation_class_value: 'row red-text',
                local_content_validation_message: this.Language.ReactValidationMessage.BaselineData.LocalContentItemValidationNotSaved
              });
            }
          }
        }

        if (newBaselineData.baseline_option === 'local_value_added_base')
          this.setState({
            data_badge_caption_local_value_added_base: this.Language.ButtonLabel.Save
          });
        if (newBaselineData.baseline_option === 'priority_sector_base')
          this.setState({
            data_badge_caption_priority_sector_base: this.Language.ButtonLabel.Save
          });
      });
  }

  onDeleteBaselineData(baseline_option, baseline_data_hash_id, position) {
    let message = this.Language.ConfirmMessage.DeleteBaseline; // default message

    if (baseline_option === 'baseline_notes') message = this.Language.ConfirmMessage.DeleteBaselineNote;
    if (baseline_option === 'employment_base') message = this.Language.ConfirmMessage.DeleteEmploymentBase;
    if (baseline_option === 'export_growth_base') message = this.Language.ConfirmMessage.DeleteExportGrowth;
    if (baseline_option === 'investment_base') message = this.Language.ConfirmMessage.DeleteInvestmentBase;
    if (baseline_option === 'local_content_base') message = this.Language.ConfirmMessage.DeleteLocalContent;
    if (baseline_option === 'local_value_added_base') message = this.Language.ConfirmMessage.DeleteLocalValueAdded;
    if (baseline_option === 'priority_sector_base') message = this.Language.ConfirmMessage.DeletePrioritySector;

    if (window.confirm(`${message}`)) {
      let exporter_id = this.state.exporter_id;
      let business_year = this.state.business_year;
      let logged_in_username = this.state.logged_in_username;
      let exporterId = this.props.match.params.id;

      const userOS = this.Browser.getOS();

      const deleteBaselineData = {
        exporter_id: exporter_id,
        business_year: business_year,
        baseline_option: baseline_option,
        baseline_data_hash_id: baseline_data_hash_id,
        last_updated_by: logged_in_username,
        device_name: this.state.logged_in_username + "'s " + userOS,
        device_model: userOS,
        device_os: userOS,
        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
        app_source: 'web',
        token: this.Auth.getToken()
      };

      axios
        .request({
          method: 'delete',
          url: `${this.apiURL}/api/v1/baseline/data/${exporterId}`,
          data: deleteBaselineData
        })
        .then(response => {
          this.setState({status: response.data.status});

          this.setState({message: response.data.message}, () => {

            if (deleteBaselineData.baseline_option === 'baseline_notes')
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessBaselineNote + deleteBaselineData.business_year, 8000, 'rounded green');
            if (deleteBaselineData.baseline_option === 'employment_base')
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessEmploymentBase + deleteBaselineData.business_year, 8000, 'rounded green');
            if (deleteBaselineData.baseline_option === 'export_growth_base')
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessExportGrowthBase + deleteBaselineData.business_year, 8000, 'rounded green');
            if (deleteBaselineData.baseline_option === 'investment_base')
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessInvestmentBase + deleteBaselineData.business_year, 8000, 'rounded green');
            if (deleteBaselineData.baseline_option === 'local_content_base')
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessLocalContentBase + deleteBaselineData.business_year, 8000, 'rounded green');
            if (deleteBaselineData.baseline_option === 'local_value_added_base')
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessLocalValueBase + deleteBaselineData.business_year, 8000, 'rounded green');
            if (deleteBaselineData.baseline_option === 'priority_sector_base')
              window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessPrioritySectorBase + deleteBaselineData.business_year, 8000, 'rounded green');
          });

          if (deleteBaselineData.baseline_option === 'baseline_notes') {
            this.setState({
              data_badge_caption_baseline_notes_delete: this.Language.ButtonLabel.ItemDeleted,
              notes: '',
              reviewer_notes: '',
              baseline_note_status: '',
              baseline_note_status_active: false,
              baseline_note_last_updated_by: '',
              baseline_note_hash_id: ''
            });
          }

          if (deleteBaselineData.baseline_option === 'employment_base') {
            this.setState({
              data_badge_caption_employment_base_delete: this.Language.ButtonLabel.ItemDeleted,
              total_staff_strength: '',
              total_nigerian_staff: '',
              employment_base_status: '',
              employment_base_status_active: false,
              employment_base_last_updated_by: '',
              employment_base_is_approved_initial_value: false,
              employment_base_is_approved: false,
              employment_base_approved_by: '',
              employment_base_approved_at: '',
              employment_base_hash_id: ''
            });
          }

          if (deleteBaselineData.baseline_option === 'export_growth_base') {
            this.setState({
              data_badge_caption_export_growth_base_delete: this.Language.ButtonLabel.ItemDeleted,
              export_growth_base_currency: '',
              total_export_value: '',
              export_growth_rate: '',
              export_growth_base_status: '',
              export_growth_base_status_active: false,
              export_growth_base_last_updated_by: '',
              export_growth_base_is_approved_initial_value: false,
              export_growth_base_is_approved: false,
              export_growth_base_approved_by: '',
              export_growth_base_approved_at: '',
              export_growth_base_hash_id: ''
            });
          }

          if (deleteBaselineData.baseline_option === 'investment_base') {
            this.setState({
              data_badge_caption_investment_base_delete: this.Language.ButtonLabel.ItemDeleted,
              investment_base_currency: '',
              additions: '',
              opening_nbv: '',
              capital_investment_growth: '',
              investment_base_status: '',
              investment_base_status_active: false,
              investment_base_last_updated_by: '',
              investment_base_is_approved_initial_value: false,
              investment_base_is_approved: false,
              investment_base_approved_by: '',
              investment_base_approved_at: '',
              investment_base_hash_id: ''
            });
          }

          if (deleteBaselineData.baseline_option === 'local_content_base') {
            let temp_exported_items_array = this.state.exported_items_array;
            let len = temp_exported_items_array.length;

            for (let i = 0; i < len; i++) {
              temp_exported_items_array = temp_exported_items_array.filter(value => value.local_content_base_hash_id !== deleteBaselineData.baseline_data_hash_id);

              // if (temp_exported_items_array[i].local_content_base_hash_id === deleteBaselineData.baseline_data_hash_id) {

              //     temp_exported_items_array[i].exported_item = '';
              //     temp_exported_items_array[i].local_content_base_currency= '';
              //     temp_exported_items_array[i].foreign_content = '';
              //     temp_exported_items_array[i].local_content = '';
              //     temp_exported_items_array[i].export_value = '';
              //     temp_exported_items_array[i].local_content_base_status = '';
              //     temp_exported_items_array[i].local_content_base_last_updated_by = '';
              //     temp_exported_items_array[i].local_content_base_is_approved_initial_value = false;
              //     temp_exported_items_array[i].local_content_base_is_approved = false;
              //     temp_exported_items_array[i].local_content_base_approved_by = '';
              //     temp_exported_items_array[i].local_content_base_approved_at = '';
              //     temp_exported_items_array[i]. local_content_base_hash_id = '';

              // }
            }

            this.setState({exported_items_array: temp_exported_items_array});

            if (this.state.exported_items_array.length === 0) {
              let temp_exported_items_array = this.state.exported_items_array;
              const len = temp_exported_items_array.length;

              for (let i = 0; i < len; i++) {
                if (i === position) {
                  temp_exported_items_array[position].data_badge_caption_local_content_base_delete = this.Language.ButtonLabel.ItemDeleted;
                  this.setState({
                    exported_items_array: temp_exported_items_array
                  });
                }
              }
            }
          }

          if (deleteBaselineData.baseline_option === 'local_value_added_base') {
            this.setState({
              data_badge_caption_local_value_added_base_delete: this.Language.ButtonLabel.ItemDeleted,
              local_value_added_base_currency: '',
              turnover: '',
              other_incomes: '',
              bought_in_goods_and_materials: '',
              local_value_added: '',
              local_value_added_base_status: '',
              local_value_added_base_status_active: false,
              local_value_added_base_last_updated_by: '',
              local_value_added_base_is_approved_initial_value: false,
              local_value_added_base_is_approved: false,
              local_value_added_base_approved_by: '',
              local_value_added_base_approved_at: '',
              local_value_added_base_hash_id: ''
            });
          }

          if (deleteBaselineData.baseline_option === 'priority_sector_base') {
            this.setState({
              data_badge_caption_priority_sector_base_delete: this.Language.ButtonLabel.ItemDeleted,
              exporter_category: '',
              priority_sector: false,
              priority_sector_score: '',
              priority_sector_base_status: '',
              priority_sector_base_status_active: false,
              priority_sector_base_last_updated_by: '',
              priority_sector_base_is_approved_initial_value: false,
              priority_sector_base_is_approved: false,
              priority_sector_base_approved_by: '',
              priority_sector_base_approved_at: '',
              priority_sector_base_hash_id: ''
            });
          }
        })
        .catch(error => {
          if (error.response) {

            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');
          } else if (error.request) {
          } else {
            // Something happened in setting up the request that triggered an Error
          }


          if (deleteBaselineData.baseline_option === 'baseline_notes')
            this.setState({
              data_badge_caption_baseline_notes_delete: this.Language.ButtonLabel.Delete
            });
          if (deleteBaselineData.baseline_option === 'employment_base')
            this.setState({
              data_badge_caption_employment_base_delete: this.Language.ButtonLabel.Delete
            });
          if (deleteBaselineData.baseline_option === 'export_growth_base')
            this.setState({
              data_badge_caption_export_growth_base_delete: this.Language.ButtonLabel.Delete
            });
          if (deleteBaselineData.baseline_option === 'investment_base')
            this.setState({
              data_badge_caption_investment_base_delete: this.Language.ButtonLabel.Delete
            });

          if (deleteBaselineData.baseline_option === 'local_content_base') {
            let temp_exported_items_array = this.state.exported_items_array;
            const len = temp_exported_items_array.length;

            for (let i = 0; i < len; i++) {
              if (i === position) {
                temp_exported_items_array[position].data_badge_caption_local_content_base_delete = this.Language.ButtonLabel.Delete;
                this.setState({
                  exported_items_array: temp_exported_items_array
                });
              }
            }
          }

          if (deleteBaselineData.baseline_option === 'local_value_added_base')
            this.setState({
              data_badge_caption_local_value_added_base_delete: this.Language.ButtonLabel.Delete
            });
          if (deleteBaselineData.baseline_option === 'priority_sector_base')
            this.setState({
              data_badge_caption_priority_sector_base_delete: this.Language.ButtonLabel.Delete
            });
        });
    }
  }

  onDeleteBaselineDocument(document_option, document_name, baseline_document_hash_id, position) {
    let message = this.Language.ConfirmMessage.DeleteBaselineDocument + ' - ' + document_name; // default message

    if (window.confirm(`${message}`)) {
      let exporter_id = this.state.exporter_id;
      let business_year = this.state.business_year;
      let logged_in_username = this.state.logged_in_username;

      const userOS = this.Browser.getOS();

      const deleteBaselineDocument = {
        exporter_id: exporter_id,
        business_year: business_year,
        document_option: document_option,
        baseline_document_hash_id: baseline_document_hash_id,
        last_updated_by: logged_in_username,
        device_name: this.state.logged_in_username + "'s " + userOS,
        device_model: userOS,
        device_os: userOS,
        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
        app_source: 'web',
        token: this.Auth.getToken()
      };



      axios
        .request({
          method: 'delete',
          url: `${this.apiURL}/api/v1/file/documents/${baseline_document_hash_id}`,
          data: deleteBaselineDocument
        })
        .then(response => {
          this.setState({status: response.data.status});

          this.setState({message: response.data.message}, () => {


            window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteSuccessBaselineDocument + deleteBaselineDocument.business_year, 8000, 'rounded green');
          });

          let temp_baseline_documents_array = this.state.baseline_documents;
          temp_baseline_documents_array = temp_baseline_documents_array.filter((value, i) => i !== position);
          this.setState({baseline_documents: temp_baseline_documents_array});
        })
        .catch(error => {
          if (error.response) {


            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 404) window.Materialize.toast(this.Language.NodeMessage.BaselineData.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');
          } else if (error.request) {

          } else {
            // Something happened in setting up the request that triggered an Error

          }

        });
    }
  }

  render() {
    let exporterId = this.props.match.params.id;
    let businessYear = this.props.match.params.yr;

    window.$('.collapsible').collapsible();
    $('.activate_switch_local_content_base_new')
      .find('input[type=checkbox]')
      .prop('checked', true);

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Baseline Data'} active_menu_level_2={'Baseline Profile'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.EditBaselineData}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.BaselineDataProfile, this.Language.BreadCrumbLabel.EditBaselineData]}
              links={[`/`, `/exporter/${exporterId}/baseline/profile`, `/exporters/${exporterId}/baseline/profile/edit/${businessYear}`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <div className="row">
                <div className="col s12 l12">
                  {this.state.error_messages}

                  <div className="card-panel">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <h4 className="header2">{this.Language.PageLabel.EditExporter}</h4>
                    <div className="row">
                      <div className="row">
                        <div className="input-field col s8">
                          <div className="select-wrapper initialized">
                            <span className="caret">▼</span>
                            <select data-select-id="select-options-exporter_id" className="initialized" name="exporter_id" id="exporter_id" ref="exporter_id">
                              {this.state.exporter_id && (
                                <option value={this.state.exporter_id} selected>
                                  {this.state.exporter_name}
                                </option>
                              )}
                              <option value="Select Exporter">{this.Language.DataFieldDefault.SelectExporter}</option>
                              {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                return exporter_value;
                              })}
                            </select>
                          </div>
                          <label>{this.Language.DataField.Exporter} *</label>
                        </div>
                        <div className="input-field col s4">
                          <div className="select-wrapper initialized">
                            <span className="caret">▼</span>
                            <select data-select-id="select-options-business_year" className="initialized" name="business_year" id="business_year" ref="business_year">
                              {this.state.business_year && (
                                <option value={this.state.business_year} selected>
                                  {this.state.business_year}
                                </option>
                              )}
                              <option value="Select Business Year">{this.Language.DataFieldDefault.SelectBusinessYear}</option>
                              {this.state.select_field_values_business_year.map((business_year_value, i) => {
                                return business_year_value;
                              })}
                            </select>
                          </div>
                          <label>
                            {this.Language.DataField.BusinessYear} * - {this.Language.PageLabel.ForSubsequentYearsExports}
                          </label>
                        </div>
                      </div>

                      <ul className="collapsible" data-collapsible="expandable">
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">supervisor_account</i>
                            {this.Language.DataField.Employment}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s4">
                                <input
                                  id="total_staff_strength"
                                  name="total_staff_strength"
                                  type="number"
                                  className="validate"
                                  ref="total_staff_strength"
                                  value={this.state.total_staff_strength}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="total_staff_strength"
                                  data-error={this.Language.DataFieldError.IntegerNumber}
                                  className={this.state.total_staff_strength && this.state.total_staff_strength !== null && typeof this.state.total_staff_strength !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.TotalStaffStrength} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input
                                  id="total_nigerian_staff"
                                  name="total_nigerian_staff"
                                  type="number"
                                  className="validate"
                                  ref="total_nigerian_staff"
                                  value={this.state.total_nigerian_staff}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="total_nigerian_staff"
                                  data-error={this.Language.DataFieldError.IntegerNumber}
                                  className={this.state.total_nigerian_staff && this.state.total_nigerian_staff !== null && typeof this.state.total_nigerian_staff !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.TotalNigerianStaff} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="employment_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="employment_base_is_approved" ref="employment_base_is_approved" checked={this.state.employment_base_is_approved} readOnly={true} />
                                  <label htmlFor="employment_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input
                                      type="checkbox"
                                      name="activate_switch_employment_base"
                                      ref="activate_switch_employment_base"
                                      id="activate_switch_employment_base"
                                      checked={this.state.employment_base_status_active}
                                      onChange={this.handleCheckBoxChange}
                                    />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                {this.state.employment_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.onDeleteBaselineData('employment_base', this.state.employment_base_hash_id, 0);
                                    }}>
                                    <span className="new badge grey gradient-shadow" data-badge-caption={this.state.data_badge_caption_employment_base_delete}></span>
                                  </Link>
                                )}
                                {this.state.employment_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.submitBaselineData('employment_base', 0);
                                    }}>
                                    <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_employment_base}></span>
                                  </Link>
                                )}
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.EmploymentBase}>
                              help_outline
                            </i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">trending_up</i>
                            {this.Language.DataField.ExportGrowth}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id="select-options-export_growth_base_currency"
                                    className="initialized"
                                    name="export_growth_base_currency"
                                    id="export_growth_base_currency"
                                    ref="export_growth_base_currency">
                                    {this.state.export_growth_base_currency && (
                                      <option value={this.state.export_growth_base_currency} selected>
                                        {this.state.export_growth_base_currency}
                                      </option>
                                    )}
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s5">
                                <input
                                  id="total_export_value"
                                  name="total_export_value"
                                  type="number"
                                  step="any"
                                  className="validate"
                                  ref="total_export_value"
                                  value={this.state.total_export_value}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="total_export_value"
                                  data-error={this.Language.DataFieldError.DecimalNumber}
                                  className={this.state.total_export_value && this.state.total_export_value !== null && typeof this.state.total_export_value !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.TotalExportValue} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="export_growth_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="export_growth_base_is_approved" ref="export_growth_base_is_approved" checked={this.state.export_growth_base_is_approved} readOnly={true} />
                                  <label htmlFor="export_growth_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input
                                      type="checkbox"
                                      name="activate_switch_export_growth_base"
                                      ref="activate_switch_export_growth_base"
                                      id="activate_switch_export_growth_base"
                                      checked={this.state.export_growth_base_status_active}
                                      onChange={this.handleCheckBoxChange}
                                    />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                {this.state.export_growth_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.onDeleteBaselineData('export_growth_base', this.state.export_growth_base_hash_id, 0);
                                    }}>
                                    <span className="new badge grey gradient-shadow" data-badge-caption={this.state.data_badge_caption_export_growth_base_delete}></span>
                                  </Link>
                                )}
                                {this.state.export_growth_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.submitBaselineData('export_growth_base', 0);
                                    }}>
                                    <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_export_growth_base}></span>
                                  </Link>
                                )}
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.ExportGrowthBase}>
                              help_outline
                            </i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">account_balance_wallet</i>
                            {this.Language.DataField.CapitalInvestment}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id="select-options-investment_base_currency"
                                    className="initialized"
                                    name="investment_base_currency"
                                    id="investment_base_currency"
                                    ref="investment_base_currency">
                                    {this.state.investment_base_currency && (
                                      <option value={this.state.investment_base_currency} selected>
                                        {this.state.investment_base_currency}
                                      </option>
                                    )}
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s3">
                                <input id="additions" name="additions" type="number" step="any" className="validate" ref="additions" value={this.state.additions} onChange={this.handleInputChange} />
                                <label
                                  htmlFor="additions"
                                  data-error={this.Language.DataFieldError.DecimalNumber}
                                  className={this.state.additions && this.state.additions !== null && typeof this.state.additions !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.Additions} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s3">
                                <input
                                  id="opening_nbv"
                                  name="opening_nbv"
                                  type="number"
                                  step="any"
                                  className="validate"
                                  ref="opening_nbv"
                                  value={this.state.opening_nbv}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="opening_nbv"
                                  data-error={this.Language.DataFieldError.DecimalNumber}
                                  className={this.state.opening_nbv && this.state.opening_nbv !== null && typeof this.state.opening_nbv !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.OpenningNBV} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="investment_base_is_approved col s4">
                                <p>
                                  <input type="checkbox" id="investment_base_is_approved" ref="investment_base_is_approved" checked={this.state.investment_base_is_approved} readOnly={true} />
                                  <label htmlFor="investment_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input
                                      type="checkbox"
                                      name="activate_switch_investment_base"
                                      ref="activate_switch_investment_base"
                                      id="activate_switch_investment_base"
                                      checked={this.state.investment_base_status_active}
                                      onChange={this.handleCheckBoxChange}
                                    />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                {this.state.investment_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.onDeleteBaselineData('investment_base', this.state.investment_base_hash_id, 0);
                                    }}>
                                    <span className="new badge grey gradient-shadow" data-badge-caption={this.state.data_badge_caption_investment_base_delete}></span>
                                  </Link>
                                )}
                                {this.state.investment_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.submitBaselineData('investment_base', 0);
                                    }}>
                                    <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_investment_base}></span>
                                  </Link>
                                )}
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.InvestmentBase}>
                              help_outline
                            </i>
                          </div>
                        </li>

                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">chrome_reader_mode</i>
                            {this.Language.DataField.LocalContent}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s8">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select data-select-id="select-options-exported_item_new" className="initialized" name="exported_item_new" id="exported_item_new" ref="exported_item_new">
                                    <option value="Select Exported Item">{this.Language.DataFieldDefault.SelectExportedItem}</option>
                                    {this.state.select_field_values_exported_item.map((exported_item_value, i) => {
                                      return exported_item_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.ExportedItem} *</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id={'select-options-local_content_base_currency_new'}
                                    className="initialized"
                                    name={'local_content_base_currency_new'}
                                    id={'local_content_base_currency_new'}
                                    ref={'local_content_base_currency_new'}>
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s6">
                                <input id="export_value_new" name="export_value_new" type="number" step="any" className="validate" ref="export_value_new" />
                                <label htmlFor="export_value_new" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.ExportValue} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s6">
                                <input id="foreign_content_new" name="foreign_content_new" type="number" step="any" className="validate" ref="foreign_content_new" />
                                <label htmlFor="foreign_content_new" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.ForeignContent} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <input id="local_content_new" name="local_content_new" type="number" step="any" className="validate" ref="local_content_new" />
                                <label htmlFor="local_content_new" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                  {this.Language.DataField.LocalContent} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="activate_switch_local_content_base_new col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input type="checkbox" name="activate_switch_local_content_base_new" ref="activate_switch_local_content_base_new" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className={this.state.local_content_validation_class_value}>
                              <div className="col s12">{this.state.local_content_validation_message}</div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                {this.state.is_submitted === false && (
                                  <Link to={`#!`} onClick={this.addLocalContentItem}>
                                    <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.AddLocalContentItem}></span>
                                  </Link>
                                )}
                              </div>
                            </div>
                            <div className="divider"></div>
                            <div className="divider"></div>
                            <div className="divider"></div>
                            <br />
                            <br />
                            <div className="row teal-text">
                              <div className="col s2">
                                <span>
                                  <b>{this.Language.DataField.LocalContentItems}:</b>{' '}
                                  {this.state.exported_items_array && this.state.exported_items_array.length > 0 ? this.state.exported_items_array.length : null}
                                </span>
                              </div>
                              <div className="col s3">
                                <span>
                                  <b>{this.Language.DataField.TotalExportValue}:</b> {isNaN(this.state.total_local_content_items_amount) === false ? this.state.total_local_content_items_amount : null}
                                </span>
                              </div>
                              <div className="col s3">
                                <span>
                                  <b>{this.Language.DataField.TotalLocalContent}:</b> {isNaN(this.state.total_local_content_value) === false ? this.state.total_local_content_value : null}
                                </span>
                              </div>
                              <div className="col s3">
                                <span>
                                  <b>{this.Language.DataField.TotalForeignContent}:</b> {isNaN(this.state.total_foreign_content_value) === false ? this.state.total_foreign_content_value : null}
                                </span>
                              </div>
                            </div>
                            <br />
                            <br />
                            {this.state.exported_items_array.map((local_content_value, i) => {
                              if (local_content_value.exported_item !== '' && local_content_value.exported_item !== null && typeof local_content_value.exported_item !== 'undefined') {
                                return [
                                  <div className="row" key={i}>
                                    <div className="input-field col s8">
                                      <input id="exported_item_" name="exported_item_" type="text" ref="exported_item_" defaultValue={local_content_value.exported_item} disabled={true} />
                                      <label htmlFor="exported_item_" className="active">
                                        {this.Language.DataField.ExportedItem}
                                        {/* ::after */}
                                      </label>
                                    </div>
                                  </div>,
                                  <div className="row" key={i + 1}>
                                    <div className="input-field col s3">
                                      <input
                                        id="local_content_base_currency"
                                        name="local_content_base_currency"
                                        type="text"
                                        ref="local_content_base_currency"
                                        defaultValue={local_content_value.local_content_base_currency}
                                        disabled={true}
                                      />
                                      <label htmlFor="local_content_base_currency" className="active">
                                        {this.Language.DataField.Currency}
                                        {/* ::after */}
                                      </label>
                                    </div>
                                    <div className="input-field col s6">
                                      <input
                                        id={'export_value_' + i}
                                        name={'export_value_' + i}
                                        type="number"
                                        step="any"
                                        className="validate"
                                        ref={'export_value_' + i}
                                        value={local_content_value.export_value}
                                        onChange={this.handleInputChange}
                                      />
                                      <label
                                        htmlFor={'export_value_' + i}
                                        data-error={this.Language.DataFieldError.DecimalNumber}
                                        className={
                                          local_content_value.export_value && local_content_value.export_value !== null && typeof local_content_value.export_value !== 'undefined' ? 'active' : ''
                                        }>
                                        {this.Language.DataField.ExportValue} *{/* ::after */}
                                      </label>
                                    </div>
                                  </div>,
                                  <div className="row" key={i + 2}>
                                    <div className="input-field col s6">
                                      <input
                                        id={'foreign_content_' + i}
                                        name={'foreign_content_' + i}
                                        type="number"
                                        step="any"
                                        className="validate"
                                        ref={'foreign_content_' + i}
                                        value={local_content_value.foreign_content}
                                        onChange={this.handleInputChange}
                                      />
                                      <label
                                        htmlFor={'foreign_content_' + i}
                                        data-error={this.Language.DataFieldError.DecimalNumber}
                                        className={
                                          local_content_value.foreign_content && local_content_value.foreign_content !== null && typeof local_content_value.foreign_content !== 'undefined'
                                            ? 'active'
                                            : ''
                                        }>
                                        {this.Language.DataField.ForeignContent} *{/* ::after */}
                                      </label>
                                    </div>
                                    <div className="input-field col s4">
                                      <input
                                        id={'local_content_' + i}
                                        name={'local_content_' + i}
                                        type="number"
                                        step="any"
                                        className="validate"
                                        ref={'local_content_' + i}
                                        value={local_content_value.local_content}
                                        onChange={this.handleInputChange}
                                      />
                                      <label
                                        htmlFor={'local_content_' + i}
                                        data-error={this.Language.DataFieldError.DecimalNumber}
                                        className={
                                          local_content_value.local_content && local_content_value.local_content !== null && typeof local_content_value.local_content !== 'undefined' ? 'active' : ''
                                        }>
                                        {this.Language.DataField.LocalContent} *{/* ::after */}
                                      </label>
                                    </div>
                                    <div className="input-field col s2">
                                      <span>
                                        {this.Language.DataField.Percentage}:{' '}
                                        {!isNaN(parseFloat(local_content_value.local_content) && !isNaN(parseFloat(local_content_value.foreign_content)))
                                          ? this.FormatService.FormatLocaleNumber(
                                              (parseFloat(local_content_value.local_content) / (parseFloat(local_content_value.foreign_content) + parseFloat(local_content_value.local_content))) * 100,
                                              this.Language.Lang,
                                              true,
                                              2
                                            ) + '%'
                                          : null}
                                      </span>
                                    </div>
                                  </div>,
                                  <div className="row" key={i + 3}>
                                    <div className="col s8">
                                      <br />
                                      <div className="switch">
                                        <label className="font-weight-600 black-text">
                                          {this.Language.DataField.Inactive}
                                          <input
                                            type="checkbox"
                                            name={'activate_switch_local_content_base_' + i}
                                            ref={'activate_switch_local_content_base_' + i}
                                            id={'activate_switch_local_content_base_' + i}
                                            checked={local_content_value.local_content_base_status_active}
                                            onChange={this.handleCheckBoxChange}
                                          />
                                          <span className="lever"></span>
                                          {this.Language.DataField.Active}
                                        </label>
                                      </div>
                                    </div>
                                  </div>,
                                  <div className="row" key={i + 4}>
                                    <div className="col s12">
                                      {local_content_value.new_or_old_record === 'old' && local_content_value.local_content_base_is_approved === false && this.state.is_submitted === false && (
                                        <Link
                                          to={`#!`}
                                          onClick={() => {
                                            this.onDeleteBaselineData('local_content_base', local_content_value.local_content_base_hash_id, i);
                                          }}>
                                          <span className="new badge grey gradient-shadow" data-badge-caption={local_content_value.data_badge_caption_local_content_base_delete}></span>
                                        </Link>
                                      )}
                                      {local_content_value.new_or_old_record === 'new' && local_content_value.local_content_base_is_approved === false && this.state.is_submitted === false && (
                                        <Link
                                          to={`#!`}
                                          onClick={() => {
                                            this.removeLocalContentItem(i, local_content_value.export_value, local_content_value.local_content, local_content_value.foreign_content);
                                          }}>
                                          <span className="new badge grey gradient-shadow" data-badge-caption={local_content_value.data_badge_caption_local_content_base_delete}></span>
                                        </Link>
                                      )}
                                      {local_content_value.local_content_base_is_approved === false && this.state.is_submitted === false && (
                                        <Link
                                          to={`#!`}
                                          onClick={() => {
                                            this.submitBaselineData('local_content_base', i);
                                          }}>
                                          <span
                                            className="new badge gradient-45deg-purple-deep-orange gradient-shadow"
                                            data-badge-caption={local_content_value.data_badge_caption_local_content_base}></span>
                                        </Link>
                                      )}
                                    </div>
                                  </div>,
                                  <div className="divider" key={i + 5}></div>,
                                  <div className="divider" key={i + 6}></div>,
                                  <br key={i + 7} />
                                ];
                              } else {
                                return null;
                              }
                            })}
                            <div className={this.state.local_content_validation_class_value}>
                              <div className="col s12">{this.state.local_content_validation_message}</div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.LocalContentBase}>
                              help_outline
                            </i>
                          </div>
                        </li>

                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">timeline</i>
                            {this.Language.DataField.LocalValueAdded}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s3">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select
                                    data-select-id="select-options-local_value_added_base_currency"
                                    className="initialized"
                                    name="local_value_added_base_currency"
                                    id="local_value_added_base_currency"
                                    ref="local_value_added_base_currency">
                                    {this.state.local_value_added_base_currency && (
                                      <option value={this.state.local_value_added_base_currency} selected>
                                        {this.state.local_value_added_base_currency}
                                      </option>
                                    )}
                                    <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                      return currency_value;
                                    })}
                                  </select>
                                </div>
                                <label>{this.Language.DataField.Currency} *</label>
                              </div>
                              <div className="input-field col s6">
                                <input id="turnover" name="turnover" type="number" step="any" className="validate" ref="turnover" value={this.state.turnover} onChange={this.handleInputChange} />
                                <label
                                  htmlFor="turnover"
                                  data-error={this.Language.DataFieldError.DecimalNumber}
                                  className={this.state.turnover && this.state.turnover !== null && typeof this.state.turnover !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.Turnover} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s6">
                                <input
                                  id="other_incomes"
                                  name="other_incomes"
                                  type="number"
                                  step="any"
                                  className="validate"
                                  ref="other_incomes"
                                  value={this.state.other_incomes}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="other_incomes"
                                  data-error={this.Language.DataFieldError.DecimalNumber}
                                  className={this.state.other_incomes && this.state.other_incomes !== null && typeof this.state.other_incomes !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.OtherIncomes} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <input
                                  id="bought_in_goods_and_materials"
                                  name="bought_in_goods_and_materials"
                                  type="number"
                                  step="any"
                                  className="validate"
                                  ref="bought_in_goods_and_materials"
                                  value={this.state.bought_in_goods_and_materials}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  htmlFor="bought_in_goods_and_materials"
                                  data-error={this.Language.DataFieldError.DecimalNumber}
                                  className={
                                    this.state.bought_in_goods_and_materials && this.state.bought_in_goods_and_materials !== null && typeof this.state.bought_in_goods_and_materials !== 'undefined'
                                      ? 'active'
                                      : ''
                                  }>
                                  {this.Language.DataField.BoughtInGoodsAndMaterials} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="local_value_added_base_is_approved col s4">
                                <p>
                                  <input
                                    type="checkbox"
                                    id="local_value_added_base_is_approved"
                                    ref="local_value_added_base_is_approved"
                                    checked={this.state.local_value_added_base_is_approved}
                                    readOnly={true}
                                  />
                                  <label htmlFor="local_value_added_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input
                                      type="checkbox"
                                      name="activate_switch_local_value_added_base"
                                      ref="activate_switch_local_value_added_base"
                                      id="activate_switch_local_value_added_base"
                                      checked={this.state.local_value_added_base_status_active}
                                      onChange={this.handleCheckBoxChange}
                                    />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                {this.state.local_value_added_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.onDeleteBaselineData('local_value_added_base', this.state.local_value_added_base_hash_id, 0);
                                    }}>
                                    <span className="new badge grey gradient-shadow" data-badge-caption={this.state.data_badge_caption_local_value_added_base_delete}></span>
                                  </Link>
                                )}
                                {this.state.local_value_added_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.submitBaselineData('local_value_added_base', 0);
                                    }}>
                                    <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_local_value_added_base}></span>
                                  </Link>
                                )}
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.LocalValueAddedBase}>
                              help_outline
                            </i>
                          </div>
                        </li>
                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">check_circle</i>
                            {this.Language.DataField.PrioritySector}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="priority_sector col s4">
                                <p>
                                  <input type="checkbox" id="priority_sector" ref="priority_sector" checked={this.state.priority_sector} onChange={this.handleCheckBoxChange} />
                                  <label htmlFor="priority_sector">{this.Language.DataField.PrioritySector}</label>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="priority_sector_base_is_approved col s4">
                                <p>
                                  <input
                                    type="checkbox"
                                    id="priority_sector_base_is_approved"
                                    ref="priority_sector_base_is_approved"
                                    checked={this.state.priority_sector_base_is_approved}
                                    readOnly={true}
                                  />
                                  <label htmlFor="priority_sector_base_is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input
                                      type="checkbox"
                                      name="activate_switch_priority_sector_base"
                                      ref="activate_switch_priority_sector_base"
                                      id="activate_switch_priority_sector_base"
                                      checked={this.state.priority_sector_base_status_active}
                                      onChange={this.handleCheckBoxChange}
                                    />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                {this.state.priority_sector_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.onDeleteBaselineData('priority_sector_base', this.state.priority_sector_base_hash_id, 0);
                                    }}>
                                    <span className="new badge grey gradient-shadow" data-badge-caption={this.state.data_badge_caption_priority_sector_base_delete}></span>
                                  </Link>
                                )}
                                {this.state.priority_sector_base_is_approved === false && this.state.is_submitted === false && (
                                  <Link
                                    to={`#!`}
                                    onClick={() => {
                                      this.submitBaselineData('priority_sector_base', 0);
                                    }}>
                                    <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_priority_sector_base}></span>
                                  </Link>
                                )}
                              </div>
                            </div>
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.PrioritySector}>
                              help_outline
                            </i>
                          </div>
                        </li>

                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">import_contacts</i>
                            {this.Language.DataField.Notes}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            <div className="row">
                              <div className="input-field col s12">
                                <textarea
                                  id="notes"
                                  className="materialize-textarea"
                                  data-length={this.state.text_limit}
                                  ref="notes"
                                  value={this.state.notes}
                                  onChange={this.handleInputChange}></textarea>
                                <label
                                  htmlFor="notes"
                                  data-error={this.Language.DataFieldError.MaxLength}
                                  className={this.state.notes && this.state.notes !== null && typeof this.state.notes !== 'undefined' ? 'active' : ''}>
                                  {this.Language.DataField.Notes}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.Inactive}
                                    <input
                                      type="checkbox"
                                      name="activate_switch_baseline_notes"
                                      ref="activate_switch_baseline_notes"
                                      id="activate_switch_baseline_notes"
                                      checked={this.state.baseline_note_status_active}
                                      onChange={this.handleCheckBoxChange}
                                    />
                                    <span className="lever"></span>
                                    {this.Language.DataField.Active}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12">
                                {/* <Link to={`#!`} onClick={() => {this.onDeleteBaselineData('baseline_notes', this.state.baseline_note_hash_id, 0)} }><span className="new badge grey gradient-shadow" data-badge-caption={this.state.data_badge_caption_baseline_notes_delete}></span></Link> */}
                                <Link
                                  to={`#!`}
                                  onClick={() => {
                                    this.submitBaselineData('baseline_notes', 0);
                                  }}>
                                  <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.state.data_badge_caption_baseline_notes}></span>
                                </Link>
                              </div>
                            </div>
                            <br />
                            <br />
                            <i className="material-icons tooltipped" data-position="right" data-tooltip={this.Language.ToolTip.BaselineData.Notes}>
                              help_outline
                            </i>
                            <br />
                            <ul className="collapsible" data-collapsible="expandable">
                              <li className="">
                                <div className="collapsible-header cyan lighten-1 white-text">
                                  <i className="material-icons">import_contacts</i>
                                  {this.Language.DataField.NotesHistory}
                                </div>
                                <div className="collapsible-body white" style={{display: 'none'}}>
                                  <b>{this.Language.DataField.Notes}:</b>
                                  {this.state.baseline_notes_history.map((baseline_note_value, i) => {
                                    if (
                                      (baseline_note_value.note_type !== 'notes' || baseline_note_value.note_type !== 'notes_&_reviewer_notes') &&
                                      baseline_note_value.notes !== '' &&
                                      baseline_note_value.notes !== null &&
                                      typeof baseline_note_value.notes !== 'undefined'
                                    ) {
                                      let new_last_updated_on = null;
                                      if (
                                        baseline_note_value.new_last_updated_on !== '' &&
                                        baseline_note_value.new_last_updated_on !== null &&
                                        typeof baseline_note_value.new_last_updated_on !== 'undefined'
                                      )
                                        new_last_updated_on = this.DateService.convertToCalendarFullDate(baseline_note_value.new_last_updated_on);

                                      return [
                                        <p key={i}></p>,
                                        <span key={i + 1}>
                                          <b>{i + 1})</b> [{baseline_note_value.last_updated_by + ' - ' + new_last_updated_on}
                                          ]: {baseline_note_value.notes}
                                        </span>,
                                        <p key={i + 2}></p>
                                      ];
                                    } else {
                                      return null;
                                    }
                                  })}
                                  <br />
                                  <div className="divider"></div>
                                  <br />
                                  <b>{this.Language.DataField.ReviewerNotes}:</b>
                                  {this.state.baseline_notes_history.map((baseline_note_value, i) => {
                                    if (
                                      (baseline_note_value.note_type !== 'reviewer_notes' || baseline_note_value.note_type !== 'notes_&_reviewer_notes') &&
                                      baseline_note_value.reviewer_notes !== '' &&
                                      baseline_note_value.reviewer_notes !== null &&
                                      typeof baseline_note_value.reviewer_notes !== 'undefined'
                                    ) {
                                      let new_last_updated_on = null;
                                      if (
                                        baseline_note_value.new_last_updated_on !== '' &&
                                        baseline_note_value.new_last_updated_on !== null &&
                                        typeof baseline_note_value.new_last_updated_on !== 'undefined'
                                      )
                                        new_last_updated_on = this.DateService.convertToCalendarFullDate(baseline_note_value.new_last_updated_on);

                                      return [
                                        <p key={i}></p>,
                                        <span key={i + 1}>
                                          <b>{i + 1})</b> [{baseline_note_value.last_updated_by + ' - ' + new_last_updated_on}
                                          ]: {baseline_note_value.reviewer_notes}
                                        </span>,
                                        <p key={i + 2}></p>
                                      ];
                                    } else {
                                      return null;
                                    }
                                  })}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="">
                          <div className="collapsible-header cyan lighten-1 white-text">
                            <i className="material-icons">chrome_reader_mode</i>
                            {this.Language.DataField.Documents}
                          </div>
                          <div className="collapsible-body white" style={{display: 'none'}}>
                            {this.state.baseline_documents.map((baseline_document_value, i) => {
                              if (baseline_document_value.document_type !== '' && baseline_document_value.document_type !== null && typeof baseline_document_value.document_type !== 'undefined') {
                                let new_last_updated_on = null;
                                if (
                                  baseline_document_value.new_last_updated_on !== '' &&
                                  baseline_document_value.new_last_updated_on !== null &&
                                  typeof baseline_document_value.new_last_updated_on !== 'undefined'
                                )
                                  new_last_updated_on = this.DateService.convertToCalendarFullDate(baseline_document_value.new_last_updated_on);

                                return (
                                  <div key={i}>
                                    <div className="row">
                                      <div className="col s5">
                                        <p></p>
                                        <Link to={`/exporter/${exporterId}/document/${baseline_document_value.hash_id}?eut=${this.Auth.getToken()}`} target="_blank">
                                          <span className="teal-text">
                                            <b>
                                              {i + 1}) {baseline_document_value.document_category}{' '}
                                            </b>{' '}
                                            - {baseline_document_value.document_name}
                                          </span>
                                        </Link>
                                        <p>
                                          <b> {this.Language.DataField.Size}:</b> {this.FormatService.FormatLocaleNumber(baseline_document_value.file_size, this.Language.Lang, false, 0)} KB
                                        </p>
                                        <p></p>
                                      </div>
                                      <div className="col s3">
                                        <p>
                                          <b> {this.Language.DataField.Approved}:</b>{' '}
                                          {baseline_document_value.is_approved
                                            ? baseline_document_value.is_approved === 'true' || baseline_document_value.is_approved === true
                                              ? this.Language.DataValue.Yes
                                              : this.Language.DataValue.No
                                            : null}
                                        </p>
                                        <p>
                                          <b>{this.Language.DataField.UploadedOn}:</b> {new_last_updated_on}
                                        </p>
                                      </div>
                                      <div className="col s3">
                                        <p>
                                          <b> {this.Language.DataField.UploadedBy}:</b> {baseline_document_value.uploaded_by}
                                        </p>
                                        <p>
                                          <b> {this.Language.DataField.Status}:</b> {baseline_document_value.status}
                                        </p>
                                      </div>
                                      <div className="col s1">
                                        {baseline_document_value.is_approved === false && this.state.is_submitted === false && (
                                          <Link
                                            to={`#!`}
                                            onClick={() => {
                                              this.onDeleteBaselineDocument(
                                                'baseline_document',
                                                baseline_document_value.document_category + ' - ' + baseline_document_value.document_name,
                                                baseline_document_value.hash_id,
                                                i
                                              );
                                            }}>
                                            <span className="new badge grey gradient-shadow" data-badge-caption={this.state.data_badge_caption_document_delete}></span>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                    <br />
                                    <div className="divider"></div>
                                    <br />
                                    <br />
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}

                            <ul className="collapsible" data-collapsible="expandable">
                              <li className="">
                                <div className="collapsible-header cyan lighten-1 white-text">
                                  <i className="material-icons">chrome_reader_mode</i>
                                  {this.Language.DataField.DocumentUpload}
                                </div>
                                <div className="collapsible-body white" style={{display: 'none'}}>
                                  {this.state.is_submitted === false && this.state.fileUploadItem_1}
                                  {/* <FileUpload fieldName={this.Language.PageLabel.BaselineForms} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-001'} maxFileSize={'10M'} defaultFile={''} fileName={'Baseline_Forms'} pageDataCategory={"Baseline_Forms"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && this.state.fileUploadItem_2}
                                  {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementCurrent} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-002'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_current'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && this.state.fileUploadItem_3}
                                  {/* <FileUpload fieldName={this.Language.PageLabel.ManagementAccountCurrent} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-003'} maxFileSize={'10M'} defaultFile={''} fileName={'Management_Account_Current'} pageDataCategory={"Management_Account"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && this.state.fileUploadItem_4}
                                  {/* <FileUpload fieldName={this.Language.PageLabel.ProjectedFinancialStatement} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-004'} maxFileSize={'10M'} defaultFile={''} fileName={'Projected_Statement'} pageDataCategory={"Projected_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && this.state.fileUploadItem_5}
                                  {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear1} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-005'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_Pre_Year_1'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && this.state.fileUploadItem_6}
                                  {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear2} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-006'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_Pre_Year_2'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && <br />}
                                  {this.state.is_submitted === false && this.state.fileUploadItem_7}
                                  {/* <FileUpload fieldName={this.Language.PageLabel.AuditedStatementPreceedingYear3} fileExtensions={'pdf png jpeg jpg doc docx txt'} fieldID={'baseline-file-007'} maxFileSize={'10M'} defaultFile={''} fileName={'Audited_Statement_Pre_Year_3'} pageDataCategory={"Audited_Statement"} pageName={"AddBaselineData"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.BusinessYear}/> */}
                                  {this.state.is_submitted === false && <br />}
                                  <br />
                                  <br />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>

                      {/* ::after */}
                    </div>

                    {this.state.is_submitted === false && this.state.is_submitable_business_year === true && (
                      <Link
                        className="waves-effect waves-light btn grey lighten-3 cyan-text left"
                        to={`#!`}
                        onClick={() => {
                          if (window.confirm(`${this.Language.ConfirmMessage.SubmitBaseline} ${this.refs.business_year.value === 'Select Business Year' ? '' : this.refs.business_year.value}`)) {
                            this.submitBaselineData('submit_baseline_data_final');
                          }
                        }}>
                        {this.state.submit_baseline_data_button_caption || this.Language.ButtonLabel.SubmitBaseline}
                      </Link>
                    )}
                    {this.state.is_submitted === false && this.state.is_submitable_business_year === true && <br />}
                    {this.state.is_submitted === false && this.state.is_submitable_business_year === true && <br />}
                  </div>
                </div>
                {/* ::after */}
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default EditBaselineDataProfile;
