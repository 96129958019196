import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
import BrowserService from '../modules/BrowserService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class PartnerDetails extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getPartner = this.getPartner.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.activateDeactivatePartner = this.activateDeactivatePartner.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
          partnerDetails: '',
          result: '',
          status: '',
          message: '',
          logged_in_username: '',
          account_status: 'Inactive',
          temp_account_status: 'Inactive',
          partners_update: null,
          partners_delete: null,
          scopes: {},
          return_back_to_url: '/partners'
        };
        this.Auth = new AuthService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.PartnerAgencyProfile + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();
        this.getPartner();


    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (this.props.history.location.search && this.props.history.location.search !== '' && this.props.history.location.search !== null && typeof this.props.history.location.search !== 'undefined') {
            let search = this.props.history.location.search || '';
            if (search.substring(0, 4) === this.returnURLQuery) {
                let returnURLPlaceHolder = search.substring(4);
                this.setState({
                  return_back_to_url: returnURLPlaceHolder || this.state.return_back_to_url
                });
            }
        }

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['read:partners', 'action:partners'], 'view') === false)) {
            this.props.history.replace(this.state.return_back_to_url);
        };

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'update:partners' || this.state.scopes['views'][i] === 'action:partners') this.setState({ partners_update: true });
                if (this.state.scopes['views'][i] === 'delete:partners' || this.state.scopes['views'][i] === 'action:partners') this.setState({ partners_delete: true });
            }
        };

    }

    checkBoxChange() {

        let checkBoxState = this.refs.activate_switch.checked;

        if (checkBoxState) { // Activating
            if (window.confirm(`${this.Language.ConfirmMessage.ActivatePartner}  ${this.state.partnerDetails.organisation_name}`)) {
                // make call to the database here
                // window.Materialize.toast(`${this.state.partnerDetails.organisation_name} Partner Agency activated`, 6000, 'rounded green accent-3');

                this.activateDeactivatePartner('Activate');

                this.setState({ temp_account_status: 'Active' });

            } else {

                this.refs.activate_switch.checked = false;

            };

        }
        else { //Deactivating
            if (window.confirm(`${this.Language.ConfirmMessage.DeactivatePartner}  ${this.state.partnerDetails.organisation_name}`)) {
                // make call to the database here
                this.activateDeactivatePartner('Deactivate');

                this.setState({ temp_account_status: 'Inactive' });

                // window.Materialize.toast(`${this.state.partnerDetails.organisation_name} Partner Agency deactivated`, 6000, 'rounded deep-orange accent-3');

            } else {

                this.refs.activate_switch.checked = "checked";

            };

        };

    }



    getPartner() {
        let partnerId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/partners/${partnerId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {
                this.setState({ partnerDetails: response.data.data[0] }, () => {
                    
                })

                if (response.data.data[0].status === 'Active') {
                    this.setState({ account_status: 'Active' });
                } else {
                    this.setState({ account_status: 'Inactive' });
                }

                if (response.data.data[0].status === 'Active') {
                    this.setState({ temp_account_status: 'Active' });
                } else {
                    this.setState({ temp_account_status: 'Inactive' });
                }


                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }


    activateDeactivatePartner(activate_deactivate_action) {

        let organisation_name = this.state.partnerDetails.organisation_name;
        let logged_in_username = this.state.logged_in_username;
        let partnerIdTemp = this.state.partnerDetails.hash_id;

        const userOS = this.Browser.getOS();

        const newPartnerAction = {
            organisation_name: organisation_name,
            activate_deactivate_action: activate_deactivate_action,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken()
        }


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/partners/activate/${partnerIdTemp}`,
            data: newPartnerAction
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200 && newPartnerAction.activate_deactivate_action === 'Activate') window.Materialize.toast(this.Language.NodeMessage.Partner.ActivateSuccess + newPartnerAction.organisation_name, 8000, 'rounded green');
                if (response.status === 200 && newPartnerAction.activate_deactivate_action === 'Deactivate') window.Materialize.toast(this.Language.NodeMessage.Partner.DeactivateSuccess + newPartnerAction.organisation_name, 8000, 'rounded green');

            })


        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.Partner.UpdateError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.Partner.DoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                


            });
    }




    onDelete() {

        document.getElementById("delete_partner_button").setAttribute("disabled", true);


        let organisation_name = this.state.partnerDetails.organisation_name;
        let logged_in_username = this.state.logged_in_username;
        let partnerIdTemp = this.state.partnerDetails.hash_id;

        const userOS = this.Browser.getOS();

        const deletePartner = {
            organisation_name: organisation_name,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/partners/${partnerIdTemp}`,
            data: deletePartner
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                window.Materialize.toast(this.Language.NodeMessage.Partner.DeleteSuccess + deletePartner.organisation_name, 8000, 'rounded green');
            })


            document.getElementById("delete_partner_button").removeAttribute("disabled");
            this.props.history.replace(this.state.return_back_to_url);

        })
            .catch(error => {
                if (error.response) {

                   

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.Partner.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_partner_button").removeAttribute("disabled");

            });

    }







    render() {

        let date_of_creation = this.state.partnerDetails.date_of_creation + '';
        date_of_creation = this.DateService.convertToCalendarFullDate(date_of_creation);

        let approved_date = this.state.partnerDetails.approved_at + '';
        approved_date = this.DateService.convertToCalendarFullDate(approved_date);


        let partnerId = this.props.match.params.id;

        if (this.state.temp_account_status === 'Active') {
            $(".switch").find("input[type=checkbox]").prop('checked', true);
        } else {
            $(".switch").find("input[type=checkbox]").prop('checked', false);
        }




        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Partner Management"} active_menu_level_2={"Partner Agencies"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.PartnerAgencyProfile}`} breadcrumbs={[this.Language.BreadCrumbLabel.PartnerManagement, this.Language.BreadCrumbLabel.PartnerAgencies, this.Language.BreadCrumbLabel.PartnerProfile]} links={[`/partners`, this.state.return_back_to_url, `/partners/${partnerId}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div id="profile-page" className="section">
                                    <div id="profile-page-header" className="card" style={{ overflow: "hidden" }}>
                                        <div className="card-image waves-effect waves-block waves-light">
                                            <img className="activator" src="/assets/images/gallary/30.png" alt="" />
                                        </div>
                                        <figure className="card-profile-image">
                                            <img src="/images/bank.png" alt="" className="circle white z-depth-2 responsive-img activator gradient-shadow" />
                                            {/* <img src="/assets/images/avatar/avatar-7.png" alt="" className="circle z-depth-2 responsive-img activator gradient-45deg-light-blue-cyan gradient-shadow"/> */}
                                        </figure>
                                        <div className="card-content">
                                            <div className="row pt-2">
                                                <div className="col s12 m4 offset-m2">
                                                    <h4 className="card-title grey-text text-darken-4">{this.state.partnerDetails.organisation_name} </h4>
                                                    <p className="medium-small grey-text">{this.state.partnerDetails.registration_number}</p>
                                                </div>
                                                <div className="col s12 m2 center-align">
                                                    <h4 className="card-title grey-text text-darken-4">{this.state.partnerDetails.organisation_type}</h4>
                                                    <p className="medium-small grey-text">{this.Language.DataField.PartnerCategory}</p>
                                                </div>
                                                <div className="col s12 m2 center-align">
                                                    <h4 className="card-title grey-text text-darken-4">{date_of_creation}</h4>
                                                    <p className="medium-small grey-text">{this.Language.DataField.RegistrationDate}</p>
                                                </div>
                                                <div className="col s12 m1 right-align">
                                                    <a href={"#!"} className="btn-floating activator waves-effect waves-light teal accent-2 right">
                                                        <i className="material-icons">account_balance</i>
                                                    </a>
                                                </div>
                                                {/* :;after */}
                                            </div>
                                        </div>
                                        <div className="card-reveal" style={{ display: "none", transform: "translateY(0px)" }}>
                                            <p>
                                                <span className="card-title grey-text text-darken-4">
                                                    {this.state.partnerDetails.organisation_name}
                                                    <i className="material-icons right">close</i>
                                                </span>
                                                <span>
                                                    <i className="material-icons cyan-text text-darken-2">account_balance</i>
                                                    {this.state.partnerDetails.registration_number}
                                                </span>
                                            </p>
                                            <p>
                                                {this.state.partnerDetails.website}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">perm_phone_msg</i>
                                                {this.state.partnerDetails.phone}, {this.state.partnerDetails.mobile}, {this.state.partnerDetails.alternate_phone}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">email</i>
                                                {this.state.partnerDetails.email}, {this.state.partnerDetails.alternate_email}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">card_membership</i>
                                                {date_of_creation}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">assignment_turned_in</i>
                                                {this.Language.DataField.PartnerCategory}: {this.state.partnerDetails.organisation_type}
                                            </p>
                                        </div>
                                    </div>
                                    <div id="profile-page-content" className="row">
                                        <div id="profile-page-sidebar" className="col s12 m5">
                                            <div className="card cyan">
                                                <div className="card-content white-text">
                                                    <span className="card-title">{this.Language.DataField.Description}: <b>{this.state.partnerDetails.organisation_description}</b> </span>
                                                    <p>
                                                        {this.Language.DataField.PartnerCategory}: <b>{this.state.partnerDetails.organisation_type}</b>
                                                    </p>
                                                    <p> {this.Language.DataField.Status}: <b>{this.state.partnerDetails.status ? (this.state.partnerDetails.status === 'Active' ? this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null}</b> </p>
                                                    <p> {this.Language.DataField.CreatedBy}: <b>{this.state.partnerDetails.created_by}</b> </p>
                                                    <p> {this.Language.DataField.Approved}: <b>{(this.state.partnerDetails.is_approved !== null && typeof this.state.partnerDetails.is_approved !== 'undefined') ? ((this.state.partnerDetails.is_approved === 'true') || (this.state.partnerDetails.is_approved === true) ? this.Language.DataValue.Yes : this.Language.DataValue.No) : null}</b> </p>
                                                    <p> {this.Language.DataField.ApprovedBy}: <b>{this.state.partnerDetails.approved_by}</b> </p>
                                                    <p> {this.Language.DataField.ApprovedOn}: <b>{approved_date}</b> </p>
                                                </div>
                                            </div>
                                            <ul id="profile-page-about-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <b className="teal-text"> {this.Language.PageLabel.ContactDetails} </b>
                                                        </div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">perm_phone_msg</i>
                                                            {this.Language.DataField.Phone}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.phone}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">phone</i>
                                                            {this.Language.DataField.AlternatePhone}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.alternate_phone}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">smartphone</i>
                                                            {this.Language.DataField.Mobile}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.mobile}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">email</i>
                                                            {this.Language.DataField.Email}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.email}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">email</i>
                                                            {this.Language.DataField.AlternateEmail}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.alternate_email}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">language</i>
                                                            {this.Language.DataField.Website}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.website}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">card_membership</i>
                                                            {this.Language.DataField.RegistrationDate}
                                                        </div>
                                                        <div className="col s7 right-align">{date_of_creation}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <br />
                                            <br />
                                        </div>
                                        <div id="profile-page-wall" className="col s12 m7">

                                            <ul id="profile-page-about-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">location_on</i>
                                                            <b className="teal-text"> {this.Language.PageLabel.Address}</b>
                                                        </div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine1}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_line1}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine2}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_line2}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine3}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_line3}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.City}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_city}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.State}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_state}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.Country}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.address_country}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AlternateAddress}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.alternate_address}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.MailingAddress}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.mailing_address}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.ZipCode}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.post_zip_code}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.PostalArea}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.postal_area}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.ParentOrganisationName}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.partnerDetails.parent_organisation_name}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            {/* ::after */}
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <Link className="waves-effect waves-light btn grey" to={this.state.return_back_to_url}><i className="material-icons left">arrow_back</i>{this.Language.ButtonLabel.Back}</Link>

                                    {(this.state.partners_update) && <Link className="waves-effect waves-light btn" to={`/partners/edit/${this.state.partnerDetails.hash_id}`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>
                                    }

                                    {(this.state.partners_delete) && <button className="waves-effect waves-light btn red right" id="delete_partner_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeletePartner} ${this.state.partnerDetails.organisation_name}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</button>
                                    }

                                    <br />
                                    <br />
                                    {(this.state.partners_update) && <div className="collection-item border-none">
                                        <div className="switch">
                                            <label className="font-weight-600 black-text">
                                                {this.Language.DataField.DeactivatePartner}
                                                <input type="checkbox" name="activate_switch" ref="activate_switch" onClick={this.checkBoxChange} />
                                                <span className="lever"></span>
                                                {this.Language.DataField.ActivatePartner}
                                            </label>
                                        </div>
                                    </div>
                                    }
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default PartnerDetails;