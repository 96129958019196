import React, { Component } from 'react';



class Pagination extends Component{
    constructor(props){
        super(props);
        this.state = {
            numberOfPages: props.numberOfPages,
            activePage: props.activePage,
            leftChevronEnable: props.leftChevronEnable,
            rightChevronEnable: props.rightChevronEnable,
            handlePaginationChange: props.handlePaginationChange
        }
        this.changePage = this.changePage.bind(this);
    }



    changePage(newActivePage){

        if (newActivePage >= 1 && newActivePage <= this.state.numberOfPages) {
            this.setState({activePage: newActivePage}, () => {
                this.state.handlePaginationChange(newActivePage);

                if (newActivePage <= 1) {
                    this.setState({leftChevronEnable: false});
                } else {
                    this.setState({leftChevronEnable: true});
                }

                if (newActivePage >= this.state.numberOfPages) {
                    this.setState({rightChevronEnable: false});
                } else {
                    this.setState({rightChevronEnable: true});
                }


            });
        }

    }


    render(){

        let leftClassName = 'waves-effect';
        let rightClassName = 'waves-effect'

        if(this.state.leftChevronEnable === false ) leftClassName = 'disabled';
        if(this.state.rightChevronEnable === false ) rightClassName = 'disabled';


        let len = this.state.numberOfPages;
        let active = this.state.activePage;
        let paginationItems = [];


        if (len === 0 ) len = 1; // force the number of pages to be equal to 1 at least when rendering for the first time if 0 passed in as the number of pages


            for (let i = 0; i < len; i++) {

                if (i === (active - 1) ){
                    paginationItems.push(<li className="active teal" key={i} onClick={() => {this.changePage(i + 1)}}><a href="#!">{i + 1}</a></li>);
                } else {
                    paginationItems.push(<li className="waves-effect"key={i} onClick={() => {this.changePage(i + 1)}}><a href="#!">{i + 1}</a></li>);
                }
                
            }

            return (
                <ul className="pagination">
                    <li className={leftClassName} onClick={() => {this.changePage(active - 1)}}><a href="#!"><i className="material-icons">chevron_left</i></a></li>
                    {paginationItems}
                    <li className={rightClassName} onClick={() => {this.changePage(active + 1)}}><a href="#!"><i className="material-icons">chevron_right</i></a></li>
                </ul>
            )

    }
}

export default Pagination;