import React, { Component } from 'react';
import DatatableHeaderItem from './DatatableHeaderItem';


class DatatableHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
            headers: props.headers,
            sortPosition: props.sortPosition,
            sortingClassName: "sorting",
            sortType: props.sortType,
            thStyle: props.thStyle
        }
    }


    render(){
        const headerItems = this.state.headers.map((headeritem,  i) => {

            if (this.state.sortPosition === i && this.state.sortType === "ascending" ){
                return(
                        <DatatableHeaderItem key={i} headerItem={headeritem} sortingClassName={"sorting_asc"} thStyle={this.state.thStyle[i]} />
                    )
            }

            if (this.state.sortPosition === i && this.state.sortType === "descending" ){
                return(
                        <DatatableHeaderItem key={i} headerItem={headeritem} sortingClassName={"sorting_des"} thStyle={this.state.thStyle[i]} />
                    )
            }

            return(
                <DatatableHeaderItem key={i} headerItem={headeritem} sortingClassName={this.state.sortingClassName} thStyle={this.state.thStyle[i]}/>
            )
        })

        return (
            <thead>
                <tr role="row">
                    {headerItems}
                </tr>
            </thead>
        )
    }
}

export default DatatableHeader;