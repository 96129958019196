import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import FileUpload from '../components/FileUpload';
import URLService from '../modules/URLService';

import ReactDOM from 'react-dom';
const $ = require('jquery');

class AddEEGClaim extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.addEEGClaim = this.addEEGClaim.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.getDropdownValues = this.getDropdownValues.bind(this);
    this.toggleDeliveryMode = this.toggleDeliveryMode.bind(this);
    this.toggleCCI = this.toggleCCI.bind(this);
    this.getValues = this.getValues.bind(this);
    this.getExchangeRate = this.getExchangeRate.bind(this);
    this.validateNXPNumber = this.validateNXPNumber.bind(this);
    this.validateCCINumber = this.validateCCINumber.bind(this);
    this.getNXPNumberValidation = this.getNXPNumberValidation.bind(this);
    this.getCCINumberValidation = this.getCCINumberValidation.bind(this);
    this.getEEGRateValues = this.getEEGRateValues.bind(this);
    this.getEEGRate = this.getEEGRate.bind(this);
    this.calculateAmount = this.calculateAmount.bind(this);
    this.addExportedItem = this.addExportedItem.bind(this);
    this.removeExportedItem = this.removeExportedItem.bind(this);
    this.state = {
      nxp_number_status: true,
      bill_of_lading_status: true,
      waybill_status: true,
      cci_number_status: true,
      cci_date_status: true,
      default_export_date: '1970-01-01 00:00:00+00',
      default_export_currency: '',
      default_repatriation_date: '1970-01-01 00:00:00+00',
      default_repatriation_currency: '',
      target_currency: 'NGN',
      exchange_rate_values: [],
      export_exchange_rate: 0,
      naira_export_value: 0,
      repatriation_exchange_rate: 0,
      naira_amount_repatriated: 0,
      cross_rate: 0,
      nxp_number_values: [],
      numberOfNXPRecords: 0,
      cci_number_values: [],
      numberOfCCIRecords: 0,
      exporter_id: '',
      product_category: '',
      eeg_rate: 0,
      eeg_payable: 0,
      eeg_rate_values: [],
      exported_item_amount: 0,
      exported_items_array: [],
      total_exported_items_amount: 0,
      total_exported_items_quantity: 0,
      exported_item_validation_message: '',
      message: '',
      status: '',
      pick_list_values: [],
      select_field_values_exporter_id: [],
      select_field_values_originating_nxp_bank: [],
      select_field_values_industry: [],
      select_field_values_destination_country: [],
      select_field_values_product_category: [],
      select_field_values_measurement_unit: [],
      select_field_values_currency: [],
      select_field_values_delivery_mode: [],
      preloader: true,
      hash_user: '',
      logged_in_username: '',
      text_limit: 2000,
      error_messages: null,
      fileUploadItem_combined: null,
      fileUploadItem_1: null,
      fileUploadItem_2: null,
      fileUploadItem_3: null,
      fileUploadItem_4: null,
      fileUploadItem_5: null,
      fileUploadItem_6: null,
      fileUploadItem_7: null,
      fileUploadItem_8: null,
      fileUploadItem_9: null,
      fileUploadItem_10: null,
      close_error_messages: (
        <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
          <span aria-hidden="true">×</span>
        </button>
      )
    };
    this.Auth = new AuthService();
    this.Validate = new ValidationService();
    this.DateService = new DateService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.Format = new FormatService();
    this.apiURL = URLService.prototype.getApiURL();
  }

  componentWillMount() {}

  componentDidMount() {
    document.title = this.Language.PageTitle.AddEEGClaim + ' - ' + this.Language.EEGWebPortal;
    this.getProfile();

    const monthsFull = this.Language.DatePicker.monthsFull.split(',');
    const monthsShort = this.Language.DatePicker.monthsShort.split(',');
    const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(',');
    const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(',');
    const today = this.Language.DatePicker.today;
    const clear = this.Language.DatePicker.clear;
    const close = this.Language.DatePicker.close;
    const labelMonthNext = this.Language.DatePicker.labelMonthNext;
    const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
    const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
    const labelYearSelect = this.Language.DatePicker.labelYearSelect;

    window.$('.datepicker').pickadate({
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      today: today,
      clear: clear,
      close: close,
      labelMonthNext: labelMonthNext,
      labelMonthPrev: labelMonthPrev,
      labelMonthSelect: labelMonthSelect,
      labelYearSelect: labelYearSelect,
      onSet: () => {
        this.getValues();
      }
      // firstDay: 1,
      // format: 'dd mmmm yyyy',
      // formatSubmit: 'yyyy/mm/dd',
      // selectMonths: true,
      // selectYears: true,
      // selectMonths: true, // Creates a dropdown to control month
      // selectYears: 15, // Creates a dropdown of 15 years to control year,
      // closeOnSelect: false, // Close upon selecting a date,
      // container: undefined, // ex. 'body' will append picker to body
    });

    window.$('select').material_select();
    window.$(ReactDOM.findDOMNode(this.refs.delivery_mode)).on('change', this.toggleDeliveryMode.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.has_clean_certificate_of_inspection)).on('change', this.toggleCCI.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.export_currency)).on('change', this.getValues.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.repatriation_currency)).on('change', this.getValues.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.nxp_number)).on('focusout', this.validateNXPNumber.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.cci_number)).on('focusout', this.validateCCINumber.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.exporter_id)).on('change', this.getEEGRateValues.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.product_category)).on('change', this.getEEGRateValues.bind(this));

    window.$('.dropify').dropify({
      messages: {
        default: this.Language.FileUpload.Default,
        replace: this.Language.FileUpload.Replace,
        remove: this.Language.FileUpload.Remove,
        error: this.Language.FileUpload.Error
      }
    });
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    $('select').material_select('destroy');
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState(
        {
          logged_in_username: user_profile.username,
          hash_user: user_profile.user
        },
        () => {
          this.setState({
            fileUploadItem_combined: (
              <FileUpload
                fieldName={this.Language.PageLabel.CombinedNXPFiles}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'combined-nxp-file-000'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Combined_NXP_File'}
                pageDataCategory={'Combined_NXP_File'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_1: (
              <FileUpload
                fieldName={this.Language.PageLabel.NXPForm}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'nxp-form-file-001'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'NXP_Form'}
                pageDataCategory={'NXP_Form'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_2: (
              <FileUpload
                fieldName={this.Language.PageLabel.BillOfLadingOrWaybill}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'bill_of_lading_waybill-file-002'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Bill_of_Lading_Waybill'}
                pageDataCategory={'Bill_of_Lading_Waybill'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_3: (
              <FileUpload
                fieldName={this.Language.PageLabel.SGDForm}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'sgd_form-file-003'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Single_Goods_Declaration'}
                pageDataCategory={'Single_Goods_Declaration'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_4: (
              <FileUpload
                fieldName={this.Language.PageLabel.BankLetter}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'bank_letter-file-004'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Bank_Letter_To_CBN'}
                pageDataCategory={'Bank_Letter_To_CBN'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_5: (
              <FileUpload
                fieldName={this.Language.PageLabel.CommercialInvoice}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'commercial_invoice-file-005'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Commercial_Invoice'}
                pageDataCategory={'Commercial_Invoice'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_6: (
              <FileUpload
                fieldName={this.Language.PageLabel.CleanCertificateOfInspection}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'cci-file-006'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Clean_Certificate_of_Inspection'}
                pageDataCategory={'Clean_Certificate_of_Inspection'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_7: (
              <FileUpload
                fieldName={this.Language.PageLabel.NEPCNonOilDataForm}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'non_oil_data-file-007'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'NEPC_Non_Oil_Data_Form'}
                pageDataCategory={'NEPC_Non_Oil_Data_Form'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_8: (
              <FileUpload
                fieldName={this.Language.PageLabel.CertificatesOfManufacture}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'certficate_manufacture-file-008'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Certificates_Of_Manufacture'}
                pageDataCategory={'Certificates_Of_Manufacture'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_9: (
              <FileUpload
                fieldName={this.Language.PageLabel.CBNNEPCSchedule}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'cbn_nepc_schedule-file-009'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'CBN_NEPC_Schedule'}
                pageDataCategory={'CBN_NEPC_Schedule'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            ),
            fileUploadItem_10: (
              <FileUpload
                fieldName={this.Language.PageLabel.ExporterApplicationLetter}
                fileExtensions={'pdf png jpeg jpg'}
                fieldID={'exporter_application_letter-file-010'}
                maxFileSize={'10M'}
                defaultFile={''}
                fileName={'Exporter_Application_Letter'}
                pageDataCategory={'Exporter_Application_Letter'}
                pageName={'AddEEGClaim'}
                loggedInUsername={this.state.logged_in_username}
                RequiredFields={this.Language.DataField.Exporter + ', ' + this.Language.DataField.NXPNumber + ', ' + this.Language.DataField.ExportDate}
              />
            )
          });

          this.checkAccessRight();
        }
      );
    }
  }

  checkAccessRight() {
    if (this.Auth.checkAccessScope(['create:claims', 'action:claims'], 'view') === false) {
      this.props.history.replace('/exporters/eeg/claims');
    } else {
      this.getDropdownValues('exporter,major_banker,primary_industry,address_country,product_categorisation,measurement_unit,currency,delivery_mode');
    }
  }

  handleCloseAlert() {
    this.setState({error_messages: null}); // change the value of error_message to close the alert box
  }

  toggleDeliveryMode(event) {
    event.preventDefault();

    let delivery_mode = this.refs.delivery_mode.value;

    if ((delivery_mode === 'Air' || delivery_mode === 'Land') && this.state.waybill_status === true) {
      this.setState(
        {
          waybill_status: false,
          bill_of_lading_status: true
        },
        () => {
          $('#date_on_bill_of_lading').val('');
        }
      );
    }

    if (delivery_mode === 'Sea' && this.state.bill_of_lading_status === true) {
      this.setState(
        {
          waybill_status: true,
          bill_of_lading_status: false
        },
        () => {
          $('#waybill_date').val('');
        }
      );
    }

    if (delivery_mode === 'Select Delivery Mode' && (this.state.waybill_status === true || this.state.bill_of_lading_status === true)) {
      this.setState(
        {
          waybill_status: true,
          bill_of_lading_status: true
        },
        () => {
          $('#date_on_bill_of_lading').val('');
          $('#waybill_date').val('');
        }
      );
    }
  }

  toggleCCI(event) {
    event.preventDefault();

    let has_clean_certificate_of_inspection = this.refs.has_clean_certificate_of_inspection.checked;

    if (has_clean_certificate_of_inspection === true && this.state.cci_number_status === true && this.state.cci_date_status === true) {
      this.setState({
        cci_number_status: false,
        cci_date_status: false
      });
    }

    if (has_clean_certificate_of_inspection === false && this.state.cci_number_status === false && this.state.cci_date_status === false) {
      this.setState(
        {
          cci_number_status: true,
          cci_date_status: true
        },
        () => {
          $('#cci_number').val('');
          $('#cci_date').val('');
        }
      );
    }
  }

  validateNXPNumber() {
    let nxp_number = this.refs.nxp_number.value.trim();

    if (nxp_number !== '' && nxp_number !== null && typeof nxp_number !== 'undefined') {
      this.setState({numberOfNXPRecords: 0}, () => {
        this.getNXPNumberValidation(nxp_number);
      });
    }
  }

  validateCCINumber() {
    let has_clean_certificate_of_inspection = this.refs.has_clean_certificate_of_inspection.checked;
    let cci_number = this.refs.cci_number.value;

    if (has_clean_certificate_of_inspection === true && cci_number !== '' && cci_number !== null && typeof cci_number !== 'undefined') {
      this.setState({numberOfCCIRecords: 0}, () => {
        this.getCCINumberValidation(cci_number);
      });
    }
  }

  getNXPNumberValidation(nxp_number) {
    axios
      .get(`${this.apiURL}/api/v1/eeg/claims/nxp/validation/${nxp_number}?tk=${this.Auth.getToken()}`)
      .then(response => {
        this.setState({numberOfNXPRecords: response.data.data_count});

        this.setState({nxp_number_values: response.data.data}, () => {

          if (this.state.nxp_number_values && this.state.nxp_number_values.length > 0 && this.state.numberOfNXPRecords > 0) {
            window.Materialize.toast(this.Language.NodeMessage.EEGClaim.NXPNumber.NXPNumberAlreadyInUse + this.state.nxp_number_values[0].exporter_name, 8000, 'rounded deep-orange darken-4');
          }
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.NXPNumber.Error, 8000, 'rounded deep-orange darken-4');
          if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.NXPNumber.NoNXPNumberFound, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  }

  getCCINumberValidation(cci_number) {
    axios
      .get(`${this.apiURL}/api/v1/eeg/claims/cci/validation/${cci_number}?tk=${this.Auth.getToken()}`)
      .then(response => {
        this.setState({numberOfCCIRecords: response.data.data_count});

        this.setState({cci_number_values: response.data.data}, () => {

          if (this.state.cci_number_values && this.state.cci_number_values.length > 0 && this.state.numberOfCCIRecords > 0) {
            window.Materialize.toast(this.Language.NodeMessage.EEGClaim.CCINumber.CCINumberAlreadyInUse + this.state.cci_number_values[0].nxp_number, 8000, 'rounded deep-orange darken-4');
          }
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.CCINumber.Error, 8000, 'rounded deep-orange darken-4');
          if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.CCINumber.NoCCINumberFound, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  }

  calculateAmount() {
    let quantity = this.refs.quantity.value;
    let unit_price = this.refs.unit_price.value;

    if (!isNaN(parseFloat(quantity)) && isFinite(quantity) && !isNaN(parseFloat(unit_price)) && isFinite(unit_price)) {
      this.setState({exported_item_amount: quantity * unit_price});
    } else {
      this.setState({exported_item_amount: 0});
    }
  }

  addExportedItem() {
    let nxp_number = this.refs.nxp_number.value.trim();
    let product_description = this.refs.product_description.value.trim();
    let quantity = this.refs.quantity.value;
    let unit_price = this.refs.unit_price.value;

    if (!isNaN(parseFloat(quantity)) && isFinite(quantity) && !isNaN(parseFloat(unit_price)) && isFinite(unit_price)) {
      this.setState({exported_item_amount: Math.abs(parseFloat(quantity)) * Math.abs(parseFloat(unit_price))}, () => {
        let exported_item_amount = this.refs.exported_item_amount.value;

        if (
          nxp_number !== '' &&
          nxp_number !== null &&
          typeof nxp_number !== 'undefined' &&
          product_description !== '' &&
          product_description !== null &&
          typeof product_description !== 'undefined' &&
          exported_item_amount > 0
        ) {
          if (nxp_number.length === 15) {
            const newExportedItem = {
              nxp_number: nxp_number,
              product_description: product_description,
              quantity: Math.abs(parseFloat(quantity)),
              unit_price: Math.abs(parseFloat(unit_price)),
              exported_item_amount: Math.abs(parseFloat(exported_item_amount))
            };

            let temp_exported_items_array = this.state.exported_items_array;
            temp_exported_items_array.push(newExportedItem);
            this.setState(
              {
                exported_items_array: temp_exported_items_array,
                total_exported_items_amount: parseFloat(this.state.total_exported_items_amount) + parseFloat(newExportedItem.exported_item_amount),
                total_exported_items_quantity: parseFloat(this.state.total_exported_items_quantity) + parseFloat(newExportedItem.quantity),
                exported_item_validation_message: ''
              },
              () => {
                this.getValues();
              }
            );
          } else {
            this.setState({exported_item_validation_message: this.Language.ReactValidationMessage.EEGClaim.ExportedItemValidationNXP});
          }
        } else {
          this.setState({
            exported_item_amount: 0,
            exported_item_validation_message: this.Language.ReactValidationMessage.EEGClaim.ExportedItemValidationNXPAndProduct
          });
        }
      });
    } else {
      this.setState({
        exported_item_amount: 0,
        exported_item_validation_message: this.Language.ReactValidationMessage.EEGClaim.ExportedItemValidationItems
      });
    }
  }

  removeExportedItem(position, amount, quantity) {
    let temp_exported_items_array = this.state.exported_items_array;
    temp_exported_items_array = temp_exported_items_array.filter((value, i) => i !== position);
    this.setState(
      {
        exported_items_array: temp_exported_items_array,
        total_exported_items_amount: parseFloat(this.state.total_exported_items_amount) - parseFloat(amount),
        total_exported_items_quantity: parseFloat(this.state.total_exported_items_quantity) - parseFloat(quantity),
        exported_item_validation_message: ''
      },
      () => {
        this.getValues();
      }
    );
  }

  getValues() {
    //Get the values needed for the Export Exchange Rate
    let temp_export_date = this.refs.export_date.value;
    let export_currency = this.refs.export_currency.value;
    if (export_currency === 'Select Currency') export_currency = '';

    //Get the values needed for the Repatriation Exchange Rate
    let temp_repatriation_date = this.refs.repatriation_date.value;
    let repatriation_currency = this.refs.repatriation_currency.value;
    if (repatriation_currency === 'Select Currency') repatriation_currency = '';

    //Get the values needed for the EEG Rate values
    let exporter_id = this.refs.exporter_id.value;
    if (exporter_id === 'Select Exporter') exporter_id = '';

    let product_category = this.refs.product_category.value;
    let temp_product_category = '';
    if (product_category === 'Select Product Category') {
      product_category = '';
    } else {
      temp_product_category = product_category.split('<:>');
      product_category = temp_product_category[1];
    }

    // Export Exchange Rate logic
    if (
      temp_export_date !== null &&
      temp_export_date !== '' &&
      typeof temp_export_date !== 'undefined' &&
      export_currency !== null &&
      export_currency !== '' &&
      typeof export_currency !== 'undefined'
    ) {
      let export_date = this.DateService.convertToFullDate(temp_export_date);
      if (
        export_date !== null &&
        export_date !== '' &&
        typeof export_date !== 'undefined' &&
        (export_date !== this.state.default_export_date || export_currency !== this.state.default_export_currency)
      ) {
        this.setState(
          {
            default_export_date: export_date,
            default_export_currency: export_currency
          },
          () => {
            this.getExchangeRate('export_exchange_rate', export_date, export_currency, this.state.target_currency);
          }
        );
      }

      let export_value = this.refs.export_value.value.trim();
      export_value = Math.abs(export_value);

      if (!isNaN(parseFloat(this.state.export_exchange_rate)) && isFinite(this.state.export_exchange_rate) && !isNaN(parseFloat(export_value)) && isFinite(export_value)) {
        this.setState({naira_export_value: this.state.export_exchange_rate * export_value});
      } else {
        this.setState({naira_export_value: 0});
      }
    }

    // Repatrition Exchange Rate logic
    if (
      temp_repatriation_date !== null &&
      temp_repatriation_date !== '' &&
      typeof temp_repatriation_date !== 'undefined' &&
      repatriation_currency !== null &&
      repatriation_currency !== '' &&
      typeof repatriation_currency !== 'undefined'
    ) {
      let repatriation_date = this.DateService.convertToFullDate(temp_repatriation_date);
      if (
        repatriation_date !== null &&
        repatriation_date !== '' &&
        typeof repatriation_date !== 'undefined' &&
        (repatriation_date !== this.state.default_repatriation_date || repatriation_currency !== this.state.default_repatriation_currency)
      ) {
        this.setState(
          {
            default_repatriation_date: repatriation_date,
            default_repatriation_currency: repatriation_currency
          },
          () => {
            this.getExchangeRate('repatriation_exchange_rate', repatriation_date, repatriation_currency, this.state.target_currency);
          }
        );
      }

      let amount_repatriated = this.refs.amount_repatriated.value.trim();
      amount_repatriated = Math.abs(amount_repatriated);
      // let freight = this.refs.freight.value.trim();

      if (!isNaN(parseFloat(this.state.repatriation_exchange_rate)) && isFinite(this.state.repatriation_exchange_rate) && !isNaN(parseFloat(amount_repatriated)) && isFinite(amount_repatriated)) {
        this.setState({naira_amount_repatriated: this.state.repatriation_exchange_rate * amount_repatriated}, () => {
          let naira_amount_repatriated = this.refs.naira_equivalent.value.trim();
          naira_amount_repatriated = Math.abs(naira_amount_repatriated);

          if (!isNaN(parseFloat(this.state.eeg_rate)) && isFinite(this.state.eeg_rate) && !isNaN(parseFloat(naira_amount_repatriated)) && isFinite(naira_amount_repatriated)) {
            this.setState({eeg_payable: (this.state.eeg_rate / 100) * naira_amount_repatriated});
          } else {
            this.setState({eeg_payable: 0});
          }
        });
      } else {
        this.setState({
          naira_amount_repatriated: 0,
          eeg_payable: 0
        });
      }
    }

    // Cross Rate logic
    if (
      export_currency !== repatriation_currency &&
      export_currency !== '' && export_currency !== null && typeof export_currency !== 'undefined' &&
      repatriation_currency !== '' && repatriation_currency !== null && typeof repatriation_currency !== 'undefined'
    ) {
      if (
        export_currency !== repatriation_currency &&
        this.state.export_exchange_rate !== 0 &&
        this.state.export_exchange_rate !== null &&
        this.state.repatriation_exchange_rate !== 0 &&
        this.state.repatriation_exchange_rate !== null
      ) {
        const cross_rate = this.Format.RoundToDecimalPlaces(this.state.repatriation_exchange_rate / this.state.export_exchange_rate, 4);
        this.setState({cross_rate: cross_rate});
      } else {
        this.setState({cross_rate: 0});
      }
    } else {
      this.setState({cross_rate: 0});
    }

    //EEG Rate Values Logic
    if (
      (exporter_id !== this.state.exporter_id || product_category !== this.state.product_category) &&
      temp_export_date !== null && temp_export_date !== '' && typeof temp_export_date !== 'undefined' &&
      exporter_id !== '' && exporter_id !== null && typeof exporter_id !== 'undefined' &&
      product_category !== '' && product_category !== null && typeof product_category !== 'undefined'
    ) {
      this.getEEGRateValues();
    }

    const cbn_confirmation_date = this.refs.cbn_confirmation_date.value;
    if (cbn_confirmation_date !== '' && cbn_confirmation_date !== null && typeof cbn_confirmation_date !== 'undefined')
      $('.cbn_confirmed')
        .find('input[type=checkbox]')
        .prop('checked', true);

    const claims_application_date = this.refs.claims_application_date.value;
    if (claims_application_date !== '' && claims_application_date !== null && typeof claims_application_date !== 'undefined') {
      $('.claim_application_received')
        .find('input[type=checkbox]')
        .prop('checked', true);
      $('.claim_data_captured')
        .find('input[type=checkbox]')
        .prop('checked', true);
    }
  }

  getExchangeRate(option, date, base_currency, target_currency) {
    axios
      .get(`${this.apiURL}/api/v1/tools/exchange/rates/specific/${base_currency}/${target_currency}/${date}?tk=${this.Auth.getToken()}`)
      .then(response => {
        this.setState({exchange_rate_values: response.data.data}, () => {

          if (this.state.exchange_rate_values && this.state.exchange_rate_values.length > 0) {
            //Get the values needed for the Cross Rate Logic
            let export_currency = this.refs.export_currency.value;
            if (export_currency === 'Select Currency') export_currency = '';

            let repatriation_currency = this.refs.repatriation_currency.value;
            if (repatriation_currency === 'Select Currency') repatriation_currency = '';

            if (option === 'export_exchange_rate') {
              this.setState({export_exchange_rate: this.state.exchange_rate_values[0].exchange_rate}, () => {
                let export_value = this.refs.export_value.value.trim();
                export_value = Math.abs(export_value);

                if (!isNaN(parseFloat(this.state.export_exchange_rate)) && isFinite(this.state.export_exchange_rate) && !isNaN(parseFloat(export_value)) && isFinite(export_value)) {
                  this.setState({naira_export_value: this.state.export_exchange_rate * export_value});
                } else {
                  this.setState({naira_export_value: 0});
                }

                // Cross Rate logic
                if (
                  export_currency !== repatriation_currency &&
                  export_currency !== '' && export_currency !== null && typeof export_currency !== 'undefined' &&
                  repatriation_currency !== '' && repatriation_currency !== null && typeof repatriation_currency !== 'undefined'
                ) {
                  if (
                    export_currency !== repatriation_currency &&
                    this.state.export_exchange_rate !== 0 &&
                    this.state.export_exchange_rate !== null &&
                    this.state.repatriation_exchange_rate !== 0 &&
                    this.state.repatriation_exchange_rate !== null
                  ) {
                    const cross_rate = this.Format.RoundToDecimalPlaces(this.state.repatriation_exchange_rate / this.state.export_exchange_rate, 4);
                    this.setState({cross_rate: cross_rate});
                  } else {
                    this.setState({cross_rate: 0});
                  }
                } else {
                  this.setState({cross_rate: 0});
                }
              });
            }

            if (option === 'repatriation_exchange_rate') {
              this.setState({repatriation_exchange_rate: this.state.exchange_rate_values[0].exchange_rate}, () => {
                let amount_repatriated = this.refs.amount_repatriated.value.trim();
                amount_repatriated = Math.abs(amount_repatriated);

                if (
                  !isNaN(parseFloat(this.state.repatriation_exchange_rate)) &&
                  isFinite(this.state.repatriation_exchange_rate) &&
                  !isNaN(parseFloat(amount_repatriated)) &&
                  isFinite(amount_repatriated)
                ) {
                  this.setState({naira_amount_repatriated: this.state.repatriation_exchange_rate * amount_repatriated}, () => {
                    let naira_amount_repatriated = this.refs.naira_equivalent.value.trim();
                    naira_amount_repatriated = Math.abs(naira_amount_repatriated);

                    if (!isNaN(parseFloat(this.state.eeg_rate)) && isFinite(this.state.eeg_rate) && !isNaN(parseFloat(naira_amount_repatriated)) && isFinite(naira_amount_repatriated)) {
                      this.setState({eeg_payable: (this.state.eeg_rate / 100) * naira_amount_repatriated});
                    } else {
                      this.setState({eeg_payable: 0});
                    }
                  });
                } else {
                  this.setState({
                    naira_amount_repatriated: 0,
                    eeg_payable: 0
                  });
                }

                // Cross Rate logic
                if (
                  export_currency !== repatriation_currency &&
                  export_currency !== '' && export_currency !== null && typeof export_currency !== 'undefined' &&
                  repatriation_currency !== '' && repatriation_currency !== null && typeof repatriation_currency !== 'undefined'
                ) {
                  if (
                    export_currency !== repatriation_currency &&
                    this.state.export_exchange_rate !== 0 &&
                    this.state.export_exchange_rate !== null &&
                    this.state.repatriation_exchange_rate !== 0 &&
                    this.state.repatriation_exchange_rate !== null
                  ) {
                    const cross_rate = this.Format.RoundToDecimalPlaces(this.state.repatriation_exchange_rate / this.state.export_exchange_rate, 4);
                    this.setState({cross_rate: cross_rate});
                  } else {
                    this.setState({cross_rate: 0});
                  }
                } else {
                  this.setState({cross_rate: 0});
                }
              });
            }
          } else {
            //Get the values needed for the Cross Rate Logic
            let export_currency = this.refs.export_currency.value;
            if (export_currency === 'Select Currency') export_currency = '';

            let repatriation_currency = this.refs.repatriation_currency.value;
            if (repatriation_currency === 'Select Currency') repatriation_currency = '';

            if (option === 'export_exchange_rate') {
              this.setState({export_exchange_rate: 0}, () => {
                let export_value = this.refs.export_value.value.trim();
                export_value = Math.abs(export_value);

                if (!isNaN(parseFloat(this.state.export_exchange_rate)) && isFinite(this.state.export_exchange_rate) && !isNaN(parseFloat(export_value)) && isFinite(export_value)) {
                  this.setState({naira_export_value: this.state.export_exchange_rate * export_value});
                } else {
                  this.setState({naira_export_value: 0});
                }

                // Cross Rate logic
                if (
                  export_currency !== repatriation_currency &&
                  export_currency !== '' && export_currency !== null && typeof export_currency !== 'undefined' &&
                  repatriation_currency !== '' && repatriation_currency !== null && typeof repatriation_currency !== 'undefined'
                ) {
                  if (
                    export_currency !== repatriation_currency &&
                    this.state.export_exchange_rate !== 0 &&
                    this.state.export_exchange_rate !== null &&
                    this.state.repatriation_exchange_rate !== 0 &&
                    this.state.repatriation_exchange_rate !== null
                  ) {
                    const cross_rate = this.Format.RoundToDecimalPlaces(this.state.repatriation_exchange_rate / this.state.export_exchange_rate, 4);
                    this.setState({cross_rate: cross_rate});
                  } else {
                    this.setState({cross_rate: 0});
                  }
                } else {
                  this.setState({cross_rate: 0});
                }
              });
            }

            if (option === 'repatriation_exchange_rate') {
              this.setState({repatriation_exchange_rate: 0}, () => {
                let amount_repatriated = this.refs.amount_repatriated.value.trim();
                amount_repatriated = Math.abs(amount_repatriated);

                if (
                  !isNaN(parseFloat(this.state.repatriation_exchange_rate)) &&
                  isFinite(this.state.repatriation_exchange_rate) &&
                  !isNaN(parseFloat(amount_repatriated)) &&
                  isFinite(amount_repatriated)
                ) {
                  this.setState({naira_amount_repatriated: this.state.repatriation_exchange_rate * amount_repatriated}, () => {
                    let naira_amount_repatriated = this.refs.naira_equivalent.value.trim();
                    naira_amount_repatriated = Math.abs(naira_amount_repatriated);

                    if (!isNaN(parseFloat(this.state.eeg_rate)) && isFinite(this.state.eeg_rate) && !isNaN(parseFloat(naira_amount_repatriated)) && isFinite(naira_amount_repatriated)) {
                      this.setState({eeg_payable: (this.state.eeg_rate / 100) * naira_amount_repatriated});
                    } else {
                      this.setState({eeg_payable: 0});
                    }
                  });
                } else {
                  this.setState({
                    naira_amount_repatriated: 0,
                    eeg_payable: 0
                  });
                }

                // Cross Rate logic
                if (
                  export_currency !== repatriation_currency &&
                  export_currency !== '' && export_currency !== null && typeof export_currency !== 'undefined' &&
                  repatriation_currency !== '' && repatriation_currency !== null && typeof repatriation_currency !== 'undefined'
                ) {
                  if (
                    export_currency !== repatriation_currency &&
                    this.state.export_exchange_rate !== 0 &&
                    this.state.export_exchange_rate !== null &&
                    this.state.repatriation_exchange_rate !== 0 &&
                    this.state.repatriation_exchange_rate !== null
                  ) {
                    const cross_rate = this.Format.RoundToDecimalPlaces(this.state.repatriation_exchange_rate / this.state.export_exchange_rate, 4);
                    this.setState({cross_rate: cross_rate});
                  } else {
                    this.setState({cross_rate: 0});
                  }
                } else {
                  this.setState({cross_rate: 0});
                }
              });
            }
          }
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500 && option === 'export_exchange_rate')
            window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.ExportExchangeRateError, 8000, 'rounded deep-orange darken-4');
          if (error.response.status === 400 && option === 'export_exchange_rate')
            window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.NoExportExchangeRateFound, 8000, 'rounded deep-orange darken-4');

          if (error.response.status === 500 && option === 'repatriation_exchange_rate')
            window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.RepatriationExchangeRateError, 8000, 'rounded deep-orange darken-4');
          if (error.response.status === 400 && option === 'repatriation_exchange_rate')
            window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.NoRepatriationExchangeRateFound, 8000, 'rounded deep-orange darken-4');

          if (option === 'export_exchange_rate') {
            this.setState({
              export_exchange_rate: 0,
              naira_export_value: 0,
              cross_rate: 0
            });
          }

          if (option === 'repatriation_exchange_rate') {
            this.setState({
              repatriation_exchange_rate: 0,
              naira_amount_repatriated: 0,
              eeg_payable: 0,
              cross_rate: 0
            });
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  }

  getEEGRateValues() {
    //Get the values needed for the EEG Rate values
    let temp_export_date = this.refs.export_date.value;
    let exporter_id = this.refs.exporter_id.value;
    if (exporter_id === 'Select Exporter') exporter_id = '';
    let product_category = this.refs.product_category.value;
    let temp_product_category = '';
    if (product_category === 'Select Product Category') {
      product_category = '';
    } else {
      temp_product_category = product_category.split('<:>');
      product_category = temp_product_category[1]; // use the Product categorisation - manufactured, primary produce for the request to the API. Not the Product category name
    }

    //EEG Rate Values Logic
    if (
      temp_export_date !== null &&
      temp_export_date !== '' &&
      typeof temp_export_date !== 'undefined' &&
      exporter_id !== '' && exporter_id !== null && typeof exporter_id !== 'undefined' &&
      product_category !== '' && product_category !== null && typeof product_category !== 'undefined'
    ) {
      let export_date = this.DateService.convertToFullDate(temp_export_date);
      let export_year = export_date.substring(0, 4);
      if (
        export_year !== null &&
        export_year !== '' &&
        typeof export_year !== 'undefined' &&
        (export_date !== this.state.default_export_date || exporter_id !== this.state.exporter_id || product_category !== this.state.product_category)
      ) {
        this.setState(
          {
            default_export_date: export_date,
            exporter_id: exporter_id,
            product_category: product_category
          },
          () => {
            this.getEEGRate(exporter_id, product_category, export_year);
          }
        );
      }
    }
  }

  getEEGRate(exporter_id, product_category, export_year) {
    axios
      .get(`${this.apiURL}/api/v1/baseline/eeg/rates/specific/${exporter_id}/${export_year}?tk=${this.Auth.getToken()}&product_category=${product_category}`)
      .then(response => {
        this.setState({eeg_rate_values: response.data.data}, () => {

          if (this.state.eeg_rate_values && this.state.eeg_rate_values.length > 0) {
            if (this.state.eeg_rate_values[0].eeg_rate !== '' && this.state.eeg_rate_values[0].eeg_rate !== null && typeof this.state.eeg_rate_values[0].eeg_rate !== 'undefined') {
              this.setState({eeg_rate: this.state.eeg_rate_values[0].eeg_rate}, () => {
                let naira_amount_repatriated = this.refs.naira_equivalent.value.trim();
                naira_amount_repatriated = Math.abs(naira_amount_repatriated);

                if (!isNaN(parseFloat(this.state.eeg_rate)) && isFinite(this.state.eeg_rate) && !isNaN(parseFloat(naira_amount_repatriated)) && isFinite(naira_amount_repatriated)) {
                  this.setState({eeg_payable: (this.state.eeg_rate / 100) * naira_amount_repatriated});
                } else {
                  this.setState({eeg_payable: 0});
                }
              });
            }
          } else {
            this.setState({eeg_rate: 0}, () => {
              let naira_amount_repatriated = this.refs.naira_equivalent.value.trim();
              naira_amount_repatriated = Math.abs(naira_amount_repatriated);

              if (!isNaN(parseFloat(this.state.eeg_rate)) && isFinite(this.state.eeg_rate) && !isNaN(parseFloat(naira_amount_repatriated)) && isFinite(naira_amount_repatriated)) {
                this.setState({eeg_payable: (this.state.eeg_rate / 100) * naira_amount_repatriated});
              } else {
                this.setState({eeg_payable: 0});
              }
            });
          }
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.EEGRate.Error, 8000, 'rounded deep-orange darken-4');
          if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.EEGRate.NoEEGRateFound, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  }

  onSubmit(event) {
    event.preventDefault();
    this.prepareData('return_to_main_page');
  }

  prepareData(return_option) {
    let nxp_number = this.refs.nxp_number.value.trim();
    let nxp_number_validation = false;
    let nxp_number_already_used_by = '';
    if (nxp_number !== '' && nxp_number !== null && typeof nxp_number !== 'undefined' && this.state.nxp_number_values && this.state.nxp_number_values.length > 0) {
      nxp_number_validation = true;
      nxp_number_already_used_by = this.state.nxp_number_values[0].exporter_name;
    }

    let nxp_number_length_validation = false;
    if (nxp_number !== '' && nxp_number !== null && typeof nxp_number !== 'undefined' && nxp_number.length !== 15) {
      nxp_number_length_validation = true;
    }

    let exported_items_array = this.state.exported_items_array;
    let exported_items_validation = false;
    if (this.state.exported_items_array && this.state.exported_items_array.length <= 0) {
      exported_items_validation = true;
    }

    let exporter_id = this.refs.exporter_id.value;
    if (exporter_id === 'Select Exporter') exporter_id = '';

    let originating_nxp_bank = this.refs.originating_nxp_bank.value;
    if (originating_nxp_bank === 'Select Originating Bank') originating_nxp_bank = '';

    let nxp_bank_address = this.refs.nxp_bank_address.value.trim();

    let export_date = this.DateService.convertToFullDate(this.refs.export_date.value);
    let claims_application_date = this.DateService.convertToFullDate(this.refs.claims_application_date.value);

    let industry = this.refs.industry.value;
    if (industry === 'Select Industry') industry = '';

    let customer_name = this.refs.customer_name.value;
    let customer_address = this.refs.customer_address.value;
    let destination_city = this.refs.destination_city.value;

    let destination_country = this.refs.destination_country.value;
    if (destination_country === 'Select Destination Country') destination_country = '';

    let export_point = this.refs.export_point.value;

    let product_category = this.refs.product_category.value;
    let temp_product_category = '';
    if (product_category === 'Select Product Category') {
      product_category = '';
    } else {
      temp_product_category = product_category.split('<:>');
      product_category = temp_product_category[0];
    }

    let nxp_quantity = this.refs.nxp_quantity.value;

    let measurement_unit = this.refs.measurement_unit.value;
    if (measurement_unit === 'Select Measurement Unit') measurement_unit = '';

    let export_value = this.refs.export_value.value.trim();

    let export_currency = this.refs.export_currency.value;
    if (export_currency === 'Select Currency') export_currency = '';

    let export_exchange_rate = this.refs.export_exchange_rate.value.trim();
    let naira_export_value = this.refs.naira_export_value.value.trim();

    let eeg_rate = this.refs.eeg_rate.value.trim();
    let eeg_payable = this.refs.eeg_payable.value.trim();

    let commercial_invoice_date = this.DateService.convertToFullDate(this.refs.commercial_invoice_date.value);
    let date_on_bill_of_lading = this.DateService.convertToFullDate(this.refs.date_on_bill_of_lading.value);

    let invoice_quantity = this.refs.invoice_quantity.value.trim();

    let sgd_date = this.DateService.convertToFullDate(this.refs.sgd_date.value);
    let waybill_date = this.DateService.convertToFullDate(this.refs.waybill_date.value);
    let custom_release_date = this.DateService.convertToFullDate(this.refs.custom_release_date.value);
    let cbn_confirmation_date = this.DateService.convertToFullDate(this.refs.cbn_confirmation_date.value);
    let repatriation_date = this.DateService.convertToFullDate(this.refs.repatriation_date.value);

    let amount_repatriated = this.refs.amount_repatriated.value.trim();

    let freight = this.refs.freight.value.trim();
    // if (freight === null || freight === '' || typeof freight === 'undefined' || freight === '0') freight = 0;
    if (freight === null || freight === '' || typeof freight === 'undefined') freight = '0';

    let repatriation_currency = this.refs.repatriation_currency.value;
    if (repatriation_currency === 'Select Currency') repatriation_currency = '';

    let exchange_rate = this.refs.exchange_rate.value.trim();
    let cross_rate = this.refs.cross_rate.value.trim();
    let naira_equivalent = this.refs.naira_equivalent.value.trim();

    let exporter_bank_letter_date = this.DateService.convertToFullDate(this.refs.exporter_bank_letter_date.value);

    let exporter_bank_amount_repatriated = this.refs.exporter_bank_amount_repatriated.value.trim();

    let exporter_bank_repatriation_currency = this.refs.exporter_bank_repatriation_currency.value;
    if (exporter_bank_repatriation_currency === 'Select Currency') exporter_bank_repatriation_currency = '';

    let delivery_mode = this.refs.delivery_mode.value;
    if (delivery_mode === 'Select Delivery Mode') delivery_mode = '';

    let has_clean_certificate_of_inspection = this.refs.has_clean_certificate_of_inspection.checked;

    let cci_number = this.refs.cci_number.value;
    let cci_number_validation = false;
    let cci_number_already_used_by_nxp = '';
    if (cci_number !== '' && cci_number !== null && typeof cci_number !== 'undefined' && this.state.cci_number_values && this.state.cci_number_values.length > 0) {
      cci_number_validation = true;
      cci_number_already_used_by_nxp = this.state.cci_number_values[0].nxp_number;
    }

    let cci_date = this.DateService.convertToFullDate(this.refs.cci_date.value);

    let has_non_oil_export_certificate = this.refs.has_non_oil_export_certificate.checked;

    let general_comments = this.refs.general_comments.value.trim();

    let is_called_over = this.refs.is_called_over.checked;
    let claim_application_received = this.refs.claim_application_received.checked;
    let claim_application_approved = this.refs.claim_application_approved.checked;
    let claim_data_captured = this.refs.claim_data_captured.checked;
    let cbn_confirmed = this.refs.cbn_confirmed.checked;

    let is_delisted = this.refs.is_delisted.checked;
    let reason = this.refs.reason.value.trim();
    let delist_reason = '';
    let enlist_reason = '';
    if (is_delisted === true && reason !== null && reason !== '' && typeof reason !== 'undefined') delist_reason = reason;
    if (is_delisted === false && reason !== null && reason !== '' && typeof reason !== 'undefined') enlist_reason = reason;

    let validated_ok_for_approval = this.refs.validated_ok_for_approval.checked;
    let validated_not_ok_for_approval = this.refs.validated_not_ok_for_approval.checked;
    let claim_cleared = this.refs.claim_cleared.checked;
    let claim_approved_for_payment = this.refs.claim_approved_for_payment.checked;
    let claim_approved_for_issuance = this.refs.claim_approved_for_issuance.checked;
    let report_claim_status = this.refs.report_claim_status.checked;
    let is_paid = this.refs.is_paid.checked;
    let cbn_confirmation = this.refs.cbn_confirmed.checked;

    let is_approved = this.refs.is_approved.checked;
    let approved_by = null;
    if (is_approved) approved_by = this.state.logged_in_username;

    let status = 'Inactive';
    if (this.refs.activate_switch.checked) status = 'Active';
    if (!this.refs.activate_switch.checked) status = 'Inactive';

    let approved_at = null;
    if (is_approved) {
      let currentdate = new Date();
      approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
    }

    let created_by = this.state.logged_in_username;
    let last_updated_by = this.state.logged_in_username;

    // Field Validation values
    let field_validations = [
      nxp_number,
      nxp_number_validation,
      nxp_number_length_validation,
      exported_items_validation,
      exported_items_array,
      exporter_id,
      originating_nxp_bank,
      nxp_bank_address,
      nxp_bank_address,
      export_date,
      claims_application_date,
      industry,
      customer_name,
      customer_address,
      customer_address,
      destination_city,
      destination_country,
      export_point,
      product_category,
      nxp_quantity,
      measurement_unit,
      export_value,
      export_currency,
      export_exchange_rate,
      naira_export_value,
      commercial_invoice_date,
      invoice_quantity,
      sgd_date,
      custom_release_date,
      freight,
      delivery_mode
    ];

    let field_name_validations = [
      this.Language.DataField.NXPNumber,
      this.Language.NodeMessage.EEGClaim.NXPNumber.NXPNumberAlreadyInUse + ' ' + nxp_number_already_used_by,
      this.Language.NodeMessage.EEGClaim.NXPNumber.NXPNumberNotValid,
      this.Language.NodeMessage.EEGClaim.NXPNumber.NoExportedItemCaptured,
      this.Language.DataField.ExportedItems,
      this.Language.DataField.Exporter,
      this.Language.DataField.OriginatingBank,
      this.Language.DataField.BankAddress,
      this.Language.DataField.BankAddress,
      this.Language.DataField.ExportDate,
      this.Language.DataField.ApplicationDate,
      this.Language.DataField.Industry,
      this.Language.DataField.Customer,
      this.Language.DataField.CustomerAddress,
      this.Language.DataField.CustomerAddress,
      this.Language.DataField.DestinationCity,
      this.Language.DataField.DestinationCountry,
      this.Language.DataField.ExportPoint,
      this.Language.DataField.ProductCategory,
      this.Language.DataField.NXPQuantity,
      this.Language.DataField.MeasurementUnit,
      this.Language.DataField.ExportValue,
      this.Language.DataField.ExportCurrency,
      this.Language.DataField.ExportExchangeRate,
      this.Language.DataField.NairaExportValue,
      this.Language.DataField.CommercialInvoiceDate,
      this.Language.DataField.InvoiceQuantity,
      this.Language.DataField.SGDDate,
      this.Language.DataField.CustomReleaseDate,
      this.Language.DataField.Freight,
      this.Language.DataField.DeliveryMode
    ];

    let field_validation_rules = [
      'isRequired',
      'ShowCustomMessage',
      'ShowCustomMessage',
      'ShowCustomMessage',
      'isArray',
      'isRequired',
      'isRequired',
      'isRequired',
      'maxLength',
      'isRequiredDate',
      'isRequiredDate',
      'isRequired',
      'isRequired',
      'isRequired',
      'maxLength',
      'isRequired',
      'isRequired',
      'isRequired',
      'isRequired',
      'isNumber',
      'isRequired',
      'isNumber',
      'isRequired',
      'isNumber',
      'isNumber',
      'isRequiredDate',
      'isNumber',
      'isRequiredDate',
      'isRequiredDate',
      'isNumber',
      'isRequired'
    ];

    if (delivery_mode === 'Air' || delivery_mode === 'Land') {
      field_validations.push(waybill_date);

      field_name_validations.push(this.Language.DataField.WaybillDate);

      field_validation_rules.push('isRequiredDate');
    }

    if (delivery_mode === 'Sea') {
      field_validations.push(date_on_bill_of_lading);

      field_name_validations.push(this.Language.DataField.DateOnBillofLading);

      field_validation_rules.push('isRequiredDate');
    }

    if (delivery_mode === 'Select Delivery Mode' || delivery_mode === '' || (delivery_mode !== 'Air' && delivery_mode !== 'Land' && delivery_mode !== 'Sea')) {
      field_validations.push(waybill_date);
      field_validations.push(date_on_bill_of_lading);

      field_name_validations.push(this.Language.DataField.WaybillDate);
      field_name_validations.push(this.Language.DataField.DateOnBillofLading);

      field_validation_rules.push('isRequiredDate');
      field_validation_rules.push('isRequiredDate');
    }

    if (eeg_rate !== null && typeof eeg_rate !== 'undefined') {
      field_validations.push(eeg_rate);
      field_validations.push(eeg_payable);

      field_name_validations.push(this.Language.DataField.EEGRate);
      field_name_validations.push(this.Language.DataField.EEGPayable);

      field_validation_rules.push('isNumber');
      field_validation_rules.push('isNumber');
    }

    if (cbn_confirmed === true || cbn_confirmation === true) {
      field_validations.push(cbn_confirmation_date);
      field_validations.push(repatriation_date);
      field_validations.push(amount_repatriated);
      field_validations.push(repatriation_currency);
      field_validations.push(exchange_rate);
      field_validations.push(naira_equivalent);
      field_validations.push(exporter_bank_letter_date);
      field_validations.push(exporter_bank_amount_repatriated);
      field_validations.push(exporter_bank_repatriation_currency);

      field_name_validations.push(this.Language.DataField.CBNConfirmationDate);
      field_name_validations.push(this.Language.DataField.RepatriationDate);
      field_name_validations.push(this.Language.DataField.AmountRepatriated);
      field_name_validations.push(this.Language.DataField.RepatriationCurrency);
      field_name_validations.push(this.Language.DataField.ExchangeRate);
      field_name_validations.push(this.Language.DataField.NairaEquivalent);
      field_name_validations.push(this.Language.DataField.ExporterBankLetterDate);
      field_name_validations.push(this.Language.DataField.ExporterBankAmountRepatriated);
      field_name_validations.push(this.Language.DataField.ExporterBankRepatriationCurrency);

      field_validation_rules.push('isRequiredDate');
      field_validation_rules.push('isRequiredDate');
      field_validation_rules.push('isNumber');
      field_validation_rules.push('isRequired');
      field_validation_rules.push('isNumber');
      field_validation_rules.push('isNumber');
      field_validation_rules.push('isRequiredDate');
      field_validation_rules.push('isNumber');
      field_validation_rules.push('isRequired');
    }

    if (has_clean_certificate_of_inspection === true || has_clean_certificate_of_inspection === false) {
      field_validations.push(cci_number);
      field_validations.push(cci_date);
      field_validations.push(cci_number_validation);

      field_name_validations.push(this.Language.DataField.CCINumber);
      field_name_validations.push(this.Language.DataField.CCIDate);
      field_name_validations.push(this.Language.NodeMessage.EEGClaim.CCINumber.CCINumberAlreadyInUse + ' ' + cci_number_already_used_by_nxp);

      field_validation_rules.push('isRequired');
      field_validation_rules.push('isRequiredDate');
      field_validation_rules.push('ShowCustomMessage');
    }

    if (is_delisted === true) {
      field_validations.push(delist_reason);

      field_name_validations.push(this.Language.DataField.DelistReason);

      field_validation_rules.push('isRequired');
    }

    // if (is_delisted === false) {
    //     field_validations.push(enlist_reason);

    //     field_name_validations.push(this.Language.DataField.EnlistReason);

    //     field_validation_rules.push("isRequired");
    // }

    // Check for cross rate
    if (export_currency !== repatriation_currency) {
      field_validations.push(cross_rate);

      field_name_validations.push(this.Language.DataField.CrossRate);

      field_validation_rules.push('isNumber');
    }

    this.setState(
      {
        error_messages: this.Validate.validation(
          this.state.close_error_messages,
          this.Language.ReactValidationMessage.EEGClaim.AddErrorTitle,
          field_validations,
          field_name_validations,
          field_validation_rules,
          0,
          this.state.text_limit
        )
      },
      () => {
        if (this.state.error_messages === null) {
          if (exporter_id === 'Select Exporter') exporter_id = null;
          if (originating_nxp_bank === 'Select Originating Bank') originating_nxp_bank = null;
          if (industry === 'Select Industry') industry = null;
          if (destination_country === 'Select Destination Country') destination_country = null;
          if (product_category === 'Select Product Category') product_category = null;
          if (measurement_unit === 'Select Measurement Unit') measurement_unit = null;
          if (delivery_mode === 'Select Delivery Mode') delivery_mode = null;
          if (export_currency === 'Select Currency') export_currency = null;
          if (repatriation_currency === 'Select Currency') repatriation_currency = null;
          if (exporter_bank_repatriation_currency === 'Select Currency') exporter_bank_repatriation_currency = null;

          if (freight === '0') freight = 0;

          const userOS = this.Browser.getOS();

          //make call to database
          const newEEGClaim = {
            nxp_number: nxp_number || null,
            exporter_id: exporter_id || null,
            originating_nxp_bank: originating_nxp_bank || null,
            nxp_bank_address: nxp_bank_address || null,
            export_date: export_date || null,
            claims_application_date: claims_application_date || null,
            industry: industry || null,
            customer_name: customer_name || null,
            customer_address: customer_address || null,
            destination_city: destination_city || null,
            destination_country: destination_country || null,
            export_point: export_point || null,
            product_category: product_category || null,
            exported_items_array: exported_items_array || null,
            nxp_quantity: Math.abs(nxp_quantity) || 0,
            measurement_unit: measurement_unit || null,
            export_value: Math.abs(export_value) || 0,
            export_currency: export_currency || null,
            export_exchange_rate: Math.abs(export_exchange_rate) || 0,
            naira_export_value: Math.abs(naira_export_value) || 0,
            eeg_rate: Math.abs(eeg_rate) || 0,
            eeg_payable: Math.abs(eeg_payable) || 0,
            commercial_invoice_date: commercial_invoice_date || null,
            date_on_bill_of_lading: date_on_bill_of_lading || null,
            invoice_quantity: Math.abs(invoice_quantity) || 0,
            sgd_date: sgd_date || null,
            waybill_date: waybill_date || null,
            custom_release_date: custom_release_date || null,
            cbn_confirmation_date: cbn_confirmation_date || null,
            repatriation_date: repatriation_date || null,
            amount_repatriated: Math.abs(amount_repatriated) || 0,
            freight: Math.abs(freight) || 0,
            repatriation_currency: repatriation_currency || null,
            exchange_rate: Math.abs(exchange_rate) || 0,
            cross_rate: Math.abs(cross_rate) || 0,
            naira_equivalent: Math.abs(naira_equivalent) || null,
            exporter_bank_letter_date: exporter_bank_letter_date || null,
            exporter_bank_amount_repatriated: Math.abs(exporter_bank_amount_repatriated) || 0,
            exporter_bank_repatriation_currency: exporter_bank_repatriation_currency || null,
            delivery_mode: delivery_mode || null,
            has_clean_certificate_of_inspection: has_clean_certificate_of_inspection || false,
            cci_number: cci_number || null,
            cci_date: cci_date || null,
            has_non_oil_export_certificate: has_non_oil_export_certificate || false,
            general_comments: general_comments || null,
            is_called_over: is_called_over || false,
            claim_application_received: claim_application_received || false,
            claim_application_approved: claim_application_approved || false,
            claim_data_captured: claim_data_captured || false,
            cbn_confirmed: cbn_confirmed || false,
            is_delisted: is_delisted || false,
            delist_reason: delist_reason || '',
            enlist_reason: enlist_reason || '',
            validated_ok_for_approval: validated_ok_for_approval || false,
            validated_not_ok_for_approval: validated_not_ok_for_approval || false,
            claim_cleared: claim_cleared || false,
            claim_approved_for_payment: claim_approved_for_payment || false,
            claim_approved_for_issuance: claim_approved_for_issuance || false,
            report_claim_status: report_claim_status || false,
            is_paid: is_paid || false,
            cbn_confirmation: cbn_confirmation || false,
            is_approved: is_approved || false,
            approved_by: approved_by || null,
            approved_at: approved_at || null,
            status: status || null,
            created_by: created_by,
            last_updated_by: last_updated_by,
            device_name: this.state.logged_in_username + "'s " + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: 'web',
            token: this.Auth.getToken()
          };

          this.setState({preloader: true});
          document.getElementById('save_eeg_claim_button').setAttribute('disabled', true);

          this.addEEGClaim(newEEGClaim, return_option);
        } else {
          window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
        }
      }
    );
  }

  clearForm() {
    document.getElementById('add_eeg_claim_form').reset();

    $('.switch')
      .find('input[type=checkbox]')
      .prop('checked', true);
    // $(".is_approved").find("input[type=checkbox]").prop('checked', true);
  }

  getDropdownValues(pick_list_names) {
    axios
      .get(`${this.apiURL}/api/v1/settings/picklists`, {
        params: {
          tk: this.Auth.getToken(),
          pick_list_names: pick_list_names
        }
      })
      .then(response => {
        this.setState({pick_list_values: response.data.data}, () => {

          if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {
            const select_field_values_exporter_id = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'exporter') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_exporter_id: select_field_values_exporter_id});

            const select_field_values_originating_nxp_bank = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'major_banker') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_originating_nxp_bank: select_field_values_originating_nxp_bank});

            const select_field_values_industry = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'primary_industry') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_industry: select_field_values_industry});

            const select_field_values_destination_country = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'address_country') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_destination_country: select_field_values_destination_country});

            const select_field_values_product_category = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'product_categorisation') {
                let temp_item_name = pick_list_value.item_name.toString().split('<:>'); // split to extract the product name from the categorisation product<:>categorisation
                let new_item_name = temp_item_name[0].toString();

                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={new_item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_product_category: select_field_values_product_category});

            const select_field_values_measurement_unit = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'measurement_unit') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_measurement_unit: select_field_values_measurement_unit});

            const select_field_values_delivery_mode = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'delivery_mode') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_delivery_mode: select_field_values_delivery_mode});

            const select_field_values_currency = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'currency') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_currency: select_field_values_currency});
          }
        });

        window.$('select').material_select();
        this.setState({preloader: null});
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null});
      });
  }

  addEEGClaim(newEEGClaim, return_option) {
    axios
      .request({
        method: 'post',
        url: `${this.apiURL}/api/v1/eeg/claims`,
        data: newEEGClaim
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {

          if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.AddSuccess + newEEGClaim.nxp_number, 8000, 'rounded green');
        });

        this.setState({preloader: null});
        document.getElementById('save_eeg_claim_button').removeAttribute('disabled');

        if (return_option === 'return_to_main_page') {
          this.props.history.push('/exporters/eeg/claims');
        } else {
          this.clearForm();
        }
      })
      .catch(error => {
        if (error.response) {

          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.EEGClaim.AlreadyExist, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        this.setState({preloader: null});
        document.getElementById('save_eeg_claim_button').removeAttribute('disabled');
      });
  }

  render() {
    $('.switch')
      .find('input[type=checkbox]')
      .prop('checked', true);
    // $(".is_approved").find("input[type=checkbox]").prop('checked', true);

    const lengthCheck = 25;

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'EEG Claims'} active_menu_level_2={'Claim Data Capture'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.AddEEGClaim}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.EEGClaims, this.Language.BreadCrumbLabel.AddEEGClaim]}
              links={[`/`, `/exporters/eeg/claims`, `/exporters/eeg/claims/add`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <div className="row">
                <div className="col s12 l12">
                  {this.state.error_messages}

                  <div className="card-panel">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <h4 className="header2">{this.Language.PageLabel.AddANewEEGClaim}</h4>
                    <div className="row">
                      <form className="col s12" id="add_eeg_claim_form" ref="add_eeg_claim_form" onSubmit={this.onSubmit.bind(this)}>
                        <h6 className="teal-text">
                          <b>{this.Language.PageLabel.NXPDetails}</b>
                        </h6>
                        <div className="row">
                          <div className="input-field col s6">
                            <i className="material-icons prefix">language</i>
                            <input id="nxp_number" name="nxp_number" type="text" ref="nxp_number" />
                            <label htmlFor="nxp_number" className="">
                              {this.Language.DataField.NXPNumber} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-exporter_id" className="initialized" name="exporter_id" id="exporter_id" ref="exporter_id">
                                <option value="Select Exporter">{this.Language.DataFieldDefault.SelectExporter}</option>
                                {this.state.select_field_values_exporter_id.map((exporter_value, i) => {
                                  return exporter_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.Exporter} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="export_date"
                              readOnly="false"
                              id="export_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="export_date_root"
                            />
                            <label htmlFor="export_date">
                              {this.Language.DataField.ExportDate} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="claims_application_date"
                              readOnly="false"
                              id="claims_application_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="claims_application_date_root"
                            />
                            <label htmlFor="claims_application_date">
                              {this.Language.DataField.ApplicationDate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s8">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-originating_nxp_bank" className="initialized" name="originating_nxp_bank" ref="originating_nxp_bank">
                                <option value="Select Originating Bank">{this.Language.DataFieldDefault.SelectOriginatingBank}</option>
                                {this.state.select_field_values_originating_nxp_bank.map((originating_nxp_bank_value, i) => {
                                  return originating_nxp_bank_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.OriginatingBank} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s12">
                            <textarea id="nxp_bank_address" className="materialize-textarea" data-length={this.state.text_limit} ref="nxp_bank_address"></textarea>
                            <label htmlFor="nxp_bank_address">
                              {this.Language.DataField.BankAddress} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-industry" className="initialized" name="industry" ref="industry">
                                <option value="Select Industry">{this.Language.DataFieldDefault.SelectIndustry}</option>
                                {this.state.select_field_values_industry.map((industry_value, i) => {
                                  return industry_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.Industry} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-product_category" className="initialized" name="product_category" ref="product_category">
                                <option value="Select Product Category">{this.Language.DataFieldDefault.SelectProductCategory}</option>
                                {this.state.select_field_values_product_category.map((product_category_value, i) => {
                                  return product_category_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.ProductCategory} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input id="export_point" name="export_point" type="text" ref="export_point" />
                            <label htmlFor="export_point" className="">
                              {this.Language.DataField.ExportPoint} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <h6 className="teal-text">
                          <b>{this.Language.PageLabel.CustomerAndInvoiceDetails}</b>
                        </h6>
                        <div className="row">
                          <div className="input-field col s6">
                            <input id="customer_name" name="customer_name" type="text" ref="customer_name" />
                            <label htmlFor="customer_name" className="">
                              {this.Language.DataField.Customer} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s12">
                            <textarea id="customer_address" className="materialize-textarea" data-length={this.state.text_limit} ref="customer_address"></textarea>
                            <label htmlFor="customer_address">
                              {this.Language.DataField.CustomerAddress} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input id="destination_city" name="destination_city" type="text" ref="destination_city" />
                            <label htmlFor="destination_city" className="">
                              {this.Language.DataField.DestinationCity} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-destination_country" className="initialized" name="destination_country" ref="destination_country">
                                <option value="Select Destination Country">{this.Language.DataFieldDefault.SelectDestinationCountry}</option>
                                {this.state.select_field_values_destination_country.map((destination_country_value, i) => {
                                  return destination_country_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.DestinationCountry} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <ul className="collapsible" data-collapsible="expandable">
                            <li className="active">
                              <div className="collapsible-header active cyan lighten-1 white-text">
                                <i className="material-icons">airplanemode_active</i>
                                <b>{this.Language.PageLabel.ExportedItems}</b>
                                <div className="col s4">
                                  <span>
                                    {this.Language.DataField.TotalAmount}: {this.Format.FormatLocaleNumber(this.state.total_exported_items_amount, this.Language.Lang, true, 2)}
                                  </span>
                                </div>
                                <div className="col s4">
                                  <span>
                                    {this.Language.DataField.TotalQuantity}: {this.Format.FormatLocaleNumber(this.state.total_exported_items_quantity, this.Language.Lang, true, 2)}
                                  </span>
                                </div>
                              </div>
                              <div className="collapsible-body white" style={{display: 'none'}}>
                                <div className="row">
                                  <div className="col s12 teal-text">
                                    <span>
                                      <b>{this.Language.DataField.AddExportedItem}: </b>
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="input-field col s12">
                                    <input id="product_description" name="product_description" type="text" ref="product_description" />
                                    <label htmlFor="product_description" className="">
                                      {this.Language.DataField.ProductDescription} *{/* ::after */}
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="input-field col s4">
                                    <input id="unit_price" name="unit_price" type="number" step="any" ref="unit_price" className="validate" defaultValue={0} onChange={this.calculateAmount} />
                                    <label htmlFor="unit_price" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                      {this.Language.DataField.UnitPrice} *{/* ::after */}
                                    </label>
                                  </div>
                                  <div className="input-field col s4">
                                    <input id="quantity" name="quantity" type="number" step="any" ref="quantity" className="validate" defaultValue={0} onChange={this.calculateAmount} />
                                    <label htmlFor="quantity" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                      {this.Language.DataField.Quantity} *{/* ::after */}
                                    </label>
                                  </div>
                                  <div className="input-field col s4">
                                    <input
                                      id="exported_item_amount"
                                      name="exported_item_amount"
                                      type="number"
                                      step="any"
                                      ref="exported_item_amount"
                                      className="validate"
                                      value={this.state.exported_item_amount}
                                    />
                                    <label htmlFor="exported_item_amount" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                      {this.Language.DataField.Amount} *{/* ::after */}
                                    </label>
                                  </div>
                                </div>
                                <div className="row red-text">
                                  <div className="col s12">{this.state.exported_item_validation_message}</div>
                                </div>
                                <div className="row">
                                  <div className="col s12">
                                    <Link to={`#!`} onClick={this.addExportedItem}>
                                      <span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.AddExportedItem}></span>
                                    </Link>
                                  </div>
                                </div>
                                <br />
                                <br />
                                <div className="row teal-text">
                                  <div className="col s12">
                                    <span>
                                      <b>{this.Language.DataField.ExportedItems}:</b> {this.state.exported_items_array.length}
                                    </span>
                                  </div>
                                </div>
                                <br />
                                {this.state.exported_items_array.map((exported_item, i) => {
                                  return [
                                    <div className="row" key={i}>
                                      <div className="col s6">
                                        <span>
                                          <b>
                                            {i + 1}. {this.Language.DataField.ProductDescription}:{' '}
                                          </b>{' '}
                                          {exported_item.product_description}
                                        </span>
                                        <p></p>
                                        <span>
                                          <b> {this.Language.DataField.NXPNumber}: </b> {exported_item.nxp_number}
                                        </span>
                                      </div>
                                      <div className="col s3">
                                        <span>
                                          <b>{this.Language.DataField.UnitPrice}: </b> {this.Format.FormatLocaleNumber(exported_item.unit_price, this.Language.Lang, true, 2)}
                                        </span>
                                        <p></p>
                                        <span>
                                          <b>{this.Language.DataField.Quantity}: </b> {this.Format.FormatLocaleNumber(exported_item.quantity, this.Language.Lang, true, 2)}
                                        </span>
                                        <p></p>
                                      </div>
                                      <div className="col s3">
                                        <span>
                                          <b>{this.Language.DataField.Amount}: </b> {this.Format.FormatLocaleNumber(exported_item.exported_item_amount, this.Language.Lang, true, 2)}
                                        </span>
                                        <p></p>
                                        <Link
                                          to={`#!`}
                                          onClick={() => {
                                            this.removeExportedItem(i, exported_item.exported_item_amount, exported_item.quantity);
                                          }}>
                                          <span className="new badge grey left" data-badge-caption={this.Language.ButtonLabel.Remove}></span>
                                        </Link>
                                      </div>
                                    </div>,
                                    <div className="divider" key={i + 1}></div>,
                                    <br key={i + 2} />
                                  ];
                                })}
                              </div>
                            </li>
                          </ul>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <input id="nxp_quantity" name="nxp_quantity" type="number" step="any" ref="nxp_quantity" className="validate" />
                            <label htmlFor="nxp_quantity" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.NXPQuantity} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s4">
                            <input
                              id="invoice_quantity"
                              name="invoice_quantity"
                              type="number"
                              step="any"
                              ref="invoice_quantity"
                              className="validate"
                              value={this.state.total_exported_items_quantity}
                            />
                            <label htmlFor="invoice_quantity" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.InvoiceQuantity} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s4">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-measurement_unit" className="initialized" name="measurement_unit" ref="measurement_unit">
                                <option value="Select Measurement Unit">{this.Language.DataFieldDefault.SelectMeasurementUnit}</option>
                                {this.state.select_field_values_measurement_unit.map((measurement_unit_value, i) => {
                                  return measurement_unit_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.MeasurementUnit} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-export_currency" className="initialized" name="export_currency" ref="export_currency">
                                <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                {this.state.select_field_values_currency.map((currency_value, i) => {
                                  return currency_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.ExportCurrency} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s8">
                            <input
                              id="export_value"
                              name="export_value"
                              type="number"
                              step="any"
                              ref="export_value"
                              className="validate"
                              value={this.state.total_exported_items_amount}
                              onChange={this.getValues}
                            />
                            <label htmlFor="export_value" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.ExportValue} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <input
                              id="export_exchange_rate"
                              name="export_exchange_rate"
                              type="number"
                              step="any"
                              ref="export_exchange_rate"
                              className="validate"
                              value={this.state.export_exchange_rate}
                            />
                            <label htmlFor="export_exchange_rate" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.ExportExchangeRate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s8">
                            <input id="naira_export_value" name="naira_export_value" type="number" step="any" ref="naira_export_value" className="validate" value={this.state.naira_export_value} />
                            <label htmlFor="naira_export_value" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.NairaExportValue} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-delivery_mode" className="initialized" name="delivery_mode" ref="delivery_mode">
                                <option value="Select Delivery Mode">{this.Language.DataFieldDefault.SelectDeliveryMode}</option>
                                {this.state.select_field_values_delivery_mode.map((delivery_mode_value, i) => {
                                  return delivery_mode_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.DeliveryMode} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="commercial_invoice_date"
                              readOnly="false"
                              id="commercial_invoice_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="commercial_invoice_date_root"
                            />
                            <label htmlFor="commercial_invoice_date">
                              {this.Language.DataField.CommercialInvoiceDate} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="date_on_bill_of_lading"
                              readOnly="false"
                              id="date_on_bill_of_lading"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="date_on_bill_of_lading_root"
                              disabled={this.state.bill_of_lading_status}
                            />
                            <label htmlFor="date_on_bill_of_lading" className={this.state.bill_of_lading_status === false ? 'active' : ''}>
                              {this.Language.DataField.DateOnBillofLading} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="waybill_date"
                              readOnly="false"
                              id="waybill_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="waybill_date_root"
                              disabled={this.state.waybill_status}
                            />
                            <label htmlFor="waybill_date" className={this.state.waybill_status === false ? 'active' : ''}>
                              {this.Language.DataField.WaybillDate} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="sgd_date"
                              readOnly="false"
                              id="sgd_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="sgd_date_root"
                            />
                            <label htmlFor="sgd_date">
                              {this.Language.DataField.SGDDate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="custom_release_date"
                              readOnly="false"
                              id="custom_release_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="custom_release_date_root"
                            />
                            <label htmlFor="custom_release_date">
                              {this.Language.DataField.CustomReleaseDate} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <h6 className="teal-text">
                          <b>{this.Language.PageLabel.RepatriationAndConfirmationDetails}</b>
                        </h6>
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="cbn_confirmation_date"
                              readOnly="false"
                              id="cbn_confirmation_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="cbn_confirmation_date_root"
                            />
                            <label htmlFor="cbn_confirmation_date">
                              {this.Language.DataField.CBNConfirmationDate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="repatriation_date"
                              readOnly="false"
                              id="repatriation_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="repatriation_date_root"
                            />
                            <label htmlFor="repatriation_date">
                              {this.Language.DataField.RepatriationDate} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-repatriation_currency" className="initialized" name="repatriation_currency" ref="repatriation_currency">
                                <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                {this.state.select_field_values_currency.map((currency_value, i) => {
                                  return currency_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.RepatriationCurrency} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input id="amount_repatriated" name="amount_repatriated" type="number" step="any" ref="amount_repatriated" className="validate" onChange={this.getValues} />
                            <label htmlFor="amount_repatriated" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.AmountRepatriated} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s2">
                            <input id="freight" name="freight" type="number" step="any" ref="freight" className="validate" />
                            <label htmlFor="freight" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.Freight} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <input id="exchange_rate" name="exchange_rate" type="number" step="any" ref="exchange_rate" className="validate" value={this.state.repatriation_exchange_rate} />
                            <label htmlFor="exchange_rate" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.ExchangeRate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s4">
                            <input id="cross_rate" name="cross_rate" type="number" step="any" ref="cross_rate" className="validate" value={this.state.cross_rate} />
                            <label htmlFor="cross_rate" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.CrossRate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s4">
                            <input id="naira_equivalent" name="naira_equivalent" type="number" step="any" ref="naira_equivalent" className="validate" value={this.state.naira_amount_repatriated} />
                            <label htmlFor="naira_equivalent" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.NairaEquivalent} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <input id="eeg_rate" name="eeg_rate" type="number" step="any" ref="eeg_rate" className="validate" value={this.state.eeg_rate} />
                            <label htmlFor="eeg_rate" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.EEGRate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s8">
                            <input id="eeg_payable" name="eeg_payable" type="number" step="any" ref="eeg_payable" className="validate" value={this.state.eeg_payable} />
                            <label htmlFor="eeg_payable" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.EEGPayable} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s4">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="exporter_bank_letter_date"
                              readOnly="false"
                              id="exporter_bank_letter_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="exporter_bank_letter_date_root"
                            />
                            <label htmlFor="exporter_bank_letter_date">
                              {this.Language.DataField.ExporterBankLetterDate} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s3">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select
                                data-select-id="select-options-exporter_bank_repatriation_currency"
                                className="initialized"
                                name="exporter_bank_repatriation_currency"
                                ref="exporter_bank_repatriation_currency">
                                <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                {this.state.select_field_values_currency.map((currency_value, i) => {
                                  return currency_value;
                                })}
                              </select>
                            </div>
                            <label>
                              {this.Language.DataField.ExporterBankRepatriationCurrency} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s5">
                            <input id="exporter_bank_amount_repatriated" name="exporter_bank_amount_repatriated" type="number" step="any" ref="exporter_bank_amount_repatriated" className="validate" />
                            <label htmlFor="exporter_bank_amount_repatriated" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                              {this.Language.DataField.ExporterBankAmountRepatriated} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <h6 className="teal-text">
                          <b>{this.Language.PageLabel.OtherDetails}</b>
                        </h6>
                        <div className="row">
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="has_non_oil_export_certificate" ref="has_non_oil_export_certificate" name="has_non_oil_export_certificate" />
                              <label htmlFor="has_non_oil_export_certificate">{this.Language.DataField.NonOilExportCertificate}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="has_clean_certificate_of_inspection" ref="has_clean_certificate_of_inspection" name="has_clean_certificate_of_inspection" />
                              <label htmlFor="has_clean_certificate_of_inspection">{this.Language.DataField.CleanCertificateOfInspection}</label>
                            </p>
                          </div>
                          <div className="input-field col s3">
                            <input id="cci_number" name="cci_number" type="text" ref="cci_number" disabled={this.state.cci_number_status} />
                            <label htmlFor="cci_number" className={this.state.cci_number_status === false ? 'active' : ''}>
                              {this.Language.DataField.CCINumber} *{/* ::after */}
                            </label>
                          </div>
                          <div className="input-field col s3">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="cci_date"
                              readOnly="false"
                              id="cci_date"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="cci_date_root"
                              disabled={this.state.cci_date_status}
                            />
                            <label htmlFor="cci_date" className={this.state.cci_date_status === false ? 'active' : ''}>
                              {this.Language.DataField.InspectionDate} *{/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="input-field col s12">
                            <textarea id="general_comments" name="general_comments" className="materialize-textarea" data-length="3000" ref="general_comments"></textarea>
                            <label htmlFor="general_comments">
                              {this.Language.DataField.GeneralComments}
                              {/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <h6 className="teal-text">
                          <b>{this.Language.PageLabel.ClaimStatus}</b>
                        </h6>
                        <div className="row">
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="is_called_over" ref="is_called_over" name="is_called_over" />
                              <label htmlFor="is_called_over">{this.Language.DataField.CalledOver}</label>
                            </p>
                          </div>
                          <div className="col s4 cbn_confirmed">
                            <p>
                              <input type="checkbox" id="cbn_confirmed" ref="cbn_confirmed" name="cbn_confirmed" />
                              <label htmlFor="cbn_confirmed">{this.Language.DataField.CBNConfirmation}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="is_paid" ref="is_paid" name="is_paid" />
                              <label htmlFor="is_paid">{this.Language.DataField.Paid}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="is_approved" ref="is_approved" name="is_approved" />
                              <label htmlFor="is_approved">{this.Language.DataField.Approved}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="report_claim_status" ref="report_claim_status" name="report_claim_status" />
                              <label htmlFor="report_claim_status">{this.Language.DataField.ReportClaimStatus}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s4 claim_application_received">
                            <p>
                              <input type="checkbox" id="claim_application_received" ref="claim_application_received" name="claim_application_received" />
                              <label htmlFor="claim_application_received">{this.Language.DataField.ApplicationReceived}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="claim_application_approved" ref="claim_application_approved" name="claim_application_approved" />
                              <label htmlFor="claim_application_approved">{this.Language.DataField.ApplicationApproved}</label>
                            </p>
                          </div>
                          <div className="col s4 claim_data_captured">
                            <p>
                              <input type="checkbox" id="claim_data_captured" ref="claim_data_captured" name="claim_data_captured" />
                              <label htmlFor="claim_data_captured">{this.Language.DataField.ApplicationCaptured}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="is_delisted" ref="is_delisted" name="is_delisted" />
                              <label htmlFor="is_delisted">{this.Language.DataField.Delisted}</label>
                            </p>
                          </div>
                          <div className="input-field col s8">
                            <textarea id="reason" className="materialize-textarea" data-length="300" name="reason" ref="reason"></textarea>
                            <label htmlFor="reason" data-error={this.Language.DataFieldError.MaxLength}>
                              {this.Language.DataField.Reason}
                              {/* ::after */}
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="validated_ok_for_approval" ref="validated_ok_for_approval" name="validated_ok_for_approval" />
                              <label htmlFor="validated_ok_for_approval">{this.Language.DataField.ValidatedOkForApproval}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="validated_not_ok_for_approval" ref="validated_not_ok_for_approval" name="validated_not_ok_for_approval" />
                              <label htmlFor="validated_not_ok_for_approval">{this.Language.DataField.ValidatedNotOkForApproval}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="claim_cleared" ref="claim_cleared" name="claim_cleared" />
                              <label htmlFor="claim_cleared">{this.Language.DataField.ClaimCleared}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="claim_approved_for_payment" ref="claim_approved_for_payment" name="claim_approved_for_payment" />
                              <label htmlFor="claim_approved_for_payment">{this.Language.DataField.ClaimApprovedForPayment}</label>
                            </p>
                          </div>
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="claim_approved_for_issuance" ref="claim_approved_for_issuance" name="claim_approved_for_issuance" />
                              <label htmlFor="claim_approved_for_issuance">{this.Language.DataField.ClaimApprovedForIssuance}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col s8">
                            <br />
                            <div className="switch">
                              <label className="font-weight-600 black-text">
                                {this.Language.DataField.DeactivateEEGClaim}
                                <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                <span className="lever"></span>
                                {this.Language.DataField.ActivateEEGClaim}
                              </label>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className={this.Language.ButtonLabel.SaveEEGClaim.length <= lengthCheck ? 'input-field col s5' : 'input-field col s7'}>
                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_eeg_claim_button">
                              {this.Language.ButtonLabel.SaveEEGClaim}
                            </button>
                            <Link
                              className="waves-effect waves-light btn grey lighten-3 cyan-text right"
                              to={`#!`}
                              onClick={() => {
                                this.prepareData('stay_on_current_page');
                              }}>
                              {this.Language.ButtonLabel.SaveAndNew}
                            </Link>
                          </div>
                          {/* ::after */}
                          <div className={this.Language.ButtonLabel.SaveEEGClaim.length <= lengthCheck ? 'input-field col s7' : 'input-field col s5'}>
                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/exporters/eeg/claims`}>
                              <i className="material-icons left">cancel</i>
                              {this.Language.ButtonLabel.Cancel}
                            </Link>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                      </form>
                      {/* ::after */}
                    </div>
                  </div>
                </div>
                {/* ::after */}
              </div>
              <br />
              <br />
              <br />
              <h4 className="header2">{this.Language.PageLabel.UploadClaimApplicationDocuments}</h4>
              {this.state.fileUploadItem_combined}
              {/* <FileUpload fieldName={this.Language.PageLabel.NXPForm} fileExtensions={'pdf png jpeg jpg'} fieldID={'nxp-form-file-001'} maxFileSize={'10M'} defaultFile={''} fileName={'NXP_Form'} pageDataCategory={"NXP_Form"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_1}
              {/* <FileUpload fieldName={this.Language.PageLabel.NXPForm} fileExtensions={'pdf png jpeg jpg'} fieldID={'nxp-form-file-001'} maxFileSize={'10M'} defaultFile={''} fileName={'NXP_Form'} pageDataCategory={"NXP_Form"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_2}
              {/* <FileUpload fieldName={this.Language.PageLabel.BillOfLadingOrWaybill} fileExtensions={'pdf png jpeg jpg'} fieldID={'bill_of_lading_waybill-file-002'} maxFileSize={'10M'} defaultFile={''} fileName={'Bill_of_Lading_Waybill'} pageDataCategory={"Bill_of_Lading_Waybill"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_3}
              {/* <FileUpload fieldName={this.Language.PageLabel.SGDForm} fileExtensions={'pdf png jpeg jpg'} fieldID={'sgd_form-file-003'} maxFileSize={'10M'} defaultFile={''} fileName={'Single_Goods_Declaration'} pageDataCategory={"Single_Goods_Declaration"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_4}
              {/* <FileUpload fieldName={this.Language.PageLabel.BankLetter} fileExtensions={'pdf png jpeg jpg'} fieldID={'bank_letter-file-004'} maxFileSize={'10M'} defaultFile={''} fileName={'Bank_Letter_To_CBN'} pageDataCategory={"Bank_Letter_To_CBN"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_5}
              {/* <FileUpload fieldName={this.Language.PageLabel.CommercialInvoice} fileExtensions={'pdf png jpeg jpg'} fieldID={'commercial_invoice-file-005'} maxFileSize={'10M'} defaultFile={''} fileName={'Commercial_Invoice'} pageDataCategory={"Commercial_Invoice"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_6}
              {/* <FileUpload fieldName={this.Language.PageLabel.CleanCertificateOfInspection} fileExtensions={'pdf png jpeg jpg'} fieldID={'cci-file-006'} maxFileSize={'10M'} defaultFile={''} fileName={'Clean_Certificate_of_Inspection'} pageDataCategory={"Clean_Certificate_of_Inspection"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_7}
              {/* <FileUpload fieldName={this.Language.PageLabel.NEPCNonOilDataForm} fileExtensions={'pdf png jpeg jpg'} fieldID={'non_oil_data-file-007'} maxFileSize={'10M'} defaultFile={''} fileName={'NEPC_Non_Oil_Data_Form'} pageDataCategory={"NEPC_Non_Oil_Data_Form"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_8}
              {/* <FileUpload fieldName={this.Language.PageLabel.CertificatesOfManufacture} fileExtensions={'pdf png jpeg jpg'} fieldID={'certficate_manufacture-file-008'} maxFileSize={'10M'} defaultFile={''} fileName={'Certificates_Of_Manufacture'} pageDataCategory={"Certificates_Of_Manufacture"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_9}
              {/* <FileUpload fieldName={this.Language.PageLabel.CBNNEPCSchedule} fileExtensions={'pdf png jpeg jpg'} fieldID={'cbn_nepc_schedule-file-009'} maxFileSize={'10M'} defaultFile={''} fileName={'CBN_NEPC_Schedule'} pageDataCategory={"CBN_NEPC_Schedule"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              {this.state.fileUploadItem_10}
              {/* <FileUpload fieldName={this.Language.PageLabel.ExporterApplicationLetter} fileExtensions={'pdf png jpeg jpg'} fieldID={'exporter_application_letter-file-010'} maxFileSize={'10M'} defaultFile={''} fileName={'Exporter_Application_Letter'} pageDataCategory={"Exporter_Application_Letter"} pageName={"AddEEGClaim"} loggedInUsername={this.state.logged_in_username} RequiredFields={this.Language.DataField.Exporter + ", " + this.Language.DataField.NXPNumber + ", " + this.Language.DataField.ExportDate}/> */}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default AddEEGClaim;
