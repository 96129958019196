import React, { Component } from 'react';


class DatatableHeaderItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            headerItem: props.headerItem,
            sortingClassName: props.sortingClassName,
            thStyle: props.thStyle
        }
    }

    render(){

        const label = this.state.headerItem + ": activate to sort column ascending";

        if ( this.state.sortingClassName ===  "sorting_asc"){
            return (
                <th className={this.state.sortingClassName} tabIndex="0" aria-controls="data-table-simple" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label={label} style={{width: this.state.thStyle}}>{this.state.headerItem}</th>
            )
        };

        if ( this.state.sortingClassName ===  "sorting_des"){
            return (
                <th className={this.state.sortingClassName} tabIndex="0" aria-controls="data-table-simple" rowSpan="1" colSpan="1" aria-sort="descending" aria-label={label} style={{width: this.state.thStyle}}>{this.state.headerItem}</th>
            )
        }

        return (
            <th className={this.state.sortingClassName} tabIndex="0" aria-controls="data-table-simple" rowSpan="1" colSpan="1" aria-label={label} style={{width: this.state.thStyle}}>{this.state.headerItem}</th>
        )
    }
}

export default DatatableHeaderItem;