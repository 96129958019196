import URLService from './URLService';

export default class AuthService {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(token) {
    sessionStorage.setItem('ep_token', token);
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return sessionStorage.getItem('ep_token') !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  logout() {
    sessionStorage.removeItem('ep_token');
  }

  removeLanguage() {
    sessionStorage.removeItem('ep_lang');
  }

  removeTimeZone() {
    sessionStorage.removeItem('ep_timezone');
  }

  removeDashboards() {
    sessionStorage.removeItem('ep_dashboards');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  getToken() {
    try {
      return sessionStorage.getItem('ep_token') || URLService.prototype.getQueryVariable('eut');
    } catch (error) {
      return null;
    }
  }

  loggedIn() {
    try {
      // Checks if there is a saved token and it's still valid
      const token = this.getToken(); // Getting token from sessionstorage
      return !!token && !this.isTokenExpired(token); // handwaiving here
    } catch (error) {
      return false;
    }
  }

  isTokenExpired(token) {
    try {
      const decoded = this.decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (error) {
      return false;
    }
  }

  getProfile() {
    return this.decode(this.getToken());
  }

  decode(token) {
    try {
      let payload = JSON.parse(atob(token.split('.')[1]));
      return payload;
    } catch (error) {
      return null;
    }
  }

  checkAccessScope(scopes, object_type) {
    let decoded_token = this.decode(this.getToken());
    let token_payload = decoded_token.scopes;
    let has_access = false;

    // Check the views scope
    if (object_type === 'view') {
      let len = token_payload['views'].length;

      for (let i = 0; i < len; i++) {
        for (let j = 0; j < scopes.length; j++) {
          if (scopes[j] === token_payload['views'][i]) has_access = true;
        }
      }
    }

    // Check the buttons scope
    if (object_type === 'button') {
      let len = token_payload['buttons'].length;

      for (let i = 0; i < len; i++) {
        for (let j = 0; j < scopes.length; j++) {
          if (scopes[j] === token_payload['buttons'][i]) has_access = true;
        }
      }
    }

    // Check the processes scope
    if (object_type === 'process') {
      let len = token_payload['processes'].length;

      for (let i = 0; i < len; i++) {
        for (let j = 0; j < scopes.length; j++) {
          if (scopes[j] === token_payload['processes'][i]) has_access = true;
        }
      }
    }

    return has_access;
  }

  static saveDashboard(dashboard_item) {
    try {
      const dashboards = sessionStorage.getItem('ep_dashboards');
      let new_dashboards = '';

      if (dashboards !== '' && dashboards !== null && typeof dashboards !== 'undefined') {
        new_dashboards = dashboards + ',' + dashboard_item;
      }
      sessionStorage.setItem('ep_dashboards', new_dashboards);
    } catch (error) {}
  }

  getDashboards() {
    try {
      let dashboards = sessionStorage.getItem('ep_dashboards');

      if (dashboards !== '' && dashboards !== null && typeof dashboards !== 'undefined') {
        const temp_dashboards = dashboards.split(','); // convert to an array
        return temp_dashboards;
      } else {
        throw new Error(); // this forces the execution to move to the catch section of this try-catch
      }
    } catch (error) {
      try {
        let decoded_token = this.decode(this.getToken());
        let token_dashboards = decoded_token.dashboards;

        if (token_dashboards && token_dashboards !== '' && token_dashboards !== null && typeof token_dashboards !== 'undefined') {
          const temp_dashboards = token_dashboards.join(','); // convert array to string with comma seperation
          sessionStorage.setItem('ep_dashboards', temp_dashboards);

          return token_dashboards;
        } else {
          throw new Error(); // this forces the execution to move to the catch section of this try-catch
        }
      } catch (error) {
        let default_dashboards = [];
        return default_dashboards;
      }
    }
  }
}
