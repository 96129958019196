import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';




class ExporterProfile extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getExporter = this.getExporter.bind(this);
        this.getAnnualRegistrationDetails = this.getAnnualRegistrationDetails.bind(this);
        this.getExporterExportedItems = this.getExporterExportedItems.bind(this);
        this.state = {
            exporterDetails: '',
            annualRegistrationItems: '',
            exporterExportedItems: '',
            exporter_registration_details: [],
            exporter_exported_items_details: [],
            result: '',
            message: '',
            hash_organisation: '',
            exporter_update: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.DateService = new DateService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
        
    
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.ExporterProfile + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getExporter();
        this.getAnnualRegistrationDetails();
        this.getExporterExportedItems();

        
    }


    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null){
            this.setState({hash_user: user_profile.user,
                username: user_profile.username,
                scopes: user_profile.scopes,
                hash_organisation: user_profile.organisation
            }, () => {
                this.checkAccessRight();
            });
        }

    }


    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ( (this.Auth.checkAccessScope(['read:exporter', 'action:exporter'], 'view') === false) || this.state.hash_organisation !== this.props.match.params.id){
             this.props.history.replace(default_home);   
        } else {
            this.getAccessScopes();
        };
 
     }


     getAccessScopes(){

        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'update:exporter' || this.state.scopes['views'][i] === 'action:exporter') this.setState({exporter_update: true});
            }
        };


    }



    getExporter(){
        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/exporters/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {
                this.setState({exporterDetails: response.data.data[0]}, () => {
                    
                })
                

                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx

                  

                  if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });
    }


    getAnnualRegistrationDetails(){

        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/exporter/annual/registration/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({annualRegistrationItems: response.data.data}, () => {
                    

                    if (this.state.annualRegistrationItems && this.state.annualRegistrationItems.length > 0) {
                    

                        const exporter_registration_details = this.state.annualRegistrationItems.map((annual_registration_value,  i) => {
                                return(
                                    <li className="collection-item" key={i}>
                                        <div className="row">
                                            <div className="col s3">
                                                <b> {this.Language.PageLabel.ExportYear}:</b> {annual_registration_value.export_year}
                                                <b> {this.Language.PageLabel.Registered}:</b> {(annual_registration_value.is_registered !== null && typeof annual_registration_value.is_registered !== 'undefined') ?  ((annual_registration_value.is_registered === 'true') || (annual_registration_value.is_registered === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }
                                            </div>
                                            <div className="col s4">
                                                <b> {this.Language.PageLabel.StatusInScheme}:</b> {annual_registration_value.status_in_scheme}
                                            </div>
                                            <div className="col s5">
                                            <b> {this.Language.PageLabel.Category}:</b> {annual_registration_value.category_name}
                                            </div>
                                            {/* :;after */}
                                        </div>
                                    </li>
                                )
                        })
    
                        this.setState({exporter_registration_details: exporter_registration_details});

                    }

                })


                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  

                    if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                  

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });

    }



    
    getExporterExportedItems(){

        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/exporter/exported/items/all/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {

                
                this.setState({exporterExportedItems: response.data.data}, () => {
                    

                    if (this.state.exporterExportedItems && this.state.exporterExportedItems.length > 0) {
                    

                        const exporter_exported_items_details = this.state.exporterExportedItems.map((exporter_exported_item_value,  i) => {
                                return(
                                    <li className="collection-item" key={i}>
                                        <div className="row">
                                            <div className="col s5">
                                                <b> {this.Language.PageLabel.ExportedItem}:</b> {exporter_exported_item_value.exported_item}
                                                <b> {this.Language.PageLabel.Status}:</b> {exporter_exported_item_value.status ?  ((exporter_exported_item_value.status === 'Active') ?  this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null }
                                            </div>
                                            <div className="col s1">
                                            </div>
                                            <div className="col s6">
                                            <b> {this.Language.PageLabel.Description}:</b> {exporter_exported_item_value.exported_item_description}
                                            </div>
                                            {/* :;after */}
                                        </div>
                                    </li>
                                )
                        })
    
                        this.setState({exporter_exported_items_details: exporter_exported_items_details});

                    }

                })

                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  


                    if(error.response.data.message[0].msg){

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                            if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }
                    

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });

    }






    render(){

        let date_of_registration = this.state.exporterDetails.registration_date + '';
        date_of_registration = this.DateService.convertToCalendarFullDate(date_of_registration);

        let exporterId = this.props.match.params.id;

 

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Organisation"} active_menu_level_2={""} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.ExporterProfile}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ExporterProfile]} links={[`/`, `/exporter/profile/${exporterId}`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <div id="profile-page" className="section">
                                    <div id="profile-page-header" className="card" style={{overflow: "hidden"}}>
                                        <div className="card-image waves-effect waves-block waves-light">
                                            <img className="activator" src="/assets/images/gallary/30.png" alt=""/>
                                        </div>
                                        <figure className="card-profile-image">
                                            <img src="/images/bank.png" alt="" className="circle white z-depth-2 responsive-img activator gradient-shadow"/>
                                        </figure>
                                        <div className="card-content">
                                            <div className="row pt-2">
                                                <div className="col s12 m4 offset-m2">
                                                    <h4 className="card-title grey-text text-darken-4">{this.state.exporterDetails.exporter_name} </h4>
                                                    <p className="medium-small grey-text">{this.state.exporterDetails.registration_number}</p>
                                                </div>
                                                <div className="col s12 m2 center-align">
                                                    <h4 className="card-title grey-text text-darken-4">{this.state.exporterDetails.exporter_category}</h4>
                                                    <p className="medium-small grey-text">{this.Language.DataField.ExporterCategory}</p>
                                                </div>
                                                <div className="col s12 m2 center-align">
                                                    <h4 className="card-title grey-text text-darken-4">{date_of_registration}</h4>
                                                    <p className="medium-small grey-text">{this.Language.DataField.RegistrationDate}</p>
                                                </div>
                                                <div className="col s12 m1 center-align">
                                                    <h4 className="card-title grey-text text-darken-4">{this.state.exporterDetails.status_in_scheme}</h4>
                                                    <p className="medium-small grey-text">{this.Language.DataField.StatusInScheme}</p>
                                                </div>
                                                <div className="col s12 m1 right-align">
                                                    <a href={"#!"} className="btn-floating activator waves-effect waves-light teal accent-2 right">
                                                        <i className="material-icons">account_balance</i>
                                                    </a>
                                                </div>
                                                {/* :;after */}
                                            </div>
                                        </div>
                                        <div className="card-reveal" style={{display: "none", transform: "translateY(0px)"}}>
                                            <p>
                                                <span className="card-title grey-text text-darken-4">
                                                {this.state.exporterDetails.exporter_name} 
                                                    <i className="material-icons right">close</i>
                                                </span>
                                                <span>
                                                    <i className="material-icons cyan-text text-darken-2">account_balance</i>
                                                    {this.state.exporterDetails.registration_number}
                                                </span>
                                            </p>
                                            <p>
                                                {this.state.exporterDetails.website}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">perm_phone_msg</i>
                                                 {this.state.exporterDetails.phone}, {this.state.exporterDetails.mobile}, {this.state.exporterDetails.alternate_phone}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">email</i>
                                                 {this.state.exporterDetails.email}, {this.state.exporterDetails.alternate_email}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">card_membership</i>
                                                 {date_of_registration}
                                            </p>
                                            <p>
                                                <i className="material-icons cyan-text text-darken-2">assignment_turned_in</i>
                                                {this.Language.DataField.ExporterCategory}: {this.state.exporterDetails.exporter_category}
                                            </p>
                                        </div>
                                    </div>
                                    <div id="profile-page-content" className="row">
                                        <div id="profile-page-sidebar" className="col s12 m5">
                                            <div className="card cyan">
                                                <div className="card-content white-text">
                                                    <span className="card-title">{this.Language.DataField.ExporterDescription}: <b>{this.state.exporterDetails.exporter_description}</b> </span>
                                                    <p>
                                                        {this.Language.DataField.ExporterType}: <b>{this.state.exporterDetails.exporter_type}</b>
                                                    </p>
                                                    <p> {this.Language.DataField.PrimaryIndustry}: <b>{this.state.exporterDetails.primary_industry}</b> </p>
                                                    <p> {this.Language.DataField.FinancialYearEnd}: <b>{this.state.exporterDetails.financial_year_end}</b> </p>
                                                    <p> {this.Language.DataField.MajorBanker}: <b>{this.state.exporterDetails.major_banker}</b> </p>
                                                    <p> {this.Language.DataField.PrioritySector}: <b>{(this.state.exporterDetails.priority_sector !== null && typeof this.state.exporterDetails.priority_sector !== 'undefined') ?  ((this.state.exporterDetails.priority_sector === 'true') || (this.state.exporterDetails.priority_sector === true) ?  this.Language.DataValue.Yes : this.Language.DataValue.No) : null }</b> </p>
                                                    <p> {this.Language.DataField.StatusInScheme}: <b>{this.state.exporterDetails.status_in_scheme}</b> </p> 
                                                    <p> {this.Language.DataField.Status}: <b>{this.state.exporterDetails.status ? (this.state.exporterDetails.status === 'Active' ?  this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null }</b> </p> 
                                                </div>
                                            </div>
                                            <ul id="profile-page-about-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <b className="teal-text"> {this.Language.PageLabel.ContactDetails} </b>
                                                        </div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">perm_phone_msg</i>
                                                            {this.Language.DataField.Phone}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.phone}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">phone</i>
                                                            {this.Language.DataField.AlternatePhone}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.alternate_phone}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">smartphone</i>
                                                            {this.Language.DataField.Mobile}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.mobile}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">email</i>
                                                            {this.Language.DataField.Email}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.email}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">email</i>
                                                            {this.Language.DataField.AlternateEmail}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.alternate_email}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">print</i>
                                                            {this.Language.DataField.Fax}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.fax}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">language</i>
                                                            {this.Language.DataField.Website}
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.website}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">card_membership</i>
                                                            {this.Language.DataField.RegistrationDate}
                                                        </div>
                                                        <div className="col s7 right-align">{date_of_registration}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <br/>
                                            <br/>
                                        </div>
                                        <div id="profile-page-wall" className="col s12 m7">
                                            
                                            <ul id="profile-page-about-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">location_on</i>
                                                            <b className="teal-text"> {this.Language.PageLabel.Address} </b>
                                                        </div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine1}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.address_line1}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine2}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.address_line2}</div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AddressLine3}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.address_line3}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.City}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.address_city}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.State}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.address_state}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.Country}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.address_country}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.AlternateAddress}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.alternate_address}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.MailingAddress}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.mailing_address}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.ZipCode}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.post_zip_code}</div>
                                                    </div>
                                                </li>
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            {this.Language.DataField.PostalArea}:
                                                        </div>
                                                        <div className="col s7 right-align">{this.state.exporterDetails.postal_area}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            {/* ::after */}
                                            <ul id="exporter-annual-registration-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">assignment_turned_in</i>
                                                            <b className="teal-text"> {this.Language.PageLabel.AnnualRegistration}</b>
                                                        </div>
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                {this.state.exporter_registration_details}
                                            </ul>
                                            <ul id="exporter-exported-items-details" className="collection z-depth-1">
                                                <li className="collection-item">
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <i className="material-icons left">airplanemode_active</i>
                                                            <b className="teal-text"> {this.Language.PageLabel.ExportedItems}</b>
                                                        </div>
            {this.state.exporter_update   &&            <Link to={`/exporter/${this.state.hash_organisation}/exported/items`}><span className="new badge gradient-45deg-purple-deep-orange right" data-badge-caption={this.Language.ButtonLabel.ManageExportedItems}></span></Link>}
                                                        {/* :;after */}
                                                    </div>
                                                </li>
                                                {this.state.exporter_exported_items_details}
                                            </ul>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                    {/* <Link className="waves-effect waves-light btn" to={`/exporter/profile/edit/${this.state.exporterDetails.hash_id}`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link>  */}

                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]  
        )
    }
}

export default ExporterProfile;