import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import axios from 'axios';



class EEGRatesProfile extends Component{
    constructor(props){
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getEEGRates = this.getEEGRates.bind(this);
        this.state = {
            EEGRateItems: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            EEGRateDataItems:  null,
            hash_organisation: ''
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();
        
    }


    componentWillMount(){
        
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.ExporterEEGRatesProfile + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();        
        this.getEEGRates();

        
    }


    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

       if ( (this.Auth.checkAccessScope(['read:baseline', 'action:baseline'], 'view') === false) || this.state.hash_organisation !== this.props.match.params.id){
            this.props.history.replace(default_home);   
       };

    }



    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null){
            this.setState({hash_organisation: user_profile.organisation}, () => {
                this.checkAccessRight();
            });
        }

    }



    getEEGRates(){

        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/baseline/eeg/rates/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({EEGRateItems: response.data.data}, () => {
                    

                        this.setState({EEGRateDataItems: <DataTable tableName={''} 
                                        fields={['business_year', 'exporter_name', 'exporter_category', 'export_growth_score', 'investment_growth_score', 'local_content_score', 'value_addition_score', 'employment_score', 'priority_sector_score', 'total_score', 'eeg_rate']} 
                                        fieldDataTypes={['Date-Year', 'String', 'String', 'Number', 'Number','Number', 'Number', 'Number', 'Number', 'Number', 'Number']}
                                        fieldDecimalPlaces={[0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2]}
                                        dateFormat={'long_date'}
                                        timeFormat={'h12'}
                                        headers={[this.Language.DataTableHeader.BusinessYear, this.Language.DataTableHeader.Exporter, this.Language.DataTableHeader.ExporterCategory, this.Language.DataTableHeader.ExportGrowthScore, this.Language.DataTableHeader.InvestmentGrowthScore, this.Language.DataTableHeader.LocalContentScore, this.Language.DataTableHeader.ValueAdditionScore, this.Language.DataTableHeader.EmploymentScore, this.Language.DataTableHeader.PrioritySectorScore, this.Language.DataTableHeader.TotalScore, this.Language.DataTableHeader.EEGRate]} 
                                        data={this.state.EEGRateItems} 
                                        sortType={"ascending"} sortPosition={1} thStyle={[100, 221, 100, 100, 100, 100, 100, 75, 74, 51, 64]}
                                        link={"/baseline/rates/"}  return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={false} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.EEGRates} emptyMessage={this.Language.EmptyMessage.EEGRates}
                                        downloadFields={['business_year', 'exporter_name', 'exporter_category', 'export_growth_score', 'investment_growth_score', 'local_content_score', 'value_addition_score', 'employment_score', 'priority_sector_score', 'total_score', 'eeg_rate']}/> 
                                        })

                    this.setState({preloader: null});
                })

                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })

                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  

                    if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                  

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });

    }



    render(){

        let exporterId = this.props.match.params.id;

         return ( 
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Baseline Data"} active_menu_level_2={"EEG Rates Profile"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.ExporterEEGRatesProfile}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.BaselineDataProfile, this.Language.BreadCrumbLabel.EEGRates]} links={[`/`, `/exporter/${exporterId}/baseline/profile`, `/exporter/${exporterId}/baseline/profile/rates`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <br/>
                                {this.state.preloader   &&  <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}
                                    {this.state.EEGRateDataItems}
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                

                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]
            
        )
    }
}

export default EEGRatesProfile;