import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import axios from 'axios';



class ECCVerification extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getECCs = this.getECCs.bind(this);
        this.state = {
            eccs: null,
            result: '',
            message: '',
            token: '',
            preloader: null,
            eccDataItems: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();


    }

    componentDidMount() {
        document.title = this.Language.PageTitle.ECCVerification + ' - ' + this.Language.EEGWebPortal;
        // this.getECCs();


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:ecc_verification', 'action:ecc_verification'], 'view') === false) {
            this.props.history.replace(default_home);
        } else {
            this.getProfile();
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ scopes: user_profile.scopes });
        }

    }


    onSubmit(event) {

        event.preventDefault();

        let searchValue = this.refs.search_value.value;

        if (searchValue !== '' && searchValue !== null && typeof searchValue !== 'undefined') {

            searchValue = searchValue.split(" ");
            searchValue = searchValue.join('+'); // prepare the search query 

            this.setState({ preloader: true });
            this.setState({ eccDataItems: null }, () => {
                this.getECCs(searchValue);
            })

        }


    }


    getECCs(searchValue) {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/eccs/verification?search=${searchValue}&tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({ eccs: response.data.data }, () => {

                    this.setState({
                        eccDataItems: <DataTable tableName={this.Language.DataTableHeader.SearchResultsFor + ': "' + searchValue + "\""}
                            fields={['certificate_number', 'exporter_name', 'nxp_number', 'export_year', 'is_utilised', 'certificate_value', 'value_utilised', 'utilising_agency', 'transfered_to', 'transfer_approved']}
                            fieldDataTypes={['String', 'String', 'String', 'Date-Year', 'Boolean', 'Number', 'Number', 'String', 'String', 'Boolean']}
                            fieldDecimalPlaces={[0, 0, 0, 0, 0, 2, 2, 0, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.CertificateNumber, this.Language.DataTableHeader.Exporter, this.Language.DataTableHeader.NXPNumber, this.Language.DataTableHeader.ExportYear, this.Language.DataTableHeader.Utilised, this.Language.DataTableHeader.Value, this.Language.DataTableHeader.ValueUtilised, this.Language.DataTableHeader.UtilisingAgency, this.Language.DataTableHeader.TransferedTo, this.Language.DataTableHeader.TransferApproved]}
                            data={this.state.eccs}
                            sortType={"ascending"} sortPosition={1} thStyle={[51, 121, 164, 100, 100, 100, 145, 130, 100, 74]}
                            link={"/exporters/ecc/verification/"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={false} download={false} linkToBlankTarget={false} linkToBlankTargetToken={''} downloadFileName={this.Language.DownloadFileName.ECCs} emptyMessage={this.Language.EmptyMessage.ECCSearch}
                            downloadFields={['certificate_number', 'date_issued', 'exporter_name', 'nxp_number', 'export_year', 'certificate_value', 'value_utilised', 'balance_value', 'is_utilised', 'utilising_agency', 'is_transfered', 'transfer_approved', 'is_approved', 'transfered_to']} />
                    })

                    this.setState({ preloader: null });
                })

                this.setState({ result: response.data.status }, () => {
                })

                this.setState({ message: response.data.message }, () => {
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            });

    }



    render() {

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"ECCs"} active_menu_level_2={"ECC Verification"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.ECCVerification}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ECCVerification]} links={[`/`, `/exporters/ecc/verification`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">
                                        <div className="card-panel">

                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="search_value" type="text" ref="search_value" />
                                                            <label htmlFor="search_value" className="">
                                                                {this.Language.DataField.ECCSearch}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="search_for_ecc">
                                                                {this.Language.ButtonLabel.Search}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.eccDataItems}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />






                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default ECCVerification;