import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

import ReactDOM from 'react-dom';


class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            user: {
                username: '',
                password: ''
            },
            language: 'English',
            languages: ['English', 'Français', '中文'],
            status: '',
            auth: '',
            change_password: '',
            validation_error: '',
            token: null,
            redirect: null,
            message: '',
            preloader: null
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.passwordReset = this.passwordReset.bind(this);
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
    }


    componentDidMount() {
        document.title = this.Language.PageLabel.ForgotPassword + ' - ' + this.Language.EEGWebPortal;

        this.setState({
            language: this.Language.Language,
            languages: LanguageService.prototype.getLanguageList()
        });


        window.$('select').material_select();
        window.$(ReactDOM.findDOMNode(this.refs.language)).on('change', this.changeLanguage.bind(this));

    }


    changeLanguage(event) {

        event.preventDefault();

        let lang = this.refs.language.value;

        if (this.state.language !== lang) {
            this.setState({ language: lang });

            LanguageService.prototype.setTranslation(lang);
            window.location.reload();
        }

    }



    passwordReset(newResetRequest) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/user/password/forgot`,
            data: newResetRequest
        }).then(response => {
            this.setState({ status: response.data.status }, () => {
                
            })

            this.setState({ token: response.data.token }, () => {
                
                // let payload = JSON.parse(atob(this.state.token.split('.')[1]));
                
                AuthService.authenticateUser(this.state.token); // Save the token in session storage
            })



            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.state.message, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("reset_password_button").removeAttribute("disabled");

            let redirect = response.data.redirect;

            if (redirect !== null) {
                this.props.history.replace(redirect);
            } else {
                this.props.history.replace('/signin');
            };


        })
            .catch(error => {
                if (error.response) {

                    


                    window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("reset_password_button").removeAttribute("disabled");
                document.getElementById("username").removeAttribute("disabled");
                document.getElementById("signin_link").hidden = false;

            });
    }



    onSubmit(event) {
        // prevent default action. in this case, action is the form submission event

        event.preventDefault();

        let username = this.refs.username.value + '';

        const userOS = this.Browser.getOS();

        if (username.length > 0) {
            const newResetRequest = {
                username: this.refs.username.value,
                device_name: this.state.logged_in_username + '\'s ' + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: "web"
            }


            this.setState({ preloader: true });
            document.getElementById("reset_password_button").setAttribute("disabled", true);
            document.getElementById("username").setAttribute("disabled", true);
            document.getElementById("signin_link").hidden = true;
            this.passwordReset(newResetRequest);

        } else {

            window.Materialize.toast(`${this.Language.ConfirmMessage.ResetPasswordMessage}`, 4000, 'rounded deep-orange darken-4');
        };


    }



    /**
     * Render the component.
     */
    render() {

        const divStyle = {
            position: 'relative',
            top: '50%',
            WebkitTransform: 'translateY(50%)',
            msTransform: 'translateY(50%)',
            transform: 'translateY(25%)'
        };


        const select_field_values_language = this.state.languages.map((language, i) => {
            if (language !== this.state.language) {
                return (
                    <option value={language} key={i}>{language}</option>
                )
            } else {
                return (
                    null
                )
            }
        })


        return (

            <div div="login-page" className="row">
                <div className="col s4 m3 l4">
                </div>
                <div className="vertical-form sign-in col s12 m7 l4 z-depth-4 card-panel" style={divStyle} >

                    {this.state.preloader && <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                    </div>}

                    {/* <form className="login-form" onSubmit={this.onSubmit.bind(this)}> */}

                    <form className="" onSubmit={this.onSubmit.bind(this)}>

                        <div className="row">
                            <div className="input-field col s12 center">
                                <img src="/assets/images/logo/nepc_logo_small.png" alt="" className="responsive-img valign" />
                                <div className="card-content green-text darken-4">
                                    <span className="card-title center"><h5>{this.Language.PageLabel.EEGWebPortal}<br />{this.Language.PageLabel.ForgotPassword}</h5></span>
                                    <p className="center">{this.Language.PageLabel.EnterYourUsernameToResetYourPassword}</p>
                                </div>
                            </div>

                        </div>
                        <br />
                        <div className="container">
                            <div className="field-line">
                                <div className="input-field col s12">
                                    <input id="username" type="text" className="validate" ref="username" />
                                    <label htmlFor="username">{this.Language.DataField.Username}</label>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row col s12">
                                    <input type="submit" value={this.Language.ButtonLabel.SignIn} className="waves-effect waves-light col s12 btn right green" id="reset_password_button" />
                                </div>
                            </div>



                            <div className="row">
                                <div className="col s8">
                                    <p id="signin_link"><Link to={"/signin"}>{this.Language.DataField.Signin}</Link></p>
                                </div>
                                <div className="col s4">
                                    <div className="select-wrapper initialized">
                                        <span className="caret">▼</span>
                                        <select data-select-id="select-options-language" className="initialized teal-text" ref="language">
                                            <option value={this.state.language}>{this.state.language}</option>
                                            {select_field_values_language}
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </form>

                    <br />
                    <br />

                </div>
                <div className="col s4 m2 l4">
                </div>
            </div>

        );
    }

}

export default ForgotPassword;