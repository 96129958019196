import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');


class EEGClaimApplications extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getEEGClaims = this.getEEGClaims.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            eegClaims: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 500,
            paginationItem: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            eegClaimsDataItems: null,
            hash_organisation: '',
            eeg_claim_create: null,
            eeg_claim_edit: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {


    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EEGClaimApplications + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

        this.getEEGClaims(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })



    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['read:claim', 'action:claim'], 'view') === false) || this.state.hash_organisation !== this.props.match.params.id) {
            this.props.history.replace(default_home);
        } else {
            this.getAccessScopes();
        }

    }



    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null) {
            this.setState({
                hash_organisation: user_profile.organisation,
                scopes: user_profile.scopes
            }, () => {
                this.checkAccessRight();
            });
        }

    }



    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:claim' || this.state.scopes['views'][i] === 'action:claim') {
                    this.setState({ eeg_claim_create: true });
                }
                if (this.state.scopes['views'][i] === 'update:claim' || this.state.scopes['views'][i] === 'action:claim') {
                    this.setState({ eeg_claim_edit: true });
                }
            }
        };


    }



    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            eegClaimsDataItems: null,
            paginationItem: null,
            eegClaims: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getEEGClaims(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }




    getEEGClaims(currentNumberOfRecords) {

        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/eeg/claims/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ eegClaims: response.data.data }, () => {

                    this.setState({
                        eegClaimsDataItems: <DataTable tableName={''}
                            fields={['nxp_number', 'export_year', 'destination_country', 'export_currency', 'export_value', 'repatriation_currency', 'amount_repatriated', 'eeg_rate', 'eeg_payable', 'is_submitted', 'is_approved']}
                            fieldDataTypes={['String', 'Date-Year', 'String', 'Symbol', 'Number', 'Symbol', 'Number', 'Number', 'Number', 'Boolean', 'Boolean']}
                            fieldDecimalPlaces={[0, 0, 0, 0, 2, 0, 2, 2, 2, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.NXPNumber, this.Language.DataTableHeader.ExportYear, this.Language.DataTableHeader.DestinationCountry, this.Language.DataTableHeader.ExportCurrency, this.Language.DataTableHeader.ExportValue, this.Language.DataTableHeader.RepatriationCurrency, this.Language.DataTableHeader.AmountRepatriated, this.Language.DataTableHeader.EEGRate, this.Language.DataTableHeader.EEGPayable, this.Language.DataTableHeader.Submitted, this.Language.DataTableHeader.Approved]}
                            data={this.state.eegClaims}
                            sortType={"ascending"} sortPosition={1} thStyle={[51, 121, 164, 100, 100, 145, 130, 50, 100, 74, 50]}
                            link={`/exporter/${this.state.hash_organisation}/eeg/claim/application/`} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={this.state.eeg_claim_edit} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.EEGClaims} emptyMessage={this.Language.EmptyMessage.EEGClaims}
                            downloadFields={['nxp_number_id', 'new_exporter_name', 'nxp_number', 'originating_nxp_bank', 'nxp_bank_address', 'export_date', 'export_year', 'claims_application_date', 'industry', 'customer_name', 'customer_address', 'destination_city', 'destination_country', 'export_point', 'product_category', 'nxp_quantity', 'measurement_unit', 'export_value', 'export_currency', 'export_exchange_rate', 'naira_export_value', 'eeg_rate', 'eeg_payable', 'commercial_invoice_date', 'date_on_bill_of_lading', 'invoice_quantity', 'sgd_date', 'waybill_date', 'custom_release_date', 'cbn_confirmation_date', 'repatriation_date', 'amount_repatriated', 'freight', 'repatriation_currency', 'exchange_rate', 'cross_rate', 'naira_equivalent', 'exporter_bank_letter_date', 'exporter_bank_amount_repatriated', 'exporter_bank_repatriation_currency', 'delivery_mode', 'has_clean_certificate_of_inspection', 'cci_number', 'cci_date', 'has_non_oil_export_certificate', 'general_comments', 'is_called_over', 'claim_application_received', 'claim_application_approved', 'claim_data_captured', 'cbn_confirmed', 'is_delisted', 'delist_reason', 'enlist_reason', 'validated_ok_for_approval', 'validated_not_ok_for_approval', 'claim_cleared', 'claim_approved_for_payment', 'claim_approved_for_issuance', 'report_claim_status', 'is_paid', 'cbn_confirmation', 'status', 'is_approved', 'approved_by', 'approved_at', 'is_submitted']} />
                    })

                

                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });
                })

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    render() {

        let exporterId = this.props.match.params.id;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"EEG Claims"} active_menu_level_2={"Claim Applications"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EEGClaimApplications}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.EEGClaims]} links={[`/`, `/exporter/${exporterId}/eeg/claim/applications`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                {(this.state.eeg_claim_create) && <Link className="waves-effect waves-light btn right" to={`/exporter/${this.state.hash_organisation}/eeg/claim/applications/add`}><i className="material-icons left">library_add</i>{this.Language.ButtonLabel.AddEEGClaim}</Link>}
                                <br />
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.eegClaimsDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default EEGClaimApplications;