import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


import ReactDOM from 'react-dom';


const $ = require('jquery');



class AddExchangeRate extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addExchangeRate = this.addExchangeRate.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            exchange_rate_name: '/',
            message: '',
            status: '',
            pick_list_values: [],
            select_field_values_currency: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.AddExchangeRate + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

        const monthsFull = this.Language.DatePicker.monthsFull.split(",");
        const monthsShort = this.Language.DatePicker.monthsShort.split(",");
        const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
        const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
        const today = this.Language.DatePicker.today;
        const clear = this.Language.DatePicker.clear;
        const close = this.Language.DatePicker.close;
        const labelMonthNext = this.Language.DatePicker.labelMonthNext;
        const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
        const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
        const labelYearSelect = this.Language.DatePicker.labelYearSelect;


        window.$('.datepicker').pickadate({
            monthsFull: monthsFull,
            monthsShort: monthsShort,
            weekdaysFull: weekdaysFull,
            weekdaysShort: weekdaysShort,
            today: today,
            clear: clear,
            close: close,
            labelMonthNext: labelMonthNext,
            labelMonthPrev: labelMonthPrev,
            labelMonthSelect: labelMonthSelect,
            labelYearSelect: labelYearSelect
            // firstDay: 1,
            // format: 'dd mmmm yyyy',
            // formatSubmit: 'yyyy/mm/dd',
            // selectMonths: true,
            // selectYears: true,
            // selectMonths: true, // Creates a dropdown to control month
            // selectYears: 15, // Creates a dropdown of 15 years to control year,
            // closeOnSelect: false, // Close upon selecting a date,
            // container: undefined, // ex. 'body' will append picker to body
        });

        window.$(ReactDOM.findDOMNode(this.refs.base_currency_id)).on('change', this.changeCurrency.bind(this));
        window.$(ReactDOM.findDOMNode(this.refs.target_currency_id)).on('change', this.changeCurrency.bind(this));
    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if (this.Auth.checkAccessScope(['create:exchange_rates', 'action:exchange_rates'], 'view') === false) {
            this.props.history.replace('/tools/exchange/rates');
        } else {
            this.getDropdownValues("currency");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }




    prepareData(return_option) {


        let base_currency_id = this.refs.base_currency_id.value;
        if (base_currency_id === "Select Base Currency") base_currency_id = "";

        let target_currency_id = this.refs.target_currency_id.value;
        if (target_currency_id === "Select Target Currency") target_currency_id = "";

        let exchange_rate_name = base_currency_id + '/' + target_currency_id; // Exchange Rate Pair name


        let exchange_rate = this.refs.exchange_rate.value;

        let exchange_rate_date = this.DateService.convertToFullDate(this.refs.exchange_rate_date.value);
        let valid_from_date = this.DateService.convertToFullDate(this.refs.valid_from_date.value);
        let valid_to_date = this.DateService.convertToFullDate(this.refs.valid_to_date.value);

        let is_default_exchange_rate = this.refs.is_default_exchange_rate.checked;


        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;
        let last_updated_by = this.state.logged_in_username;


        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.ExchangeRates.AddErrorTitle, [exchange_rate_name, base_currency_id, target_currency_id, exchange_rate, exchange_rate, exchange_rate_date, valid_from_date, valid_to_date], [this.Language.DataField.ExchangeRateName, this.Language.DataField.BaseCurrency, this.Language.DataField.TargetCurrency, this.Language.DataField.ExchangeRate, this.Language.DataField.ExchangeRate, this.Language.DataField.ExchangeRateDate, this.Language.DataField.ValidFromDate, this.Language.DataField.ValidToDate], ["isRequired", "isRequired", "isRequired", "isRequired", "isNumber", "isRequiredDate", "isRequiredDate", "isRequiredDate"], 0, 0) }, () => {

            if (this.state.error_messages === null) {

                if (base_currency_id === "Select Base Currency") base_currency_id = null;
                if (target_currency_id === "Select Target Currency") target_currency_id = null;


                const userOS = this.Browser.getOS();

                //make call to database 
                const newExchangeRate = {
                    exchange_rate_name: exchange_rate_name,
                    base_currency_id: base_currency_id,
                    exchange_rate: Math.abs(exchange_rate) || null,
                    target_currency_id: target_currency_id,
                    exchange_rate_date: exchange_rate_date,
                    valid_from_date: valid_from_date,
                    valid_to_date: valid_to_date,
                    is_default_exchange_rate: is_default_exchange_rate,
                    status: status,
                    created_by: created_by,
                    last_updated_by: last_updated_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken()
                }


                this.setState({ preloader: true });
                document.getElementById("save_exchange_rate_button").setAttribute("disabled", true);


                this.addExchangeRate(newExchangeRate, return_option);


            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });



    }



    clearForm() {

        document.getElementById("add_exchange_rate_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {

                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_currency = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'currency') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_currency: select_field_values_currency });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    addExchangeRate(newExchangeRate, return_option) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/tools/exchange/rates`,
            data: newExchangeRate
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.AddSuccess + newExchangeRate.exchange_rate_name + ', ' + newExchangeRate.exchange_rate_date, 8000, 'rounded green');

            })


            this.setState({ preloader: null });
            document.getElementById("save_exchange_rate_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/tools/exchange/rates');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ExchangeRates.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("save_exchange_rate_button").removeAttribute("disabled");

            });

    }



    changeCurrency(event) {

        event.preventDefault();

        let base_currency_id = this.refs.base_currency_id.value;
        if (base_currency_id === "Select Base Currency") base_currency_id = "";

        let target_currency_id = this.refs.target_currency_id.value;
        if (target_currency_id === "Select Target Currency") target_currency_id = "";

        this.setState({ exchange_rate_name: base_currency_id + '/' + target_currency_id });

    }





    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Tools"} active_menu_level_2={"Exchange Rates"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.AddExchangeRate}`} breadcrumbs={[this.Language.BreadCrumbLabel.Tools, this.Language.BreadCrumbLabel.ExchangeRates, this.Language.BreadCrumbLabel.AddExchangeRate]} links={[`/`, `/tools/exchange/rates`, `/tools/exchange/rates/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.AddExchangeRate} - <b>{this.state.exchange_rate_name}</b></h4>
                                            <div className="row">
                                                <form className="col s12" id="add_exchange_rate_form" ref="add_exchange_rate_form" onSubmit={this.onSubmit.bind(this)}>



                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-base_currency_id" className="initialized" name="base_currency_id" id="base_currency_id" ref="base_currency_id">
                                                                    <option value="Select Base Currency" >{this.Language.DataFieldDefault.SelectBaseCurrency}</option>
                                                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                                                        return (
                                                                            currency_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.BaseCurrency} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-target_currency_id" className="initialized" name="target_currency_id" id="target_currency_id" ref="target_currency_id">
                                                                    <option value="Select Target Currency" >{this.Language.DataFieldDefault.SelectTargetCurrency}</option>
                                                                    {this.state.select_field_values_currency.map((currency_value, i) => {
                                                                        return (
                                                                            currency_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.TargetCurrency} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <input id="exchange_rate" type="number" step="any" className="validate" ref="exchange_rate" />
                                                            <label htmlFor="exchange_rate" data-error={this.Language.DataFieldError.DecimalNumber} className="">
                                                                {this.Language.DataField.ExchangeRate} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input type="text" className="datepicker picker__input" ref="exchange_rate_date" readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" />
                                                            <label htmlFor="exchange_rate_date">
                                                                {this.Language.DataField.ExchangeRateDate} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input type="text" className="datepicker picker__input" ref="valid_from_date" readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" />
                                                            <label htmlFor="valid_from_date">
                                                                {this.Language.DataField.ValidFromDate} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input type="text" className="datepicker picker__input" ref="valid_to_date" readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" />
                                                            <label htmlFor="valid_to_date">
                                                                {this.Language.DataField.ValidToDate} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <p>
                                                                <input type="checkbox" id="is_default_exchange_rate" ref="is_default_exchange_rate" />
                                                                <label htmlFor="is_default_exchange_rate">{this.Language.DataField.IsDefaultExchangeRate}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateExchangeRate}
                                                                    <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateExchangeRate}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveExchangeRate.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_exchange_rate_button">
                                                                {this.Language.ButtonLabel.SaveExchangeRate}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveExchangeRate.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/tools/exchange/rates`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar  language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddExchangeRate;