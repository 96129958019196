import React, { Component } from 'react';
// import { Bar, Bubble, Doughnut, HorizontalBar, Pie, Line, Polar, Radar, Scatter} from 'react-chartjs-2';
// import { Bar, Bubble, Doughnut, HorizontalBar, Pie, Line, Polar, Radar, Scatter, Chart, defaults } from 'react-chartjs-2';


import DataCard from './DataCard';
import DataChart from './DataChart'
import ValuesChart from './ValuesChart';
import ValuesChartVertical from './ValuesChartVertical';
import DataCardPicture from './DataCardPicture';
import DataCardCirclePicture from './DataCardCirclePicture';
import DataCardAnalytics from './DataCardAnalytics';
import DataChartAnalytics from './DataChartAnalytics';
import DataChartAnalyticsV2 from './DataChartAnalyticsV2';
import DataCollectionWithChart from './DataCollectionWithChart';
import TaskCollectionList from './TaskCollectionList';
import ProfileCard from './ProfileCard';
import MapCard from './MapCard';
import DataMapSectionCard from './DataMapSectionCard';


class DashboardLayoutDummy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardLayoutDummy: "4:DataCard__1:ValuesChart_1:ValuesChartVertical__3:DataChart",
            Lang: props.Lang,
            DetailsLabel: '',
            elements: [],
            chart: null,
        }
    }


    componentDidMount() {


    }

    render() {


        let dashboard_layout_level_display = [];
        let position_counter = 0;


        if (this.state.dashboardLayoutDummy !== '' && this.state.dashboardLayoutDummy !== null && typeof this.state.dashboardLayoutDummy !== 'undefined') {

            let layout_levels = this.state.dashboardLayoutDummy.split("__");
            let layout_len = layout_levels.length;


            for (let i = 0; i < layout_len; i++) {

                let layout_level_objects = layout_levels[i].split("_");
                let layout_level_len = layout_level_objects.length;
                let dashboard_layout_objects_display = [];

                for (let j = 0; j < layout_level_len; j++) {

                    let layout_object = layout_level_objects[j].split(":");
                    let object_repeat_count = parseInt(layout_object[0], 10);
                    let object_name = layout_object[1];

                    for (let k = 0; k < object_repeat_count; k++) {

                        // let object_position = k + 1;
                        // let dashboard_object_type = "";
                        let element_type = '';
                        let element_type2 = '';
                        // let element_data = null;
                        // let element_data2 = null;
                        // let object_element = null;
                        // let object_element2 = null;
                        // let object_element3 = null;

                        let element_data_table_header = null;
                        let element_data_table_rows = null;

                        let element_collection_values_list = null;


                        // set default values for the variables
                        let element_title = '';
                        let element_icon_name = 'language';
                        let element_total_value = '';
                        let element_sub_title = '';
                        let element_sub_category_title = '';
                        let element_lower_title = '';
                        let element_sub_category_value = '';
                        let element_card_colour = 'grey';
                        let element_hoverable = '';
                        let element_arrow_icon_direction = '';
                        let element_show_arrow_icon = '';
                        let element_title_trending_value = '';
                        let element_title_trending_value_prefix = '';
                        let element_title_trending_value_suffix = '';
                        let element_link = '#!';
                        let element_button_title = '';
                        let element_text_colour = 'purple';
                        let element_sub_title_text_colour = 'grey';
                        let element_main_value = '';
                        let element_main_value_prefix = '';
                        let element_main_value_suffix = '';
                        let element_sub_value = '';
                        let element_sub_value_prefix = '';
                        let element_sub_value_suffix = '';
                        let element_sub_value_text_colour = '';
                        let element_value_is_decimal = true;
                        let element_value_decimal_places = 2;
                        let element_sub_value_is_decimal = false;
                        let element_sub_value_decimal_places = 0;
                        let element_badge_colour = 'gradient-45deg-light-blue-cyan';
                        let element_badge_value = '';
                        let element_badge_value_prefix = '';
                        let element_badge_value_suffix = '';
                        let element_badge_status = 'new';
                        let element_src_image = '';
                        let element_src_image_alt = '';
                        let element_paragraph_text = '';
                        let element_button_value = '';
                        let element_button_value_prefix = '';
                        let element_button_value_suffix = '';
                        let element_button_colour = '';

                        let element_title2 = '';
                        let element_filter_colour = '';
                        let element_card_reveal_title = '';
                        let element_no_record_available = '';



                        // Use this object_position variable to link the chart, card, stream or collection object to the
                        // Dashboard layout position



                        switch (object_name) {
                            case 'DataCard':
                                element_icon_name = 'language';
                                element_title = '';
                                element_total_value = '';
                                element_value_is_decimal = false;
                                element_value_decimal_places = 0;
                                element_sub_category_title = '';
                                element_sub_category_value = '';
                                element_sub_value_is_decimal = false;
                                element_sub_value_decimal_places = 0;
                                element_card_colour = 'grey';
                                element_text_colour = 'white';
                                element_hoverable = true;

                                dashboard_layout_objects_display.push(<DataCard key={'object:' + j + ':' + k} iconName={element_icon_name} title={element_title} totalValue={element_total_value} valueIsDecimal={element_value_is_decimal} valueDecimalPlaces={element_value_decimal_places} Lang={this.state.Lang} subCategoryTitle={element_sub_category_title} subCategoryValue={element_sub_category_value} subValueIsDecimal={element_sub_value_is_decimal} subValueDecimalPlaces={element_sub_value_decimal_places} hoverable={element_hoverable} cardColour={element_card_colour} textColour={element_text_colour} />);
                                break;



                            case 'ValuesChart':

                                element_title = '';
                                element_arrow_icon_direction = 'up';
                                element_show_arrow_icon = false;
                                element_title_trending_value = '';
                                element_title_trending_value_prefix = '';
                                element_title_trending_value_suffix = '';
                                element_text_colour = 'grey';
                                element_link = '#!';


                                dashboard_layout_objects_display.push(<ValuesChart key={'object:' + j + ':' + k} title={element_title} link={element_link} showArrowIcon={element_show_arrow_icon} arrowIconDirection={element_arrow_icon_direction} titleTrendingValue={element_title_trending_value} buttonTitle={this.state.DetailsLabel} chartID={element_type + ':' + j + ':' + k} titleTrendingValuePrefix={element_title_trending_value_prefix} titleTrendingValueSuffix={element_title_trending_value_suffix} textColour={element_text_colour} />);
                                break;



                            case 'ValuesChartVertical':

                                element_icon_name = 'language';
                                element_title = '';
                                element_sub_title = '';
                                element_title_trending_value = '';
                                element_title_trending_value_prefix = '';
                                element_title_trending_value_suffix = '';
                                element_lower_title = ''
                                element_arrow_icon_direction = '';
                                element_show_arrow_icon = false;
                                element_button_title = '';
                                element_text_colour = 'grey';
                                element_sub_title_text_colour = 'grey';
                                element_link = '#!';


                                dashboard_layout_objects_display.push(<ValuesChartVertical key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} title={element_title} subTitle={element_sub_title} titleTrendingValue={element_title_trending_value} titleTrendingValuePrefix={element_title_trending_value_prefix} titleTrendingValueSuffix={element_title_trending_value_suffix} lowerTitle={element_lower_title} showArrowIcon={element_show_arrow_icon} arrowIconDirectio={element_arrow_icon_direction} buttonTitle={element_button_title} textColour={element_text_colour} subTitleTextColour={element_sub_title_text_colour} mainValueSuffix={element_main_value_suffix} subValuePrefix={element_sub_value_prefix} subValueSuffix={element_sub_value_suffix} badgeValuePrefix={element_badge_value_prefix} badgeValueSuffix={element_badge_value_suffix} badgeStatus={element_badge_status} link={element_link} />);
                                break;




                            case 'DataChart':

                                element_title = '';
                                element_main_value = '';
                                element_main_value_prefix = 0;
                                element_main_value_suffix = '';
                                element_sub_value = 0;
                                element_sub_value_prefix = '';
                                element_sub_value_suffix = '';
                                element_sub_value_text_colour = 'grey';
                                element_value_is_decimal = false;
                                element_value_decimal_places = 0;
                                element_sub_value_is_decimal = false;
                                element_sub_value_decimal_places = 0;
                                element_badge_colour = 'grey';
                                element_badge_value = '';
                                element_badge_value_prefix = '';
                                element_badge_value_suffix = '';
                                element_badge_status = '';


                                dashboard_layout_objects_display.push(<DataChart key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} title={element_title} Lang={this.state.Lang} valueIsDecimal={element_value_is_decimal} valueDecimalPlaces={element_value_decimal_places} badgeValue={element_badge_value} badgeValuePrefix={element_badge_value_prefix} badgeValueSuffix={element_badge_value_suffix} badgeColour={element_badge_colour} badgeStatus={element_badge_status} subValue={element_sub_value} subValueTextColour={element_sub_value_text_colour} mainValue={element_main_value} subValueIsDecimal={element_sub_value_is_decimal} subValueDecimalPlaces={element_sub_value_decimal_places} mainValuePrefix={element_main_value_prefix} />);
                                break;



                            case 'DataCardPicture':

                                element_title = '';

                                element_sub_title = '';
                                element_src_image = '';
                                element_src_image_alt = '';
                                element_paragraph_text = '';
                                element_button_value = '';
                                element_button_value_prefix = '';
                                element_button_value_suffix = '';
                                element_button_colour = '';

                                dashboard_layout_objects_display.push(<DataCardPicture key={'object:' + j + ':' + k} title={element_title} subTitle={element_sub_title} srcImage={element_src_image} srcImageAlt={element_src_image_alt} paragraphText={element_paragraph_text} buttonValue={element_button_value} buttonValuePrefix={element_button_value_prefix} buttonValueSuffix={element_button_value_suffix} buttonColour={element_button_colour} />);
                                break;



                            case 'DataCardCirclePicture':

                                element_title = '';
                                element_sub_title = '';
                                element_text_colour = '';
                                element_card_colour = 'grey';
                                element_src_image = '';
                                element_src_image_alt = '';


                                dashboard_layout_objects_display.push(<DataCardCirclePicture key={'object:' + j + ':' + k} title={element_title} subTitle={element_sub_title} cardColour={element_card_colour} textColour={element_text_colour} srcImage={element_src_image} srcImageAlt={element_src_image_alt} />);
                                break;



                            case 'DataCardAnalytics':

                                element_title = '';
                                element_sub_title = '';
                                element_icon_name = 'language';
                                element_text_colour = '';
                                element_card_colour = 'grey';
                                element_main_value = 0;
                                element_main_value_prefix = '';
                                element_main_value_suffix = '';
                                element_sub_value = 0;
                                element_sub_value_prefix = '';
                                element_sub_value_suffix = '';
                                element_arrow_icon_direction = '';
                                element_show_arrow_icon = '';


                                dashboard_layout_objects_display.push(<DataCardAnalytics key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} title={element_title} subTitle={element_sub_title} iconName={element_icon_name} mainValue={element_main_value} mainValuePrefix={element_main_value_prefix} mainValueSuffix={element_main_value_suffix} showArrowIcon={element_show_arrow_icon} arrowIconDirection={element_arrow_icon_direction} subValue={element_sub_value} subValuePrefix={element_sub_value_prefix} subValueSuffix={element_sub_value_suffix} cardColour={element_card_colour} textColour={element_text_colour} />);
                                break;



                            case 'DataChartAnalytics':

                                element_type2 = '';
                                let element_type3 = '';


                                element_data_table_header = [];
                                element_data_table_rows = [[]];

                                element_title = '';
                                element_main_value = 0;
                                element_main_value_prefix = '';
                                element_main_value_suffix = '';
                                let element_main_value2 = 0;
                                let element_main_value_prefix2 = '';
                                let element_main_value_suffix2 = '';
                                element_sub_value = 0;
                                element_sub_value_prefix = '';
                                element_sub_value_suffix = '';
                                let element_chart_text2 = '';
                                element_text_colour = '';
                                element_card_colour = 'grey';
                                element_filter_colour = '';
                                element_arrow_icon_direction = '';
                                element_show_arrow_icon = '';
                                let element_option1_text = '';
                                let element_option2_text = '';
                                element_card_reveal_title = '';


                                dashboard_layout_objects_display.push(<DataChartAnalytics key={'object:' + j + ':' + k} chartID1={element_type + ':' + j + ':' + k} chartID2={element_type2 + ':' + j + ':' + k} chartID3={element_type3 + ':' + j + ':' + k} title={element_title} dataTableHeader={element_data_table_header} dataTableRows={element_data_table_rows} mainValue={element_main_value} mainValuePrefix={element_main_value_prefix} mainValueSuffix={element_main_value_suffix} mainValue2={element_main_value2} mainValuePrefix2={element_main_value_prefix2} mainValueSuffix2={element_main_value_suffix2} subValue={element_sub_value} subValuePrefix={element_sub_value_prefix} subValueSuffix={element_sub_value_suffix} chartText2={element_chart_text2} cardColour={element_card_colour} textColour={element_text_colour} filterColour={element_filter_colour} showArrowIcon={element_show_arrow_icon} arrowIconDirection={element_arrow_icon_direction} Option1Text={element_option1_text} Option2Text={element_option2_text} cardRevealTitle={element_card_reveal_title} />);
                                break;



                            case 'DataChartAnalyticsV2':


                                element_type2 = '';

                                element_data_table_header = [];
                                element_data_table_rows = [[]];

                                element_title = '';
                                element_title2 = '';
                                element_text_colour = '';
                                element_card_colour = 'grey';
                                element_filter_colour = '';
                                element_card_reveal_title = '';


                                dashboard_layout_objects_display.push(<DataChartAnalyticsV2 key={'object:' + j + ':' + k} title={element_title} title2={element_title2} cardColour={element_card_colour} textColour={element_text_colour} filterColour={element_filter_colour} cardRevealTitle={element_card_reveal_title} dataTableHeader={element_data_table_header} dataTableRows={element_data_table_rows} />);

                                break;



                            case 'DataCollectionWithChart':

                                element_collection_values_list = [];

                                element_title = '';
                                element_icon_name = 'language';
                                let element_icon_colour = '';
                                element_sub_title = '';
                                element_no_record_available = '';

                                dashboard_layout_objects_display.push(<DataCollectionWithChart key={'object:' + j + ':' + k} collectionID={element_type + ':' + j + ':' + k} collectionValuesList={element_collection_values_list} title={element_title} iconName={element_icon_name} iconColour={element_icon_colour} subTitle={element_sub_title} textColour={element_text_colour} noRecordAvailable={element_no_record_available} />);
                                break;



                            case 'TaskCollectionList':

                                element_collection_values_list = [];

                                element_title = '';
                                element_sub_title = '';
                                element_no_record_available = '';
                                element_card_colour = 'grey';
                                element_text_colour = '';
                                let element_can_edit_check_boxes = false;

                                dashboard_layout_objects_display.push(<TaskCollectionList key={'object:' + j + ':' + k} collectionID={element_type + ':' + j + ':' + k} collectionValuesList={element_collection_values_list} title={element_title} subTitle={element_sub_title} cardColour={element_card_colour} textColour={element_text_colour} noRecordAvailable={element_no_record_available} canEditCheckBoxes={element_can_edit_check_boxes} />);
                                break;



                            case 'ProfileCard':


                                let element_profile_name = '';
                                let element_profile_title = '';
                                let element_profile_phone = '';
                                let element_profile_email = '';
                                element_icon_name = 'language';
                                let element_detail_title = '';

                                let element_date_of_registration = '';
                                let element_role = '';
                                let element_src_back_image = '';
                                let element_src_back_image_alt = '';
                                element_src_image = '';
                                element_src_image_alt = '';

                                let element_image_colour = '';
                                element_button_colour = '';


                                dashboard_layout_objects_display.push(<ProfileCard key={'object:' + j + ':' + k} profileID={element_type + ':' + j + ':' + k} profileName={element_profile_name} profileTitle={element_profile_title} profilePhone={element_profile_phone} profileEmail={element_profile_email} iconName={element_icon_name} detailTitle={element_detail_title} dateOfRegistration={element_date_of_registration} role={element_role} srcBackImage={element_src_back_image} srcBackImageAlt={element_src_back_image_alt} srcImage={element_src_image} srcImageAlt={element_src_image_alt} imageColour={element_image_colour} buttonColour={element_button_colour} />);
                                break;




                            case 'MapCard':

                                element_title = '';
                                element_sub_title = '';
                                let element_details_text = '';
                                let element_main_text_colour = '';
                                let element_details_text_colour = '';
                                element_button_colour = '';

                                let element_map_card_type = '';
                                let element_address = '';
                                let element_phone = '';
                                let element_email = '';

                                let element_latitude = '';
                                let element_longitude = '';


                                dashboard_layout_objects_display.push(<MapCard key={'object:' + j + ':' + k} buttonColour={element_button_colour} mainTextColour={element_main_text_colour} detailsTextColour={element_details_text_colour} title={element_title} subTitle={element_sub_title} detailsText={element_details_text} type={element_map_card_type} address={element_address} phone={element_phone} email={element_email} latitude={element_latitude} longitude={element_longitude} />);
                                break;



                            case 'DataMapSectionCard':

                                element_title = '';
                                element_sub_title = '';
                                let element_chart_title = '';
                                let element_chart_sub_title = '';
                                let element_map_height = '';
                                let element_map_width = '';
                                element_card_colour = 'grey';
                                element_text_colour = 'white';
                                element_sub_title_text_colour = 'grey';

                                dashboard_layout_objects_display.push(<DataMapSectionCard key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} title={element_title} subTitle={element_sub_title} chartTitle={element_chart_title} chartSubTitle={element_chart_sub_title} mapHeight={element_map_height} mapWidth={element_map_width} cardColour={element_card_colour} textColour={element_text_colour} subTextColour={element_sub_title_text_colour} />);
                                break;




                            case 'Picture':
                                break;

                            case 'SocialStream':
                                break;

                            case 'NotificationStream':
                                break;

                            case 'MessageStream':
                                break;

                            case 'TaskStream':
                                break;

                            case 'EventStream':
                                break;

                            case 'CustomData':
                                break;

                            default:

                        }


                        position_counter = position_counter + 1;

                    }

                }

                dashboard_layout_level_display.push(<div key={'level:' + i} className="row">{dashboard_layout_objects_display}</div>);

            }





            return (
                // <!--Dashboard Layout start-->
                <div className="">
                    {dashboard_layout_level_display.map((layout_value, i) => {
                        return (
                            layout_value
                        )
                    })}
                </div>
                // <!--Dashboard Layout end-->
            )

        } else {

            return (
                // <!--Dashboard Layout start-->
                null
                // <!--Dashboard Layout end-->
            )

        }


    }
}

export default DashboardLayoutDummy;