import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


class AccessRights extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getAccessRights = this.getAccessRights.bind(this);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.toggleSaveButton = this.toggleSaveButton.bind(this);
        this.saveAccessRight = this.saveAccessRight.bind(this);
        this.deleteAccessRight = this.deleteAccessRight.bind(this);
        this.state = {
            accessRights: [],
            result: '',
            message: '',
            access_right_create: null,
            access_right_edit: null,
            access_right_delete: null,
            access_right_delete_for_list: null,
            preloader: true,
            logged_in_username: '',
            scopes: {}
        }
        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();
    }

    componentDidMount() {
        document.title = this.Language.PageTitle.AccessRights + ' - ' + this.Language.EEGWebPortal;
        this.getAccessScopes();
        this.getAccessRights();
    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['read:access_right', 'action:access_right'], 'view') === false)) {
            this.props.history.replace(default_home);
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username
            });
        }

    }



    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:access_right' || this.state.scopes['views'][i] === 'action:access_right') this.setState({ access_right_create: true });
                if (this.state.scopes['views'][i] === 'update:access_right' || this.state.scopes['views'][i] === 'action:access_right') this.setState({ access_right_edit: true });
                if (this.state.scopes['views'][i] === 'delete:access_right' || this.state.scopes['views'][i] === 'action:access_right') this.setState({ access_right_delete: true });
            }
        };

    }




    getAccessRights() {

        axios.get(`${this.apiURL}/api/v1/settings/access/rights?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({ accessRights: response.data.data }, () => {


                    this.setState({ preloader: null });

                })

                this.setState({ result: response.data.status }, () => {

                })

                this.setState({ message: response.data.message }, () => {

                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx



                    window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error

                }

            });

    }


    saveAccessRight(updateAccessRight) {

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/settings/access/rights/${updateAccessRight.hash_id}`,
            data: updateAccessRight
        }).then(response => {

            this.setState({ result: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.AccessRight.UpdateSuccess + updateAccessRight.access_right_title, 8000, 'rounded green');

            })


            this.setState({ preloader: null });


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.AccessRight.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.AccessRight.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {

                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }


    deleteAccessRight(deleteAccessRight) {

        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/settings/access/rights/${deleteAccessRight.hash_id}`,
            data: deleteAccessRight
        }).then(response => {

            this.setState({ result: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.AccessRight.DeleteSuccess + deleteAccessRight.access_right_title, 8000, 'rounded green');

            })


            this.setState({ preloader: null });


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.AccessRight.DeleteError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.AccessRight.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {

                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }


    handleCheckBoxClick(position, can_value) {


        if (this.state.access_right_edit === true) {


            let temp_array_of_objects = this.state.accessRights.concat();

            if (can_value === 'can_view') temp_array_of_objects[position].can_view = !this.state.accessRights[position].can_view;
            if (can_value === 'can_create') temp_array_of_objects[position].can_create = !this.state.accessRights[position].can_create;
            if (can_value === 'can_edit') temp_array_of_objects[position].can_edit = !this.state.accessRights[position].can_edit;
            if (can_value === 'can_delete') temp_array_of_objects[position].can_delete = !this.state.accessRights[position].can_delete;
            if (can_value === 'can_take_action') temp_array_of_objects[position].can_take_action = !this.state.accessRights[position].can_take_action;

            this.setState({ accessRights: temp_array_of_objects });

            this.toggleSaveButton(position, 'allow_save');

        }




    }


    handleSwitchChange(position, switch_value) {

        if (this.state.access_right_edit === true) {


            let temp_array_of_objects = this.state.accessRights.concat();


            if (switch_value === 'activate_switch' && this.state.accessRights[position].status === 'Active') {
                temp_array_of_objects[position].status = 'Inactive';
            } else {
                temp_array_of_objects[position].status = 'Active';
            }

            this.setState({ accessRights: temp_array_of_objects });

            this.toggleSaveButton(position, 'allow_save');

        }

    }




    toggleSaveButton(position, option) {

        if (this.state.access_right_edit === true) {


            let temp_array_of_objects = this.state.accessRights.concat();

            if (this.state.accessRights[position].change_status === false && option === 'allow_save') {
                temp_array_of_objects[position].change_status = true;
            }

            if (this.state.accessRights[position].change_status === true && option === 'save_action') {
                // make a call to the save API 

                const userOS = this.Browser.getOS();


                const updateAccessRight = {
                    role_id: this.state.accessRights[position].role_id,
                    system_access_right_object_id: this.state.accessRights[position].system_access_right_object_id,
                    access_right_code: this.state.accessRights[position].access_right_code,
                    access_right_title: this.state.accessRights[position].access_right_title,
                    can_edit: this.state.accessRights[position].can_edit,
                    can_view: this.state.accessRights[position].can_view,
                    can_delete: this.state.accessRights[position].can_delete,
                    can_create: this.state.accessRights[position].can_create,
                    can_take_action: this.state.accessRights[position].can_take_action,
                    status: this.state.accessRights[position].status,
                    last_updated_by: this.state.logged_in_username,
                    hash_id: this.state.accessRights[position].hash_id,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }


                this.saveAccessRight(updateAccessRight);

                temp_array_of_objects[position].change_status = false;
            }


            if (this.state.accessRights[position].change_status === true && option === 'cancel') {
                temp_array_of_objects[position].change_status = false;
            }


            if (this.state.access_right_delete_for_list === null && option === 'allow_delete') {

                this.setState({ access_right_delete_for_list: true });
            }


            if (this.state.access_right_delete_for_list === true && option === 'delete_action') {

                const userOS = this.Browser.getOS();

                const deleteAccessRight = {
                    role_id: this.state.accessRights[position].role_id,
                    access_right_title: this.state.accessRights[position].access_right_title,
                    last_updated_by: this.state.logged_in_username,
                    hash_id: this.state.accessRights[position].hash_id,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }


                this.deleteAccessRight(deleteAccessRight);

                this.setState({ access_right_delete_for_list: null });

                temp_array_of_objects = temp_array_of_objects.filter((value) => value !== this.state.accessRights[position]);
            }


            if (this.state.access_right_delete_for_list === true && option === 'cancel_delete') {
                this.setState({ access_right_delete_for_list: null });
            }

            this.setState({ accessRights: temp_array_of_objects });

        }


    }



    render() {


        let accessRightCollection = []; // array for the final array 
        let accessRightCollectionObject = {}; // object to hold the values before transfering to the final array
        let len = this.state.accessRights.length;

        let li_element_temp = null;
        let ul_element_temp = null;

        if (len > 0) {

            let access_right_position = 0; // counter for the access right position

            for (let i = 0; i < len; i++) { // go through the array and identify the roles that need to be headers in the view

                if (i === 0) { // create the collection header for the first role

                    access_right_position = 0; // reset the counter for the access right position

                    li_element_temp = <li className="collection-item avatar grey lighten-4" key={i}>
                        <div className="col s5">
                            <i className="material-icons cyan circle">assignment_ind</i>
                            <h6 className="collection-header m-0">{this.Language.PageLabel.Role}: {this.state.accessRights[i].role_title}</h6>
                            <p>{this.state.accessRights[i].role_type}</p>
                        </div>
                        <div className="col s7">
                            {(this.state.access_right_create) && <Link to={`/settings/access/right/add`} className="btn-flat waves-effect">
                                <i className="material-icons left">add_box</i>{this.Language.ButtonLabel.Add}
                            </Link>}
                            {(this.state.access_right_delete) && <a href={"#!"} className="btn-flat waves-effect" onClick={() => { this.toggleSaveButton(i, 'allow_delete') }}>
                                <i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}
                            </a>}
                        </div>
                    </li>

                    accessRightCollectionObject[this.state.accessRights[i].role_title] = [];
                    accessRightCollectionObject[this.state.accessRights[i].role_title].push(li_element_temp);

                }


                // create the collection header for the subsequent roles
                if (i !== 0 && this.state.accessRights[i].role_title !== this.state.accessRights[i - 1].role_title) {

                    access_right_position = 0; // reset the counter for the access right position

                    li_element_temp = <li className="collection-item avatar grey lighten-4" key={i}>
                        <div className="row">
                            <div className="col s5">
                                <i className="material-icons cyan circle">assignment_ind</i>
                                <h6 className="collection-header m-0">{this.Language.PageLabel.Role}: {this.state.accessRights[i].role_title}</h6>
                                <p>{this.state.accessRights[i].role_type}</p>
                            </div>
                            <div className="col s7">
                                {(this.state.access_right_create) && <Link to={`/settings/access/right/add`} className="btn-flat waves-effect">
                                    <i className="material-icons left">add_box</i>{this.Language.ButtonLabel.Add}
                                </Link>}
                                {(this.state.access_right_delete) && <a href={"#!"} className="btn-flat waves-effect" onClick={() => { this.toggleSaveButton(i, 'allow_delete') }}>
                                    <i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}
                                </a>}
                            </div>
                        </div>
                    </li>

                    // Conclude the Collection for the previous role and then add to the final array for display
                    ul_element_temp = <ul id="projects-collection" className="collection z-depth-1" key={i - 1}>
                        {accessRightCollectionObject[this.state.accessRights[i - 1].role_title]}
                    </ul>

                    accessRightCollection.push(ul_element_temp); // push the object into the final array for display
                    accessRightCollection.push(<br key={"br" + (i - 1)} />); // push the breakline into the final array for display

                    accessRightCollectionObject[this.state.accessRights[i].role_title] = [];
                    accessRightCollectionObject[this.state.accessRights[i].role_title].push(li_element_temp);
                }



                // create the collection items that will fall under the role header
                for (let j = 0; j < len; j++) {

                    if (this.state.accessRights[j].role_title === this.state.accessRights[i].role_title && j === i) {

                        access_right_position = access_right_position + 1;

                        li_element_temp = <li className="collection-item" key={i + 1}>
                            <div className="row">
                                <div className="col s6">
                                    <p className="collections-title">{access_right_position}. <b>{this.state.accessRights[j].system_object_name}</b></p>
                                    <p className="collections-content">{this.Language.PageLabel.Module}: {this.state.accessRights[j].module_name}</p>
                                </div>
                                <div className="col s3">
                                    <span className="task-cat teal accent-4">{this.Language.PageLabel.Role} - {this.state.accessRights[j].role_title}</span>
                                </div>
                                <div className="col s3">
                                    <span className="task-cat cyan">{this.state.accessRights[j].access_right_title}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s3">
                                    <p>
                                        <input type="checkbox" id={`can_view_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} ref={`can_view_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} checked={this.state.accessRights[j].can_view} readOnly={this.state.access_right_edit} onClick={() => { this.handleCheckBoxClick(j, 'can_view') }} />
                                        <label htmlFor={`can_view_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`}>{this.Language.DataField.CanView}</label>
                                    </p>
                                </div>
                                <div className="col s3">
                                    <p>
                                        <input type="checkbox" id={`can_create_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} ref={`can_create_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} checked={this.state.accessRights[j].can_create} readOnly={this.state.access_right_edit} onClick={() => { this.handleCheckBoxClick(j, 'can_create') }} />
                                        <label htmlFor={`can_create_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`}>{this.Language.DataField.CanCreate}</label>
                                    </p>
                                </div>
                                <div className="col s3">
                                    <p>
                                        <input type="checkbox" id={`can_edit_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} ref={`can_edit_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} checked={this.state.accessRights[j].can_edit} readOnly={this.state.access_right_edit} onClick={() => { this.handleCheckBoxClick(j, 'can_edit') }} />
                                        <label htmlFor={`can_edit_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`}>{this.Language.DataField.CanEdit}</label>
                                    </p>
                                </div>
                                <div className="col s3">
                                    <p>
                                        <input type="checkbox" id={`can_delete_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} ref={`can_delete_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} checked={this.state.accessRights[j].can_delete} readOnly={this.state.access_right_edit} onClick={() => { this.handleCheckBoxClick(j, 'can_delete') }} />
                                        <label htmlFor={`can_delete_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`}>{this.Language.DataField.CanDelete}</label>
                                    </p>
                                </div>
                                <div className="col s4">
                                    <p>
                                        <input type="checkbox" id={`can_take_action_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} ref={`can_take_action_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} checked={this.state.accessRights[j].can_take_action} readOnly={this.state.access_right_edit} onClick={() => { this.handleCheckBoxClick(j, 'can_take_action') }} />
                                        <label htmlFor={`can_take_action_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`}>{this.Language.DataField.CanTakeAllActions}</label>
                                    </p>
                                </div>
                                {this.state.accessRights[j].change_status && <div className="col s4">
                                    <Link to={`#!`} onClick={() => { this.toggleSaveButton(j, 'cancel') }}><span className="new badge grey gradient-shadow" data-badge-caption={this.Language.ButtonLabel.Cancel}></span></Link>
                                    <Link to={`#!`} onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.SaveAccessRight} ${this.state.accessRights[j].system_object_name}, ${this.Language.PageLabel.Role}: ${this.state.accessRights[j].role_title}`)) { this.toggleSaveButton(j, 'save_action') } }}><span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.SaveChanges}></span></Link>
                                </div>}
                                {this.state.access_right_delete_for_list && <div className="col s4">
                                    <Link to={`#!`} onClick={() => { this.toggleSaveButton(j, 'cancel_delete') }}><span className="new badge grey gradient-shadow" data-badge-caption={this.Language.ButtonLabel.Cancel}></span></Link>
                                    <Link to={`#!`} onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteAccessRight} ${this.state.accessRights[j].system_object_name}, ${this.Language.PageLabel.Role}: ${this.state.accessRights[j].role_title}`)) { this.toggleSaveButton(j, 'delete_action') } }}><span className="new badge grey gradient-shadow" data-badge-caption={this.Language.ButtonLabel.Delete}></span></Link>
                                </div>}
                                <div className="col s4 right">
                                    <div className="switch">
                                        <label className="font-weight-600 black-text">
                                            {this.Language.DataField.Inactive}
                                            <input type="checkbox" name={`activate_switch_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} id={`activate_switch_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} ref={`activate_switch_${this.state.accessRights[j].system_object_name}_${this.state.accessRights[j].role_title}_${j}`} checked={this.state.accessRights[j].status === 'Active' ? true : false} onChange={() => { this.handleSwitchChange(j, 'activate_switch') }} />
                                            <span className="lever"></span>
                                            {this.Language.DataField.Active}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </li>

                        accessRightCollectionObject[this.state.accessRights[i].role_title].push(li_element_temp);

                    }
                }



                // Push the last collection item into the final array
                if (i === (len - 1)) {

                    ul_element_temp = <ul id="projects-collection" className="collection z-depth-1" key={i}>
                        {accessRightCollectionObject[this.state.accessRights[i].role_title]}
                    </ul>

                    accessRightCollection.push(ul_element_temp);
                    accessRightCollection.push(<br key={"br" + i} />); // push the breakline into the final array for display

                }

            }

        }





        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"Access Rights"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.AccessRights}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.AccessRights]} links={[`/settings/modules`, `/settings/access/rights`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}

                                <div id="work-collections" className="section">
                                    <div className="row">
                                        <div className="col s12 m12 l7">
                                            {accessRightCollection}
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <br />
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar  language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AccessRights;