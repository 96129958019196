import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

// const $ = require('jquery');



class EditPasswordConfiguration extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updatePasswordConfiguration = this.updatePasswordConfiguration.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getPasswordConfiguration = this.getPasswordConfiguration.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            passwordConfigurationDetails: [],
            limit_pasword_length: false,
            min_password_length: 4,
            max_password_length: 30,
            allow_alphanumeric: false,
            allow_capital: false,
            allow_special_char: false,
            allow_user_id: false,
            must_contain_lower_case: false,
            must_contain_upper_case: false,
            must_contain_special_character: false,
            allow_consectutive_character: false,
            allow_password_reuse: false,
            allow_password_reuse_after: 10,
            check_password_history: false,
            password_history_count: 10,
            password_expiry_days: 90,
            hash_id: '',
            password_configuration_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_user: '',
            scopes: {},
            text_limit: 5,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.EditPasswordConfiguration + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();


    }



    componentDidUpdate() {

    }



    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['update:password_config', 'action:password_config'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getPasswordConfiguration();
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getPasswordConfiguration() {


        axios.get(`${this.apiURL}/api/v1/user/password/config?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    passwordConfigurationDetails: response.data.data,
                    limit_pasword_length: (response.data.data[0].limit_pasword_length === true || response.data.data[0].limit_pasword_length === 'true') ? true : false || false,
                    min_password_length: response.data.data[0].min_password_length || this.state.min_password_length,
                    max_password_length: response.data.data[0].max_password_length || this.state.max_password_length,
                    allow_alphanumeric: (response.data.data[0].allow_alphanumeric === true || response.data.data[0].allow_alphanumeric === 'true') ? true : false || false,
                    allow_capital: (response.data.data[0].allow_capital === true || response.data.data[0].allow_capital === 'true') ? true : false || false,
                    allow_special_char: (response.data.data[0].allow_special_char === true || response.data.data[0].allow_special_char === 'true') ? true : false || false,
                    allow_user_id: (response.data.data[0].allow_user_id === true || response.data.data[0].allow_user_id === 'true') ? true : false || false,
                    must_contain_lower_case: (response.data.data[0].must_contain_lower_case === true || response.data.data[0].must_contain_lower_case === 'true') ? true : false || false,
                    must_contain_upper_case: (response.data.data[0].must_contain_upper_case === true || response.data.data[0].must_contain_upper_case === 'true') ? true : false || false,
                    must_contain_special_character: (response.data.data[0].must_contain_special_character === true || response.data.data[0].must_contain_special_character === 'true') ? true : false || false,
                    allow_consectutive_character: (response.data.data[0].allow_consectutive_character === true || response.data.data[0].allow_consectutive_character === 'true') ? true : false || false,
                    allow_password_reuse: (response.data.data[0].allow_password_reuse === true || response.data.data[0].allow_password_reuse === 'true') ? true : false || false,
                    allow_password_reuse_after: response.data.data[0].allow_password_reuse_after || this.state.allow_password_reuse_after,
                    check_password_history: (response.data.data[0].check_password_history === true || response.data.data[0].check_password_history === 'true') ? true : false || false,
                    password_history_count: response.data.data[0].password_history_count || this.state.password_history_count,
                    password_expiry_days: response.data.data[0].password_expiry_days || this.state.password_expiry_days,
                    hash_id: response.data.data[0].hash_id || '',
                    password_configuration_id: response.data.data[0].password_configuration_id || '',
                    status: response.data.data[0].status || '',
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                });



                if (this.state.status === 'Active') {
                    this.setState({ status_active: true });
                } else {
                    this.setState({ status_active: false });
                }


                this.setState({ result: response.data.status }, () => {
                   
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                this.setState({ preloader: null });

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

            });

        this.setState({ preloader: null });

    }




    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }



        if (name === 'limit_pasword_length') { this.setState({ [name]: !this.state.limit_pasword_length }); }
        if (name === 'allow_alphanumeric') { this.setState({ [name]: !this.state.allow_alphanumeric }); }
        if (name === 'allow_capital') { this.setState({ [name]: !this.state.allow_capital }); }
        if (name === 'allow_special_char') { this.setState({ [name]: !this.state.allow_special_char }); }
        if (name === 'allow_user_id') { this.setState({ [name]: !this.state.allow_user_id }); }
        if (name === 'must_contain_lower_case') { this.setState({ [name]: !this.state.must_contain_lower_case }); }
        if (name === 'must_contain_upper_case') { this.setState({ [name]: !this.state.must_contain_upper_case }); }
        if (name === 'must_contain_special_character') { this.setState({ [name]: !this.state.must_contain_special_character }); }
        if (name === 'allow_consectutive_character') { this.setState({ [name]: !this.state.allow_consectutive_character }); }
        if (name === 'allow_password_reuse') { this.setState({ [name]: !this.state.allow_password_reuse }); }
        if (name === 'check_password_history') { this.setState({ [name]: !this.state.check_password_history }); }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SavePasswordConfiguration}`)) {

            let password = this.refs.password.value + '';

            if (password.length > 0) {



                let limit_pasword_length = this.refs.limit_pasword_length.checked;

                let min_password_length = this.refs.min_password_length.value.replace(/\s/g, "");
                let max_password_length = this.refs.max_password_length.value.replace(/\s/g, "");

                let allow_alphanumeric = this.refs.allow_alphanumeric.checked;
                let allow_capital = this.refs.allow_capital.checked;
                let allow_special_char = this.refs.allow_special_char.checked;
                let allow_user_id = this.refs.allow_user_id.checked;
                let must_contain_lower_case = this.refs.must_contain_lower_case.checked;
                let must_contain_upper_case = this.refs.must_contain_upper_case.checked;
                let must_contain_special_character = this.refs.must_contain_special_character.checked;
                let allow_consectutive_character = this.refs.allow_consectutive_character.checked;
                let allow_password_reuse = this.refs.allow_password_reuse.checked;

                let allow_password_reuse_after = this.refs.allow_password_reuse_after.value.replace(/\s/g, "");

                let check_password_history = this.refs.check_password_history.checked;

                let password_history_count = this.refs.password_history_count.value.replace(/\s/g, "");
                let password_expiry_days = this.refs.password_expiry_days.value.replace(/\s/g, "");

                let hash_id = this.state.hash_id;
                let password_configuration_id = this.state.password_configuration_id;


                let status = "Inactive";
                if (this.refs.activate_switch.checked) status = "Active";
                if (!this.refs.activate_switch.checked) status = "Inactive";

                let username = this.state.logged_in_username;
                let last_updated_by = this.state.logged_in_username;

                let last_updated_on_check = this.state.last_updated_on_check;


                this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.PasswordConfiguration.AddErrorTitle, [min_password_length, min_password_length, min_password_length, max_password_length, max_password_length, max_password_length, allow_password_reuse_after, allow_password_reuse_after, allow_password_reuse_after, password_history_count, password_history_count, password_history_count, password_expiry_days, password_expiry_days, password_expiry_days], [this.Language.DataField.MinimumPasswordLength, this.Language.DataField.MinimumPasswordLength, this.Language.DataField.MinimumPasswordLength, this.Language.DataField.MaximumPasswordLength, this.Language.DataField.MaximumPasswordLength, this.Language.DataField.MaximumPasswordLength, this.Language.DataField.AllowPasswordToBeReusedAfter, this.Language.DataField.AllowPasswordToBeReusedAfter, this.Language.DataField.AllowPasswordToBeReusedAfter, this.Language.DataField.CheckNumberOfPreviousPasswordHistoryBeforeAllowingPasswordReuse, this.Language.DataField.CheckNumberOfPreviousPasswordHistoryBeforeAllowingPasswordReuse, this.Language.DataField.CheckNumberOfPreviousPasswordHistoryBeforeAllowingPasswordReuse, this.Language.DataField.NumberOfDaysBeforePasswordExpiry, this.Language.DataField.NumberOfDaysBeforePasswordExpiry, this.Language.DataField.NumberOfDaysBeforePasswordExpiry], ["isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength", "isRequired", "isNumber", "maxLength"], 0, this.state.text_limit) }, () => {

                    if (this.state.error_messages === null) {


                        const userOS = this.Browser.getOS();

                        //make call to database 
                        const newPasswordConfiguration = {
                            username: username,
                            password: password,
                            hash_id: hash_id,
                            password_configuration_id: password_configuration_id,
                            limit_pasword_length: limit_pasword_length,
                            min_password_length: Math.abs(min_password_length),
                            max_password_length: Math.abs(max_password_length),
                            allow_alphanumeric: allow_alphanumeric,
                            allow_capital: allow_capital,
                            allow_special_char: allow_special_char,
                            allow_user_id: allow_user_id,
                            must_contain_lower_case: must_contain_lower_case,
                            must_contain_upper_case: must_contain_upper_case,
                            must_contain_special_character: must_contain_special_character,
                            allow_consectutive_character: allow_consectutive_character,
                            allow_password_reuse: allow_password_reuse,
                            allow_password_reuse_after: Math.abs(allow_password_reuse_after) || null,
                            check_password_history: check_password_history,
                            password_history_count: Math.abs(password_history_count) || null,
                            password_expiry_days: Math.abs(password_expiry_days) || null,
                            status: status,
                            last_updated_by: last_updated_by,
                            last_updated_on_check: last_updated_on_check,
                            device_name: this.state.logged_in_username + '\'s ' + userOS,
                            device_model: userOS,
                            device_os: userOS,
                            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                            app_source: "web",
                            token: this.Auth.getToken(),
                        }



                        

                        this.setState({ preloader: true });
                        document.getElementById("update_password_configuration_button").setAttribute("disabled", true);

                        this.updatePasswordConfiguration(newPasswordConfiguration);

                    } else {
                        window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                    }

                });


            } else {

                window.Materialize.toast(this.Language.ReactValidationMessage.PasswordConfiguration.PleaseEnterYourPasswordToUpdate, 4000, 'rounded deep-orange darken-4');
            }

        }

    }







    updatePasswordConfiguration(newPasswordConfiguration) {


        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/user/password/config`,
            data: newPasswordConfiguration
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(this.state.message, 8000, 'rounded green');

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.UpdateSuccess, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_password_configuration_button").removeAttribute("disabled");



        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 401) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.IncorrectUsernamePassword, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.UpdateConflict, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_password_configuration_button").removeAttribute("disabled");

            });

    }




    onDelete() {

        document.getElementById("delete_password_configuration_button").setAttribute("disabled", true);

        let username = this.state.logged_in_username;
        let password = this.refs.password.value + '';


        if (password.length > 0) {


            let password_configuration_id = this.state.password_configuration_id;


            const userOS = this.Browser.getOS();

            const deletePasswordConfiguration = {
                username: username,
                password: password,
                password_configuration_id: password_configuration_id,
                last_updated_by: username,
                device_name: this.state.logged_in_username + '\'s ' + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: "web",
                token: this.Auth.getToken(),
            }



            axios.request({
                method: 'delete',
                url: `${this.apiURL}/api/v1/user/password/config`,
                data: deletePasswordConfiguration
            }).then(response => {

                this.setState({ status: response.data.status })

                this.setState({ message: response.data.message }, () => {
                    

                    window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.DeleteSuccess, 8000, 'rounded green');


                })


                document.getElementById("delete_password_configuration_button").removeAttribute("disabled");
                this.props.history.replace('/security/password/configuration');

            })
                .catch(error => {
                    if (error.response) {

                       


                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 401) window.Materialize.toast(this.Language.NodeMessage.PasswordConfiguration.IncorrectUsernamePassword, 8000, 'rounded deep-orange darken-4');




                    } else if (error.request) {
                        
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        
                    }
                    

                    document.getElementById("delete_password_configuration_button").removeAttribute("disabled");

                });


        } else {

            window.Materialize.toast(this.Language.ReactValidationMessage.PasswordConfiguration.PleaseEnterYourPasswordToDelete, 4000, 'rounded deep-orange darken-4');
            document.getElementById("delete_password_configuration_button").removeAttribute("disabled");
        }

    }




    render() {


        let passwordConfigId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Security"} active_menu_level_2={"Password Configuration"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditPasswordConfiguration}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.PasswordConfiguration, this.Language.BreadCrumbLabel.EditPasswordConfiguration]} links={[`/users`, `/security/password/configuration`, `/security/password/configuration/edit/${passwordConfigId}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.EditPasswordConfiguration}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="password" name="password" type="password" ref="password" />
                                                            <label htmlFor="password" className="">
                                                                {this.Language.DataField.EnterYourPassword} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="limit_pasword_length" ref="limit_pasword_length" name="limit_pasword_length" checked={this.state.limit_pasword_length} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="limit_pasword_length">{this.Language.DataField.LimitPasswordLength}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="min_password_length" name="min_password_length" type="number" className="validate" data-length={this.state.text_limit} ref="min_password_length" value={this.state.min_password_length} onChange={this.handleInputChange} />
                                                            <label htmlFor="min_password_length" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className={((this.state.min_password_length && this.state.min_password_length !== null && typeof this.state.min_password_length !== 'undefined') || this.state.min_password_length === 0) ? "active" : ""}>
                                                                {this.Language.DataField.MinimumPasswordLength} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="max_password_length" name="max_password_length" type="number" className="validate" data-length={this.state.text_limit} ref="max_password_length" value={this.state.max_password_length} onChange={this.handleInputChange} />
                                                            <label htmlFor="max_password_length" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className={((this.state.max_password_length && this.state.max_password_length !== null && typeof this.state.max_password_length !== 'undefined') || this.state.max_password_length === 0) ? "active" : ""}>
                                                                {this.Language.DataField.MaximumPasswordLength} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_alphanumeric" ref="allow_alphanumeric" name="allow_alphanumeric" checked={this.state.allow_alphanumeric} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="allow_alphanumeric">{this.Language.DataField.AllowAlphanumericCharacters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_capital" ref="allow_capital" name="allow_capital" checked={this.state.allow_capital} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="allow_capital">{this.Language.DataField.AllowUpperCaseLetters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_special_char" ref="allow_special_char" name="allow_special_char" checked={this.state.allow_special_char} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="allow_special_char">{this.Language.DataField.AllowSpecialCharacters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_user_id" ref="allow_user_id" name="allow_user_id" checked={this.state.allow_user_id} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="allow_user_id">{this.Language.DataField.AllowUsernameInPassword}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="must_contain_lower_case" ref="must_contain_lower_case" name="must_contain_lower_case" checked={this.state.must_contain_lower_case} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="must_contain_lower_case">{this.Language.DataField.AllowUsernameInPassword}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="must_contain_upper_case" ref="must_contain_upper_case" name="must_contain_upper_case" checked={this.state.must_contain_upper_case} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="must_contain_upper_case">{this.Language.DataField.MustContainUpperCaseLetters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="must_contain_special_character" ref="must_contain_special_character" name="must_contain_special_character" checked={this.state.must_contain_special_character} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="must_contain_special_character">{this.Language.DataField.MustContainSpecialCharacters}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="allow_consectutive_character" ref="allow_consectutive_character" name="allow_consectutive_character" checked={this.state.allow_consectutive_character} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="allow_consectutive_character">{this.Language.DataField.AllowConsecutiveCharacters}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="allow_password_reuse" ref="allow_password_reuse" name="allow_password_reuse" checked={this.state.allow_password_reuse} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="allow_password_reuse">{this.Language.DataField.AllowPasswordReuse}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s8">
                                                            <input id="allow_password_reuse_after" name="allow_password_reuse_after" type="number" className="validate" data-length={this.state.text_limit} ref="allow_password_reuse_after" value={this.state.allow_password_reuse_after} onChange={this.handleInputChange} />
                                                            <label htmlFor="allow_password_reuse_after" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className={((this.state.allow_password_reuse_after && this.state.allow_password_reuse_after !== null && typeof this.state.allow_password_reuse_after !== 'undefined') || this.state.allow_password_reuse_after === 0) ? "active" : ""}>
                                                                {this.Language.DataField.AllowPasswordReuseAfer} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="check_password_history" ref="check_password_history" name="check_password_history" checked={this.state.check_password_history} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="check_password_history">{this.Language.DataField.CheckPasswordHistory}</label>
                                                            </p>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="password_history_count" name="password_history_count" type="number" className="validate" data-length={this.state.text_limit} ref="password_history_count" value={this.state.password_history_count} onChange={this.handleInputChange} />
                                                            <label htmlFor="password_history_count" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className={((this.state.password_history_count && this.state.password_history_count !== null && typeof this.state.password_history_count !== 'undefined') || this.state.password_history_count === 0) ? "active" : ""}>
                                                                {this.Language.DataField.PasswordHistoryCount} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="password_expiry_days" name="password_expiry_days" type="number" className="validate" data-length={this.state.text_limit} ref="password_expiry_days" value={this.state.password_expiry_days} onChange={this.handleInputChange} />
                                                            <label htmlFor="password_expiry_days" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className={((this.state.password_expiry_days && this.state.password_expiry_days !== null && typeof this.state.password_expiry_days !== 'undefined') || this.state.password_expiry_days === 0) ? "active" : ""}>
                                                                {this.Language.DataField.PasswordExpiryDays} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivatePasswordConfiguration}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivatePasswordConfiguration}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_password_configuration_button">
                                                                {this.Language.ButtonLabel.UpdatePasswordConfiguration}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s2">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/security/password/configuration`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_password_configuration_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeletePasswordConfiguration}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditPasswordConfiguration;