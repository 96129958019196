import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditAnnualRegistration extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateAnnualRegistration = this.updateAnnualRegistration.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getAnnualRegistration = this.getAnnualRegistration.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.getExportYears = this.getExportYears.bind(this);
        this.state = {
            exporter_id: '',
            status_in_scheme: '',
            exporter_category_id: '',
            export_year: '',
            is_registered: false,
            registration_number: '',
            exporter_name: '',
            exporter_hash_id: '',
            category_name: '',
            category_level: '',
            hash_exporter_id: '',
            hash_id: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_exporter_category: [],
            select_field_values_status_in_scheme: [],
            select_field_values_export_year: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        
        this.getProfile();
        this.checkAccessRight();
        this.getExportYears();
        this.getAnnualRegistration();

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:annual_reg', 'action:annual_reg'], 'view') === false)) {
            this.props.history.replace('/exporters/annual/registration');
        } else {
            this.getDropdownValues("exporter_category,status_in_scheme");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }


    getExportYears() {

        let year_temp = null;
        let select_field_values_export_year = [];

        for (let i = 0; i < 20; i++) { // populate the values in the export year dropdown
            if (i === 0) {
                year_temp = new Date();
                year_temp = year_temp.getFullYear();
                select_field_values_export_year.push(year_temp - 5); // add five precedding years to the list
                select_field_values_export_year.push(year_temp - 4);
                select_field_values_export_year.push(year_temp - 3);
                select_field_values_export_year.push(year_temp - 2);
                select_field_values_export_year.push(year_temp - 1);
                select_field_values_export_year.push(year_temp);
            } else {
                select_field_values_export_year.push(select_field_values_export_year[select_field_values_export_year.length - 1] + 1); // get the last element in the array and add 1 to it before adding the value back to the array
            }
        }

        this.setState({ select_field_values_export_year: select_field_values_export_year });



    }



    getAnnualRegistration() {

        let annualregistrationId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/exporter/annual/registration/item/${annualregistrationId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({
                    exporter_id: response.data.data[0].exporter_id,
                    status_in_scheme: response.data.data[0].status_in_scheme || '',
                    exporter_category_id: response.data.data[0].exporter_category_id,
                    export_year: response.data.data[0].export_year || '',
                    is_registered: (response.data.data[0].is_registered === true || response.data.data[0].is_registered === 'true') ? true : false || false,
                    registration_number: response.data.data[0].registration_number || '',
                    exporter_name: response.data.data[0].exporter_name || '',
                    exporter_hash_id: response.data.data[0].exporter_hash_id,
                    category_name: response.data.data[0].category_name || '',
                    category_level: response.data.data[0].category_level,
                    hash_exporter_id: response.data.data[0].hash_exporter_id,
                    hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                    document.title = this.Language.PageTitle.EditAnnualRegistration.BeginningPhrase + ' ' + this.state.exporter_name + ' ' + this.Language.PageTitle.EditAnnualRegistration.EndPhrase + ' - ' + this.Language.EEGWebPortal;
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                })

                this.setState({ message: response.data.message }, () => {
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }

        if (name === 'is_registered') { this.setState({ [name]: !this.state.is_registered }); }


    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveAnnualRegistration} ${this.state.exporter_name}, ${this.state.export_year}`)) {


            let exporter_name = this.state.exporter_name;

            let export_year = this.refs.export_year.value;
            if (export_year === "Select Export Year") export_year = "";

            let exporter_id = this.state.exporter_id;

            let status_in_scheme = this.refs.status_in_scheme.value;
            if (status_in_scheme === "Select Status in Scheme") status_in_scheme = "";

            let exporter_category_id = this.refs.exporter_category_id.value;
            if (exporter_category_id === "Select Exporter Category") exporter_category_id = "";

            let is_registered = this.refs.is_registered.checked;

            let hash_exporter_id = this.state.hash_exporter_id;

            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.AnnualRegistration.EditErrorTitle, [export_year, exporter_id, status_in_scheme, exporter_category_id, hash_exporter_id], [this.Language.DataField.ExportYear, this.Language.DataField.ExporterID, this.Language.DataField.StatusInScheme, this.Language.DataField.ExporterCategory, this.Language.DataField.HashExporterID], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

                if (this.state.error_messages === null) {

                    //continue from here
                    if (export_year === "Select Export Year") export_year = null;
                    if (export_year === "Select Status in Scheme") status_in_scheme = null;
                    if (exporter_category_id === "Select Exporter Category") exporter_category_id = null;



                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newAnnualRegistration = {
                        exporter_name: exporter_name,
                        exporter_id: exporter_id,
                        status_in_scheme: status_in_scheme,
                        exporter_category_id: exporter_category_id,
                        export_year: export_year,
                        is_registered: is_registered,
                        hash_exporter_id: hash_exporter_id,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }




                    this.setState({ preloader: true });
                    document.getElementById("update_annual_registration_button").setAttribute("disabled", true);

                    this.updateAnnualRegistration(newAnnualRegistration);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_exporter_category = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'exporter_category') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_exporter_category: select_field_values_exporter_category });



                        const select_field_values_status_in_scheme = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'status_in_scheme') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_status_in_scheme: select_field_values_status_in_scheme });



                        const select_field_values_export_year = this.state.select_field_values_export_year.map((export_year_value, i) => {
                            return (
                                <SelectField pick_list_id={export_year_value} key={i} item_name={export_year_value} />
                            )
                        })

                        this.setState({ select_field_values_export_year: select_field_values_export_year });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    updateAnnualRegistration(newAnnualRegistration) {

        let annualRegistrationId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/exporter/annual/registration/${annualRegistrationId}`,
            data: newAnnualRegistration
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.UpdateSuccess + newAnnualRegistration.exporter_name + ', ' + newAnnualRegistration.export_year, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_annual_registration_button").removeAttribute("disabled");

            this.props.history.push('/exporters/annual/registration');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/exporters/annual/registration');


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("update_annual_registration_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_annual_registration_button").setAttribute("disabled", true);


        let exporter_name = this.state.exporter_name;

        let export_year = this.refs.export_year.value;
        if (export_year === "Select Export Year") export_year = "";

        let exporter_id = this.state.exporter_id;
        let logged_in_username = this.state.logged_in_username;
        let annualRegistrationId = this.props.match.params.id;

        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.AnnualRegistration.EditErrorTitle, [export_year, exporter_id], [this.Language.DataField.ExportYear, this.Language.DataField.ExporterID], ["isRequired", "isRequired"], 0, 0) }, () => {

            if (this.state.error_messages === null) {



                const userOS = this.Browser.getOS();


                const deleteAnnualRegistration = {
                    exporter_name: exporter_name,
                    export_year: export_year,
                    exporter_id: exporter_id,
                    last_updated_by: logged_in_username,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }



                axios.request({
                    method: 'delete',
                    url: `${this.apiURL}/api/v1/exporter/annual/registration/${annualRegistrationId}`,
                    data: deleteAnnualRegistration
                }).then(response => {

                    this.setState({ status: response.data.status })

                    this.setState({ message: response.data.message }, () => {
                        window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.DeleteSuccess + deleteAnnualRegistration.exporter_name + ', ' + deleteAnnualRegistration.export_year, 8000, 'rounded green');
                    })


                    document.getElementById("delete_annual_registration_button").removeAttribute("disabled");
                    this.props.history.replace('/exporters/annual/registration');

                })
                    .catch(error => {
                        if (error.response) {



                            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.AnnualRegistration.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');



                        } else if (error.request) {
                        } else {
                            // Something happened in setting up the request that triggered an Error
                        }

                        document.getElementById("delete_annual_registration_button").removeAttribute("disabled");

                    });


            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });

    }




    render() {


        // let annualRegistrationId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Exporter Management"} active_menu_level_2={"Annual Registration"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditAnnualRegistration.BeginningPhrase} ${this.state.exporter_name} ${this.Language.PageTitle.EditAnnualRegistration.EndPhrase}`} breadcrumbs={[this.Language.BreadCrumbLabel.ExporterManagement, this.Language.BreadCrumbLabel.AnnualRegistration, this.Language.BreadCrumbLabel.EditAnnualRegistration]} links={[`/exporters`, `/exporters/annual/registration`, `/exporters/annual/registration/edit/`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.EditAnnualRegistration}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>

                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="exporter_name" type="text" ref="exporter_name" value={this.state.exporter_name} />
                                                            <label htmlFor="exporter_name" className={(this.state.exporter_name && this.state.exporter_name !== null && typeof this.state.exporter_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ExporterName} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-export_year" className="initialized" name="export_year" id="export_year" ref="export_year">
                                                                    {this.state.export_year && <option value={this.state.export_year} selected>{this.state.export_year}</option>}
                                                                    <option value="Select Export Year">{this.Language.DataFieldDefault.SelectExportYear}</option>
                                                                    {this.state.select_field_values_export_year.map((export_year_value, i) => {
                                                                        return (
                                                                            export_year_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.ExportYear} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-status_in_scheme" className="initialized" name="status_in_scheme" id="status_in_scheme" ref="status_in_scheme">
                                                                    {this.state.status_in_scheme && <option value={this.state.status_in_scheme} selected>{this.state.status_in_scheme}</option>}
                                                                    <option value="Select Status in Scheme" >{this.Language.DataFieldDefault.SelectStatusInScheme}</option>
                                                                    {this.state.select_field_values_status_in_scheme.map((status_in_scheme_value, i) => {
                                                                        return (
                                                                            status_in_scheme_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.StatusInScheme} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-exporter_category_id" className="initialized" name="exporter_category_id" id="exporter_category_id" ref="exporter_category_id">
                                                                    {this.state.exporter_category_id && <option value={this.state.exporter_category_id} selected>{this.state.category_name}</option>}
                                                                    <option value="Select Exporter Category" >{this.Language.DataFieldDefault.SelectExporterCategory}</option>
                                                                    {this.state.select_field_values_exporter_category.map((exporter_category_id_value, i) => {
                                                                        return (
                                                                            exporter_category_id_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.ExporterCategory} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="is_registered" ref="is_registered" checked={this.state.is_registered} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="is_registered">{this.Language.DataField.IsRegistered}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateAnnualRegistration}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateAnnualRegistration}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_annual_registration_button">
                                                                {this.Language.ButtonLabel.UpdateAnnualRegistration}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s4">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/exporters/annual/registration`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_annual_registration_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteAnnualRegistration} ${this.state.exporter_name}, ${this.state.export_year}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditAnnualRegistration;