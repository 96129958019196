import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';


const $ = require('jquery');



class AddDataFieldSecurity extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addDataFieldSecurity = this.addDataFieldSecurity.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            message: '',
            status: '',
            pick_list_values: [],
            select_field_values_object: [],
            select_field_values_data_field_name: [],
            select_field_values_data_field_type: [],
            select_field_values_masking_type: [],
            preloader: true,
            logged_in_username: '',
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.CreateDataFieldSecurityItem + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

        const monthsFull = this.Language.DatePicker.monthsFull.split(",");
        const monthsShort = this.Language.DatePicker.monthsShort.split(",");
        const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
        const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
        const today = this.Language.DatePicker.today;
        const clear = this.Language.DatePicker.clear;
        const close = this.Language.DatePicker.close;
        const labelMonthNext = this.Language.DatePicker.labelMonthNext;
        const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
        const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
        const labelYearSelect = this.Language.DatePicker.labelYearSelect;


        window.$('.datepicker').pickadate({
            monthsFull: monthsFull,
            monthsShort: monthsShort,
            weekdaysFull: weekdaysFull,
            weekdaysShort: weekdaysShort,
            today: today,
            clear: clear,
            close: close,
            labelMonthNext: labelMonthNext,
            labelMonthPrev: labelMonthPrev,
            labelMonthSelect: labelMonthSelect,
            labelYearSelect: labelYearSelect
            // firstDay: 1,
            // format: 'dd mmmm yyyy',
            // formatSubmit: 'yyyy/mm/dd',
            // selectMonths: true,
            // selectYears: true,
            // selectMonths: true, // Creates a dropdown to control month
            // selectYears: 15, // Creates a dropdown of 15 years to control year,
            // closeOnSelect: false, // Close upon selecting a date,
            // container: undefined, // ex. 'body' will append picker to body
        });

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if (this.Auth.checkAccessScope(['create:data_field_security', 'action:data_field_security'], 'view') === false) {
            this.props.history.replace('/security/data/field/security');
        } else {
            this.getDropdownValues("object,data_field_type,masking_type,data_field_name");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }


    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }



    prepareData(return_option) {

        let data_field_name_temp = ""; // to temporarily store the tablename value
        let object_id_temp = ""; // to temporarily store the object name value

        let object_id = this.refs.object_id.value;
        if (object_id === "Select Object") object_id = "";

        // extract the object name from the concatenation of objectname - objectid string
        if (object_id !== "" && object_id !== null && object_id !== "Select Object") {
            object_id_temp = object_id.split(" - ");
            object_id = object_id_temp[1].toString();
            object_id_temp = object_id_temp[0].toString().trim();
        }



        let data_field_id = this.refs.data_field_id.value.trim();

        let data_field_name = this.refs.data_field_name.value;
        if (data_field_name === "Select Data Field Name") data_field_name = "";

        // extract the field values names from the concatenation of tablename - fieldname string
        if (data_field_name !== "" && data_field_name !== null && data_field_name !== "Select Data Field Name") {
            data_field_name_temp = data_field_name.split(" - ");
            data_field_name = data_field_name_temp[1].toString();
            data_field_name_temp = data_field_name_temp[0].toString().trim();
        }


        let data_field_type = this.refs.data_field_type.value;
        if (data_field_type === "Select Data Field Type") data_field_type = "";


        let encrypt_data_field = this.refs.encrypt_data_field.checked;
        let mask_data_field = this.refs.mask_data_field.checked;

        let masking_type = this.refs.masking_type.value;
        if (masking_type === "Select Data Masking Type") masking_type = "";

        let masking_character = this.refs.masking_character.value;

        let masking_date = this.DateService.convertToFullDate(this.refs.masking_date.value);

        let description = this.refs.description.value;


        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;
        let last_updated_by = this.state.logged_in_username;


        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.DataFieldSecurity.AddErrorTitle, [object_id, data_field_id, data_field_name, data_field_type, masking_type, masking_character, masking_date, description, description], [this.Language.DataField.Object, this.Language.DataField.DataFieldID, this.Language.DataField.DataFieldName, this.Language.DataField.DataFieldType, this.Language.DataField.MaskingType, this.Language.DataField.MaskingCharacter, this.Language.DataField.MaskingDate, this.Language.DataField.Description, this.Language.DataField.Description], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequiredDate", "isRequired", "maxLength"], 0, this.state.text_limit) }, () => {

            if (this.state.error_messages === null) {

                if (object_id === "Select Object") object_id = null;
                if (data_field_name === "Select Data Field Name") data_field_name = null;
                if (data_field_type === "Select Data Field Type") data_field_type = null;
                if (masking_type === "Select Data Masking Type") masking_type = null;

                const userOS = this.Browser.getOS();

                //make call to database 
                const newDataFieldSecurity = {
                    object_id: object_id,
                    data_field_id: object_id + data_field_id, //combine object id and field id
                    data_field_name: data_field_name,
                    data_field_type: data_field_type,
                    encrypt_data_field: encrypt_data_field,
                    mask_data_field: mask_data_field,
                    masking_type: masking_type,
                    masking_character: masking_character,
                    masking_date: masking_date,
                    description: description,
                    status: status,
                    created_by: created_by,
                    last_updated_by: last_updated_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken()
                }


                this.setState({ preloader: true });
                document.getElementById("save_data_field_security_button").setAttribute("disabled", true);


                if (data_field_name_temp === object_id_temp) {
                    this.addDataFieldSecurity(newDataFieldSecurity, return_option);
                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.DataFieldSecurity.MismatchErrorMessage, 10000, 'rounded deep-orange darken-4');
                    this.setState({ preloader: null });
                    document.getElementById('save_data_field_security_button').removeAttribute('disabled');
                }


            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }



    clearForm() {

        document.getElementById("add_data_field_security_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);
        $(".encrypt_data_field").find("input[type=checkbox]").prop('checked', true);
        $(".mask_data_field").find("input[type=checkbox]").prop('checked', true);

    }




    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {

                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_object = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'object') {
                                return (
                                    <SelectField pick_list_id={`${pick_list_value.item_name} - ${pick_list_value.pick_list_id}`} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_object: select_field_values_object });



                        const select_field_values_data_field_name = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'data_field_name') {
                                let temp_data_field_name = pick_list_value.item_name.split('.');
                                temp_data_field_name = temp_data_field_name[1];

                                return <SelectField pick_list_id={temp_data_field_name} key={i} item_name={temp_data_field_name} />;
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_data_field_name: select_field_values_data_field_name });



                        const select_field_values_data_field_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'data_field_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_data_field_type: select_field_values_data_field_type });




                        const select_field_values_masking_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'masking_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_masking_type: select_field_values_masking_type });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {

                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    addDataFieldSecurity(newDataFieldSecurity, return_option) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/security/data/field/security`,
            data: newDataFieldSecurity
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.DataFieldSecurity.AddSuccess + newDataFieldSecurity.data_field_name, 8000, 'rounded green');

            })


            this.setState({ preloader: null });
            document.getElementById("save_data_field_security_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/security/data/field/security');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DataFieldSecurity.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.DataFieldSecurity.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("save_data_field_security_button").removeAttribute("disabled");

            });

    }






    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);
        $(".encrypt_data_field").find("input[type=checkbox]").prop('checked', true);
        $(".mask_data_field").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Security"} active_menu_level_2={"Data Field Security"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.CreateDataFieldSecurityItem}`} breadcrumbs={[this.Language.BreadCrumbLabel.Security, this.Language.BreadCrumbLabel.DataFieldSecurity, this.Language.BreadCrumbLabel.AddDataFieldSecurityItem]} links={[`/`, `/security/data/field/security/`, `/security/data/field/security/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.AddDataFieldSecurityItem}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_data_field_security_form" ref="add_data_field_security_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-object_id" className="initialized" name="object_id" id="object_id" ref="object_id">
                                                                    <option value="Select Object" >{this.Language.DataFieldDefault.SelectObject}</option>
                                                                    {this.state.select_field_values_object.map((object_value, i) => {
                                                                        return (
                                                                            object_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Object} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-data_field_name" className="initialized" name="data_field_name" ref="data_field_name">
                                                                    <option value="Select Data Field Name">{this.Language.DataFieldDefault.SelectDataFieldName}</option>
                                                                    {this.state.select_field_values_data_field_name.map((field_name_value, i) => {
                                                                        return (
                                                                            field_name_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.DataFieldName} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s3">
                                                            <input id="data_field_id" type="text" ref="data_field_id" defaultValue={"fieldx"} />
                                                            <label htmlFor="data_field_id" className="">
                                                                {this.Language.DataField.DataFieldID} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s3">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-data_field_type" className="initialized" name="data_field_type" ref="data_field_type">
                                                                    <option value="Select Data Field Type">{this.Language.DataFieldDefault.SelectDataFieldType}</option>
                                                                    {this.state.select_field_values_data_field_type.map((field_type_value, i) => {
                                                                        return (
                                                                            field_type_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.DataFieldType} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4 encrypt_data_field">
                                                            <p>
                                                                <input type="checkbox" id="encrypt_data_field" ref="encrypt_data_field" />
                                                                <label htmlFor="encrypt_data_field">{this.Language.DataField.EncryptDataField}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4 right mask_data_field">
                                                            <p>
                                                                <input type="checkbox" id="mask_data_field" ref="mask_data_field" />
                                                                <label htmlFor="mask_data_field">{this.Language.DataField.MaskDataField}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-masking_type" className="initialized" name="masking_type" id="masking_type" ref="masking_type">
                                                                    <option value="Select Data Masking Type" >{this.Language.DataFieldDefault.SelectDataMaskingType}</option>
                                                                    {this.state.select_field_values_masking_type.map((masking_type_value, i) => {
                                                                        return (
                                                                            masking_type_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.MaskingType} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s3">
                                                            <input id="masking_character" type="text" ref="masking_character" defaultValue={"*******"} />
                                                            <label htmlFor="masking_character" className="">
                                                                {this.Language.DataField.MaskingCharacter} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s3">
                                                            <input type="text" className="datepicker picker__input" ref="masking_date" readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" defaultValue={`07 ${this.Language.Calendar.MonthsFull.July}, 1777`} />
                                                            <label htmlFor="masking_date">
                                                                {this.Language.DataField.MaskingDate} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="description" className="materialize-textarea" data-length={this.state.text_limit} ref="description"></textarea>
                                                            <label htmlFor="description" data-error={this.Language.DataFieldError.MaxLength}>
                                                                {this.Language.DataField.Description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s6">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateDataFieldSecurity}
                                                                    <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateDataFieldSecurity}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveDataFieldSecurity.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_data_field_security_button">
                                                                {this.Language.ButtonLabel.SaveDataFieldSecurity}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveDataFieldSecurity.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/security/data/field/security`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar  language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddDataFieldSecurity;