import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

class VerifyCode extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.verifySecurityCode = this.verifySecurityCode.bind(this);
        this.state = {
            hash_user: '',
            logged_in_username: '',
            username: '',
            name: '',
            email: '',
            organisation: '',
            user_role: '',
            failed_attempt_count: 0,
            max_failed_attempt: 3,
            two_factor_code: '',
            two_factor_code_temp: '',
            preloader: null,
            message: '',
            login_message: '',
            token: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }

    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();
    }

    componentDidMount() {
        document.title = this.Language.PageTitle.VerifyCode + ' - ' + this.Language.EEGWebPortal;
        // this.checkAccessRight();

    }

    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }

    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null) {
            this.setState({
                hash_user: user_profile.user,
                logged_in_username: user_profile.username
            });
            this.setState({});
            // this.setState({name: user_profile.name});
            // this.setState({email: user_profile.email});
            // this.setState({organisation: user_profile.organisation});
            // this.setState({user_role: user_profile.user_role});
        }

    }

    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['create:authenticate', 'update:authenticate', 'create:change_password', 'update:change_password'], 'view') === false && this.state.hash_user === this.props.match.params.id) {
            this.props.history.replace(default_home);
        };

    }

    onSubmit(event) {

        event.preventDefault();

        let username = this.state.logged_in_username;
        let two_factor_code = this.refs.two_factor_code.value;



        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.VerifyCode.EditErrorTitle, [two_factor_code], [this.Language.DataField.SecurityCode], ["isRequired"], 0, 0) }, () => {

            if (this.state.error_messages === null) {


                if (two_factor_code !== null) {


                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newSecurityAuthentication = {
                        username: username,
                        two_factor_code: two_factor_code,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }

                    

                    this.setState({ preloader: true });
                    document.getElementById("verify_security_code_button").setAttribute("disabled", true);

                    this.verifySecurityCode(newSecurityAuthentication);

                } else {

                    this.setState({ failed_attempt_count: this.state.failed_attempt_count + 1 });

                    let try_count = this.state.max_failed_attempt - this.state.failed_attempt_count;

                    window.Materialize.toast(`${this.Language.ConfirmMessage.ConfirmVerifyCode} ${try_count}`, 8000, 'rounded deep-orange accent-3');

                    if (this.state.failed_attempt_count >= this.state.max_failed_attempt) {
                        this.Auth.logout();
                        this.props.history.replace('/signin');
                    };


                };


            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            };

        });


    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }




    verifySecurityCode(newSecurityAuthentication) {

        let userId = this.props.match.params.id;

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/authenticate/2fa/c/${userId}`,
            data: newSecurityAuthentication
        }).then(response => {
            this.setState({ status: response.data.status }, () => {
                
            })

            this.setState({ token: response.data.token }, () => {
                
                // let payload = JSON.parse(atob(this.state.token.split('.')[1]));
                
                // this.Auth.logout();
                AuthService.authenticateUser(this.state.token); // Save the token in session storage

                this.setState({ preloader: null });
                document.getElementById("verify_security_code_button").removeAttribute("disabled");

                let redirect = response.data.redirect;


                if (redirect !== null) {
                    this.props.history.replace(redirect);
                } else {
                    this.props.history.replace('/');
                };

            })



            this.setState({ login_message: response.data.login_message }, () => {
                
                if (this.state.login_message !== null) {
                    window.Materialize.toast(this.state.login_message, 8000, 'rounded green');
                }
            })

            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(`${this.Language.NodeMessage.VerifyCode.Success}`, 8000, 'rounded green');
            })





        })
            .catch(error => {
                if (error.response) {

                    


                    window.Materialize.toast(`${this.Language.NodeMessage.VerifyCode.Error}`, 10000, 'rounded deep-orange darken-4');

                    this.setState({ failed_attempt_count: this.state.failed_attempt_count + 1 });

                    let try_count = this.state.max_failed_attempt - this.state.failed_attempt_count;

                    window.Materialize.toast(`${this.Language.ConfirmMessage.VerifyCodeAttemptsLeft} ${try_count}`, 10000, 'rounded deep-orange accent-3');

                    if (this.state.failed_attempt_count === this.state.max_failed_attempt) {
                        this.Auth.logout();
                        this.props.history.replace('/signin');
                    };


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("verify_security_code_button").removeAttribute("disabled");

            });
    }




    render() {


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={""} active_menu_level_2={""} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.VerifyCode}`} breadcrumbs={[this.Language.BreadCrumbLabel.SecurityCode]} links={[`/signin/2fa/c/${this.state.hash_user}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            {/* <h4 className="header2">Provide Your Code to Signin </h4> */}
                                            <div className="row">

                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">enhanced_encryption</i>
                                                            <input id="two_factor_code" name="two_factor_code" type="text" ref="two_factor_code" value={this.state.two_factor_code} onChange={this.handleInputChange} />
                                                            <label htmlFor="two_factor_code" className={(this.state.two_factor_code && this.state.two_factor_code !== null && typeof this.state.two_factor_code !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SecurityCode}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="verify_security_code_button">
                                                                {this.Language.ButtonLabel.VerifySecurityCode}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        {/* <div className="input-field col s6">
                                                                    <Link className="waves-effect waves-light btn grey right" to={`/signin`}><i className="material-icons left">cancel</i>cancel</Link>
                                                                </div> */}
                                                    </div>
                                                    <br />
                                                    <br />
                                                </form>



                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <br />
                                <br />

                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default VerifyCode;