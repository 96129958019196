import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';



class UserDevices extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getDevices = this.getDevices.bind(this);
        this.revokeDeviceAccess = this.revokeDeviceAccess.bind(this);
        this.revokeDeviceAccessStepTwo = this.revokeDeviceAccessStepTwo.bind(this);
        this.revokeAccess = this.revokeAccess.bind(this);
        this.state = {
            deviceDetails: [],
            result: '',
            status: '',
            message: '',
            hash_user: '',
            username: '',
            passwordField: null,
            revoke_device_position: '',
            revoke_user_device_id: '',
            revoke_device_name: '',
            revoke_device_model: '',
            revoke_device_mac: '',
            revoke_device_os: ''
        }
        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
    }


    componentWillMount() {
        this.getProfile();

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.UserDevicesUser.BeginningPhrase + ' ' + this.state.username + ' ' + this.Language.PageTitle.UserDevicesUser.EndPhrase + ' - ' + this.Language.EEGWebPortal;
        this.checkAccessRight();
        this.getDevices();


    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null) {
            this.setState({
                hash_user: user_profile.user,
                username: user_profile.username
            });
        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['read:device', 'read:devices'], 'view') === false) || this.state.hash_user !== this.props.match.params.id) {
            this.props.history.replace(default_home);
        };

    }


    getDevices() {
        let userId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/user/devices/${userId}?tk=${this.Auth.getToken()}`)
            .then(response => {

                if (response.data.data) {
                    this.setState({ deviceDetails: response.data.data || [] }, () => {
                        
                    })
                }

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }

    revokeDeviceAccess(i, user_device_id, device_name, device_model, device_os) {

        this.setState({ passwordField: true });

        window.alert(`${this.Language.ConfirmMessage.UserDevicesPasswordEntry}`);

        this.setState({ revoke_device_position: i });
        this.setState({ revoke_user_device_id: user_device_id });
        this.setState({ revoke_device_name: device_name });
        this.setState({ revoke_device_model: device_model });
        this.setState({ revoke_device_os: device_os });

    }

    revokeDeviceAccessStepTwo() {

        let password = this.refs.password.value;
        let username = this.state.username;


        if (password !== '' && password !== null && username !== '' && username !== null) {

            const userOS = this.Browser.getOS();

            const newRevokeDeviceAction = {
                username: username,
                password: password,
                revoke_device_id: this.state.revoke_user_device_id,
                revoke_device_name: this.state.revoke_device_name,
                revoke_device_model: this.state.revoke_device_model,
                revoke_device_os: this.state.revoke_device_os,
                device_name: username + '\'s ' + userOS,
                device_model: userOS,
                device_os: userOS,
                browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                app_source: "web",
                token: this.Auth.getToken()
            }

            this.setState({ passwordField: null });

            

            if (newRevokeDeviceAction.device_name === newRevokeDeviceAction.revoke_device_name && newRevokeDeviceAction.device_model === newRevokeDeviceAction.revoke_device_model && newRevokeDeviceAction.device_os === newRevokeDeviceAction.revoke_device_os) {

                window.Materialize.toast(`${this.Language.ConfirmMessage.UserDevicesRevokeAccess}`, 8000, 'rounded deep-orange accent-3');

            } else {

                this.revokeAccess(newRevokeDeviceAction);

            };


        } else {

            window.Materialize.toast(`${this.Language.ConfirmMessage.UserDevicesPasswordRequired}`, 4000, 'rounded deep-orange darken-4');

        }




    }


    revokeAccess(newRevokeDeviceAction) {

        let hash_user_id = this.state.hash_user;

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/authenticate/token/revoke/${hash_user_id}`,
            data: newRevokeDeviceAction
        }).then(response => {

            this.setState({ status: response.data.status }, () => {
                
            })


            this.setState({ message: response.data.message }, () => {
                
                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.UserDevices.UpdateSuccess + newRevokeDeviceAction.revoke_device_name, 8000, 'rounded green');
            })

            this.props.history.replace(`/user/devices/${hash_user_id}`);

        })
            .catch(error => {
                if (error.response) {

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.UserDevices.UpdateError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.UserDevices.DoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

            });

    }



    render() {


        let deviceCardsOdd = null

        if (this.state.deviceDetails && this.state.deviceDetails !== null && typeof this.state.deviceDetails !== 'undefined') {
        deviceCardsOdd = this.state.deviceDetails.map((device, i) => {
            if ((i % 2) === 0) {
                return (
                    [
                        <div className="card white" key={i}>
                            <div className="card-content teal-text">
                                <span className="card-title">{i + 1}. {this.Language.DataField.DeviceName}: <b>{device.device_name}</b> <i className="material-icons right">devices</i></span>
                                <Link to={`#!`}><span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.RevokeAccess} onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.UserDevicesRevoke} ${device.device_name}`)) { this.revokeDeviceAccess(i, device.user_device_id, device.device_name, device.device_model, device.device_os) } }}></span></Link>
                                <br />
                                <span>{this.Language.DataField.Username}: <b>{device.username}</b> </span>
                                <br />
                                <div className="divider"></div>
                                <br />
                                <p><b> {this.Language.PageLabel.DeviceDetails} </b></p>
                                <p>{this.Language.DataField.Model}: <b>{device.device_model}</b> </p>
                                <p>{this.Language.DataField.OS}: <b>{device.device_os}</b> </p>
                                <p>{this.Language.DataField.Browser}: <b>{device.browser}</b> </p>
                                <p>{this.Language.DataField.Location}: <b>{device.location_used}</b> </p>
                            </div>
                        </div>,
                        <br key={i + 1} />,
                        <div className="divider" key={i + 2}></div>
                    ]
                )
            } else {
                return (
                    null
                )
            }
        })

    }


        let deviceCardsEven = null

        if (this.state.deviceDetails && this.state.deviceDetails !== null && typeof this.state.deviceDetails !== 'undefined') {
          deviceCardsEven = this.state.deviceDetails.map((device, i) => {
            if (i % 2 > 0) {
              return [
                <div className="card white" key={i}>
                  <div className="card-content teal-text">
                    <span className="card-title">
                      {i + 1}. {this.Language.DataField.DeviceName}: <b>{device.device_name}</b> <i className="material-icons right">devices</i>
                    </span>
                    <Link to={`#!`}>
                      <span
                        className="new badge gradient-45deg-purple-deep-orange gradient-shadow"
                        data-badge-caption={this.Language.ButtonLabel.RevokeAccess}
                        onClick={() => {
                          if (window.confirm(`${this.Language.ConfirmMessage.UserDevicesRevoke} ${device.device_name}`)) {
                            this.revokeDeviceAccess(i, device.user_device_id, device.device_name, device.device_model, device.device_os);
                          }
                        }}></span>
                    </Link>
                    <br />
                    <span>
                      {this.Language.DataField.Username}: <b>{device.username}</b>{' '}
                    </span>
                    <br />
                    <div className="divider"></div>
                    <br />
                    <p>
                      <b> {this.Language.PageLabel.DeviceDetails} </b>
                    </p>
                    <p>
                      {this.Language.DataField.Model}: <b>{device.device_model}</b>{' '}
                    </p>
                    <p>
                      {this.Language.DataField.OS}: <b>{device.device_os}</b>{' '}
                    </p>
                    <p>
                      {this.Language.DataField.Browser}: <b>{device.browser}</b>{' '}
                    </p>
                    <p>
                      {this.Language.DataField.Location}: <b>{device.location_used}</b>{' '}
                    </p>
                  </div>
                </div>,
                <br key={i + 1} />,
                <div className="divider" key={i + 2}></div>
              ];
            } else {
              return null;
            }
          });
        }



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Devices"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.UserDevicesUser.BeginningPhrase} ${this.state.username} ${this.Language.PageTitle.UserDevicesUser.EndPhrase}`} breadcrumbs={[this.Language.BreadCrumbLabel.Profile, this.Language.BreadCrumbLabel.Devices]} links={[`/user/profile/${this.state.hash_user}`, `/user/devices/${this.state.hash_user}}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div id="profile-page" className="section">
                                    <div id="profile-page-content" className="row">
                                        {this.state.passwordField && <div className="row">
                                            <form onSubmit={this.revokeDeviceAccessStepTwo}>
                                                <div className="input-field col s6">
                                                    <i className="material-icons prefix">lock</i>
                                                    <input id="password" type="password" ref="password" className="validate" />
                                                    <label htmlFor="password">{this.Language.ConfirmMessage.UserDevicePasswordToRevokeAccess} {this.state.revoke_device_position + 1}, {this.state.revoke_device_name}</label>
                                                </div>
                                                <div className="input-field col s6">
                                                    <input type="submit" value={`${this.Language.ConfirmMessage.UserDeviceRevokeMessage} ${this.state.revoke_device_position + 1}`} className="waves-effect waves-light col s6 btn left gradient-45deg-purple-deep-orange" id="revoke_submit_button" />
                                                </div>
                                            </form>
                                        </div>}
                                        <div id="profile-page-sidebar" className="col s12 m6">
                                            {deviceCardsOdd}
                                        </div>
                                        <div id="profile-page-wall" className="col s12 m6">
                                            {deviceCardsEven}
                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default UserDevices;