import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import AuthService from '../modules/AuthService';

import Home from './Home';
import Signin from './Signin';
import Search from './Search';
import SupportContacts from './SupportContacts';
import VerifySecurityQuestions from './VerifySecurityQuestions';
import VerifyCode from './VerifyCode';
import Users from './Users';
import AddUser from './AddUser';
import EditUser from './EditUser';
import UserDetails from './UserDetails';
import UserProfile from './UserProfile';
import UserSecurityQuestions from './UserSecurityQuestions';
import Devices from './Devices';
import UserDevices from './UserDevices';
import UserSignins from './UserSignins';
import UserSpecificSignins from './UserSpecificSignins';
import OnlineUsers from './OnlineUsers';
import Exporters from './Exporters';
import AddExporter from './AddExporter';
import ExporterDetails from './ExporterDetails';
import ExporterProfile from './ExporterProfile';
import EditExporter from './EditExporter';
import AnnualRegistration from './AnnualRegistration';
import AddAnnualRegistration from './AddAnnualRegistration';
import EditAnnualRegistration from './EditAnnualRegistration';
import ExporterExportedItems from './ExporterExportedItems';
import AddExporterExportedItem from './AddExporterExportedItem';
import EditExporterExportedItem from './EditExporterExportedItem';
import ExporterExportedItemsProfile from './ExporterExportedItemsProfile';
import AddExporterExportedItemProfile from './AddExporterExportedItemProfile';
import EditExporterExportedItemProfile from './EditExporterExportedItemProfile';
import Partners from './Partners';
import AddPartner from './AddPartner';
import PartnerProfile from './PartnerProfile';
import PartnerDetails from './PartnerDetails';
import EditPartner from './EditPartner';
import ExporterCategories from './ExporterCategories';
import AddExporterCategory from './AddExporterCategory';
import EditExporterCategory from './EditExporterCategory';
import ProductCategorisations from './ProductCategorisations';
import AddProductCategorisation from './AddProductCategorisation';
import EditProductCategorisation from './EditProductCategorisation';
import BaselineDataSummary from './BaselineDataSummary';
import BaselineDataProfile from './BaselineDataProfile';
import AddBaselineData from './AddBaselineData';
import AddBaselineDataProfile from './AddBaselineDataProfile';
import EditBaselineData from './EditBaselineData';
import EditBaselineDataProfile from './EditBaselineDataProfile';
import BaselineThresholdWeight from './BaselineThresholdWeight';
import BaselineYears from './BaselineYears';
import AddBaselineYear from './AddBaselineYear';
import EditBaselineYear from './EditBaselineYear';
import EEGRates from './EEGRates';
import EEGRatesProfile from './EEGRatesProfile';
import ECCSummary from './ECCSummary';
import ECCProfile from './ECCProfile';
import AddECC from './AddECC';
import EditECC from './EditECC';
import ECCUtilisationSummary from './ECCUtilisationSummary';
import EditECCUtilisation from './EditECCUtilisation';
import ECCVerification from './ECCVerification';
import ExchangeRates from './ExchangeRates';
import AddExchangeRate from './AddExchangeRate';
import EditExchangeRate from './EditExchangeRate';
import TrackProductivity from './TrackProductivity';
import EEGClaimSummary from './EEGClaimSummary';
import EEGClaimApplications from './EEGClaimApplications';
import EEGClaimsStatus from './EEGClaimsStatus';
import EEGClaimsStatusSummary from './EEGClaimsStatusSummary';
import EEGClaimDetails from './EEGClaimDetails';
import EEGClaimApplicationDetails from './EEGClaimApplicationDetails';
import AddEEGClaim from './AddEEGClaim';
import AddEEGClaimApplication from './AddEEGClaimApplication';
import EditEEGClaim from './EditEEGClaim';
import EditEEGClaimApplication from './EditEEGClaimApplication';
import EEGClaimApplicationsExporter from './EEGClaimApplicationsExporter';
import ExportersDocuments from './ExportersDocuments';
import ExporterDocuments from './ExporterDocuments';
import DocumentDownloader from './DocumentDownloader';
import Settings from './Settings';
import SystemModules from './SystemModules';
import EditSystemModule from './EditSystemModule';
import AddSystemModule from './AddSystemModule';
import UserRoles from './UserRoles';
import AddUserRole from './AddUserRole';
import EditUserRole from './EditUserRole';
import UserPasswordHistory from './UserPasswordHistory';
import SystemAccessRightObjects from './SystemAccessRightObjects';
import AddSystemAccessRightObject from './AddSystemAccessRightObject';
import EditSystemAccessRightObject from './EditSystemAccessRightObject';
import AccessRights from './AccessRights';
import AddAccessRight from './AddAccessRight';
import PickLists from './PickLists';
import AddPickList from './AddPickList';
import EditPickList from './EditPickList';
import NotificationSettings from './NotificationSettings';
import NotificationSettingsUsers from './NotificationSettingsUsers';
import Notifications from './Notifications';
import AddNotification from './AddNotification';
import AnnouncementView from './AnnouncementView';
import Announcements from './Announcements';
import AddAnnouncement from './AddAnnouncement';
import EditAnnouncement from './EditAnnouncement';
import SecuritySettings from './SecuritySettings';
import DeviceSettings from './DeviceSettings';
import LanguageAndTimeZone from './LanguageAndTimeZone';
import ForgotPassword from './ForgotPassword';
import UserPasswordChange from './UserPasswordChange';
import ForgotPasswordChange from './ForgotPasswordChange';
import LoginConfiguration from './LoginConfiguration';
import EditLoginConfiguration from './EditLoginConfiguration';
import AddLoginConfiguration from './AddLoginConfiguration';
import PasswordConfiguration from './PasswordConfiguration';
import EditPasswordConfiguration from './EditPasswordConfiguration';
import AddPasswordConfiguration from './AddPasswordConfiguration';
import DataFieldSecurity from './DataFieldSecurity';
import AddDataFieldSecurity from './AddDataFieldSecurity';
import EditDataFieldSecurity from './EditDataFieldSecurity';
import Tenants from './Tenants';
import EditTenant from './EditTenant';
import AddTenant from './AddTenant';
import AuditActivities from './AuditActivities';
import DataTables from './DataTables';
import DataTableDetails from './DataTableDetails';
import DataTemplates from './DataTemplates';
import DataTemplateFields from './DataTemplateFields';
import OrphanedRecords from './OrphanedRecords';
import OrphanedRecordEntries from './OrphanedRecordEntries';
import DataDownloadUpload from './DataDownloadUpload';
import DataDelete from './DataDelete';

const Main = () => {
  // Check if the user is authenticated before allowing the user access any route. If not redirect to the signin page
  if (AuthService.prototype.loggedIn() === true) {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/signin/2fa/sq/:id" component={VerifySecurityQuestions} />
          <Route path="/signin/2fa/c/:id" component={VerifyCode} />
          <Route path="/home" component={Home} />
          <Route path="/dashboard/:name/:record_limit" component={Home} />
          <Route path="/dashboard/:name" component={Home} />
          <Route path="/dashboard" component={Home} />
          <Route path="/search" component={Search} />
          <Route path="/support/contacts" component={SupportContacts} />
          <Route path="/help/documentation/:id" component={DocumentDownloader} />
          <Route path="/user/profile/password/history/:id" component={UserPasswordHistory} />
          <Route path="/user/profile/sq/:id" component={UserSecurityQuestions} />
          <Route path="/user/profile/signins/:id" component={UserSpecificSignins} />
          <Route path="/user/profile/:id" component={UserProfile} />
          <Route path="/users/add" component={AddUser} />
          <Route path="/users/online" component={OnlineUsers} />
          <Route path="/users/edit/:id" component={EditUser} />
          <Route path="/users/:id" component={UserDetails} />
          <Route path="/users" component={Users} />
          <Route path="/user/devices/:id" component={UserDevices} />
          <Route path="/user/devices" component={Devices} />
          <Route path="/user/signins" component={UserSignins} />
          <Route path="/exporters/document/:id" component={DocumentDownloader} />
          <Route path="/exporters/documents" component={ExportersDocuments} />
          <Route path="/exporter/:exp/document/:id" component={DocumentDownloader} />
          <Route path="/exporter/:id/documents" component={ExporterDocuments} />
          <Route path="/exporters/eeg/claims/edit/:id" component={EditEEGClaim} />
          <Route path="/exporters/eeg/claims/listing/:id" component={EEGClaimApplicationsExporter} />
          <Route path="/exporters/eeg/claims/status" component={EEGClaimsStatusSummary} />
          <Route path="/exporters/eeg/claims/add" component={AddEEGClaim} />
          <Route path="/exporters/eeg/claims/:id" component={EEGClaimDetails} />
          <Route path="/exporters/eeg/claims" component={EEGClaimSummary} />
          <Route path="/exporter/:id/eeg/claim/status/" component={EEGClaimsStatus} />
          <Route path="/exporter/:exp_id/eeg/claim/application/edit/:id" component={EditEEGClaimApplication} />
          <Route path="/exporter/:exp_id/eeg/claim/application/:id" component={EEGClaimApplicationDetails} />
          <Route path="/exporter/:id/eeg/claim/applications/add" component={AddEEGClaimApplication} />
          <Route path="/exporter/:id/eeg/claim/applications" component={EEGClaimApplications} />
          <Route path="/exporters/ecc/edit/:id" component={EditECC} />
          <Route path="/exporters/ecc/add" component={AddECC} />
          <Route path="/exporters/ecc/utilisation/edit/:id" component={EditECCUtilisation} />
          <Route path="/exporters/ecc/utilisation" component={ECCUtilisationSummary} />
          <Route path="/exporters/ecc/verification" component={ECCVerification} />
          <Route path="/exporters/ecc" component={ECCSummary} />
          <Route path="/exporter/:id/ecc/profile" component={ECCProfile} />
          <Route path="/exporters/exported/items/edit/:id" component={EditExporterExportedItem} />
          <Route path="/exporters/exported/items/add" component={AddExporterExportedItem} />
          <Route path="/exporters/exported/items" component={ExporterExportedItems} />
          <Route path="/exporter/:exp_id/exported/items/edit/:id" component={EditExporterExportedItemProfile} />
          <Route path="/exporter/:id/exported/items/add" component={AddExporterExportedItemProfile} />
          <Route path="/exporter/:id/exported/items" component={ExporterExportedItemsProfile} />
          <Route path="/exporters/annual/registration/edit/:id" component={EditAnnualRegistration} />
          <Route path="/exporters/annual/registration/add" component={AddAnnualRegistration} />
          <Route path="/exporters/annual/registration" component={AnnualRegistration} />
          <Route path="/exporter/profile/:id" component={ExporterProfile} />
          <Route path="/exporters/add" component={AddExporter} />
          <Route path="/exporters/edit/:id" component={EditExporter} />
          <Route path="/exporters/:id" component={ExporterDetails} />
          <Route path="/exporters" component={Exporters} />
          <Route path="/partner/profile/:id" component={PartnerProfile} />
          <Route path="/partners/add" component={AddPartner} />
          <Route path="/partners/edit/:id" component={EditPartner} />
          <Route path="/partners/:id" component={PartnerDetails} />
          <Route path="/partners" component={Partners} />
          <Route path="/categorisation/exporter/add" component={AddExporterCategory} />
          <Route path="/categorisation/exporter/edit/:id" component={EditExporterCategory} />
          <Route path="/categorisation/exporter" component={ExporterCategories} />
          <Route path="/categorisation/product/add" component={AddProductCategorisation} />
          <Route path="/categorisation/product/edit/:id" component={EditProductCategorisation} />
          <Route path="/categorisation/product" component={ProductCategorisations} />
          <Route path="/exporter/baseline/rates" component={EEGRates} />
          <Route path="/exporter/:id/baseline/profile/rates" component={EEGRatesProfile} />
          <Route path="/exporter/:id/baseline/profile/edit/:yr" component={EditBaselineDataProfile} />
          <Route path="/exporter/baseline/threshold/weight" component={BaselineThresholdWeight} />
          <Route path="/exporter/baseline/data/edit/:id/:yr" component={EditBaselineData} />
          <Route path="/exporter/baseline/data/add" component={AddBaselineData} />
          <Route path="/exporter/baseline/data" component={BaselineDataSummary} />
          <Route path="/exporter/baseline/years/add" component={AddBaselineYear} />
          <Route path="/exporter/baseline/years/edit/:id" component={EditBaselineYear} />
          <Route path="/exporter/baseline/years" component={BaselineYears} />
          <Route path="/exporter/:id/baseline/profile/add" component={AddBaselineDataProfile} />
          <Route path="/exporter/:id/baseline/profile" component={BaselineDataProfile} />
          <Route path="/tools/exchange/rates/edit/:id" component={EditExchangeRate} />
          <Route path="/tools/exchange/rates/add" component={AddExchangeRate} />
          <Route path="/tools/exchange/rates" component={ExchangeRates} />
          <Route path="/tools/productivity" component={TrackProductivity} />
          <Route path="/settings/language/timezone/:id" component={LanguageAndTimeZone} />
          <Route path="/settings/device/:id" component={DeviceSettings} />
          <Route path="/settings/security/:id" component={SecuritySettings} />
          <Route path="/settings/notification/:id" component={NotificationSettings} />
          <Route path="/settings/notifications" component={NotificationSettingsUsers} />
          <Route path="/settings/picklist/edit/:id" component={EditPickList} />
          <Route path="/settings/picklist/add" component={AddPickList} />
          <Route path="/settings/picklists" component={PickLists} />
          <Route path="/settings/access/right/add" component={AddAccessRight} />
          <Route path="/settings/access/rights" component={AccessRights} />
          <Route path="/settings/access/right/object/edit/:id" component={EditSystemAccessRightObject} />
          <Route path="/settings/access/right/object/add" component={AddSystemAccessRightObject} />
          <Route path="/settings/access/right/objects" component={SystemAccessRightObjects} />
          <Route path="/settings/modules/edit/:id" component={EditSystemModule} />
          <Route path="/settings/modules/add" component={AddSystemModule} />
          <Route path="/settings/modules" component={SystemModules} />
          <Route path="/settings/user/role/edit/:id" component={EditUserRole} />
          <Route path="/settings/user/role/add" component={AddUserRole} />
          <Route path="/settings/user/roles" component={UserRoles} />
          <Route path="/settings" component={Settings} />
          <Route path="/notifications/compose" component={AddNotification} />
          <Route path="/notifications/:id" component={Notifications} />
          <Route path="/annoucements/all/edit/:id" component={EditAnnouncement} />
          <Route path="/announcements/all/compose" component={AddAnnouncement} />
          <Route path="/announcements/all" component={Announcements} />
          <Route path="/announcements" component={AnnouncementView} />
          <Route path="/password/change/user/:id" component={UserPasswordChange} />
          <Route path="/password/change/forgot/:id" component={ForgotPasswordChange} />
          <Route path="/security/login/configuration/edit/:id" component={EditLoginConfiguration} />
          <Route path="/security/login/configuration/add" component={AddLoginConfiguration} />
          <Route path="/security/login/configuration" component={LoginConfiguration} />
          <Route path="/security/password/configuration/edit/:id" component={EditPasswordConfiguration} />
          <Route path="/security/password/configuration/add" component={AddPasswordConfiguration} />
          <Route path="/security/password/configuration" component={PasswordConfiguration} />
          <Route path="/security/data/field/security/edit/:id" component={EditDataFieldSecurity} />
          <Route path="/security/data/field/security/add" component={AddDataFieldSecurity} />
          <Route path="/security/data/field/security" component={DataFieldSecurity} />
          <Route path="/security/tenants/edit/:id" component={EditTenant} />
          <Route path="/security/tenants/add" component={AddTenant} />
          <Route path="/security/tenants" component={Tenants} />
          <Route path="/security/audit/activities" component={AuditActivities} />
          <Route path="/data/management/tables" component={DataTables} />
          <Route path="/data/management/table/:table_name" component={DataTableDetails} />
          <Route path="/data/management/templates" component={DataTemplates} />
          <Route path="/data/management/template/:table_name" component={DataTemplateFields} />
          <Route path="/data/management/orphaned/records" component={OrphanedRecords} />
          <Route path="/data/management/orphaned/record/:table_name" component={OrphanedRecordEntries} />
          <Route path="/data/management/download/upload" component={DataDownloadUpload} />
          <Route path="/data/management/delete/management" component={DataDelete} />
          <Route path="/signin" component={Signin} />
          <Route path="/password/forgot" component={ForgotPassword} />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  } else {
    //If not redirect to the signin page
    return (
      <div>
        <Switch>
          <Route path="/signin" component={Signin} />
          <Route path="/password/forgot" component={ForgotPassword} />
          {/* <Route path='/password/change/forgot/:id' component={ForgotPasswordChange}/>
          <Route path='/password/change/user/:id' component={UserPasswordChange}/> */}
          <Redirect to={{pathname: '/signin'}} />
        </Switch>
      </div>
    );
  }
};

export default Main;
