import React, {Component} from 'react';


class Settings extends Component{
    render(){
        return (
            <div>
                <h1> Settings </h1>
            </div>
        )
    }
}

export default Settings;