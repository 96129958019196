import React, {Component} from 'react';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
// import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import BrowserService from '../modules/BrowserService';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import Dashboard from '../components/Dashboard';

import axios from 'axios';

class Home extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.getAccessScopes = this.getAccessScopes.bind(this);
    this.getDashboardData = this.getDashboardData.bind(this);
    this.state = {
      hash_user: '',
      username: '',
      name: '',
      email: '',
      organisation: '',
      partner: '',
      user_role: '',
      user_access: null,
      dashboard_name: 'default',
      scopes: {},
      elements: {},
      preloader: true,
      dashboardLayout: '',
      record_limit: 5,
      dashboardElement: null
    };
    this.Auth = new AuthService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.Format = new FormatService();
    this.DateService = new DateService();
    this.apiURL = URLService.prototype.getApiURL();
    this.returnURLQuery = URLService.prototype.getReturnURLQuery();
  }

  componentWillMount() {
    this.checkAccessRight();
  }

  componentDidMount() {
    document.title = this.Language.EEGWebPortal;

    // this.setState({dashboardElement: <Dashboard dashboardDisplayType={"dummy"}/> });
    // const elements = [
    //     {dashboard_object_type: "DataCard",
    //         element_type: "card",
    //         icon_name: 'account_circle',
    //         title: "User Accounts",
    //         total_value: 1000,
    //         value_is_decimal: false,
    //         value_decimal_places: 0,
    //         Lang: this.Language.Lang,
    //         sub_category_title: "Active User Accounts",
    //         sub_category_value: 200,
    //         sub_value_is_decimal: true,
    //         card_colour: 'cyan',
    //         text_colour: 'white',
    //         hoverable: true
    //         },
    //     {dashboard_object_type: "DataCard",
    //         element_type: "card",
    //         icon_name: 'language',
    //         title: "Total EEG Claims",
    //         total_value: 100000,
    //         value_is_decimal: false,
    //         value_decimal_places: 0,
    //         Lang: this.Language.Lang,
    //         sub_category_title: "Pending Claims",
    //         sub_category_value: 20000,
    //         sub_value_is_decimal: true,
    //         card_colour: 'green',
    //         text_colour: 'white',
    //         hoverable: true
    //         },
    //     {dashboard_object_type: "DataCard",
    //         element_type: "card",
    //         icon_name: 'credit_card',
    //         title: "EEG Payable",
    //         total_value: 1000000,
    //         value_is_decimal: false,
    //         value_decimal_places: 0,
    //         Lang: this.Language.Lang,
    //         sub_category_title: "Outstanding payments",
    //         sub_category_value: 200000,
    //         sub_value_is_decimal: true,
    //         card_colour: 'teal',
    //         text_colour: 'white',
    //         hoverable: true
    //         },
    //     {dashboard_object_type: "DataCard",
    //         element_type: "card",
    //         icon_name: 'card_membership',
    //         title: "ECC Issued",
    //         total_value: 50000000,
    //         value_is_decimal: false,
    //         value_decimal_places: 0,
    //         Lang: this.Language.Lang,
    //         sub_category_title: "Outstanding ECCs",
    //         sub_category_value: 300000,
    //         card_colour: 'blue',
    //         text_colour: 'white',
    //         hoverable: true
    //         },
    //     {dashboard_object_type: "ValuesChart",
    //     element_type: "line",
    //     title: "Monthly EEG Claims",
    //     show_arrow_icon: true,
    //     arrow_icon_direction: "up",
    //     title_trending_value: "20.90%",
    //     title_trending_value_prefix: "",
    //     title_trending_value_suffix: '',
    //     link: '/users',
    //     data: {
    //         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //         datasets: [
    //           {
    //             label: 'My First dataset',
    //             fill: false,
    //             fillColor: "#E4E4E4",
    //             strokeColor: "#E4E4E4",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "transparent",
    //             pointStrokeColor: "transparent",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#E4E4E4",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             data: [65, 59, 80, 81, 56, 55, 40]
    //           }
    //         ]
    //       }
    //     },
    //     {dashboard_object_type: "ValuesChartVertical",
    //         element_type: "bar",
    //         icon_name: 'language',
    //         title: "NECCs",
    //         title_trending_value: "10,000",
    //         title_trending_value_prefix: "NGN ",
    //         title_trending_value_suffix: "",
    //         sub_title: "Unutilised NECCs",
    //         lower_title: "Total NECCs for 2017",
    //         show_arrow_icon: true,
    //         arrow_icon_direction: "up",
    //         button_title: "View Full",
    //         text_colour: 'purple',
    //         sub_title_text_colour: 'grey',
    //         link: '/users',
    //         data: {
    //         labels: ["1", "2", "3", "4", "5", "6", "7", "8", "8", "8", "8", "8", "8", "8"],
    //         datasets: [
    //             {
    //             label: 'My First dataset',
    //             fill: false,
    //             fillColor: "#E4E4E4",
    //             strokeColor: "#E4E4E4",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "transparent",
    //             pointStrokeColor: "transparent",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#E4E4E4",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             data: [50, 40, 30, 55, 50, 90, 40, 50, 40, 70, 55, 80, 50, 55]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataChart",
    //         element_type: "line",
    //         title: "Members Online",
    //         main_value: 23450000,
    //         main_value_prefix: "NGN",
    //         main_value_suffix: "%",
    //         Lang: this.Language.Lang,
    //         sub_value: 45000,
    //         sub_value_prefix: "Value: ",
    //         sub_value_suffix : "avg",
    //         sub_value_text_colour: 'cyan',
    //         value_is_decimal: true,
    //         value_decimal_places: 2,
    //         sub_value_is_decimal: false,
    //         sub_value_decimal_places: 0,
    //         badge_colour: 'purple',
    //         badge_value: 34500,
    //         badge_value_prefix: "NGN",
    //         badge_value_suffix: "%",
    //         badge_status: 'new',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataChart",
    //         element_type: "bar",
    //         title: "Members Online",
    //         main_value: 23450000,
    //         main_value_prefix: "NGN",
    //         Lang: this.Language.Lang,
    //         sub_value: 45000,
    //         sub_value_suffix : "avg",
    //         sub_value_text_colour: 'cyan',
    //         value_is_decimal: true,
    //         value_decimal_places: 2,
    //         sub_value_is_decimal: false,
    //         sub_value_decimal_places: 0,
    //         badge_colour: 'purple',
    //         badge_value: 34500,
    //         badge_value_prefix: "NGN",
    //         badge_value_suffix: "%",
    //         badge_status: 'new',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataChart",
    //         element_type: "pie",
    //         title: "Members Online",
    //         main_value: 23450000,
    //         main_value_prefix: "NGN",
    //         Lang: this.Language.Lang,
    //         sub_value: 45000,
    //         sub_value_suffix : "avg",
    //         sub_value_text_colour: 'cyan',
    //         value_is_decimal: true,
    //         value_decimal_places: 2,
    //         sub_value_is_decimal: false,
    //         sub_value_decimal_places: 0,
    //         badge_colour: 'purple',
    //         badge_value: 34500,
    //         badge_value_prefix: "NGN",
    //         badge_value_suffix: "%",
    //         badge_status: 'new',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataCardPicture",
    //         element_type: "card",
    //         title: "EEG Payable for the Period",
    //         sub_title: "Total EEG Payable Amount",
    //         src_image: '',
    //         src_image_alt: '',
    //         paragraph_text: 'Total EEG Payable Amount to be paid to Exporters in the Scheme',
    //         button_value: "9,000,000",
    //         button_value_prefix: "NGN",
    //         button_value_suffix: "%",
    //         button_colour: 'purple',
    //         Lang: this.Language.Lang
    //     },
    //     {dashboard_object_type: "DataCardPicture",
    //         element_type: "card",
    //         title: "EECs Issued for the Period",
    //         sub_title: "Total EEC Amount",
    //         src_image: '',
    //         src_image_alt: '',
    //         paragraph_text: 'Total EEC Issued to be paid to Exporters in the Scheme',
    //         button_value: "100000",
    //         button_value_prefix: "NGN",
    //         button_value_suffix: "%",
    //         button_colour: 'blue',
    //         Lang: this.Language.Lang
    //     },
    //     {dashboard_object_type: "DataCardPicture",
    //         element_type: "card",
    //         title: "Returns for the Period",
    //         sub_title: "Total EEG Payable Amount",
    //         src_image: '',
    //         src_image_alt: '',
    //         paragraph_text: 'Total Retunrs to be paid to Exporters in the Scheme',
    //         button_value: "Returns 100",
    //         button_value_prefix: "",
    //         button_value_suffix: "",
    //         button_colour: 'amber',
    //         Lang: this.Language.Lang
    //     },
    //     {dashboard_object_type: "DataCardCirclePicture",
    //         element_type: "card",
    //         title: "50% off",
    //         sub_title: "Get 50% off when you buy this",
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'cyan',
    //         text_colour: 'white'
    //     },
    //     {dashboard_object_type: "DataCardCirclePicture",
    //         element_type: "card",
    //         title: "50% off",
    //         sub_title: "Get 50% off when you buy this",
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'amber',
    //         text_colour: 'white'
    //     },
    //     {dashboard_object_type: "DataCardCirclePicture",
    //         element_type: "card",
    //         title: "50% off",
    //         sub_title: "Get 50% off when you buy this",
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'green',
    //         text_colour: 'white'
    //     },
    //     {dashboard_object_type: "DataCardCirclePicture",
    //         element_type: "card",
    //         title: "50% off",
    //         sub_title: "Get 50% off when you buy this",
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'red',
    //         text_colour: 'white'
    //     },
    //     {dashboard_object_type: "DataCardAnalytics",
    //         element_type: "line",
    //         icon_name: 'language',
    //         title: "New Clients",
    //         sub_title: "from yesterday.",
    //         main_value: 566,
    //         main_value_prefix: '',
    //         main_value_suffix: '',
    //         sub_value: 15,
    //         sub_value_prefix: "",
    //         sub_value_suffix: "%",
    //         show_arrow_icon: true,
    //         arrow_icon_direction: 'up',
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'blue',
    //         text_colour: 'white',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataCardAnalytics",
    //         element_type: "horizontalbar",
    //         icon_name: 'language',
    //         title: "New Clients",
    //         sub_title: "from yesterday.",
    //         main_value: 566,
    //         main_value_prefix: '',
    //         main_value_suffix: '',
    //         sub_value: 15,
    //         sub_value_prefix: "",
    //         sub_value_suffix: "%",
    //         show_arrow_icon: true,
    //         arrow_icon_direction: 'up',
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'purple',
    //         text_colour: 'white',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataCardAnalytics",
    //         element_type: "pie",
    //         icon_name: 'language',
    //         title: "New Clients",
    //         sub_title: "from yesterday.",
    //         main_value: 566,
    //         main_value_prefix: '',
    //         main_value_suffix: '',
    //         sub_value: 15,
    //         sub_value_prefix: "",
    //         sub_value_suffix: "%",
    //         show_arrow_icon: true,
    //         arrow_icon_direction: 'up',
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'blue',
    //         text_colour: 'white',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataCardAnalytics",
    //         element_type: "bar",
    //         icon_name: 'language',
    //         title: "New Clients",
    //         sub_title: "from yesterday.",
    //         main_value: 566,
    //         main_value_prefix: '',
    //         main_value_suffix: '',
    //         sub_value: 15,
    //         sub_value_prefix: "",
    //         sub_value_suffix: "%",
    //         show_arrow_icon: true,
    //         arrow_icon_direction: 'up',
    //         src_image: '',
    //         src_image_alt: '',
    //         card_colour: 'blue',
    //         text_colour: 'white',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     },
    //     {dashboard_object_type: "DataChartAnalytics",
    //         element_type: "line",
    //         element_type2: "doughnut",
    //         element_type3: "bar",
    //         title: "Total EEG Claims",
    //         main_value: "4,900",
    //         main_value_prefix: "NGN",
    //         main_value_suffix: "avg",
    //         main_value2: "200",
    //         main_value_prefix2: "$",
    //         main_value_suffix2: ".",
    //         sub_value: 21,
    //         sub_value_prefix: "",
    //         sub_value_suffix: "%",
    //         chart_text2: "value",
    //         card_colour: 'cyan',
    //         text_colour: 'white',
    //         filter_colour: "red",
    //         arrow_icon_direction: "up",
    //         show_arrow_icon: true,
    //         option1_text: "Active",
    //         option2_text:  "Inactive",
    //         card_reveal_title: "EEG Claim Records",
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fontColor: "white",
    //             fillColor: "rgba(255, 255, 255, 0.8)",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 255, 255, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         },
    //         data2: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         },
    //         data3: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "gradient",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.6)',
    //                 'rgba(54, 162, 235, 0.6)',
    //                 'rgba(255, 206, 86, 0.6)',
    //                 'rgba(75, 192, 192, 0.6)',
    //                 'rgba(153, 102, 255, 0.6)',
    //                 'rgba(255, 159, 64, 0.6)',
    //                 'rgba(255, 99, 132, 0.6)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         },
    //         data_table_header: ['ID', 'Month', 'Item Sold', 'Item Price', 'Total Profit'],
    //         data_table_rows: [[1,'January', '122', '100', '$122,00.00'],
    //             [2,'February', '122', '100', '$122,00.00'],
    //             [3,'March', '122', '100', '$122,00.00']
    //         ]
    //     },
    //     {dashboard_object_type: "DataChartAnalyticsV2",
    //         element_type: "radar",
    //         element_type2: "line",
    //         title: "ECC Data Manage",
    //         title2: "Revenue by Location",
    //         card_colour: 'teal',
    //         text_colour: 'white',
    //         filter_colour: "cyan",
    //         card_reveal_title: "EEC Records",
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "rgb(255, 255, 255)",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         },
    //         data2: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: false,
    //             display: false,
    //             fillColor: "rgb(255, 255, 255)",
    //             strokeColor: "rgb(255, 255, 255)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         },
    //         data_table_header: ['ID', 'Month', 'ECC Sold', 'ECC Price', 'Total Profit'],
    //         data_table_rows: [[1,'January', '122', '100', '$122,00.00'],
    //             [2,'February', '122', '100', '$122,00.00'],
    //             [3,'March', '122', '100', '$122,00.00']
    //         ]
    //     },
    //     {dashboard_object_type: "DataCollectionWithChart",
    //         element_type: "collection_list_determinate",
    //         title: "Top ECCs",
    //         icon_name: "card_travel",
    //         icon_colour: 'purple',
    //         sub_title: 'List of Top ECCs',
    //         no_record_available: 'No ECC available',
    //         collection_values_list: [{title: '01234568 ECC', subTitle: 'ECC For Exporter 12345', categoryTitle: 'Sub Category', showLink: true, showProgress: true, progressValue: '70%', categoryColour: 'red', progressColour: 'teal'},
    //         {title: '01234568 ECC', subTitle: 'ECC For Exporter 12345', categoryTitle: 'Sub Category', showLink: true, showProgress: false, progressValue: '70%', categoryColour: 'blue', progressColour: 'teal'},
    //         {title: '01234568 ECC', subTitle: 'ECC For Exporter 12345', categoryTitle: 'Sub Category', showLink: false, showProgress: true, progressValue: '50%', categoryColour: 'cyan', progressColour: 'cyan'}]
    //     },
    //     {dashboard_object_type: "TaskCollectionList",
    //         element_type: "collection_list_determinate",
    //         title: "My Tasks",
    //         sub_title: 'List of Latest Tasks',
    //         card_colour: 'green',
    //         text_colour: 'white',
    //         no_record_available: 'No Tasks',
    //         can_edit_check_boxes: true,
    //         collection_values_list: [{title: 'Go to Lagos Island', subTitle: 'Next Week', checked: true, disabled: false, link: '/eeg/users', categoryTitle: 'location tasks', categoryColour: 'green'},
    //         {title: 'Crosscheck your data', subTitle: 'Monday', checked: false, disabled: false, link: '/eeg/users/one', categoryTitle: 'location tasks', categoryColour: 'cyan'},
    //         {title: 'Review EEG Claims', subTitle: 'Thursday', checked: false, disabled: true, link: '/eeg/users/two', categoryTitle: 'exporter schedule', categoryColour: 'purple'}]
    //     },
    //     {dashboard_object_type: "ProfileCard",
    //         element_type: "profile",
    //         profile_name: "Segun Faleti",
    //         profile_title: 'System User',
    //         profile_phone: '+234 806 0200 395',
    //         profile_email: 'jly09@yahoo.com',
    //         icon_name: 'edit',
    //         detail_title: 'Additional details for user',
    //         date_of_registration: '20 July 2018',
    //         role: 'Administrator',
    //         src_back_image: '',
    //         src_back_image_alt: '',
    //         src_image: '',
    //         src_image_alt: '',
    //         image_colour: 'cyan',
    //         button_colour: 'purple'
    //     },
    //     {dashboard_object_type: "MapCard",
    //         element_type: "map_card",
    //         title: "Segun Faleti",
    //         sub_title: 'Administrator System User',
    //         details_text: 'Additional text messages for user',
    //         main_text_colour: 'black',
    //         details_text_colour: 'black',
    //         button_colour: 'teal',
    //         map_card_type: 'Administrator',
    //         address: '1 Bank Street Agege Lagos Nigeria',
    //         phone: '+234 806 0200 395',
    //         email: 'jly09@yahoo.com',
    //         latitude: 50.747688,
    //         longitude: -84.004142
    //     },
    //     {dashboard_object_type: "DataMapSectionCard",
    //         element_type: "bar",
    //         title: "Export Locations",
    //         sub_title: 'Locations for all exports from Nigeria',
    //         chart_title: 'Number of Exports by Location',
    //         chart_sub_title: 'Locations to where all exports go to',
    //         map_height: '300',
    //         map_width: '718.003',
    //         card_colour: 'green',
    //         text_colour: 'white',
    //         sub_title_text_colour: 'grey',
    //         data: {
    //         labels: ["January", "February", "March", "April", "May", "June", ],
    //         datasets: [
    //             {
    //             label: 'My Second dataset',
    //             fill: true,
    //             display: false,
    //             fillColor: "rgb(255, 255, 255)",
    //             strokeColor: "rgba(255,82,82,0.1)",
    //             // scaleShowGridLines: false,
    //             // scaleFontColor: '#9e9e9e',
    //             // scaleGridLineColor: '#e4e4e4',
    //             // scaleLineColor: 'transparent',
    //             // scaleOverride: true,
    //             // scaleSteps: 5,
    //             // scaleStepWidth: 20,
    //             // scaleStartValue: 0,
    //             // datasetStrokeWidth: 10,
    //             lineTension: 0.2,
    //             backgroundColor: [
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)',
    //                 'rgb(255, 255, 255)'],
    //             borderColor: 'rgba(75,192,192,1)',
    //             hoverBackgroundColor: 'rgba(75,192,192,1)',
    //             hoverBorderColor: 'rgba(220,220,220,1)',
    //             borderWidth: 0,
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointColor: "#fff",
    //             pointStrokeColor: "#0288d1",
    //             pointHighlightFill: "#fff",
    //             pointHighlightStroke: "#0288d1",
    //             pointBorderColor: 'rgba(75,192,192,1)',
    //             pointBackgroundColor: '#fff',
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             showLine: true,
    //             spanGaps: true,
    //             scaleFontColor: "#fff",
    //             scaleShowLabels: false,
    //             animation: false,
    //             bezierCurve: true,
    //             scaleStartValue: 0,
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             scaleShowVerticalLines: false,
    //             scaleShowHorizontalLines: false,
    //             showScale: false,
    //             data: [24, 18, 20, 30, 40, 43, ]
    //             }
    //         ]
    //         }
    //     }
    // ]
    // const dashboardLayout = "4:DataCard__1:ValuesChart_1:ValuesChartVertical__3:DataChart";
    // const dashboardLayout = "4:DataCard__1:ValuesChart_1:ValuesChartVertical__3:DataChart__3:DataCardPicture__4:DataCardCirclePicture__4:DataCardAnalytics__1:DataChartAnalytics_1:DataChartAnalyticsV2__1:DataCollectionWithChart_1:TaskCollectionList__1:ProfileCard_1:MapCard__1:DataMapSectionCard";
    //   this.setState({elements: elements,
    //     dashboardLayout: dashboardLayout
    // });
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile !== null) {
      this.setState(
        {
          hash_user: user_profile.user,
          username: user_profile.username,
          name: user_profile.name,
          email: user_profile.email,
          organisation: user_profile.organisation,
          partner: user_profile.partner,
          user_role: user_profile.user_role,
          scopes: user_profile.scopes
        },
        () => {
          this.getAccessScopes();
          this.getDashboardData();
        }
      );
    }
  }

  checkAccessRight() {
    let default_home = '/';
    const dashboards = this.Auth.getDashboards() || [];
    if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${'5'}`;

    if (this.Auth.checkAccessScope(['read:user', 'read:users', 'action:user', 'action:users', 'create:change_password', 'update:change_password'], 'view') === false) {
      this.props.history.replace(default_home);
    } else {
      this.getProfile();
    }
  }

  getAccessScopes() {
    // Check the views scope
    if (this.state.scopes['views']) {
      let len = this.state.scopes['views'].length;

      for (let i = 0; i < len; i++) {
        if (this.state.scopes['views'][i] === 'read:user' || this.state.scopes['views'][i] === 'action:user') {
          this.setState({user_access: true});
          break; // exit the loop
        }
      }
    }
  }

  getDashboardData() {
    let dashboard_name = this.props.match.params.name || this.state.dashboard_name;
    let record_limit = this.props.match.params.record_limit || this.state.record_limit;

    //Query parameters
    const export_year = '2015' || null;
    const business_year = '2016' || null;
    const issued_year = '2015' || null;
    const current_year = '2015' || null;
    const current_date = '2015-10-12' || null; // used for hours_in_day, period_in_day and days_in_week
    const latitude = 9.06752574;
    const longitude = 7.50983501;
    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
    if (record_limit === 'All records' || record_limit === null || typeof record_limit === 'undefined') record_limit = 'all_records';

    // using the previous route approach that had the hash ids in the route
    //`${this.apiURL}/api/v1/home/dashboard/${this.state.user_role}/${dashboard_name}/${this.state.organisation}/${this.state.partner}/${this.state.hash_user}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${record_limit}&exy=${export_year}&bzy=${business_year}&isy=${issued_year}&cury=${current_year}&curd=${current_date}&lat=${latitude}&long=${longitude}`

    axios
      .get(
        `${
          this.apiURL
        }/api/v1/home/dashboard/${dashboard_name}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${record_limit}&exy=${export_year}&bzy=${business_year}&isy=${issued_year}&cury=${current_year}&curd=${current_date}&lat=${latitude}&long=${longitude}`
      )
      .then(response => {
        this.setState(
          {
            elements: response.data.elements,
            dashboardLayout: response.data.dashboardLayout,
            dashboardElement: null
          },
          () => {
            

            if (this.state.elements && this.state.elements !== null && typeof this.state.elements !== 'undefined' && this.state.elements.length > 0) {
              this.setState({
                dashboardElement: (
                  <Dashboard
                    dashboardDisplayType={'real'}
                    Lang={this.Language.Lang}
                    elements={this.state.elements}
                    dashboardLayout={this.state.dashboardLayout}
                    DetailsLabel={this.Language.ButtonLabel.Details}
                  />
                ),
                preloader: null
              });
            } else {
              this.setState({
                dashboardElement: <Dashboard dashboardDisplayType={'dummy'} />,
                preloader: null
              });
            }
          }
        );
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
         

          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        
      });
  }

  render() {
    const dashboard_name = this.props.match.params.name || this.state.dashboard_name;

    let active_menu_level_2 = '';
    let breadcrumb_item = '';
    if (dashboard_name === 'exporters') {
      active_menu_level_2 = 'Exporters';
      breadcrumb_item = this.Language.BreadCrumbLabel.Exporters;
    } else if (dashboard_name === 'exporter') {
      active_menu_level_2 = 'Exporter';
      breadcrumb_item = this.Language.BreadCrumbLabel.Exporter;
    } else if (dashboard_name === 'partners') {
      active_menu_level_2 = 'Partner Agencies';
      breadcrumb_item = this.Language.BreadCrumbLabel.PartnerAgencies;
    } else if (dashboard_name === 'partner') {
      active_menu_level_2 = 'Partner';
      breadcrumb_item = this.Language.BreadCrumbLabel.Partner;
    } else if (dashboard_name === 'claims') {
      active_menu_level_2 = 'Exporters Claims and Baseline';
      breadcrumb_item = this.Language.BreadCrumbLabel.ExportersClaimsandBaseline;
    } else if (dashboard_name === 'claim') {
      active_menu_level_2 = 'Claims and Baseline';
      breadcrumb_item = this.Language.BreadCrumbLabel.ClaimsandBaseline;
    } else if (dashboard_name === 'productivity' || dashboard_name === 'Productivity') {
      active_menu_level_2 = 'Productivity';
      breadcrumb_item = this.Language.BreadCrumbLabel.Productivity;
    } else {
      breadcrumb_item = dashboard_name;
    }

    active_menu_level_2 = active_menu_level_2 + ' Dashboard';

    

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Dashboard'} active_menu_level_2={active_menu_level_2} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.Welcome.BeginningPhrase} ${this.state.name} ${this.Language.Welcome.EndPhrase}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.Dashboard, breadcrumb_item]}
              links={[`/`, `/dashboard/${dashboard_name}/5`, `/dashboard/${dashboard_name}/5`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            {this.state.user_access && (
              <div className="container">
                {this.state.preloader && (
                  <div className="progress teal lighten-4">
                    <div className="indeterminate teal"></div>
                  </div>
                )}

                {this.state.dashboardElement}

                {/* <DataTable tableName={this.Language.DataTableLabel.UserAccounts} 
                                        fields={['Name', 'Position', 'Office', 'Number', 'Start date', 'Pending Claims']} 
                                        fieldDataTypes={['String', 'String', 'String', 'String', 'Date-Year','Number']}
                                        fieldDecimalPlaces={[0, 0, 0, 0, 0, 0]}
                                        dateFormat={'long_date'}
                                        timeFormat={'h12'}
                                        headers={[this.Language.DataTableHeader.Name, this.Language.DataTableHeader.Position, this.Language.DataTableHeader.Office, this.Language.DataTableHeader.RCNumber, this.Language.DataTableHeader.StartDate, this.Language.DataTableHeader.Category]} 
                                        data={[{'Name': 'Adamu Shehu', 'Position': 'Accountant', 'Office': 'Abuja', 'Number': '33', 'Start date': '2008', 'Pending Claims': '162700'},{'Name': 'segun Babalola', 'Position': 'Accountant', 'Office': 'Abuja', 'Number': '33', 'Start date': '2008', 'Pending Claims': '162700'},{'Name': 'Obinna Ezinne', 'Position': 'Accountant', 'Office': 'Abuja', 'Number': '33', 'Start date': '2008', 'Pending Claims': '162700'}]} 
                                        sortType={"ascending"} sortPosition={2} thStyle={[351, 221, 164, 74, 145, 130]} link={''} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={false} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.HomeUserAccounts} emptyMessage={this.Language.DataTableLabel.EmptyMessage} 
                                        downloadFields={['Name', 'Position', 'Office', 'Number', 'Start date', 'Pending Claims']}/> */}
                <br />
                <br />
                <br />
                {/* <br/>
                                    <br/>
                                    <br/> */}
              </div>
            )}
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          {this.state.user_access && <FloatingActionButton language={this.Language} />}
          {/* {this.state.user_access  && <RightSideBar language={this.Language}/> } */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default Home;
