import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';




class NotificationSettings extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateNotificationSetting = this.updateNotificationSetting.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getNotificationSetting = this.getNotificationSetting.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.state = {
            username: '',
            hash_id: '',
            phone: '',
            email: '',
            email_signature: '',
            recovery_email: '',
            recovery_phone: '',
            enable_sms: false,
            enable_email: false,
            enable_notifications: false,
            receive_notification_as_sms: false,
            receive_notification_as_email: false,
            email_security_risk: false,
            email_account_activity: false,
            phone_security_risk: false,
            phone_account_activity: false,
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_user: '',
            text_limit: 300,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.NotificationSettings + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getNotificationSetting();

    }



    componentDidUpdate() {

    }



    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:user', 'action:user', 'update:notification'], 'view') === false || this.state.hash_user !== this.props.match.params.id)) {
            this.props.history.replace(`/user/profile/${this.state.hash_user}`);
        } else {

        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getNotificationSetting() {

        let userId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/settings/notifications/${userId}?tk=${this.Auth.getToken()}`)
            .then(response => {

                this.setState({
                    hash_id: response.data.data[0].hash_id,
                    username: response.data.data[0].username,
                    phone: response.data.data[0].phone || '',
                    email: response.data.data[0].email || '',
                    email_signature: response.data.data[0].email_signature || '',
                    enable_sms: (response.data.data[0].enable_sms === true || response.data.data[0].enable_sms === 'true') ? true : false || false,
                    enable_email: (response.data.data[0].enable_email === true || response.data.data[0].enable_email === 'true') ? true : false || false,
                    enable_notifications: (response.data.data[0].enable_notifications === true || response.data.data[0].enable_notifications === 'true') ? true : false || false,
                    receive_notification_as_sms: (response.data.data[0].receive_notification_as_sms === true || response.data.data[0].receive_notification_as_sms === 'true') ? true : false || false,
                    receive_notification_as_email: (response.data.data[0].receive_notification_as_email === true || response.data.data[0].receive_notification_as_email === 'true') ? true : false || false,
                    recovery_email: response.data.data[0].recovery_email || '',
                    recovery_phone: response.data.data[0].recovery_phone || '',
                    email_security_risk: (response.data.data[0].email_security_risk === true || response.data.data[0].email_security_risk === 'true') ? true : false || false,
                    email_account_activity: (response.data.data[0].email_account_activity === true || response.data.data[0].email_account_activity === 'true') ? true : false || false,
                    phone_security_risk: (response.data.data[0].phone_security_risk === true || response.data.data[0].phone_security_risk === 'true') ? true : false || false,
                    phone_account_activity: (response.data.data[0].phone_account_activity === true || response.data.data[0].phone_account_activity === 'true') ? true : false || false,
                });



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                this.setState({ preloader: null });

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'enable_sms') { this.setState({ [name]: !this.state.enable_sms }); }
        if (name === 'enable_email') { this.setState({ [name]: !this.state.enable_email }); }
        if (name === 'enable_notifications') { this.setState({ [name]: !this.state.enable_notifications }); }
        if (name === 'receive_notification_as_sms') { this.setState({ [name]: !this.state.receive_notification_as_sms }); }
        if (name === 'receive_notification_as_email') { this.setState({ [name]: !this.state.receive_notification_as_email }); }
        if (name === 'email_security_risk') { this.setState({ [name]: !this.state.email_security_risk }); }
        if (name === 'email_account_activity') { this.setState({ [name]: !this.state.email_account_activity }); }
        if (name === 'phone_security_risk') { this.setState({ [name]: !this.state.phone_security_risk }); }
        if (name === 'phone_account_activity') { this.setState({ [name]: !this.state.phone_account_activity }); }

    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.UpdateNotificationSetting}`)) {

            let phone = this.refs.phone.value;
            let email = this.refs.email.value;
            let email_signature = this.refs.email_signature.value;
            let recovery_email = this.refs.recovery_email.value;
            let recovery_phone = this.refs.recovery_phone.value;


            let enable_sms = this.refs.enable_sms.checked;
            let enable_email = this.refs.enable_email.checked;
            let enable_notifications = this.refs.enable_notifications.checked;
            let receive_notification_as_sms = this.refs.receive_notification_as_sms.checked;
            let receive_notification_as_email = this.refs.receive_notification_as_email.checked;
            let email_security_risk = this.refs.email_security_risk.checked;
            let email_account_activity = this.refs.email_account_activity.checked;
            let phone_security_risk = this.refs.phone_security_risk.checked;
            let phone_account_activity = this.refs.phone_account_activity.checked;


            let last_updated_by = this.state.logged_in_username;



            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.NotificationSettings.EditErrorTitle, [phone, email, email, recovery_email, recovery_phone, email_signature], [this.Language.DataField.Phone, this.Language.DataField.Email, this.Language.DataField.Email, this.Language.DataField.RecoveryEmail, this.Language.DataField.RecoveryPhoneNumber, this.Language.DataField.EmailSignature], ["isPhone", "isRequired", "isEmail", "isEmail", "isPhone", "maxLength"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {


                    if (phone === '') phone = null;
                    if (email === '') email = null;
                    if (email_signature === '') email_signature = null;
                    if (recovery_email === '') recovery_email = null;
                    if (recovery_phone === '') recovery_phone = null;

                    const userOS = this.Browser.getOS();


                    //make call to database 
                    const newNotificationSetting = {
                        hash_id: this.state.hash_id,
                        username: this.state.username,
                        phone: phone,
                        email: email,
                        email_signature: email_signature,
                        recovery_email: recovery_email,
                        recovery_phone: recovery_phone,
                        enable_sms: enable_sms,
                        enable_email: enable_email,
                        enable_notifications: enable_notifications,
                        receive_notification_as_sms: receive_notification_as_sms,
                        receive_notification_as_email: receive_notification_as_email,
                        email_security_risk: email_security_risk,
                        email_account_activity: email_account_activity,
                        phone_security_risk: phone_security_risk,
                        phone_account_activity: phone_account_activity,
                        last_updated_by: last_updated_by,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_notification_setting_button").setAttribute("disabled", true);

                    this.updateNotificationSetting(newNotificationSetting);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }




    updateNotificationSetting(newNotificationSetting) {

        let userId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/settings/notifications/${userId}`,
            data: newNotificationSetting
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.NotificationSettings.UpdateSuccess + newNotificationSetting.username, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_notification_setting_button").removeAttribute("disabled");

            // this.props.history.push(`/user/profile/${this.state.hash_user}`);


        })
            .catch(error => {
                if (error.response) {

         


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.NotificationSettings.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.NotificationSettings.DoesNotExist, 8000, 'rounded deep-orange darken-4');

                    }



                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_notification_setting_button").removeAttribute("disabled");

            });

    }




    render() {




        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Notification Settings"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.NotificationSettings}`} breadcrumbs={[this.Language.BreadCrumbLabel.Profile, this.Language.BreadCrumbLabel.NotificationSettings,]} links={[`/user/profile/${this.state.hash_user}`, `/settings/notification/${this.state.hash_user}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.NotificationSettings}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <i className="material-icons prefix">email</i>
                                                            <input id="email" name="email" type="email" className="validate" ref="email" value={this.state.email} onChange={this.handleInputChange} />
                                                            <label htmlFor="email" data-error={this.Language.DataFieldError.Email} className={(this.state.email && this.state.email !== null && typeof this.state.email !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Email} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="phone" name="phone" type="tel" className="validate" ref="phone" value={this.state.phone} onChange={this.handleInputChange} />
                                                            <label htmlFor="phone" data-error={this.Language.DataFieldError.Phone} className={(this.state.phone && this.state.phone !== null && typeof this.state.phone !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Phone} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="email_signature" className="materialize-textarea" data-length={this.state.text_limit} name="email_signature" ref="email_signature" value={this.state.email_signature} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="email_signature" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.email_signature && this.state.email_signature !== null && typeof this.state.email_signature !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.EmailSignature}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="recovery_email" name="recovery_email" type="email" className="validate" ref="recovery_email" value={this.state.recovery_email} onChange={this.handleInputChange} />
                                                            <label htmlFor="recovery_email" data-error={this.Language.DataFieldError.Email} className={(this.state.recovery_email && this.state.recovery_email !== null && typeof this.state.recovery_email !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.RecoveryEmail} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="recovery_phone" name="recovery_phone" type="tel" className="validate" ref="recovery_phone" value={this.state.recovery_phone} onChange={this.handleInputChange} />
                                                            <label htmlFor="recovery_phone" data-error={this.Language.DataFieldError.Phone} className={(this.state.recovery_phone && this.state.recovery_phone !== null && typeof this.state.recovery_phone !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.RecoveryPhoneNumber} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="enable_sms" ref="enable_sms" name="enable_sms" checked={this.state.enable_sms} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_sms">{this.Language.DataField.EnableSMS}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="enable_email" ref="enable_email" name="enable_email" checked={this.state.enable_email} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_email">{this.Language.DataField.EnableEmail}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="enable_notifications" ref="enable_notifications" name="enable_notifications" checked={this.state.enable_notifications} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="enable_notifications">{this.Language.DataField.EnableNotifications}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="receive_notification_as_sms" ref="receive_notification_as_sms" name="receive_notification_as_sms" checked={this.state.receive_notification_as_sms} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="receive_notification_as_sms">{this.Language.DataField.ReceiveNotificationsAsSMS}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="receive_notification_as_email" ref="receive_notification_as_email" name="receive_notification_as_email" checked={this.state.receive_notification_as_email} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="receive_notification_as_email">{this.Language.DataField.ReceiveNotificationsAsEmail}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="email_security_risk" ref="email_security_risk" name="email_security_risk" checked={this.state.email_security_risk} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="email_security_risk">{this.Language.DataField.SendSecurityRiskToEmail}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="email_account_activity" ref="email_account_activity" name="email_account_activity" checked={this.state.email_account_activity} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="email_account_activity">{this.Language.DataField.SendAccountActivitiesToEmail}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="phone_security_risk" ref="phone_security_risk" name="phone_security_risk" checked={this.state.phone_security_risk} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="phone_security_risk">{this.Language.DataField.SendSecurityRiskToPhone}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="phone_account_activity" ref="phone_account_activity" name="phone_account_activity" checked={this.state.phone_account_activity} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="phone_account_activity">{this.Language.DataField.SendAccountActivitiesToPhone}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_notification_setting_button">
                                                                {this.Language.ButtonLabel.UpdateNotificationSettings}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s2">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/user/profile/${this.state.hash_user}`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default NotificationSettings;