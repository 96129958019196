import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class AddSystemModule extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addModule = this.addModule.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_module: [],
            preloader: true,
            logged_in_username: '',
            text_limit: 3,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.CreateSystemModule + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['create:modules', 'action:modules'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("module");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }


    prepareData(return_option) {



        let module_name = this.refs.module_name.value.trim().toLowerCase();
        let module_description = this.refs.module_description.value;
        let module_code = this.refs.module_code.value.trim();
        let module_number = this.refs.module_number.value.replace(/\s/g, "");
        let module_menu_position = this.refs.module_menu_position.value.replace(/\s/g, "");
        let module_url = this.refs.module_url.value.trim();


        let parent_module_id = this.refs.parent_module_id.value;
        if (parent_module_id === "Select Parent Module" && (this.refs.is_child_module.checked === true || this.refs.is_sub_module.checked === true)) parent_module_id = "";

        let is_child_module = this.refs.is_child_module.checked;
        let is_sub_module = this.refs.is_sub_module.checked;

        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;



        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.SystemModule.AddErrorTitle, [module_name, module_description, module_code, module_number, module_menu_position, module_url, module_number, module_menu_position, module_number, module_menu_position], [this.Language.DataField.ModuleName, this.Language.DataField.ModuleDescription, this.Language.DataField.ModuleCode, this.Language.DataField.ModuleNumber, this.Language.DataField.ModuleMenuPosition, this.Language.DataField.ModuleURL, this.Language.DataField.ModuleNumber, this.Language.DataField.ModuleMenuPosition, this.Language.DataField.ModuleNumber, this.Language.DataField.ModuleMenuPosition], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isNumber", "isNumber", "maxLength", "maxLength"], 0, this.state.text_limit) }, () => {

            if (this.state.error_messages === null) {

                if (parent_module_id === "Select Parent Module") parent_module_id = null;

                const userOS = this.Browser.getOS();

                //make call to database 
                const newModule = {
                    module_name: module_name,
                    module_description: module_description,
                    module_code: module_code,
                    module_number: Math.abs(module_number) || null,
                    module_menu_position: Math.abs(module_menu_position) || null,
                    is_child_module: is_child_module,
                    is_sub_module: is_sub_module,
                    module_url: module_url,
                    parent_module_id: parent_module_id,
                    status: status,
                    created_by: created_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }


                if (parent_module_id === null) newModule.parent_module_id = undefined;


                this.setState({ preloader: true });
                document.getElementById("create_module_button").setAttribute("disabled", true);

                this.addModule(newModule, return_option);

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }


    clearForm() {

        document.getElementById("add_system_module_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }


    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_module = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'module') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_module: select_field_values_module });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    addModule(newModule, return_option) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/settings/modules`,
            data: newModule
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.SystemModule.AddSuccess + newModule.module_name, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("create_module_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/settings/modules');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SystemModule.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.SystemModule.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("create_module_button").removeAttribute("disabled");

            });

    }




    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"System Modules"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.CreateSystemModule}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.SystemModules, this.Language.BreadCrumbLabel.AddSystemModule]} links={[`/settings/modules`, `/settings/modules`, `/settings/modules`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.AddANewSystemModule}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_system_module_form" ref="add_system_module_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <i className="material-icons prefix">view_quilt</i>
                                                            <input id="module_name" name="module_name" type="text" ref="module_name" />
                                                            <label htmlFor="module_name" className="">
                                                                {this.Language.DataField.ModuleName} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="module_code" name="module_code" type="text" data-length="10" ref="module_code" />
                                                            <label htmlFor="module_code" className="" data-error={this.Language.DataFieldError.MaxLength}>
                                                                {this.Language.DataField.ModuleCode} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="module_description" className="materialize-textarea" data-length="300" name="module_description" ref="module_description"></textarea>
                                                            <label htmlFor="module_description" data-error={this.Language.DataFieldError.MaxLength}>
                                                                {this.Language.DataField.ModuleDescription} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <input id="module_number" name="module_number" type="number" className="validate" data-length={this.state.text_limit} ref="module_number" />
                                                            <label htmlFor="module_number" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.ModuleNumber} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="module_menu_position" name="module_menu_position" type="number" className="validate" data-length={this.state.text_limit} ref="module_menu_position" />
                                                            <label htmlFor="module_menu_position" data-error={this.Language.DataFieldError.IntegerNumberWithMaxLength + this.state.text_limit} className="">
                                                                {this.Language.DataField.ModuleMenuPosition} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="module_url" name="module_url" type="text" ref="module_url" />
                                                            <label htmlFor="module_url" className="">
                                                                {this.Language.DataField.ModuleURL} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-parent_module_id" className="initialized" name="parent_module_id" ref="parent_module_id" >
                                                                    <option value="Select Parent Module">{this.Language.DataFieldDefault.SelectParentModule}</option>
                                                                    {this.state.select_field_values_module.map((module_value, i) => {
                                                                        return (
                                                                            module_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.ParentModule}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="is_child_module" ref="is_child_module" name="is_child_module" />
                                                                <label htmlFor="is_child_module">{this.Language.DataField.ChildModule}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s4">
                                                            <p>
                                                                <input type="checkbox" id="is_sub_module" ref="is_sub_module" name="is_sub_module" />
                                                                <label htmlFor="is_sub_module">{this.Language.DataField.SubModuleQ}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s4">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateModule}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateModule}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveNewModule.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_module_button">
                                                                {this.Language.ButtonLabel.SaveNewModule}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveNewModule.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/settings/modules`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddSystemModule;