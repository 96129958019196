import React, { Component } from 'react';
import { Bar, Bubble, Doughnut, HorizontalBar, Pie, Line, Polar, Radar, Scatter } from 'react-chartjs-2';
// import { Bar, Bubble, Doughnut, HorizontalBar, Pie, Line, Polar, Radar, Scatter, Chart, defaults } from 'react-chartjs-2';


import DataCard from './DataCard';
import DataChart from './DataChart'
import ValuesChart from './ValuesChart';
import ValuesChartVertical from './ValuesChartVertical';
import DataCardPicture from './DataCardPicture';
import DataCardCirclePicture from './DataCardCirclePicture';
import DataCardAnalytics from './DataCardAnalytics';
import DataChartAnalytics from './DataChartAnalytics';
import DataChartAnalyticsV2 from './DataChartAnalyticsV2';
import DataCollectionWithChart from './DataCollectionWithChart';
import TaskCollectionList from './TaskCollectionList';
import ProfileCard from './ProfileCard';
import MapCard from './MapCard';
import DataMapSectionCard from './DataMapSectionCard';


class DashboardLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardLayout: props.dashboardLayout || "4:DataCard__1:ValuesChart_1:ValuesChartVertical__3:DataChart",
            Lang: props.Lang,
            DetailsLabel: props.DetailsLabel,
            elements: props.elements || [],
            chart: null,
        }
    }


    componentDidMount() {
        // Chart.scales["x-axis-0"].options.ticks.autoSkip = false;
        // Chart.scales["x-axis-0"].options.ticks.stepSize = 1;

        // defaults.global.autoSkip = false;
        // defaults.global.stepSize = 1;


    }

    render() {



        let dashboard_layout_level_display = [];
        let position_counter = 0;
        // defaults.global.autoSkip = false;
        // defaults.global.stepSize = 1;

        if (this.state.dashboardLayout !== '' && this.state.dashboardLayout !== null && typeof this.state.dashboardLayout !== 'undefined') {

            let layout_levels = this.state.dashboardLayout.split("__");
            let layout_len = layout_levels.length;


            for (let i = 0; i < layout_len; i++) {

                let layout_level_objects = layout_levels[i].split("_");
                let layout_level_len = layout_level_objects.length;
                let dashboard_layout_objects_display = [];

                for (let j = 0; j < layout_level_len; j++) {

                    let layout_object = layout_level_objects[j].split(":");
                    let object_repeat_count = parseInt(layout_object[0], 10);
                    let object_name = layout_object[1];

                    for (let k = 0; k < object_repeat_count; k++) {

                        // let object_position = k + 1;
                        let dashboard_object_type = this.state.elements[position_counter].dashboard_object_type || "";
                        let element_type = this.state.elements[position_counter].element_type || '';
                        let element_type2 = '';
                        let element_data = null;
                        let element_data2 = null;
                        let object_element = null;
                        let object_element2 = null;
                        let object_element3 = null;

                        let element_data_table_header = null;
                        let element_data_table_rows = null;

                        let element_collection_values_list = null;


                        // set default values for the variables
                        let element_title = '';
                        let element_icon_name = 'language';
                        let element_total_value = '';
                        let element_sub_title = '';
                        let element_sub_category_title = '';
                        let element_lower_title = '';
                        let element_sub_category_value = '';
                        let element_card_colour = 'cyan';
                        let element_hoverable = '';
                        let element_arrow_icon_direction = '';
                        let element_show_arrow_icon = '';
                        let element_title_trending_value = '';
                        let element_title_trending_value_prefix = '';
                        let element_title_trending_value_suffix = '';
                        let element_link = '#!';
                        let element_button_title = '';
                        let element_text_colour = 'purple';
                        let element_sub_title_text_colour = 'grey';
                        let element_main_value = '';
                        let element_main_value_prefix = '';
                        let element_main_value_suffix = '';
                        let element_sub_value = '';
                        let element_sub_value_prefix = '';
                        let element_sub_value_suffix = '';
                        let element_sub_value_text_colour = '';
                        let element_value_is_decimal = true;
                        let element_value_decimal_places = 2;
                        let element_sub_value_is_decimal = false;
                        let element_sub_value_decimal_places = 0;
                        let element_badge_colour = 'gradient-45deg-light-blue-cyan';
                        let element_badge_value = '';
                        let element_badge_value_prefix = '';
                        let element_badge_value_suffix = '';
                        let element_badge_status = 'new';
                        let element_src_image = '';
                        let element_src_image_alt = '';
                        let element_paragraph_text = '';
                        let element_button_value = '';
                        let element_button_value_prefix = '';
                        let element_button_value_suffix = '';
                        let element_button_colour = '';

                        let element_title2 = '';
                        let element_filter_colour = '';
                        let element_card_reveal_title = '';
                        let element_no_record_available = '';



                        // Use this object_position variable to link the chart, card, stream or collection object to the
                        // Dashboard layout position



                        switch (object_name) {
                            case 'DataCard':
                                element_icon_name = this.state.elements[position_counter].icon_name || 'language';
                                element_title = this.state.elements[position_counter].title || '';
                                element_total_value = this.state.elements[position_counter].total_value;
                                element_value_is_decimal = this.state.elements[position_counter].value_is_decimal;
                                element_value_decimal_places = this.state.elements[position_counter].value_decimal_places;
                                element_sub_category_title = this.state.elements[position_counter].sub_category_title || '';
                                element_sub_category_value = this.state.elements[position_counter].sub_category_value;
                                element_sub_value_is_decimal = this.state.elements[position_counter].sub_value_is_decimal;
                                element_sub_value_decimal_places = this.state.elements[position_counter].sub_value_decimal_places;
                                element_card_colour = this.state.elements[position_counter].card_colour || 'cyan';
                                element_text_colour = this.state.elements[position_counter].text_colour || 'white';
                                element_hoverable = this.state.elements[position_counter].hoverable || true;

                                dashboard_layout_objects_display.push(<DataCard key={'object:' + j + ':' + k} iconName={element_icon_name} title={element_title} totalValue={element_total_value} valueIsDecimal={element_value_is_decimal} valueDecimalPlaces={element_value_decimal_places} Lang={this.state.Lang} subCategoryTitle={element_sub_category_title} subCategoryValue={element_sub_category_value} subValueIsDecimal={element_sub_value_is_decimal} subValueDecimalPlaces={element_sub_value_decimal_places} hoverable={element_hoverable} cardColour={element_card_colour} textColour={element_text_colour} />);
                                break;



                            case 'ValuesChart':
                                element_data = this.state.elements[position_counter].data || {};

                                element_title = this.state.elements[position_counter].title || '';
                                element_arrow_icon_direction = this.state.elements[position_counter].arrow_icon_direction || '';
                                element_show_arrow_icon = this.state.elements[position_counter].show_arrow_icon || '';
                                element_title_trending_value = this.state.elements[position_counter].title_trending_value || '';
                                element_title_trending_value_prefix = this.state.elements[position_counter].title_trending_value_prefix || '';
                                element_title_trending_value_suffix = this.state.elements[position_counter].title_trending_value_suffix || '';
                                element_text_colour = this.state.elements[position_counter].text_colour || 'purple';
                                element_link = this.state.elements[position_counter].link || '#!';


                                if (dashboard_object_type === 'ValuesChart' && element_type === 'line') object_element = <Line data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'bar') object_element = <Bar data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'bubble') object_element = <Bubble data={element_data} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'doughnut') object_element = <Doughnut data={element_data} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'horizontalbar') object_element = <HorizontalBar data={element_data} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'pie') object_element = <Pie data={element_data} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'polar') object_element = <Polar data={element_data} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'radar') object_element = <Radar data={element_data} />;
                                if (dashboard_object_type === 'ValuesChart' && element_type === 'scatter') object_element = <Scatter data={element_data} />;

                                if (object_element !== null && typeof object_element !== 'undefined') dashboard_layout_objects_display.push(<ValuesChart key={'object:' + j + ':' + k} title={element_title} link={element_link} showArrowIcon={element_show_arrow_icon} arrowIconDirection={element_arrow_icon_direction} titleTrendingValue={element_title_trending_value} buttonTitle={this.state.DetailsLabel} chartID={element_type + ':' + j + ':' + k} chartElement={object_element} titleTrendingValuePrefix={element_title_trending_value_prefix} titleTrendingValueSuffix={element_title_trending_value_suffix} textColour={element_text_colour} />);
                                break;



                            case 'ValuesChartVertical':

                                element_data = this.state.elements[position_counter].data || {};

                                element_icon_name = this.state.elements[position_counter].icon_name || 'language';
                                element_title = this.state.elements[position_counter].title || '';
                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                element_title_trending_value = this.state.elements[position_counter].title_trending_value || '';
                                element_title_trending_value_prefix = this.state.elements[position_counter].title_trending_value_prefix || '';
                                element_title_trending_value_suffix = this.state.elements[position_counter].title_trending_value_suffix || '';
                                element_lower_title = this.state.elements[position_counter].lower_title || ''
                                element_arrow_icon_direction = this.state.elements[position_counter].arrow_icon_direction || '';
                                element_show_arrow_icon = this.state.elements[position_counter].show_arrow_icon || '';
                                element_button_title = this.state.elements[position_counter].button_title || '';
                                element_text_colour = this.state.elements[position_counter].text_colour || 'purple';
                                element_sub_title_text_colour = this.state.elements[position_counter].sub_title_text_colour || 'grey';
                                element_link = this.state.elements[position_counter].link || '#!';


                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'line') object_element = <Line data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'bar') object_element = <Bar data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'bubble') object_element = <Bubble data={element_data} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'doughnut') object_element = <Doughnut data={element_data} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'horizontalbar') object_element = <HorizontalBar data={element_data} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'pie') object_element = <Pie data={element_data} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'polar') object_element = <Polar data={element_data} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'radar') object_element = <Radar data={element_data} />;
                                if (dashboard_object_type === 'ValuesChartVertical' && element_type === 'scatter') object_element = <Scatter data={element_data} />;


                                if (object_element !== null && typeof object_element !== 'undefined') dashboard_layout_objects_display.push(<ValuesChartVertical key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} title={element_title} subTitle={element_sub_title} titleTrendingValue={element_title_trending_value} titleTrendingValuePrefix={element_title_trending_value_prefix} titleTrendingValueSuffix={element_title_trending_value_suffix} lowerTitle={element_lower_title} showArrowIcon={element_show_arrow_icon} arrowIconDirection={element_arrow_icon_direction} buttonTitle={element_button_title} chartElement={object_element} textColour={element_text_colour} subTitleTextColour={element_sub_title_text_colour} mainValueSuffix={element_main_value_suffix} subValuePrefix={element_sub_value_prefix} subValueSuffix={element_sub_value_suffix} badgeValuePrefix={element_badge_value_prefix} badgeValueSuffix={element_badge_value_suffix} badgeStatus={element_badge_status} link={element_link} />);
                                break;




                            case 'DataChart':

                                element_data = this.state.elements[position_counter].data || {};

                                element_title = this.state.elements[position_counter].title || '';
                                element_main_value = this.state.elements[position_counter].main_value;
                                element_main_value_prefix = this.state.elements[position_counter].main_value_prefix || '';
                                element_main_value_suffix = this.state.elements[position_counter].main_value_suffix || '';
                                element_sub_value = this.state.elements[position_counter].sub_value;
                                element_sub_value_prefix = this.state.elements[position_counter].sub_value_prefix || '';
                                element_sub_value_suffix = this.state.elements[position_counter].sub_value_suffix || '';
                                element_sub_value_text_colour = this.state.elements[position_counter].sub_value_text_colour || '';
                                element_value_is_decimal = this.state.elements[position_counter].value_is_decimal || true;
                                element_value_decimal_places = this.state.elements[position_counter].value_decimal_places || 2;
                                element_sub_value_is_decimal = this.state.elements[position_counter].sub_value_is_decimal || false;
                                element_sub_value_decimal_places = this.state.elements[position_counter].sub_value_decimal_places || 0;
                                element_badge_colour = this.state.elements[position_counter].badge_colour || 'gradient-45deg-light-blue-cyan';
                                element_badge_value = this.state.elements[position_counter].badge_value || '';
                                element_badge_value_prefix = this.state.elements[position_counter].badge_value_prefix || '';
                                element_badge_value_suffix = this.state.elements[position_counter].badge_value_suffix || '';
                                element_badge_status = this.state.elements[position_counter].badge_status || 'new';
                                element_link = this.state.elements[position_counter].link || '#!';


                                if (dashboard_object_type === 'DataChart' && element_type === 'line') object_element = <Line data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'bar') object_element = <Bar data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'bubble') object_element = <Bubble data={element_data} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'doughnut') object_element = <Doughnut data={element_data} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'horizontalbar') object_element = <HorizontalBar data={element_data} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'pie') object_element = <Pie data={element_data} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'polar') object_element = <Polar data={element_data} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'radar') object_element = <Radar data={element_data} />;
                                if (dashboard_object_type === 'DataChart' && element_type === 'scatter') object_element = <Scatter data={element_data} />;

                                if (object_element !== null && typeof object_element !== 'undefined') dashboard_layout_objects_display.push(<DataChart key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} title={element_title} Lang={this.state.Lang} valueIsDecimal={element_value_is_decimal} valueDecimalPlaces={element_value_decimal_places} badgeValue={element_badge_value} badgeValuePrefix={element_badge_value_prefix} badgeValueSuffix={element_badge_value_suffix} badgeColour={element_badge_colour} badgeStatus={element_badge_status} subValue={element_sub_value} subValueTextColour={element_sub_value_text_colour} mainValue={element_main_value} subValueIsDecimal={element_sub_value_is_decimal} subValueDecimalPlaces={element_sub_value_decimal_places} mainValuePrefix={element_main_value_prefix} chartElement={object_element} link={element_link} />);
                                break;



                            case 'DataCardPicture':

                                element_title = this.state.elements[position_counter].title || '';

                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                element_src_image = this.state.elements[position_counter].src_image || '';
                                element_src_image_alt = this.state.elements[position_counter].src_image_alt || '';
                                element_paragraph_text = this.state.elements[position_counter].paragraph_text || '';
                                element_button_value = this.state.elements[position_counter].button_value || '';
                                element_button_value_prefix = this.state.elements[position_counter].button_value_prefix || '';
                                element_button_value_suffix = this.state.elements[position_counter].button_value_suffix || '';
                                element_button_colour = this.state.elements[position_counter].button_colour || '';

                                dashboard_layout_objects_display.push(<DataCardPicture key={'object:' + j + ':' + k} title={element_title} subTitle={element_sub_title} srcImage={element_src_image} srcImageAlt={element_src_image_alt} paragraphText={element_paragraph_text} buttonValue={element_button_value} buttonValuePrefix={element_button_value_prefix} buttonValueSuffix={element_button_value_suffix} buttonColour={element_button_colour} />);
                                break;



                            case 'DataCardCirclePicture':

                                element_title = this.state.elements[position_counter].title || '';
                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                element_text_colour = this.state.elements[position_counter].text_colour || '';
                                element_card_colour = this.state.elements[position_counter].card_colour || '';
                                element_src_image = this.state.elements[position_counter].src_image || '';
                                element_src_image_alt = this.state.elements[position_counter].src_image_alt || '';


                                dashboard_layout_objects_display.push(<DataCardCirclePicture key={'object:' + j + ':' + k} title={element_title} subTitle={element_sub_title} cardColour={element_card_colour} textColour={element_text_colour} srcImage={element_src_image} srcImageAlt={element_src_image_alt} />);
                                break;



                            case 'DataCardAnalytics':

                                element_data = this.state.elements[position_counter].data || {};

                                element_title = this.state.elements[position_counter].title || '';
                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                element_icon_name = this.state.elements[position_counter].icon_name || 'language';
                                element_text_colour = this.state.elements[position_counter].text_colour || '';
                                element_card_colour = this.state.elements[position_counter].card_colour || '';
                                element_main_value = this.state.elements[position_counter].main_value;
                                element_main_value_prefix = this.state.elements[position_counter].main_value_prefix || '';
                                element_main_value_suffix = this.state.elements[position_counter].main_value_suffix || '';
                                element_sub_value = this.state.elements[position_counter].sub_value;
                                element_sub_value_prefix = this.state.elements[position_counter].sub_value_prefix || '';
                                element_sub_value_suffix = this.state.elements[position_counter].sub_value_suffix || '';
                                element_arrow_icon_direction = this.state.elements[position_counter].arrow_icon_direction || '';
                                element_show_arrow_icon = this.state.elements[position_counter].show_arrow_icon || '';

                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'line') object_element = <Line data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'bar') object_element = <Bar data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'bubble') object_element = <Bubble data={element_data} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'doughnut') object_element = <Doughnut data={element_data} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'horizontalbar') object_element = <HorizontalBar data={element_data} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'pie') object_element = <Pie data={element_data} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'polar') object_element = <Polar data={element_data} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'radar') object_element = <Radar data={element_data} />;
                                if (dashboard_object_type === 'DataCardAnalytics' && element_type === 'scatter') object_element = <Scatter data={element_data} />;


                                if (object_element !== null && typeof object_element !== 'undefined') dashboard_layout_objects_display.push(<DataCardAnalytics key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} title={element_title} subTitle={element_sub_title} iconName={element_icon_name} mainValue={element_main_value} mainValuePrefix={element_main_value_prefix} mainValueSuffix={element_main_value_suffix} showArrowIcon={element_show_arrow_icon} arrowIconDirection={element_arrow_icon_direction} subValue={element_sub_value} subValuePrefix={element_sub_value_prefix} subValueSuffix={element_sub_value_suffix} cardColour={element_card_colour} textColour={element_text_colour} chartElement={object_element} />);
                                break;



                            case 'DataChartAnalytics':

                                element_data = this.state.elements[position_counter].data || {};
                                element_data2 = this.state.elements[position_counter].data2 || {};
                                let element_data3 = this.state.elements[position_counter].data3 || {};

                                element_type2 = this.state.elements[position_counter].element_type2 || '';
                                let element_type3 = this.state.elements[position_counter].element_type3 || '';


                                element_data_table_header = this.state.elements[position_counter].data_table_header || [];
                                element_data_table_rows = this.state.elements[position_counter].data_table_rows || [[]];

                                element_title = this.state.elements[position_counter].title || '';
                                element_main_value = this.state.elements[position_counter].main_value;
                                element_main_value_prefix = this.state.elements[position_counter].main_value_prefix || '';
                                element_main_value_suffix = this.state.elements[position_counter].main_value_suffix || '';
                                let element_main_value2 = this.state.elements[position_counter].main_value2;
                                let element_main_value_prefix2 = this.state.elements[position_counter].main_value_prefix2 || '';
                                let element_main_value_suffix2 = this.state.elements[position_counter].main_value_suffix2 || '';
                                element_sub_value = this.state.elements[position_counter].sub_value;
                                element_sub_value_prefix = this.state.elements[position_counter].sub_value_prefix || '';
                                element_sub_value_suffix = this.state.elements[position_counter].sub_value_suffix || '';
                                let element_chart_text2 = this.state.elements[position_counter].chart_text2 || '';
                                element_text_colour = this.state.elements[position_counter].text_colour || '';
                                element_card_colour = this.state.elements[position_counter].card_colour || '';
                                element_filter_colour = this.state.elements[position_counter].filter_colour || '';
                                element_arrow_icon_direction = this.state.elements[position_counter].arrow_icon_direction || '';
                                element_show_arrow_icon = this.state.elements[position_counter].show_arrow_icon || '';
                                let element_option1_text = this.state.elements[position_counter].option1_text || '';
                                let element_option2_text = this.state.elements[position_counter].option2_text || '';
                                element_card_reveal_title = this.state.elements[position_counter].card_reveal_title || '';


                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'line') object_element = <Line data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'bar') object_element = <Bar data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'bubble') object_element = <Bubble data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'doughnut') object_element = <Doughnut data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'horizontalbar') object_element = <HorizontalBar data={element_data} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'pie') object_element = <Pie data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'polar') object_element = <Polar data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'radar') object_element = <Radar data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type === 'scatter') object_element = <Scatter data={element_data} />;

                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'line') object_element2 = <Line data={element_data2} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'bar') object_element2 = <Bar data={element_data2} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'bubble') object_element2 = <Bubble data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'doughnut') object_element2 = <Doughnut data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'horizontalbar') object_element2 = <HorizontalBar data={element_data2} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'pie') object_element2 = <Pie data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'polar') object_element2 = <Polar data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'radar') object_element2 = <Radar data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type2 === 'scatter') object_element2 = <Scatter data={element_data2} />;

                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'line') object_element3 = <Line data={element_data3} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'bar') object_element3 = <Bar data={element_data3} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'bubble') object_element3 = <Bubble data={element_data3} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'doughnut') object_element3 = <Doughnut data={element_data3} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'horizontalbar') object_element3 = <HorizontalBar data={element_data3} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'pie') object_element3 = <Pie data={element_data3} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'polar') object_element3 = <Polar data={element_data3} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'radar') object_element3 = <Radar data={element_data3} />;
                                if (dashboard_object_type === 'DataChartAnalytics' && element_type3 === 'scatter') object_element3 = <Scatter data={element_data3} />;


                                if (object_element !== null && typeof object_element !== 'undefined' && object_element2 !== null && typeof object_element2 !== 'undefined' && object_element3 !== null && typeof object_element3 !== 'undefined') {
                                    dashboard_layout_objects_display.push(<DataChartAnalytics key={'object:' + j + ':' + k} chartID1={element_type + ':' + j + ':' + k} chartID2={element_type2 + ':' + j + ':' + k} chartID3={element_type3 + ':' + j + ':' + k} chartElement1={object_element} chartElement2={object_element2} chartElement3={object_element3} title={element_title} dataTableHeader={element_data_table_header} dataTableRows={element_data_table_rows} mainValue={element_main_value} mainValuePrefix={element_main_value_prefix} mainValueSuffix={element_main_value_suffix} mainValue2={element_main_value2} mainValuePrefix2={element_main_value_prefix2} mainValueSuffix2={element_main_value_suffix2} subValue={element_sub_value} subValuePrefix={element_sub_value_prefix} subValueSuffix={element_sub_value_suffix} chartText2={element_chart_text2} cardColour={element_card_colour} textColour={element_text_colour} filterColour={element_filter_colour} showArrowIcon={element_show_arrow_icon} arrowIconDirection={element_arrow_icon_direction} Option1Text={element_option1_text} Option2Text={element_option2_text} cardRevealTitle={element_card_reveal_title} />);
                                }
                                break;



                            case 'DataChartAnalyticsV2':


                                element_data = this.state.elements[position_counter].data || {};
                                element_data2 = this.state.elements[position_counter].data2 || {};

                                element_type2 = this.state.elements[position_counter].element_type2 || '';

                                element_data_table_header = this.state.elements[position_counter].data_table_header || [];
                                element_data_table_rows = this.state.elements[position_counter].data_table_rows || [[]];

                                element_title = this.state.elements[position_counter].title || '';
                                element_title2 = this.state.elements[position_counter].title2 || '';
                                element_text_colour = this.state.elements[position_counter].text_colour || '';
                                element_card_colour = this.state.elements[position_counter].card_colour || '';
                                element_filter_colour = this.state.elements[position_counter].filter_colour || '';
                                element_card_reveal_title = this.state.elements[position_counter].card_reveal_title || '';


                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'line') object_element = <Line data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'bar') object_element = <Bar data={element_data} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'bubble') object_element = <Bubble data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'doughnut') object_element = <Doughnut data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'horizontalbar') object_element = <HorizontalBar data={element_data} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'pie') object_element = <Pie data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'polar') object_element = <Polar data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'radar') object_element = <Radar data={element_data} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type === 'scatter') object_element = <Scatter data={element_data} />;

                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'line') object_element2 = <Line data={element_data2} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'bar') object_element2 = <Bar data={element_data2} options={{ scales: { xAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'bubble') object_element2 = <Bubble data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'doughnut') object_element2 = <Doughnut data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'horizontalbar') object_element2 = <HorizontalBar data={element_data2} options={{ scales: { yAxes: [{ ticks: { autoSkip: false } }] } }} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'pie') object_element2 = <Pie data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'polar') object_element2 = <Polar data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'radar') object_element2 = <Radar data={element_data2} />;
                                if (dashboard_object_type === 'DataChartAnalyticsV2' && element_type2 === 'scatter') object_element2 = <Scatter data={element_data2} />;


                                if (object_element !== null && typeof object_element !== 'undefined' && object_element2 !== null && typeof object_element2 !== 'undefined') {
                                    dashboard_layout_objects_display.push(<DataChartAnalyticsV2 key={'object:' + j + ':' + k} title={element_title} title2={element_title2} cardColour={element_card_colour} textColour={element_text_colour} filterColour={element_filter_colour} cardRevealTitle={element_card_reveal_title} chartElement1={object_element} chartElement2={object_element2} dataTableHeader={element_data_table_header} dataTableRows={element_data_table_rows} />);
                                }

                                break;



                            case 'DataCollectionWithChart':

                                element_collection_values_list = this.state.elements[position_counter].collection_values_list || [];

                                element_title = this.state.elements[position_counter].title || '';
                                element_icon_name = this.state.elements[position_counter].icon_name || 'language';
                                let element_icon_colour = this.state.elements[position_counter].icon_colour || '';
                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                element_no_record_available = this.state.elements[position_counter].no_record_available || '';

                                if (element_collection_values_list !== null && typeof element_collection_values_list !== 'undefined') dashboard_layout_objects_display.push(<DataCollectionWithChart key={'object:' + j + ':' + k} collectionID={element_type + ':' + j + ':' + k} collectionValuesList={element_collection_values_list} title={element_title} iconName={element_icon_name} iconColour={element_icon_colour} subTitle={element_sub_title} textColour={element_text_colour} noRecordAvailable={element_no_record_available} />);
                                break;



                            case 'TaskCollectionList':

                                element_collection_values_list = this.state.elements[position_counter].collection_values_list || [];

                                element_title = this.state.elements[position_counter].title || '';
                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                element_no_record_available = this.state.elements[position_counter].no_record_available || '';
                                element_card_colour = this.state.elements[position_counter].card_colour || '';
                                element_text_colour = this.state.elements[position_counter].text_colour || '';
                                let element_can_edit_check_boxes = this.state.elements[position_counter].can_edit_check_boxes || '';

                                if (element_collection_values_list !== null && typeof element_collection_values_list !== 'undefined') dashboard_layout_objects_display.push(<TaskCollectionList key={'object:' + j + ':' + k} collectionID={element_type + ':' + j + ':' + k} collectionValuesList={element_collection_values_list} title={element_title} subTitle={element_sub_title} cardColour={element_card_colour} textColour={element_text_colour} noRecordAvailable={element_no_record_available} canEditCheckBoxes={element_can_edit_check_boxes} />);
                                break;



                            case 'ProfileCard':


                                let element_profile_name = this.state.elements[position_counter].profile_name || '';
                                let element_profile_title = this.state.elements[position_counter].profile_title || '';
                                let element_profile_phone = this.state.elements[position_counter].profile_phone || '';
                                let element_profile_email = this.state.elements[position_counter].profile_email || '';
                                element_icon_name = this.state.elements[position_counter].icon_name || 'language';
                                let element_detail_title = this.state.elements[position_counter].detail_title || '';

                                let element_date_of_registration = this.state.elements[position_counter].date_of_registration || '';
                                let element_role = this.state.elements[position_counter].role || '';
                                let element_src_back_image = this.state.elements[position_counter].src_back_image || '';
                                let element_src_back_image_alt = this.state.elements[position_counter].src_back_image_alt || '';
                                element_src_image = this.state.elements[position_counter].src_image || '';
                                element_src_image_alt = this.state.elements[position_counter].src_image_alt || '';

                                let element_image_colour = this.state.elements[position_counter].image_colour || '';
                                element_button_colour = this.state.elements[position_counter].button_colour || '';


                                dashboard_layout_objects_display.push(<ProfileCard key={'object:' + j + ':' + k} profileID={element_type + ':' + j + ':' + k} profileName={element_profile_name} profileTitle={element_profile_title} profilePhone={element_profile_phone} profileEmail={element_profile_email} iconName={element_icon_name} detailTitle={element_detail_title} dateOfRegistration={element_date_of_registration} role={element_role} srcBackImage={element_src_back_image} srcBackImageAlt={element_src_back_image_alt} srcImage={element_src_image} srcImageAlt={element_src_image_alt} imageColour={element_image_colour} buttonColour={element_button_colour} />);
                                break;




                            case 'MapCard':

                                element_title = this.state.elements[position_counter].title || '';
                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                let element_details_text = this.state.elements[position_counter].details_text || '';
                                let element_main_text_colour = this.state.elements[position_counter].main_text_colour || '';
                                let element_details_text_colour = this.state.elements[position_counter].details_text_colour || '';
                                element_button_colour = this.state.elements[position_counter].button_colour || '';

                                let element_map_card_type = this.state.elements[position_counter].map_card_type || '';
                                let element_address = this.state.elements[position_counter].address || '';
                                let element_phone = this.state.elements[position_counter].phone || '';
                                let element_email = this.state.elements[position_counter].email || '';

                                let element_latitude = this.state.elements[position_counter].latitude || '';
                                let element_longitude = this.state.elements[position_counter].longitude || '';


                                dashboard_layout_objects_display.push(<MapCard key={'object:' + j + ':' + k} buttonColour={element_button_colour} mainTextColour={element_main_text_colour} detailsTextColour={element_details_text_colour} title={element_title} subTitle={element_sub_title} detailsText={element_details_text} type={element_map_card_type} address={element_address} phone={element_phone} email={element_email} latitude={element_latitude} longitude={element_longitude} />);
                                break;



                            case 'DataMapSectionCard':

                                element_data = this.state.elements[position_counter].data || {};

                                element_title = this.state.elements[position_counter].title || '';
                                element_sub_title = this.state.elements[position_counter].sub_title || '';
                                let element_chart_title = this.state.elements[position_counter].chart_title || '';
                                let element_chart_sub_title = this.state.elements[position_counter].chart_sub_title || '';
                                let element_map_height = this.state.elements[position_counter].map_height || '';
                                let element_map_width = this.state.elements[position_counter].map_width || '';
                                element_card_colour = this.state.elements[position_counter].card_colour || '';
                                element_text_colour = this.state.elements[position_counter].text_colour || '';
                                element_sub_title_text_colour = this.state.elements[position_counter].sub_title_text_colour || 'grey';


                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'line') object_element = <Line data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'bar') object_element = <Bar data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'bubble') object_element = <Bubble data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'doughnut') object_element = <Doughnut data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'horizontalbar') object_element = <HorizontalBar data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'pie') object_element = <Pie data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'polar') object_element = <Polar data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'radar') object_element = <Radar data={element_data} />;
                                if (dashboard_object_type === 'DataMapSectionCard' && element_type === 'scatter') object_element = <Scatter data={element_data} />;

                                if (object_element !== null && typeof object_element !== 'undefined') dashboard_layout_objects_display.push(<DataMapSectionCard key={'object:' + j + ':' + k} chartID={element_type + ':' + j + ':' + k} chartElement={object_element} title={element_title} subTitle={element_sub_title} chartTitle={element_chart_title} chartSubTitle={element_chart_sub_title} mapHeight={element_map_height} mapWidth={element_map_width} cardColour={element_card_colour} textColour={element_text_colour} subTextColour={element_sub_title_text_colour} />);
                                break;




                            case 'Picture':
                                break;

                            case 'SocialStream':
                                break;

                            case 'NotificationStream':
                                break;

                            case 'MessageStream':
                                break;

                            case 'TaskStream':
                                break;

                            case 'EventStream':
                                break;

                            case 'CustomData':
                                break;

                            default:

                        }


                        position_counter = position_counter + 1;

                    }

                }

                dashboard_layout_level_display.push(<div key={'level:' + i} className="row">{dashboard_layout_objects_display}</div>);

            }





            return (
                // <!--Dashboard Layout start-->
                <div className="">
                    {dashboard_layout_level_display.map((layout_value, i) => {
                        return (
                            layout_value
                        )
                    })}
                </div>
                // <!--Dashboard Layout end-->
            )

        } else {

            return (
                // <!--Dashboard Layout start-->
                null
                // <!--Dashboard Layout end-->
            )

        }


    }
}

export default DashboardLayout;