import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');

class ExporterDocuments extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.getExportersDocuments = this.getExportersDocuments.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            exporterdocumentItems: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 100,
            paginationItem: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            exporterdocumentsDataItems: null,
            exporter_document_create: null,
            exporter_document_edit: null,
            hash_organisation: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {


    }

    componentDidMount() {
        document.title = this.Language.PageTitle.Documents + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();


        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:document', 'action:document'], 'view') === false || this.state.hash_organisation !== this.props.match.params.id) {
            this.props.history.replace(default_home);
        } else {
            this.getAccessScopes();
            this.getExportersDocuments(this.state.currentNumberOfRecords);
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                hash_organisation: user_profile.organisation,
                scopes: user_profile.scopes
            }, () => {
                this.checkAccessRight();
            });
        }

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:document' || this.state.scopes['views'][i] === 'action:document') {
                    this.setState({ exporter_document_create: true });
                }
                if (this.state.scopes['views'][i] === 'update:document' || this.state.scopes['views'][i] === 'action:document') {
                    this.setState({ exporter_document_edit: true });
                }
            }
        };


    }



    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            exporterdocumentsDataItems: null,
            paginationItem: null,
            exporterdocumentItems: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getExportersDocuments(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }




    getExportersDocuments(currentNumberOfRecords) {

        let exporterId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/file/documents/exporter/${exporterId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {


                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ exporterdocumentItems: response.data.data }, () => {
                    

                    this.setState({
                        exporterdocumentsDataItems: <DataTable tableName={''}
                            fields={['document_type', 'document_name', 'nxp_number', 'file_size', 'business_year', 'export_year', 'uploaded_by', 'is_approved', 'status']}
                            fieldDataTypes={['String', 'String', 'String', 'Number', 'Date-Year', 'Date-Year', 'String', 'Boolean', 'Status']}
                            fieldDecimalPlaces={[0, 0, 0, 0, 0, 0, 0, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.DocumentType, this.Language.DataTableHeader.Document, this.Language.DataTableHeader.NXPNumber, this.Language.DataTableHeader.FileSize, this.Language.DataTableHeader.BusinessYear, this.Language.DataTableHeader.ExportYear, this.Language.DataTableHeader.UploadedBy, this.Language.DataTableHeader.Approved, this.Language.DataTableHeader.Status]}
                            data={this.state.exporterdocumentItems}
                            sortType={"ascending"} sortPosition={1} thStyle={[140, 485, 100, 50, 70, 70, 50, 50, 70]}
                            link={`/exporter/${exporterId}/document/`} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={this.state.exporter_document_edit} linkToBlankTarget={true} linkToBlankTargetToken={this.Auth.getToken()} download={true} downloadFileName={this.Language.DownloadFileName.ExportersDocuments} emptyMessage={this.Language.EmptyMessage.ExportersDocuments}
                            downloadFields={['exporter_name', 'document_type', 'document_code', 'document_title', 'document_name', 'document_category', 'nxp_number', 'file_size', 'business_year', 'export_year', 'file_extension', 'uploaded_by', 'is_approved', 'status']} />
                    })


                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });
                })

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    render() {

        let exporterId = this.props.match.params.id;

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Documents"} active_menu_level_2={"Documents"} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.Documents}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ExporterProfile, this.Language.BreadCrumbLabel.Documents]} links={[`/`, `/exporter/profile/${exporterId}`, `/exporter/${exporterId}/documents`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                { /* {(this.state.exporter_document_create) &&   <Link className="waves-effect waves-light btn right" to={`/exporter/${exporterId}/documents/add`}><i className="material-icons left">library_add</i>{this.Language.ButtonLabel.UploadExporterDocument}</Link>} */}
                                <br />
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.exporterdocumentsDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default ExporterDocuments;