import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';




class DeviceSettings extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getDeviceSetting = this.getDeviceSetting.bind(this);
        this.state = {
            username: '',
            hash_id: '',
            send_notification_on_new_device_use: false,
            send_email_on_new_device_use: false,
            send_sms_on_new_device_use: false,
            status: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_user: ''
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.DeviceSettings + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

    }



    componentDidUpdate(){
       
    }



    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile){
            this.setState({logged_in_username: user_profile.username,
                hash_user: user_profile.user}, () => {
                this.checkAccessRight();
            });
            
        }

    }


    checkAccessRight(){

        if ( (this.Auth.checkAccessScope(['read:device', 'action:device', 'read:user'], 'view') === false || this.state.hash_user !== this.props.match.params.id) ){
             this.props.history.replace(`/user/profile/${this.state.hash_user}`);   
        } else {

            this.getDeviceSetting();
        };
 
     }





     getDeviceSetting(){


        axios.get(`${this.apiURL}/api/v1/settings/device?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    hash_id: response.data.data[0].hash_id,
                    send_notification_on_new_device_use: (response.data.data[0].send_notification_on_new_device_use === true || response.data.data[0].send_notification_on_new_device_use === 'true')? true : false || false,
                    send_email_on_new_device_use: (response.data.data[0].send_email_on_new_device_use === true || response.data.data[0].send_email_on_new_device_use === 'true')? true : false || false,
                    send_sms_on_new_device_use: (response.data.data[0].send_sms_on_new_device_use === true || response.data.data[0].send_sms_on_new_device_use === 'true')? true : false || false,
                    status: response.data.data[0].status || '',
                });



                this.setState({result: response.data.status}, () => {
                })

                this.setState({message: response.data.message}, () => {
                })

                this.setState({preloader: null});
                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx


                    if(error.response.data.message[0].msg){

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }
                    

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                } else {
                  // Something happened in setting up the request that triggered an Error
                }

                this.setState({preloader: null});
              });

    }






    render(){

        let userId = this.props.match.params.id;
        

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Device Settings"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.DeviceSettings}`} breadcrumbs={[this.Language.BreadCrumbLabel.Profile, this.Language.BreadCrumbLabel.DeviceSettings]} links={[`/user/profile/${userId}`, `/settings/device/${this.state.hash_user}`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">


                {this.state.preloader   &&  <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <div className="row">
                                                <div className="card white col s6">
                                                    <div className="card-content teal-text">
                                                        <span className="card-title">{this.Language.PageLabel.DeviceSettings}<i className="material-icons right">devices</i></span>
                                                        <br/>
                                                        <div className="divider"></div>
                                                        <br/>
                                                        <p>{this.Language.DataField.SendNotificationOnNewDeviceLogins}: <b>{this.state.send_notification_on_new_device_use !== null && typeof this.state.send_notification_on_new_device_use !== 'undefined' ?  ((this.state.send_notification_on_new_device_use === 'true') || (this.state.send_notification_on_new_device_use === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b> </p>
                                                        <p>{this.Language.DataField.SendEmailOnNewDeviceLogins}: <b>{this.state.send_email_on_new_device_use !== null && typeof this.state.send_email_on_new_device_use !== 'undefined' ?  ((this.state.send_email_on_new_device_use === 'true') || (this.state.send_email_on_new_device_use === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b> </p>
                                                        <p>{this.Language.DataField.SendSMSOnNewDeviceLogins}: <b>{this.state.send_sms_on_new_device_use !== null && typeof this.state.send_sms_on_new_device_use !== 'undefined' ?  ((this.state.send_sms_on_new_device_use === 'true') || (this.state.send_sms_on_new_device_use === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b> </p>
                                                        <p>{this.Language.DataField.Status}: <b>{this.state.status !== null && typeof this.state.status !== 'undefined' ?  ((this.state.status === 'Active') ?  this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null }</b> </p>
                                                    </div>
                                                </div>
                                                {/* ::after */}
                                            </div>
                                    </div>
                                {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]  
        )
    }
}

export default DeviceSettings;