import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import PaginationGetDataLimit from '../components/PaginationGetDataLimit';
import URLService from '../modules/URLService';

import axios from 'axios';

const $ = require('jquery');

class Exporters extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getExporters = this.getExporters.bind(this);
        this.getMoreRecords = this.getMoreRecords.bind(this);
        this.state = {
            exporters: null,
            numberOfRecords: 0,
            currentNumberOfRecords: 300,
            paginationItem: null,
            result: '',
            message: '',
            token: '',
            preloader: true,
            exporterDataItems: null,
            exporters_create: null,
            exporters_edit: null,
            scopes: {},
            paginationGetMoreDataButton: null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();

    }


    componentWillMount() {
        this.checkAccessRight();
        this.getProfile();
    }

    componentDidMount() {
        document.title = this.Language.PageTitle.Exporters + ' - ' + this.Language.EEGWebPortal;
        // this.checkAccessRight();
        this.getAccessScopes();

        this.getExporters(this.state.currentNumberOfRecords);
        this.setState({
            paginationGetMoreDataButton: <li className='input-field' onClick={this.getMoreRecords}>
                <a href={"#!"} className="waves-effect waves-teal teal-text">{this.Language.Pagination.GetData}<i className="material-icons right">refresh</i></a>
            </li>
        })

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:exporters', 'action:exporters'], 'view') === false) {
            this.props.history.replace(default_home);
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ scopes: user_profile.scopes });
        }

    }


    getAccessScopes() {

        // Check the views scope
        if (this.state.scopes['views']) {
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'create:exporters' || this.state.scopes['views'][i] === 'action:exporters') {
                    this.setState({ exporters_create: true });
                }
                if (this.state.scopes['views'][i] === 'update:exporters' || this.state.scopes['views'][i] === 'action:exporters') {
                    this.setState({ exporters_edit: true });
                }
            }
        };


    }


    getMoreRecords() {

        let currentNumberOfRecords = $('#current_number_of_records').val();

        this.setState({
            exporterDataItems: null,
            paginationItem: null,
            exporters: null,
            currentNumberOfRecords: currentNumberOfRecords,
            preloader: true
        });

        this.getExporters(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input


    }


    getExporters(currentNumberOfRecords) {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/exporters?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {

                this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

                this.setState({ exporters: response.data.data }, () => {

                    this.setState({
                        exporterDataItems: <DataTable tableName={''}
                            fields={['exporter_name', 'registration_number', 'exporter_category', 'email', 'status_in_scheme', 'status']}
                            fieldDataTypes={['String', 'String', 'String', 'String', 'String', 'Status']}
                            fieldDecimalPlaces={[0, 0, 0, 0, 0, 0]}
                            dateFormat={'long_date'}
                            timeFormat={'h12'}
                            headers={[this.Language.DataTableHeader.Exporter, this.Language.DataTableHeader.RCNumber, this.Language.DataTableHeader.ExporterCategory, this.Language.DataTableHeader.Email, this.Language.DataTableHeader.StatusInScheme, this.Language.DataTableHeader.Status]}
                            data={this.state.exporters}
                            sortType={"ascending"} sortPosition={1} thStyle={[351, 121, 164, 174, 145, 130]}
                            link={"/exporters/"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={this.state.exporters_edit} linkToBlankTarget={false} linkToBlankTargetToken={''} download={true} downloadFileName={this.Language.DownloadFileName.Exporters} emptyMessage={this.Language.EmptyMessage.Exporters}
                            downloadFields={['exporter_code', 'registration_number', 'exporter_name', 'exporter_description', 'exporter_type', 'email', 'alternate_email', 'mobile', 'phone', 'alternate_phone', 'fax', 'website', 'financial_year_end', 'primary_industry', 'priority_sector', 'priority_sector_score', 'major_banker', 'exporter_category', 'registration_date', 'status_in_scheme', 'status', 'is_merged', 'is_approved', 'approved_by', 'approved_at', 'address_line1', 'address_line2', 'address_line3', 'address_city', 'address_state', 'address_country', 'mailing_address', 'alternate_address', 'post_zip_code', 'postal_area', 'is_registered', 'registered_by', 'parent_exporter_code', 'parent_exporter_name', 'parent_registration_number']} />
                    })

                    this.setState({ paginationItem: <PaginationGetDataLimit button={this.state.paginationGetMoreDataButton} numberOfRecords={this.state.numberOfRecords} currentNumberOfRecords={this.state.currentNumberOfRecords} language={this.Language} /> })
                    this.setState({ preloader: null });

                })

                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }



    render() {

        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Exporter Management"} active_menu_level_2={"Exporters"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.Exporters}`} breadcrumbs={[this.Language.BreadCrumbLabel.ExporterManagement, this.Language.BreadCrumbLabel.Exporters]} links={[`/exporters`, `/exporters`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <br />
                                {(this.state.exporters_create) && <Link className="waves-effect waves-light btn right" to={`/exporters/add`}><i className="material-icons left">library_add</i>{this.Language.ButtonLabel.CreateExporter}</Link>}
                                <br />
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                {this.state.exporterDataItems}
                                <br />
                                <br />
                                {this.state.paginationItem}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default Exporters;