import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
// import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';
import FormatService from '../modules/FormatService';
import Pagination from '../components/Pagination';

import axios from 'axios';



class Search extends Component {
    constructor(props) {
        super(props);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getSearchResults = this.getSearchResults.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.state = {
            searchResults: [],
            searchValue: '',
            result: '',
            message: '',
            token: '',
            preloader: null,
            currentNumberOfRecords: 50,
            defaultNumberOfPages: 1,
            defaultRecordsPerPage: 5,
            defaultActivePage: 1,
            PaginationElement: null,
            optionsList: [],
            scopes: {}
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.Format = new FormatService();
        this.apiURL = URLService.prototype.getApiURL();
        this.searchURL = URLService.prototype.getSearchURL();
        this.searchQuery = URLService.prototype.getSearchQuery();

    }


    componentWillMount() {
        this.checkAccessRight();


    }

    componentDidMount() {
        document.title = this.Language.PageTitle.Search + ' - ' + this.Language.EEGWebPortal;


        this.setState({ optionsList: this.Format.GenerateStepNumberListWithinRange(0, 1000) }, () => {
            window.$('select').material_select();
        });


        //determine the placeholder value to be used in the serach field
        let searchValuePlaceHolder = '';



        if (this.props.history.location.search && this.props.history.location.search !== '' && this.props.history.location.search !== null && typeof this.props.history.location.search !== 'undefined') {

            let search = this.props.history.location.search;
            let pathname = this.props.history.location.pathname;

            

            if (search.substring(0, 3) === this.searchQuery && pathname === this.searchURL) {
                let temp_placeholder = search.substring(3);
                temp_placeholder = temp_placeholder.split('+');
                temp_placeholder = temp_placeholder.join(" ");

                searchValuePlaceHolder = temp_placeholder;

                this.setState({ preloader: true });
                this.setState({
                    searchValue: searchValuePlaceHolder
                }, () => {
                    this.getSearchResults(searchValuePlaceHolder, this.state.currentNumberOfRecords);
                })
            }

        }


    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if (this.Auth.checkAccessScope(['read:search', 'action:search', 'read:users', 'action:users'], 'view') === false) {
            this.props.history.replace(default_home);
        } else {
            this.getProfile();
        };

    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ scopes: user_profile.scopes });
        }

    }


    onSubmit(event) {

        event.preventDefault();

        let searchValue = this.refs.searchValue.value;
        let currentNumberOfRecords = this.refs.current_number_of_records.value;

        if (searchValue !== '' && searchValue !== null && typeof searchValue !== 'undefined') {

            searchValue = searchValue.split(" ");
            searchValue = searchValue.join('+'); // prepare the search query 

            // this.props.history.replace('/search?q=' + searchValue); // reload to the search path with the new search query

            this.setState({ preloader: true });
            this.setState({
                searchResults: null,
                PaginationElement: null,
                defaultActivePage: 1,
            }, () => {
                this.getSearchResults(searchValue, currentNumberOfRecords);
            })

        }


    }


    getSearchResults(searchValue, currentNumberOfRecords) {

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
        if (currentNumberOfRecords === 'All records' || currentNumberOfRecords === null || typeof currentNumberOfRecords === 'undefined') currentNumberOfRecords = 'all_records';

        axios.get(`${this.apiURL}/api/v1/home/search?search=${searchValue}&tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`)
            .then(response => {


                this.setState({ searchResults: response.data.data }, () => {
                    


                    this.setState({ preloader: null });

                    let leftChevronEnable = true;
                    let rightChevronEnable = true;
                    let numberOfPages = 0;
                    if (this.state.searchResults) numberOfPages = Math.ceil(this.state.searchResults.length / this.state.defaultRecordsPerPage);

                    if (this.state.defaultActivePage <= 1) leftChevronEnable = false;
                    if (this.state.defaultActivePage >= numberOfPages) rightChevronEnable = false;

                    this.setState({ PaginationElement: <Pagination numberOfPages={numberOfPages} activePage={this.state.defaultActivePage} leftChevronEnable={leftChevronEnable} rightChevronEnable={rightChevronEnable} handlePaginationChange={this.handlePaginationChange} /> });


                })






                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }


    handlePaginationChange(activePage) {

        this.setState({ defaultActivePage: activePage }, () => {


            let leftChevronEnable = true;
            let rightChevronEnable = true;
            let numberOfPages = 0;
            if (this.state.searchResults) numberOfPages = Math.ceil(this.state.searchResults.length / this.state.defaultRecordsPerPage);

            if (this.state.defaultActivePage <= 1) leftChevronEnable = false;
            if (this.state.defaultActivePage >= numberOfPages) rightChevronEnable = false;

            this.setState({ PaginationElement: <Pagination numberOfPages={numberOfPages} activePage={this.state.defaultActivePage} leftChevronEnable={leftChevronEnable} rightChevronEnable={rightChevronEnable} handlePaginationChange={this.handlePaginationChange} /> });

        }); // change the value of active page so that the new set of records are displayed


    }




    render() {

        let searchResultDataItems = null;
        let numberOfResults = null;
        let startShowingFromNumber = null;
        let stopShowingFromNumber = null;
        let displayResultCount = null;
        let displayShowingCount = null;


        if (this.state.searchResults && this.state.searchResults.length > 0) {

            numberOfResults = this.state.searchResults.length;
            startShowingFromNumber = (this.state.defaultRecordsPerPage * (this.state.defaultActivePage - 1)) + 1;
            stopShowingFromNumber = ((this.state.defaultRecordsPerPage * this.state.defaultActivePage) - 1) + 1;

            if (startShowingFromNumber >= numberOfResults) startShowingFromNumber = numberOfResults;
            if (stopShowingFromNumber >= numberOfResults) stopShowingFromNumber = numberOfResults;

            if (numberOfResults !== 0 && numberOfResults !== '0') displayResultCount = ': ' + numberOfResults;
            if (startShowingFromNumber !== 0 && stopShowingFromNumber !== 0 && startShowingFromNumber !== '0' && stopShowingFromNumber !== '0') displayShowingCount = '(' + this.Language.PageLabel.Showing + ' ' + startShowingFromNumber + ' - ' + stopShowingFromNumber + ')';

            searchResultDataItems = this.state.searchResults.map((search_result_value, i) => {


                if (i >= (this.state.defaultRecordsPerPage * (this.state.defaultActivePage - 1)) && i <= (this.state.defaultRecordsPerPage * this.state.defaultActivePage) - 1) { // restrict the number of records to be displayed

                    let link_target = '';
                    let result_link = search_result_value.result_link;

                    if (search_result_value.result_category === "Document" || search_result_value.result_category === "Exporter Document" || search_result_value.result_category === "Partner Document" || search_result_value.result_category === "Exporters Document" || search_result_value.result_category === "Partners Document") {
                        link_target = '_blank';
                        result_link = `${result_link}?eut=${this.Auth.getToken()}`;
                    }


                    // let last_updated = this.DateService.convertToCalendarFullDate(search_result_value.new_last_updated_on);

                    let last_updated = search_result_value.new_last_updated_on + '';
                    last_updated = this.DateService.convertToDisplayDate(last_updated);
                    last_updated = this.Format.FormatLocaleDate(last_updated, this.Language.Lang, 'long_date', 'h12');



                    return (
                        [
                            <div key={i} className="row">
                                <div className="col s7">
                                    <p><Link to={result_link} target={link_target} ><b className="blue-text">{search_result_value.result_title}</b></Link></p>
                                    <p>{search_result_value.result_sub_title}</p>
                                    <span className={`task-cat cyan`}>{search_result_value.result_category}</span>
                                </div>
                                <div className="col s5">
                                    <p>{search_result_value.result_category}</p>
                                    <b> {this.Language.PageLabel.Status}:</b> {search_result_value.status ? (search_result_value.status === 'Active' ? this.Language.DataValue.Active : this.Language.DataValue.Inactive) : null}
                                    <p><b> {this.Language.PageLabel.LastUpdatedOn}:</b> {last_updated}</p>
                                </div>
                            </div>,
                            <br key={i + 'br 1'} />,
                            <div key={i + 'section 2'} className="divider"></div>,
                            <br key={i + 'br 2'} />,
                            <br key={i + 'br 3'} />,
                            <br key={i + 'br 4'} />
                        ]
                    )

                } else {

                    return (
                        null
                    )

                }

            })



        } else {

            if (this.state.searchValue !== '') {
                displayResultCount = ': ' + this.Language.PageLabel.NoResults;
            }

        }


        const optionsList = this.state.optionsList.map((option_value, i) => {
            if (parseInt(option_value, 10) !== this.state.currentNumberOfRecords) {
                return (
                    <option value={option_value} key={i + 1}>{option_value}</option>
                )
            } else {
                return (
                    null
                )
            }

        })



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Tools"} active_menu_level_2={"Search"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.Search}`} breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.Search]} links={[`/`, `/search`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">
                                        <div className="card-panel">

                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="searchValue" name="searchValue" type="text" ref="searchValue" value={this.state.searchValue} onChange={this.handleInputChange} />
                                                            <label htmlFor="searchValue" className="">
                                                                {this.Language.DataField.SearchEEGPortal}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4" >
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-current_number_of_records" className="initialized" id="current_number_of_records" name="current_number_of_records" ref="current_number_of_records">
                                                                    <option value={this.state.currentNumberOfRecords}>{this.state.currentNumberOfRecords}</option>
                                                                    {optionsList}
                                                                    <option value={"All records"}>{this.Language.Pagination.AllRecords}</option>
                                                                </select>
                                                            </div>
                                                            <label> {this.Language.DataField.ResultstoShow}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="search_for_ecc">
                                                                {this.Language.ButtonLabel.Search}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <br />
                                {this.state.preloader && <div className="progress teal lighten-4">
                                    <div className="indeterminate teal"></div>
                                </div>}
                                <div className="card-panel">
                                    <div className="row">
                                        <p>{this.Language.PageLabel.SearchResults}{displayResultCount} {displayShowingCount}</p>
                                        <br />
                                        <br />
                                        {searchResultDataItems}
                                        {this.state.PaginationElement}
                                    </div>
                                </div>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />






                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]

        )
    }
}

export default Search;