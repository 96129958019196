import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class AddAccessRight extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.AddAccessRight = this.AddAccessRight.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_user_role: [],
            select_field_values_access_right_object: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = this.Language.PageTitle.CreateAccessRight + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.checkAccessRight();

    }

    shouldComponentUpdate() {
        return true;
    }


    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['create:access_right', 'action:access_right'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("user_role,access_right_object");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }


    prepareData(return_option) {


        let role_id = this.refs.role_id.value;
        if (role_id === "Select User Role") role_id = "";

        let system_access_right_object_id = this.refs.system_access_right_object_id.value;
        if (system_access_right_object_id === "Select Access Right Object") system_access_right_object_id = "";

        let access_right_code = this.refs.access_right_code.value.trim().toLowerCase();
        let access_right_title = this.refs.access_right_title.value;

        let can_edit = this.refs.can_edit.checked;
        let can_view = this.refs.can_view.checked;
        let can_delete = this.refs.can_delete.checked;
        let can_create = this.refs.can_create.checked;
        let can_take_action = this.refs.can_take_action.checked;


        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let created_by = this.state.logged_in_username;



        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.AccessRight.AddErrorTitle, [role_id, system_access_right_object_id, access_right_title, access_right_code], [this.Language.DataField.UserRole, this.Language.DataField.SystemAccessRightObject, this.Language.DataField.AccessRightTitle, this.Language.DataField.AccessRightCode], ["isRequired", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

            if (this.state.error_messages === null) {

                if (role_id === "Select User Role") role_id = null;
                if (system_access_right_object_id === "Select Access Right Object") system_access_right_object_id = null;

                const userOS = this.Browser.getOS();

                //make call to database 
                const newAccessRight = {
                    role_id: role_id,
                    system_access_right_object_id: system_access_right_object_id,
                    access_right_code: access_right_code,
                    access_right_title: access_right_title,
                    can_edit: can_edit,
                    can_view: can_view,
                    can_delete: can_delete,
                    can_create: can_create,
                    can_take_action: can_take_action,
                    status: status,
                    created_by: created_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }




                this.setState({ preloader: true });
                document.getElementById("create_access_right_button").setAttribute("disabled", true);

                this.AddAccessRight(newAccessRight, return_option);

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }


    clearForm() {

        document.getElementById("add_access_right_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_user_role = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'user_role') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_user_role: select_field_values_user_role });



                        const select_field_values_access_right_object = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'access_right_object') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_access_right_object: select_field_values_access_right_object });


                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    AddAccessRight(newAccessRight, return_option) {


        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/settings/access/rights/`,
            data: newAccessRight
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.AccessRight.AddSuccess + newAccessRight.access_right_title, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("create_access_right_button").removeAttribute("disabled");

            if (return_option === 'return_to_main_page') {
                this.props.history.push('/settings/access/rights');
            } else {
                this.clearForm();
            }



        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {


                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SaveError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.AccessRight.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("create_access_right_button").removeAttribute("disabled");

            });

    }




    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"Access Rights"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.CreateAccessRight}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.AccessRights, this.Language.BreadCrumbLabel.AddAccessRight]} links={[`/settings/modules`, `/settings/access/rights`, `/settings/access/right/add`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}


                                            <h4 className="header2">{this.Language.PageLabel.AddaNewAccessRightObject}</h4>
                                            <div className="row">
                                                <form className="col s12" id="add_access_right_form" ref="add_access_right_form" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select className="initialized" name="role_id" id="role_id" ref="role_id">
                                                                    <option value="Select User Role" >{this.Language.DataFieldDefault.SelectUserRole}</option>
                                                                    {this.state.select_field_values_user_role.map((user_role_value, i) => {
                                                                        return (
                                                                            user_role_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.UserRole} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select className="initialized" name="system_access_right_object_id" id="system_access_right_object_id" ref="system_access_right_object_id">
                                                                    <option value="Select Access Right Object" >{this.Language.DataFieldDefault.SelectAccessRightObject}</option>
                                                                    {this.state.select_field_values_access_right_object.map((access_right_value, i) => {
                                                                        return (
                                                                            access_right_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.AccessRightObject} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="access_right_title" type="text" ref="access_right_title" />
                                                            <label htmlFor="access_right_title" className="">
                                                                {this.Language.DataField.AccessRightTitle} *
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="access_right_code" type="text" ref="access_right_code" />
                                                            <label htmlFor="access_right_code" className="">
                                                                {this.Language.DataField.AccessRightCode} *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="can_view" ref="can_view" />
                                                                <label htmlFor="can_view">{this.Language.DataField.CanView}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="can_create" ref="can_create" />
                                                                <label htmlFor="can_create">{this.Language.DataField.CanCreate}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="can_edit" ref="can_edit" />
                                                                <label htmlFor="can_edit">{this.Language.DataField.CanEdit}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col s3">
                                                            <p>
                                                                <input type="checkbox" id="can_delete" ref="can_delete" />
                                                                <label htmlFor="can_delete">{this.Language.DataField.CanDelete}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s5">
                                                            <p>
                                                                <input type="checkbox" id="can_take_action" ref="can_take_action" />
                                                                <label htmlFor="can_take_action">{this.Language.DataField.CanTakeAllActions}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateAccessRight}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateAccessRight}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className={this.Language.ButtonLabel.SaveNewAccessRight.length <= lengthCheck ? "input-field col s5" : "input-field col s7"}>
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="create_access_right_button">
                                                                {this.Language.ButtonLabel.SaveNewAccessRight}
                                                            </button>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`#!`} onClick={() => { this.prepareData("stay_on_current_page") }}>{this.Language.ButtonLabel.SaveAndNew}</Link>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className={this.Language.ButtonLabel.SaveNewAccessRight.length <= lengthCheck ? "input-field col s7" : "input-field col s5"}>
                                                            <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/settings/access/rights`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default AddAccessRight;