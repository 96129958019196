import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthService from "../modules/AuthService";
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSideBar from "../components/LeftSideBar";
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from "../components/BreadcrumbsWrapper";
import DataTable from "../components/DataTable";
import FloatingActionButton from "../components/FloatingActionButton";
import LanguageService from "../modules/LanguageService";
import DateService from "../modules/DateService";
import PaginationGetDataLimit from "../components/PaginationGetDataLimit";
import URLService from "../modules/URLService";

import axios from "axios";

const $ = require("jquery");

class BaselineDataSummary extends Component {
  constructor(props) {
    super(props);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getAccessScopes = this.getAccessScopes.bind(this);
    this.getBaselineDataSummary = this.getBaselineDataSummary.bind(this);
    this.getMoreRecords = this.getMoreRecords.bind(this);
    this.state = {
      baselineDataSummary: null,
      numberOfRecords: 0,
      currentNumberOfRecords: 500,
      paginationItem: null,
      result: "",
      message: "",
      token: "",
      preloader: true,
      baselineDataSummaryDataItems: null,
      baseline_create: null,
      baseline_edit: null,
      scopes: {},
      paginationGetMoreDataButton: null
    };
    this.Auth = new AuthService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.DateService = new DateService();
    this.apiURL = URLService.prototype.getApiURL();
    this.returnURLQuery = URLService.prototype.getReturnURLQuery();
  }

  componentWillMount() {
    this.checkAccessRight();
    this.getProfile();
  }

  componentDidMount() {
    document.title = this.Language.PageTitle.BaselineDataSummary + ' - ' + this.Language.EEGWebPortal;
    this.getAccessScopes();

    this.getBaselineDataSummary(this.state.currentNumberOfRecords);
    this.setState({
      paginationGetMoreDataButton: (
        <li className="input-field" onClick={this.getMoreRecords}>
          <a href={"#!"} className="waves-effect waves-teal teal-text">
            {this.Language.Pagination.GetData}
            <i className="material-icons right">refresh</i>
          </a>
        </li>
      )
    });
  }

  checkAccessRight() {
    let default_home = "/";
    const dashboards = this.Auth.getDashboards() || [];
    if (dashboards && dashboards.length > 0)
      default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`;

    if (
      this.Auth.checkAccessScope(
        ["read:baselines", "action:baselines"],
        "view"
      ) === false
    ) {
      this.props.history.replace(default_home);
    }
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState({ scopes: user_profile.scopes });
    }
  }

  getAccessScopes() {
    // Check the views scope
    if (this.state.scopes["views"]) {
      let len = this.state.scopes["views"].length;

      for (let i = 0; i < len; i++) {
        if (
          this.state.scopes["views"][i] === "create:baselines" ||
          this.state.scopes["views"][i] === "action:baselines"
        ) {
          this.setState({ baseline_create: true });
        }
        if (
          this.state.scopes["views"][i] === "update:baselines" ||
          this.state.scopes["views"][i] === "action:baselines"
        ) {
          this.setState({ baseline_edit: true });
        }
      }
    }
  }

  getMoreRecords() {
    let currentNumberOfRecords = $("#current_number_of_records").val();

    this.setState({
      baselineDataSummaryDataItems: null,
      paginationItem: null,
      baselineDataSummary: null,
      currentNumberOfRecords: currentNumberOfRecords,
      preloader: true
    });

    this.getBaselineDataSummary(currentNumberOfRecords); // get more data using the current value gotten from the PaginationGetDataLimit Component's currentNumberOfRecords input
  }

  getBaselineDataSummary(currentNumberOfRecords) {
    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);
    if (
      currentNumberOfRecords === "All records" ||
      currentNumberOfRecords === null ||
      typeof currentNumberOfRecords === "undefined"
    )
      currentNumberOfRecords = "all_records";

    axios
      .get(
        `${
          this.apiURL
        }/api/v1/baseline/data?tk=${this.Auth.getToken()}&tz=${user_time_zone}&record_limit=${currentNumberOfRecords}`
      )
      .then(response => {
        this.setState({ numberOfRecords: response.data.data_count }); // count of the total result expected. especially if not all the records are retrieve at a go.

        this.setState({ baselineDataSummary: response.data.data }, () => {
          this.setState({
            baselineDataSummaryDataItems: (
              <DataTable
                tableName={''}
                fields={[
                  'business_year',
                  'exporter_name',
                  'is_submitted',
                  'total_staff_strength',
                  'total_nigerian_staff',
                  'total_export_value',
                  'export_growth_rate',
                  'additions',
                  'opening_nbv',
                  'capital_investment_growth',
                  'turnover',
                  'other_incomes',
                  'bought_in_goods_and_materials',
                  'local_value_added'
                ]}
                fieldDataTypes={['Date-Year', 'String', 'Boolean', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number', 'Number']}
                fieldDecimalPlaces={[0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2]}
                dateFormat={'long_date'}
                timeFormat={'h12'}
                headers={[
                  this.Language.DataTableHeader.BusinessYear,
                  this.Language.DataTableHeader.Exporter,
                  this.Language.DataTableHeader.Submitted,
                  this.Language.DataTableHeader.TotalStaff,
                  this.Language.DataTableHeader.NigerianStaff,
                  this.Language.DataTableHeader.ExportValue,
                  this.Language.DataTableHeader.ExportGrowthRate,
                  this.Language.DataTableHeader.Additions,
                  this.Language.DataTableHeader.OpeningNBV,
                  this.Language.DataTableHeader.InvestmentGrowth,
                  this.Language.DataTableHeader.Turnover,
                  this.Language.DataTableHeader.OtherIncomes,
                  this.Language.DataTableHeader.BoughtInGoodsAndMaterials,
                  this.Language.DataTableHeader.LocalValueAdded
                ]}
                data={this.state.baselineDataSummary}
                sortType={'ascending'}
                sortPosition={1}
                thStyle={[50, 200, 50, 100, 100, 100, 100, 100, 21, 64, 74, 45, 30, 50]}
                link={'/exporter/baseline/data/edit/'}
                return_query_prefix={this.returnURLQuery}
                current_path={this.props.history.location.pathname}
                link_status={this.state.baseline_edit}
                linkToBlankTarget={false}
                linkToBlankTargetToken={''}
                download={true}
                downloadFileName={this.Language.DownloadFileName.BaselineDataSummary}
                emptyMessage={this.Language.EmptyMessage.BaselineDataSummary}
                downloadFields={[
                  'business_year',
                  'exporter_name',
                  'is_submitted',
                  'notes',
                  'reviewer_notes',
                  'total_staff_strength',
                  'total_nigerian_staff',
                  'total_export_value',
                  'export_growth_rate',
                  'additions',
                  'opening_nbv',
                  'capital_investment_growth',
                  'turnover',
                  'other_incomes',
                  'bought_in_goods_and_materials',
                  'local_value_added',
                  'priority_sector',
                  'priority_sector_score'
                ]}
              />
            )
          });

          this.setState({
            paginationItem: (
              <PaginationGetDataLimit
                button={this.state.paginationGetMoreDataButton}
                numberOfRecords={this.state.numberOfRecords}
                currentNumberOfRecords={this.state.currentNumberOfRecords}
                language={this.Language}
              />
            )
          });
          this.setState({ preloader: null });
        });

        this.setState({ result: response.data.status }, () => {

        });

        this.setState({ message: response.data.message }, () => {

        });
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx


          if (error.response.status === 500)
            window.Materialize.toast(
              this.Language.NodeMessage.GetError,
              8000,
              "rounded deep-orange darken-4"
            );
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

        } else {
          // Something happened in setting up the request that triggered an Error

        }
      });
  }

  render() {
    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'Baseline Data'} active_menu_level_2={'Baseline Summary'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.BaselineDataSummary}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.BaselineData, this.Language.BreadCrumbLabel.BaselineDataSummary]}
              links={[`/exporter/baseline/data`, `/exporter/baseline/data`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <br />
              {this.state.baseline_create && (
                <Link className="waves-effect waves-light btn right" to={`/exporter/baseline/data/add`}>
                  <i className="material-icons left">library_add</i>
                  {this.Language.ButtonLabel.CaptureBaselineData}
                </Link>
              )}
              <br />
              <br />
              {this.state.preloader && (
                <div className="progress teal lighten-4">
                  <div className="indeterminate teal"></div>
                </div>
              )}
              {this.state.baselineDataSummaryDataItems}
              <br />
              <br />
              {this.state.paginationItem}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default BaselineDataSummary;
