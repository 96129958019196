import React, { Component } from 'react';


class MapCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            latitude: props.latitude || 40.747688,
            longitude: props.longitude || -74.004142,
            buttonColour: props.buttonColour || 'cyan',
            mainTextColour: props.mainTextColour || 'grey',
            detailsTextColour: props.detailsTextColour || 'cyan',
            title: props.title || '',
            subTitle: props.subTitle || '',
            detailsText: props.detailsText || '',
            type: props.type || '',
            address: props.address || '',
            phone: props.phone || '',
            email: props.email || '',
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '12',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '4',
        }
    }

    render(){
        return (
            // <!--Map Card start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div className="map-card">
                <div className="card" style={{overflow: 'hidden'}}>
                    <div className="card-image waves-effect waves-block waves-light">
                    <div id="map-canvas" data-lat={this.state.latitude} data-lng={this.state.longitude} className="" style={{position: 'relative', overflow: 'hidden'}}/>
                    </div>
                    <div className="card-content">
                    <a href={"#!"} className={`btn-floating activator ${this.state.buttonColour} btn-move-up waves-effect waves-light darken-2 right`}>
                        <i className="material-icons">pin_drop</i>
                    </a>
                    <h4 className={`card-title ${this.state.mainTextColour}-text text-darken-4`}><a href={"#!"} className={`${this.state.mainTextColour}-text text-darken-4`}>{this.state.title}</a>
                    </h4>
                    <p className="blog-post-content">{this.state.subTitle}</p>
                    </div>
                    <div className="card-reveal" style={{display: "none", transform: "translateY(0px)"}}>
                    <span className={`card-title ${this.state.mainTextColour}-text text-darken-4`}>{this.state.title}
                        <i className="material-icons right">close</i>
                    </span>
                    <p>{this.state.detailsText}</p>
                    <p>
                        <i className={`material-icons ${this.state.detailsTextColour}-text text-darken-2`}>perm_identity</i>{this.state.type}</p>
                    <p>
                        <i className={`material-icons ${this.state.detailsTextColour}-text text-darken-2`}>business</i>{this.state.address}</p>
                    <p>
                        <i className={`material-icons ${this.state.detailsTextColour}-text text-darken-2`}>perm_phone_msg</i>{this.state.phone}</p>
                    <p>
                        <i className={`material-icons ${this.state.detailsTextColour}-text text-darken-2`}>email</i>{this.state.email}</p>
                    </div>
                </div>
                </div>
            </div>
            // <!--Map Card end-->
        )
    }
}

export default MapCard;