import React, { Component } from 'react';


class ProfileCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            profileID: props.profileID || 'profile-card',
            profileName: props.profileName || '',
            profileTitle: props.profileTitle || '',
            profilePhone: props.profilePhone || '',
            profileEmail: props.profileEmail || '',
            iconName: props.iconName || 'edit',
            detailTitle: props.detailTitle || '',
            dateOfRegistration: props.dateOfRegistration || '',
            role: props.role || '',
            srcBackImage: props.srcBackImage || '',
            srcBackImageAlt: props.srcBackImageAlt || '',
            srcImage: props.srcImage || '',
            srcImageAlt: props.srcImageAlt || '',
            imageColour: props.imageColour || 'cyan',
            buttonColour: props.buttonColour || 'red',
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '4',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '4',
        }
    }

    render(){
        return (
            // <!--Data Card start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                <div id={this.state.profileID} className="card" style={{overflow: 'hidden'}}>
                <div className="card-image waves-effect waves-block waves-light">
                    <img className="activator" src={this.state.srcBackImage} alt={this.state.srcBackImageAlt}/>
                </div>
                <div className="card-content">
                    <img src={this.state.srcImage} alt={this.state.srcImageAlt} className={`circle responsive-img activator card-profile-image ${this.state.imageColour} lighten-1 padding-2`}/>
                    <a href={"#!"} className={`btn-floating activator btn-move-up waves-effect waves-light ${this.state.buttonColour} accent-2 z-depth-4 right`}>
                    <i className="material-icons">{this.state.iconName}</i>
                    </a>
                    <span className="card-title activator grey-text text-darken-4">{this.state.profileName}</span>
                    <p>
                    <i className="material-icons">perm_identity</i>{this.state.profileTitle}</p>
                    <p>
                    <i className="material-icons">perm_phone_msg</i>{this.state.profilePhone}</p>
                    <p>
                    <i className="material-icons">email</i>{this.state.profileEmail}</p>
                </div>
                <div className="card-reveal" style={{display: "none", transform: "translateY(0px)"}}>
                    <span className="card-title grey-text text-darken-4">{this.state.profileName}
                    <i className="material-icons right">close</i>
                    </span>
                    <p>{this.state.detailTitle}:</p>
                    <p>
                    <i className="material-icons">perm_identity</i>{this.state.profileTitle}</p>
                    <p>
                    <i className="material-icons">perm_phone_msg</i>{this.state.profilePhone}</p>
                    <p>
                    <i class="material-icons">email</i>{this.state.profileEmail}</p>
                    <p>
                    <i className="material-icons">card_membership</i>{this.state.dateOfRegistration}
                    </p>
                    <p>
                    </p>
                    <p>
                    <i className="material-icons">assignment_turned_in</i>{this.state.role}
                    </p>
                    <p>
                    </p>
                </div>
                </div>
            </div>
            // <!--Data Card end-->
        )
    }
}

export default ProfileCard;