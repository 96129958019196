import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
//import ProfilePageHeader from '../components/ProfilePageHeader';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';






class UserProfile extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getUser = this.getUser.bind(this);
        this.state = {
            userDetails: '',
            result: '',
            message: '',
            hash_user: '',
            username: '',
            name: '',
            email: '',
            organisation: '',
            user_role: '',
            passwordExpiryWarningDays: 7
        }
        this.Auth = new AuthService();
        this.DateService = new DateService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
        this.getProfile();
        // this.checkAccessRight();
    
    }

    componentDidMount(){
      document.title = this.Language.PageTitle.UserProfile + ' - ' + this.Language.EEGWebPortal;
        this.checkAccessRight();
        this.getUser();

        
    }

    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null){
            this.setState({hash_user: user_profile.user,
                username: user_profile.username,
                name: user_profile.name,
                email: user_profile.email,
                organisation: user_profile.organisation,
                user_role: user_profile.user_role
            });
        }

    }


    checkAccessRight(){

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ( this.Auth.checkAccessScope(['read:user'], 'view') === false || this.state.hash_user !== this.props.match.params.id){
             this.props.history.replace(default_home);   
        };
 
     }




    getUser(){
        let userId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/users/${userId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {
                this.setState({userDetails: response.data.data[0]}, () => {

                })
                

                this.setState({result: response.data.status}, () => {

                })

                this.setState({message: response.data.message}, () => {

                })
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx

                  if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });
    }

    render(){

        let date_of_registration = '';
        let password_expiry = '';
        let password_expiry_phrase = '';
        let security_element, password_change_element =  null;

        date_of_registration = this.state.userDetails.date_of_registration + '';
        date_of_registration = this.DateService.convertToCalendarFullDate(date_of_registration);

        // password_expiry = Math.round(parseInt(this.state.userDetails.seconds_to_password_expiry, 10) / 86400);

        password_expiry = this.DateService.convertSecondstoDays(this.state.userDetails.seconds_to_password_expiry);
        
        if(password_expiry > 1) {
            password_expiry_phrase = `${this.Language.PasswordExpiryDays.Multiple.BeginningPhrase} ${password_expiry} ${this.Language.PasswordExpiryDays.Multiple.EndPhrase}`;
        } else if (password_expiry === 1) {
            password_expiry_phrase = `${this.Language.PasswordExpiryDays.One.BeginningPhrase} ${password_expiry} ${this.Language.PasswordExpiryDays.One.EndPhrase}`;
        } else {
            password_expiry_phrase = this.Language.PasswordExpiryDays.Expired;
        };

        let userId = this.props.match.params.id;


        if (this.state.userDetails.sign_in_method === 'Security Question'){
            security_element =  <Link to={`/user/profile/sq/${this.state.hash_user}`}><span className="new badge gradient-45deg-green-teal gradient-shadow" data-badge-caption={this.Language.ButtonLabel.ViewDetails}></span></Link>;
        };


        if (password_expiry <= this.state.passwordExpiryWarningDays){
            password_change_element =  <Link to={`/password/change/user/${this.state.hash_user}`}><span className="new badge gradient-45deg-purple-deep-orange gradient-shadow" data-badge-caption={this.Language.ButtonLabel.ChangePassword}></span></Link>;
        };
        

        let default_time_zone = this.state.userDetails.time_zone_region || '';
        default_time_zone = default_time_zone.split("***"); // split the time zone values  gotten from the database
        default_time_zone = default_time_zone[1];




        return [
          // <LoaderWrapper key={0}/>,
          <Header key={1} history={this.props.history} language={this.Language} />,

          // <!-- START MAIN -->
          <div id="main" key={2}>
            <div className="wrapper green lighten-5">
              <LeftSideBar active_menu_level_1={'User Profile'} active_menu_level_2={''} history={this.props.history} language={this.Language} />
              {/* <!-- START CONTENT --> */}
              <section id="content">
                <BreadcrumbsWrapper
                  page_title={`${this.Language.PageTitle.UserProfile}`}
                  breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.UserProfile]}
                  links={[`/`, `/user/profile/${userId}`]}
                  language={this.Language}
                />
                {/* <!--start container--> */}
                <div className="container">
                  <div id="profile-page" className="section">
                    {/* <ProfilePageHeader title={this.state.userDetails.title} prefix={this.state.userDetails.prefix} first_name={this.state.userDetails.first_name} middle_name={this.state.userDetails.middle_name} last_name={this.state.userDetails.last_name} suffix={this.state.userDetails.suffix} username={this.state.userDetails.username} exporter_name={this.state.userDetails.exporter_name} date_of_registration={this.state.userDetails.date_of_registration} phone={this.state.userDetails.phone} user_role={this.state.userDetails.user_role} email={this.state.userDetails.email}/> */}
                    <div id="profile-page-header" className="card" style={{overflow: 'hidden'}}>
                      <div className="card-image waves-effect waves-block waves-light">
                        <img className="activator" src="/assets/images/gallary/30.png" alt="" />
                      </div>
                      <figure className="card-profile-image">
                        <img src="/images/user.png" alt="" className="circle z-depth-2 responsive-img activator gradient-shadow" />
                        {/* <img src="/assets/images/avatar/avatar-7.png" alt="" className="circle z-depth-2 responsive-img activator gradient-45deg-light-blue-cyan gradient-shadow"/> */}
                      </figure>
                      <div className="card-content">
                        <div className="row pt-2">
                          <div className="col s12 m4 offset-m2">
                            <h4 className="card-title grey-text text-darken-4">
                              {this.state.userDetails.title} {this.state.userDetails.prefix} {this.state.userDetails.first_name} {this.state.userDetails.middle_name} {this.state.userDetails.last_name}{' '}
                              {this.state.userDetails.suffix}{' '}
                            </h4>
                            <p className="medium-small grey-text">{this.state.userDetails.username}</p>
                          </div>
                          <div className="col s12 m2 center-align">
                            <h4 className="card-title grey-text text-darken-4">{this.state.userDetails.exporter_name}</h4>
                            <p className="medium-small grey-text">{this.Language.DataField.Exporter}</p>
                          </div>
                          <div className="col s12 m2 center-align">
                            <h4 className="card-title grey-text text-darken-4">{date_of_registration}</h4>
                            <p className="medium-small grey-text">{this.Language.DataField.DateofRegistration}</p>
                          </div>
                          <div className="col s12 m1 center-align">
                            <h4 className="card-title grey-text text-darken-4">
                              {this.state.userDetails.status !== null && typeof this.state.userDetails.status !== 'undefined' ? (
                                this.state.userDetails.status === 'Active' ? (
                                  <span className="green-text">{this.Language.DataValue.Active}</span>
                                ) : (
                                  <span className="red-text">{this.Language.DataValue.Inactive}</span>
                                )
                              ) : null}
                            </h4>
                            <p className="medium-small grey-text">{this.Language.DataField.Status}</p>
                          </div>
                          <div className="col s12 m1 right-align">
                            <span className="btn-floating activator waves-effect waves-light teal accent-2 right">
                              <i className="material-icons">perm_identity</i>
                            </span>
                          </div>
                          {/* ::after  */}
                        </div>
                      </div>
                      <div className="card-reveal" style={{display: 'none', transform: 'translateY(0px)'}}>
                        <p>
                          <span className="card-title grey-text text-darken-4">
                            {this.state.userDetails.title} {this.state.userDetails.prefix} {this.state.userDetails.first_name} {this.state.userDetails.middle_name} {this.state.userDetails.last_name}{' '}
                            {this.state.userDetails.suffix}
                            <i className="material-icons right">close</i>
                          </span>
                          <span>
                            <i className="material-icons cyan-text text-darken-2">perm_identity</i>
                            {this.state.userDetails.exporter_name}
                          </span>
                        </p>
                        <p>
                          {this.Language.DataField.PortalUserAccountFor} {this.state.userDetails.exporter_name}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">perm_phone_msg</i>
                          {this.state.userDetails.phone}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">email</i>
                          {this.state.userDetails.email}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">card_membership</i>
                          {date_of_registration}
                        </p>
                        <p>
                          <i className="material-icons cyan-text text-darken-2">assignment_turned_in</i>
                          {this.Language.DataField.Role}: {this.state.userDetails.user_role}
                        </p>
                      </div>
                    </div>
                    <div id="profile-page-content" className="row">
                      <div id="profile-page-sidebar" className="col s12 m5">
                        <div className="card cyan">
                          <div className="card-content white-text">
                            <span className="card-title">
                              {this.Language.DataField.Username}: <b>{this.state.userDetails.username}</b>{' '}
                            </span>
                            <p>
                              {' '}
                              {this.Language.DataField.Role}: <b>{this.state.userDetails.user_role}</b>{' '}
                            </p>
                            <p>
                              {' '}
                              {this.Language.DataField.Exporter}: <b>{this.state.userDetails.exporter_name}</b>{' '}
                            </p>
                          </div>
                        </div>
                        <ul id="profile-page-about-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <b>{this.Language.PageLabel.ContactDetails}</b>
                              </div>
                              {/* ::after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">perm_phone_msg</i>
                                {this.Language.DataField.Phone}
                              </div>
                              <div className="col s7 right-align">
                                <b>{this.state.userDetails.phone}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">email</i>
                                {this.Language.DataField.Email}
                              </div>
                              <div className="col s7 right-align">
                                <b>{this.state.userDetails.email}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">language</i>
                                {this.Language.DataField.Language}
                              </div>
                              <div className="col s7 right-align">
                                <b>{this.state.userDetails.language_id}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">language</i>
                                {this.Language.DataField.DefaultTimeZone}
                              </div>
                              <div className="col s7 right-align">
                                <b>{default_time_zone}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <i className="material-icons left">card_membership</i>
                                {this.Language.DataField.DateofRegistration}
                              </div>
                              <div className="col s7 right-align">
                                <b>{date_of_registration}</b>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <br />
                        <br />
                      </div>
                      <div id="profile-page-wall" className="col s12 m7">
                        <ul id="profile-page-about-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <b>{this.Language.PageLabel.OtherDetailsandSettings}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableSMS}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_sms !== null && typeof this.state.userDetails.enable_sms !== 'undefined' ? (
                                  this.state.userDetails.enable_sms === 'true' || this.state.userDetails.enable_sms === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableEmail}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_email !== null && typeof this.state.userDetails.enable_email !== 'undefined' ? (
                                  this.state.userDetails.enable_email === 'true' || this.state.userDetails.enable_email === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableNotifications}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_notifications !== null && typeof this.state.userDetails.enable_notifications !== 'undefined' ? (
                                  this.state.userDetails.enable_notifications === 'true' || this.state.userDetails.enable_notifications === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ReceiveNotificationsAsSMS}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.receive_notification_as_sms !== null && typeof this.state.userDetails.receive_notification_as_sms !== 'undefined' ? (
                                  this.state.userDetails.receive_notification_as_sms === 'true' || this.state.userDetails.receive_notification_as_sms === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.ReceiveNotificationsAsEmail}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.receive_notification_as_email !== null && typeof this.state.userDetails.receive_notification_as_email !== 'undefined' ? (
                                  this.state.userDetails.receive_notification_as_email === 'true' || this.state.userDetails.receive_notification_as_email === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EmailSignature}:</div>
                              <div className="col s7 right-align">{this.state.userDetails.email_signature}</div>
                            </div>
                          </li>
                        </ul>
                        {/* ::after */}
                        <ul id="profile-page-about-details" className="collection z-depth-1">
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">
                                <b>{this.Language.PageLabel.SecuritySettings}</b>
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableTwoFactorAthentication}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.two_step_verification !== null && typeof this.state.userDetails.two_step_verification !== 'undefined' ? (
                                  this.state.userDetails.two_step_verification === 'true' || this.state.userDetails.two_step_verification === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.TwoFactorSigninMethod}:</div>
                              <div className="col s7 right-align">
                                {security_element}{' '}
                                {this.state.userDetails.sign_in_method
                                  ? this.state.userDetails.sign_in_method === 'Security Question'
                                    ? this.Language.DataValue.SecurityQuestion
                                    : this.Language.DataValue.Code
                                  : null}
                              </div>
                              {/* :;after */}
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.PasswordExpiry}:</div>
                              <div className="col s7 right-align">
                                {password_change_element}
                                {password_expiry_phrase}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.RecoveryEmail}:</div>
                              <div className="col s7 right-align">{this.state.userDetails.recovery_email}</div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.RecoveryPhoneNumber}:</div>
                              <div className="col s7 right-align">{this.state.userDetails.recovery_phone}</div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.SendAccountActivitiesToPhone}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.phone_account_activity !== null && typeof this.state.userDetails.phone_account_activity !== 'undefined' ? (
                                  this.state.userDetails.phone_account_activity === 'true' || this.state.userDetails.phone_account_activity === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.SendSecurityRiskToPhone}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.phone_security_risk !== null && typeof this.state.userDetails.phone_security_risk !== 'undefined' ? (
                                  this.state.userDetails.phone_security_risk === 'true' || this.state.userDetails.phone_security_risk === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.SendAccountActivitiesToEmail}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.email_account_activity !== null && typeof this.state.userDetails.email_account_activity !== 'undefined' ? (
                                  this.state.userDetails.email_account_activity === 'true' || this.state.userDetails.email_account_activity === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.SendSecurityRiskToEmail}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.email_security_risk !== null && typeof this.state.userDetails.email_security_risk !== 'undefined' ? (
                                  this.state.userDetails.email_security_risk === 'true' || this.state.userDetails.email_security_risk === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                          <li className="collection-item">
                            <div className="row">
                              <div className="col s5">{this.Language.DataField.EnableOfflineUsage}:</div>
                              <div className="col s7 right-align">
                                {this.state.userDetails.enable_offline_user !== null && typeof this.state.userDetails.enable_offline_user !== 'undefined' ? (
                                  this.state.userDetails.enable_offline_user === 'true' || this.state.userDetails.enable_offline_user === true ? (
                                    <span className="green-text">{this.Language.DataValue.Yes}</span>
                                  ) : (
                                    <span className="black-text">{this.Language.DataValue.No}</span>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </li>
                        </ul>
                        {/* ::after */}
                      </div>
                    </div>
                    {/* <Link className="waves-effect waves-light btn" to={`/user/profile/edit/${this.state.userDetails.hash_id}`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link> */}

                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                {/* <!--end container--> */}
              </section>
              {/* <!-- END CONTENT --> */}
              <FloatingActionButton language={this.Language} />
              {/* <RightSideBar language={this.Language}/> */}
            </div>
            {/* <!-- END WRAPPER --> */}
          </div>,
          // <!-- END MAIN --> */
          // <!-- //////////////////////////////////////////////////////////////////////////// -->
          <Footer key={3} language={this.Language} />
        ];
    }
}

export default UserProfile;