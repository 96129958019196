import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class AddPartner extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.addPartner = this.addPartner.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.state = {
            message: '',
            status: '',
            pick_list_values: [],
            select_field_values_organisation_type: [],
            select_field_values_address_country: [],
            select_field_values_address_state: [],
            preloader: true,
            hash_user: '',
            logged_in_username: '',
            text_limit: 2000,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
      document.title = this.Language.PageTitle.CreatePartnerAgency + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

        const monthsFull = this.Language.DatePicker.monthsFull.split(",");
        const monthsShort = this.Language.DatePicker.monthsShort.split(",");
        const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
        const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
        const today = this.Language.DatePicker.today;
        const clear = this.Language.DatePicker.clear;
        const close = this.Language.DatePicker.close;
        const labelMonthNext = this.Language.DatePicker.labelMonthNext;
        const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
        const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
        const labelYearSelect = this.Language.DatePicker.labelYearSelect;


        window.$('.datepicker').pickadate({
            monthsFull: monthsFull,
            monthsShort: monthsShort,
            weekdaysFull: weekdaysFull,
            weekdaysShort: weekdaysShort,
            today: today,
            clear: clear,
            close: close,
            labelMonthNext: labelMonthNext,
            labelMonthPrev: labelMonthPrev,
            labelMonthSelect: labelMonthSelect,
            labelYearSelect: labelYearSelect
            // firstDay: 1,
            // format: 'dd mmmm yyyy',
            // formatSubmit: 'yyyy/mm/dd',
            // selectMonths: true,
            // selectYears: true,
            // selectMonths: true, // Creates a dropdown to control month
            // selectYears: 15, // Creates a dropdown of 15 years to control year,
            // closeOnSelect: false, // Close upon selecting a date,
            // container: undefined, // ex. 'body' will append picker to body
        });

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['read:partners', 'action:partners'], 'view') === false)) {
            this.props.history.replace('/partners');
        } else {
            this.getDropdownValues("organisation_type,address_state,address_country");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    onSubmit(event) {

        event.preventDefault();
        this.prepareData("return_to_main_page");

    }




    prepareData(return_option) {


        let organisation_code = this.refs.organisation_code.value.trim();
        let registration_number = this.refs.registration_number.value.trim();

        let organisation_name = this.refs.organisation_name.value.trim();
        let organisation_description = this.refs.organisation_description.value;

        let organisation_type = this.refs.organisation_type.value;
        if (organisation_type === "Select Organisation Type") organisation_type = "";

        let email = this.refs.email.value.trim();
        let alternate_email = this.refs.alternate_email.value.trim();
        let mobile = this.refs.mobile.value.trim();
        let phone = this.refs.phone.value.trim();
        let alternate_phone = this.refs.alternate_phone.value.trim();
        let website = this.refs.website.value.trim();


        let date_of_creation = this.DateService.convertToFullDate(this.refs.date_of_creation.value);

        let address_line1 = this.refs.address_line1.value.trim();
        let address_line2 = this.refs.address_line2.value.trim();
        let address_line3 = this.refs.address_line3.value.trim();
        let address_city = this.refs.address_city.value.trim();

        let address_state = this.refs.address_state.value;
        if (address_state === "Select State") address_state = "";

        let address_country = this.refs.address_country.value;
        if (address_country === "Select Country") address_country = "";

        let mailing_address = this.refs.mailing_address.value.trim();
        let alternate_address = this.refs.alternate_address.value.trim();
        let post_zip_code = this.refs.post_zip_code.value.trim();
        let postal_area = this.refs.postal_area.value.trim();


        let is_approved = this.refs.is_approved.checked;
        let approved_by = null;
        if (is_approved) approved_by = this.state.logged_in_username;

        let status = "Inactive";
        if (this.refs.activate_switch.checked) status = "Active";
        if (!this.refs.activate_switch.checked) status = "Inactive";

        let approved_at = null;
        if (is_approved) {
            let currentdate = new Date();
            approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
        }

        let created_by = this.state.logged_in_username;
        let last_updated_by = this.state.logged_in_username;


        this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.Partner.AddErrorTitle, [organisation_code, registration_number, organisation_name, organisation_type, email, email, alternate_email, alternate_email, mobile, mobile, phone, phone, alternate_phone, alternate_phone, date_of_creation, address_line1, address_city, address_state, address_country, mailing_address, organisation_description, mailing_address, alternate_address], [this.Language.DataField.OrganisationCode, this.Language.DataField.RegistrationNumber, this.Language.DataField.PartnerAgency, this.Language.DataField.OrganisationType, this.Language.DataField.Email, this.Language.DataField.Email, this.Language.DataField.AlternateEmail, this.Language.DataField.AlternateEmail, this.Language.DataField.Mobile, this.Language.DataField.Mobile, this.Language.DataField.Phone, this.Language.DataField.Phone, this.Language.DataField.AlternatePhone, this.Language.DataField.AlternatePhone, this.Language.DataField.RegistrationDate, this.Language.DataField.AddressLine1, this.Language.DataField.AddressCity, this.Language.DataField.AddressState, this.Language.DataField.AddressCountry, this.Language.DataField.MailingAddress, this.Language.DataField.Description, this.Language.DataField.MailingAddress, this.Language.DataField.AlternateAddress], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isEmail", "isRequired", "isEmail", "isRequired", "isPhone", "isRequired", "isPhone", "isRequired", "isPhone", "isRequiredDate", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "maxLength", "maxLength", "maxLength"], 0, this.state.text_limit) }, () => {



            if (this.state.error_messages === null) {

                if (organisation_type === "Select Organisation Type") organisation_type = null;
                if (address_state === "Select State") address_state = null;
                if (address_country === "Select Country") address_country = null;

                const userOS = this.Browser.getOS();

                //make call to database 
                const newPartner = {
                    organisation_code: organisation_code,
                    registration_number: registration_number,
                    organisation_name: organisation_name,
                    organisation_description: organisation_description,
                    organisation_type: organisation_type,
                    email: email,
                    alternate_email: alternate_email,
                    mobile: mobile,
                    phone: phone,
                    alternate_phone: alternate_phone,
                    website: website,
                    date_of_creation: date_of_creation,
                    address_line1: address_line1,
                    address_line2: address_line2,
                    address_line3: address_line3,
                    address_city: address_city,
                    address_state: address_state,
                    address_country: address_country,
                    mailing_address: mailing_address,
                    alternate_address: alternate_address,
                    post_zip_code: post_zip_code,
                    postal_area: postal_area,
                    status: status,
                    is_approved: is_approved,
                    approved_by: approved_by,
                    approved_at: approved_at,
                    created_by: created_by,
                    last_updated_by: last_updated_by,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web",
                    token: this.Auth.getToken(),
                }


                this.setState({ preloader: true });
                document.getElementById("save_partner_button").setAttribute("disabled", true);

                this.addPartner(newPartner, return_option);

            } else {
                window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
            }

        });


    }


    clearForm() {

        document.getElementById("add_partner_form").reset();

        $(".switch").find("input[type=checkbox]").prop('checked', true);
        $(".is_approved").find("input[type=checkbox]").prop('checked', true);


    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {



                        const select_field_values_organisation_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'organisation_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_organisation_type: select_field_values_organisation_type });




                        const select_field_values_address_state = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'address_state') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_address_state: select_field_values_address_state });



                        const select_field_values_address_country = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'address_country') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_address_country: select_field_values_address_country });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });

            });

    }





    addPartner(newPartner, return_option) {

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/partners`,
            data: newPartner
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.Partner.AddSuccess + newPartner.organisation_name, 8000, 'rounded green');

            })


            this.setState({ preloader: null });
            document.getElementById("save_partner_button").removeAttribute("disabled");


            if (return_option === 'return_to_main_page') {
                this.props.history.push('/partners');
            } else {
                this.clearForm();
            }


        })
            .catch(error => {
                if (error.response) {



                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.Partner.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.Partner.AlreadyExist, 8000, 'rounded deep-orange darken-4');
                    }



                } else if (error.request) {
                } else {
                    // Something happened in setting up the request that triggered an Error
                }

                this.setState({ preloader: null });
                document.getElementById("save_partner_button").removeAttribute("disabled");

            });

    }





    render() {

        $(".switch").find("input[type=checkbox]").prop('checked', true);
        $(".is_approved").find("input[type=checkbox]").prop('checked', true);

        const lengthCheck = 25;


        return [
          // <LoaderWrapper key={0}/>,
          <Header key={1} history={this.props.history} language={this.Language} />,

          // <!-- START MAIN -->
          <div id="main" key={2}>
            <div className="wrapper green lighten-5">
              <LeftSideBar active_menu_level_1={'Partner Management'} active_menu_level_2={'Partner Registration'} history={this.props.history} language={this.Language} />
              {/* <!-- START CONTENT --> */}
              <section id="content">
                <BreadcrumbsWrapper
                  page_title={`${this.Language.PageTitle.CreatePartnerAgency}`}
                  breadcrumbs={[this.Language.BreadCrumbLabel.PartnerManagement, this.Language.BreadCrumbLabel.PartnerAgencies, this.Language.BreadCrumbLabel.AddPartnerAgency]}
                  links={[`/partners`, `/partners`, `/partners/add`]}
                  language={this.Language}
                />
                {/* <!--start container--> */}
                <div className="container">
                  <div className="row">
                    <div className="col s12 l12">
                      {this.state.error_messages}

                      <div className="card-panel">
                        {this.state.preloader && (
                          <div className="progress teal lighten-4">
                            <div className="indeterminate teal"></div>
                          </div>
                        )}

                        <h4 className="header2">{this.Language.PageLabel.AddANewPartnerAgency}</h4>
                        <div className="row">
                          <form className="col s12" id="add_partner_form" ref="add_partner_form" onSubmit={this.onSubmit.bind(this)}>
                            <div className="row">
                              <div className="input-field col s4">
                                <i className="material-icons prefix">account_balance</i>
                                <input id="organisation_code" name="organisation_code" type="text" ref="organisation_code" />
                                <label htmlFor="organisation_code" className="">
                                  {this.Language.DataField.OrganisationCode} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s8">
                                <input id="registration_number" name="registration_number" type="text" ref="registration_number" />
                                <label htmlFor="registration_number" className="">
                                  {this.Language.DataField.RegistrationNumber} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s8">
                                <input id="organisation_name" name="organisation_name" type="text" ref="organisation_name" />
                                <label htmlFor="organisation_name" className="">
                                  {this.Language.DataField.PartnerAgency} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select data-select-id="select-options-organisation_type" className="initialized" name="organisation_type" ref="organisation_type">
                                    <option value="Select Organisation Type">{this.Language.DataFieldDefault.SelectOrganisationType}</option>
                                    {this.state.select_field_values_organisation_type.map((organisation_type_value, i) => {
                                      return organisation_type_value;
                                    })}
                                  </select>
                                </div>
                                <label>
                                  {this.Language.DataField.OrganisationType} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s8">
                                <textarea id="organisation_description" className="materialize-textarea" data-length={this.state.text_limit} ref="organisation_description"></textarea>
                                <label htmlFor="organisation_description" data-error={this.Language.DataFieldError.MaxLength}>
                                  {this.Language.DataField.Description}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s6">
                                <input id="email" type="email" className="validate" ref="email" />
                                <label htmlFor="email" data-error={this.Language.DataFieldError.Email} className="">
                                  {this.Language.DataField.Email} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <input id="alternate_email" type="email" className="validate" ref="alternate_email" />
                                <label htmlFor="alternate_email" data-error={this.Language.DataFieldError.Email} className="">
                                  {this.Language.DataField.AlternateEmail} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s4">
                                <input id="mobile" type="tel" className="validate" ref="mobile" />
                                <label htmlFor="mobile" data-error={this.Language.DataFieldError.Mobile} className="">
                                  {this.Language.DataField.Mobile} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input id="phone" type="tel" className="validate" ref="phone" />
                                <label htmlFor="phone" data-error={this.Language.DataFieldError.Phone} className="">
                                  {this.Language.DataField.Phone} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s4">
                                <input id="alternate_phone" type="tel" className="validate" ref="alternate_phone" />
                                <label htmlFor="alternate_phone" data-error={this.Language.DataFieldError.Phone} className="">
                                  {this.Language.DataField.AlternatePhone}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s8">
                                <input id="website" name="website" type="url" className="validate" ref="website" defaultValue={'http://www.'} />
                                <label htmlFor="website" data-error={this.Language.DataFieldError.Website} className="">
                                  {this.Language.DataField.Website}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                              <div className="input-field col s6">
                                <input
                                  type="text"
                                  className="datepicker picker__input"
                                  ref="date_of_creation"
                                  readOnly="false"
                                  id="P875200303"
                                  tabIndex="-1"
                                  aria-haspopup="true"
                                  aria-readonly="false"
                                  aria-owns="P875200303_root"
                                />
                                <label htmlFor="date_of_creation">
                                  {this.Language.DataField.RegistrationDate} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s12">
                                <input id="address_line1" name="address_line1" type="text" ref="address_line1" />
                                <label htmlFor="address_line1" className="">
                                  {this.Language.DataField.AddressLine1} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s6">
                                <input id="address_line2" name="address_line2" type="text" ref="address_line2" />
                                <label htmlFor="address_line2" className="">
                                  {this.Language.DataField.AddressLine2}
                                  {/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <input id="address_line3" name="address_line3" type="text" ref="address_line3" />
                                <label htmlFor="address_line3" className="">
                                  {this.Language.DataField.AddressLine3}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s6">
                                <input id="address_city" name="address_city" type="text" ref="address_city" />
                                <label htmlFor="address_city" className="">
                                  {this.Language.DataField.City} *{/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s6">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select data-select-id="select-options-address_state" className="initialized" name="address_state" ref="address_state">
                                    <option value="Select State">{this.Language.DataFieldDefault.SelectState}</option>
                                    {this.state.select_field_values_address_state.map((address_state_value, i) => {
                                      return address_state_value;
                                    })}
                                  </select>
                                </div>
                                <label>
                                  {this.Language.DataField.State} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s6">
                                <div className="select-wrapper initialized">
                                  <span className="caret">▼</span>
                                  <select data-select-id="select-options-address_country" className="initialized" name="address_country" ref="address_country">
                                    <option value="Select Country">{this.Language.DataFieldDefault.SelectCountry}</option>
                                    {this.state.select_field_values_address_country.map((address_country_value, i) => {
                                      return address_country_value;
                                    })}
                                  </select>
                                </div>
                                <label>
                                  {this.Language.DataField.Country} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s12">
                                <textarea id="mailing_address" className="materialize-textarea" data-length={this.state.text_limit} ref="mailing_address"></textarea>
                                <label htmlFor="mailing_address" data-error={this.Language.DataFieldError.MaxLength}>
                                  {this.Language.DataField.MailingAddress} *{/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s12">
                                <textarea id="alternate_address" className="materialize-textarea" data-length={this.state.text_limit} ref="alternate_address"></textarea>
                                <label htmlFor="alternate_address" data-error={this.Language.DataFieldError.MaxLength}>
                                  {this.Language.DataField.AlternateAddress}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="input-field col s3">
                                <input id="post_zip_code" name="post_zip_code" type="text" ref="post_zip_code" />
                                <label htmlFor="post_zip_code" className="">
                                  {this.Language.DataField.ZipCode}
                                  {/* ::after */}
                                </label>
                              </div>
                              <div className="input-field col s3">
                                <input id="postal_area" name="postal_area" type="text" ref="postal_area" />
                                <label htmlFor="postal_area" className="">
                                  {this.Language.DataField.PostalArea}
                                  {/* ::after */}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="is_approved col s4">
                                <p>
                                  <input type="checkbox" id="is_approved" ref="is_approved" />
                                  <label htmlFor="is_approved">{this.Language.DataField.IsApproved}</label>
                                </p>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                              <div className="col s8">
                                <br />
                                <div className="switch">
                                  <label className="font-weight-600 black-text">
                                    {this.Language.DataField.DeactivatePartner}
                                    <input type="checkbox" name="activate_switch" ref="activate_switch" />
                                    <span className="lever"></span>
                                    {this.Language.DataField.ActivatePartner}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                              <div className={this.Language.ButtonLabel.SavePartner.length <= lengthCheck ? 'input-field col s6' : 'input-field col s8'}>
                                <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="save_partner_button">
                                  {this.Language.ButtonLabel.SavePartner}
                                </button>
                                <Link
                                  className="waves-effect waves-light btn grey lighten-3 cyan-text right"
                                  to={`#!`}
                                  onClick={() => {
                                    this.prepareData('stay_on_current_page');
                                  }}>
                                  {this.Language.ButtonLabel.SaveAndNew}
                                </Link>
                              </div>
                              {/* ::after */}
                              <div className={this.Language.ButtonLabel.SavePartner.length <= lengthCheck ? 'input-field col s6' : 'input-field col s4'}>
                                <Link className="waves-effect waves-light btn grey lighten-3 cyan-text right" to={`/partners`}>
                                  <i className="material-icons left">cancel</i>
                                  {this.Language.ButtonLabel.Cancel}
                                </Link>
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                          </form>
                          {/* ::after */}
                        </div>
                      </div>
                    </div>
                    {/* ::after */}
                  </div>
                </div>
                {/* <!--end container--> */}
              </section>
              {/* <!-- END CONTENT --> */}
              <FloatingActionButton language={this.Language} />
              {/* <RightSideBar language={this.Language}/> */}
            </div>
            {/* <!-- END WRAPPER --> */}
          </div>,
          // <!-- END MAIN --> */
          // <!-- //////////////////////////////////////////////////////////////////////////// -->
          <Footer key={3} language={this.Language} />
        ];
    }
}

export default AddPartner;