import React, { Component } from 'react';
import Main from './pages/Main';
//import Footer from './components/Footer';


class App extends Component {
  render() {
    return (
            <Main />
          );
  }
}

export default App;
