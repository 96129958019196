import React, { Component } from 'react';
// import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditPartner extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updatePartner = this.updatePartner.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getPartner = this.getPartner.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.state = {
            organisation_code: '',
            registration_number: '',
            organisation_name: '',
            organisation_description: '',
            organisation_type: '',
            email: '',
            alternate_email: '',
            mobile: '',
            phone: '',
            alternate_phone: '',
            website: '',
            date_of_creation: '',
            address_line1: '',
            address_line2: '',
            address_line3: '',
            address_city: '',
            address_state: '',
            address_country: '',
            mailing_address: '',
            alternate_address: '',
            post_zip_code: '',
            postal_area: '',
            status: '',
            status_active: false,
            is_approved: false,
            is_approved_temp: false, //temp for tracking the initial is_approved value
            approved_by: '',
            approved_at: '',
            last_updated_by: '',
            last_updated_on_check: '',
            result: '',
            message: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_organisation_type: [],
            select_field_values_address_country: [],
            select_field_values_address_state: [],
            preloader: true,
            hash_user: '',
            logged_in_username: '',
            scopes: {},
            text_limit: 2000,
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.DateService = new DateService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        
        this.getProfile();
        this.getPartner();

        const monthsFull = this.Language.DatePicker.monthsFull.split(",");
        const monthsShort = this.Language.DatePicker.monthsShort.split(",");
        const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(",");
        const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(",");
        const today = this.Language.DatePicker.today;
        const clear = this.Language.DatePicker.clear;
        const close = this.Language.DatePicker.close;
        const labelMonthNext = this.Language.DatePicker.labelMonthNext;
        const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
        const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
        const labelYearSelect = this.Language.DatePicker.labelYearSelect;


        window.$('.datepicker').pickadate({
            monthsFull: monthsFull,
            monthsShort: monthsShort,
            weekdaysFull: weekdaysFull,
            weekdaysShort: weekdaysShort,
            today: today,
            clear: clear,
            close: close,
            labelMonthNext: labelMonthNext,
            labelMonthPrev: labelMonthPrev,
            labelMonthSelect: labelMonthSelect,
            labelYearSelect: labelYearSelect
            // firstDay: 1,
            // format: 'dd mmmm yyyy',
            // formatSubmit: 'yyyy/mm/dd',
            // selectMonths: true,
            // selectYears: true,
            // selectMonths: true, // Creates a dropdown to control month
            // selectYears: 15, // Creates a dropdown of 15 years to control year,
            // closeOnSelect: false, // Close upon selecting a date,
            // container: undefined, // ex. 'body' will append picker to body
        });

    }

    shouldComponentUpdate() {
        return true;

    }

    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({
                scopes: user_profile.scopes,
                logged_in_username: user_profile.username,
                hash_user: user_profile.user
            }, () => {
                this.checkAccessRight();
            });

        }

    }


    checkAccessRight() {

        if ((this.Auth.checkAccessScope(['update:partners', 'action:partners'], 'view') === false)) {
            this.props.history.replace('/partners');
        } else {
            this.getDropdownValues("organisation_type,address_state,address_country");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }

    getPartner() {
        let partnerId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/partners/${partnerId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {

                this.setState({
                    organisation_code: response.data.data[0].organisation_code || '',
                    registration_number: response.data.data[0].registration_number || '',
                    organisation_name: response.data.data[0].organisation_name || '',
                    organisation_description: response.data.data[0].organisation_description || '',
                    organisation_type: response.data.data[0].organisation_type,
                    email: response.data.data[0].email || '',
                    alternate_email: response.data.data[0].alternate_email || '',
                    mobile: response.data.data[0].mobile || '',
                    phone: response.data.data[0].phone || '',
                    alternate_phone: response.data.data[0].alternate_phone || '',
                    website: response.data.data[0].website || '',
                    date_of_creation: this.DateService.convertToCalendarFullDate(response.data.data[0].date_of_creation),
                    address_line1: response.data.data[0].address_line1 || '',
                    address_line2: response.data.data[0].address_line2 || '',
                    address_line3: response.data.data[0].address_line3 || '',
                    address_city: response.data.data[0].address_city || '',
                    address_state: response.data.data[0].address_state,
                    address_country: response.data.data[0].address_country,
                    mailing_address: response.data.data[0].mailing_address || '',
                    alternate_address: response.data.data[0].alternate_address || '',
                    post_zip_code: response.data.data[0].post_zip_code || '',
                    postal_area: response.data.data[0].postal_area || '',
                    status: response.data.data[0].status,
                    is_approved: (response.data.data[0].is_approved === 'true' || response.data.data[0].is_approved === true) ? true : false || false,
                    is_approved_temp: (response.data.data[0].is_approved === 'true' || response.data.data[0].is_approved === true) ? true : false || false,
                    approved_by: response.data.data[0].approved_by,
                    approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].approved_at),
                    last_updated_by: response.data.data[0].last_updated_by,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                    document.title = this.Language.PageTitle.EditPartner + this.state.organisation_code + ' - ' + this.Language.EEGWebPortal;
                });



                if (this.state.status === 'Active') {
                    this.setState({ status_active: true });
                } else {
                    this.setState({ status_active: false });
                };



                this.setState({ response_status: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })

                window.$('select').material_select();

            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


        if (name === 'is_approved') { this.setState({ [name]: !this.state.is_approved }); }

    }




    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.EditPartner} ${this.refs.organisation_name.value.trim()}`)) {


            let organisation_code = this.refs.organisation_code.value.trim();
            let registration_number = this.refs.registration_number.value.trim();

            let organisation_name = this.refs.organisation_name.value.trim();
            let organisation_description = this.refs.organisation_description.value;

            let organisation_type = this.refs.organisation_type.value;
            if (organisation_type === "Select Exporter Type") organisation_type = "";

            let email = this.refs.email.value.trim();
            let alternate_email = this.refs.alternate_email.value.trim();
            let mobile = this.refs.mobile.value.trim();
            let phone = this.refs.phone.value.trim();
            let alternate_phone = this.refs.alternate_phone.value.trim();
            let website = this.refs.website.value.trim();

            let date_of_creation = this.DateService.convertToFullDate(this.refs.date_of_creation.value);


            let address_line1 = this.refs.address_line1.value.trim();
            let address_line2 = this.refs.address_line2.value.trim();
            let address_line3 = this.refs.address_line3.value.trim();
            let address_city = this.refs.address_city.value.trim();

            let address_state = this.refs.address_state.value;
            if (address_state === "Select State") address_state = "";

            let address_country = this.refs.address_country.value;
            if (address_country === "Select Country") address_country = "";

            let mailing_address = this.refs.mailing_address.value.trim();
            let alternate_address = this.refs.alternate_address.value.trim();
            let post_zip_code = this.refs.post_zip_code.value.trim();
            let postal_area = this.refs.postal_area.value.trim();


            let is_approved = this.refs.is_approved.checked;
            let approved_by = null;
            if (is_approved) approved_by = this.state.logged_in_username;

            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let approved_at = null;
            if (this.state.is_approved_temp === true || this.state.is_approved_temp === 'true') { // using the is_approved_temp variable check if the partner had been approved before now take the approved at value
                approved_at = this.state.approved_at;
            }

            if (is_approved === true && this.state.is_approved_temp === false) { // if the partner has just been approved then take the new approved at value
                let currentdate = new Date();
                approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
            }


            let last_updated_by = this.state.logged_in_username;


            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.Partner.EditErrorTitle, [organisation_code, registration_number, organisation_name, organisation_type, email, email, alternate_email, alternate_email, mobile, mobile, phone, phone, alternate_phone, alternate_phone, date_of_creation, address_line1, address_city, address_state, address_country, mailing_address, organisation_description, mailing_address, alternate_address], [this.Language.DataField.OrganisationCode, this.Language.DataField.RegistrationNumber, this.Language.DataField.PartnerAgency, this.Language.DataField.OrganisationType, this.Language.DataField.Email, this.Language.DataField.Email, this.Language.DataField.AlternateEmail, this.Language.DataField.AlternateEmail, this.Language.DataField.Mobile, this.Language.DataField.Mobile, this.Language.DataField.Phone, this.Language.DataField.Phone, this.Language.DataField.AlternatePhone, this.Language.DataField.AlternatePhone, this.Language.DataField.RegistrationDate, this.Language.DataField.AddressLine1, this.Language.DataField.AddressCity, this.Language.DataField.AddressState, this.Language.DataField.AddressCountry, this.Language.DataField.MailingAddress, this.Language.DataField.Description, this.Language.DataField.MailingAddress, this.Language.DataField.AlternateAddress], ["isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "isEmail", "isRequired", "isEmail", "isRequired", "isPhone", "isRequired", "isPhone", "isRequired", "isPhone", "isRequiredDate", "isRequired", "isRequired", "isRequired", "isRequired", "isRequired", "maxLength", "maxLength", "maxLength"], 0, this.state.text_limit) }, () => {

                if (this.state.error_messages === null) {

                    if (organisation_type === "Select Organisation Type") organisation_type = null;
                    if (address_state === "Select State") address_state = null;
                    if (address_country === "Select Country") address_country = null;


                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newPartner = {
                        organisation_code: organisation_code,
                        registration_number: registration_number,
                        organisation_name: organisation_name,
                        organisation_description: organisation_description,
                        organisation_type: organisation_type,
                        email: email,
                        alternate_email: alternate_email,
                        mobile: mobile,
                        phone: phone,
                        alternate_phone: alternate_phone || '',
                        website: website || '',
                        date_of_creation: date_of_creation,
                        address_line1: address_line1 || '',
                        address_line2: address_line2 || '',
                        address_line3: address_line3 || '',
                        address_city: address_city || '',
                        address_state: address_state || '',
                        address_country: address_country || '',
                        mailing_address: mailing_address || '',
                        alternate_address: alternate_address || '',
                        post_zip_code: post_zip_code || '',
                        postal_area: postal_area || '',
                        status: status,
                        is_approved: is_approved,
                        approved_by: approved_by,
                        approved_at: approved_at,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken()
                    }

                    

                    this.setState({ preloader: true });
                    document.getElementById("update_partner_button").setAttribute("disabled", true);

                    this.updatePartner(newPartner);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_organisation_type = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'organisation_type') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_organisation_type: select_field_values_organisation_type });




                        const select_field_values_address_state = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'address_state') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_address_state: select_field_values_address_state });



                        const select_field_values_address_country = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'address_country') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_address_country: select_field_values_address_country });

                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    updatePartner(newPartner) {

        let partnerId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/partners/${partnerId}`,
            data: newPartner
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.Partner.UpdateSuccess + newPartner.organisation_name, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_partner_button").removeAttribute("disabled");

            this.props.history.replace('/partners');
            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.Partner.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.Partner.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.Partner.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/partners');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
               

                this.setState({ preloader: null });
                document.getElementById("update_partner_button").removeAttribute("disabled");

            });

    }


    render() {

        let partnerId = this.props.match.params.id;



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Partner Management"} active_menu_level_2={"Partner Agencies"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditPartner} ${this.state.organisation_name}`} breadcrumbs={[this.Language.BreadCrumbLabel.PartnerManagement, this.Language.BreadCrumbLabel.PartnerAgencies, this.Language.BreadCrumbLabel.EditPartnerAgency]} links={[`/partners`, `/partners`, `/partners/edit/${partnerId}`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditPartner}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <i className="material-icons prefix">account_balance</i>
                                                            <input id="organisation_code" name="organisation_code" type="text" ref="organisation_code" value={this.state.organisation_code} disabled />
                                                            <label htmlFor="organisation_code" className={(this.state.organisation_code && this.state.organisation_code !== null && typeof this.state.organisation_code !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.OrganisationCode} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s8">
                                                            <input id="registration_number" name="registration_number" type="text" ref="registration_number" value={this.state.registration_number} disabled />
                                                            <label htmlFor="registration_number" className={(this.state.registration_number && this.state.registration_number !== null && typeof this.state.registration_number !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.RegistrationNumber} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="organisation_name" name="organisation_name" type="text" ref="organisation_name" value={this.state.organisation_name} onChange={this.handleInputChange} />
                                                            <label htmlFor="organisation_name" className={(this.state.organisation_name && this.state.organisation_name !== null && typeof this.state.organisation_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ExporterName} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-organisation_type" className="initialized" name="organisation_type" ref="organisation_type">
                                                                    {this.state.organisation_type && <option value={this.state.organisation_type} selected>{this.state.organisation_type}</option>}
                                                                    <option value="Select Organisation Type">{this.Language.DataFieldDefault.SelectOrganisationType}</option>
                                                                    {this.state.select_field_values_organisation_type.map((organisation_type_value, i) => {
                                                                        return (
                                                                            organisation_type_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.OrganisationType} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <textarea id="organisation_description" className="materialize-textarea" data-length={this.state.text_limit} ref="organisation_description" name="organisation_description" value={this.state.organisation_description} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="organisation_description" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.organisation_description && this.state.organisation_description !== null && typeof this.state.organisation_description !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Description}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="email" type="email" className="validate" ref="email" name="email" value={this.state.email} onChange={this.handleInputChange} />
                                                            <label htmlFor="email" data-error={this.Language.DataFieldError.Email} className={(this.state.email && this.state.email !== null && typeof this.state.email !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Email} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="alternate_email" type="email" className="validate" ref="alternate_email" name="alternate_email" value={this.state.alternate_email} onChange={this.handleInputChange} />
                                                            <label htmlFor="alternate_email" data-error={this.Language.DataFieldError.Email} className={(this.state.alternate_email && this.state.alternate_email !== null && typeof this.state.alternate_email !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.AlternateEmail} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s4">
                                                            <input id="mobile" type="tel" className="validate" ref="mobile" name="mobile" value={this.state.mobile} onChange={this.handleInputChange} />
                                                            <label htmlFor="mobile" data-error={this.Language.DataFieldError.Mobile} className={(this.state.mobile && this.state.mobile !== null && typeof this.state.mobile !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Mobile} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="phone" type="tel" className="validate" ref="phone" name="phone" value={this.state.phone} onChange={this.handleInputChange} />
                                                            <label htmlFor="phone" data-error={this.Language.DataFieldError.Phone} className={(this.state.phone && this.state.phone !== null && typeof this.state.phone !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Phone} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s4">
                                                            <input id="alternate_phone" type="tel" className="validate" ref="alternate_phone" name="alternate_phone" value={this.state.alternate_phone} onChange={this.handleInputChange} />
                                                            <label htmlFor="alternate_phone" data-error={this.Language.DataFieldError.Phone} className={(this.state.alternate_phone && this.state.alternate_phone !== null && typeof this.state.alternate_phone !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.AlternatePhone}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <input id="website" name="website" type="text" ref="website" value={this.state.website} onChange={this.handleInputChange} />
                                                            <label htmlFor="website" data-error={this.Language.DataFieldError.Website} className={(this.state.website && this.state.website !== null && typeof this.state.website !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.Website}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input type="text" className="datepicker picker__input" ref="date_of_creation" value={this.state.date_of_creation} readOnly="false" id="P875200303" tabIndex="-1" aria-haspopup="true" aria-readonly="false" aria-owns="P875200303_root" />
                                                            <label htmlFor="date_of_creation" className={(this.state.date_of_creation && this.state.date_of_creation !== null && typeof this.state.date_of_creation !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.RegistrationDate} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <input id="address_line1" name="address_line1" type="text" ref="address_line1" value={this.state.address_line1} onChange={this.handleInputChange} />
                                                            <label htmlFor="address_line1" className={(this.state.address_line1 && this.state.address_line1 !== null && typeof this.state.address_line1 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.AddressLine1} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="address_line2" name="address_line2" type="text" ref="address_line2" value={this.state.address_line2} onChange={this.handleInputChange} />
                                                            <label htmlFor="address_line2" className={(this.state.address_line2 && this.state.address_line2 !== null && typeof this.state.address_line2 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.AddressLine2}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <input id="address_line3" name="address_line3" type="text" ref="address_line3" value={this.state.address_line3} onChange={this.handleInputChange} />
                                                            <label htmlFor="address_line3" className={(this.state.address_line3 && this.state.address_line3 !== null && typeof this.state.address_line3 !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.AddressLine3}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <input id="address_city" name="address_city" type="text" ref="address_city" value={this.state.address_city} onChange={this.handleInputChange} />
                                                            <label htmlFor="address_city" className={(this.state.address_city && this.state.address_city !== null && typeof this.state.address_city !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.City} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-address_state" className="initialized" name="address_state" ref="address_state">
                                                                    {this.state.address_state && <option value={this.state.address_state} selected>{this.state.address_state}</option>}
                                                                    <option value="Select State">{this.Language.DataFieldDefault.SelectState}</option>
                                                                    {this.state.select_field_values_address_state.map((address_state_value, i) => {
                                                                        return (
                                                                            address_state_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.State} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-address_country" className="initialized" name="address_country" ref="address_country">
                                                                    {this.state.address_country && <option value={this.state.address_country} selected>{this.state.address_country}</option>}
                                                                    <option value="Select Country">{this.Language.DataFieldDefault.SelectCountry}</option>
                                                                    {this.state.select_field_values_address_country.map((address_country_value, i) => {
                                                                        return (
                                                                            address_country_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Country} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="mailing_address" className="materialize-textarea" data-length={this.state.text_limit} ref="mailing_address" value={this.state.mailing_address} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="mailing_address" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.mailing_address && this.state.mailing_address !== null && typeof this.state.mailing_address !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.MailingAddress} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <textarea id="alternate_address" className="materialize-textarea" data-length={this.state.text_limit} ref="alternate_address" value={this.state.alternate_address} onChange={this.handleInputChange}></textarea>
                                                            <label htmlFor="alternate_address" data-error={this.Language.DataFieldError.MaxLength} className={(this.state.alternate_address && this.state.alternate_address !== null && typeof this.state.alternate_address !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.AlternateAddress}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s3">
                                                            <input id="post_zip_code" name="post_zip_code" type="text" ref="post_zip_code" value={this.state.post_zip_code} onChange={this.handleInputChange} />
                                                            <label htmlFor="post_zip_code" className={(this.state.post_zip_code && this.state.post_zip_code !== null && typeof this.state.post_zip_code !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.ZipCode}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s3">
                                                            <input id="postal_area" name="postal_area" type="text" ref="postal_area" value={this.state.postal_area} onChange={this.handleInputChange} />
                                                            <label htmlFor="postal_area" className={(this.state.postal_area && this.state.postal_area !== null && typeof this.state.postal_area !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.PostalArea}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="is_approved col s4">
                                                            <p>
                                                                <input type="checkbox" id="is_approved" ref="is_approved" checked={this.state.is_approved} onChange={this.handleCheckBoxChange} />
                                                                <label htmlFor="is_approved">{this.Language.DataField.IsApproved}</label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivatePartner}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivatePartner}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            {/* <input type="submit" value="Save User Account" className="btn cyan waves-effect waves-light" /> */}
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_partner_button">
                                                                {this.Language.ButtonLabel.UpdatePartner}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s6">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/partners/${partnerId}`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditPartner;