import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';




class LoginConfiguration extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getLoginConfiguration = this.getLoginConfiguration.bind(this);
        this.getAccessScopes = this.getAccessScopes.bind(this);
        this.state = {
            loginConfigurationDetails: [],
            check_ip_address: false,
            login_timeout_interval_seconds: 0,
            max_failed_attempt: 5,
            require_validation_on_new_device_use: false,
            allow_mutiple_login_per_device: false,
            send_notification_on_new_login: false,
            send_notification_on_new_device_use: false,
            send_email_on_new_login: false,
            send_email_on_new_device_use: false,
            send_sms_on_new_login: false,
            send_sms_on_new_device_use: false,
            global_two_step_verification: false,
            show_login_message: false,
            login_message: '',
            security_question1: '',
            security_question2: '',
            security_question3: '',
            hash_id: '',
            status: '',
            result: '',
            message: '',
            response_status: '',
            preloader: true,
            logged_in_username: '',
            hash_user: '',
            login_config_update: null,
            scopes: {}
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        
    }


    componentWillMount(){
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.LoginConfiguration + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();

    }



    componentDidUpdate(){
       
    }



    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile){
            this.setState({scopes: user_profile.scopes,
                logged_in_username: user_profile.username,
                hash_user: user_profile.user}, () => {
                this.checkAccessRight();
            });
            
        }

    }


    checkAccessRight(){

        if ( (this.Auth.checkAccessScope(['read:login_config', 'action:login_config'], 'view') === false) ){
             this.props.history.replace(`/`);   
        } else {
            this.getAccessScopes();
            this.getLoginConfiguration();
        };
 
     }



     getAccessScopes(){

        // Check the views scope
        if (this.state.scopes['views']){
            let len = this.state.scopes['views'].length;

            for (let i = 0; i < len; i++) {
                if (this.state.scopes['views'][i] === 'update:login_config' || this.state.scopes['views'][i] === 'action:login_config') this.setState({login_config_update: true});
                
            }
        };

    }



     getLoginConfiguration(){

        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);


        axios.get(`${this.apiURL}/api/v1/authenticate/login/config?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {


                this.setState({
                    loginConfigurationDetails: response.data.data,
                    check_ip_address: (response.data.data[0].check_ip_address === true || response.data.data[0].check_ip_address === 'true')? true : false || false,
                    login_timeout_interval_seconds: response.data.data[0].login_timeout_interval_seconds || this.state.login_timeout_interval_seconds,
                    max_failed_attempt: response.data.data[0].max_failed_attempt || this.state.max_failed_attempt,
                    require_validation_on_new_device_use: (response.data.data[0].require_validation_on_new_device_use === true || response.data.data[0].require_validation_on_new_device_use === 'true')? true : false || false,
                    allow_mutiple_login_per_device: (response.data.data[0].allow_mutiple_login_per_device === true || response.data.data[0].allow_mutiple_login_per_device === 'true')? true : false || false,
                    send_notification_on_new_login: (response.data.data[0].send_notification_on_new_login === true || response.data.data[0].send_notification_on_new_login === 'true')? true : false || false,
                    send_notification_on_new_device_use: (response.data.data[0].send_notification_on_new_device_use === true || response.data.data[0].send_notification_on_new_device_use === 'true')? true : false || false,
                    send_email_on_new_login: (response.data.data[0].send_email_on_new_login === true || response.data.data[0].send_email_on_new_login === 'true')? true : false || false,
                    send_email_on_new_device_use: (response.data.data[0].send_email_on_new_device_use === true || response.data.data[0].send_email_on_new_device_use === 'true')? true : false || false,
                    send_sms_on_new_login: (response.data.data[0].send_sms_on_new_login === true || response.data.data[0].send_sms_on_new_login === 'true')? true : false || false,
                    send_sms_on_new_device_use: (response.data.data[0].send_sms_on_new_device_use === true || response.data.data[0].send_sms_on_new_device_use === 'true')? true : false || false,
                    global_two_step_verification: (response.data.data[0].global_two_step_verification === true || response.data.data[0].global_two_step_verification === 'true')? true : false || false,
                    show_login_message: (response.data.data[0].show_login_message === true || response.data.data[0].show_login_message === 'true')? true : false || false,
                    login_message: response.data.data[0].login_message || '',
                    security_question1: response.data.data[0].security_question1 || '',
                    security_question2: response.data.data[0].security_question2 || '',
                    security_question3: response.data.data[0].security_question3 || '',
                    hash_id: response.data.data[0].hash_id || '',
                    status: response.data.data[0].status || ''
                });



                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })

                this.setState({preloader: null});
                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  


                    if(error.response.data.message[0].msg){

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                            if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }
                    

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                

              });

              this.setState({preloader: null});

    }






    render(){

        const loginConfigCards = this.state.loginConfigurationDetails.map((loginConfig,  i) => {


                return(
                    [         
                        <ul id="profile-page-about-details" className="collection z-depth-1" key={loginConfig.hash_id}>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.CheckUserIPAddress}:
                                    </div>
                                    <div className="col s7 right-align"><b>{(loginConfig.check_ip_address !== null && typeof loginConfig.check_ip_address !== 'undefined') ?  ((loginConfig.check_ip_address === 'true') || (loginConfig.check_ip_address === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.InactivityTimeoutInterval}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.login_timeout_interval_seconds}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                    {this.Language.DataField.MaxNumberofFailedLoginAttempts}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.max_failed_attempt}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                    {this.Language.DataField.RequireValidationforNewDevices}:
                                    </div>
                                    <div className="col s7 right-align"><b>{(loginConfig.require_validation_on_new_device_use !== null && typeof loginConfig.require_validation_on_new_device_use !== 'undefined')?  ((loginConfig.require_validation_on_new_device_use === 'true') || (loginConfig.require_validation_on_new_device_use === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                    {this.Language.DataField.AllowMultipleUserLoginsOnADevice}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.allow_mutiple_login_per_device !== null && typeof loginConfig.allow_mutiple_login_per_device !== 'undefined' ?  ((loginConfig.allow_mutiple_login_per_device === 'true') || (loginConfig.allow_mutiple_login_per_device === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SendNotificationOnNewLogin}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.send_notification_on_new_login !== null && typeof loginConfig.send_notification_on_new_login !== 'undefined' ?  ((loginConfig.send_notification_on_new_login === 'true') || (loginConfig.send_notification_on_new_login === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SendNotificationOnNewDeviceLogins}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.send_notification_on_new_device_use !== null && typeof loginConfig.send_notification_on_new_device_use !== 'undefined' ?  ((loginConfig.send_notification_on_new_device_use === 'true') || (loginConfig.send_notification_on_new_device_use === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SendEmailOnNewLogin}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.send_email_on_new_login !== null && typeof loginConfig.send_email_on_new_login !== 'undefined' ?  ((loginConfig.send_email_on_new_login === 'true') || (loginConfig.send_email_on_new_login === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SendEmailOnNewDeviceLogins}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.send_email_on_new_device_use !== null && typeof loginConfig.send_email_on_new_device_use !== 'undefined' ?  ((loginConfig.send_email_on_new_device_use === 'true') || (loginConfig.send_email_on_new_device_use === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SendSMSOnNewLogin}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.send_sms_on_new_login !== null && typeof loginConfig.send_sms_on_new_login !== 'undefined' ?  ((loginConfig.send_sms_on_new_login === 'true') || (loginConfig.send_sms_on_new_login === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                         {this.Language.DataField.SendSMSOnNewDeviceLogins}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.send_sms_on_new_device_use !== null && typeof loginConfig.send_sms_on_new_device_use !== 'undefined' ?  ((loginConfig.send_sms_on_new_device_use === 'true') || (loginConfig.send_sms_on_new_device_use === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.RequireTwoStepAuthenticationForAllUsers}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.global_two_step_verification !== null && typeof loginConfig.global_two_step_verification !== 'undefined' ?  ((loginConfig.global_two_step_verification === 'true') || (loginConfig.global_two_step_verification === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.ShowLoginMessage}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.show_login_message !== null && typeof loginConfig.show_login_message !== 'undefined' ?  ((loginConfig.show_login_message === 'true') || (loginConfig.show_login_message === true) ?  <span className="green-text">{this.Language.DataValue.Yes}</span> : <span className="black-text">{this.Language.DataValue.No}</span>) : null }</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.LoginMessage}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.login_message}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SecurityQuestion1}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.security_question1}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SecurityQuestion2}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.security_question2}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.SecurityQuestion3}:
                                    </div>
                                    <div className="col s7 right-align"><b>{loginConfig.security_question3}</b></div>
                                </div>
                            </li>
                            <li className="collection-item">
                                <div className="row">
                                    <div className="col s5">
                                        {this.Language.DataField.Status}:
                                    </div>
                                    <div className="col s7 right-align"><b>{(loginConfig.status === 'Active') ? this.Language.DataValue.Active : this.Language.DataValue.Inactive}</b></div>
                                </div>
                            </li>
                        </ul>,
                        <br key={loginConfig.hash_id + 1}/>,
                    ]
                )

        })
        



        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Security"} active_menu_level_2={"Login Configuration"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.LoginConfiguration}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.LoginConfiguration]} links={[`/users`, `/security/login/configuration`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">


                {this.state.preloader   &&  <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                    <div id="profile-page-sidebar" className="col s12 m7" >
                                        <div id="profile-page-content" className="row">
                                                {loginConfigCards}
                                            
    {(this.state.loginConfigurationDetails.length > 0 && this.state.login_config_update) ?   
    <Link className="waves-effect waves-light btn" to={`/security/login/configuration/edit/${this.state.hash_id}`}><i className="material-icons left">edit</i>{this.Language.ButtonLabel.Edit}</Link> : <Link className="waves-effect waves-light btn" to={`/security/login/configuration/add`}><i className="material-icons left">add</i>{this.Language.ButtonLabel.Add}</Link>
    }

                                        </div>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]  
        )
    }
}

export default LoginConfiguration;