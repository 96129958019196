import React, {Component} from 'react';


class DataChartAnalyticsV2 extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: props.title || '',
            title2: props.title2 || '',
            cardColour: props.cardColour || 'teal',
            textColour: props.textColour || 'white',
            filterColour: props.filterColour || 'red',
            chartElement1: props.chartElement1 || null,
            chartElement2: props.chartElement2 || null,
            cardSizeSmallScreen: props.cardSizeSmallScreen || '12',
            cardSizeMediumScreen: props.cardSizeMediumScreen || '4',
            cardSizeLargeScreen: props.cardSizeLargeScreen || '4',
            cardRevealTitle: props.cardRevealTitle || '',
            dataTableHeader: props.dataTableHeader || [],
            dataTableRows: props.dataTableRows || [[]]
        }

    }


    render(){



        
        return (
            // <!--Data Chart Analytics Version 2 start-->
            <div className={`col s${this.state.cardSizeSmallScreen} m${this.state.cardSizeMediumScreen} l${this.state.cardSizeLargeScreen}`}>
                  <div className="card">
                    <div className={`card-move-up ${this.state.cardColour} accent-4 waves-effect waves-block waves-light`}>
                      <div className="move-up">
                        <p className={`margin ${this.state.textColour}-text`}>{this.state.title}</p>
                        {this.state.chartElement1}
                      </div>
                    </div>
                    <div className={`card-content  ${this.state.cardColour}`}>
                      <a href={"#!"} className={`btn-floating btn-move-up waves-effect waves-light ${this.state.filterColour} accent-2 z-depth-4 right`}>
                        <i className="material-icons activator">done</i>
                      </a>
                      <div className="line-chart-wrapper">
                        <p className={`margin ${this.state.textColour}-text`}>{this.state.title2}</p>
                        {this.state.chartElement2}
                      </div>
                    </div>
                    <div className="card-reveal">
                      <span className="card-title grey-text text-darken-4">{this.state.cardRevealTitle}
                        <i className="material-icons right">close</i>
                      </span>
                      <table className="responsive-table">
                        <thead>
                          <tr>
                          {this.state.dataTableHeader.map((header,  i) => {

                            return (
                              <th key={'th' + i} data-field={header}>{header}</th>
                            )

                            })}
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.dataTableRows.map((rowitems,  i) => {
                                                    
                          return (
                            <tr key={'tr' + i}>
                              {rowitems.map((rowitem, j) => {
                                return (
                                  <td key={'td' + i + j}>{rowitem}</td>
                                )
                              })}
                            </tr>
                          )

                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            // <!--Data Chart Analytics Version 2 end-->
        )
    }
}

export default DataChartAnalyticsV2;