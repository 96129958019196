import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CircularButtonItem from './CircularButtonItem';
import AuthService from '../modules/AuthService';

class FloatingActionButton extends Component{
    constructor(props){
        super(props);
        this.getProfileDetails = this.getProfileDetails.bind(this);
        this.state = {
            hash_user: '',
            username: '',
            user_role: '',
            user_scopes: null,
            organisation: '',
            language: props.language,
        };
        this.Auth = new AuthService();
        
    }

    componentWillMount(){
        this.getProfileDetails();
    }



    getProfileDetails(){

        let user_profile = this.Auth.getProfile();

        if (user_profile !== null){
            this.setState({hash_user: user_profile.user,
                username: user_profile.username,
                user_role: user_profile.user_role,
                user_scopes: user_profile.scopes,
                organisation: user_profile.organisation
            });
        }
    }


    render(){
        
        const circularButtonItems = this.state.user_scopes.views.map((accessScope,  i) => {
            return(
                <CircularButtonItem key={i} accessScope={accessScope} hash_user={this.state.hash_user} hash_exporter={this.state.organisation} language={this.state.language}/>
            )
        })

        let default_dashboard = '/home';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_dashboard = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        return (
                // <!-- Floating Action Button -->
                <div className="fixed-action-btn vertical" style={{bottom: 50, right:19}}>
                    <a href={"#!"} className="btn-floating btn-large tooltipped teal gradient-shadow" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.AddNewItem}>
                        <i className="large material-icons">add</i>
                    </a>
                    <ul>
                        <li><Link to={default_dashboard} className="btn-floating tooltipped red" data-position="left" data-delay="50" data-tooltip={this.state.language.ToolTip.ViewDashboard}><i className="material-icons">assessment</i></Link></li>
                        {circularButtonItems}
                    </ul>
                </div>
                // <!-- Floating Action Button -->
        )
    }
}

export default FloatingActionButton;