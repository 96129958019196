import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import AuthService from '../modules/AuthService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import DataTable from '../components/DataTable';
import FloatingActionButton from '../components/FloatingActionButton';
import LanguageService from '../modules/LanguageService';
import DateService from '../modules/DateService';
import URLService from '../modules/URLService';

import axios from 'axios';



class UserPasswordHistory extends Component{
    constructor(props){
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.getUserPasswordHistory = this.getUserPasswordHistory.bind(this);
        this.state = {
            userPasswordHistory: null,
            result: '',
            message: '',
            preloader: true,
            userPasswordHistoryDataItems:  null
        }
        this.Auth = new AuthService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.DateService = new DateService();
        this.apiURL = URLService.prototype.getApiURL();
        this.returnURLQuery = URLService.prototype.getReturnURLQuery();
        
    }


    componentWillMount(){
        
    }

    componentDidMount(){
        document.title = this.Language.PageTitle.UserPasswordChangeHistory + ' - ' + this.Language.EEGWebPortal;
        this.getProfile();
        this.getUserPasswordHistory();
        
    }


    getProfile(){

        let user_profile = this.Auth.getProfile();

        if (user_profile){
            this.setState({logged_in_username: user_profile.username,
                hash_user: user_profile.user}, () => {
                this.checkAccessRight();
            });
            
        }

    }


    checkAccessRight(){

        if ( (this.Auth.checkAccessScope(['read:user', 'read:change_password', 'action:change_password'], 'view') === false || this.state.hash_user !== this.props.match.params.id) ){
            this.props.history.replace(`/user/profile/${this.state.hash_user}`);   
       }

    }





    getUserPasswordHistory(){

        let userId = this.props.match.params.id;
        const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

        axios.get(`${this.apiURL}/api/v1/user/password/history/${userId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
            .then(response => {

                this.setState({userPasswordHistory: response.data.data}, () => {
                    

                    // note that user_device_id was used in the table below. You will need to fix the query inserting the password change history in savePasswordHistory
                    // under the user_account model. fixing the insert will help ensure that the user_device_id is placed in the user_device_id field instead of the device_name
                    // becuase I am inserting the device+name I have chosen to user user_device_id in the table below

                    this.setState({userPasswordHistoryDataItems: <DataTable tableName={''} 
                                    fields={['username', 'date_changed', 'user_device_id', 'browser']}
                                    fieldDataTypes={['String', 'Date', 'String', 'String']}
                                    fieldDecimalPlaces={[0, 0, 0, 0]}
                                    dateFormat={'long_date'}
                                    timeFormat={'h12'}
                                    headers={[this.Language.DataTableHeader.Username, this.Language.DataTableHeader.PasswordChangeDate, this.Language.DataTableHeader.DeviceName, this.Language.DataTableHeader.Browser]} 
                                    data={this.state.userPasswordHistory} 
                                    sortType={"descending"} sortPosition={1} thStyle={[325, 300, 280, 280]}
                                    link={"/user/profile/"} return_query_prefix={this.returnURLQuery} current_path={this.props.history.location.pathname} link_status={false} linkToBlankTarget={false} linkToBlankTargetToken={''} download={false} downloadFileName={this.Language.DownloadFileName.PasswordChangeHistory} emptyMessage={this.Language.EmptyMessage.PasswordChangeHistory} 
                                    downloadFields={['username', 'date_changed', 'user_device_id', 'browser', 'organisation_name']}/> 
                                    })

                    this.setState({preloader: null});
                })

                this.setState({result: response.data.status}, () => {
                    
                })

                this.setState({message: response.data.message}, () => {
                    
                })

                
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  

                  if (error.response.status === 500 ) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                  

                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  
                } else {
                  // Something happened in setting up the request that triggered an Error
                  
                }
                
              });

    }



    render(){

         return ( 
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language}/>,
                
                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Settings"} active_menu_level_2={"Password History"} history={this.props.history} language={this.Language}/>
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.UserPasswordChangeHistory}`} breadcrumbs={[this.Language.BreadCrumbLabel.UserManagement, this.Language.BreadCrumbLabel.PasswordChangeHistory]} links={[`/`, `/user/profile/password/history/`]} language={this.Language}/>
                            {/* <!--start container--> */}
                            <div className="container">
                                <br/>
                                <br/>
                                {this.state.preloader   &&  <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}
                                    {this.state.userPasswordHistoryDataItems}
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language}/>
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language}/>
            ]
            
        )
    }
}

export default UserPasswordHistory;