import React from 'react';
import LanguageService from './LanguageService';

const getTranslation = LanguageService.prototype.getTranslation();
const language = getTranslation.default;

export default class ValidationService {


    validation(close_error_messages, title, fieldValues, fieldNames, validations, minLenght, maxLength) {
        try {

            if ((fieldValues && fieldNames && validations && (minLenght >= 0) && (maxLength >= 0))) { // Check that all parameters exists

                if ((fieldValues.length === fieldNames.length && fieldValues.length === validations.length)) {

                    let errorCount = 0;
                    let regex = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
                    let regexPhone = /[A-Z]/gi;
                    // let regexNumber = /[A-Z]/gi;
                    let regexPhone2 = /[`~!@#$%^&*_|=?;:'",.<>{}[\]\\/]/g;
                    // let regexNumber2 = /[`~!@#$%^&*_|+-=?;:'",.<>{}[\]\\/]/g;


                    const fieldValidations = fieldValues.map((fieldValue, i) => {

                        if (validations[i] === "isRequired" && !fieldValue.toString().trim().length) {
                            errorCount = errorCount + 1;
                            return (
                                <p key={i}>{`${errorCount}.${language.ReactDataValidation.isRequired.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.isRequired.EndPhrase}`}</p>
                            )
                        }

                        if (validations[i] === "isRequiredDate" && (!fieldValue.toString().trim().length || fieldValue.toString().trim() === '1970-01-01 00:00:00+00')) {
                            errorCount = errorCount + 1;
                            return (
                                <p key={i}>{`${errorCount}.${language.ReactDataValidation.isRequiredDate.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.isRequiredDate.EndPhrase}`}</p>
                            )
                        }


                        if (validations[i] === "isEmail" && !regex.test(String(fieldValue).toLowerCase())) {
                            errorCount = errorCount + 1;
                            return (
                                <p key={i}>{`${errorCount}.${language.ReactDataValidation.isEmail.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.isEmail.EndPhrase}`}</p>
                            )
                        }

                        if (validations[i] === "minLength" && fieldValue.toString().length < minLenght) {
                            errorCount = errorCount + 1;
                            return (
                                <p key={i}>{`${errorCount}.${language.ReactDataValidation.minLength.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.minLength.EndPhrase} ${minLenght} ${language.ReactDataValidation.minLength.OtherPhrase}`}</p>
                            )
                        }

                        if (validations[i] === "maxLength" && fieldValue.toString().length > maxLength) {
                            errorCount = errorCount + 1;
                            return (
                                <p key={i}>{`${errorCount}.${language.ReactDataValidation.maxLength.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.maxLength.EndPhrase} ${maxLength}  ${language.ReactDataValidation.maxLength.OtherPhrase}`}</p>
                            )
                        }


                        if (validations[i] === "isPhone" && ((fieldValue.search(regexPhone) >= 0) || (fieldValue.search(regexPhone2) >= 0))) {
                            errorCount = errorCount + 1;
                            return (
                                <p key={i}>{`${errorCount}.${language.ReactDataValidation.isPhone.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.isPhone.EndPhrase}`}</p>
                            )
                        }


                        // if (validations[i] === "isNumber" && ((fieldValue.search(regexNumber) >= 0) || (fieldValue.search(regexNumber2) >= 0))) {
                        //     errorCount = errorCount + 1;
                        //     return(
                        //         <p key={i}>{`${errorCount}. ${fieldNames[i]} is not a valid number.`}</p>
                        //     )
                        // }



                        if (validations[i] === "isNumber") {

                            fieldValue = fieldValue.replace(/,/, ".");


                            // check if the fieldvalue is a valid number or is a negative number
                            if ((!isNaN(parseFloat(fieldValue)) && isFinite(fieldValue)) === false || (Math.sign(fieldValue) === -1 || Math.sign(fieldValue) === -0 || isNaN(Math.sign(fieldValue)) === true)) {
                                errorCount = errorCount + 1;
                                return (
                                    <p key={i}>{`${errorCount}.${language.ReactDataValidation.isNumber.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.isNumber.EndPhrase}`}</p>
                                )
                            }


                        }


                        if (validations[i] === "isArray" && fieldValue.constructor !== Array && (fieldValue.length <= 0 || typeof fieldValue.length === 'undefined' || fieldValue === null)) {
                            errorCount = errorCount + 1;
                            return (
                                <p key={i}>{`${errorCount}.${language.ReactDataValidation.isArray.BeginningPhrase} ${fieldNames[i]} ${language.ReactDataValidation.isArray.EndPhrase}`}</p>
                            )
                        }



                        if (validations[i] === "ShowCustomMessage") {

                            // check if the fieldvalue is true or false to determine whether to show the custom message or not
                            if (fieldValue === true) {
                                errorCount = errorCount + 1;
                                return (
                                    <p key={i}>{`${errorCount}. ${fieldNames[i]}`}</p>
                                )
                            }

                        }


                        return (
                            null // return null if the validation criteria is not met
                        )

                    })



                    if (errorCount > 0) {

                        return (

                            <div id="card-alert" className="card red lighten-4">
                                <div className="card-content red-text">
                                    <span className="card-title red-text darken-1">
                                        <i className="material-icons">error</i> {title}</span>
                                    {fieldValidations}
                                </div>
                                {close_error_messages}
                            </div>

                        )

                    } else {
                        return null;
                    }


                } else {
                    return (
                        <div id="card-alert" className="card red lighten-4">
                            <div className="card-content red-text">
                                <span className="card-title red-text darken-1">
                                    <i className="material-icons">error</i> {title}</span>
                                <p>{language.ReactDataValidation.ReviewFormComment}</p>
                            </div>
                            {close_error_messages}
                        </div>
                    )
                }


            } else {

                return (
                    <div id="card-alert" className="card red lighten-4">
                        <div className="card-content red-text">
                            <span className="card-title red-text darken-1">
                                <i className="material-icons">error</i> {title}</span>
                            <p>{language.ReactDataValidation.ReviewFormComment}</p>
                        </div>
                        {close_error_messages}
                    </div>
                )
            }

        }
        catch (error) {
            return null;
        }
    }


}