import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
import DateService from '../modules/DateService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import FormatService from '../modules/FormatService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditECC extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.checkAccessRight = this.checkAccessRight.bind(this);
    this.updateECC = this.updateECC.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.getECC = this.getECC.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getDropdownValues = this.getDropdownValues.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.fillNXPNumberDropdown = this.fillNXPNumberDropdown.bind(this);
    this.getExportYears = this.getExportYears.bind(this);
    this.state = {
      certificate_number: '',
      date_issued: '',
      exporter_id: '',
      selected_exporter_id: '',
      exporter_name: '',
      nxp_number: '',
      selected_nxp_number: '',
      export_year: '',
      currency: '',
      certificate_value: '',
      certificate_value_words: '',
      value_utilised: '',
      balance_value: '',
      utilising_agency: '',
      is_utilised: false,
      is_utilised_initial_value: false,
      utilised_by: '',
      utilised_at: '',
      is_transfered: false,
      is_transfered_initial_value: false,
      transfered_by: '',
      transfered_at: '',
      transfered_to: '',
      transfer_approved: false,
      transfer_approved_initial_value: false,
      transfer_approved_by: '',
      transfer_approved_at: '',
      status: '',
      status_active: false,
      is_approved: false,
      is_approved_initial_value: false,
      approved_by: '',
      approved_at: '',
      last_updated_on_check: '',
      hash_id: String,
      result: '',
      message: '',
      response_status: '',
      selected_export_year: '',
      pick_list_values: [],
      select_field_values_exporter: [],
      select_field_values_currency: [],
      select_field_values_organisation: [],
      select_field_values_nxp_number: [],
      select_field_values_export_year: [],
      preloader: true,
      logged_in_username: '',
      error_messages: null,
      close_error_messages: (
        <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
          <span aria-hidden="true">×</span>
        </button>
      )
    };
    this.Auth = new AuthService();
    this.Validate = new ValidationService();
    this.DateService = new DateService();
    this.Browser = new BrowserService();
    this.getTranslation = LanguageService.prototype.getTranslation();
    this.Language = this.getTranslation.default;
    this.Format = new FormatService();
    this.apiURL = URLService.prototype.getApiURL();
  }

  componentWillMount() {}

  componentDidMount() {
    
    this.getProfile();
    this.checkAccessRight();
    this.getECC();

    const monthsFull = this.Language.DatePicker.monthsFull.split(',');
    const monthsShort = this.Language.DatePicker.monthsShort.split(',');
    const weekdaysFull = this.Language.DatePicker.weekdaysFull.split(',');
    const weekdaysShort = this.Language.DatePicker.weekdaysShort.split(',');
    const today = this.Language.DatePicker.today;
    const clear = this.Language.DatePicker.clear;
    const close = this.Language.DatePicker.close;
    const labelMonthNext = this.Language.DatePicker.labelMonthNext;
    const labelMonthPrev = this.Language.DatePicker.labelMonthPrev;
    const labelMonthSelect = this.Language.DatePicker.labelMonthSelect;
    const labelYearSelect = this.Language.DatePicker.labelYearSelect;

    window.$('.datepicker').pickadate({
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      today: today,
      clear: clear,
      close: close,
      labelMonthNext: labelMonthNext,
      labelMonthPrev: labelMonthPrev,
      labelMonthSelect: labelMonthSelect,
      labelYearSelect: labelYearSelect
      // firstDay: 1,
      // format: 'dd mmmm yyyy',
      // formatSubmit: 'yyyy/mm/dd',
      // selectMonths: true,
      // selectYears: true,
      // selectMonths: true, // Creates a dropdown to control month
      // selectYears: 15, // Creates a dropdown of 15 years to control year,
      // closeOnSelect: false, // Close upon selecting a date,
      // container: undefined, // ex. 'body' will append picker to body
    });

    window.$(ReactDOM.findDOMNode(this.refs.export_year)).on('change', this.fillNXPNumberDropdown.bind(this));
    window.$(ReactDOM.findDOMNode(this.refs.exporter_id)).on('change', this.fillNXPNumberDropdown.bind(this));
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    $('select').material_select('destroy');
  }

  getProfile() {
    let user_profile = this.Auth.getProfile();

    if (user_profile) {
      this.setState({logged_in_username: user_profile.username});
    }
  }

  checkAccessRight() {
    if (this.Auth.checkAccessScope(['update:eccs', 'action:eccs'], 'view') === false) {
      this.props.history.replace('/exporters/ecc');
    } else {
      this.getDropdownValues('exporter,currency,organisation,nxp_number_by_year_and_exporter');
      this.getExportYears();
    }
  }

  handleCloseAlert() {
    this.setState({error_messages: null}); // change the value of error_message to close the alert box
  }

  getECC() {
    let eccId = this.props.match.params.id;
    const user_time_zone = this.DateService.getUserTimeZoneKeyValue(false);

    axios
      .get(`${this.apiURL}/api/v1/eccs/specific/${eccId}?tk=${this.Auth.getToken()}&tz=${user_time_zone}`)
      .then(response => {
        this.setState({
          certificate_number: response.data.data[0].certificate_number || '',
          date_issued: this.DateService.convertToCalendarFullDate(response.data.data[0].date_issued),
          exporter_id: response.data.data[0].exporter_id || '',
          selected_exporter_id: response.data.data[0].exporter_id || '',
          exporter_name: response.data.data[0].exporter_name || '',
          nxp_number: response.data.data[0].nxp_number || '',
          selected_nxp_number: response.data.data[0].nxp_number || '',
          export_year: response.data.data[0].export_year || '',
          selected_export_year: response.data.data[0].export_year || '',
          currency: response.data.data[0].currency || '',
          certificate_value: response.data.data[0].new_certificate_value || '',
          certificate_value_words: response.data.data[0].certificate_value_words || '',
          value_utilised: response.data.data[0].new_value_utilised || '',
          balance_value: response.data.data[0].new_balance_value || '',
          utilising_agency: response.data.data[0].utilising_agency || '',
          is_utilised: response.data.data[0].is_utilised === true || response.data.data[0].is_utilised === 'true' ? true : false || false,
          is_utilised_initial_value: response.data.data[0].is_utilised === 'true' || response.data.data[0].is_utilised === true ? true : false || false,
          utilised_by: response.data.data[0].utilised_by || '',
          utilised_at: this.DateService.convertToCalendarFullDate(response.data.data[0].utilised_at),
          is_transfered: response.data.data[0].is_transfered === true || response.data.data[0].is_transfered === 'true' ? true : false || false,
          is_transfered_initial_value: response.data.data[0].is_transfered === 'true' || response.data.data[0].is_transfered === true ? true : false || false,
          transfered_by: response.data.data[0].transfered_by || '',
          transfered_at: this.DateService.convertToCalendarFullDate(response.data.data[0].transfered_at),
          transfered_to: response.data.data[0].transfered_to || 'Enter name of company',
          transfer_approved: response.data.data[0].transfer_approved === true || response.data.data[0].transfer_approved === 'true' ? true : false || false,
          transfer_approved_initial_value: response.data.data[0].transfer_approved === 'true' || response.data.data[0].transfer_approved === true ? true : false || false,
          transfer_approved_by: response.data.data[0].transfer_approved_by || '',
          transfer_approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].transfer_approved_at),
          is_approved: response.data.data[0].is_approved === true || response.data.data[0].is_approved === 'true' ? true : false || false,
          is_approved_initial_value: response.data.data[0].is_approved === true || response.data.data[0].is_approved === 'true' ? true : false || false,
          approved_by: response.data.data[0].approved_by || '',
          approved_at: this.DateService.convertToCalendarFullDate(response.data.data[0].approved_at),
          status: response.data.data[0].status,
          last_updated_on_check: response.data.data[0].last_updated_on_check || ''
        }, () => {
          document.title = this.Language.PageTitle.EditECC + ' ' + this.state.certificate_number + ' - ' + this.Language.EEGWebPortal;
        });


        this.setState({status_active: this.state.status === 'Active' ? true : false});

        this.setState({result: response.data.status}, () => {
        
        });

        this.setState({message: response.data.message}, () => {
        
        });

        window.$('select').material_select();
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          

          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    let certificate_value = this.refs.certificate_value.value || 0;
    let value_utilised = this.refs.value_utilised.value || 0;
    let transfered_to = this.refs.transfered_to.value.toLowerCase();

    this.setState({
      [name]: value
    });

    if (name === 'certificate_value') {
      this.setState({certificate_value_words: this.Format.numberToWords(value, 'and') + ' ' + this.Language.DataField.NairaOnly});

      if (!isNaN(parseFloat(certificate_value)) && isFinite(certificate_value) && !isNaN(parseFloat(value_utilised)) && isFinite(value_utilised)) {
        this.setState({balance_value: certificate_value - value_utilised});
      } else {
        this.setState({balance_value: 0});
      }
    }

    if (name === 'value_utilised') {
      if (!isNaN(parseFloat(certificate_value)) && isFinite(certificate_value) && !isNaN(parseFloat(value_utilised)) && isFinite(value_utilised)) {
        this.setState({balance_value: certificate_value - value_utilised});
      } else {
        this.setState({balance_value: 0});
      }
    }

    if (name === 'transfered_to' && transfered_to === 'enter name of company') {
      $('#transfered_to').val('');
    }

    if (name === 'transfered_to' && transfered_to === '') {
      $('#transfered_to').val('Enter name of company');
    }
  }

  handleCheckBoxChange(event) {
    const target = event.target;
    // const checked = target.checked;
    const name = target.id;

    if (name === 'activate_switch') {
      this.setState({
        status_active: !this.state.status_active,
        status: this.state.status === 'Active' ? 'Inactive' : 'Active'
      });
    }

    if (name === 'is_utilised') {
      this.setState({[name]: !this.state.is_utilised});
    }
    if (name === 'is_transfered') {
      this.setState({[name]: !this.state.is_transfered});
    }
    if (name === 'transfer_approved') {
      this.setState({[name]: !this.state.transfer_approved});
    }
    if (name === 'is_approved') {
      this.setState({[name]: !this.state.is_approved});
    }
  }

  onSubmit(event) {
    event.preventDefault();

    if (window.confirm(`${this.Language.ConfirmMessage.SaveECC} ${this.refs.certificate_number.value.trim()}`)) {
      let certificate_number = this.refs.certificate_number.value.trim();
      let date_issued = this.DateService.convertToFullDate(this.refs.date_issued.value);

      let exporter_id = this.refs.exporter_id.value;
      if (exporter_id === 'Select Exporter') exporter_id = '';

      let nxp_number = this.refs.nxp_number.value;
      if (nxp_number === 'Select NXP Number') nxp_number = '';

      let currency = this.refs.currency.value;
      if (currency === 'Select Currency') currency = '';

      let certificate_value = this.refs.certificate_value.value;
      // let certificate_value_words = this.refs.certificate_value_words.value;

      let temp_certificate_value = certificate_value || 0;
      let certificate_value_words = this.Format.numberToWords(temp_certificate_value, 'and') + ' ' + this.Language.DataField.NairaOnly;

      let value_utilised = this.refs.value_utilised.value;
      let balance_value = this.refs.balance_value.value;

      if (value_utilised === '0') value_utilised = 0;

      let utilising_agency = this.refs.utilising_agency.value;
      if (utilising_agency === 'Select Utilising Agency') utilising_agency = '';

      let currentdate = new Date();

      let is_utilised_initial_value = this.state.is_utilised_initial_value;
      let is_utilised = this.refs.is_utilised.checked;
      let utilised_by = null;
      let utilised_at = null;
      if (is_utilised_initial_value === true && is_utilised === true) {
        utilised_by = this.state.utilised_by;
        utilised_at = this.state.utilised_at;
      }
      if (is_utilised_initial_value === false && is_utilised === true) {
        utilised_by = this.state.logged_in_username;
        utilised_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      let is_transfered_initial_value = this.state.is_transfered_initial_value;
      let is_transfered = this.refs.is_transfered.checked;
      let transfered_by = null;
      let transfered_at = null;
      if (is_transfered_initial_value === true && is_transfered === true) {
        transfered_by = this.state.transfered_by;
        transfered_at = this.state.transfered_at;
      }
      if (is_transfered_initial_value === false && is_transfered === true) {
        transfered_by = this.state.logged_in_username;
        transfered_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      let transfered_to = this.refs.transfered_to.value.toLowerCase();
      if (transfered_to === 'enter name of company') {
        transfered_to = '';
      } else {
        transfered_to = this.refs.transfered_to.value;
      }

      let transfer_approved_initial_value = this.state.transfer_approved_initial_value;
      let transfer_approved = this.refs.transfer_approved.checked;
      let transfer_approved_by = null;
      let transfer_approved_at = null;
      if (transfer_approved_initial_value === true && transfer_approved === true) {
        transfer_approved_by = this.state.transfer_approved_by;
        transfer_approved_at = this.state.transfer_approved_at;
      }
      if (transfer_approved_initial_value === false && transfer_approved === true) {
        transfer_approved_by = this.state.logged_in_username;
        transfer_approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      let is_approved_initial_value = this.state.is_approved_initial_value;
      let is_approved = this.refs.is_approved.checked;
      let approved_by = null;
      let approved_at = null;
      if (is_approved_initial_value === true && is_approved === true) {
        approved_by = this.state.approved_by;
        approved_at = this.state.approved_at;
      }
      if (is_approved_initial_value === false && is_approved === true) {
        approved_by = this.state.logged_in_username;
        approved_at = currentdate.toString(); // this date is updated on the API. The values assigned here is temporary
      }

      let status = 'Inactive';
      if (this.refs.activate_switch.checked) status = 'Active';
      if (!this.refs.activate_switch.checked) status = 'Inactive';

      let last_updated_by = this.state.logged_in_username;

      let last_updated_on_check = this.state.last_updated_on_check;

      this.setState(
        {
          error_messages: this.Validate.validation(
            this.state.close_error_messages,
            this.Language.ReactValidationMessage.ECC.AddErrorTitle,
            [
              certificate_number,
              date_issued,
              exporter_id,
              nxp_number,
              currency,
              certificate_value,
              certificate_value,
              value_utilised,
              value_utilised,
              balance_value,
              balance_value,
              utilising_agency,
              transfered_to
            ],
            [
              this.Language.DataField.CertificateNumber,
              this.Language.DataField.DateIssued,
              this.Language.DataField.Exporter,
              this.Language.DataField.NXPNumber,
              this.Language.DataField.Currency,
              this.Language.DataField.CertificateValue,
              this.Language.DataField.CertificateValue,
              this.Language.DataField.ValueUtilised,
              this.Language.DataField.ValueUtilised,
              this.Language.DataField.BalanceValue,
              this.Language.DataField.BalanceValue,
              this.Language.DataField.UtilisingAgency,
              this.Language.DataField.TransferredTo
            ],
            ['isRequired', 'isRequiredDate', 'isRequired', 'isRequired', 'isRequired', 'isRequired', 'isNumber', 'isRequired', 'isNumber', 'isRequired', 'isNumber', 'isRequired', 'isRequired'],
            0,
            0
          )
        },
        () => {
          if (this.state.error_messages === null) {
            if (exporter_id === 'Select Exporter') exporter_id = null;
            if (nxp_number === 'Select NXP Number') nxp_number = null;
            if (utilising_agency === 'Select Utilising Agency') utilising_agency = null;
            if (transfered_to === 'enter name of company') transfered_to = null;
            if (currency === 'Select Currency') currency = null;

            if (value_utilised === '0' || value_utilised === 0) {
              value_utilised = 0;
            } else {
              value_utilised = Math.abs(value_utilised);
            }

            const userOS = this.Browser.getOS();

            //make call to database
            const newECC = {
              certificate_number: certificate_number,
              date_issued: date_issued,
              exporter_id: exporter_id,
              nxp_number: nxp_number,
              currency: currency,
              certificate_value: Math.abs(certificate_value) || null,
              certificate_value_words: certificate_value_words,
              value_utilised: value_utilised,
              balance_value: Math.abs(balance_value) || null,
              utilising_agency: utilising_agency,
              is_utilised: is_utilised,
              utilised_by: utilised_by,
              utilised_at: utilised_at,
              is_transfered: is_transfered,
              transfered_by: transfered_by,
              transfered_at: transfered_at,
              transfered_to: transfered_to,
              transfer_approved: transfer_approved,
              transfer_approved_by: transfer_approved_by,
              transfer_approved_at: transfer_approved_at,
              status: status,
              is_approved: is_approved,
              approved_by: approved_by,
              approved_at: approved_at,
              last_updated_by: last_updated_by,
              last_updated_on_check: last_updated_on_check,
              device_name: this.state.logged_in_username + "'s " + userOS,
              device_model: userOS,
              device_os: userOS,
              browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
              location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
              app_source: 'web',
              token: this.Auth.getToken()
            };

            

            this.setState({preloader: true});
            document.getElementById('update_ecc_button').setAttribute('disabled', true);

            this.updateECC(newECC);
          } else {
            window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
          }
        }
      );
    }
  }

  getDropdownValues(pick_list_names) {
    axios
      .get(`${this.apiURL}/api/v1/settings/picklists`, {
        params: {
          tk: this.Auth.getToken(),
          pick_list_names: pick_list_names
        }
      })
      .then(response => {
        this.setState({pick_list_values: response.data.data}, () => {
        

          if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {
            const select_field_values_exporter = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'exporter') {
                return <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_exporter: select_field_values_exporter});

            const select_field_values_currency = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'currency') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_currency: select_field_values_currency});

            const select_field_values_organisation = this.state.pick_list_values.map((pick_list_value, i) => {
              if (pick_list_value.category === 'organisation') {
                return <SelectField pick_list_id={pick_list_value.item_name} key={i} item_name={pick_list_value.item_name} />;
              } else {
                return null;
              }
            });

            this.setState({select_field_values_organisation: select_field_values_organisation});

            const select_field_values_nxp_number = this.state.pick_list_values.map((pick_list_value, i) => {
              const temp_item_array = pick_list_value.item_name.split(':nxp_by_year_and_exporter:');
              const temp_nxp_number = temp_item_array[0];
              const temp_nxp_export_year = temp_item_array[1];
              const temp_nxp_exporter_id = temp_item_array[2];

              if (
                pick_list_value.category === 'nxp_number_by_year_and_exporter' &&
                this.state.selected_export_year.toString() === temp_nxp_export_year &&
                this.state.exporter_id.toString() === temp_nxp_exporter_id
              ) {
                return <SelectField pick_list_id={temp_nxp_number} key={i} item_name={temp_nxp_number} />;
              } else {
                return null;
              }
            });



            this.setState({select_field_values_nxp_number: select_field_values_nxp_number});


            const select_field_values_export_year = this.state.select_field_values_export_year.map((export_year_value, i) => {
              return <SelectField pick_list_id={export_year_value} key={i} item_name={export_year_value} />;
            });

            this.setState({select_field_values_export_year: select_field_values_export_year});
          }
        });

        window.$('select').material_select();
        this.setState({preloader: null});
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');
          }
        } else if (error.request) {
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        

        this.setState({preloader: null});
      });
  }

  fillNXPNumberDropdown() {
    // const export_year = this.refs.export_year.value;
    // this.setState({selected_export_year: export_year});

    // const select_field_values_nxp_number = this.state.pick_list_values.map((pick_list_value, i) => {
    //   const temp_item_array = pick_list_value.item_name.split(':nxp_by_year_and_exporter:');
    //   const temp_nxp_number = temp_item_array[0];
    //   const temp_nxp_export_year = temp_item_array[1];

    //   if (pick_list_value.category === 'nxp_number_by_year_and_exporter' && this.state.selected_export_year === temp_nxp_export_year) {
    //     return <SelectField pick_list_id={temp_nxp_number} key={i} item_name={temp_nxp_number} />;
    //   } else {
    //     return null;
    //   }
    // });

    // this.setState({select_field_values_nxp_number: select_field_values_nxp_number});

    // window.$('select').material_select();








            const export_year = this.refs.export_year.value;
            const exporter_id = this.refs.exporter_id.value;
            this.setState({selected_export_year: export_year, selected_exporter_id: exporter_id, selected_nxp_number: null}, () => {
              const select_field_values_nxp_number = this.state.pick_list_values.map((pick_list_value, i) => {
                if (pick_list_value.category === 'nxp_number_by_year_and_exporter') {
                  const temp_item_array = pick_list_value.item_name.split(':nxp_by_year_and_exporter:');
                  const temp_nxp_number = temp_item_array[0];
                  const temp_nxp_export_year = temp_item_array[1];
                  const temp_nxp_exporter_id = temp_item_array[2];

                  if (
                    pick_list_value.category === 'nxp_number_by_year_and_exporter' &&
                    this.state.selected_export_year.toString() === temp_nxp_export_year &&
                    this.state.selected_exporter_id.toString() === temp_nxp_exporter_id
                  ) {
                    return <SelectField pick_list_id={temp_nxp_number} key={i} item_name={temp_nxp_number} />;
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              });

              this.setState({select_field_values_nxp_number: select_field_values_nxp_number}, () => {
                window.$('select').material_select();
              });
            });
  }

  getExportYears() {
    let year_temp = null;
    let select_field_values_export_year = [];

    for (let i = 0; i < 10; i++) {
      // populate the values in the export year dropdown
      if (i === 0) {
        year_temp = new Date();
        year_temp = year_temp.getFullYear();
        select_field_values_export_year.push(year_temp - 10); // add ten precedding years to the list
        select_field_values_export_year.push(year_temp - 9);
        select_field_values_export_year.push(year_temp - 8);
        select_field_values_export_year.push(year_temp - 7);
        select_field_values_export_year.push(year_temp - 6);
        select_field_values_export_year.push(year_temp - 5);
        select_field_values_export_year.push(year_temp - 4);
        select_field_values_export_year.push(year_temp - 3);
        select_field_values_export_year.push(year_temp - 2);
        select_field_values_export_year.push(year_temp - 1);
        select_field_values_export_year.push(year_temp);
      } else {
        select_field_values_export_year.push(select_field_values_export_year[select_field_values_export_year.length - 1] + 1); // get the last element in the array and add 1 to it before adding the value back to the array
      }
    }

    this.setState({
      select_field_values_export_year: select_field_values_export_year
    }, () => {
        window.$('select').material_select();
    });

    
  }

  updateECC(newECC) {
    let eccId = this.props.match.params.id;

    axios
      .request({
        method: 'put',
        url: `${this.apiURL}/api/v1/eccs/${eccId}`,
        data: newECC
      })
      .then(response => {
        this.setState({response_status: response.data.status});

        this.setState({message: response.data.message}, () => {
          

          if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.ECC.UpdateSuccess + newECC.certificate_number, 8000, 'rounded green');
        });

        this.setState({preloader: null});
        document.getElementById('update_ecc_button').removeAttribute('disabled');

        this.props.history.push('/exporters/ecc');

        // this.clearForm();
      })
      .catch(error => {
        if (error.response) {
          

          if (error.response.data.message[0].msg) {
            let len = error.response.data.message.length;

            for (let i = 0; i < len; i++) {
              window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
            }
          } else {
            if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.ECC.UpdateError, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ECC.DoesNotExist, 8000, 'rounded deep-orange darken-4');
            if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.ECC.UpdateConflict, 8000, 'rounded deep-orange darken-4');
          }

          //   this.props.history.push('/exporters/ecc');
        } else if (error.request) {
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        

        this.setState({preloader: null});
        document.getElementById('update_ecc_button').removeAttribute('disabled');
      });
  }

  onDelete() {
    document.getElementById('delete_ecc_button').setAttribute('disabled', true);

    let certificate_number = this.state.certificate_number;
    let exporter_id = this.state.exporter_id;
    let nxp_number = this.state.nxp_number;
    let logged_in_username = this.state.logged_in_username;
    let eccId = this.props.match.params.id;

    const userOS = this.Browser.getOS();

    const deleteECC = {
      certificate_number: certificate_number,
      exporter_id: exporter_id,
      nxp_number: nxp_number,
      last_updated_by: logged_in_username,
      device_name: this.state.logged_in_username + "'s " + userOS,
      device_model: userOS,
      device_os: userOS,
      browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
      location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
      app_source: 'web',
      token: this.Auth.getToken()
    };

    axios
      .request({
        method: 'delete',
        url: `${this.apiURL}/api/v1/eccs/${eccId}`,
        data: deleteECC
      })
      .then(response => {
        this.setState({status: response.data.status});

        this.setState({message: response.data.message}, () => {
          
          window.Materialize.toast(this.Language.NodeMessage.ECC.DeleteSuccess + deleteECC.certificate_number, 8000, 'rounded green');
        });

        document.getElementById('delete_ecc_button').removeAttribute('disabled');
        this.props.history.replace('/exporters/ecc');
      })
      .catch(error => {
        if (error.response) {
          

          if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
          if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.ECC.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');
        } else if (error.request) {
          
        } else {
          // Something happened in setting up the request that triggered an Error
          
        }
        

        document.getElementById('delete_ecc_button').removeAttribute('disabled');
      });
  }

  render() {
    // let eccId = this.props.match.params.id;

    return [
      // <LoaderWrapper key={0}/>,
      <Header key={1} history={this.props.history} language={this.Language} />,

      // <!-- START MAIN -->
      <div id="main" key={2}>
        <div className="wrapper green lighten-5">
          <LeftSideBar active_menu_level_1={'ECCs'} active_menu_level_2={'ECC Summary'} history={this.props.history} language={this.Language} />
          {/* <!-- START CONTENT --> */}
          <section id="content">
            <BreadcrumbsWrapper
              page_title={`${this.Language.PageTitle.EditECC} ${this.state.certificate_number}`}
              breadcrumbs={[this.Language.BreadCrumbLabel.Home, this.Language.BreadCrumbLabel.ECCs, this.Language.BreadCrumbLabel.EditECC]}
              links={[`/`, `/exporters/ecc/`, `/exporters/ecc/edit`]}
              language={this.Language}
            />
            {/* <!--start container--> */}
            <div className="container">
              <div className="row">
                <div className="col s12 l12">
                  {this.state.error_messages}

                  <div className="card-panel">
                    {this.state.preloader && (
                      <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                      </div>
                    )}

                    <h4 className="header2">{this.Language.PageLabel.EditECC}</h4>
                    <div className="row">
                      <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                        <div className="row">
                          <div className="input-field col s8">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-exporter_id" className="initialized" name="exporter_id" id="exporter_id" ref="exporter_id">
                                {this.state.exporter_id && (
                                  <option value={this.state.exporter_id} selected>
                                    {this.state.exporter_name}
                                  </option>
                                )}
                                <option value="Select Exporter">{this.Language.DataFieldDefault.SelectExporter}</option>
                                {this.state.select_field_values_exporter.map((exporter_value, i) => {
                                  return exporter_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.Exporter} *</label>
                          </div>

                          <div className="input-field col s4">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-export_year" className="initialized" name="export_year" id="export_year" ref="export_year">
                                {this.state.selected_export_year && (
                                  <option value={this.state.selected_export_year} selected>
                                    {this.state.selected_export_year}
                                  </option>
                                )}
                                <option value="Select Export Year">{this.Language.DataFieldDefault.SelectExportYear}</option>
                                {this.state.select_field_values_export_year.map((export_year_value, i) => {
                                  return export_year_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.ExportYear}</label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input id="certificate_number" type="text" ref="certificate_number" value={this.state.certificate_number} onChange={this.handleInputChange} />
                            <label
                              htmlFor="certificate_number"
                              className={this.state.certificate_number && this.state.certificate_number !== null && typeof this.state.certificate_number !== 'undefined' ? 'active' : ''}>
                              {this.Language.DataField.CertificateNumber} *
                            </label>
                          </div>
                          <div className="input-field col s4">
                            <input
                              type="text"
                              className="datepicker picker__input"
                              ref="date_issued"
                              readOnly="false"
                              id="P875200303"
                              tabIndex="-1"
                              aria-haspopup="true"
                              aria-readonly="false"
                              aria-owns="P875200303_root"
                              value={this.state.date_issued}
                            />
                            <label htmlFor="date_issued" className={this.state.date_issued && this.state.date_issued !== null && typeof this.state.date_issued !== 'undefined' ? 'active' : ''}>
                              {this.Language.DataField.DateIssued} *
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s8">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-nxp_number" className="initialized" name="nxp_number" id="nxp_number" ref="nxp_number">
                                {this.state.selected_nxp_number && (
                                  <option value={this.state.selected_nxp_number} selected>
                                    {this.state.selected_nxp_number}
                                  </option>
                                )}
                                <option value="Select NXP Number">{this.Language.DataFieldDefault.SelectNXPNumber}</option>
                                {this.state.select_field_values_nxp_number.map((nxp_number_value, i) => {
                                  return nxp_number_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.NXPNumber} *</label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s3">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-currency" className="initialized" name="currency" ref="currency">
                                {this.state.currency && (
                                  <option value={this.state.currency} selected>
                                    {this.state.currency}
                                  </option>
                                )}
                                <option value="Select Currency">{this.Language.DataFieldDefault.SelectCurrency}</option>
                                {this.state.select_field_values_currency.map((currency_value, i) => {
                                  return currency_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.Currency} *</label>
                          </div>
                          <div className="input-field col s6">
                            <input id="certificate_value" type="number" className="validate" ref="certificate_value" value={this.state.certificate_value} onChange={this.handleInputChange} />
                            <label
                              htmlFor="certificate_value"
                              data-error={this.Language.DataFieldError.IntegerNumber}
                              className={
                                (this.state.certificate_value && this.state.certificate_value !== null && typeof this.state.certificate_value !== 'undefined') || this.state.certificate_value === 0
                                  ? 'active'
                                  : ''
                              }>
                              {this.Language.DataField.CertificateValue} *
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s12">
                            <input id="certificate_value_words" type="text" ref="certificate_value_words" value={this.state.certificate_value_words} />
                            <label
                              htmlFor="certificate_value_words"
                              className={
                                this.state.certificate_value_words && this.state.certificate_value_words !== null && typeof this.state.certificate_value_words !== 'undefined' ? 'active' : ''
                              }>
                              {this.Language.DataField.CertificateValue}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <input id="value_utilised" type="number" className="validate" ref="value_utilised" value={this.state.value_utilised} onChange={this.handleInputChange} />
                            <label
                              htmlFor="value_utilised"
                              data-error={this.Language.DataFieldError.IntegerNumber}
                              className={
                                (this.state.value_utilised && this.state.value_utilised !== null && typeof this.state.value_utilised !== 'undefined') || this.state.value_utilised === 0 ? 'active' : ''
                              }>
                              {this.Language.DataField.ValueUtilised} *
                            </label>
                          </div>
                          <div className="input-field col s6">
                            <input id="balance_value" type="number" className="validate" ref="balance_value" value={this.state.balance_value} />
                            <label
                              htmlFor="balance_value"
                              data-error={this.Language.DataFieldError.IntegerNumber}
                              className={
                                (this.state.balance_value && this.state.balance_value !== null && typeof this.state.balance_value !== 'undefined') || this.state.balance_value === 0 ? 'active' : ''
                              }>
                              {this.Language.DataField.BalanceValue} *
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="is_utilised" ref="is_utilised" checked={this.state.is_utilised} onChange={this.handleCheckBoxChange} />
                              <label htmlFor="is_utilised">{this.Language.DataField.IsUtilised}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s8">
                            <div className="select-wrapper initialized">
                              <span className="caret">▼</span>
                              <select data-select-id="select-options-utilising_agency" className="initialized" name="utilising_agency" ref="utilising_agency">
                                {this.state.utilising_agency && (
                                  <option value={this.state.utilising_agency} selected>
                                    {this.state.utilising_agency}
                                  </option>
                                )}
                                <option value="Select Utilising Agency">{this.Language.DataFieldDefault.SelectPartnerAgency}</option>
                                {this.state.select_field_values_organisation.map((organisation_value, i) => {
                                  return organisation_value;
                                })}
                              </select>
                            </div>
                            <label>{this.Language.DataField.UtilisingAgency} *</label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="is_transfered" ref="is_transfered" checked={this.state.is_transfered} onChange={this.handleCheckBoxChange} />
                              <label htmlFor="is_transfered">{this.Language.DataField.IsTransferred}</label>
                            </p>
                          </div>
                          <div className="col s3">
                            <p>
                              <input type="checkbox" id="transfer_approved" ref="transfer_approved" checked={this.state.transfer_approved} onChange={this.handleCheckBoxChange} />
                              <label htmlFor="transfer_approved">{this.Language.DataField.TransferApproved}</label>
                            </p>
                          </div>
                          <div className="input-field col s6">
                            <input
                              id="transfered_to"
                              type="text"
                              ref="transfered_to"
                              name="transfered_to"
                              value={this.state.transfered_to}
                              onChange={this.handleInputChange}
                              onBlur={this.handleInputChange}
                            />
                            <label htmlFor="transfered_to" className={this.state.transfered_to && this.state.transfered_to !== null && typeof this.state.transfered_to !== 'undefined' ? 'active' : ''}>
                              {this.Language.DataField.TransferredTo}
                            </label>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col s4">
                            <p>
                              <input type="checkbox" id="is_approved" ref="is_approved" checked={this.state.is_approved} onChange={this.handleCheckBoxChange} />
                              <label htmlFor="is_approved">{this.Language.DataField.IsApproved}</label>
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col s6">
                            <br />
                            <div className="switch">
                              <label className="font-weight-600 black-text">
                                {this.Language.DataField.DeactivateECC}
                                <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                <span className="lever"></span>
                                {this.Language.DataField.ActivateECC}
                              </label>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="input-field col s6">
                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_ecc_button">
                              {this.Language.ButtonLabel.UpdateECC}
                            </button>
                          </div>
                          {/* ::after */}
                          <div className="input-field col s4">
                            <Link className="waves-effect waves-light btn grey right" to={`/exporters/ecc`}>
                              <i className="material-icons left">cancel</i>
                              {this.Language.ButtonLabel.Cancel}
                            </Link>
                          </div>
                          <div className="input-field col s2">
                            <div
                              className="waves-effect waves-light btn red"
                              id="delete_ecc_button"
                              onClick={() => {
                                if (window.confirm(`${this.Language.ConfirmMessage.DeleteECC} ${this.state.certificate_number}`)) {
                                  this.onDelete();
                                }
                              }}>
                              <i className="material-icons left">delete</i>
                              {this.Language.ButtonLabel.Delete}
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                      </form>
                      {/* ::after */}
                    </div>
                  </div>
                </div>
                {/* ::after */}
              </div>
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!-- END CONTENT --> */}
          <FloatingActionButton language={this.Language} />
          {/* <RightSideBar language={this.Language}/> */}
        </div>
        {/* <!-- END WRAPPER --> */}
      </div>,
      // <!-- END MAIN --> */
      // <!-- //////////////////////////////////////////////////////////////////////////// -->
      <Footer key={3} language={this.Language} />
    ];
  }
}

export default EditECC;