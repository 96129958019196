import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

import ReactDOM from 'react-dom';


class ForgotPasswordChange extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.getPasswordConfig = this.getPasswordConfig.bind(this);
        this.state = {
            errors: {},
            user: {
                username: '',
                password: ''
            },
            language: 'English',
            languages: ['English', 'Français', '中文'],
            status: '',
            auth: '',
            password_config: '',
            instructions: [],
            token: null,
            redirect: null,
            message: '',
            preloader: null
        };
        this.Auth = new AuthService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();
    }


    componentDidMount() {
        document.title = this.Language.Header.ChangePassword + ' - ' + this.Language.EEGWebPortal;
        this.getPasswordConfig();

        this.setState({
            language: this.Language.Language,
            languages: LanguageService.prototype.getLanguageList()
        });

        window.$('select').material_select();
        window.$(ReactDOM.findDOMNode(this.refs.language)).on('change', this.changeLanguage.bind(this));

    }

    changeLanguage(event) {

        event.preventDefault();

        let lang = this.refs.language.value;

        if (this.state.language !== lang) {
            this.setState({ language: lang });

            LanguageService.prototype.setTranslation(lang);
            window.location.reload();
        }

    }


    getPasswordConfig() {

        axios.get(`${this.apiURL}/api/v1/user/password/config?tk=${this.Auth.getToken()}`)
            .then(response => {

                this.setState({ password_config: response.data.data[0] }, () => {
                    
                })

                this.setState({ instructions: response.data.instructions }, () => {
                    
                })
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    

                    
                    //window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange accent-3');

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });
    }


    changePassword(newPasswordChange) {

        let userId = this.props.match.params.id;

        axios.request({
            method: 'post',
            url: `${this.apiURL}/api/v1/user/password/change/${userId}`,
            data: newPasswordChange
        }).then(response => {
            this.setState({ status: response.data.status }, () => {
                
            })


            this.Auth.logout(); // remove the password change token earlier stored in the session storage


            this.setState({ message: response.data.message }, () => {
                
                window.Materialize.toast(`${this.Language.ConfirmMessage.PasswordChangeSuccess}`, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("password_change_button_forgot").removeAttribute("disabled");;
            document.getElementById("username").removeAttribute("disabled");;
            document.getElementById("reset_code").removeAttribute("disabled");;
            document.getElementById("new_password").removeAttribute("disabled");;
            document.getElementById("confirm_new_password").removeAttribute("disabled");;
            document.getElementById("signin_link").hidden = false;

            this.props.history.replace('/signin');


        })
            .catch(error => {
                if (error.response) {

                    


                    window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("password_change_button_forgot").removeAttribute("disabled");;
                document.getElementById("username").removeAttribute("disabled");;
                document.getElementById("reset_code").removeAttribute("disabled");;
                document.getElementById("new_password").removeAttribute("disabled");;
                document.getElementById("confirm_new_password").removeAttribute("disabled");;
                document.getElementById("signin_link").hidden = false;

            });


    }


    onSubmit(event) {
        // prevent default action. in this case, action is the form submission event
        let username = this.refs.username.value + '';
        let reset_code = this.refs.reset_code.value + '';
        let new_password = this.refs.new_password.value + '';
        let confirm_new_password = this.refs.confirm_new_password.value + '';

        if (username.length > 0 && reset_code.length > 0 && new_password.length > 0 && confirm_new_password.length > 0) {

            if (new_password === confirm_new_password) {

                const userOS = this.Browser.getOS();

                const newPasswordChange = {
                    token: this.Auth.getToken(),
                    username: this.refs.username.value,
                    hash_id: this.props.match.params.id,
                    two_factor_code: this.refs.reset_code.value,
                    old_password: this.refs.reset_code.value,
                    new_password: this.refs.new_password.value,
                    confirm_new_password: this.refs.confirm_new_password.value,
                    forgot_password_flag: true,
                    device_name: this.state.logged_in_username + '\'s ' + userOS,
                    device_model: userOS,
                    device_os: userOS,
                    browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                    location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                    app_source: "web"
                }


                this.setState({ preloader: true });
                document.getElementById("password_change_button_forgot").setAttribute("disabled", true);
                document.getElementById("username").setAttribute("disabled", true);
                document.getElementById("reset_code").setAttribute("disabled", true);
                document.getElementById("new_password").setAttribute("disabled", true);
                document.getElementById("confirm_new_password").setAttribute("disabled", true);
                document.getElementById("signin_link").hidden = true;
                this.changePassword(newPasswordChange);

            } else {

                window.Materialize.toast(`${this.Language.ConfirmMessage.PasswordChangeError}`, 4000, 'rounded deep-orange darken-4');
            };

        } else {

            window.Materialize.toast(`${this.Language.ConfirmMessage.PasswordChangeEntryCode}`, 4000, 'rounded deep-orange darken-4');
        };

        event.preventDefault();
    }



    render() {

        let passwordMessage = `${this.Language.ConfirmMessage.PasswordChangeInstructionPhrase}`;
        let passwordConfirm = `${this.Language.ConfirmMessage.PasswordConfirm}`;

        const divStyle = {
            position: 'relative',
            top: '50%',
            WebkitTransform: 'translateY(50%)',
            msTransform: 'translateY(50%)',
            transform: 'translateY(25%)'
        };



        const passwordInstructions = this.state.instructions.map((instruction, i) => {
            if (instruction === 'limit_pasword_length_min') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.limit_pasword_length_min} {this.state.minimimum_pass_length}</p>);
            if (instruction === 'limit_pasword_length_max') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.limit_pasword_length_max} {this.state.maximum_pass_length}</p>);
            if (instruction === 'allow_alphanumeric') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.allow_alphanumeric}</p>);
            if (instruction === 'allow_capital') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.allow_capital}</p>);
            if (instruction === 'allow_special_char') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.allow_special_char}</p>);
            if (instruction === 'allow_user_id') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.allow_user_id}</p>);
            if (instruction === 'must_contain_lower_case') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.must_contain_lower_case}</p>);
            if (instruction === 'must_contain_upper_case') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.must_contain_upper_case}</p>);
            if (instruction === 'must_contain_special_character') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.must_contain_special_character}</p>);
            if (instruction === 'allow_consectutive_character') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.allow_consectutive_character}</p>);
            if (instruction === 'allow_password_reuse') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.allow_password_reuse}</p>);
            if (instruction === 'allow_password_reuse_count') return (<p className="orange-text" key={i}>{i + 1}. {this.Language.PasswordInstructions.allow_password_reuse_count.BeginningPhrase} {this.state.pass_reuse_count} {this.Language.PasswordInstructions.allow_password_reuse_count.EndPhrase}</p>);
            return (<p className="orange-text" key={i}>{i + 1}. {''}</p>)
        })


        const select_field_values_language = this.state.languages.map((language, i) => {
            if (language !== this.state.language) {
                return (
                    <option value={language} key={i}>{language}</option>
                )
            } else {
                return (
                    null
                )
            }
        })



        return (
            <div div="login-page" className="row">
                <div className="col s4 m3 l4">
                </div>
                <div className="vertical-form sign-in col s12 m7 l4 z-depth-4 card-panel" style={divStyle} >

                    {this.state.preloader && <div className="progress teal lighten-4">
                        <div className="indeterminate teal"></div>
                    </div>}

                    {/* <form className="login-form" onSubmit={this.onSubmit.bind(this)}> */}

                    <form className="" onSubmit={this.onSubmit.bind(this)}>

                        <div className="row">
                            <div className="input-field col s12 center">
                                <img src="/assets/images/logo/nepc_logo_small.png" alt="" className="responsive-img valign" />
                                <div className="card-content green-text darken-4">
                                    <span className="card-title center"><h5>{this.Language.PageLabel.EEGWebPortal}<br />{this.Language.PageLabel.ChangePassword}</h5></span>
                                    <p className="center">{this.Language.PageLabel.PleaseEnterDetailsBelowToChangeYourPassword}</p>
                                </div>
                            </div>

                        </div>



                        <div className="container">
                            <div className="field-line">
                                <div className="input-field col s12">
                                    <input id="username" type="text" className="validate" ref="username" />
                                    <label htmlFor="username">{this.Language.DataField.Username}</label>
                                </div>
                            </div>

                            <div className="field-line">
                                <div className="input-field col s12">
                                    <input id="reset_code" type="password" className="validate" ref="reset_code" />
                                    <label htmlFor="reset_code">{this.Language.DataField.ResetCode}</label>
                                </div>
                            </div>

                            <div className="field-line">
                                <div className="input-field col s12">
                                    <input id="new_password" type="password" className="validate tooltipped" ref="new_password" data-position="left" data-delay="50" data-tooltip={passwordMessage} />
                                    <label htmlFor="new_password">{this.Language.DataField.NewPassword}</label>
                                </div>
                            </div>

                            <div className="field-line">
                                <div className="input-field col s12">
                                    <input id="confirm_new_password" type="password" className="validate tooltipped" ref="confirm_new_password" data-position="left" data-delay="50" data-tooltip={passwordConfirm} />
                                    <label htmlFor="confirm_new_password">{this.Language.DataField.ConfirmPassword}</label>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row col s12">
                                    <input type="submit" value={this.Language.ButtonLabel.ChangePassword} className="waves-effect waves-light col s12 btn right green" id="password_change_button_forgot" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s8">
                                    <p id="signin_link"><Link to={"/signin"}>{this.Language.DataField.Signin}</Link></p>
                                </div>
                                <div className="col s4">
                                    <div className="select-wrapper initialized">
                                        <span className="caret">▼</span>
                                        <select data-select-id="select-options-language" className="initialized teal-text" ref="language">
                                            <option value={this.state.language}>{this.state.language}</option>
                                            {select_field_values_language}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <br />

                            <p className="orange-text"><b>{this.Language.PageLabel.PasswordInstructions}:</b></p>
                            {passwordInstructions}

                        </div>

                    </form>

                </div>
                <div className="col s4 m2 l4">
                </div>
            </div>
        )
    }
}

export default ForgotPasswordChange;