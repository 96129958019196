import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../modules/AuthService';
import BrowserService from '../modules/BrowserService';
import ValidationService from '../modules/ValidationService';
// import LoaderWrapper from '../components/LoaderWrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LeftSideBar from '../components/LeftSideBar';
// import RightSideBar from '../components/RightSideBar';
import BreadcrumbsWrapper from '../components/BreadcrumbsWrapper';
import FloatingActionButton from '../components/FloatingActionButton';
import SelectField from '../components/SelectField';
import LanguageService from '../modules/LanguageService';
import URLService from '../modules/URLService';

const $ = require('jquery');



class EditSystemAccessRightObject extends Component {
    constructor(props) {
        super(props);
        this.getProfile = this.getProfile.bind(this);
        this.checkAccessRight = this.checkAccessRight.bind(this);
        this.updateSystemAccessRightObject = this.updateSystemAccessRightObject.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.getSystemAccessRightObject = this.getSystemAccessRightObject.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getDropdownValues = this.getDropdownValues.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            system_object_name: '',
            module_name_id: '',
            module_name: '',
            sub_module_name_id: '',
            sub_module_name: '',
            system_object_type: '',
            system_object_action_type: '',
            status: '',
            status_active: false,
            last_updated_on_check: '',
            result: '',
            message: '',
            system_access_hash_id: '',
            response_status: '',
            pick_list_values: [],
            select_field_values_module: [],
            preloader: true,
            logged_in_username: '',
            error_messages: null,
            close_error_messages: <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close" onClick={this.handleCloseAlert}>
                <span aria-hidden="true">×</span>
            </button>,
        }
        this.Auth = new AuthService();
        this.Validate = new ValidationService();
        this.Browser = new BrowserService();
        this.getTranslation = LanguageService.prototype.getTranslation();
        this.Language = this.getTranslation.default;
        this.apiURL = URLService.prototype.getApiURL();

    }


    componentWillMount() {

    }

    componentDidMount() {
        
        this.getProfile();
        this.checkAccessRight();
        this.getSystemAccessRightObject();

    }



    componentDidUpdate() {

    }


    componentWillUnmount() {
        $('select').material_select('destroy');
    }


    getProfile() {

        let user_profile = this.Auth.getProfile();

        if (user_profile) {
            this.setState({ logged_in_username: user_profile.username });

        }

    }


    checkAccessRight() {

        let default_home = '/';
        const dashboards = this.Auth.getDashboards() || [];
        if (dashboards && dashboards.length > 0) default_home = `/dashboard/${dashboards[0].toLowerCase()}/${"5"}`

        if ((this.Auth.checkAccessScope(['update:access_right_object', 'action:access_right_object'], 'view') === false)) {
            this.props.history.replace(default_home);
        } else {
            this.getDropdownValues("module");
        };

    }




    handleCloseAlert() {
        this.setState({ error_messages: null }); // change the value of error_message to close the alert box
    }



    getSystemAccessRightObject() {

        let systemAccessRightObjectId = this.props.match.params.id;

        axios.get(`${this.apiURL}/api/v1/settings/access/right/objects/${systemAccessRightObjectId}?tk=${this.Auth.getToken()}`)
            .then(response => {


                this.setState({
                    system_object_name: response.data.data[0].system_object_name,
                    module_name_id: response.data.data[0].module_id,
                    module_name: response.data.data[0].module_name,
                    sub_module_name_id: response.data.data[0].sub_module_id,
                    sub_module_name: response.data.data[0].sub_module_name,
                    system_object_type: response.data.data[0].system_object_type,
                    system_object_action_type: response.data.data[0].system_object_action_type,
                    system_access_hash_id: response.data.data[0].hash_id,
                    status: response.data.data[0].status,
                    last_updated_on_check: response.data.data[0].last_updated_on_check || '',
                }, () => {
                    document.title = this.Language.PageTitle.EditSystemAccessRightObject.BeginningPhrase + ' ' + this.state.system_object_name + ' ' + this.Language.PageTitle.EditSystemAccessRightObject.EndPhrase + ' - ' + this.Language.EEGWebPortal;
                });


                this.setState({ status_active: this.state.status === 'Active' ? true : false });



                this.setState({ result: response.data.status }, () => {
                    
                })

                this.setState({ message: response.data.message }, () => {
                    
                })


                window.$('select').material_select();


            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.GetError, 8000, 'rounded deep-orange darken-4');
                    }


                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                
            });

    }





    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }



    handleCheckBoxChange(event) {

        const target = event.target;
        // const checked = target.checked;
        const name = target.id;


        if (name === 'activate_switch') {
            this.setState({
                status_active: !this.state.status_active,
                status: this.state.status === 'Active' ? 'Inactive' : 'Active'
            });
        }


    }





    onSubmit(event) {

        event.preventDefault();


        if (window.confirm(`${this.Language.ConfirmMessage.SaveSystemAccessRightObject} ${this.refs.system_object_name.value.trim()}`)) {


            let system_object_name = this.refs.system_object_name.value.trim();


            let system_object_type = this.refs.system_object_type.value;
            if (system_object_type === "Select System Object Type") system_object_type = "";

            let system_object_action_type = this.refs.system_object_action_type.value;
            if (system_object_action_type === "Select System Object Action Type") system_object_action_type = "";


            let module_name_id = this.refs.module_name_id.value;
            if (module_name_id === "Select Module") module_name_id = "";

            let sub_module_name_id = this.refs.sub_module_name_id.value;
            if (sub_module_name_id === "Select Sub Module") sub_module_name_id = "";


            let status = "Inactive";
            if (this.refs.activate_switch.checked) status = "Active";
            if (!this.refs.activate_switch.checked) status = "Inactive";

            let last_updated_by = this.state.logged_in_username;

            let last_updated_on_check = this.state.last_updated_on_check;


            this.setState({ error_messages: this.Validate.validation(this.state.close_error_messages, this.Language.ReactValidationMessage.SystemAccessRightObject.EditErrorTitle, [system_object_name, system_object_type, system_object_action_type, module_name_id], [this.Language.DataField.SystemObjectName, this.Language.DataField.SystemObjectType, this.Language.DataField.SystemActionType, this.Language.DataField.Module], ["isRequired", "isRequired", "isRequired", "isRequired"], 0, 0) }, () => {

                if (this.state.error_messages === null) {

                    if (module_name_id === "Select Module") module_name_id = null;
                    if (sub_module_name_id === "Select Sub Module") sub_module_name_id = null;

                    const userOS = this.Browser.getOS();

                    //make call to database 
                    const newSystemAccessRightObject = {
                        system_object_name: system_object_name,
                        module_id: module_name_id,
                        sub_module_id: sub_module_name_id,
                        system_object_type: system_object_type,
                        system_object_action_type: system_object_action_type,
                        status: status,
                        last_updated_by: last_updated_by,
                        last_updated_on_check: last_updated_on_check,
                        device_name: this.state.logged_in_username + '\'s ' + userOS,
                        device_model: userOS,
                        device_os: userOS,
                        browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
                        location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
                        app_source: "web",
                        token: this.Auth.getToken(),
                    }



                    

                    this.setState({ preloader: true });
                    document.getElementById("update_system_access_right_object_button").setAttribute("disabled", true);

                    this.updateSystemAccessRightObject(newSystemAccessRightObject);

                } else {
                    window.Materialize.toast(this.Language.ReactValidationMessage.FormErrors, 8000, 'rounded deep-orange darken-4');
                }

            });

        }

    }



    getDropdownValues(pick_list_names) {


        axios.get(`${this.apiURL}/api/v1/settings/picklists`, {
            params: {
                tk: this.Auth.getToken(),
                pick_list_names: pick_list_names,
            }
        })
            .then(response => {


                this.setState({ pick_list_values: response.data.data }, () => {
                    


                    if (this.state.pick_list_values && this.state.pick_list_values.length > 0) {


                        const select_field_values_module = this.state.pick_list_values.map((pick_list_value, i) => {
                            if (pick_list_value.category === 'module') {
                                return (
                                    <SelectField pick_list_id={pick_list_value.pick_list_id} key={i} item_name={pick_list_value.item_name} />
                                )
                            } else {
                                return (
                                    null
                                )
                            }
                        })

                        this.setState({ select_field_values_module: select_field_values_module });



                    };

                })

                window.$('select').material_select();
                this.setState({ preloader: null });


            })
            .catch(error => {
                if (error.response) {


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.PickListValues.Error, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.PickListValues.DataNotprovided, 8000, 'rounded deep-orange darken-4');

                    }


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });

            });

    }





    updateSystemAccessRightObject(newSystemAccessRightObject) {

        let systemAccessrightObjectId = this.props.match.params.id;

        axios.request({
            method: 'put',
            url: `${this.apiURL}/api/v1/settings/access/right/objects/${systemAccessrightObjectId}`,
            data: newSystemAccessRightObject
        }).then(response => {

            this.setState({ response_status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                if (response.status === 200) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.UpdateSuccess + newSystemAccessRightObject.system_object_name, 8000, 'rounded green');
            })


            this.setState({ preloader: null });
            document.getElementById("update_system_access_right_object_button").removeAttribute("disabled");

            this.props.history.push('/settings/access/right/objects');

            // this.clearForm();

        })
            .catch(error => {
                if (error.response) {

                    


                    if (error.response.data.message[0].msg) {

                        let len = error.response.data.message.length

                        for (let i = 0; i < len; i++) {
                            window.Materialize.toast(error.response.data.message[i].msg, 8000, 'rounded deep-orange darken-4');
                        }

                    } else {

                        window.Materialize.toast(error.response.data.message, 8000, 'rounded deep-orange darken-4');

                        if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.UpdateError, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.DoesNotExist, 8000, 'rounded deep-orange darken-4');
                        if (error.response.status === 409) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.UpdateConflict, 8000, 'rounded deep-orange darken-4');

                    }

                    //   this.props.history.push('/modules');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                this.setState({ preloader: null });
                document.getElementById("update_system_access_right_object_button").removeAttribute("disabled");

            });

    }



    onDelete() {

        document.getElementById("delete_system_access_right_object_button").setAttribute("disabled", true);


        let system_object_name = this.state.system_object_name;
        let logged_in_username = this.state.logged_in_username;
        let systemAccessrightObjectId = this.props.match.params.id;

        const userOS = this.Browser.getOS();


        const deleteSystemAccessrightObject = {
            system_object_name: system_object_name,
            last_updated_by: logged_in_username,
            device_name: this.state.logged_in_username + '\'s ' + userOS,
            device_model: userOS,
            device_os: userOS,
            browser: this.Browser.getBrowser() || this.Language.DefaultBrowser,
            location_used: this.Browser.getLocation() || this.Language.DefaultLocation, // consider using a promise based approch to getting the location details due to async issues
            app_source: "web",
            token: this.Auth.getToken(),
        }



        axios.request({
            method: 'delete',
            url: `${this.apiURL}/api/v1/settings/access/right/objects/${systemAccessrightObjectId}`,
            data: deleteSystemAccessrightObject
        }).then(response => {

            this.setState({ status: response.data.status })

            this.setState({ message: response.data.message }, () => {
                

                window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.DeleteSuccess + deleteSystemAccessrightObject.system_object_name, 8000, 'rounded green');
            })


            document.getElementById("delete_system_access_right_object_button").removeAttribute("disabled");
            this.props.history.replace('/settings/access/right/objects');

        })
            .catch(error => {
                if (error.response) {

                    

                    if (error.response.status === 500) window.Materialize.toast(this.Language.NodeMessage.DeleteError, 8000, 'rounded deep-orange darken-4');
                    if (error.response.status === 400) window.Materialize.toast(this.Language.NodeMessage.SystemAccessRightObject.DeleteDoesNotExist, 8000, 'rounded deep-orange darken-4');


                } else if (error.request) {
                    
                } else {
                    // Something happened in setting up the request that triggered an Error
                    
                }
                

                document.getElementById("delete_system_access_right_object_button").removeAttribute("disabled");

            });

    }




    render() {




        return (
            [
                // <LoaderWrapper key={0}/>,
                <Header key={1} history={this.props.history} language={this.Language} />,

                // <!-- START MAIN -->
                <div id="main" key={2}>
                    <div className="wrapper green lighten-5">
                        <LeftSideBar active_menu_level_1={"Access Management"} active_menu_level_2={"Access Right Objects"} history={this.props.history} language={this.Language} />
                        {/* <!-- START CONTENT --> */}
                        <section id="content">
                            <BreadcrumbsWrapper page_title={`${this.Language.PageTitle.EditSystemAccessRightObject.BeginningPhrase} ${this.state.system_object_name} ${this.Language.PageTitle.EditSystemAccessRightObject.EndPhrase}`} breadcrumbs={[this.Language.BreadCrumbLabel.AccessManagement, this.Language.BreadCrumbLabel.SystemAccessRightObject, this.Language.BreadCrumbLabel.UpdateSystemAccessRightObject]} links={[`/settings/modules`, `/settings/access/right/objects`, `/settings/access/right/object/edit`]} language={this.Language} />
                            {/* <!--start container--> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l12">

                                        {this.state.error_messages}

                                        <div className="card-panel">

                                            {this.state.preloader && <div className="progress teal lighten-4">
                                                <div className="indeterminate teal"></div>
                                            </div>}

                                            <h4 className="header2">{this.Language.PageLabel.EditSystemAccessRightObject}</h4>
                                            <div className="row">
                                                <form className="col s12" onSubmit={this.onSubmit.bind(this)}>
                                                    <div className="row">
                                                        <div className="input-field col s12">
                                                            <i className="material-icons prefix">view_quilt</i>
                                                            <input id="system_object_name" name="system_object_name" type="text" ref="system_object_name" value={this.state.system_object_name} />
                                                            <label htmlFor="system_object_name" className={(this.state.system_object_name && this.state.system_object_name !== null && typeof this.state.system_object_name !== 'undefined') ? "active" : ""}>
                                                                {this.Language.DataField.SystemObjectName} *
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-system_object_type" className="initialized" name="system_object_type" ref="system_object_type">
                                                                    {this.state.system_object_type && <option value={this.state.system_object_type} selected>{this.state.system_object_type.substr(0, 1).toUpperCase() + this.state.system_object_type.substr(1).toLowerCase()}</option>}
                                                                    <option value="Select System Object Type">{this.Language.DataFieldDefault.SelectSystemObjectType}</option>
                                                                    <option value="view">{this.Language.DataField.View}</option>
                                                                    <option value="process">{this.Language.DataField.Process}</option>
                                                                    <option value="button">{this.Language.DataField.Button}</option>
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.SystemObjectType}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s6">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-system_object_action_type" className="initialized" name="system_object_action_type" ref="system_object_action_type">
                                                                    {this.state.system_object_action_type && <option value={this.state.system_object_action_type} selected>{this.state.system_object_action_type.substr(0, 1).toUpperCase() + this.state.system_object_action_type.substr(1).toLowerCase()}</option>}
                                                                    <option value="Select System Object Action Type">{this.Language.DataFieldDefault.SelectSystemObjectActionType}</option>
                                                                    <option value="create">{this.Language.DataField.Create}</option>
                                                                    <option value="read">{this.Language.DataField.Read}</option>
                                                                    <option value="update">{this.Language.DataField.Update}</option>
                                                                    <option value="action">{this.Language.DataField.Action}</option>
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.SystemActionType}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="input-field col s7">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-module_name_id" className="initialized" name="module_name_id" ref="module_name_id">
                                                                    {this.state.module_name_id && <option value={this.state.module_name_id} selected>{this.state.module_name}</option>}
                                                                    <option value="Select Module">{this.Language.DataFieldDefault.SelectModule}</option>
                                                                    {this.state.select_field_values_module.map((module_value, i) => {
                                                                        return (
                                                                            module_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.Module}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                        <div className="input-field col s5">
                                                            <div className="select-wrapper initialized">
                                                                <span className="caret">▼</span>
                                                                <select data-select-id="select-options-sub_module_name_id" className="initialized" name="sub_module_name_id" ref="sub_module_name_id">
                                                                    {this.state.sub_module_name_id && <option value={this.state.sub_module_name_id} selected>{this.state.sub_module_name}</option>}
                                                                    <option value="Select Sub Module">{this.Language.DataFieldDefault.SelectSubModule}</option>
                                                                    {this.state.select_field_values_module.map((module_value, i) => {
                                                                        return (
                                                                            module_value
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>
                                                                {this.Language.DataField.SubModule}
                                                                {/* ::after */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="col s8">
                                                            <br />
                                                            <div className="switch">
                                                                <label className="font-weight-600 black-text">
                                                                    {this.Language.DataField.DeactivateSystemAccessRightObject}
                                                                    <input type="checkbox" name="activate_switch" id="activate_switch" ref="activate_switch" checked={this.state.status_active} onChange={this.handleCheckBoxChange} />
                                                                    <span className="lever"></span>
                                                                    {this.Language.DataField.ActivateSystemAccessRightObject}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="row">
                                                        <div className="input-field col s8">
                                                            <button className="btn cyan waves-effect waves-light" type="submit" name="action" id="update_system_access_right_object_button">
                                                                {this.Language.ButtonLabel.UpdateSystemAccessRightObject}
                                                            </button>
                                                        </div>
                                                        {/* ::after */}
                                                        <div className="input-field col s2">
                                                            <Link className="waves-effect waves-light btn grey right" to={`/settings/access/right/objects`}><i className="material-icons left">cancel</i>{this.Language.ButtonLabel.Cancel}</Link>
                                                        </div>
                                                        <div className="input-field col s2">
                                                            <div className="waves-effect waves-light btn red" id="delete_system_access_right_object_button" onClick={() => { if (window.confirm(`${this.Language.ConfirmMessage.DeleteSystemAccessRightObject} ${this.state.system_object_name}`)) { this.onDelete() } }}><i className="material-icons left">delete</i>{this.Language.ButtonLabel.Delete}</div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </form>
                                                {/* ::after */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ::after */}
                                </div>
                            </div>
                            {/* <!--end container--> */}
                        </section>
                        {/* <!-- END CONTENT --> */}
                        <FloatingActionButton language={this.Language} />
                        {/* <RightSideBar language={this.Language}/> */}
                    </div>
                    {/* <!-- END WRAPPER --> */}
                </div>
                // <!-- END MAIN --> */
                // <!-- //////////////////////////////////////////////////////////////////////////// -->
                ,
                <Footer key={3} language={this.Language} />
            ]
        )
    }
}

export default EditSystemAccessRightObject;